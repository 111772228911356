var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      staticClass: "integrations-modal",
      attrs: { closeOnClickOutside: false },
      model: {
        value: _vm.integrationModalOpened,
        callback: function($$v) {
          _vm.integrationModalOpened = $$v
        },
        expression: "integrationModalOpened"
      }
    },
    [
      _vm.value
        ? _c("div", { staticClass: "integrations-modal__inner" }, [
            _c("div", { staticClass: "integrations-modal__head" }, [
              _vm.value.logoUrl
                ? _c("img", {
                    staticClass: "integrations-modal__head-icon",
                    attrs: { src: _vm.value.logoUrl }
                  })
                : _vm._e(),
              _c("h4", { staticClass: "integrations-modal__head-title" }, [
                _vm._v(_vm._s(_vm.value.title))
              ]),
              _c(
                "div",
                {
                  staticClass: "integrations-modal__head-status",
                  class: {
                    "is-active": ["Connected", "Built-in"].includes(
                      _vm.value.enabledStatus
                    )
                  }
                },
                [_vm._v(" " + _vm._s(_vm.value.enabledStatus) + "\n      ")]
              )
            ]),
            _c("div", { staticClass: "integrations-modal__body" }, [
              _vm.value.coverUrl
                ? _c("img", {
                    staticClass: "integrations-modal__img",
                    attrs: { src: _vm.value.coverUrl }
                  })
                : _vm._e(),
              _c("div", { staticClass: "integrations-modal__content" }, [
                _c(
                  "div",
                  { staticClass: "integrations-modal__content-left-side" },
                  [
                    _c("div", {
                      staticClass: "integrations-modal__about",
                      domProps: { innerHTML: _vm._s(_vm.renderedDescription) }
                    }),
                    _c(
                      "ProOptionWrapper",
                      {
                        attrs: {
                          featureName: _vm.value.featureRestrictionsGroup
                        }
                      },
                      [
                        _vm.hasForm && _vm.$v
                          ? _c(
                              "form",
                              {
                                staticClass: "integrations-modal__form",
                                on: {
                                  submit: function($event) {
                                    $event.preventDefault()
                                    return _vm.submit()
                                  }
                                }
                              },
                              [
                                _vm._l(_vm.fields, function(field) {
                                  return _c(
                                    "FormField",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.showField(field),
                                          expression: "showField(field)"
                                        }
                                      ],
                                      key: field.name,
                                      staticClass:
                                        "integrations-modal__form-item",
                                      attrs: {
                                        label:
                                          field.type === "toggle" ||
                                          field.type === "color"
                                            ? ""
                                            : field.label
                                      }
                                    },
                                    [
                                      field.type === "text"
                                        ? _c("v-text-field", {
                                            attrs: {
                                              placeholder: field.placeholder
                                            },
                                            on: {
                                              blur: function(el) {
                                                return _vm.handleFieldBlur(
                                                  el,
                                                  field
                                                )
                                              }
                                            },
                                            model: {
                                              value: _vm.formValues[field.name],
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.formValues,
                                                  field.name,
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression:
                                                "formValues[field.name]"
                                            }
                                          })
                                        : _vm._e(),
                                      _vm.showField(field)
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "form-field__error"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.validationMsg(
                                                    _vm.$v.formValues[
                                                      field.name
                                                    ]
                                                  )
                                                ) + "\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      field.type === "textarea"
                                        ? _c("v-textarea", {
                                            attrs: {
                                              placeholder: field.placeholder,
                                              "auto-grow": true,
                                              spellcheck: "false",
                                              rows: field.rows || 10
                                            },
                                            on: {
                                              blur: function(el) {
                                                return _vm.handleFieldBlur(
                                                  el,
                                                  field
                                                )
                                              }
                                            },
                                            model: {
                                              value: _vm.formValues[field.name],
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.formValues,
                                                  field.name,
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression:
                                                "formValues[field.name]"
                                            }
                                          })
                                        : _vm._e(),
                                      _vm.showField(field)
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "form-field__error"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.validationMsg(
                                                    _vm.$v.formValues[
                                                      field.name
                                                    ]
                                                  )
                                                ) + "\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      field.type === "toggle"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "side-form__item is-justified"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "side-form__item-label"
                                                },
                                                [_vm._v(_vm._s(field.label))]
                                              ),
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "switcher",
                                                  class: {
                                                    selected:
                                                      _vm.formValues[field.name]
                                                  }
                                                },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.formValues[
                                                            field.name
                                                          ],
                                                        expression:
                                                          "formValues[field.name]"
                                                      }
                                                    ],
                                                    staticClass:
                                                      "switcher__input",
                                                    attrs: { type: "checkbox" },
                                                    domProps: {
                                                      checked: Array.isArray(
                                                        _vm.formValues[
                                                          field.name
                                                        ]
                                                      )
                                                        ? _vm._i(
                                                            _vm.formValues[
                                                              field.name
                                                            ],
                                                            null
                                                          ) > -1
                                                        : _vm.formValues[
                                                            field.name
                                                          ]
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        var $$a =
                                                            _vm.formValues[
                                                              field.name
                                                            ],
                                                          $$el = $event.target,
                                                          $$c = $$el.checked
                                                            ? true
                                                            : false
                                                        if (
                                                          Array.isArray($$a)
                                                        ) {
                                                          var $$v = null,
                                                            $$i = _vm._i(
                                                              $$a,
                                                              $$v
                                                            )
                                                          if ($$el.checked) {
                                                            $$i < 0 &&
                                                              _vm.$set(
                                                                _vm.formValues,
                                                                field.name,
                                                                $$a.concat([
                                                                  $$v
                                                                ])
                                                              )
                                                          } else {
                                                            $$i > -1 &&
                                                              _vm.$set(
                                                                _vm.formValues,
                                                                field.name,
                                                                $$a
                                                                  .slice(0, $$i)
                                                                  .concat(
                                                                    $$a.slice(
                                                                      $$i + 1
                                                                    )
                                                                  )
                                                              )
                                                          }
                                                        } else {
                                                          _vm.$set(
                                                            _vm.formValues,
                                                            field.name,
                                                            $$c
                                                          )
                                                        }
                                                      }
                                                    }
                                                  }),
                                                  _c("span", {
                                                    staticClass:
                                                      "switcher__indicator"
                                                  })
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      field.type === "select"
                                        ? _c("v-select", {
                                            attrs: {
                                              items: _vm.setOptions(
                                                field.options
                                              ),
                                              "item-text": "label",
                                              "item-value": "value",
                                              "item-disabled": "disabled",
                                              attach: "",
                                              placeholder: field.label,
                                              "menu-props": "offsetY",
                                              "append-icon":
                                                "keyboard_arrow_down"
                                            },
                                            model: {
                                              value: _vm.formValues[field.name],
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.formValues,
                                                  field.name,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "formValues[field.name]"
                                            }
                                          })
                                        : _vm._e(),
                                      field.type === "multiselect"
                                        ? _c("v-select", {
                                            attrs: {
                                              items: _vm.setOptions(
                                                field.options
                                              ),
                                              "item-text": "label",
                                              "item-value": "value",
                                              attach: "",
                                              chips: "",
                                              "deletable-chips": "",
                                              multiple: "",
                                              placeholder: field.label,
                                              "menu-props": "offsetY",
                                              "append-icon":
                                                "keyboard_arrow_down"
                                            },
                                            model: {
                                              value: _vm.formValues[field.name],
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.formValues,
                                                  field.name,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "formValues[field.name]"
                                            }
                                          })
                                        : _vm._e(),
                                      field.type === "color"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "side-form__item is-justified"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "side-form__item-label"
                                                },
                                                [_vm._v(_vm._s(field.label))]
                                              ),
                                              _c("ColorPicker", {
                                                staticClass: "for-mailchimp",
                                                model: {
                                                  value:
                                                    _vm.formValues[field.name],
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.formValues,
                                                      field.name,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "formValues[field.name]"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      field.type === "tel"
                                        ? [
                                            _c("vue-tel-input", {
                                              staticClass: "phone-input",
                                              attrs: {
                                                value:
                                                  _vm.formValues[field.name],
                                                validCharactersOnly: false,
                                                autoFormat: true
                                              },
                                              on: {
                                                input: function(val, obj) {
                                                  _vm.formValues[field.name] =
                                                    obj.number
                                                },
                                                validate: function(val) {
                                                  return _vm.setComponentValidation(
                                                    field.name,
                                                    val.valid
                                                  )
                                                }
                                              }
                                            }),
                                            _vm.showField(field)
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-field__error"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.validationMsg(
                                                          _vm.$v.formValues[
                                                            field.name
                                                          ]
                                                        )
                                                      ) + "\n                  "
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                }),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "integrations-modal__form-btn btn btn--uppercase",
                                    attrs: { type: "submit" }
                                  },
                                  [_vm._v("Save")]
                                ),
                                _vm.validationStatus !== ""
                                  ? _c(
                                      "p",
                                      {
                                        staticStyle: {
                                          color: "#ff4d4d",
                                          "font-size": "10px"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.validationStatus) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              2
                            )
                          : _vm._e()
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "integrations-modal__content-right-side" },
                  [
                    _vm.integrationIsAllowed === "sandbox"
                      ? _c(
                          "UiMsgBox",
                          {
                            staticClass: "mb-24",
                            attrs: {
                              icon: "mf-error_outline",
                              color: "warning",
                              size: "s"
                            }
                          },
                          [
                            _c("p", [_c("b", [_vm._v("Sandbox mode only")])]),
                            _c("p", [
                              _vm._v(
                                "Please note that on the current plan this payment gateway will work in sandbox mode only. You will not\n              be able to\n              receive real payments in the sandbox mode."
                              )
                            ]),
                            _c(
                              "UpgradePlanButton",
                              { attrs: { navigateToBilling: true } },
                              [_c("b", [_vm._v("Upgrade Plan")])]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.value.url
                      ? _c("div", { staticClass: "integrations-modal__cta" }, [
                          _c(
                            "a",
                            {
                              staticClass:
                                "integrations-modal__cta-item is-highlighted",
                              attrs: { href: _vm.value.url, target: "_blank" }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "icon-external-link integrations-modal__cta-item-icon"
                              }),
                              _vm._v(
                                "\n              Go to website\n            "
                              )
                            ]
                          )
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "integrations-modal__footnote" }, [
                      _c("p", [
                        _vm._v("To share feedback or request a feature, "),
                        _c("a", { attrs: { href: _vm.supportMailTo } }, [
                          _vm._v("send us a note")
                        ])
                      ]),
                      _vm.value.helpUrl
                        ? _c("p", [
                            _vm._v(
                              "If you don't know how to integrate, see the "
                            ),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.value.helpUrl,
                                  target: "_blank"
                                }
                              },
                              [_vm._v("Connection guide")]
                            )
                          ])
                        : _vm._e()
                    ])
                  ],
                  1
                )
              ])
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }