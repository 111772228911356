export default {
  published: true,
  pro: false,
  featureRestrictionsGroup: 'builtInIntegrations',
  name: 'cookies',
  title: 'Cookies Banner',
  logoUrl: '/img/integrations/cookies.svg',
  coverUrl: '/img/integrations/cookies-cover.png',
  bgColor: '#FEA832',
  categories: ['Privacy'],
  comingSoon: false,
  builtIn: false,
  url: '',
  helpUrl: 'https://help.spreadsimple.com/en/article/setting-up-cookies-banner-nf2n0e/',
  description: `
  A cookie banner (popup) is a block which pops up on top of your website and warns your site visitors of the cookies usage.
  `,

  formFields: [
    {
      name: 'enabled',
      type: 'toggle',
      label: 'Enabled',
      default: false,
      validations: [
        'required'
      ],
      secret: false
    },
    {
      name: 'title',
      type: 'text',
      showIf: ['enabled'],
      label: 'Title',
      default: '🍪 Cookies',
      placeholder: 'Cookies',
      validations: [
        'required',
        'minLength:1',
        'maxLength:100'
      ],
      secret: false
    },
    {
      name: 'text',
      type: 'textarea',
      showIf: ['enabled'],
      label: 'Text',
      default: 'We care about your data, and we’d use cookies only to improve your experience. For a complete overview of the cookies uses, see our Privacy Policy.',
      placeholder: 'We’d use cookies only to improve your experience',
      validations: [
        'required',
        'minLength:1',
        'maxLength:1000'
      ],
      secret: false
    },
    {
      name: 'buttonText',
      type: 'text',
      showIf: ['enabled'],
      label: 'Button Text',
      default: 'I Agree',
      placeholder: 'I Agree',
      validations: [
        'required',
        'minLength:1',
        'maxLength:30'
      ],
      secret: false
    }
  ],

  actions: []
}
