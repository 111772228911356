export default {
  published: true,
  pro: true,
  featureRestrictionsGroup: 'automationIntegrations',
  name: 'suretriggers',
  title: 'Suretriggers',
  headline: '',
  logoUrl: '/img/integrations/suretriggers.svg',
  coverUrl: '',
  bgColor: '#0553f0',
  categories: ['Automation'],
  comingSoon: false,
  builtIn: true,
  url: 'https://suretriggers.com/integrations/spreadsimple',
  helpUrl: '',
  description: `With SureTriggers, you can integrate SpreadSimple with over 750+ apps, seamlessly transferring data between them. This helps you streamline tasks and automate processes effortlessly.`,

  formFields: [],

  actions: []
}
