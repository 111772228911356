import { getOrderTags, getUrlTags, getStoredUrlTags, replaceTemplateTags } from '../../utils/replacement-tags/'

export default {
  published: true,
  pro: true,
  featureRestrictionsGroup: 'messengersIntegrations',
  name: 'whatsapp',
  title: 'WhatsApp — orders to chat',
  logoUrl: '/img/integrations/whatsapp.svg',
  coverUrl: '/img/integrations/whatsapp-cover.png',
  bgColor: '#36A944',
  categories: ['Messengers'],
  comingSoon: false,
  builtIn: false,
  url: 'https://www.whatsapp.com/features/',
  helpUrl: 'https://help.spreadsimple.com/en/article/whatsapp-add-on-orders-to-chat-1l3zpkf/?bust=1613831271849',
  description: `
  Increase your store conversions by allowing your customers to place orders on WhatsApp.

  Using this add-on you will allow your customers to start the <a target="_blank" href="https://faq.whatsapp.com/general/chats/how-to-use-click-to-chat/?lang=en"> instant conversation</a>  with you right upon order placement.

  You will receive the order detail in the first message.

  You can compose the custom message template, just use replacement tags like \`{{client.name}}\` and  \`{{cartItemsText}}\`, these variables to be replaced with actual values from the order data.

  <details>
  <summary>Available order replacement tags</summary>
  <div class="table-wrap">

  | Tag | Example value |
  |---------------|---------------|
  |  \`{{client.name}}\` | John Doe |
  |  \`{{client.phone}}\` | 202-555-0192 |
  |  \`{{client.email}}\` | john@example.com |
  |  \`{{client.message}}\` | Deliver it ASAP |
  |  \`{{orderId}}\` | K8KZPUA |
  |  \`{{cartItemsText}}\` | • 1 × $2.40 \\| Spicy unagi |
  |  \`{{totalAmountFormatted}}\` | $2.40 |

  </div>
  </details>
  `,

  formFields: [
    {
      name: 'enabled',
      type: 'toggle',
      label: 'Enabled',
      default: false,
      validations: [
        'required'
      ],
      secret: false
    },
    {
      name: 'receiverNumber',
      type: 'tel',
      showIf: ['enabled'],
      label: 'Take orders on this WhatsApp number',
      default: '',
      placeholder: '',
      validations: [
        'required',
        'tel'
      ],
      secret: false
    },
    {
      name: 'messageTemplate',
      type: 'textarea',
      showIf: ['enabled'],
      label: 'Message template',
      default: `Hello, I'd like to place my order:
{{ cartItemsText }}

Total: {{ totalAmountFormatted }}

Name: {{ client.name }}
Phone: {{ client.phone }}
Email: {{ client.email }}

Order #: {{ orderId }}

Order notes: {{ client.message }}`,
      placeholder: '',
      validations: [
        'required'
      ],
      secret: false
    }
  ],

  actions: [
    {
      type: 'getRedirectUrl',
      run (formData, orderData) {
        const number = formData.receiverNumber.replace(/\D+/g, '')

        const tags = [
          ...getOrderTags(orderData),
          ...getUrlTags(),
          ...getStoredUrlTags(orderData.urlTags)
        ]
        const message = replaceTemplateTags(formData.messageTemplate, tags)

        return `https://api.whatsapp.com/send?phone=${number}&text=${encodeURIComponent(message)}`
      }
    }
  ]

}
