export default {
  published: true,
  pro: true,
  featureRestrictionsGroup: 'chatIntegrations',
  name: 'drift',
  title: 'Drift',
  headline: '',
  logoUrl: '/img/integrations/drift.svg',
  coverUrl: '/img/integrations/drift-cover.png',
  bgColor: '#3B8AF0',
  categories: ['Chat'],
  comingSoon: false,
  builtIn: false,
  url: 'https://www.drift.com',
  helpUrl: '',
  description: `
  Drift refers to itself as a 'conversational marketing platform'. You can use it for live chat for support but it's more focussed on live chat for capturing and qualifying leads

  <a href="https://support.proposify.com/en/articles/2483291-finding-your-site-id-in-drift" target="_blank">Finding Your Site ID In Drift</a>
  `,

  formFields: [
    {
      name: 'enabled',
      type: 'toggle',
      label: 'Enabled',
      default: false,
      validations: [
        'required'
      ],
      secret: false
    },
    {
      name: 'id',
      type: 'text',
      showIf: ['enabled'],
      label: 'Site ID',
      default: '',
      validations: [
        'required',
        'minLength:4',
        'maxLength:20'
      ],
      secret: false
    }
  ],

  actions: [
    {
      type: 'injectToHead',
      run (formData) {
        return `
        <!-- Start of Async Drift Code -->
        <script>
        "use strict";

        !function() {
          var t = window.driftt = window.drift = window.driftt || [];
          if (!t.init) {
            if (t.invoked) return void (window.console && console.error && console.error("Drift snippet included twice."));
            t.invoked = !0, t.methods = [ "identify", "config", "track", "reset", "debug", "show", "ping", "page", "hide", "off", "on" ],
            t.factory = function(e) {
              return function() {
                var n = Array.prototype.slice.call(arguments);
                return n.unshift(e), t.push(n), t;
              };
            }, t.methods.forEach(function(e) {
              t[e] = t.factory(e);
            }), t.load = function(t) {
              var e = 3e5, n = Math.ceil(new Date() / e) * e, o = document.createElement("script");
              o.type = "text/javascript", o.async = !0, o.crossorigin = "anonymous", o.src = "https://js.driftt.com/include/" + n + "/" + t + ".js";
              var i = document.getElementsByTagName("script")[0];
              i.parentNode.insertBefore(o, i);
            };
          }
        }();
        drift.SNIPPET_VERSION = '0.3.1';
        drift.load('${formData.id}');
        </script>
        <!-- End of Async Drift Code -->
        `
      }
    }
  ]
}
