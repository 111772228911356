export const fieldSchema = {
  name: '', // must be unique
  type: 'text', // select, text, email, number, radio, checkbox, file, textarea, multiselect
  label: '',
  default: '',
  placeholder: '',
  hint: '',
  enabled: true,
  readOnly: false,
  width: '12', // 12, 6, 4, 3. Full width is 12
  validations: [],
  conditions: [], // show if another field is present
  options: [] // {value: '', default: true/false}
}

export const fieldTypes = [
  { type: 'text', label: 'Text' },
  { type: 'email', label: 'Email' },
  { type: 'number', label: 'Number' },
  { type: 'select', label: 'Select' },
  { type: 'multiselect', label: 'Multiselect' },
  { type: 'radio', label: 'Radio' },
  { type: 'checkbox', label: 'Checkbox' },
  { type: 'textarea', label: 'Long text' },
  { type: 'date', label: 'Date' },
  { type: 'time', label: 'Time' },
  { type: 'markdown', label: 'Description (markdown)' }
]
export const fieldWidth = [
  { value: '12', label: 'Full width' },
  { value: '6', label: 'Half width' },
  { value: '4', label: 'One third' },
  { value: '3', label: 'One quarter' }
]

export const fieldValidations = [
  { name: 'required', label: 'Required', message: 'Field required' },
  { name: 'url', label: 'URL', message: 'Field invalid' },
  { name: 'email', label: 'Email', message: 'Field invalid' },
  { name: 'numeric', label: 'Numeric', message: 'Field invalid' },
  { name: 'minLength', label: 'Min Length', message: 'Field invalid' },
  { name: 'maxLength', label: 'Max Length', message: 'Field invalid' },
  { name: 'between', label: 'From To', message: 'Field invalid' },
  { name: 'startsWith', label: 'Starts With', message: 'Field invalid' },
  { name: 'regex', label: 'REGEX', message: 'Field invalid' }
]

export const fieldValidationsRules = [
  { name: 'required', availableForTypes: ['text', 'email', 'number', 'select', 'multiselect', 'radio', 'checkbox', 'textarea', 'date', 'time', 'tel'] },
  { name: 'url', availableForTypes: ['text'] },
  { name: 'email', availableForTypes: ['email'] },
  { name: 'numeric', availableForTypes: ['number'] },
  { name: 'minLength', numberOfArgs: 1, typeOfArgs: 'number', availableForTypes: ['text', 'number', 'textarea'] },
  { name: 'maxLength', numberOfArgs: 1, typeOfArgs: 'number', availableForTypes: ['text', 'number', 'textarea'] },
  { name: 'between', numberOfArgs: 2, typeOfArgs: 'number', availableForTypes: ['number'] },
  { name: 'startsWith', numberOfArgs: 1, typeOfArgs: 'text', availableForTypes: ['text'] },
  { name: 'regex', numberOfArgs: 1, typeOfArgs: 'text', availableForTypes: ['text'] }
]

// ============================== //

export const shippingFieldSchema = [
  { name: 'label', label: 'Label', type: 'text', width: 12, default: '', validations: [{ name: 'required' }] },
  { name: 'minOrder', label: 'Min order', type: 'number', width: 4, default: '', validations: [{ name: 'required' }, { name: 'decimal' }, { name: 'minValue', args: [0] }] },
  { name: 'maxOrder', label: 'Max order', type: 'number', width: 4, default: '', validations: [{ name: 'decimal' }, { name: 'minValue', args: [0] }] },
  { name: 'value', label: 'Value', type: 'number', width: 4, default: '', validations: [{ name: 'required' }, { name: 'decimal' }, { name: 'minValue', args: [0] }] }
]

export const feeFieldSchema = [
  { name: 'label', label: 'Label', type: 'text', width: 6, default: '', validations: [{ name: 'required' }] },
  { name: 'type', label: 'Type', type: 'select', options: [{ label: '+', value: 'addition' }, { label: '%', value: 'multiplication' }], width: 3, default: 'addition', validations: [{ name: 'required' }] },
  { name: 'value', label: 'Value', type: 'number', width: 3, default: '', validations: [{ name: 'required' }, { name: 'decimal' }, { name: 'minValue', args: [0] }] }
]

export const promocodeSchema = [
  { name: 'code', label: 'Code', type: 'text', width: 6, default: '', validations: [{ name: 'required' }, { name: 'alphaNumDashUnderscore' }] },
  { name: 'type', label: 'Discount type', type: 'select', options: [{ label: '-', value: 'fixed' }, { label: '%', value: 'percentage' }, { label: 'Shipping discount (%)', value: 'shipping' }], width: 6, default: 'percentage', validations: [{ name: 'required' }] },
  { name: 'value', label: 'Discount value', type: 'number', width: 3, default: '', validations: [{ name: 'required' }, { name: 'decimal' }, { name: 'minValue', args: [0] }] },
  { name: 'limit', label: 'Usage Limit', type: 'number', width: 3, default: '', validations: [{ name: 'required' }, { name: 'decimal' }, { name: 'minValue', args: [0] }] },
  { name: 'expirationDate', label: 'Code expiration', type: 'date', width: 6, default: '', validations: [{ name: 'required' }] },
  { name: 'sku', label: 'SKUs', type: 'text', width: 12, default: '*', placeholder: 'Add comma separated SKU or "*" to apply code for all items ', validations: [{ name: 'required' }] }
]
