<template>
  <div class="integrations">
    <div v-if="enableSearch" class="integrations__head">
      <div class="integrations__head-search-wrap">
        <button class="integrations__head-search-btn icon-search-new"></button>
        <input v-model="searchQuery" type="text" placeholder="Search..." class="integrations__head-search" @keyup.esc="searchQuery = ''">
      </div>
    </div>
    <div v-if="filteredList.length" class="integrations__list" :class="{ 'is-centered': singleCentered }">
      <div
        v-for="item in filteredList"
        :key="item.name"
        class="integrations__item"
        :class="{
          'is-disabled': item.enabledStatus === 'Coming Soon',
          'three-in-row': modalView,
          'is-single': singleCentered
        }"
        @click="setCurrent(item)"
      >
        <div
          class="integrations__item-img-bg"
          :style="`background-color: ${ item.bgColor };`"
        >
          <div
            v-if="setBadge(item)"
            class="integrations__item-pro-status"
          >
            <!-- // PRICING_UPDATE -->
            Subscription required
          </div>
          <div
            class="integrations__item-img-wrap"
          >
            <img :src="item.logoUrl" class="integrations__item-img">
          </div>
        </div>
        <div class="integrations__item-body">
          <div class="integrations__item-content">
            <h4 class="integrations__item-title">{{ item.title }}</h4>
            <p v-if="item.about" class="integrations__item-paragraph">{{ item.description }}</p>
          </div>
          <div
            v-if="item.comingSoon"
            class="integrations__item-status"
          >
            Coming Soon
          </div>
          <div v-else
            class="integrations__item-status"
            :class="{ 'is-active': ['Connected', 'Built-in'].includes(item.enabledStatus) }"
          >
            {{ item.enabledStatus }}
          </div>
        </div>
      </div>
    </div>
    <div v-else class="integrations__not-found">
      <div class="is-centered">
        <p>Sorry, but nothing matched your search terms. Please try  again with some different keywords.</p>
        <button class="btn" @click="searchQuery = ''">RESET</button>
      </div>
    </div>

    <IntegrationModal
      v-model="currentIntegration"
      :apiKey="userData.apiKey"
      :websiteUrl="websiteUrl"
      :integrationsData="integrationsData"
      @update="updateIntegration"
    />
  </div>
</template>
<script>
import IntegrationModal from '@/components/integration-modal'
import { mapState } from 'vuex'
import { allowFeature } from '@/modules/features-checker'

function getEnabledStatus (int) {
  if (int.builtIn) return 'Built-in'
  if (int.comingSoon) return 'Coming Soon'

  const enabledField = int.formFields.find(({ name }) => name === 'enabled')
  return enabledField && enabledField.value ? 'Connected' : 'Not Connected'
}

export default {
  name: 'IntegrationsManager',
  components: {
    IntegrationModal
  },
  props: {
    integrations: {
      type: Array,
      required: true
    },
    currentIntegrationCategory: {
      type: [String, Object]
    },
    currentIntegrationItems: {
      type: Array
    },
    integrationCategories: {
      type: Array,
      required: true
    },
    integrationsData: {
      type: Array,
      required: true
    },
    userData: {
      type: Object,
      required: true
    },
    websiteUrl: {
      type: String,
      required: true
    },
    enableSearch: {
      type: Boolean,
      default: true
    },
    modalView: {
      type: Boolean,
      default: false
    },
    singleCentered: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      currentIntegration: null,
      searchQuery: ''
    }
  },
  computed: {
    ...mapState('app', [
      'currentSpreadViewData'
    ]),
    integrationsList () {
      return this.integrations
        .map(int => {
          return {
            ...int,
            enabledStatus: getEnabledStatus(int)
          }
        })
    },
    filteredList () {
      const byCategory = (integration) => {
        if (this.currentIntegrationCategory === '_ALL_') return true
        return integration.categories.includes(this.currentIntegrationCategory)
      }
      const byQuery = (integration) => {
        if (!this.searchQuery) return true
        return integration.title.toLowerCase().includes(this.searchQuery.toLowerCase())
      }

      const byName = (integration) => {
        if (!this.currentIntegrationItems) return true
        return this.currentIntegrationItems.includes(integration.name)
      }

      const ftHidden = (integration) => {
        return true
        // if (this.$ft.NEXT) return true
        // const hidden = ['universal-email-subscription']
        // return !hidden.includes(integration.name)
      }

      return this.integrationsList
        .filter(ftHidden)
        .filter(byCategory)
        .filter(byQuery)
        .filter(byName)
    }
  },
  methods: {
    updateIntegration (intItem) {
      const integrationsData = this.integrationsData.slice()
      const item = integrationsData.find(({ name }) => name === intItem.name)
      if (item) {
        item.data = intItem.data
      } else {
        integrationsData.push(intItem)
      }
      this.$emit('update:integrationsData', integrationsData)
    },

    setCurrent (item) {
      if (item.enabledStatus === 'Coming Soon') return
      this.currentIntegration = item
      this.$trackEvent({
        'event': 'openAddonSettings',
        'eventData': {
          addonName: item.name
        }
      })
    },

    setBadge (item) {
      const allowed = allowFeature(item.featureRestrictionsGroup, {
        user: this.userData,
        website: this.currentSpreadViewData
      })
      return !allowed
    }
  }
}
</script>
