var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c("span", { staticClass: "value" }, [_vm._v(_vm._s(_vm.progress))]),
      _vm.showProgressBar
        ? _c("el-progress", {
            attrs: {
              type: "circle",
              percentage: (_vm.progress / _vm.time) * 100
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }