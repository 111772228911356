import Vue from 'vue'
import VueAuthService from 'vue-authenticate-service'
import api, { client } from '@/services/api/'
import store from '@/store/'
import apiDriver from './apiDriver'
import clientDriver from './clientDriver'

Vue.use(VueAuthService, {
  api: apiDriver(api, store),
  client: clientDriver(client),
  options: {
    refresh: true,
    autoFetch: true,
    reloadOnLogout: false
  }
})
