<template>
  <div class="appsumo-page__wrapp">
    <div class="appsumo-page__container">
      <img class="appsumo-page__logo" src="/img/logo_appsumo_ss.png" alt="">
      <h1 class="appsumo-page__title">Hello Sumo-ling! 👋</h1>

      <div class="appsumo-deal-over">
        <p>Sorry, AppSumo codes can’t be redeemed anymore</p>
        <div class="appsumo-deal-over-details">
          According to our <a target="_blank" href="https://appsumo.com/products/spreadsimple/#pricePlans">AppSumo Deal Terms and Conditions</a>, after you’ve purchased a code, you must have redeemed it within 60 days.
          We understand that sometimes 2 months is not enough that’s why we made it 10 months for you to redeem your codes as a thank you for your interest in SpreadSimple and your support.
          But, unfortunately, all things must come to an end, and all our codes have expiration dates as well.
          Due to the fact that it’s been almost a year since our last AppSumo Deal has officially ended, all the codes that were not redeemed before <b>November 25th, 2022</b> are no longer valid.
          If you have any questions or concerns regarding the expired deal, please reach out to AppSumo Support team at <a href="mailto:support@appsumo.com">support@appsumo.com</a>.
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AppsumoCodePage',
  metaInfo: {
    title: 'AppSumo Redemption Page',
    titleTemplate: '%s | SpreadSimple'
  },
  props: {
    id: {
      type: String
    }
  }
}
</script>
