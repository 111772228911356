<template>
  <div class="avatar">
    {{ letter }}
  </div>
</template>

<script>
export default {
  name: 'UserAvatar',
  props: {
    letter: {
      type: String,
      default: 'U'
    }
  }
}
</script>
