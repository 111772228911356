export default {
  published: true,
  pro: true,
  featureRestrictionsGroup: 'analyticsIntegrations',
  name: 'hotjar',
  title: 'Hotjar',
  logoUrl: '/img/integrations/hotjar.svg',
  coverUrl: '/img/integrations/hotjar-cover.png',
  bgColor: '#EE4854',
  categories: ['Analytics'],
  comingSoon: false,
  builtIn: false,
  url: 'https://www.hotjar.com',
  helpUrl: '',
  description: `
  Hotjar is a behavior analytics tool that analyses website use, providing feedback through tools such as heatmaps, session recordings, and surveys.

  <a href="https://help.hotjar.com/hc/en-us/articles/115009336727" target="_blank">Where can I get the Hotjar Site ID ?</a>
  `,

  formFields: [
    {
      name: 'enabled',
      type: 'toggle',
      label: 'Enabled',
      default: false,
      validations: [
        'required'
      ],
      secret: false
    },
    {
      name: 'id',
      type: 'text',
      showIf: ['enabled'],
      label: 'Site ID',
      default: '',
      placeholder: '1230123',
      validations: [
        'required',
        'minLength:1',
        'maxLength:10',
        'numeric'
      ],
      secret: false
    }
  ],

  actions: [
    {
      type: 'injectToHead',
      run (formData) {
        return `
        <!-- Hotjar Tracking Code -->
        <script>
            (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:${formData.id},hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        </script>
        `
      }
    }
  ]
}
