export default {
  published: true,
  pro: false,
  featureRestrictionsGroup: 'builtInIntegrations',
  name: 'youtube',
  title: 'YouTube',
  headline: '',
  logoUrl: '/img/integrations/youtube.svg',
  // coverUrl: '/img/integrations/youtube-cover.png',
  coverUrl: 'https://spreadsimple.s3.amazonaws.com/ae764f89-14ae-498d-9ea7-b5088ffb868d_tg_image_1100547233.jpeg',
  bgColor: '#FF0000',
  categories: ['Video'],
  comingSoon: false,
  builtIn: true,
  url: 'https://www.youtube.com',
  helpUrl: '',
  description: `
  Place the URLs to your YouTube videos in a column in your Google Sheet.

  Then map the column to the **Image (Gallery)** in the 'Content' Tab.
  `,

  formFields: [],

  actions: []
}
