var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "side-form__item is-justified" }, [
    _c("div", { staticClass: "side-form__item-label" }, [
      _vm._v(_vm._s(_vm.title))
    ]),
    _c(
      "label",
      { staticClass: "switcher", class: { selected: _vm.isEnabled } },
      [
        _c("input", {
          staticClass: "switcher__input",
          attrs: { type: "checkbox" },
          domProps: { value: _vm.accessModeValue },
          on: {
            input: function($event) {
              return _vm.$emit("toggle-access-mode")
            },
            change: function($event) {
              return _vm.$trackFeature(
                "access",
                "accessManagementMode",
                _vm.isEnabled
              )
            }
          }
        }),
        _c("span", { staticClass: "switcher__indicator" })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }