<template>
<div style="width: 100%;">
  <v-md-editor
    ref="vmdeditor"
    key="vmdeditor"
    v-model="content"
    class="sv-md-editor"
    :height="height"
    placeholder="Enter text"
    :leftToolbar="leftToolbar"
    rightToolbar=""
    :mode="preview ? 'editable' : 'edit'"
    :toolbar="toolbar"
    :disabled-menus="[]"
  ></v-md-editor>

  <UploadImageModal
    :opened="unsplashModalOpened"
    :unsplashEnabled="true"
    @input="handleUploadImage"
    @on-close="unsplashModalOpened = false"
  />
  <UploadFromNotion
    :opened="notionModalOpened"
    @input="handleUploadFile"
    @on-close="notionModalOpened = false"
  />
  <CreateCartItemsTable
    :cols="cols"
    :opened="cartItemsModalOpened"
    @input="handleCartItemsTable"
    @on-close="cartItemsModalOpened = false"
  />
</div>
</template>
<script>
import UploadImageModal from '@/components/upload-image/UploadImageModal.vue'
import UploadFromNotion from '@/components/upload-from-notion/UploadFromNotion.vue'
import CreateCartItemsTable from '@/components/create-cart-items-table'
export default {
  name: 'MarkdownComponent',
  components: {
    UploadImageModal,
    UploadFromNotion,
    CreateCartItemsTable
  },
  props: {
    value: {
      type: String
    },
    preview: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: '400px'
    },
    leftToolbar: {
      type: String,
      default: 'undo redo clear | h bold italic strikethrough quote hr | ul ol todo-list table | link addImage code | notion'
    },
    cols: {
      type: Array,
      default: () => { return [] }
    }
  },
  data () {
    return {
      unsplashModalOpened: false,
      notionModalOpened: false,
      cartItemsModalOpened: false,
      toolbar: {
        addImage: {
          title: 'menu',
          icon: 'v-md-icon-img',
          menus: [
            {
              name: 'image',
              text: 'Insert Link',
              action (editor) {
                editor.execCommand('image')
              }
            },
            {
              name: 'upload-image',
              text: 'Upload Image',
              action: () => {
                this.unsplashModalOpened = true
              }
            }
          ]
        },
        notion: {
          title: 'notion',
          text: 'Import',
          icon: 'icon-notion text-plus-icon',
          action: () => {
            this.notionModalOpened = true
          }
        },
        cartItemsTable: {
          title: 'Insert cart items as a table or list',
          text: '',
          icon: 'icon-cart-to-list',
          action: () => {
            this.cartItemsModalOpened = true
          }
        }
      }
    }
  },
  computed: {
    content: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    handleUploadImage (url) {
      this.$refs.vmdeditor.execCommand('image', {
        'url': url
      })
    },
    handleCartItemsTable (text) {
      this.$refs.vmdeditor.insert(function (selected) {
        return {
          text: text,
          selected: selected
        }
      })
    },
    handleUploadFile (val) {
      this.$refs.vmdeditor.insert((selected) => {
        return {
          text: `${val}`,
          selected: val
        }
      })
    }
  }
}
</script>
