var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      staticClass: "tag",
      class: ["tag--color-" + _vm.color, "tag--size-" + _vm.size]
    },
    [_c("span", { staticClass: "tag__title" }, [_vm._v(_vm._s(_vm.label))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }