var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "account-page" }, [
      _vm.userData
        ? _c(
            "div",
            { staticClass: "account-page__container" },
            [
              _c(
                "el-tabs",
                {
                  staticClass: "account-tabs",
                  attrs: { type: "card" },
                  on: { "tab-click": _vm.pushToRoute },
                  model: {
                    value: _vm.activeTab,
                    callback: function($$v) {
                      _vm.activeTab = $$v
                    },
                    expression: "activeTab"
                  }
                },
                [
                  _c(
                    "el-tab-pane",
                    {
                      attrs: {
                        label: _vm.$t("global.billing"),
                        name: "account-billing"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "profile-tab no-bottom-indent" },
                        [_c("BillingInfo")],
                        1
                      )
                    ]
                  ),
                  _c(
                    "el-tab-pane",
                    {
                      attrs: {
                        label: _vm.$t("global.settings"),
                        name: "account-settings"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "profile-tab" },
                        [_c("AccountSettings")],
                        1
                      )
                    ]
                  ),
                  _c(
                    "el-tab-pane",
                    {
                      attrs: { label: "Affiliate", name: "account-affiliate" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "profile-tab" },
                        [_c("AffiliateSettings")],
                        1
                      )
                    ]
                  ),
                  _c(
                    "el-tab-pane",
                    {
                      attrs: { label: "Workspace", name: "account-workspaces" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "profile-tab" },
                        [_c("WorkspacesSettings")],
                        1
                      )
                    ]
                  ),
                  _vm.userData.appsumo
                    ? _c(
                        "el-tab-pane",
                        {
                          attrs: { label: "AppSumo", name: "account-appsumo" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "profile-tab" },
                            [_c("AppsumoSettings")],
                            1
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }