export default {
  userData: null,
  workspaces: null,
  currentWorkspaceId: undefined,
  currentSpreadViewId: null,
  currentSpreadViewData: null,
  needToUpdateViews: false,
  integrationsOpened: false,
  unsubscribeModifierDescription: 'unsubscribeOfferMinus20ToAllPlans',
  seatsRequestModal: false,
  updateSeatsModal: false,
  addMoreSeatsInfoModal: false,
  offerToChangeWebsitePlan: false,
  pricingModal: false,
  unSubscriptionQuizModal: false,
  paymentDetailsModal: false,
  proRateModal: false,
  websiteAccess: {
    created: false,
    enabled: false,
    spreadsheetUrl: '',
    scheme: [],
    authProvider: '',
    authProviders: [],
    users: [],
    availableAccessGroups: []
  },
  spreadViewerOptionsValidation: {
    contentOptionsInvalid: false,
    checkoutOptionsInvalid: false,
    designOptionsInvalid: false,
    accessOptionsInvalid: false,
    routesOptionsInvalid: false,
    settingsOptionsInvalid: false
  }
}
