<template>
  <div class="page-cta">
    <div v-if="!canApplyTrial" class="page-cta__container">
      <h2 class="page-cta__title">Looks like you already have some pro licenses!</h2>
      <p>Or your trial hasn't finished yet. Either way, you already have access to our Pro feautures, just make sure that your website is Pro in your workspace.</p>
      <p>Need more licenses? You can always get some in your Billing Settings.</p>
      <UiBtn
        uppercase
        wide
        class="page-cta__btn"
        @click="$router.push({name: 'account-billing'})"
      >Go to Billing Settings</UiBtn>
    </div>
    <div v-if="success" class="page-cta__container">
      <h2 class="page-cta__title">🎉 Yay! You are on the fast trial now!</h2>
      <p>Your trial will end <b>in 3 days</b>. </p>

      <p>During this period you have three Pro licenses. Upgrade your websites and test the Pro features.</p>
      <p>You can also check the list of our recent updates <a href="https://features.spreadsimple.com/spreadsimple/updates">here</a>.</p>
      <UiBtn
        uppercase
        wide
        class="page-cta__btn"
        @click="$router.push({name: 'spread-views'})"
      >Start testing</UiBtn>
    </div>
    <div v-if="failure" class="page-cta__container">
      <h2 class="page-cta__title">Oops, your fast trial is not available now</h2>
      <p>Looks like it’s been <b>less than 30 days</b> since your last trial ended or you deleted this account just recently. </p>

      <p>Either way, you can start a fast trial once a month, so come back later</p>

      <UiBtn
        uppercase
        wide
        class="page-cta__btn"
        @click="$router.push({name: 'spread-views'})"
      >OK</UiBtn>
    </div>
  </div>

</template>
<script>
import UiBtn from '@/components/ui-btn'
import api from '@/services/api/'
import { mapState } from 'vuex'
export default {
  name: 'PageRepeatTrial',
  components: {
    UiBtn
  },
  metaInfo: {
    title: 'Repeat Trial'
  },
  data () {
    return {
      success: false,
      failure: false
    }
  },
  computed: {
    ...mapState('app', [
      'userData'
    ]),
    canApplyTrial () {
      if (this.userData &&
          this.userData.deal === 'free' &&
          this.userData.deal !== 'appsumo'
      ) return true
      return false
    }
  },
  methods: {
    async tryApplyTrial () {
      if (this.canApplyTrial) {
        try {
          await api.applyFlashTrial()
          this.success = true
          this.$trackEvent({
            'event': 'startFastTrial'
          })
        } catch (e) {
          this.failure = true
        }
      }
    }
  },
  async created () {
    await this.tryApplyTrial()
  }
}

</script>
