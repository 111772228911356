<template>
  <div class="iframe-code">
      <div class="iframe-code__left-side">
        <div class="settings-form__settings-wrap">
          <FormField label="Routing options">
            <v-select
              v-model="routingMode"
              :items="routingOptions"
              item-text="label"
              item-value="value"
              attach
              placeholder="Choose routing mode"
              menu-props="offsetY"
              append-icon="keyboard_arrow_down"
              >
            </v-select>
          </FormField>
        </div>
        <div class="settings-form__settings-wrap">
          <FormField
            label="Widget Path"
            hint="Leave empty to point widget to the home page"
          >
            <v-text-field
              v-model.trim="widgetPath"
              placeholder="/"
            />
          </FormField>
        </div>
      </div>
      <div class="iframe-code__right-side">
        <textarea
          ref="code"
          readonly
          class="iframe-code__textarea"
          :value="widgetCode"
          >
        </textarea>
        <div class="iframe-code__footer">
          <button
            class="iframe-code__copy-button"
            @click.prevent="copyToClipboard"
          >
            {{ $t('global.copy') }}
            <i class="icon icon-duplicate2 iframe-code__copy-button-icon"></i>
          </button>
        </div>
      </div>
      <div class="tile-constructor__hint" style="margin-bottom: -10px; margin-top: 0px;">
        <a target="_blank" href="https://help.spreadsimple.com/en/article/embedding-spreadsimple-as-a-widget-into-other-platforms-xorqqu/">Help: Embedding as a widget</a>
      </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import FormField from '@/components/form-field'
export default {
  name: 'WidgetCode',
  components: {
    FormField
  },
  props: {
    domain: {
      type: String,
      required: true
    },
    options: {
      type: String
    }
  },
  data () {
    return {
      viewerBaseUrl: process.env.VUE_APP_VIEWER_APP_BASE_URL,
      routingOptions: [{
        'label': 'Use routing',
        'value': 'hash'
      }, {
        'label': 'No routing',
        'value': 'none'
      }],
      routingMode: 'hash',
      widgetPath: ''
    }
  },
  computed: {
    ...mapState('app', [
      'currentSpreadViewId'
    ]),
    widgetCode () {
      // eslint-disable-next-line
      return `<script src="${this.viewerBaseUrl}/js/widget.js"><\/script>\n<div ${this.widgetSettings}></div>`
    },
    widgetSettings () {
      let settings = 'data-ss-widget'
      settings = settings.concat(` data-id="${this.currentSpreadViewId}"`)

      if (!this.isEmptyWidgetPath) {
        settings = settings.concat(` data-path="${this.widgetPath}"`)
      }

      if (this.routingMode !== 'hash') {
        settings = settings.concat(` data-routing="${this.routingMode}"`)
      }

      return settings
    },

    isEmptyWidgetPath () {
      return this.widgetPath.length === 0
    }
  },
  watch: {
    widgetPath (value) {
      if (this.validURL(value)) {
        const url = new URL(value)
        if (url.hash && url.hash.startsWith('#/')) {
          this.widgetPath = url.pathname + url.hash.split('#/')[1]
        } else {
          this.widgetPath = url.pathname + url.search
        }
      } else if (value.startsWith(this.domain)) {
        this.widgetPath = value.split(this.domain)[1]
      } else if (value.length && !value.startsWith('/')) {
        this.widgetPath = `/${value}`
      }
    }
  },
  methods: {
    copyToClipboard () {
      let code = this.$refs.code
      code.select()
      document.execCommand('copy')
    },
    validURL (str) {
      let url
      try {
        url = new URL(str)
      } catch (_) {
        return false
      }
      return url.protocol === 'http:' || url.protocol === 'https:'
    }
  }
}
</script>
