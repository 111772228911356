var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ProOptionWrapper", { attrs: { featureName: "itemVariations" } }, [
    _c("div", { staticClass: "content-form__switch-field" }, [
      _c("div", { staticClass: "content-form__switcher-wrap" }, [
        _c("div", { staticClass: "content-form__switcher-label" }, [
          _vm._v("Enable item variations")
        ]),
        _c(
          "label",
          {
            staticClass: "switcher",
            class: { selected: _vm.variantsState.enabled }
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.variantsState.enabled,
                  expression: "variantsState.enabled"
                }
              ],
              staticClass: "switcher__input",
              attrs: { type: "checkbox", value: "tiles" },
              domProps: {
                checked: Array.isArray(_vm.variantsState.enabled)
                  ? _vm._i(_vm.variantsState.enabled, "tiles") > -1
                  : _vm.variantsState.enabled
              },
              on: {
                change: [
                  function($event) {
                    var $$a = _vm.variantsState.enabled,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = "tiles",
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.variantsState,
                            "enabled",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.variantsState,
                            "enabled",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.variantsState, "enabled", $$c)
                    }
                  },
                  function($event) {
                    return _vm.$trackFeature(
                      "content",
                      "variants",
                      _vm.variantsState.enabled
                    )
                  }
                ]
              }
            }),
            _c("span", { staticClass: "switcher__indicator" })
          ]
        )
      ]),
      _vm.variantsState.enabled
        ? _c(
            "div",
            {
              staticClass:
                "content-form__switch-hidden-field aside-select paddingless"
            },
            [
              _c("div", { staticClass: "sorting-constructor__highlighted" }, [
                _c("div", { staticClass: "sorting-constructor__item" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "sorting-constructor__select aside-select paddingless"
                    },
                    [
                      _c(
                        "h6",
                        { staticClass: "sorting-constructor__item-label" },
                        [_vm._v("Group by column")]
                      ),
                      _c("v-select", {
                        attrs: {
                          attach: "",
                          clearable: "",
                          "menu-props": "offsetY",
                          items: _vm.mappedCols,
                          "item-text": "label",
                          "item-value": "value",
                          "append-icon": "keyboard_arrow_down"
                        },
                        model: {
                          value: _vm.variantsState.variantsGroupBy.id,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.variantsState.variantsGroupBy,
                              "id",
                              $$v
                            )
                          },
                          expression: "variantsState.variantsGroupBy.id"
                        }
                      }),
                      _vm.variantsGroupByValidationError
                        ? _c(
                            "p",
                            {
                              staticClass: "sorting-constructor__select-error"
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("global.fieldIsRequired")) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "a",
                        {
                          staticClass: "sorting-constructor__link",
                          attrs: {
                            target: "_blank",
                            href:
                              "https://help.spreadsimple.com/en/article/item-variations-sylxt5/"
                          }
                        },
                        [_vm._v("Help: How to use variations?")]
                      )
                    ],
                    1
                  )
                ]),
                _vm.variantsState.variantsGroupBy.id
                  ? _c("div", [
                      _c("div", { staticClass: "sorting-constructor__item" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "sorting-constructor__select aside-select"
                          },
                          [
                            _c(
                              "h6",
                              {
                                staticClass: "sorting-constructor__item-label"
                              },
                              [_vm._v("Variations options")]
                            ),
                            _c("v-select", {
                              attrs: {
                                items: _vm.mappedCols,
                                "item-text": "label",
                                "item-value": "value",
                                attach: "",
                                "menu-props": "offsetY",
                                chips: "",
                                "deletable-chips": "",
                                multiple: "",
                                "append-icon": "keyboard_arrow_down"
                              },
                              model: {
                                value: _vm.variantsState.variantsOptions.ids,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.variantsState.variantsOptions,
                                    "ids",
                                    $$v
                                  )
                                },
                                expression: "variantsState.variantsOptions.ids"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm.variantsState.variantsOptions.ids.length
                        ? _c(
                            "div",
                            { staticClass: "content-form__switch-field" },
                            [
                              _c(
                                "div",
                                { staticClass: "content-form__switcher-wrap" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "content-form__switcher-label"
                                    },
                                    [_vm._v("Group cards in list")]
                                  ),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "switcher",
                                      class: {
                                        selected:
                                          _vm.variantsState.variantsGroupBy
                                            .groupCards
                                      }
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.variantsState.variantsGroupBy
                                                .groupCards,
                                            expression:
                                              "variantsState.variantsGroupBy.groupCards"
                                          }
                                        ],
                                        staticClass: "switcher__input",
                                        attrs: { type: "checkbox" },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.variantsState.variantsGroupBy
                                              .groupCards
                                          )
                                            ? _vm._i(
                                                _vm.variantsState
                                                  .variantsGroupBy.groupCards,
                                                null
                                              ) > -1
                                            : _vm.variantsState.variantsGroupBy
                                                .groupCards
                                        },
                                        on: {
                                          change: [
                                            function($event) {
                                              var $$a =
                                                  _vm.variantsState
                                                    .variantsGroupBy.groupCards,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.variantsState
                                                        .variantsGroupBy,
                                                      "groupCards",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.variantsState
                                                        .variantsGroupBy,
                                                      "groupCards",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.variantsState
                                                    .variantsGroupBy,
                                                  "groupCards",
                                                  $$c
                                                )
                                              }
                                            },
                                            function($event) {
                                              return _vm.$trackFeature(
                                                "content",
                                                "variantsGroupCards",
                                                _vm.variantsState
                                                  .variantsGroupBy.groupCards
                                              )
                                            }
                                          ]
                                        }
                                      }),
                                      _c("span", {
                                        staticClass: "switcher__indicator"
                                      })
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      false
                        ? _c(
                            "div",
                            { staticClass: "content-form__switch-field" },
                            [
                              _c(
                                "div",
                                { staticClass: "content-form__switcher-wrap" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "content-form__switcher-label"
                                    },
                                    [_vm._v("Show variants options in cards")]
                                  ),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "switcher",
                                      class: {
                                        selected:
                                          _vm.variantsState.variantsOptions
                                            .showVariantsOptionsInCards
                                      }
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.variantsState.variantsOptions
                                                .showVariantsOptionsInCards,
                                            expression:
                                              "variantsState.variantsOptions.showVariantsOptionsInCards"
                                          }
                                        ],
                                        staticClass: "switcher__input",
                                        attrs: { type: "checkbox" },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.variantsState.variantsOptions
                                              .showVariantsOptionsInCards
                                          )
                                            ? _vm._i(
                                                _vm.variantsState
                                                  .variantsOptions
                                                  .showVariantsOptionsInCards,
                                                null
                                              ) > -1
                                            : _vm.variantsState.variantsOptions
                                                .showVariantsOptionsInCards
                                        },
                                        on: {
                                          change: function($event) {
                                            var $$a =
                                                _vm.variantsState
                                                  .variantsOptions
                                                  .showVariantsOptionsInCards,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.variantsState
                                                      .variantsOptions,
                                                    "showVariantsOptionsInCards",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.variantsState
                                                      .variantsOptions,
                                                    "showVariantsOptionsInCards",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.variantsState
                                                  .variantsOptions,
                                                "showVariantsOptionsInCards",
                                                $$c
                                              )
                                            }
                                          }
                                        }
                                      }),
                                      _c("span", {
                                        staticClass: "switcher__indicator"
                                      })
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e()
                    ])
                  : _vm._e()
              ])
            ]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }