import axios from 'axios'
import { encode } from 'js-base64'

export const validateSheetUrl = (url) => {
  if (typeof url !== 'string') return false
  if (!url.includes('//docs.google.com/spreadsheets/d/')) return false
  return true
}

export const getIdFromUrl = (url) => {
  if (typeof url !== 'string') return ''
  const parts = url.match(/\/d\/(.+)\//)
  if (parts == null || parts.length < 2) {
    return ''
  } else {
    return parts[1]
  }
}

export const setRequestOptions = (spreadViewData, rowsLimit) => {
  return {
    rowsLimit: rowsLimit,
    dealType: spreadViewData.dealType,
    dynamicData: {
      sheetHash: spreadViewData.sheetHash || 0,
      SCPTableLatestUpdateTimestamp: spreadViewData?.SCPTableLatestUpdateTimestamp || 0
    },
    search: spreadViewData.options.search ? {
      enabled: spreadViewData.options.search.enabled,
      columns: spreadViewData.options.search.searchByColumns
    } : {},
    sorting: spreadViewData.options.sorting ? {
      enabled: spreadViewData.options.sorting.enabled,
      shuffle: false
      // shuffle: spreadViewData.options.sorting.shuffleRows // SHUFFLE IS DISABLED ON SERVER SIDE
    } : {},
    variants: spreadViewData.options.variants ? {
      enabled: spreadViewData.options.variants.enabled,
      groupCards: spreadViewData.options.variants.variantsGroupBy.groupCards,
      id: spreadViewData.options.variants.variantsGroupBy.id,
      options: spreadViewData.options.variants.variantsOptions
    } : {},
    pagination: spreadViewData.options.pagination ? {
      enabled: spreadViewData.options.pagination.enabled,
      itemsPerPage: spreadViewData.options.pagination.itemsPerPage
    } : {},
    filters: spreadViewData.options.filters ? {
      enabled: spreadViewData.options.filters.enabled,
      values: spreadViewData.options.filters.values
    } : {},
    mapView: (spreadViewData.options.viewer && spreadViewData.options.viewer.mapView) ? {
      enabled: spreadViewData.options.viewer.mapView.enabled,
      id: spreadViewData.options.viewer.mapView.markerColId,
      markerType: spreadViewData.options.viewer.mapView.markerType,
      imageColId: spreadViewData.options.viewer.mapView.markerImageColId
    } : {}
  }
}

export async function sheetContentPipelineRead({ sid = '', query = null, spreadViewData = null, subRoute = null, shouldAwait = false, shouldRewriteTable = false, accessRulesEnabled = false, isUserLoggedIn = false, accessToken = '', websiteId = null, rowsLimit = 1 }) {
  const requestOptions = setRequestOptions(spreadViewData, rowsLimit)
  const encodedRequestOptions = encodeURIComponent(btoa(JSON.stringify(requestOptions || {})))
  const queryOptions = encodeURIComponent(encode(JSON.stringify(query || {})))
  try {
    if (!sid) return null
    const url = accessRulesEnabled ? `${process.env.VUE_APP_API_BASE}/website-access/${websiteId}` : `${process.env.VUE_APP_SHEET_CACHE_BASE}`
    const headers = accessRulesEnabled && isUserLoggedIn ? { Authorization: `Bearer ${accessToken}` } : {}
    let { data } = await axios.get(`${url}/sheet/${sid}${subRoute ? `/${subRoute}/` : ''}?query=${queryOptions}&options=${encodedRequestOptions}`, {
      headers
    })
    // if response weight is over 6Mb response will be provided as a S3 link to json
    // we need to use that json file as a response
    if (data.s3Url) {
      try {
        const jsonData = await axios.get(data.s3Url, {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        })
        data = jsonData.data
      } catch (e) {
        console.warn(e)
      }
    }

    return Object.assign({},
      subRoute === 'scheme' ? {
        cols: data.table.cols,
        // @PREV_SCP_VERSION
        // filtersValues: data.table.filtersValues,
        // variantsOptions: data.table.variantsOptions,
        totalItems: data.totalRows
      } : null,
      subRoute === '' ? {
        rows: subRoute ? [] : data.table.rows,
        queriedFilterValues: data.table.queriedFilterValues,
        totalQueriedItems: data.totalQueriedRows
      } : null,
      subRoute === 'markers' ? {
        markers: data.table.markers,
        queriedFilterValues: data.table.queriedFilterValues,
        totalMarkers: data.totalQueriedRows
      } : null,
      // @NEW_SCP_VERSION
      subRoute === 'variants' ? {
        variantsOptions: data.table.variantsOptions
      } : null,
      subRoute === 'filters' ? {
        filtersValues: data.table.filtersValues
      } : null
    )
  } catch ({ response }) {
    return response.data
  } finally {
    if (sid && shouldRewriteTable) {
      sheetContentPipelineWrite({ sid, spreadViewData, shouldAwait })
    }
  }
}

export const createEncodedWriteOptions = (options) => {
  return encodeURIComponent(btoa(JSON.stringify(options.viewer.singleItemOptions ? {
    enableSingleItemView: options.viewer.enableSingleItemView,
    singleItemOptions: {
      url: {
        id: options.viewer.singleItemOptions.url.id
      }
    }
  } : {})))
}

export async function sheetContentPipelineWrite({ sid = '', spreadViewData = null, shouldAwait = false }) {
  const routingOptions = createEncodedWriteOptions(spreadViewData.options)
  try {
    const { data } = await axios.post(`${process.env.VUE_APP_API_BASE}/sheet/${sid}?options=${routingOptions}${shouldAwait ? '&await' : ''}`)
    return data
  } catch ({ response }) {
    return response.data
  }
}
export async function sheetContentRoutesWrite({ sid = '', spreadViewData = null }) {
  const routingOptions = createEncodedWriteOptions(spreadViewData.options)

  axios.post(`${process.env.VUE_APP_API_BASE}/sheet/${sid}/routes?options=${routingOptions}`)
}
