var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-form__highlighted" }, [
    _c(
      "div",
      { staticClass: "sorting-constructor__item" },
      [
        _c(
          "FormField",
          {
            staticClass: "side-form__item aside-input",
            attrs: {
              label:
                "Select the user groups column to grant access to the items"
            }
          },
          [
            _c(
              "v-select",
              {
                attrs: {
                  value: _vm.privateRow.id,
                  items: _vm.cols,
                  disabled: !_vm.accessRulesEnabled,
                  "item-text": "label",
                  "item-value": "id",
                  attach: "",
                  placeholder: !_vm.accessRulesEnabled
                    ? "Create or enable access rules"
                    : "Choose a column",
                  "menu-props": "offsetY",
                  clearable: "",
                  chips: "",
                  "append-icon": "keyboard_arrow_down"
                },
                on: { change: _vm.setPrivateRow }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "list-title",
                    attrs: { slot: "prepend-item" },
                    slot: "prepend-item"
                  },
                  [_vm._v(" " + _vm._s(""))]
                )
              ]
            )
          ],
          1
        ),
        _c(
          "a",
          {
            staticClass: "sorting-constructor__link",
            attrs: {
              target: "_blank",
              href:
                "https://help.spreadsimple.com/en/article/how-to-set-up-website-access-rules-1vre7ri/"
            }
          },
          [_vm._v("Help: How to set up website access rules?")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }