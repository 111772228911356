var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vue-select", {
    class: { clearless: _vm.clearable },
    attrs: {
      options: _vm.options,
      searchable: false,
      placeholder: _vm.placeholderValue,
      transition: "fade"
    },
    model: {
      value: _vm.selectedValue,
      callback: function($$v) {
        _vm.selectedValue = $$v
      },
      expression: "selectedValue"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }