var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "spreadviews" },
    [
      _c("div", { staticClass: "spreadviews__container" }, [
        _c(
          "div",
          { staticClass: "spreadviews__left" },
          [
            _c("WorkspaceDropdown", {
              staticClass: "spreadviews__left-workspaces-manage",
              on: { "on-ws-change": _vm.updatePageContent }
            }),
            _c("div", { staticClass: "spreadviews__search" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchQuery,
                    expression: "searchQuery"
                  }
                ],
                staticClass: "spreadviews__search-input",
                attrs: { type: "text", placeholder: "Search sites..." },
                domProps: { value: _vm.searchQuery },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.searchQuery = $event.target.value
                  }
                }
              }),
              _c("button", {
                staticClass: "spreadviews__search-btn icon-search-fat"
              })
            ]),
            !_vm.isLoading
              ? _c("AvailableWebsitesInfo", {
                  attrs: {
                    amountOfActiveSpreadViews: _vm.amountOfActiveSpreadViews,
                    amountOfArchivedSpreadViews: _vm.amountOfArchivedSpreadViews
                  }
                })
              : _vm._e(),
            _c(
              "div",
              { staticClass: "spreadviews__filters spreadviews-filters" },
              [
                _c("div", { staticClass: "spreadviews-filters__section" }, [
                  _vm._m(0),
                  _c(
                    "div",
                    { staticClass: "spreadviews-filters__list" },
                    _vm._l(_vm.websitesFilterOptions, function(item) {
                      return _c(
                        "div",
                        {
                          key: item.value,
                          staticClass: "spreadviews-filters__item",
                          class: {
                            "is-active": _vm.statusToFilter === item.value
                          },
                          on: {
                            click: function($event) {
                              _vm.statusToFilter = item.value
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "spreadviews-filters__item-left" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "spreadviews-filters__item-title"
                                },
                                [_vm._v(_vm._s(item.label))]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "spreadviews-filters__item-counter"
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.websitesCounter[item.value])
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    }),
                    0
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "spreadviews-filters__section" },
                  [
                    _c(
                      "div",
                      { staticClass: "spreadviews-filters__section-head" },
                      [
                        _c(
                          "h5",
                          { staticClass: "spreadviews-filters__section-title" },
                          [_vm._v("Tags")]
                        ),
                        _c("button", {
                          staticClass:
                            "spreadviews-filters__section-head-btn icon-plus",
                          on: {
                            click: function($event) {
                              _vm.showAddNewTagInput = true
                            }
                          }
                        })
                      ]
                    ),
                    _vm.showAddNewTagInput
                      ? _c(
                          "form",
                          {
                            staticClass: "spreadviews-filters__add-form",
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                                return _vm.createNewTag.apply(null, arguments)
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "spreadviews-filters__add-form-input-wrap"
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value: _vm.$v.newTag.$model,
                                      expression: "$v.newTag.$model",
                                      modifiers: { trim: true }
                                    }
                                  ],
                                  ref: "newTagInput",
                                  staticClass:
                                    "spreadviews-filters__add-form-input",
                                  attrs: {
                                    readonly: _vm.$async.createTag.$pending,
                                    type: "text",
                                    placeholder: "Enter new tag name..."
                                  },
                                  domProps: { value: _vm.$v.newTag.$model },
                                  on: {
                                    keydown: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "esc",
                                          27,
                                          $event.key,
                                          ["Esc", "Escape"]
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.closeNewTagForm()
                                    },
                                    blur: [
                                      function($event) {
                                        return _vm.closeNewTagForm()
                                      },
                                      function($event) {
                                        return _vm.$forceUpdate()
                                      }
                                    ],
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.$v.newTag,
                                        "$model",
                                        $event.target.value.trim()
                                      )
                                    }
                                  }
                                }),
                                _vm.$async.createTag.$pending
                                  ? _c("button", {
                                      staticClass:
                                        "spreadviews-filters__add-form-btn icon-loader",
                                      attrs: { type: "button", disabled: "" }
                                    })
                                  : _c("button", {
                                      staticClass:
                                        "spreadviews-filters__add-form-btn icon-circle-close",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function($event) {
                                          return _vm.closeNewTagForm()
                                        }
                                      }
                                    })
                              ]
                            ),
                            _vm.$v.newTag.$dirty && !_vm.$v.newTag.maxLength
                              ? _c(
                                  "p",
                                  {
                                    staticClass:
                                      "spreadviews-filters__add-form-msg"
                                  },
                                  [_vm._v("Max length is 30 symbols")]
                                )
                              : _vm._e(),
                            _vm.$v.newTag.$dirty && !_vm.$v.newTag.required
                              ? _c(
                                  "p",
                                  {
                                    staticClass:
                                      "spreadviews-filters__add-form-msg"
                                  },
                                  [_vm._v("Label shouldn`t be empty")]
                                )
                              : _vm._e(),
                            _vm.$v.newTag.$dirty &&
                            !_vm.$v.newTag.checkLabelUnique
                              ? _c(
                                  "p",
                                  {
                                    staticClass:
                                      "spreadviews-filters__add-form-msg"
                                  },
                                  [_vm._v("Label should be unique")]
                                )
                              : _vm._e()
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "draggable",
                      {
                        staticClass: "spreadviews-filters__list",
                        attrs: {
                          tag: "div",
                          handle: ".spreadviews-filters__item-drag-trigger",
                          draggable: ".is-draggable"
                        },
                        on: { change: _vm.onAllTagsOrderChange },
                        model: {
                          value: _vm.allTags,
                          callback: function($$v) {
                            _vm.allTags = $$v
                          },
                          expression: "allTags"
                        }
                      },
                      [
                        _vm.allTags.length
                          ? _c(
                              "div",
                              {
                                staticClass: "spreadviews-filters__item",
                                class: {
                                  "is-active": _vm.tagToFilter === "all"
                                },
                                attrs: { slot: "header" },
                                on: {
                                  click: function($event) {
                                    _vm.tagToFilter = "all"
                                  }
                                },
                                slot: "header"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "spreadviews-filters__item-left"
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "spreadviews-filters__item-title"
                                      },
                                      [_vm._v("All")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "spreadviews-filters__item-counter"
                                      },
                                      [_vm._v(_vm._s(_vm.viewsList.length))]
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._l(_vm.allTags, function(item) {
                          return _c(
                            "div",
                            {
                              key: item.id,
                              staticClass:
                                "spreadviews-filters__item is-draggable",
                              class: [
                                { "is-active": _vm.tagToFilter === item.id },
                                { "is-disabled": _vm.$async.deleteTag.$pending }
                              ]
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "spreadviews-filters__item-left",
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      _vm.tagToFilter = item.id
                                    }
                                  }
                                },
                                [
                                  _vm.editableTagId === item.id
                                    ? _c(
                                        "form",
                                        {
                                          staticClass:
                                            "spreadviews-filters__item-edit-form",
                                          on: {
                                            submit: function($event) {
                                              $event.preventDefault()
                                              return _vm.onTagNameEdit(item)
                                            }
                                          }
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.trim",
                                                value:
                                                  _vm.$v.updatedTagLabel.$model,
                                                expression:
                                                  "$v.updatedTagLabel.$model",
                                                modifiers: { trim: true }
                                              }
                                            ],
                                            ref: "tagEdit" + item.id,
                                            refInFor: true,
                                            staticClass:
                                              "spreadviews-filters__item-edit-input",
                                            attrs: {
                                              placeholder: item.label,
                                              type: "text"
                                            },
                                            domProps: {
                                              value:
                                                _vm.$v.updatedTagLabel.$model
                                            },
                                            on: {
                                              blur: [
                                                function($event) {
                                                  _vm.editableTagId = undefined
                                                },
                                                function($event) {
                                                  return _vm.$forceUpdate()
                                                }
                                              ],
                                              keydown: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "esc",
                                                    27,
                                                    $event.key,
                                                    ["Esc", "Escape"]
                                                  )
                                                ) {
                                                  return null
                                                }
                                                _vm.editableTagId = undefined
                                              },
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.$v.updatedTagLabel,
                                                  "$model",
                                                  $event.target.value.trim()
                                                )
                                              }
                                            }
                                          }),
                                          _vm.$v.updatedTagLabel.$dirty &&
                                          !_vm.$v.updatedTagLabel.maxLength
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "spreadviews-filters__item-edit-msg"
                                                },
                                                [
                                                  _vm._v(
                                                    "Max length is 30 symbols"
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.$v.updatedTagLabel.$dirty &&
                                          !_vm.$v.updatedTagLabel.required
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "spreadviews-filters__item-edit-msg"
                                                },
                                                [
                                                  _vm._v(
                                                    "Label shouldn`t be empty"
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.$v.updatedTagLabel.$dirty &&
                                          !_vm.$v.updatedTagLabel
                                            .checkLabelUnique
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "spreadviews-filters__item-edit-msg"
                                                },
                                                [
                                                  _vm._v(
                                                    "Label should be unique"
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    : [
                                        _c("span", {
                                          staticClass:
                                            "spreadviews-filters__item-drag-trigger icon-draggable"
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "spreadviews-filters__item-title"
                                          },
                                          [_vm._v(_vm._s(item.label))]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "spreadviews-filters__item-counter"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.tagsCounter[item.id]
                                                  ? _vm.tagsCounter[item.id]
                                                      .counter
                                                  : 0
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                ],
                                2
                              ),
                              _c(
                                "el-dropdown",
                                {
                                  staticClass:
                                    "spreadviews-filters__item-dropdown",
                                  attrs: {
                                    showTimeout: 100,
                                    placement: "right-start"
                                  },
                                  on: {
                                    command: function(payload) {
                                      return _vm.handleDropdownCommand(payload)
                                    }
                                  }
                                },
                                [
                                  _c("button", {
                                    staticClass:
                                      "spreadviews-filters__item-dropdown-trigger icon-dots"
                                  }),
                                  _c(
                                    "el-dropdown-menu",
                                    {
                                      staticClass:
                                        "spreadviews-filters__item-dropdown-menu",
                                      attrs: { slot: "dropdown" },
                                      slot: "dropdown"
                                    },
                                    [
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          staticClass:
                                            "spreadviews-filters__item-dropdown-item",
                                          attrs: {
                                            command: {
                                              method: "onTagRename",
                                              tag: item
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    Rename\n                  "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          staticClass:
                                            "spreadviews-filters__item-dropdown-item is-danger",
                                          attrs: {
                                            command: {
                                              method: "onTagDelete",
                                              tag: item
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    Delete\n                  "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        _vm.allTags.length
                          ? _c(
                              "div",
                              {
                                staticClass: "spreadviews-filters__item",
                                class: {
                                  "is-active": _vm.tagToFilter === "no-tags"
                                },
                                attrs: { slot: "footer" },
                                on: {
                                  click: function($event) {
                                    _vm.tagToFilter = "no-tags"
                                  }
                                },
                                slot: "footer"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "spreadviews-filters__item-left"
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "spreadviews-filters__item-title"
                                      },
                                      [_vm._v("No tags")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "spreadviews-filters__item-counter"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.viewsList.filter(function(i) {
                                              return i.tags.length === 0
                                            }).length
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      2
                    )
                  ],
                  1
                )
              ]
            )
          ],
          1
        ),
        _c("div", { staticClass: "spreadviews__right" }, [
          _c(
            "div",
            { staticClass: "spreadviews__list-head" },
            [
              _c("h1", { staticClass: "spreadviews__title" }, [
                _vm._v(_vm._s(_vm.currentWorkspaceTitle) + " websites")
              ]),
              _c(
                "el-select",
                {
                  staticClass: "spreadviews__select",
                  attrs: {
                    placeholder: "",
                    "popper-class": "spreadviews__select-menu"
                  },
                  model: {
                    value: _vm.websitesOrder,
                    callback: function($$v) {
                      _vm.websitesOrder = $$v
                    },
                    expression: "websitesOrder"
                  }
                },
                _vm._l(_vm.orderOptions, function(option) {
                  return _c("el-option", {
                    key: option.value,
                    attrs: { label: option.label, value: option.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm.statusToFilter !== "archived"
            ? _c(
                "div",
                { staticClass: "spreadviews__list" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "spreadviews__list-item spreadviews__add",
                      on: {
                        click: _vm.goToAddViewPage,
                        mouseenter: function($event) {
                          _vm.addButtonHovered = true
                        },
                        mouseleave: function($event) {
                          _vm.addButtonHovered = false
                        }
                      }
                    },
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "spreadviews__add-bg",
                          attrs: {
                            width: "100%",
                            height: "100%",
                            xmlns: "http://www.w3.org/2000/svg"
                          }
                        },
                        [
                          _c("rect", {
                            attrs: {
                              width: "100%",
                              height: "100%",
                              stroke: "#A8B7CF",
                              fill: "none",
                              "stroke-width": "2",
                              "stroke-dasharray": "5 13",
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round"
                            }
                          })
                        ]
                      ),
                      _c("div", { staticClass: "spreadviews__add-circle" }),
                      _c("i", {
                        staticClass: "spreadviews__add-icon icon-plus"
                      }),
                      _c(
                        "transition",
                        {
                          attrs: {
                            name: "spreadviews-add-title-animation",
                            mode: "out-in"
                          }
                        },
                        [
                          _vm.addButtonHovered
                            ? _c(
                                "p",
                                {
                                  key: "1",
                                  staticClass: "spreadviews__add-title"
                                },
                                [_vm._v("Add new website")]
                              )
                            : _c(
                                "p",
                                {
                                  key: "2",
                                  staticClass: "spreadviews__add-title"
                                },
                                [_vm._v("New website")]
                              )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._l(_vm.activeViews, function(item) {
                    return _c("SingleSpreadView", {
                      key: item.id,
                      staticClass: "spreadviews__list-item",
                      attrs: {
                        item: item,
                        allTags: _vm.allTags,
                        activeEditor: _vm.activeEditors.find(function(i) {
                          return i.spreadViewId === item.id
                        })
                      },
                      on: {
                        "title-updated": function(name) {
                          return _vm.updateSpreadViewName(item, name)
                        },
                        duplicate: function($event) {
                          return _vm.duplicateSpreadView(item)
                        },
                        delete: function($event) {
                          return _vm.deleteCurrentView(item)
                        },
                        "add-new-tag": function(payload) {
                          return _vm.addNewTagToWebsite(payload)
                        },
                        "update-website-tags": function(payload) {
                          return _vm.updateWebsiteTags(payload)
                        },
                        "change-view-published-status": function($event) {
                          return _vm.changeViewPublishedStatus($event)
                        },
                        "change-view-archived-status": function($event) {
                          return _vm.changeViewArchivedStatus($event)
                        }
                      }
                    })
                  })
                ],
                2
              )
            : _vm._e(),
          _vm.archivedViews.length &&
          (_vm.statusToFilter === "all" || _vm.statusToFilter === "archived")
            ? _c("div", { staticClass: "spreadviews__list-archive" }, [
                _vm._m(1),
                _c(
                  "div",
                  { staticClass: "spreadviews__list" },
                  _vm._l(_vm.archivedViews, function(item) {
                    return _c("SingleSpreadView", {
                      key: item.id,
                      staticClass: "spreadviews__list-item",
                      attrs: {
                        item: item,
                        allTags: _vm.allTags,
                        activeEditor: _vm.activeEditors.find(function(i) {
                          return i.spreadViewId === item.id
                        })
                      },
                      on: {
                        "title-updated": function(name) {
                          return _vm.updateSpreadViewName(item, name)
                        },
                        duplicate: function($event) {
                          return _vm.duplicateSpreadView(item)
                        },
                        delete: function($event) {
                          return _vm.deleteCurrentView(item)
                        },
                        "add-new-tag": function(payload) {
                          return _vm.addNewTagToWebsite(payload)
                        },
                        "update-website-tags": function(payload) {
                          return _vm.updateWebsiteTags(payload)
                        },
                        "change-view-published-status": function($event) {
                          return _vm.changeViewPublishedStatus($event)
                        },
                        "change-view-archived-status": function($event) {
                          return _vm.changeViewArchivedStatus($event)
                        }
                      }
                    })
                  }),
                  1
                )
              ])
            : _vm._e()
        ])
      ]),
      false ? _c("div", { staticClass: "spreadviews__bg" }) : _vm._e(),
      _c("Preloader", { attrs: { loading: _vm.isLoading } }),
      _c(
        "Modal",
        {
          staticClass: "confirmation-popup",
          attrs: {
            modalName: "You reached your website quantity limit",
            closeOnPressEscBtn: "",
            alignTitleLeft: ""
          },
          on: { input: _vm.closeModalOverLimitSpreadViews },
          model: {
            value: _vm.modalOverLimitSpreadViews,
            callback: function($$v) {
              _vm.modalOverLimitSpreadViews = $$v
            },
            expression: "modalOverLimitSpreadViews"
          }
        },
        [
          _c("div", { staticClass: "confirmation-popup__content" }, [
            _c("p", [
              _vm._v(
                "You can’t add more websites to your workspace.\n        "
              ),
              _c(
                "a",
                {
                  staticClass: "over-limit-modal__link",
                  attrs: {
                    href:
                      "https://help.spreadsimple.com/en/article/website-quantity-limitation-iul9ct/",
                    target: "_blank",
                    rel: "nofollow"
                  }
                },
                [
                  _vm._v(
                    "\n            Learn more about the website quantity limit.\n        "
                  )
                ]
              )
            ]),
            _c("p", [
              _vm._v("To create a new website, do one of the following steps: ")
            ]),
            _c("ul", [
              _c("li", [_vm._v("delete one of your existing websites")]),
              _c("li", [
                _vm._v(
                  "update your subscription plan in the Billing Settings to increase the number of licenses"
                )
              ])
            ])
          ]),
          _c(
            "div",
            { staticClass: "confirmation-popup__btns" },
            [
              _c(
                "UiBtn",
                {
                  attrs: { type: "secondary" },
                  on: { click: _vm.closeModalOverLimitSpreadViews }
                },
                [_vm._v("OK")]
              ),
              _c(
                "UiBtn",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.$router.push({ name: "account-billing" })
                    }
                  }
                },
                [_vm._v("UPDATE SUBSCRIPTION")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "spreadviews-filters__section-head" }, [
      _c("h5", { staticClass: "spreadviews-filters__section-title" }, [
        _vm._v("Websites")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "spreadviews__list-head" }, [
      _c("h2", { staticClass: "spreadviews__title" }, [
        _vm._v("Archived websites")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }