<template>
  <form class="fields-builder" @submit.prevent="submit">
    <div class="fields-builder__items">
      <!-- field type -->

      <div class="fields-builder__item">
        <FormField
          :label="`Field type ${fieldData.isBuiltIn ? '(read only)' : ''}`"
          :messages="$v.fieldData.type.$dirty && $v.fieldData.type.$invalid ? ['Field is required'] : []"
          class="fields-builder__item-control"
        >
          <v-select
            v-model="$v.fieldData.type.$model"
            :items="fieldTypes"
            :readonly="fieldData.isBuiltIn"
            item-text="label"
            item-value="type"
            menu-props="offsetY"
            placeholder="Choose field type"
            append-icon="keyboard_arrow_down"
          >
          </v-select>
        </FormField>
      </div>

      <template v-if="fieldData.type === 'markdown'">

        <!-- field label -->
        <div class="fields-builder__item">
          <FormField
            label="Label"
            :messages="$v.fieldData.label.$dirty && $v.fieldData.label.$invalid ? ['Field is required'] : []"
            class="fields-builder__item-control"
          >
            <v-text-field
              v-model="$v.fieldData.label.$model"
              placeholder="Enter field label"
              @change="setSysNameFromLabel"
            ></v-text-field>
          </FormField>
        </div>

        <!-- description block -->
        <div class="fields-builder__item">
          <FormField label="Description" class="fields-builder__item-control">
            <MarkdownComponent
              v-model="fieldData.default"
              :preview="false"
              leftToolbar="bold italic strikethrough link"
            />
          </FormField>
        </div>

        <!-- field sys name -->
        <div class="fields-builder__item">
          <FormField
            :label="`Field system name ${fieldData.isBuiltIn ? '(read only)' : ''}`"
            :hint="`Must be unique. ${ fieldData.name.length ? `Replacing tag for this field will be {{formFields.${fieldData.name}}}` : ''}`"
            :messages="$v.fieldData.name.$dirty && $v.fieldData.name.$invalid ? systemNameErrorMessages : []"
            class="fields-builder__item-control"
          >
            <v-text-field
              :value="$v.fieldData.name.$model"
              :readonly="fieldData.isBuiltIn"
              placeholder="Enter system name"
              @change="setSysNameManually"
            ></v-text-field>
          </FormField>
        </div>

        <!-- field width -->
        <div class="fields-builder__item">
          <FormField
            label="Field width"
            class="fields-builder__item-control"
          >
            <v-select
              v-model="fieldData.width"
              :items="widthOptions"
              item-text="label"
              item-value="value"
              menu-props="offsetY"
              placeholder="Choose field width"
              append-icon="keyboard_arrow_down"
            >
            </v-select>
          </FormField>
        </div>
      </template>

      <template v-else class="fields-builder__items">
        <!-- field label -->
        <div class="fields-builder__item">
          <FormField
            label="Label"
            :messages="$v.fieldData.label.$dirty && $v.fieldData.label.$invalid ? ['Field is required'] : []"
            class="fields-builder__item-control"
          >
            <v-text-field
              v-model="$v.fieldData.label.$model"
              placeholder="Enter field label"
              @change="setSysNameFromLabel"
            ></v-text-field>
          </FormField>
        </div>

        <!-- field options -->
        <div class="fields-builder__item">
          <div
            v-if="['select', 'multiselect', 'radio', 'checkbox'].includes(fieldData.type)"
            class="fields-builder__draggable"
          >
            <!-- check this v-if -->
            <div class="n-form-field__label">
              Options
            </div>
            <draggable
              v-if="fieldData.options.length"
              v-model="fieldData.options"
              tag="div"
              handle=".draggable__item-trigger"
              class="draggable"
            >
              <div
                v-for="(option, index) in $v.fieldData.options.$each.$iter"
                :key="index"
                class="draggable__item hover-disabled"
                >
                <div class="draggable__item-trigger icon-draggable"></div>
                <div class="draggable__item-input-wrap">
                  <FormField :messages="option.value.$dirty && option.value.$invalid ? ['Required']: []">
                    <v-text-field
                      :value="option.value.$model"
                      placeholder="Enter option here"
                      @input="val => setOptionValue(val, index)"
                      @keydown.tab.prevent="addOption"
                    ></v-text-field>
                  </FormField>
                </div>
                <div class="draggable__item-controls fields-builder__control-toolbar">
                  <div class="fields-builder__control-toolbar-item">
                    <i
                      class="icon-circle-close"
                      @click="deleteOption(index)"
                    ></i>
                  </div>
                  <div class="fields-builder__control-toolbar-item">
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="Select default item"
                      placement="top"
                    >
                      <label class="radio draggable__item-control-btn">
                        <input
                          :value="fieldData.options[index].default"
                          :checked="fieldData.options[index].default"
                          type="checkbox"
                          class="radio__input"
                          @input="updateDefaultOption(index)"
                        >
                        <div class="radio__indicator"></div>
                      </label>
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </draggable>
            <button
              type="button"
              class="fields-builder__item-add-btn for-draggable-items"
              @click="addOption"
            >
              + Add new option
            </button>
          </div>
          <div class="fields-builder__item-msg is-error is-flex-row for-draggable">
            <p
              v-if="$v.fieldData.options.$dirty && $v.fieldData.options.$invalid && !$v.fieldData.options.required"
            >Add options to the list</p>
            <p
              v-if="$v.fieldData.options.$dirty && $v.fieldData.options.$invalid && !$v.fieldData.options.minOptionsLength"
            >Min 2 options required</p>
          </div>
        </div>

        <!-- default value for filed without options -->
        <div class="fields-builder__item">
          <FormField
            v-if="['text', 'number', 'email', 'textarea'].includes(fieldData.type)"
            label="Default value"
            hint="Optional"
            class="fields-builder__item-control"
          >
            <v-text-field
              v-model="fieldData.default"
              placeholder="Enter default value"
            ></v-text-field>
          </FormField>
        </div>

        <!-- field placeholder -->
        <div class="fields-builder__item">
          <FormField
            v-if="!['radio', 'checkbox', 'time'].includes(fieldData.type)"
            label="Placeholder"
            hint="Optional"
            class="fields-builder__item-control"
          >
            <v-text-field
              v-model="fieldData.placeholder"
              placeholder="Enter placeholder"
            ></v-text-field>
          </FormField>
        </div>

        <!-- field hint -->
        <div class="fields-builder__item">
          <FormField
            label="Hint"
            hint="Optional"
            class="fields-builder__item-control"
          >
            <v-text-field
              v-model="fieldData.hint"
              placeholder="Enter field hint"
            ></v-text-field>
          </FormField>
        </div>

        <!-- field sys name -->
        <div class="fields-builder__item">
          <FormField
            :label="`Field system name ${fieldData.isBuiltIn ? '(read only)' : ''}`"
            :hint="`Must be unique. ${ fieldData.name.length ? `Replacing tag for this field will be {{formFields.${fieldData.name}}}` : ''}`"
            :messages="$v.fieldData.name.$dirty && $v.fieldData.name.$invalid ? systemNameErrorMessages : []"
            class="fields-builder__item-control"
          >
            <v-text-field
              :value="$v.fieldData.name.$model"
              :readonly="fieldData.isBuiltIn"
              placeholder="Enter system name"
              @change="setSysNameManually"
            ></v-text-field>
          </FormField>
        </div>

        <!-- field width -->
        <div class="fields-builder__item">
          <FormField
            label="Field width"
            class="fields-builder__item-control"
          >
            <v-select
              v-model="fieldData.width"
              :items="widthOptions"
              item-text="label"
              item-value="value"
              menu-props="offsetY"
              placeholder="Choose field width"
              append-icon="keyboard_arrow_down"
            >
            </v-select>
          </FormField>
        </div>

        <!-- field read only -->
        <div class="fields-builder__item">
          <div
          v-if="['text', 'number', 'email', 'textarea'].includes(fieldData.type)"
          class="content-form__switcher-wrap">
            <div class="content-form__switcher-label">Make this field readonly</div>
            <label
              :class="{ 'selected': fieldData.readOnly }"
              class="switcher"
            >
              <input
                v-model="fieldData.readOnly"
                class="switcher__input"
                type="checkbox"
              >
              <span class="switcher__indicator"></span>
            </label>
          </div>
        </div>

        <div class="fields-builder__validations">
          <h3 class="fields-builder__validations-title">Validation rules</h3>
          <!-- validation rules list -->
          <div
            v-for="rule in $v.selectedValidations.$each.$iter"
            :key="rule.name"
            class="fields-builder__item"
          >
            <h5 class="fields-builder__item-title">
              {{rule.$model.label}}
              <i class="fields-builder__item-delete-btn icon-circle-close" @click="deleteValidationRule(rule.$model)"></i>
            </h5>
            <div class="fields-builder__item is-flex-row">
              <template v-if="rule.$model.numberOfArgs">
                <FormField
                  v-for="(val, index) in rule.$model.numberOfArgs"
                  :key="index"
                  class="fields-builder__item-control"
                  label="Validation value"
                  :messages="rule.args.$each.$iter[index].value.$dirty && rule.args.$each.$iter[index].value.$invalid ? ['Field is required'] : []"
                >
                  <v-text-field
                    :value="rule.$model.args[index].value"
                    :type="rule.$model.typeOfArgs"
                    placeholder="Enter value"
                    @input="(val) => rule.$model.args[index].value = val"
                  ></v-text-field>
                </FormField>
              </template>
              <FormField
                class="fields-builder__item-control"
                label="Error message"
              >
                <v-text-field
                  v-model="rule.$model.message"
                  placeholder="Enter error message"
                ></v-text-field>
              </FormField>
              <!-- <div class="fields-builder__control-toolbar">
                <div class="fields-builder__control-toolbar-item">
                  <i class="icon-circle-close" @click="deleteValidationRule(rule.$model)"></i>
                </div>
              </div> -->
            </div>
          </div>
          <!-- field validation rules -->
          <div class="fields-builder__item">
            <el-dropdown
              v-if="availiableValidations.length"
              trigger="click"
            >
              <span class="fields-builder__item-add-btn">+ Add validation rule</span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                v-for="rule in availiableValidations" :key="rule.name"
                @click.native="addValidationRule(rule)"
                >{{ rule.label }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </template>
    </div>

    <div class="fields-builder__footer">
      <div class="fields-builder__footer-btns">
        <button class="fields-builder__footer-btn btn btn--text-only" type="button" @click="$emit('cancel-submission')">Cancel</button>
        <button class="fields-builder__footer-btn btn" type="submit">{{value ? 'SAVE' : 'ADD'}}</button>
      </div>
      <p v-if="validationStatus.length" class="fields-builder__footer-msg">
        <i class="icon-circle-close fields-builder__footer-msg-icon"></i>
        {{validationStatus}}
      </p>
    </div>
  </form>
</template>
<script>
import Vue from 'vue'
import Vuelidate from 'vuelidate'
import FormField from '@/components/form-field'
import draggable from 'vuedraggable'
import MarkdownComponent from '@/components/markdown-component'
import { required, requiredIf } from 'vuelidate/lib/validators'
import { fieldSchema, fieldTypes, fieldWidth, fieldValidations, fieldValidationsRules } from './fieldParams.js'
import urlifyLib from 'urlify'
const urlify = urlifyLib.create({
  addEToUmlauts: true,
  szToSs: true,
  spaces: '-',
  nonPrintable: 'non-supported-symbol',
  toLower: true,
  trim: true
})
Vue.use(Vuelidate)
function checkNameUnique () {
  return this.isNameUnique
}
function minOptionsLength () {
  if (!['select', 'multiselect', 'radio'].includes(this.fieldData.type)) return true
  return this.fieldData.options.length >= 2
}
export default {
  name: 'FormFieldBuilder',
  components: {
    FormField,
    draggable,
    MarkdownComponent
  },
  props: {
    existingFormFields: {
      type: Array,
      required: true
    },
    value: {
      type: Object
    }
  },
  data () {
    return {
      fieldTypes,
      fieldWidth,
      fieldValidations,
      fieldValidationsRules,
      fieldData: null,
      selectedValidations: [],
      validationStatus: '',
      savedSysName: '',
      fieldNameWasUpdated: false
    }
  },

  validations: {
    fieldData: {
      label: { required },
      type: { required },
      name: {
        required,
        checkNameUnique
      },
      options: {
        required: requiredIf(function () {
          return this.fieldData.type === 'select' ||
               this.fieldData.type === 'multiselect' ||
               this.fieldData.type === 'radio' ||
               this.fieldData.type === 'checkbox'
        }),
        minOptionsLength,
        $each: {
          value: { required }
        }
      }
    },
    selectedValidations: {
      $each: {
        args: {
          $each: {
            value: { required }
          }
        }
      }
    }
  },
  computed: {
    validationsWithParams () {
      return this.addParamsToValidations(this.fieldValidations)
    },
    availiableValidations () {
      const validationsByType = this.validationsWithParams.filter((el) => el.availableForTypes.includes(this.fieldData.type))
      return validationsByType.reduce((acc, value) => {
        if (this.selectedValidations.some(el => el.name === value.name)) return acc
        acc.push(value)
        return acc
      }, [])
    },
    isNameUnique () {
      const fieldNames = this.existingFormFields.filter(el => el.name !== this.savedSysName).map(el => el.name)
      return !fieldNames.includes(this.fieldData.name)
    },
    systemNameErrorMessages () {
      const messages = []
      if (!this.$v.fieldData.name.required) messages.push('Field is required')
      if (!this.$v.fieldData.name.checkNameUnique) messages.push('This name was used for another field')
      return messages
    },
    widthOptions () {
      if (['date', 'time', 'markdown'].includes(this.fieldData.type)) {
        const acceptedWidths = ['6', '12']
        return this.fieldWidth.reduce((acc, item) => {
          if (!acceptedWidths.includes(item.value)) return acc
          acc.push(item)
          return acc
        }, [])
      } else {
        return this.fieldWidth
      }
    }
  },
  watch: {
    'fieldData.type': function (val, oldVal) {
      if (!oldVal) return
      if (
        ['multiselect', 'checkbox'].includes(oldVal) &&
        ['select', 'radio'].includes(val)
      ) {
        this.updateDefaultOption()
      }
      if (['multiselect', 'checkbox', 'select', 'radio'].includes(val)) {
        this.selectedValidations = this.selectedValidations.filter(el => this.validationsAvailableForSelect.includes(el.name))
      }
      if (
        ['multiselect', 'checkbox', 'select', 'radio'].includes(oldVal) &&
        !['multiselect', 'checkbox', 'select', 'radio'].includes(val)
      ) {
        this.fieldData.options = []
      }
    },
    selectedValidations: {
      deep: true,
      handler: function (val) {
        this.fieldData.validations = val.map(el => {
          const newEl = {
            name: el.name,
            label: el.label,
            message: el.message
          }
          if (el.args) {
            newEl.args = el.args.map((arg) => arg.value)
          }
          return newEl
        })
      }
    }
  },
  methods: {
    submit () {
      this.setSysNameFromLabel()
      this.$v.$touch()
      this.validationStatus = ''
      if (this.$v.$invalid) {
        this.validationStatus = this.$t('global.formSubmitError')
        return
      }
      if (this.value) {
        this.$emit('on-edit', this.fieldData)
      } else {
        this.$emit('on-add', this.fieldData)
      }
    },
    addOption () {
      this.fieldData.options.push({
        value: '',
        label: '',
        default: false
      })
      this.$nextTick(() => {
        const el = document.querySelectorAll('.fields-builder__draggable .v-text-field__slot input')[this.fieldData.options.length - 1]
        el.focus()
      })
    },
    setOptionValue (val, index) {
      this.fieldData.options[index].value = val
      this.fieldData.options[index].label = val
    },
    deleteOption (index) {
      this.fieldData.options.splice(index, 1)
    },
    addValidationRule (val) {
      this.selectedValidations.push(
        val.numberOfArgs ? {
          ...val,
          args: Array.from({ length: val.numberOfArgs }, () => { return { value: '' } })
        } : val
      )
    },
    deleteValidationRule (val) {
      this.selectedValidations = this.selectedValidations.filter(rule => rule.name !== val.name)
    },
    updateDefaultOption (index) {
      const allowMultipleDefault = ['multiselect', 'checkbox'].includes(this.fieldData.type)
      if (!allowMultipleDefault) {
        this.fieldData.options = this.fieldData.options.map((option, i) => {
          if (Number(index) === i) {
            option.default = !option.default
          } else {
            option.default = false
          }
          return option
        })
      } else {
        this.fieldData.options[index].default = !this.fieldData.options[index].default
      }
    },
    setSysNameFromLabel () {
      if (this.fieldData.label.length === 0) return
      if (this.fieldNameWasUpdated) return
      if (this.value) return
      const urlifiedVal = urlify(this.fieldData.label)
      const camelize = s => s.replace(/-./g, x => x.toUpperCase()[1])
      this.fieldData.name = camelize(urlifiedVal)
    },
    setSysNameManually (val) {
      this.fieldNameWasUpdated = true
      const urlifiedVal = urlify(val)
      const camelize = s => s.replace(/-./g, x => x.toUpperCase()[1])
      this.fieldData.name = camelize(urlifiedVal)
    },
    addParamsToValidations (source) {
      return source.map(rule => {
        if (!this.fieldValidationsRules.some(el => el.name === rule.name)) return rule
        return Object.assign(rule, this.fieldValidationsRules.find(el => el.name === rule.name))
      })
    }
  },
  created () {
    const valueData = JSON.parse(JSON.stringify(this.value))
    const localFieldSchema = JSON.parse(JSON.stringify(fieldSchema))
    this.fieldData = Object.assign({}, localFieldSchema, valueData || {})
    this.savedSysName = this.fieldData.name
    if (valueData && valueData.validations.length) {
      const selectedValidations = this.addParamsToValidations(valueData.validations)
      this.selectedValidations = selectedValidations.map((rule) => {
        if (rule.args) {
          rule.args = rule.args.map(v => { return { value: v } })
        }
        return rule
      })
    }
  }
}
</script>

<style scoped>
.form-field-builder{padding: 20px;}
.v-input--is-readonly {opacity: 0.5; background:#e4e9f1;}
</style>
