export default {
  published: true,
  pro: true,
  featureRestrictionsGroup: 'analyticsIntegrations',
  name: 'gtm',
  title: 'Google Tag Manager',
  logoUrl: '/img/integrations/gtm.svg',
  coverUrl: '/img/integrations/gtm-cover.png',
  bgColor: '#4285F4',
  categories: ['Analytics'],
  comingSoon: false,
  builtIn: false,
  url: 'https://tagmanager.google.com',
  helpUrl: '',
  description: `
  Manage all your website tags without editing code.

  GTM was designed to help manage the lifecycle of e-marketing tags (sometimes referred to as tracking pixels or web beacons), which are used to integrate third-party software into digital properties such as websites and web applications.

  <details>
  <summary>Where to find your GTM Container ID.</summary>

  1. Open <a href="https://tagmanager.google.com" target="_blank">Google Tag Manager Home</a>
  2. Copy the ID from the accounts list.

  ![](https://spreadsimple.s3.us-east-2.amazonaws.com/4e062fa4-f26c-48fd-aba3-b4cbd86cda25_Image%202020-12-24%20at%2012.48.14%20AM.jpg)
  </details>
  `,

  formFields: [
    {
      name: 'enabled',
      type: 'toggle',
      label: 'Enabled',
      default: false,
      validations: [
        'required'
      ],
      secret: false
    },
    {
      name: 'id',
      type: 'text',
      showIf: ['enabled'],
      label: 'GTM Container ID',
      placeholder: 'GTM-ABCDE4F',
      default: '',
      validations: [
        'required',
        'minLength:1',
        'maxLength:20',
        'startsWith:GTM-'
      ],
      secret: false
    },
    {
      name: 'trackPurchaseEvents',
      type: 'toggle',
      showIf: ['enabled'],
      label: 'Track Purchase Events',
      default: false,
      validations: [],
      secret: false
    },
    {
      name: 'purchaseCurrency',
      type: 'text',
      showIf: ['enabled', 'trackPurchaseEvents'],
      label: 'Currency Code',
      default: 'USD',
      placeholder: 'USD',
      validations: [
        'required',
        'minLength:2',
        'maxLength:4'
      ],
      secret: false
    }
  ],

  actions: [
    {
      type: 'injectToHead',
      run (formData) {
        return `
        <!-- Google Tag Manager -->
        <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${formData.id}');</script>
        <!-- End Google Tag Manager -->
        `
      }
    },
    {
      type: 'injectToBodyStart',
      run (formData) {
        return `
        <!-- End Google Tag Manager -->
        <!-- Google Tag Manager (noscript) -->
        <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${formData.id}"
        height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
        <!-- End Google Tag Manager (noscript) -->
        `
      }
    },
    {
      type: 'trackPurchase',
      run (formData, payload) {
        if (!formData.trackPurchaseEvents) return

        try {
          const eventData = {
            event: 'purchase',
            ecommerce: {
              purchase: {
                value: payload.totalAmount,
                currency: formData.purchaseCurrency,
                items: payload.cartItems.map(item => ({
                  item_id: item.sku || item.title,
                  item_name: item.title,
                  item_price: item.price,
                  quantity: item.quantity
                }))
              }
            }
          }
          window.dataLayer.push(eventData)
        } catch (e) {
          console.warn('Failed to track GTM Purchase', e)
        }
      }
    }
  ]
}
