var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tile-constructor" }, [
    _c(
      "div",
      { staticClass: "tile-constructor__form-fields" },
      [
        _c(
          "FormField",
          {
            staticClass: "tile-constructor__field aside-select",
            attrs: {
              label: "Base path for detail pages",
              required: "",
              messages: _vm.baseRouteValidationErrors
            }
          },
          [
            _c("v-text-field", {
              model: {
                value: _vm.baseRoute,
                callback: function($$v) {
                  _vm.baseRoute = typeof $$v === "string" ? $$v.trim() : $$v
                },
                expression: "baseRoute"
              }
            })
          ],
          1
        ),
        _c("SingleItemLayoutConstructor", {
          model: {
            value: _vm.singleItemOptions.layout,
            callback: function($$v) {
              _vm.$set(_vm.singleItemOptions, "layout", $$v)
            },
            expression: "singleItemOptions.layout"
          }
        }),
        _vm._l(_vm.singleItemFields, function(field) {
          return _c(
            "div",
            {
              key: field.name,
              staticClass: "tile-constructor__field aside-select"
            },
            [
              _c("h6", { staticClass: "aside-field-label" }, [
                _vm._v(_vm._s(field.label))
              ]),
              _c(
                "v-select",
                {
                  attrs: {
                    value:
                      _vm.singleItemOptions[field.name][
                        field.multiple ? "ids" : "id"
                      ],
                    items: _vm.cols,
                    "item-text": "label",
                    "item-value": "id",
                    attach: "",
                    placeholder: "Choose a column",
                    "menu-props": "offsetY",
                    clearable: field.clearable,
                    chips: field.multiple,
                    "deletable-chips": field.multiple,
                    multiple: field.multiple,
                    "append-icon": "keyboard_arrow_down"
                  },
                  on: {
                    input: function(val) {
                      return _vm.updateTileOptionValue(
                        val,
                        field.name,
                        field.multiple
                      )
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "selection",
                        fn: function(data) {
                          return [
                            field.multiple
                              ? _c(
                                  "DraggableChip",
                                  {
                                    attrs: {
                                      id: data.index,
                                      value:
                                        _vm.singleItemOptions[field.name][
                                          field.multiple ? "ids" : "id"
                                        ]
                                    },
                                    on: {
                                      input: function(val) {
                                        return _vm.updateTileOptionValue(
                                          val,
                                          field.name,
                                          field.multiple
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(data.item.label) +
                                        "\n          "
                                    )
                                  ]
                                )
                              : _c("div", [_vm._v(_vm._s(data.item.label))])
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "list-title",
                      attrs: { slot: "prepend-item" },
                      slot: "prepend-item"
                    },
                    [_vm._v(" " + _vm._s(field.dropdownLabel))]
                  )
                ]
              ),
              _c("i", {
                staticClass: "aside-select__field-icon icon-arrow-down"
              }),
              !!_vm.v[field.name] && !_vm.v[field.name].id.required
                ? _c("p", { staticClass: "form-field__error" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("global.fieldIsRequired")) +
                        "\n      "
                    )
                  ])
                : _vm._e(),
              [
                _c("div", {
                  staticClass: "tile-constructor__hint",
                  domProps: { innerHTML: _vm._s(field.hint) }
                })
              ],
              field.name === "image" &&
              _vm.singleItemOptions.image.keepAspectRatio !== undefined
                ? _c(
                    "div",
                    {
                      staticClass: "content-form__switch-field",
                      staticStyle: { "margin-top": "20px" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "content-form__switcher-wrap" },
                        [
                          _c(
                            "div",
                            { staticClass: "content-form__switcher-label" },
                            [_vm._v("Keep image aspect ratio")]
                          ),
                          _c(
                            "label",
                            {
                              staticClass: "switcher",
                              class: {
                                selected:
                                  _vm.singleItemOptions.image.keepAspectRatio
                              }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.singleItemOptions.image
                                        .keepAspectRatio,
                                    expression:
                                      "singleItemOptions.image.keepAspectRatio"
                                  }
                                ],
                                staticClass: "switcher__input",
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.singleItemOptions.image.keepAspectRatio
                                  )
                                    ? _vm._i(
                                        _vm.singleItemOptions.image
                                          .keepAspectRatio,
                                        null
                                      ) > -1
                                    : _vm.singleItemOptions.image
                                        .keepAspectRatio
                                },
                                on: {
                                  change: function($event) {
                                    var $$a =
                                        _vm.singleItemOptions.image
                                          .keepAspectRatio,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.singleItemOptions.image,
                                            "keepAspectRatio",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.singleItemOptions.image,
                                            "keepAspectRatio",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.singleItemOptions.image,
                                        "keepAspectRatio",
                                        $$c
                                      )
                                    }
                                  }
                                }
                              }),
                              _c("span", { staticClass: "switcher__indicator" })
                            ]
                          )
                        ]
                      )
                    ]
                  )
                : _vm._e()
            ],
            2
          )
        }),
        _c("CtaButtonConstructor", {
          staticClass: "tile-constructor__field aside-select",
          attrs: {
            widgetLabel: "Primary button settings",
            cols: _vm.cols,
            msg: _vm.msg,
            onDetailsPage: true
          },
          on: {
            "change-tab": function($event) {
              return _vm.$emit("change-tab", "checkout-options")
            }
          },
          model: {
            value: _vm.singleItemOptions.cta,
            callback: function($$v) {
              _vm.$set(_vm.singleItemOptions, "cta", $$v)
            },
            expression: "singleItemOptions.cta"
          }
        }),
        _c("CtaButtonConstructor", {
          staticClass: "tile-constructor__field aside-select",
          attrs: {
            widgetLabel: "Secondary button settings",
            cols: _vm.cols,
            msg: _vm.msg,
            onDetailsPage: true
          },
          on: {
            "change-tab": function($event) {
              return _vm.$emit("change-tab", "checkout-options")
            }
          },
          model: {
            value: _vm.singleItemOptions.cta_second,
            callback: function($$v) {
              _vm.$set(_vm.singleItemOptions, "cta_second", $$v)
            },
            expression: "singleItemOptions.cta_second"
          }
        }),
        _c("MapViewConstructor", {
          attrs: {
            cols: _vm.cols,
            singleItemPage: true,
            integrationsData: _vm.integrationsData
          },
          model: {
            value: _vm.singleItemOptions.mapView,
            callback: function($$v) {
              _vm.$set(_vm.singleItemOptions, "mapView", $$v)
            },
            expression: "singleItemOptions.mapView"
          }
        }),
        _c("div", { staticClass: "content-form__switch-field" }, [
          _c("div", { staticClass: "content-form__switcher-wrap" }, [
            _c("div", { staticClass: "content-form__switcher-label" }, [
              _vm._v("Social Share buttons")
            ]),
            _c(
              "label",
              {
                staticClass: "switcher",
                class: {
                  selected: _vm.singleItemOptions.socialShareButtons.enabled
                }
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.singleItemOptions.socialShareButtons.enabled,
                      expression: "singleItemOptions.socialShareButtons.enabled"
                    }
                  ],
                  staticClass: "switcher__input",
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(
                      _vm.singleItemOptions.socialShareButtons.enabled
                    )
                      ? _vm._i(
                          _vm.singleItemOptions.socialShareButtons.enabled,
                          null
                        ) > -1
                      : _vm.singleItemOptions.socialShareButtons.enabled
                  },
                  on: {
                    change: function($event) {
                      var $$a =
                          _vm.singleItemOptions.socialShareButtons.enabled,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.singleItemOptions.socialShareButtons,
                              "enabled",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.singleItemOptions.socialShareButtons,
                              "enabled",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(
                          _vm.singleItemOptions.socialShareButtons,
                          "enabled",
                          $$c
                        )
                      }
                    }
                  }
                }),
                _c("span", { staticClass: "switcher__indicator" })
              ]
            )
          ])
        ]),
        _vm.singleItemOptions.socialShareButtons.enabled
          ? _c(
              "div",
              {
                staticClass:
                  "tile-constructor__field content-form__switch-hidden-field aside-select paddingless"
              },
              [
                _c(
                  "div",
                  { staticClass: "content-form__highlighted" },
                  [
                    _c(
                      "FormField",
                      {
                        staticClass: "side-form__item aside-input",
                        attrs: { label: "Social share buttons style" }
                      },
                      [
                        _c("v-select", {
                          attrs: {
                            value:
                              _vm.singleItemOptions.socialShareButtons.style,
                            items: _vm.socialShareButtonsStyleOptions,
                            attach: "",
                            "item-text": "label",
                            "item-value": "value",
                            placeholder: "Choose a share button style",
                            "menu-props": "offsetY",
                            "append-icon": "keyboard_arrow_down"
                          },
                          on: {
                            input: function(val) {
                              return (_vm.singleItemOptions.socialShareButtons.style = val)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _vm.singleItemOptions.socialShareButtons.style === "popup"
                      ? _c(
                          "FormField",
                          {
                            staticClass: "side-form__item aside-input",
                            attrs: { label: "Popup title text" }
                          },
                          [
                            _c("v-text-field", {
                              attrs: { type: "text" },
                              model: {
                                value:
                                  _vm.singleItemOptions.socialShareButtons
                                    .popupTitleText,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.singleItemOptions.socialShareButtons,
                                    "popupTitleText",
                                    $$v
                                  )
                                },
                                expression:
                                  "singleItemOptions.socialShareButtons.popupTitleText"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.singleItemOptions.socialShareButtons.enabled
                      ? _c(
                          "FormField",
                          {
                            staticClass: "side-form__item aside-input",
                            attrs: { label: "Social share buttons" }
                          },
                          [
                            _c("v-select", {
                              attrs: {
                                value:
                                  _vm.singleItemOptions.socialShareButtons
                                    .buttons,
                                items: _vm.socialShareButtons,
                                "item-text": "label",
                                "item-value": "value",
                                chips: "",
                                attach: "",
                                multiple: "",
                                "deletable-chips": "",
                                "append-icon": "keyboard_arrow_down"
                              },
                              on: { input: _vm.updateSocialShareButtons },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "selection",
                                    fn: function(data) {
                                      return [
                                        _c(
                                          "DraggableChip",
                                          {
                                            attrs: { id: data.index },
                                            model: {
                                              value:
                                                _vm.singleItemOptions
                                                  .socialShareButtons.buttons,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.singleItemOptions
                                                    .socialShareButtons,
                                                  "buttons",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "singleItemOptions.socialShareButtons.buttons"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(data.item.label) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                326371527
                              )
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            )
          : _vm._e(),
        _c("RelatedItemsConstructor", {
          attrs: { cols: _vm.cols },
          model: {
            value: _vm.singleItemOptions.relatedItems,
            callback: function($$v) {
              _vm.$set(_vm.singleItemOptions, "relatedItems", $$v)
            },
            expression: "singleItemOptions.relatedItems"
          }
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }