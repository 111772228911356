<template>
  <div v-if="mainWorkspace" class="workspaces-manage">
    <div class="workspaces-manage__head">
      <TitleEditor
        class="workspaces-manage__title"
        :value="mainWorkspace.title"
        @input="updateWorkspaceTitle"
      />
    </div>
    <div class="workspaces-manage__body">
      <form class="workspaces-manage__search" @submit.prevent="inviteEditor">
        <FormField
          class="workspaces-manage__search-input"
          :messages="emailValidationMessages"
        >
          <el-input v-model="inviteEmail" type="text" placeholder="Enter e-mail to invite editor" />
        </FormField>
        <UiBtn :loading="isLoading" :disabled="isLoading" class="workspaces-manage__search-btn" uppercase attrType="submit">Invite members</UiBtn>
      </form>
      <div class="workspaces-manage__counter">
        Your workspace has <span>{{mainWorkspaceEditors}}/{{workspaceUserLimit}}</span> editors
      </div>
      <div class="workspaces-manage__members">
        <div
          v-for="user in mainWorkspace.users"
          :key="user.email"
          class="workspaces-manage__member">
          <div class="workspaces-manage__member-details">
            <UserAvatar class="workspaces-manage__member-avatar" :letter="user.email.charAt(0)"/>
            <div class="workspaces-manage__member-meta">
              <h5 class="workspaces-manage__member-meta-title">{{user.email}}</h5>
              <p v-if="user.role !== 'OWNER'" class="workspaces-manage__member-meta-subtitle">
                {{user.status}}
                  <el-tooltip
                    v-if="user.status === 'INACTIVE'"
                    :disabled="isLoading"
                    content="Update editor status to “Active”"
                    placement="top"
                  >
                    <button
                      class="icon-refresh-arrow workspaces-manage__member-meta-subtitle-icon"
                      :disabled="isLoading"
                      @click="updateEditorStatus(user)"></button>
                  </el-tooltip>
              </p>
            </div>
          </div>
          <div class="workspaces-manage__member-status">
            {{userRoleText(user.role)}}
          </div>
          <button v-if="user.role !== 'OWNER'" :disabled="isLoading" class="workspaces-manage__member-delete-btn icon-delete" @click="openDeleteDialog(user.email)"></button>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="deleteDialog"
      class="msg-dialog"
      @close="closeDeleteDialog"

    >
      <div class="msg-dialog__body">
        <h4 class="msg-dialog__title">Are you sure?</h4>
        <p class="msg-dialog__string">This user won't be able to edit your websites anymore</p>
        <div class="msg-dialog__btns">
          <UiBtn :disabled="isLoading" type="secondary" uppercase @click="closeDeleteDialog">
            No
          </UiBtn>
          <UiBtn :disabled="isLoading" uppercase @click="deleteEditor">
            Yes
          </UiBtn>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import UiBtn from '@/components/ui-btn'
import UserAvatar from '@/components/user-avatar'
import TitleEditor from '@/components/title-editor/'
import FormField from '@/components/form-field'
import Vuelidate from 'vuelidate'
import Vue from 'vue'
import { required, email } from 'vuelidate/lib/validators'
import api from '@/services/api/'
Vue.use(Vuelidate)
export default {
  name: 'WorkspacesSettings',
  components: {
    UiBtn,
    UserAvatar,
    TitleEditor,
    FormField
  },
  data () {
    return {
      deleteDialog: false,
      inviteEmail: '',
      userToDelete: '',
      isLoading: false,
      rolesList: {
        'OWNER': 'Owner',
        'USER': 'Editor'
      }
    }
  },
  validations: {
    inviteEmail: {
      required,
      email
    }
  },
  computed: {
    ...mapState('app', [
      'workspaces'
    ]),
    mainWorkspace () {
      return this.workspaces ? this.workspaces.find(ws => ws.main) : undefined
    },
    emailValidationMessages () {
      const messages = []
      if (this.$v.inviteEmail.$dirty && !this.$v.inviteEmail.required) {
        messages.push('Field is required')
      }
      if (this.$v.inviteEmail.$dirty && !this.$v.inviteEmail.email) {
        messages.push('Email is incorrect')
      }
      return messages
    },
    workspaceUserLimit () {
      return this.mainWorkspace.editorsLimit || 0
    },
    mainWorkspaceEditors () {
      return this.mainWorkspace ? this.mainWorkspace.users.filter(u => u.role !== 'OWNER').length : 0
    },
    canAddMoreEditors () {
      return this.workspaceUserLimit > this.mainWorkspaceEditors
    }
  },
  methods: {
    ...mapActions('app', [
      'fetchWorkspaces'
    ]),
    showMsg (text, type = 'info') {
      this.$toasted.show(text, {
        theme: 'toasted-primary',
        position: 'top-center',
        type: type,
        duration: 1500
      })
    },
    userRoleText (role) {
      return this.rolesList[role]
    },
    async inviteEditor () {
      // if (!this.canAddMoreEditors) return
      this.$v.inviteEmail.$touch()
      if (!this.$v.inviteEmail.$invalid) {
        this.isLoading = true
        try {
          await api.addWorkspaceUser(this.mainWorkspace.id, { user: this.inviteEmail })
          await this.fetchWorkspaces()
          this.inviteEmail = ''
          this.$v.$reset()
          this.showMsg('Invite have been sent out', 'success')
          this.$trackEvent({
            'event': 'addMember'
          })
        } catch ({ response }) {
          this.showMsg(response.data.reason, 'error')
        } finally {
          this.isLoading = false
        }
      }
    },
    async updateEditorStatus (user) {
      try {
        await api.updateWorkspaceUser(this.mainWorkspace.id, {
          ...user,
          status: 'ACTIVE'
        })
        await this.fetchWorkspaces()
        this.showMsg('Editor status was updated', 'success')
      } catch ({ response }) {
        this.showMsg(response.data.reason, 'error')
      } finally {
        this.isLoading = false
      }
    },
    async deleteEditor () {
      try {
        this.isLoading = true
        await api.deleteWorkspaceUser(this.mainWorkspace.id, { user: this.userToDelete })
        await this.fetchWorkspaces()
        this.closeDeleteDialog()
        this.showMsg('User was removed from editors', 'success')
        this.$trackEvent({
          'event': 'deleteMember'
        })
      } catch ({ response }) {
        this.showMsg(response.data.reason, 'error')
      } finally {
        this.isLoading = false
      }
    },
    async updateWorkspaceTitle (val) {
      try {
        await api.updateWorkspace(this.mainWorkspace.id, { title: val })
        await this.fetchWorkspaces()
        this.showMsg('Workspace was successfully renamed', 'success')
      } catch ({ response }) {
        this.showMsg(response.data.reason, 'error')
      }
    },
    openDeleteDialog (userEmail) {
      this.deleteDialog = true
      this.userToDelete = userEmail
    },
    closeDeleteDialog () {
      this.deleteDialog = false
      this.userToDelete = ''
    }
  },
  async created () {
    if (!this.workspaces) {
      await this.fetchWorkspaces()
    }
  }
}
</script>
