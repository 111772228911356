import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import ManageSheetPage from '@/pages/manage-sheet-page'
import SpreadViews from '@/pages/views-page'
import AddViewPage from '@/pages/add-view-page'
import Page404 from '@/pages/404'
import AccountPage from '@/pages/account-page'
import LoginPage from '@/pages/login-page'
import PromoCodePage from '@/pages/promo-code-page'
import AppsumoCodePage from '@/pages/appsumo-code-page'
import AcceptWorkspaceInvitation from '@/pages/accept-workspace-invitation'
import RepeatTrial from '@/pages/repeat-trial'
import PageQuiz from '@/pages/quiz'
import { beforeEach, afterEach } from './middleware.js'
import api from '@/services/api'

Vue.use(Router)
Vue.use(Meta)

const router = new Router({
  mode: 'history',
  routes: [
    {
      name: 'login',
      path: '/login',
      component: LoginPage,
      meta: {
        layout: 'LayoutEmpty'
      }
    },
    {
      name: 'quiz',
      path: '/quiz',
      component: PageQuiz,
      meta: {
        auth: {
          required: true,
          redirect: { name: 'login' }
        },
        layout: 'LayoutEmpty'
      }
    },
    {
      name: 'manage-sheet-page',
      path: '/manage/:id',
      component: ManageSheetPage,
      props: true,
      meta: {
        auth: {
          required: true,
          redirect: { name: 'login' }
        },
        layout: 'LayoutManage'
      }
    },
    {
      name: 'spread-views',
      path: '/',
      component: SpreadViews,
      props: true,
      meta: {
        auth: {
          required: true,
          redirect: { name: 'login' }
        },
        layout: 'LayoutFooterless'
      }
    },
    {
      name: 'add-view-page',
      path: '/add',
      component: AddViewPage,
      query: true,
      props: true,
      meta: {
        auth: {
          required: true,
          redirect: { name: 'login' }
        },
        layout: 'LayoutFooterless'
      },
      beforeEnter: async (to, from, next) => {
        if (from.name === 'spread-views') {
          next()
        } else {
          const workSpacesList = await api.getWorkspaces()
          const currentSsWsId = localStorage.getItem('ssWsId')
          const currentWorkspace = workSpacesList.find(workspace => workspace.id === currentSsWsId)
          const availableSlots = Object.values(currentWorkspace.ownerLicenses.paidLicenses).reduce((res, i) => {
            res = res + i.available
            return res
          }, currentWorkspace.ownerLicenses.free.available)
          if (availableSlots === 0) {
            next({ path: '/', query: { isOverlimit: true } })
          }
          next()
        }
      }
    },
    {
      name: 'account',
      path: '/account',
      meta: {
        auth: {
          required: true,
          redirect: { name: 'login' }
        }
      },
      redirect: {
        name: 'account-settings'
      }
    },
    {
      name: 'account-settings',
      path: '/account/settings',
      component: AccountPage,
      props: true,
      meta: {
        auth: {
          required: true,
          redirect: { name: 'login' }
        },
        layout: 'LayoutFooterless'
      }
    },
    {
      name: 'account-billing',
      path: '/account/billing',
      component: AccountPage,
      props: true,
      meta: {
        auth: {
          required: true,
          redirect: { name: 'login' }
        },
        layout: 'LayoutFooterless'
      }
    },
    {
      name: 'account-appsumo',
      path: '/account/appsumo',
      component: AccountPage,
      props: true,
      meta: {
        auth: {
          required: true,
          redirect: { name: 'login' }
        },
        layout: 'LayoutFooterless'
      }
    },
    {
      name: 'account-affiliate',
      path: '/account/affiliate',
      component: AccountPage,
      props: true,
      meta: {
        auth: {
          required: true,
          redirect: { name: 'login' }
        },
        layout: 'LayoutFooterless'
      }
    },
    {
      name: 'account-workspaces',
      path: '/account/workspace',
      component: AccountPage,
      props: true,
      meta: {
        auth: {
          required: true,
          redirect: { name: 'login' }
        },
        layout: 'LayoutFooterless'
      }
    },
    {
      name: 'promo-code-page',
      path: '/promo',
      component: PromoCodePage,
      meta: {
        auth: {
          required: true,
          redirect: { name: 'login' }
        },
        layout: 'LayoutFooterless'
      }
    },
    {
      name: 'appsumo-code-page',
      path: '/appsumo',
      component: AppsumoCodePage,
      props: true,
      meta: {
        layout: 'LayoutFooterless'
      }
    },
    {
      name: 'accept-workspace-invitation',
      path: '/accept-invite',
      component: AcceptWorkspaceInvitation,
      query: true,
      meta: {
        auth: {
          required: true,
          redirect: { name: 'login' }
        },
        layout: 'LayoutFooterless'
      }
    },
    {
      name: 'repeat-trial',
      path: '/repeat-trial',
      component: RepeatTrial,
      props: true,
      meta: {
        auth: {
          required: true,
          redirect: { name: 'login' }
        },
        layout: 'LayoutFooterless'
      }
    },
    {
      name: 'page-404',
      path: '*',
      component: Page404,
      meta: {
        layout: 'LayoutFooterless'
      }
    }
  ]
})

router.beforeEach(beforeEach)
router.afterEach(afterEach)

export default router
