<template>
  <div
    v-click-outside="closePicker"
    :style="{backgroundColor: color.hex}"
    class="color-picker"
    @click="pickerVisible = true"
    >
    <chrome-picker v-show="pickerVisible" :value="color" @input="updateValue"/>
  </div>
</template>
<script>
import { Chrome } from 'vue-color'
export default {
  name: 'ColorPicker',
  components: {
    'chrome-picker': Chrome
  },
  props: {
    value: {
      type: String
    }
  },
  data () {
    return {
      defaultColor: '#000000',
      pickerVisible: false,
      color: null,
      timeout: null
    }
  },
  watch: {
    value (val) {
      if (this.value === undefined) {
        this.color = {
          hex: this.defaultColor
        }
      } else {
        this.color = {
          hex: this.value
        }
      }
      this.$emit('input', this.color.hex)
    }
  },
  methods: {
    updateValue (value) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.color = value
        this.$emit('input', this.color.hex)
      }, 250)
    },
    togglePicker () {
      this.pickerVisible = !this.pickerVisible
    },
    closePicker () {
      this.pickerVisible = false
    }
  },
  created () {
    if (this.value === undefined) {
      this.color = {
        hex: this.defaultColor
      }
    } else {
      this.color = {
        hex: this.value
      }
    }
  }
}
</script>
<style>
  .current-color {
    cursor: pointer;
    width: 18px;
    height: 18px;
    border-radius: 2px;
    border: 1px solid #A8B7CF;
  }
</style>
