<template>
  <form class="fields-builder" @submit.prevent="submit">
    <div class="fields-builder__item is-flex-row is-multiline">
      <FormField
        v-for="field in currentSchema"
        :key="field.name" class="fields-builder__item-control"
        :label="field.label"
        :messages="$v.optionData[field.name].$dirty && $v.optionData[field.name].$invalid ? ['Field is invalid'] : []"
        :class="[
          setFieldWidthClass(field.width)
        ]"
      >
        <v-text-field
          v-if="['text', 'number'].includes(field.type)"
          v-model.trim="$v.optionData[field.name].$model"
          :type="field.type"
          :step=".01"
          :placeholder="field.placeholder ? field.placeholder : ''"
          :suffix="field.name === 'value' && optionData.type && (optionData.type === 'multiplication' || optionData.type === 'percentage') ? '%' : ''"
        ></v-text-field>

        <v-select
          v-if="['select'].includes(field.type)"
          v-model="$v.optionData[field.name].$model"
          :items="field.options"
          item-text="label"
          item-value="value"
          menu-props="offsetY"
          append-icon="keyboard_arrow_down"
        >
        </v-select>

        <div v-if="['date'].includes(field.type)">
          <el-date-picker
            v-model="$v.optionData[field.name].$model"
            type="datetime"
            default-time="12:00:00"
            :pickerOptions="{
              disabledDate(time) {
                  return checkIfDateIsPast(time)
              }
            }"
            placeholder="Select date and time">
          </el-date-picker>
        </div>

      </FormField>
    </div>

    <div class="fields-builder__footer">
      <div class="fields-builder__footer-btns">
        <button class="fields-builder__footer-btn btn btn--text-only" type="button" @click="$emit('cancel-submission')">Cancel</button>
        <button :class="{'is-loading': loading}" class="fields-builder__footer-btn btn" type="submit">{{value ? 'SAVE' : 'ADD'}}</button>
      </div>
      <p v-if="validationStatus.length" class="fields-builder__footer-msg">
        <i class="icon-circle-close fields-builder__footer-msg-icon"></i>
        {{validationStatus}}
      </p>
    </div>
  </form>
</template>
<script>
import Vue from 'vue'
import Vuelidate from 'vuelidate'
import FormField from '@/components/form-field'
import { required, helpers, minLength, maxLength, between, numeric, integer, decimal, minValue } from 'vuelidate/lib/validators'
import { shippingFieldSchema, feeFieldSchema, promocodeSchema } from './fieldParams.js'
Vue.use(Vuelidate)
// eslint-disable-next-line
const alphaNumDashUnderscore = helpers.regex('alphaNumDashUnderscore', /^[A-Za-z0-9\-_]*$/)

const builtInValidation = {
  required,
  minLength,
  minValue,
  maxLength,
  between,
  numeric,
  integer,
  decimal,
  isUniqueItem: (...args) => helpers.withParams(
    { type: 'options', value: [...args] },
    (value) => {
      return !args.includes(value.toLowerCase())
    }
  ),
  alphaNumDashUnderscore
}
export default {
  name: 'OptionItemBuilder',
  components: {
    FormField
  },
  props: {
    schema: {
      type: String,
      required: true
    },
    schemaArray: {
      type: Array
    },
    value: {
      type: Object
    }
  },
  data () {
    return {
      currentSchema: [],
      validationStatus: '',
      optionData: {},
      loading: false
    }
  },
  validations () {
    return this.validationRules
  },
  computed: {
    validationRules () {
      return { optionData: this.currentSchema.reduce((acc, field) => {
        if (field.validations.length === 0) {
          return acc
        }
        acc[field.name] = {}
        field.validations.forEach((rule) => {
          acc[field.name][rule.name] = rule.args ? builtInValidation[rule.name](...rule.args) : builtInValidation[rule.name]
        })
        return acc
      }, {})
      }
    }
  },
  methods: {
    setDefaultData () {
      return this.currentSchema.reduce((acc, field) => {
        acc[field.name] = field.default
        return acc
      }, {})
    },
    setCurrentSchema () {
      switch (this.schema) {
        case 'shippingFieldSchema':
          return shippingFieldSchema

        case 'feeFieldSchema':
          return feeFieldSchema

        case 'promocodeSchema':
          return promocodeSchema

        default:
          return []
      }
    },
    checkIfDateIsPast (val) {
      return val.getTime() < Date.now() - 8.64e7
    },
    submit () {
      this.loading = true
      this.$v.$touch()
      this.validationStatus = ''
      if (this.$v.$invalid) {
        this.validationStatus = this.$t('global.formSubmitError')
        this.loading = false
        return
      }
      this.currentSchema.forEach(field => {
        if (field.type === 'number') {
          this.optionData[field.name] = Number(this.optionData[field.name])
        }
      })
      if (this.value) {
        this.$emit('on-edit', this.optionData)
      } else {
        this.$emit('input', this.optionData)
      }
    },
    setFieldWidthClass (width) {
      switch (Number(width)) {
        case 12:
          return 'is-full'
        case 9:
          return 'is-three-quarters'
        case 6:
          return 'is-half'
        case 4:
          return 'is-third'
        case 3:
          return 'is-quater'
        default:
          return ''
      }
    }
  },

  created () {
    if (this.schemaArray) {
      this.currentSchema = this.schemaArray
    } else {
      this.currentSchema = this.setCurrentSchema(this.schema)
    }
    this.optionData = Object.assign({}, this.setDefaultData(), this.value || {})
  }

}
</script>
