export default {
  published: true,
  pro: true,
  featureRestrictionsGroup: 'calendarIntegrations',
  name: 'calendly',
  title: 'Calendly',
  headline: '',
  logoUrl: '/img/integrations/calendly.svg',
  coverUrl: '/img/integrations/calendly_cover.png',
  bgColor: '#084FFF',
  categories: ['Calendar'],
  comingSoon: false,
  builtIn: false,
  url: '',
  helpUrl: '',
  description: `
  Calendly is the scheduling automation platform with team-based scheduling,
  solutions and integrations for every department, and advanced security features.
  `,

  formFields: [
    {
      name: 'enabled',
      type: 'toggle',
      label: 'Enabled',
      default: false,
      validations: [
        'required'
      ],
      secret: false
    },
    {
      name: 'popupDetailsPage',
      type: 'toggle',
      showIf: ['enabled'],
      label: 'Hide Page Details (hide profile picture, event duration etc)',
      default: false,
      validations: [],
      secret: false
    },
    {
      name: 'popupCoockieBanner',
      type: 'toggle',
      showIf: ['enabled'],
      label: 'Hide Coockie Banner (hide Calendly’s request for cookie consent)',
      default: false,
      validations: [],
      secret: false
    },
    {
      name: 'popupBgClr',
      type: 'color',
      showIf: ['enabled'],
      label: 'Popup Background Color',
      default: '#FFFFFF',
      validations: [],
      secret: false
    },
    {
      name: 'popupTxtClr',
      type: 'color',
      showIf: ['enabled'],
      label: 'Popup Text Color',
      default: '#1A1A1A',
      validations: [],
      secret: false
    },
    {
      name: 'popupBtnLinkClr',
      type: 'color',
      showIf: ['enabled'],
      label: 'Popup Button & Link Color',
      default: '#0069FF',
      validations: [],
      secret: false
    },
    {
      name: 'floatingButton',
      type: 'toggle',
      showIf: ['enabled'],
      label: 'Enable floating button',
      default: false,
      validations: [],
      secret: false
    },
    {
      name: 'url',
      type: 'text',
      showIf: ['enabled', 'floatingButton'],
      label: 'Set Clendly URL',
      placeholder: 'https://',
      default: '',
      validations: [
        'url',
        'containsValue:calendly.com'
      ],
      secret: false
    },
    {
      name: 'floatingButtonText',
      type: 'text',
      showIf: ['enabled', 'floatingButton'],
      label: 'Button Text',
      placeholder: 'Schedule time with me',
      default: 'Schedule Time',
      validations: [
        'required'
      ],
      secret: false
    },
    {
      name: 'floatingButtonCustomSettings',
      type: 'toggle',
      showIf: ['enabled', 'floatingButton'],
      label: 'Set custom colors for floating button',
      default: false,
      validations: [],
      secret: false
    },
    {
      name: 'floatingButtonBgColor',
      type: 'color',
      showIf: ['enabled', 'floatingButton', 'floatingButtonCustomSettings'],
      label: 'Button Background Color',
      default: '#0069ff',
      validations: [
        'required'
      ],
      secret: false
    },
    {
      name: 'floatingButtonTextColor',
      type: 'color',
      showIf: ['enabled', 'floatingButton', 'floatingButtonCustomSettings'],
      label: 'Button Text Color',
      default: '#ffffff',
      validations: [
        'required'
      ],
      secret: false
    }
  ],

  actions: [
    {
      type: 'injectToHead',
      run () {
        return '<script src="https://assets.calendly.com/assets/external/widget.js" type="text/javascript"></script>'
      }
    },
    {
      type: 'injectToHead',
      run () {
        return `
        <script type="text/javascript">
          const head = document.getElementsByTagName('head')[0];
          const link = document.createElement('link');
          link.setAttribute('rel', 'stylesheet');
          link.setAttribute('href', 'https://assets.calendly.com/assets/external/widget.css');
          head.appendChild(link);
        </script>
        `
      }
    }
  ]
}
