var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "aside",
      class: { absolute: _vm.positionAbsolute, fixed: _vm.positionFixed }
    },
    [
      _c(
        "div",
        { staticClass: "aside__controls-wrap" },
        [_vm._t("controls")],
        2
      ),
      _c(
        "div",
        { staticClass: "aside__body", class: { opened: _vm.isOpened } },
        [
          _c(
            "div",
            { staticClass: "aside__body-inner" },
            [_vm._t("default")],
            2
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }