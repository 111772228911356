<template>
  <el-dropdown class="profile-dropdown" hide-on-click trigger="click" @command="handleCommand" @visible-change="val => dropdownOpened = val">
    <div class="profile-dropdown__trigger">
      <div class="profile-dropdown__avatar">{{ userInitials }} </div>
      <i class="profile-dropdown__trigger-icon icon-arrow-down" :class="{ 'is-dropdown-opened': dropdownOpened }" />
    </div>
    <el-dropdown-menu slot="dropdown" class="profile-dropdown__menu">
      <div class="profile-dropdown__menu-header">
        {{ userData.email || '' }}
      </div>
      <div class="profile-dropdown__menu-nav">
        <el-dropdown-item
          v-for="navItem in navItems"
          :key="navItem.routeName"
          :command="navItem.routeName"
        >
          {{ navItem.title }}
        </el-dropdown-item>
        <el-dropdown-item
          v-if="userData.appsumo"
          command="account-appsumo"
        >
          AppSumo
        </el-dropdown-item>
      </div>
      <div class="profile-dropdown__menu-footer">
        <a class="profile-dropdown__menu-footer-item el-dropdown-menu__item" :href="publicAppUrl">Home</a>
        <a class="profile-dropdown__menu-footer-item el-dropdown-menu__item" href="https://features.spreadsimple.com/spreadsimple" target="_blank">Roadmap</a>
        <a class="profile-dropdown__menu-footer-item el-dropdown-menu__item" href="https://help.spreadsimple.com/en/" target="_blank">Help</a>
        <div class="profile-dropdown__menu-footer-item el-dropdown-menu__item is-logout" @click="logout">
          Sign out
          <i class="icon-exit" />
        </div>
      </div>
    </el-dropdown-menu>
  </el-dropdown>
</template>
<script>
export default {
  name: 'ProfileDropdown',
  props: {
    userData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      publicAppUrl: process.env.VUE_APP_PUBLIC_APP_URL,
      dropdownOpened: false,
      navItems: [
        {
          routeName: 'spread-views',
          title: 'My Websites'
        },
        {
          routeName: 'account-billing',
          title: 'Billing settings'
        },
        {
          routeName: 'account-settings',
          title: 'Account settings'
        },
        {
          routeName: 'account-affiliate',
          title: 'Affiliate program'
        },
        {
          routeName: 'account-workspaces',
          title: 'Workspaces'
        }
      ]
    }
  },
  computed: {
    userInitials () {
      if (!this.userData.firstName) return '◌'
      return this.userData.firstName.charAt(0) || 'U'
    }
  },
  methods: {
    logout () {
      this.$auth.logout()
      this.$router.push({ name: 'login' })
    },
    handleCommand (routerLinkName) {
      this.$router.push({ 'name': routerLinkName })
    }
  }
}
</script>
