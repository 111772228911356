export const startProfitWell = (userEmail) => {
  if (process.env.NODE_ENV === 'development') return
  if (!userEmail || window['profitwell']) return
  try {
    const profitWellAuthToken = '90b2042fc8b6c76117ac30c71ce5032c'
    const profitWellScript = document.createElement('script')
    profitWellScript.setAttribute('id', 'profitwell-js')
    profitWellScript.setAttribute('data-pw-auth', profitWellAuthToken)
    document.body.appendChild(profitWellScript);

    (function (i, s, o, g, r, a, m) {
      i[o] = i[o] || function () { (i[o].q = i[o].q || []).push(arguments) }
      a = s.createElement(g)
      m = s.getElementsByTagName(g)[0]; a.async = 1; a.src = r + '?auth=' +
    s.getElementById(o + '-js').getAttribute('data-pw-auth'); m.parentNode.insertBefore(a, m)
    })(window, document, 'profitwell', 'script', 'https://public.profitwell.com/js/profitwell.js')

    window.profitwell('start', { 'user_email': userEmail })
  } catch (e) {}
}
