<template>
  <Modal
    v-model="modalOpened"
    modalName="Code editor"
    :hasCloseButton="true"
    :closeOnClickOutside="false"
    maxWidth="850px"
  >
    <div class="code-editor-form">
      <FormField
        label="Inject custom code"
        class="code-editor-form__item"
      >
        <CodeTextArea
          v-model="codeString"
        />
      </FormField>
      <div class="code-editor-form__btns">
        <UiBtn
          type="secondary"
          label="Cancel"
          @click="onCancelClick"
        />
        <UiBtn
          label="Save"
          @click="onSaveClick"
        />
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/modal'
import CodeTextArea from '@/components/code-textarea/CodeTextArea.vue'
import FormField from '@/components/form-field'
import UiBtn from '@/components/ui-btn'
export default {
  name: 'CodeInjectModal',
  components: {
    UiBtn,
    FormField,
    CodeTextArea,
    Modal
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      modalOpened: false,
      codeString: ''
    }
  },
  watch: {
    modalOpened (val) {
      this.codeString = this.value
    }
  },
  methods: {
    open () {
      this.modalOpened = true
    },
    onSaveClick () {
      this.$emit('input', this.codeString)
      this.modalOpened = false
    },
    onCancelClick () {
      this.modalOpened = false
    }
  },
  mounted () {
    this.codeString = this.value
  }
}
</script>
