<template>
  <form class="page-form side-form" @submit.prevent="submitForm">
    <p v-if="$v.$invalid" class="aside-message">
      {{ validationStatus }}
    </p>

    <div class="side-form__section">
      <!-- @notice: here I remove Blue theme from available options,
        but it still exist in some already created spreadViews -->
      <ThemePicker v-model="options.appearance.theme" />
      <div class="side-form__item is-justified">
        <p class="side-form__item-label">Website background color</p>
        <ColorPicker style="top: 0;" :value="options.appearance.bgColor"
          @input="val => options.appearance.bgColor = val" />
      </div>
      <div class="side-form__item is-justified">
        <div class="side-form__item-label">Show 'Scroll to top' button</div>
        <label :class="{ 'selected': options.appearance.scrollToTopBtn }" class="switcher">
          <input v-model="options.appearance.scrollToTopBtn" class="switcher__input" type="checkbox"
            @change="$trackFeature('design', 'scrollToTopBtn', options.appearance.scrollToTopBtn)">
          <span class="switcher__indicator"></span>
        </label>
      </div>
      <div class="side-form__item is-justified">
        <div class="side-form__item-label">Сhoose global Google Fonts for your website</div>
      </div>
      <div class="side-form__item is-justified">
        <FormField label="Heading Font" class="side-form__item aside-input">
          <v-autocomplete :value="options.appearance.globalFonts.headingFont" :items="popularGoogleFontsLabels"
            item-text="label" item-value="value" clearable attach deletable-chips placeholder="Use default font"
            append-icon="keyboard_arrow_down" @input="updateGoogleFont"></v-autocomplete>
        </FormField>
      </div>
      <div class="side-form__item is-justified">
        <FormField label="Body Text Font" class="side-form__item aside-input">
          <v-autocomplete :value="options.appearance.globalFonts.bodyTextFont" :items="popularGoogleFontsLabels"
            item-text="label" item-value="value" clearable attach deletable-chips placeholder="Use default font"
            append-icon="keyboard_arrow_down" @input="updateGoogleFont($event, 'body')"></v-autocomplete>
        </FormField>
      </div>
    </div>

    <CollapsableBlock v-if="options.header" :expanded="false" class="side-form__section" title="Header">
      <div class="side-form__item is-justified">
        <div class="side-form__item-label">Show header</div>
        <label :class="{ 'selected': options.header.showHeader }" class="switcher">
          <input v-model="options.header.showHeader" class="switcher__input" type="checkbox"
            @change="$trackFeature('design', 'showHeader', options.header.showHeader)">
          <span class="switcher__indicator"></span>
        </label>
      </div>
      <template v-if="options.header.showHeader">
        <div class="side-form__item is-justified">
          <div class="side-form__item-label">Compact menu</div>
          <label :class="{ 'selected': options.header.sideBarMenuByDefault }" class="switcher">
            <input v-model="options.header.sideBarMenuByDefault" class="switcher__input" type="checkbox">
            <span class="switcher__indicator"></span>
          </label>
        </div>
        <div class="side-form__item is-justified">
          <div class="side-form__item-label">Fix header on scroll</div>
          <label :class="{ 'selected': options.header.stickyHeader }" class="switcher">
            <input v-model="options.header.stickyHeader" class="switcher__input" type="checkbox">
            <span class="switcher__indicator"></span>
          </label>
        </div>
        <!-- <FormField label="Header view" class="side-form__item aside-select">
          <v-select
            v-model="options.header.headerLayout"
            :items="headerLayoutOptions"
            item-text="label"
            item-value="value"
            attach
            placeholder="Choose appearance"
            menu-props="offsetY"
            append-icon="keyboard_arrow_down"
            >
          </v-select>
        </FormField> -->
        <FormField label="Header background color" class="side-form__item aside-select">
          <v-select v-model="options.header.headerStyle" :items="headerStyleOptions" item-text="label"
            item-value="value" attach placeholder="Choose style" menu-props="offsetY" append-icon="keyboard_arrow_down">
          </v-select>
        </FormField>
        <div class="side-form__item is-justified">
          <p class="side-form__item-label">Header button color</p>
          <ColorPicker style="top: 0;" :value="options.header.headerButtonsColor"
            @input="val => options.header.headerButtonsColor = val" />
        </div>
        <FormField label="Header Logo" class="side-form__item">
          <UploadImage v-model="options.header.headerLogoImage" buttonText="Upload a logo" />
        </FormField>
        <FormField label="Header tagline" class="side-form__item aside-input">
          <v-text-field v-model.trim="options.header.headerTagline" />
        </FormField>
        <FormField class="side-form__item aside-input">
          <div class="content-form__switch-field">
            <div class="content-form__switcher-wrap">
              <div class="content-form__switcher-label">Link logo to external URL</div>
              <label :class="{ 'selected': options.header.headerLogoExternalURL.enabled }" class="switcher">
                <input v-model="options.header.headerLogoExternalURL.enabled" class="switcher__input" type="checkbox">
                <span class="switcher__indicator"></span>
              </label>
            </div>
          </div>
        </FormField>
        <FormField v-if="options.header.headerLogoExternalURL.enabled" label="Logo external URL"
          class="side-form__item aside-input">
          <v-text-field v-model.trim="options.header.headerLogoExternalURL.link" placeholder="https://" />
          <p v-if="!$v.options.header.headerLogoExternalURL.link.required || !$v.options.header.headerLogoExternalURL.link.url"
            class="form-field__error">
            {{ !$v.options.header.headerLogoExternalURL.link.required ? $t('global.fieldIsRequired') :
              $t('global.notValidUrl') }}
          </p>
        </FormField>
      </template>
    </CollapsableBlock>

    <CollapsableBlock v-if="options.footer" :expanded="false" class="side-form__section" title="Footer">
      <div class="side-form__item is-justified">
        <p class="side-form__item-label">Show footer</p>
        <label :class="{ 'selected': options.footer.showFooter }" class="switcher">
          <input v-model="options.footer.showFooter" class="switcher__input" type="checkbox" value="tiles"
            @change="$trackFeature('design', 'showFooter', options.footer.showFooter)">
          <span class="switcher__indicator"></span>
        </label>
      </div>
      <div class="side-form__item is-justified">
        <p class="side-form__item-label">Show social media icons</p>
        <label :class="{ 'selected': options.footer.showSocialNetworks }" class="switcher">
          <input v-model="options.footer.showSocialNetworks" class="switcher__input" type="checkbox" value="tiles"
            @change="$trackFeature('design', 'showSocialNetworks', options.footer.showSocialNetworks)">
          <span class="switcher__indicator"></span>
        </label>
      </div>
      <div class="side-form__item tile-constructor__hint" style="margin-top: 0;">
        <a target="_blank" href="https://help.spreadsimple.com/en/article/adding-social-media-icons-kzg8iq/">Help: How
          to
          add social media icons</a>
      </div>
      <div v-if="options.footer.showFooter" class="side-form__item">
        <MarkdownComponent v-model="options.footer.footerText" :preview="false"
          leftToolbar="bold italic strikethrough link" />
      </div>
    </CollapsableBlock>

    <CollapsableBlock :expanded="false" class="side-form__section" title="Home page intro">
      <div class="side-form__item is-justified">
        <div class="side-form__item-label">Show home page intro</div>
        <label :class="{ 'selected': options.appearance.showHomeIntro }" class="switcher">
          <input v-model="options.appearance.showHomeIntro" class="switcher__input" type="checkbox"
            @change="$trackFeature('design', 'showHomeIntro', options.appearance.showHomeIntro)">
          <span class="switcher__indicator"></span>
        </label>
      </div>
      <FormField label="Page title" class="side-form__item aside-input with-colopicker">
        <v-text-field v-model.trim="$v.options.page.title.$model"></v-text-field>
        <ColorPicker v-model="options.page.titleColor" />
        <p v-if="!$v.options.page.title.maxLength" class="n-form-field__error-message">
          {{ $t('global.inputMaxLengthError') }}
        </p>
      </FormField>
      <FormField label="Page subtitle" class="side-form__item aside-input with-colopicker">
        <v-text-field v-model.trim="$v.options.page.subtitle.$model"></v-text-field>

        <ColorPicker v-model="options.page.subtitleColor" />
        <p v-if="!$v.options.page.subtitle.maxLength" class="n-form-field__error-message">
          Max lenght of the value should be less then 300 symbols
        </p>
      </FormField>

      <FormField label="Logo" class="side-form__item">
        <UploadImage v-model="options.appearance.logoImage" buttonText="Upload a logo" />
      </FormField>
      <FormField label="Cover Image" class="side-form__item">
        <UploadImage v-model="options.appearance.coverImage" buttonText="Upload a cover image" />
      </FormField>

      <div class="side-form__item">
        <div :class="{ 'side-form__item-disabled': options.appearance.introKeepAspectRatio }">
          <CustomOptionsPicker v-model="options.appearance.coverLayout" :options="coverLayoutOptions"
            label="Cover layout" />
          <div v-if="options.appearance.introKeepAspectRatio" class="side-form__item-label">
            You can not use this option along with "Keep intro aspect ratio"
          </div>
        </div>
      </div>
      <div class="side-form__item is-justified">
        <div class="side-form__item-label">Keep intro image aspect ratio</div>
        <label :class="{ 'selected': options.appearance.introKeepAspectRatio }" class="switcher">
          <input v-model="options.appearance.introKeepAspectRatio" class="switcher__input" type="checkbox">
          <span class="switcher__indicator"></span>
        </label>
      </div>
    </CollapsableBlock>

    <CollapsableBlock :expanded="false" class="side-form__section" title="Home page content">
      <FormField label="Description" class="side-form__item">
        <MarkdownComponent v-model="options.page.contentBeforeDataMd" :preview="false"
          leftToolbar="bold italic strikethrough link" />
      </FormField>
      <FormField label="Text after cards" class="side-form__item">
        <MarkdownComponent v-model="options.page.contentAfterDataMd" :preview="false"
          leftToolbar="bold italic strikethrough link" />
      </FormField>
    </CollapsableBlock>
    
    <CollapsableBlock v-if="options.header" :expanded="false" class="side-form__section">
      <template slot="header">
        <span class="side-form__section-title">Mobile Bottom Menu</span>
      </template>
      <div class="side-form__item is-justified">
        <div class="side-form__item-label">Enabled</div>
        <label :class="{ 'selected': options.header.mobileBottomNavigationEnabled }" class="switcher">
          <input v-model="options.header.mobileBottomNavigationEnabled" class="switcher__input" type="checkbox">
          <span class="switcher__indicator"></span>
        </label>
      </div>
      <template v-if="options.header.mobileBottomNavigationEnabled">
        <FormField label="Mobile menu items" class="side-form__item aside-input">
          <v-select :value="options.header.mobileBottomNavigationItems" :items="menuItemsOptions" item-text="label"
            item-value="value" chips attach multiple deletable-chips append-icon="keyboard_arrow_down"
            @input="updateMobileMenuItems">
            <template slot="selection" slot-scope="data">
              <DraggableChip :id="data.index" v-model="options.header.mobileBottomNavigationItems">
                {{ data.item.label }}
              </DraggableChip>
            </template>
          </v-select>
        </FormField>
      </template>
    </CollapsableBlock>
    <UiBtn size="l" attr-type="submit" :loading="loading" :disabled="loading || spreadViewerOptionsInvalid"
      class="aside-save">
      SAVE
    </UiBtn>
  </form>
</template>
<script>
import { maxLength, required, url } from 'vuelidate/lib/validators'
import Vuelidate from 'vuelidate'
import ThemePicker from '@/components/theme-picker/'
import CustomOptionsPicker from '@/components/custom-options-picker/'
import ColorPicker from '@/components/color-picker/'
import UploadImage from '@/components/upload-image/'
import FormField from '@/components/form-field/'
import UiBtn from '@/components/ui-btn'
import { coverOptions } from '@/modules/utils/coverOptions'
import { headerLayoutOptions, headerStyleOptions } from '@/modules/utils/headerOptions'
import CollapsableBlock from '@/components/collapsable-block'
import Vue from 'vue'
import MarkdownComponent from '@/components/markdown-component'
import { getPopularFirstGoogleFonts } from '@/modules/google-fonts/api.js'
import { mapActions, mapGetters } from 'vuex'
import DraggableChip from '@/components/draggable-chip/DraggableChip.vue'

Vue.use(Vuelidate)
export default {
  name: 'SpreadViewerPageOptions',
  components: {
    DraggableChip,
    ThemePicker,
    CustomOptionsPicker,
    ColorPicker,
    UploadImage,
    CollapsableBlock,
    FormField,
    UiBtn,
    MarkdownComponent
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean
    }
  },
  data() {
    return {
      debounceMs: 300,
      options: null,
      validationStatus: '',
      headerLayoutOptions,
      headerStyleOptions,
      popularGoogleFonts: [],
      menuItemsOptions: [
        {
          label: 'Website menu',
          value: 'open-menu'
        },
        {
          label: 'Sidebar filters',
          value: 'open-filters'
        },
        {
          label: 'Shopping cart',
          value: 'open-cart'
        },
        {
          label: 'Subscribe button (Universal Email Subscription add-on)',
          value: 'subscribe'
        },
        {
          label: 'Scroll to top',
          value: 'scroll-to-top'
        }
      ]
    }
  },
  validations() {
    if (this.options.header.headerLogoExternalURL.enabled) {
      return {
        options: {
          page: {
            title: {
              maxLength: maxLength(100)
            },
            subtitle: {
              maxLength: maxLength(300)
            }
          },
          appearance: {
            theme: {
              required
            }
          },
          header: {
            headerLogoExternalURL: {
              link: {
                url,
                required
              }
            }
          }
        }
      }
    } else {
      return {
        options: {
          page: {
            title: {
              maxLength: maxLength(100)
            },
            subtitle: {
              maxLength: maxLength(300)
            }
          },
          appearance: {
            theme: {
              required
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters('app', ['spreadViewerOptionsInvalid']),
    coverLayoutOptions() {
      return coverOptions.map(option => {
        return {
          ...option,
          icon: (option.value === 'is-fullscreen') ? 'icon-preview-fs' : 'icon-preview-small'
        }
      })
    },
    popularGoogleFontsLabels() {
      if (!this.popularGoogleFonts) { return }
      return this.popularGoogleFonts.map(font => font.family)
    }
  },
  watch: {
    options: {
      deep: true,
      handler(val) {
        if (this.$v.$invalid) {
          this.validationStatus = this.$t('pageManageSheet.settings.optionsSubmitError')
        }
      }
    },
    'options.appearance.theme.value'(val, prevVal) {
      if (prevVal && prevVal !== val) {
        // this.options.header.headerTextColor = this.options.appearance.theme.value === 'is-light' ? '#000000' : '#ffffff'
        this.options.header.headerButtonsColor = this.options.appearance.theme.value === 'is-light' ? '#1da2ff' : '#60606c'
        this.options.appearance.bgColor = this.options.appearance.theme.value === 'is-light' ? '#FCFCFF' : '#3B414C'
      }
    }
  },
  methods: {
    ...mapActions('app', ['setSpreadViewerOptionsValidationState']),
    submitForm() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.validationStatus = this.$t('global.formSubmitError')
        return
      }
      this.validationStatus = ''
      this.$emit('save', this.options)
    },
    updateColorsOnce() {
      if (!this.options.header.headerButtonsColor) {
        this.options.header.headerButtonsColor = this.options.appearance.theme.value === 'is-light' ? '#1da2ff' : '#60606c'
      }
      if (!this.options.appearance.bgColor) {
        this.options.appearance.bgColor = this.options.appearance.theme.value === 'is-light' ? '#FCFCFF' : '#3B414C'
      }
    },
    async updateGoogleFont(fontFamily, type = 'heading') {
      if (type === 'heading') {
        fontFamily
          ? this.options.appearance.globalFonts.headingFont = fontFamily
          : this.options.appearance.globalFonts.headingFont = ''
      } else {
        fontFamily
          ? this.options.appearance.globalFonts.bodyTextFont = fontFamily
          : this.options.appearance.globalFonts.bodyTextFont = ''
      }
    },
    updateMobileMenuItems(value) {
      this.options.header.mobileBottomNavigationItems = value
    }
  },
  created() {
    this.options = this.value
    this.updateColorsOnce()
  },
  async mounted() {
    this.popularGoogleFonts = await getPopularFirstGoogleFonts()
    this.$watch('$v.$invalid', (val) => {
      this.setSpreadViewerOptionsValidationState({
        optionName: 'designOptionsInvalid',
        isInvalid: val
      })
    })
  }
}
</script>
