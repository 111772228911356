export default {
  published: true,
  pro: true,
  featureRestrictionsGroup: 'automationIntegrations',
  name: 'integromat',
  title: 'Make (formerly Integromat)',
  headline: '',
  logoUrl: '/img/integrations/make.svg',
  // coverUrl: '/img/integrations/integromat-cover.png',
  bgColor: '#ad03b6',
  categories: ['Automation'],
  comingSoon: false,
  builtIn: true,
  url: 'https://www.make.com/en',
  helpUrl: 'https://help.spreadsimple.com/en/article/how-to-use-webhooks-for-automation-19majym/',
  description: `
  An automation online platform, "a glue of the internet", that allows you to automate your workflow.

  To connect your SpreadSimple website with other tools:
  1. Create a new scenario in your Make  dashboard
  2. Create a webhook and paste the webhook URL in **Webhook URL Field** in the 'Checkout' Tab on SpreadSimple.

  ![](https://spreadsimple.s3.us-east-2.amazonaws.com/7517d74c-8515-4183-b761-23c86c456fff_webhooks.png)

  <iframe width="100%" height="225" src="https://www.youtube.com/embed/yKaUCPgoRak" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

  `,

  formFields: [],

  actions: []
}
