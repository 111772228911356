export default {
  published: true,
  pro: true,
  featureRestrictionsGroup: 'paymentOnDeliveryIntegration',
  name: 'cod',
  title: 'Cash on delivery',
  headline: '',
  logoUrl: '/img/integrations/cod.svg',
  bgColor: 'rgb(255 120 68)',
  categories: ['Payment Gateway'],
  comingSoon: false,
  builtIn: false,
  url: '',
  helpUrl: '',
  description: 'Allow to submit order without payment.',

  formFields: [
    {
      name: 'enabled',
      type: 'toggle',
      label: 'Enabled',
      default: false,
      validations: [
        'required'
      ],
      secret: false
    },
    {
      name: 'customTitle',
      type: 'text',
      showIf: ['enabled'],
      label: 'Custom title',
      default: 'Cash on delivery',
      placeholder: '',
      validations: [
        'maxLength:50'
      ],
      secret: false
    },
    {
      name: 'customDescription',
      type: 'text',
      showIf: ['enabled'],
      label: 'Description',
      default: '',
      placeholder: '',
      validations: [
        'maxLength:100'
      ],
      secret: false
    }
  ],

  actions: []
}
