<template>
  <div class="side-form">
    <div class="side-form__item side-form__draggable-wrap">
      <div
        v-for="(field, index) in codes"
        :key="index"
        class="draggable__item"
        >
        <div class="draggable__item-title-wrap no-padding is-column" @click="onEditStart(field)">
          <span class="draggable__item-title">{{ field.code }} </span>
          <span class="draggable__item-subtitle"> used: {{field.used}} of {{field.limit}} </span>
        </div>
        <div class="draggable__item-controls">
          <el-dropdown
            trigger="click"
            class="draggable__item-control"
          >
            <i class="icon-gear"></i>
            <el-dropdown-menu slot="dropdown" class="draggable__dropdown-menu">
              <el-dropdown-item @click.native="onEditStart(field)">Edit</el-dropdown-item>
              <el-dropdown-item  @click.native="onDelete(field)">Delete</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>

    </div>
    <div class="side-form__btns">
      <button type="button" class="btn btn--transparent" @click="builderModalOpened = true">+ Add promo code</button>
    </div>
    <Modal
      v-model="builderModalOpened"
      :closeOnClickOutside="false"
      modalName="New promo code"
      :hasCloseButton="true"
    >
      <OptionItemBuilder
        :value="currentEditingField"
        schema="promocodeSchema"
        :schemaArray="promocodeSchemaUpdated"
        @input="onAddField"
        @on-edit="onEditEnd"
        @cancel-submission="onCancel"
      />
    </Modal>
  </div>
</template>
<script>
import api from '@/services/api/'
import Modal from '@/components/modal'
import OptionItemBuilder from '@/components/form-builder/OptionItemBuilder.vue'
import { promocodeSchema } from '@/components/form-builder/fieldParams.js'
import { mapState } from 'vuex'

export default {
  name: 'PromoCodesComponent',
  components: {
    Modal,
    OptionItemBuilder
  },
  data () {
    return {
      builderModalOpened: false,
      currentEditingField: null,
      codes: [
        // {
        //   id: 123,
        //   expirationDate: '2022-02-08T13:45:03.280Z',
        //   code: 'PROMOCODENAME',
        //   discount: {
        //     type: 'fixed',
        //     value: 50
        //   },
        //   sku: ['*'],
        //   limit: 100,
        //   used: 0
        // }
      ]
    }
  },
  computed: {
    ...mapState('app', [
      'currentSpreadViewId'
    ]),
    promocodeSchemaUpdated () {
      if (this.codes.length === 0) return promocodeSchema
      return JSON.parse(JSON.stringify(promocodeSchema)).map((item) => {
        if (item.name === 'code') {
          let codeNames = this.codes.map(i => i.code.toLowerCase())
          if (this.currentEditingField !== null) {
            const ind = codeNames.findIndex(j => j.toLowerCase() === this.currentEditingField.code.toLowerCase())
            codeNames.splice(ind, 1)
          }
          const isUniqueItemRule = item.validations.find(k => k.name === 'isUniqueItem')
          if (isUniqueItemRule) {
            isUniqueItemRule.args = codeNames
          } else {
            item.validations.push({
              name: 'isUniqueItem',
              args: codeNames
            })
          }
        }
        return item
      })
    }
  },
  watch: {
    builderModalOpened (val) {
      if (!val) {
        this.closeModalAndCleanData()
      }
    }
  },
  asyncOperations: {
    getPromocodes () {
      return api.getPromocodes(this.currentSpreadViewId)
    },
    createPromocode (data) {
      return api.createPromocode(this.currentSpreadViewId, data)
    },
    updatePromocode (promoId, payload) {
      return api.updatePromocode(this.currentSpreadViewId, promoId, payload)
    },
    deletePromocode (promoId) {
      return api.deletePromocode(this.currentSpreadViewId, promoId)
    }
  },
  methods: {
    setCodeStructure (val) {
      const date = new Date(val.expirationDate)
      const isoDate = date.toISOString()
      let skuArray = val.sku.split(',')
      skuArray = skuArray.map(element => {
        return element.trim()
      })
      return {
        id: val.id,
        expirationDate: isoDate,
        code: val.code,
        discount: {
          type: val.type,
          value: val.value
        },
        sku: skuArray,
        limit: val.limit
      }
    },
    setCodeForEditing (val) {
      return {
        id: val.id || '',
        expirationDate: val.expirationDate,
        limit: val.limit,
        code: val.code,
        type: val.discount.type,
        value: val.discount.value,
        sku: val.sku.join()
      }
    },
    async onAddField (val) {
      try {
        await this.$async.createPromocode.$perform(this.setCodeStructure(val))
        await this.getCodesList()
        this.closeModalAndCleanData()
      } catch (e) {
        console.error(e)
      }
    },
    onEditStart (val) {
      this.currentEditingField = this.setCodeForEditing(val)
      this.builderModalOpened = true
    },
    async onEditEnd (val) {
      try {
        await this.$async.updatePromocode.$perform(val.id, this.setCodeStructure(val))
        await this.getCodesList()
        this.closeModalAndCleanData()
      } catch (e) {
        console.error(e)
      }
    },
    onCancel () {
      this.closeModalAndCleanData()
    },
    async onDelete (val) {
      try {
        this.$async.deletePromocode.$perform(val.id)
        const ind = this.codes.findIndex(c => c.id === val.id)
        this.codes.splice(ind, 1)
      } catch (e) {
        console.log(e)
      }
    },
    closeModalAndCleanData () {
      this.builderModalOpened = false
      this.currentEditingField = null
    },
    async getCodesList () {
      const response = await this.$async.getPromocodes.$perform()
      this.codes = response
    }
  },
  async created () {
    try {
      await this.getCodesList()
    } catch (e) {
      console.error(e)
    }
  }
}
</script>
