<template>
  <div
    class="ui-msg-box"
    :class="[
      `ui-msg-box--color-${color}`,
      `ui-msg-box--size-${size}`
    ]"
  >
    <UiIcon
      v-if="icon"
      :icon="icon"
      class="ui-msg-box__icon"
    />
    <div class="ui-msg-box__title"><slot>{{ title }}</slot></div>
  </div>
</template>
<script>
import UiIcon from '@/components/ui-icon/UiIcon.vue'
export default {
  name: 'UiMsgBox',
  components: {
    UiIcon
  },
  props: {
    color: {
      type: String,
      default: 'primary',
      validator (color) {
        return ['primary', 'secondary', 'success', 'warning', 'danger'].includes(color)
      }
    },
    size: {
      type: String,
      default: 'm',
      validator (size) {
        return ['m', 's'].includes(size)
      }
    },
    icon: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    }
  }
}
</script>
