<template>
  <div>
    <div
      v-if="!uploadExist"
      class="uploader__btn"
      @click="unsplashModalOpened = true"
      >
        <div>
          {{ buttonText }}
          <i class="uploader__btn-icon icon-upload2"></i>
        </div>
    </div>
    <div v-else class="uploader">
      <div class="uploader__preview-wrap">
        <div
          class="uploader__preview"
          :style="{ backgroundImage: `url('${currentImage}')` }"
        />
      </div>
      <div class="uploader__controls-wrap">
        <div class="uploader__file-name">{{ currentImage }}</div>
        <div class="uploader__controls">
          <div
            class="uploader__change is-highlighted"
            @click="unsplashModalOpened = true"
            >
            <i class="icon-upload2"></i>
          </div>
          <div class="uploader__delete" @click="deleteImageFromOptions">
            <i class="icon-delete-bin"></i>
          </div>
        </div>
      </div>
    </div>
    <UploadImageModal
      :opened="unsplashModalOpened"
      :unsplashEnabled="unsplashEnabled"
      @input="(val) => currentImage = val"
      @on-close="unsplashModalOpened = false"

    />
  </div>
</template>
<script>
import UploadImageModal from './UploadImageModal.vue'
export default {
  name: 'UploadImage',
  components: {
    UploadImageModal
  },
  props: {
    value: {
      type: String
    },
    buttonText: {
      type: String,
      required: true
    },
    unsplashEnabled: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      currentImage: '',
      unsplashModalOpened: false
    }
  },
  computed: {
    uploadExist () {
      return this.value !== '' || undefined
    }
  },
  watch: {
    currentImage (val) {
      this.$emit('input', val)
    }
  },
  methods: {
    deleteImageFromOptions () {
      this.$emit('input', '')
    }
  },
  created () {
    this.currentImage = this.value
  }
}
</script>
