export const markerTypes = [
  { label: 'Pin', value: 'pin' },
  { label: 'Dot', value: 'dot' },
  { label: 'Image', value: 'image' }
]

export const viewTypes = [
  { label: 'Map View', value: 'mapView' },
  { label: 'List View', value: 'listView' }
]
