import api from '@/services/api/'
import { sheetContentPipelineRead, sheetContentPipelineWrite } from 'google-sheets-data'
import { mergeOptions } from 'spreadview-options'

const spreadViewData = async (id, init = true) => {
  const errorContent = JSON.stringify({
    status: 'NOCOLSFOUND',
    message: 'Some troubles in your content. Probably you`ve shared an empty table. Try to fill it out with data first'
  })
  let readResponse
  const websiteSettings = await api.getView(id)
  const readData = async () => {
    const readResponse = await sheetContentPipelineRead({
      sid: websiteSettings.sid,
      spreadViewData: websiteSettings,
      subRoute: 'scheme',
      query: init ? { 'init': true, 'timestamp': Date.now() } : ''
    })
    return readResponse
  }
  readResponse = await readData()

  if (readResponse.statusCode === 404) {
    // await 2-3s to make sure there in no update running right now
    await new Promise((resolve) => {
      setTimeout(() => { resolve() }, 5000)
    })

    // readResponse again
    readResponse = await readData()

    // if still 404 -> drop hash
    if (readResponse.statusCode === 404) {
      await api.updateView(id, { sheetHash: '' })
    }

    const writeResponse = await sheetContentPipelineWrite({
      sid: websiteSettings.sid,
      spreadViewData: websiteSettings,
      shouldAwait: true
    })

    if (init && (writeResponse.statusCode === 200 || writeResponse.statusCode === 201 || writeResponse.statusCode === 408)) {
      const refetchData = await spreadViewData(id, false)
      return refetchData
    } else {
      const errorContent = JSON.stringify({
        status: writeResponse.statusCode,
        message: writeResponse.message
      })
      throw new Error(`${errorContent}`)
    }
  } else if (readResponse.cols && readResponse.cols.length) {
    return {
      data: {
        ...websiteSettings,
        options: mergeOptions(websiteSettings.options)
      },
      cols: readResponse.cols
    }
  } else {
    throw new Error(`${errorContent}`)
  }
}

export default spreadViewData
