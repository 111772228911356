export default {
  published: true,
  pro: true,
  featureRestrictionsGroup: 'paymentIntegrations',
  name: 'stripe',
  title: 'Stripe',
  logoUrl: '/img/integrations/stripe.svg',
  coverUrl: '/img/integrations/stripe-cover.png',
  bgColor: '#6576DE',
  categories: ['Payment Gateway'],
  comingSoon: false,
  builtIn: false,
  url: 'https://stripe.com',
  helpUrl: 'https://help.spreadsimple.com/en/article/stripe-1p4bv4n/?1610127653762',
  description: `
  A complete payments platform, engineered for growth.

  Simplify your integration using  <a href="https://stripe.com/en-nl/payments/checkout" target="_blank" >Stripe Checkout</a>. It dynamically adapts to your customer’s device and location to increase conversion.

  <a href="https://support.stripe.com/questions/locate-api-keys-in-the-dashboard" target="_blank">How to find API keys in the Stripe Dashboard</a>

  You also can optionally create <a href="https://dashboard.stripe.com/test/shipping-rates" target="_blank">Shipping Rate</a> and <a href="https://dashboard.stripe.com/test/tax-rates" target="_blank">Tax Rate</a> in Stripe Dashboard and add their ID to add those rates during Stripe Checkout.

  Also you can <a href="https://dashboard.stripe.com/settings/branding" target="_blank">customize the Stripe checkout page style</a>.
  `,

  functions: {
    checkSandboxKey: (mode) => (value) => {
      if (mode === 'sandbox' && !(value.startsWith('pk_test_') || value.startsWith('sk_test_'))) return false
      return true
    }
  },

  formFields: [
    {
      name: 'enabled',
      type: 'toggle',
      label: 'Enabled',
      default: false,
      validations: [
        'required'
      ],
      secret: false
    },
    {
      name: 'allowPromoCodes',
      type: 'toggle',
      showIf: ['enabled'],
      label: 'Allow Promotional Codes',
      default: false,
      validations: [],
      secret: false
    },
    {
      name: 'collectShippingAddress',
      type: 'toggle',
      showIf: ['enabled'],
      label: 'Enable Shipping Address',
      default: false,
      validations: [],
      secret: false
    },
    {
      name: 'currency',
      type: 'text',
      showIf: ['enabled'],
      label: 'Currency Code',
      default: 'USD',
      placeholder: 'USD',
      validations: [
        'required',
        'minLength:2',
        'maxLength:4'
      ],
      secret: false
    },
    {
      name: 'buttonText',
      type: 'text',
      showIf: ['enabled'],
      label: 'Button Text',
      default: '💳 Continue to payment...',
      placeholder: 'Continue...',
      validations: [
        'required',
        'minLength:2',
        'maxLength:50'
      ],
      secret: false
    },
    {
      name: 'publishableKey',
      type: 'text',
      showIf: ['enabled'],
      label: 'Publishable Key',
      default: '',
      placeholder: 'pk_xxxx_xxxxxxxxxxxxxxxxxxxxxxxx...',
      validations: [
        'required',
        'startsWith:pk_live_:pk_test_',
        'maxLength:200',
        'runInternalValidationFunction:checkSandboxKey'
      ],
      secret: false
    },
    {
      name: 'secretKey',
      type: 'text',
      showIf: ['enabled'],
      label: 'Secret Key',
      default: '',
      placeholder: 'sk_xxxx_xxxxxxxxxxxxxxxxxxxxxxxx...',
      validations: [
        'required',
        'startsWith:sk_live_:sk_test_',
        'maxLength:200',
        'runInternalValidationFunction:checkSandboxKey'
      ],
      secret: true
    },
    {
      name: 'taxRateId',
      type: 'text',
      showIf: ['enabled'],
      label: 'Tax Rate ID (optional)',
      default: '',
      placeholder: 'txr_xxxxxxxxxxxxxxxxxxxxxxxx',
      validations: [
        'minLength:28',
        'maxLength:28'
      ],
      secret: false
    },
    {
      name: 'shippingRateId',
      type: 'text',
      showIf: ['enabled'],
      label: 'Shipping Rate ID (optional)',
      default: '',
      placeholder: 'shr_xxxxxxxxxxxxxxxxxxxxxxxx',
      validations: [
        'minLength:28',
        'maxLength:28'
      ],
      secret: false
    },
    {
      name: 'customTitle',
      type: 'text',
      showIf: ['enabled'],
      label: 'Custom title',
      default: 'Stripe',
      placeholder: '',
      validations: [
        'maxLength:50'
      ],
      secret: false
    },
    {
      name: 'customDescription',
      type: 'text',
      showIf: ['enabled'],
      label: 'Description',
      default: '',
      placeholder: '',
      validations: [
        'maxLength:100'
      ],
      secret: false
    }
  ],

  actions: [
    {
      type: 'injectToHead',
      run () {
        return '<script src="https://js.stripe.com/v3/"></script>'
      }
    }
  ]
}
