var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "draggable",
    _vm._b(
      {
        attrs: { id: _vm.id, list: _vm.value, move: _vm.move },
        on: { change: _vm.change }
      },
      "draggable",
      _vm.dragOptionsChips,
      false
    ),
    [
      _c(
        "v-chip",
        {
          key: _vm.id,
          attrs: { draggable: "", close: "" },
          on: {
            mousedown: function($event) {
              $event.stopPropagation()
            },
            click: function($event) {
              $event.stopPropagation()
            },
            input: function() {
              return _vm.onChipDeleteClick(_vm.id)
            }
          }
        },
        [_vm._t("default")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }