export default {
  published: true,
  pro: false,
  featureRestrictionsGroup: 'builtInIntegrations',
  name: 'unsplash',
  title: 'Unsplash',
  headline: '',
  logoUrl: '/img/integrations/unsplash.svg',
  // coverUrl: '/img/integrations/unsplash-cover.png',
  bgColor: '#000000',
  categories: ['Photo'],
  comingSoon: false,
  builtIn: true,
  url: 'https://unsplash.com',
  helpUrl: '',
  description: `
  This is a built-in integration.

  You can use Unsplash to get royalty-free images for:
  - the cover image in your 'Design' Tab.
  - your pages content in the **Page Editor**.
  ![](https://spreadsimple.s3.us-east-2.amazonaws.com/852ed929-0353-4968-8030-42d680325b03_unsplash.png)"


  `,

  formFields: [],

  actions: []
}
