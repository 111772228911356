var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      attrs: {
        value: _vm.value,
        modalName: _vm.$t("pageManageSheet.modal.titlePublish")
      },
      on: {
        input: function(val) {
          return _vm.$emit("input", val)
        }
      }
    },
    [
      _c("div", { staticClass: "publish-modal" }, [
        _c("div", { staticClass: "publish-modal__published-state" }, [
          _c("div", { staticClass: "publish-modal__result-field" }, [
            _c("input", {
              ref: "code",
              staticClass: "publish-modal__result-field-url",
              attrs: { type: "text", readonly: "" },
              domProps: { value: _vm.resultUrl }
            }),
            _c("button", {
              staticClass: "publish-modal__result-field-copy icon-duplicate2",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.copyToClipboard.apply(null, arguments)
                }
              }
            })
          ]),
          _c("div", { staticClass: "publish-modal__back-step for-published" }, [
            _vm._v("You can "),
            _c(
              "button",
              {
                staticClass: "publish-modal__back-step-button",
                on: {
                  click: function($event) {
                    return _vm.onChangeDomain()
                  }
                }
              },
              [_vm._v("change domain")]
            )
          ]),
          _c(
            "a",
            {
              staticClass: "publish-modal__button for-published",
              class: { disabled: _vm.publishingInProgress },
              attrs: { target: "_blank", href: _vm.resultUrl }
            },
            [
              !_vm.publishingInProgress
                ? _c("span", [_vm._v("Open")])
                : _c("div", { staticClass: "icon-loader" })
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }