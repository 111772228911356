<template>
  <Modal
    :value="value"
    :modalName="modalTitle"
    :closeOnClickOutside="false"
    alignTitleLeft
    @input="closeModal"
  >
    <div>
      <Preloader :loading="isLoading" />
      <DiscountOffer
        v-if="!isLoading && quizStep === 'DiscountOffer'"
        :features="features"
        :hasDiscount="hasDiscount"
        @take-discount="takeDiscount"
        @unsubscribe="proceedToReasons"
        @close-modal="closeModal"
      />
      <DiscountTaken
        v-if="!isLoading && quizStep === 'DiscountTaken'"
        :nextBillingPeriod="currentDeal.nextBillDate"
        :nextBillingSum="nextBillingSum"
        @close-modal="closeModal"
      />
      <UnsubscribeReasons
        v-if="!isLoading && quizStep === 'UnsubscribeReasons'"
        :reasons="reasons"
        :quizError="quizError"
        :pending="isLoading"
        @close-modal="closeModalReasons"
        @reason-checked="reasonChecked"
        @reason-detailed="reasonDetailed"
        @submit-quiz="submitQuiz"
      />
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/modal'
import DiscountOffer from './DiscountOffer.vue'
import DiscountTaken from './DiscountTaken.vue'
import UnsubscribeReasons from './UnsubscribeReasons.vue'
import api from '@/services/api/'
import { mapState } from 'vuex'
import { features, reasons } from './config.js'

export default {
  name: 'UnsubscribeQuiz',
  components: {
    Modal,
    DiscountOffer,
    DiscountTaken,
    UnsubscribeReasons
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    currentDeal: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isLoading: false,
      quizStep: 'DiscountOffer',
      modifiers: [],
      features,
      reasons,
      quizError: null,
      nextBillingSum: 0
    }
  },
  computed: {
    ...mapState('app', [
      'userData',
      'unsubscribeModifierDescription'
    ]),
    someDiscountTaken () {
      if (!this.userData.temporaryDiscounts) {
        return false
      }
      return this.userData.temporaryDiscounts.some(td => {
        return td.applied && td.appliedOnSubscriptionId === this.currentDeal.subscriptionId
      })
    },
    modalTitle () {
      switch (this.quizStep) {
        case 'DiscountOffer':
          return 'Are you sure you want to unsubscribe?'
        case 'DiscountTaken':
          return 'You have successfully applied your discount'
        case 'UnsubscribeReasons':
          return 'Why are you unsubscribing?'
        default: return ''
      }
    },
    quizResult () {
      return this.reasons.reduce((result, reason) => {
        if (reason.checked === true) {
          const value = reason.details && reason.details.value ? reason.details.value : 'yes'
          result[reason.key] = value
        }
        return result
      }, {})
    },
    hasDiscount () {
      if (this.someDiscountTaken) return true
      if (!this.modifiers) return false
      return this.modifiers.some(modifier => modifier.description === this.unsubscribeModifierDescription)
    }
  },
  watch: {
    quizResult () {
      this.quizError = null
    },
    value (val) {
      if (!val) this.quizStep = 'DiscountOffer'
    },
    modifiers (val) {
      if (!val) this.getModifiers()
    }
  },
  methods: {
    async getModifiers () {
      try {
        this.isLoading = true
        const { response } = await api.paddleGetModifiersProxy({
          subscription_id: this.currentDeal.subscriptionId
        })
        this.modifiers = response
        return response
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading = false
      }
    },
    async takeDiscount () {
      this.$trackEvent({
        'event': 'unsubscribeFlowIWillTakeDiscount'
      })
      try {
        this.isLoading = true
        await api.paddleCreateModifierProxy({
          modifierName: this.unsubscribeModifierDescription,
          subscriptionId: this.currentDeal.subscriptionId
        })
        this.$trackEvent({
          'event': 'unsubscribeFlowDiscountApplied'
        })
        this.nextBillingSum = await this.getUserPaymentInfo()
        await this.getModifiers()
      } catch (e) {
        console.warn(e)
      } finally {
        this.isLoading = false
        this.quizStep = 'DiscountTaken'
      }
    },
    async getUserPaymentInfo () {
      const { response } = await api.paddleGetUsersList({
        subscriptionId: this.currentDeal.subscriptionId
      })
      return response[0].next_payment.amount
    },
    reasonChecked (e) {
      this.reasons.forEach(reason => {
        if (e.reason === reason.key) {
          reason.checked = e.value
        }
      })
    },
    reasonDetailed (e) {
      this.reasons.forEach(reason => {
        if (e.reason === reason.key) {
          reason.details.value = e.description
        }
      })
    },
    closeModalReasons () {
      this.reasons.forEach(reason => {
        if (reason.details) {
          reason.details.value = ''
        }
        reason.checked = false
      })
      this.closeModal()
    },
    async submitQuiz () {
      const isValid = Object.keys(this.quizResult).length > 0
      if (!isValid) {
        this.quizError = 'Please select at least one reason'
        return
      }
      try {
        await api.unsubscribeFeedback({
          data: this.quizResult
        })
        this.$trackEvent({
          'event': 'unsubscribeQuizSubmitted'
        })
      } catch (e) {
        console.warn(e)
      } finally {
        this.unsubscribe()
      }
    },
    closeModal () {
      this.$emit('close')
    },
    proceedToReasons () {
      this.$trackEvent({
        'event': 'unsubscribeFlowProceedToReasons'
      })
      this.quizStep = 'UnsubscribeReasons'
    },
    unsubscribe () {
      this.$emit('unsubscribe')
      this.$trackEvent({
        'event': 'unsubscribeInitiated'
      })
      this.closeModal()
    }
  },
  async created () {
    await this.getModifiers()
  }
}
</script>
