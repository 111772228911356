var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "side-form__section" }, [
      _c("h6", { staticClass: "side-form__section-title" }, [
        _vm._v("Categories")
      ]),
      _c(
        "div",
        { staticClass: "side-form__item" },
        [
          _c(
            "div",
            {
              staticClass: "side-form__filter-item",
              class: { "is-active": _vm.value === "_ALL_" },
              on: {
                click: function($event) {
                  return _vm.setCategory("_ALL_")
                }
              }
            },
            [
              _c("span", { staticClass: "side-form__filter-item-title" }, [
                _vm._v(_vm._s(_vm.getCategoryItemWithValueAll().title))
              ]),
              _c("div", { staticClass: "side-form__filter-item-value" }, [
                _vm._v(_vm._s(_vm.getCategoryItemWithValueAll().count))
              ])
            ]
          ),
          _vm._l(_vm.sortedCategories, function(category) {
            return _c(
              "div",
              {
                key: category.value,
                staticClass: "side-form__filter-item",
                class: { "is-active": category.value === _vm.value },
                on: {
                  click: function($event) {
                    return _vm.setCategory(category.value)
                  }
                }
              },
              [
                _c("span", { staticClass: "side-form__filter-item-title" }, [
                  _vm._v(_vm._s(category.title))
                ]),
                _c("div", { staticClass: "side-form__filter-item-value" }, [
                  _vm._v(_vm._s(category.count))
                ])
              ]
            )
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }