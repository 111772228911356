<template>
  <div
    v-if="value"
    class="modal__wrapp"
    :class="{'modal__wrapp--fullscreen': fullscreen}"
    tabindex="0"
    @keydown.esc="onPressEscBtn"
  >
    <div
      v-click-outside="onClickOutside"
      v-loading="loading"
      class="modal"
      :style="`--maxWidth: ${maxWidth}; --top: ${top};`"
    >
      <button
        v-if="hasCloseButton"
        type="button"
        class="modal__close"
        @click.prevent="$emit('input', false)"
      >
        <i class="icon icon-close"></i>
      </button>
      <h4 v-if="modalName"  class="modal__title" :class="{ 'align-left': alignTitleLeft }">{{modalName}}</h4>
      <div class="modal__body">
        <slot name="modal-content"></slot>
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Modal',
  props: {
    alignTitleLeft: {
      type: Boolean,
      default: false
    },
    modalName: {
      type: String,
      default: ''
    },
    hasCloseButton: {
      type: Boolean,
      default: true
    },
    closeOnClickOutside: {
      type: Boolean,
      default: true
    },
    value: {
      type: Boolean,
      default: false
    },
    closeOnPressEscBtn: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: String,
      default: '620px'
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    top: {
      type: String,
      default: '10vh'
    }
  },
  watch: {
    value: {
      handler () {
        if (this.value) {
          const modal = document.getElementsByClassName('modal__wrapp')
          this.$nextTick(() => modal[0].focus())
        }
      }
    }
  },
  methods: {
    onClickOutside () {
      if (this.closeOnClickOutside) {
        this.$emit('input', false)
      }
    },
    onPressEscBtn () {
      if (this.closeOnPressEscBtn) {
        this.$emit('input', false)
      }
    }
  }
}
</script>
