export default {
  published: true,
  pro: true,
  featureRestrictionsGroup: 'membershipIntegrations',
  name: 'memberstack',
  title: 'Memberstack v1.0',
  logoUrl: '/img/integrations/memberstack.svg',
  // coverUrl: '/img/integrations/memberstack-cover.png',
  bgColor: '#017ace',
  categories: ['Membership'],
  comingSoon: false,
  builtIn: false,
  url: 'https://www.memberstack.com/features',
  helpUrl: 'https://help.spreadsimple.com/en/article/memberstack-1pz1ens/',
  description: `
  Memberstack lets you build members-only websites by setting membership plans, pricing and permissions for your existing website.

  <details>
  <summary>Where to find your Memberstack ID.</summary>

  1. Open <a href="https://app.memberstack.io/code" target="_blank">Memberstack Dashboard / Install Code section</a>
  2. Copy the data-memberstack-id value from the code:
  <a href="https://s3.us-east-2.amazonaws.com/uploads.spreadsimple/f00dd7a7-15dc-4a85-9dac-4935631beb82_ms-code.png" target="_blank">![](https://s3.us-east-2.amazonaws.com/uploads.spreadsimple/f00dd7a7-15dc-4a85-9dac-4935631beb82_ms-code.png)</a>

  _You don't need to inject the whole code snippet into the SpreadSimple Settings. Just put the ID into this add-on settings below. This way we can better protect your restricted data._
  </details>

  <br>

  *Memberstack features aren't available in the preview mode. To test it, please open the published website.*
  `,

  formFields: [
    {
      name: 'enabled',
      type: 'toggle',
      label: 'Enabled',
      default: false,
      validations: [
        'required'
      ],
      secret: false
    },
    {
      name: 'id',
      type: 'text',
      showIf: ['enabled'],
      label: 'Memberstack ID',
      default: '',
      placeholder: '3e287fdeb1************a7eda0dec2',
      validations: [
        'required',
        'minLength:32',
        'maxLength:32'
      ],
      secret: false
    }
  ],

  actions: [
    {
      type: 'injectToHead',
      run (formData) {
        console.log('ms injectToHead action')
        if (!formData.id) return
        return `
        <!-- Memberstack -->
        <script src="https://api.memberstack.io/static/memberstack.js?custom" data-memberstack-id="${formData.id}"> </script>
        `
      }
    }
  ]
}
