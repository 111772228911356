<template>
  <form @submit.stop.prevent="checkDomainAvaliability()">
    <p class="text--size-xs text--color-secondary">
      {{ $t('pageManageSheet.modal.title') }}
    </p>
    <ProOptionWrapper
      :featureName="'updateDefaultDomain'"
      class="mt-12"
    >
      <FormField :messages="[domainValidationErrors]">
        <div
          class="domain-field"
          :class="{'is-disabled': useCustomDomain}"
        >
          <span class="domain-field__prepend">https://</span>
          <input
            v-model="internalSubDomainValue"
            v-autowidth="{maxWidth: '100%', minWidth: '10px', comfortZone: 5}"
            :disabled="useCustomDomain"
            name="domain"
            type="text"
            class="domain-field__input"
          >
          <span class="domain-field__append">.{{ internalDomainBase }}</span>
        </div>
      </FormField>
    </ProOptionWrapper>

    <p class="text--size-xs text--color-secondary mt-12">
      Once you apply a license to your website, you can connect it to your custom domain or subdomain purchased from a domain provider.
    </p>

    <ProOptionWrapper
      :featureName="'updateCustomDomain'"
      class="mt-12"
    >
      <UiSwitcher
        v-model="useCustomDomain"
        label="Connect your own domain"
      />
      <FormField
        v-show="useCustomDomain"
        :messages="[customDomainValidationErrors]"
        class="mt-12"
      >
        <div
          class="domain-field"
          :class="{'is-disabled': !useCustomDomain}"
        >
          <span class="domain-field__prepend">https://</span>
          <input
            :disabled="!useCustomDomain"
            v-model="customDomainValue"
            type="text"
            class="domain-field__input"
            placeholder="Enter your domain"
          >
        </div>
      </FormField>
    </ProOptionWrapper>

    <div
      v-if="useCustomDomain && customDomainValue && customDomainIpInvalid"
      class="mt-12 text--size-xs text--color-secondary"
    >
      <UiMsgBox
        size="s"
        title="Awaiting DNS configuration"
        class="mt-8"
        color="warning"
      />
      <p class="mt-8">Set the following record on your DNS provider to continue:</p>
      <table
        width="100%"
        class="table mt-8"
      >
        <thead>
          <td>Type</td>
          <td>Name</td>
          <td>Value</td>
        </thead>
        <tbody>
          <tr>
            <td>{{customSubdomainDNSName === '@' ? 'A' : 'CNAME' }}</td>
            <td>{{customSubdomainDNSName}}</td>
            <td>{{customSubdomainDNSName === '@' ? spreadSimpleIp : spreadSimpleCname}}</td>
          </tr>
          <tr v-if="customSubdomainDNSName === '@'">
            <td>CNAME</td>
            <td>www</td>
            <td>{{spreadSimpleCname}}</td>
          </tr>
        </tbody>
      </table>
      <p class="mt-8">Keep in mind that this procedure may take up to 12 hours depends on your domain provider.</p>
      <UiBtn
        class="mt-8"
        size="s"
        attrType="button"
        type="secondary"
        fullWidth
        :disabled="checkingDns"
        :loading="checkingDns"
        @click="checkDomainDns"
        :label="checkingDns ? 'Checking...' : 'Refresh'"
      />
    </div>

    <div
      v-if="useCustomDomain && !checkingDns && !customDomainIpInvalid"
      class="mt-12 text--size-xs text--color-secondary"
    >
    <UiBtn
        v-if="(ssl === 'NOT_ISSUED' || ssl === 'FAILED')"
        label="Issue SSL certificate"
        attrType="button"
        type="secondary-blue"
        size="s"
        fullWidth
        :disabled="issuingSsl"
        :loading="issuingSsl"
        @click="issueSslSert"
      />

      <UiMsgBox
        v-if="ssl === 'ISSUING'"
        color="warning"
        title="SSL certificate issuing in progress"
        class="mt-8"
        size="s"
      />

      <UiMsgBox
        v-if="ssl === 'ISSUED'"
        color="success"
        title="SSL certificate issued successfully"
        class="mt-8"
        size="s"
      />
    </div>

    <div class="mt-12 mb-12" :class="{'ui-divider': useCustomDomain}"></div>
    <ProOptionWrapper
      :featureName="'updateDefaultDomain' || 'updateCustomDomain'"
      class="mt-12"
    >
      <UiBtn
        label="Update domain"
        attrType="submit"
        fullWidth
        uppercase
        size="m"
        :disabled="loading || emptyCustomDomain"
        :loading="loading"
      />
    </ProOptionWrapper>
  </form>
</template>
<script>
import { getSubdomain } from 'tldts'
import ProOptionWrapper from '@/components/pro-option-wrapper'
import UiSwitcher from '@/components/ui-switcher'
import FormField from '@/components/form-field'
import UiBtn from '@/components/ui-btn'
import UiMsgBox from '@/components/ui-msg-box/UiMsgBox.vue'
import { mapState } from 'vuex'
import api from '@/services/api/'
// eslint-disable-next-line
const punycode = require('punycode')

export default {
  name: 'DomainSettingsForm',
  components: {
    ProOptionWrapper,
    UiSwitcher,
    FormField,
    UiBtn,
    UiMsgBox
  },
  data () {
    return {
      domainValidationErrors: '',
      customDomainValidationErrors: '',
      internalSubDomainValue: '',
      customDomainValue: '',
      ssl: '',
      useCustomDomain: false,
      customDomainIpInvalid: false,
      checkingDns: false,
      issuingSsl: false,
      internalDomainBase: process.env.VUE_APP_VIEWER_APP_BASE_DOMAINS,
      spreadSimpleIp: process.env.VUE_APP_A_RECORD_IP,
      spreadSimpleCname: process.env.VUE_APP_CNAME,
      timer: undefined,
      timerIsOn: false,
      loading: false
    }
  },
  watch: {
    'useCustomDomain': function (val) {
      if (!val) {
        this.customDomainValue = ''
        this.ssl = ''
        this.customDomainIpInvalid = false
      }
    },
    'ssl': function (val) {
      if (val === 'ISSUING') {
        this.startCount()
      }
      if (val === 'ISSUED' || val === 'FAILED') {
        this.stopCount()
      }
    },
    'timer': async function () {
      if (this.customDomainIpInvalid) {
        this.checkDomainDns()
      } else if (this.ssl === 'ISSUING') {
        const { ssl } = await this.checkSslStatus()
        this.ssl = ssl
      }
    }
  },
  computed: {
    ...mapState('app', [
      'currentSpreadViewData',
      'currentSpreadViewId'
    ]),
    customSubdomainDNSName () {
      const customDomain = this.customDomainValue ? this.customDomainValue : ''
      const subdomain = getSubdomain(customDomain)
      return subdomain && subdomain.length ? subdomain : '@'
    },
    emptyCustomDomain () {
      return this.useCustomDomain && this.customDomainValue?.length === 0
    }
  },
  methods: {
    timedCount () {
      this.timer = setTimeout(this.timedCount, 5000)
    },
    startCount () {
      if (!this.timerIsOn) {
        this.timerIsOn = true
        this.timedCount()
      }
    },
    stopCount () {
      clearTimeout(this.timer)
      this.timerIsOn = false
    },
    async updateSpreadViewDomain () {
      let val = {}
      if (this.useCustomDomain) {
        val = {
          customDomain: this.customDomainValue,
          ssl: this.ssl
        }
      } else {
        val = {
          domain: `${this.internalSubDomainValue}.${this.internalDomainBase}`,
          customDomain: ''
        }
      }
      try {
        await api.updateView(this.currentSpreadViewId, val)
        this.$toasted.show('Website was updated successfully', {
          theme: 'toasted-primary',
          position: 'top-center',
          type: 'success',
          duration: 1500
        })
        this.$root.$emit('update-domain-settings', {
          domain: `${this.internalSubDomainValue}.${this.internalDomainBase}`,
          customDomain: this.customDomainValue
        })
      } catch (e) {
        this.$toasted.show('Error, try again', {
          theme: 'toasted-primary',
          position: 'top-center',
          type: 'error',
          duration: 1500
        })
      }
    },
    async checkDomainAvaliability () {
      this.loading = true
      this.customDomainValidationErrors = ''
      this.domainValidationErrors = ''
      try {
        let newDomain = `${this.internalSubDomainValue.toLowerCase()}.${process.env.VUE_APP_VIEWER_APP_BASE_DOMAINS}`
        if (this.useCustomDomain) {
          const customDomainValue = this.customDomainValue.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0]
          newDomain = punycode.toASCII(customDomainValue)
        }
        await api.validateDomain(this.currentSpreadViewId, { domain: newDomain })
        if (this.useCustomDomain && newDomain !== this.customDomain) {
          this.ssl = 'NOT_ISSUED'
          this.checkDomainDns()
        }
        await this.updateSpreadViewDomain()
        this.$toasted.show('Domain was was changed successfully', {
          theme: 'toasted-primary',
          position: 'top-center',
          type: 'success',
          duration: 1500
        })
      } catch (e) {
        if (this.useCustomDomain) {
          this.customDomainValidationErrors = e.response.data.reason
        } else {
          this.domainValidationErrors = e.response.data.reason
        }
      } finally {
        this.loading = false
      }
    },
    async checkDomainDns () {
      this.checkingDns = true
      if (this.customDomainValue?.length === 0) {
        this.customDomainIpInvalid = false
        return
      }
      try {
        const response = await api.checkCustomDomainDns(this.customDomainValue)
        if (!response.v4.includes(this.spreadSimpleIp) || response.v6.length > 0) {
          this.customDomainIpInvalid = true
        } else if (this.ssl === 'NOT_ISSUED' || this.ssl === 'FAILED') {
          this.customDomainIpInvalid = false
          this.issueSslSert()
        }
      } catch (e) {
        this.customDomainIpInvalid = true
        console.log(`DNS error: ${e}`)
      } finally {
        this.checkingDns = false
      }
    },
    async issueSslSert () {
      this.issuingSsl = true
      try {
        await api.issueSsl(this.currentSpreadViewId)
        this.ssl = 'ISSUING'
      } catch (e) {
        console.warn(e.data.reason)
      } finally {
        this.issuingSsl = false
      }
    },
    async checkSslStatus () {
      try {
        return await api.getSslStatus(this.currentSpreadViewId)
      } catch (e) {
        console.warn(e)
      }
    }
  },
  created () {
    this.internalSubDomainValue = this.currentSpreadViewData.domain.split('.')[0]
    if (this.currentSpreadViewData.customDomain) {
      this.ssl = this.currentSpreadViewData.ssl
      this.customDomainValue = this.currentSpreadViewData.customDomain
      this.useCustomDomain = true
      this.checkDomainDns()
    }
  },
  beforeDestroy () {
    this.timer = undefined
    this.stopCount()
  }
}
</script>
