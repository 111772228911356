<template>
  <component
    :is="tag"
    @click="updatePlanAction()"
  >
    <slot/>
  </component>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
export default {
  name: 'UpgradePlanButton',
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    runAction: {
      type: Boolean,
      default: true
    },
    navigateToBilling: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('app', [
      'userData',
      'currentSpreadViewData'
    ]),
    ...mapGetters('app', [
      'userActiveDealsByType',
      'canSubscribeToPaidPlan'
    ]),
    userDealsExcludeAppsumo () {
      const userActiveDealsByType = JSON.parse(JSON.stringify(this.userActiveDealsByType))
      if (userActiveDealsByType.appsumo) {
        delete userActiveDealsByType.appsumo
      }
      return userActiveDealsByType
    }
  },
  methods: {
    ...mapActions('app', [
      'toggleModal'
    ]),
    requestSubscriptionModal (modalName, dealData, details) {
      this.$root.$emit('openSubscriptionModal', { modalName, dealData, details })
    },
    updatePlanAction () {
      if (!this.runAction) return
      if (this.canSubscribeToPaidPlan) {
        this.toggleModal({ name: 'pricingModal', value: true })
        return
      }
      const currentDealType = this.currentSpreadViewData.dealType
      const activeDealOfType = this.userDealsExcludeAppsumo[currentDealType]?.find(d => d.dealStatus === 'ACTIVE' && d.isPaddleSubscription)
      if (activeDealOfType) {
        this.requestSubscriptionModal('pricingModal', activeDealOfType, { switchPlan: true })
        return
      }
      if (this.navigateToBilling) {
        this.$router.push({ name: 'account-billing' })
        return
      }
      this.toggleModal({ name: 'offerToChangeWebsitePlan', value: true })
    }
  }
}
</script>
