export default {
  published: true,
  pro: true,
  featureRestrictionsGroup: 'chatIntegrations',
  name: 'crisp',
  title: 'Crisp',
  headline: '',
  logoUrl: '/img/integrations/crisp.svg',
  coverUrl: '/img/integrations/crisp-cover.png',
  bgColor: '#1972F5',
  categories: ['Chat'],
  comingSoon: false,
  builtIn: false,
  url: 'https://crisp.chat',
  helpUrl: '',
  description: `
  The all-in-one multichannel messaging platform that connects companies & customers

  How to find your **Website** ID in Crisp:

  ![](https://spreadsimple.s3.us-east-2.amazonaws.com/020573a3-ba71-456c-93c3-bbdcaf0bb74e_website-id-crisp.png)
  `,

  formFields: [
    {
      name: 'enabled',
      type: 'toggle',
      label: 'Enabled',
      default: false,
      validations: [
        'required'
      ],
      secret: false
    },
    {
      name: 'id',
      type: 'text',
      showIf: ['enabled'],
      label: 'Website ID',
      default: '',
      placeholder: 'd94ed63a-014e-44dd-a8bb-b93f52e56d62',
      validations: [
        'required',
        'minLength:3',
        'maxLength:40'
      ],
      secret: false
    }
  ],

  actions: [
    {
      type: 'injectToHead',
      run (formData) {
        return `
        <script type="text/javascript">window.$crisp=[];window.CRISP_WEBSITE_ID="${formData.id}";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();</script>
        `
      }
    }
  ]
}
