export default {
  published: true,
  pro: true,
  featureRestrictionsGroup: 'crmIntegrations',
  name: 'hubspot',
  title: 'Hubspot',
  headline: '',
  logoUrl: '/img/integrations/hubspot.svg',
  // coverUrl: '/img/integrations/hubspot-cover.png',
  bgColor: '#F8761F',
  categories: ['CRM', 'Email Marketing'],
  comingSoon: false,
  builtIn: false,
  url: 'https://hubspot.com',
  helpUrl: '',
  description: `
  HubSpot is a Customer/Contact Relationship Management tool (CRM) that not only manages your contacts, but also your sales, pipeline, digital marketing and lead nurture too.

  You can add an email subscription form to the intro section and also as a popup.

  **How to get your API token?**

  1. Log in to your HubSpot dashboard and go to Settings (⚙️) -> Integrations -> Private Apps
  2. Click "Create a private app"
  3. On the opened popup click on the "Scopes" tab
  4. Select "crm.objects.contacts" Write scope (you can use search to find it)
  5. Click "Create App", and copy the API token from the opened popup.
  `,

  formFields: [
    {
      name: 'enabled',
      type: 'toggle',
      label: 'Enabled',
      default: false,
      validations: [],
      secret: false
    },
    {
      name: 'apiKey',
      type: 'text',
      showIf: ['enabled'],
      label: 'API token',
      default: '',
      validations: ['required'],
      secret: true
    },
    {
      name: 'showIntro',
      type: 'toggle',
      showIf: ['enabled'],
      label: 'Show Form in Intro Section',
      default: false,
      validations: [],
      secret: false
    },
    {
      name: 'showPopup',
      type: 'toggle',
      showIf: ['enabled'],
      label: 'Show Floating Button and Popup',
      default: false,
      validations: [],
      secret: false
    },
    {
      name: 'title',
      type: 'text',
      showIf: ['enabled', 'showPopup'],
      label: 'Popup Title',
      default: '',
      placeholder: '',
      validations: [
        'required',
        'maxLength:160'
      ],
      secret: false
    },
    {
      name: 'subtitle',
      type: 'text',
      showIf: ['enabled', 'showPopup'],
      label: 'Popup Subtitle',
      default: '',
      placeholder: '',
      validations: [
        'maxLength:300'
      ],
      secret: false
    },
    {
      name: 'askForName',
      type: 'toggle',
      showIf: ['enabled'],
      label: 'Ask for First and Last Name',
      default: false,
      validations: [],
      secret: false
    },
    {
      name: 'buttonTitle',
      type: 'text',
      showIf: ['enabled'],
      label: 'Button Title',
      default: 'Subscribe',
      placeholder: 'Subscribe',
      validations: [
        'required',
        'maxLength:50'
      ],
      secret: false
    },
    {
      name: 'buttonColor',
      type: 'color',
      showIf: ['enabled'],
      label: 'Button Color',
      default: '#999999',
      validations: [
        'required'
      ],
      secret: false
    }
  ],

  actions: []
}
