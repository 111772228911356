var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "FormField",
        { staticClass: "tile-constructor__field aside-select" },
        [
          _c("CustomOptionsPicker", {
            attrs: {
              options: _vm.$options.singleItemLayoutOptions,
              label: "Layout type"
            },
            model: {
              value: _vm.layoutOptions.type,
              callback: function($$v) {
                _vm.$set(_vm.layoutOptions, "type", $$v)
              },
              expression: "layoutOptions.type"
            }
          })
        ],
        1
      ),
      _vm.layoutOptions.type !== "full-width"
        ? _c("div", { staticClass: "content-form__switch-field" }, [
            _c("div", { staticClass: "content-form__switcher-wrap" }, [
              _c("div", { staticClass: "content-form__switcher-label" }, [
                _vm._v("Reverse layout")
              ]),
              _c(
                "label",
                {
                  staticClass: "switcher",
                  class: { selected: _vm.layoutOptions.reverse }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.layoutOptions.reverse,
                        expression: "layoutOptions.reverse"
                      }
                    ],
                    staticClass: "switcher__input",
                    attrs: { type: "checkbox", value: "tiles" },
                    domProps: {
                      checked: Array.isArray(_vm.layoutOptions.reverse)
                        ? _vm._i(_vm.layoutOptions.reverse, "tiles") > -1
                        : _vm.layoutOptions.reverse
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.layoutOptions.reverse,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = "tiles",
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.layoutOptions,
                                "reverse",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.layoutOptions,
                                "reverse",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.layoutOptions, "reverse", $$c)
                        }
                      }
                    }
                  }),
                  _c("span", { staticClass: "switcher__indicator" })
                ]
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }