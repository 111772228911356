<template>
  <div
    v-if="loading"
    class="loader__wrapper"
  >
    <div class="loader__container">
      <img src="./preloader.gif" alt="" class="sv-img-reset">
      <div v-if="showProgress" class="loader__progress">
        <div
          class="loader__progress-bar"
          :style="`width: ${progressValue}%; transition: width ${progressSpeed / 1000}s linear`"
        ></div>
      </div>
      <p v-if="content" class="loader__string sv-text-reset">{{content}}</p>
    </div>
  </div>
</template>
<script>
import './loader.styl'
export default {
  name: 'Loader',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    imageSrc: {
      type: String,
      required: false
    },
    content: {
      type: String,
      required: false
    },
    showProgress: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      progressValue: 0,
      progressSpeed: 600
    }
  },
  watch: {
    loading: function () {
      if (this.loading && this.showProgress) {
        setInterval(() => {
          if (this.progressValue >= 90) {
            this.progressValue = 95
          } else {
            this.progressValue += 5
          }
        }, this.progressSpeed)
      } else {
        this.progressValue = 100
      }
    }
  }
}
</script>
