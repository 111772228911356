export default {
  published: true,
  pro: true,
  featureRestrictionsGroup: 'mapsIntegrations',
  name: 'osm-maps',
  title: 'OSM Maps',
  headline: '',
  logoUrl: '/img/integrations/osm-maps.svg',
  coverUrl: '',
  bgColor: '#76c551',
  categories: ['Maps'],
  comingSoon: false,
  builtIn: true,
  url: 'https://www.openstreetmap.org/',
  helpUrl: 'https://help.spreadsimple.com/en/article/open-street-map-add-on-a0ytys/',
  description: `
  OpenStreetMap is built by a community of mappers that contribute and maintain data about roads, trails, cafés, railway stations, and much more, all over the world.
  `,

  formFields: [],

  actions: []
}
