export default function checkColumns (cols, options) {
  const existingCols = cols.map(c => c.id)

  // check if all search fields exist
  options.search.searchByColumns = options.search.searchByColumns.filter(f => {
    return (existingCols.includes(f))
  })

  // check if all filters exists
  options.filters.values = options.filters.values.filter(f => {
    return (existingCols.includes(f.id))
  })

  // check ids arrays
  const multipleIds = [
    options.sorting,
    // grid card
    options.viewer.tileOptions.image,
    options.viewer.tileOptions.specs,
    options.viewer.tileOptions.badges,
    // single card
    options.viewer.singleItemOptions.image,
    options.viewer.singleItemOptions.specs,
    options.viewer.singleItemOptions.badges,
    // variants
    options.variants.variantsOptions
  ]

  multipleIds.forEach(array => {
    const filteredIds = array.ids.filter(i => {
      return (existingCols.includes(i))
    })
    array.ids = filteredIds
    return array
  })

  // check single ids
  const singleIds = [
    // grid card
    options.viewer.tileOptions.title,
    options.viewer.tileOptions.subtitle,
    options.viewer.tileOptions.price,
    options.viewer.tileOptions.oldPrice,
    options.viewer.tileOptions.content,
    options.viewer.tileOptions.cta,
    options.viewer.tileOptions.sku,
    options.stock,
    // single card
    options.viewer.singleItemOptions.title,
    options.viewer.singleItemOptions.subtitle,
    options.viewer.singleItemOptions.price,
    options.viewer.singleItemOptions.oldPrice,
    options.viewer.singleItemOptions.content,
    options.viewer.singleItemOptions.cta,
    options.viewer.singleItemOptions.status,
    options.viewer.singleItemOptions.url,
    // variants
    options.variants.variantsGroupBy
  ]

  singleIds.map(i => {
    if (existingCols.includes(i.id) || i.id === null || i.id === '') return
    i.id = ''
    return i
  })

  return options
}
