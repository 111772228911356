const billPlzEnvironments = [
  { label: 'Sandbox', value: 'sandbox' },
  { label: 'Production', value: 'production' }
]

export default {
  published: true,
  pro: true,
  featureRestrictionsGroup: 'paymentIntegrations',
  name: 'billplz',
  title: 'Billplz',
  logoUrl: '/img/integrations/billplz.svg',
  bgColor: '#3395ff',
  categories: ['Payment Gateway'],
  comingSoon: false,
  builtIn: false,
  url: 'https://billplz.com',
  helpUrl: 'https://help.spreadsimple.com/en/article/billplz-checkout-10gheg6/',
  description: `
  Billplz is a Malaysian online payment platform that provides merchants with flexibility and affordability in their transactions.

  Billplz is a payment platform for organizations to pay and get paid fastest, at the lowest cost possible

  - <a href="https://help.billplz.com/article/54-how-to-get-the-billplz-secret-key/" target="_blank">How to find Secret key</a>
  - <a href="https://help.billplz.com/article/120-how-to-enable-x-signature-key/" target="_blank">How to find XSignatureKey key</a>
  - <a href="https://help.billplz.com/article/55-how-to-get-the-collection-id" target="_blank">How to find Collection Id</a>
  `,

  functions: {
    checkSandboxMode: (mode) => (value) => {
      if (mode === 'sandbox' && value !== 'sandbox') return false
      return true
    }
  },

  formFields: [
    {
      name: 'enabled',
      type: 'toggle',
      label: 'Enabled',
      default: false,
      validations: [
        'required'
      ],
      secret: false
    },
    {
      name: 'buttonText',
      type: 'text',
      showIf: ['enabled'],
      label: 'Button Text',
      default: '💳 Continue to payment...',
      placeholder: 'Continue...',
      validations: [
        'required',
        'minLength:2',
        'maxLength:50'
      ],
      secret: false
    },
    {
      name: 'environment',
      type: 'select',
      showIf: ['enabled'],
      label: 'Environment',
      default: 'sandbox',
      placeholder: '',
      validations: ['required', 'runInternalValidationFunction:checkSandboxMode'],
      options: billPlzEnvironments,
      secret: false
    },
    {
      name: 'secretKey',
      type: 'text',
      showIf: ['enabled'],
      label: 'Secret Key',
      default: '',
      placeholder: '',
      validations: [
        'required',
        'maxLength:100'
      ],
      secret: true
    },
    {
      name: 'collectionId',
      type: 'text',
      showIf: ['enabled'],
      label: 'Collection Id',
      default: '',
      placeholder: '',
      validations: [
        'required',
        'maxLength:100'
      ],
      secret: true
    },
    {
      name: 'xSignatureKey',
      type: 'text',
      showIf: ['enabled'],
      label: 'xSignature Key',
      default: '',
      placeholder: '',
      validations: [
        'required',
        'maxLength:100'
      ],
      secret: true
    },
    {
      name: 'customTitle',
      type: 'text',
      showIf: ['enabled'],
      label: 'Custom title',
      default: 'Billplz',
      placeholder: '',
      validations: [
        'maxLength:50'
      ],
      secret: false
    },
    {
      name: 'customDescription',
      type: 'text',
      showIf: ['enabled'],
      label: 'Description',
      default: '',
      placeholder: '',
      validations: [
        'maxLength:100'
      ],
      secret: false
    }
  ],

  actions: []
}
