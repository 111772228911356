import { storeUserId, sendUserId, getGaClientId, trackEvent, deleteUserCookieData } from '../../modules/utils/analytics'
import { setPollsStates } from '../../modules/utils/pollsManager'
const apiDriver = (api, store) => {
  return {
    login: ({ idToken }) => api.googleAuth({ idToken, clientId: getGaClientId() })
      .then(data => {
        if (data.registration) {
          localStorage.setItem('ssShowInitialQuiz', true)
          trackEvent({
            'event': 'signUp'
          })

          // firstpromoter referral tracking script
          if (typeof window.fpr === 'function') {
            window.fpr('referral', { email: data.email, uid: data.id })
          }
        }
        return data
      }),
    refresh: (refreshToken) => api.refresh({ refreshToken }),
    logout: async () => {
      try {
        store.dispatch('app/clearUserData')
        store.dispatch('app/clearWorkspacesData')
        deleteUserCookieData()
        window.$crisp.push(['do', 'session:reset'])
      } catch (e) {
        console.warn('Error when deleting user cookie')
      }
    },
    user: () => api.getUserData()
      .then(me => {
        storeUserId(me) // this is for GTM (Google Analytics) to track a user
        sendUserId()
        setPollsStates(me.pollsStates)
        return me
      })
  }
}
export default apiDriver
