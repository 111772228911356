var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "side-form",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submitForm.apply(null, arguments)
        }
      }
    },
    [
      _vm.validationStatus !== ""
        ? _c("p", { staticClass: "aside-message" }, [
            _vm._v("\n    " + _vm._s(_vm.validationStatus) + "\n  ")
          ])
        : _vm._e(),
      _c("transition", { attrs: { name: "fade3", mode: "out-in" } }, [
        _vm.activeSection === ""
          ? _c(
              "div",
              { key: "1", staticClass: "side-form__subforms-switcher" },
              _vm._l(_vm.sections, function(section) {
                return _c(
                  "div",
                  {
                    key: section.name,
                    staticClass: "side-form__subforms-switcher-item",
                    on: {
                      click: function($event) {
                        _vm.activeSection = section.name
                      }
                    }
                  },
                  [
                    _c(
                      "h6",
                      {
                        staticClass: "side-form__subforms-switcher-item-title"
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(section.title) +
                            "\n          "
                        ),
                        section.name === "webhooks" &&
                        !_vm.isAllowedFeature("webhook")
                          ? _c("UiIcon", {
                              staticClass: "collapsable-block__lock-icon",
                              attrs: { icon: "mf-lock" }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c("i", {
                      staticClass:
                        "side-form__subforms-switcher-item-icon icon-carret-down"
                    })
                  ]
                )
              }),
              0
            )
          : _c("div", { key: "2", staticClass: "side-form" }, [
              _c(
                "button",
                {
                  staticClass: "side-form__back-btn",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      _vm.activeSection = ""
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "side-form__back-btn-icon icon-arrow"
                  }),
                  _vm._v("\n        Back to checkout\n      ")
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeSection === "formFields",
                      expression: "activeSection === 'formFields'"
                    }
                  ],
                  staticClass: "side-form__block-wrapper"
                },
                [
                  _c("h6", { staticClass: "side-form__section-title" }, [
                    _vm._v("Form fields")
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "tile-constructor__hint",
                      staticStyle: {
                        "margin-top": "-15px",
                        "margin-bottom": "10px"
                      }
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            target: "_blank",
                            href:
                              "https://help.spreadsimple.com/en/article/customizing-order-form-fields-1alashw/"
                          }
                        },
                        [_vm._v("Help: How to manage form fields")]
                      )
                    ]
                  ),
                  _c(
                    "ProOptionWrapper",
                    { attrs: { featureName: "checkout" } },
                    [
                      _c("FormBuilder", {
                        model: {
                          value: _vm.options.checkout.fields,
                          callback: function($$v) {
                            _vm.$set(_vm.options.checkout, "fields", $$v)
                          },
                          expression: "options.checkout.fields"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeSection === "cartSettings",
                      expression: "activeSection === 'cartSettings'"
                    }
                  ],
                  staticClass: "side-form__block-wrapper"
                },
                [
                  _c("h6", { staticClass: "side-form__section-title" }, [
                    _vm._v("Cart settings")
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "tile-constructor__hint",
                      staticStyle: {
                        "margin-top": "-15px",
                        "margin-bottom": "10px"
                      }
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            target: "_blank",
                            href:
                              "https://help.spreadsimple.com/en/article/cart-settings-65sm1g/"
                          }
                        },
                        [_vm._v("Help: How to set up cart view")]
                      )
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "side-form__section" },
                    [
                      _c(
                        "ProOptionWrapper",
                        {
                          staticClass: "side-form__item",
                          attrs: { featureName: "checkout" }
                        },
                        [
                          _c(
                            "FormField",
                            { attrs: { label: "Shopping cart view" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.cartViewOptions,
                                  "item-text": "label",
                                  "item-value": "value",
                                  "item-disabled": "disabled",
                                  attach: "",
                                  placeholder: "Choose cart view",
                                  "menu-props": "offsetY",
                                  "append-icon": "keyboard_arrow_down"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$trackFeature(
                                      "checkout",
                                      "cartView",
                                      true,
                                      _vm.options.cart.cartView
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.options.cart.cartView,
                                  callback: function($$v) {
                                    _vm.$set(_vm.options.cart, "cartView", $$v)
                                  },
                                  expression: "options.cart.cartView"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "ProOptionWrapper",
                        {
                          staticClass: "side-form__item",
                          attrs: { featureName: "checkout" }
                        },
                        [
                          _c(
                            "FormField",
                            { attrs: { label: "On item added to the cart" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.addToCartOptions,
                                  "item-text": "label",
                                  "item-value": "value",
                                  "item-disabled": "disabled",
                                  attach: "",
                                  placeholder: "Choose action",
                                  "menu-props": "offsetY",
                                  "append-icon": "keyboard_arrow_down"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$trackFeature(
                                      "checkout",
                                      "onAddToCart",
                                      true,
                                      _vm.options.cart.onAddToCart
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.options.cart.onAddToCart,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.options.cart,
                                      "onAddToCart",
                                      $$v
                                    )
                                  },
                                  expression: "options.cart.onAddToCart"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "ProOptionWrapper",
                        {
                          staticClass: "side-form__item",
                          attrs: { featureName: "checkout" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "side-form__switcher-wrap" },
                            [
                              _c(
                                "div",
                                { staticClass: "side-form__switcher-label" },
                                [_vm._v("Show cart preview")]
                              ),
                              _c(
                                "label",
                                {
                                  staticClass: "switcher",
                                  class: {
                                    selected: _vm.options.cart.showCartPreview
                                  }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.options.cart.showCartPreview,
                                        expression:
                                          "options.cart.showCartPreview"
                                      }
                                    ],
                                    staticClass: "switcher__input",
                                    attrs: { type: "checkbox", value: "tiles" },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.options.cart.showCartPreview
                                      )
                                        ? _vm._i(
                                            _vm.options.cart.showCartPreview,
                                            "tiles"
                                          ) > -1
                                        : _vm.options.cart.showCartPreview
                                    },
                                    on: {
                                      change: [
                                        function($event) {
                                          var $$a =
                                              _vm.options.cart.showCartPreview,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = "tiles",
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.options.cart,
                                                  "showCartPreview",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.options.cart,
                                                  "showCartPreview",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              _vm.options.cart,
                                              "showCartPreview",
                                              $$c
                                            )
                                          }
                                        },
                                        function($event) {
                                          return _vm.$trackFeature(
                                            "checkout",
                                            "showCartPreview",
                                            _vm.options.cart.showCartPreview
                                          )
                                        }
                                      ]
                                    }
                                  }),
                                  _c("span", {
                                    staticClass: "switcher__indicator"
                                  })
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm.options.cart.showCartPreview
                    ? _c("div", { staticClass: "side-form__section" }, [
                        _c(
                          "div",
                          { staticClass: "side-form__item" },
                          [
                            _c("p", { staticClass: "side-form__item-label" }, [
                              _vm._v("Cart preview title")
                            ]),
                            _c("p", { staticClass: "side-form__item-hint" }, [
                              _vm._v("Maximum length 25 characters")
                            ]),
                            _c(
                              "ProOptionWrapper",
                              { attrs: { featureName: "checkout" } },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value:
                                        _vm.$v.options.cart.сartPreviewSettings
                                          .title.$model,
                                      expression:
                                        "$v.options.cart.сartPreviewSettings.title.$model",
                                      modifiers: { trim: true }
                                    }
                                  ],
                                  staticClass: "input",
                                  attrs: {
                                    type: "text",
                                    placeholder: _vm.msg.yourCart
                                  },
                                  domProps: {
                                    value:
                                      _vm.$v.options.cart.сartPreviewSettings
                                        .title.$model
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.$v.options.cart.сartPreviewSettings
                                          .title,
                                        "$model",
                                        $event.target.value.trim()
                                      )
                                    },
                                    blur: function($event) {
                                      return _vm.$forceUpdate()
                                    }
                                  }
                                })
                              ]
                            ),
                            !_vm.$v.options.cart.сartPreviewSettings.title
                              .previewMaxLength
                              ? _c(
                                  "p",
                                  { staticClass: "side-form__item-msg" },
                                  [
                                    _vm._v(
                                      "\n              Maximum length of the value should be less then 25 characters\n            "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "side-form__item" },
                          [
                            _c("p", { staticClass: "side-form__item-label" }, [
                              _vm._v("Cart preview button text")
                            ]),
                            _c("p", { staticClass: "side-form__item-hint" }, [
                              _vm._v("Maximum length 25 characters")
                            ]),
                            _c(
                              "ProOptionWrapper",
                              { attrs: { featureName: "checkout" } },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value:
                                        _vm.$v.options.cart.сartPreviewSettings
                                          .submitButtonText.$model,
                                      expression:
                                        "$v.options.cart.сartPreviewSettings.submitButtonText.$model",
                                      modifiers: { trim: true }
                                    }
                                  ],
                                  staticClass: "input",
                                  attrs: {
                                    type: "text",
                                    placeholder: _vm.msg.checkoutSubmit
                                  },
                                  domProps: {
                                    value:
                                      _vm.$v.options.cart.сartPreviewSettings
                                        .submitButtonText.$model
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.$v.options.cart.сartPreviewSettings
                                          .submitButtonText,
                                        "$model",
                                        $event.target.value.trim()
                                      )
                                    },
                                    blur: function($event) {
                                      return _vm.$forceUpdate()
                                    }
                                  }
                                })
                              ]
                            ),
                            !_vm.$v.options.cart.сartPreviewSettings
                              .submitButtonText.previewMaxLength
                              ? _c(
                                  "p",
                                  { staticClass: "side-form__item-msg" },
                                  [
                                    _vm._v(
                                      "\n              Maximum length of the value should be less then 25 characters\n            "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "side-form__item" },
                          [
                            _c("p", { staticClass: "side-form__item-label" }, [
                              _vm._v('Cart preview "Continue" button text')
                            ]),
                            _c("div", { staticClass: "side-form__item-hint" }, [
                              _vm._v("Maximum length 50 characters")
                            ]),
                            _c(
                              "ProOptionWrapper",
                              { attrs: { featureName: "checkout" } },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value:
                                        _vm.$v.options.cart.сartPreviewSettings
                                          .backButtonText.$model,
                                      expression:
                                        "$v.options.cart.сartPreviewSettings.backButtonText.$model",
                                      modifiers: { trim: true }
                                    }
                                  ],
                                  staticClass: "input",
                                  attrs: {
                                    type: "text",
                                    placeholder:
                                      _vm.msg.checkoutContinueShopping
                                  },
                                  domProps: {
                                    value:
                                      _vm.$v.options.cart.сartPreviewSettings
                                        .backButtonText.$model
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.$v.options.cart.сartPreviewSettings
                                          .backButtonText,
                                        "$model",
                                        $event.target.value.trim()
                                      )
                                    },
                                    blur: function($event) {
                                      return _vm.$forceUpdate()
                                    }
                                  }
                                })
                              ]
                            ),
                            !_vm.$v.options.cart.сartPreviewSettings
                              .backButtonText.previewMaxLength
                              ? _c(
                                  "p",
                                  { staticClass: "side-form__item-msg" },
                                  [
                                    _vm._v(
                                      "\n              Maximum length of the value should be less then 50 characters\n            "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "side-form__section" },
                    [
                      _c(
                        "div",
                        { staticClass: "side-form__item" },
                        [
                          _c("p", { staticClass: "side-form__item-label" }, [
                            _vm._v("Cart title")
                          ]),
                          _c("div", { staticClass: "side-form__item-hint" }, [
                            _vm._v("Maximum length 25 characters")
                          ]),
                          _c(
                            "ProOptionWrapper",
                            { attrs: { featureName: "checkout" } },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value:
                                      _vm.$v.options.cart.cart_title.$model,
                                    expression:
                                      "$v.options.cart.cart_title.$model",
                                    modifiers: { trim: true }
                                  }
                                ],
                                staticClass: "input",
                                attrs: {
                                  type: "text",
                                  placeholder: _vm.msg.yourCart
                                },
                                domProps: {
                                  value: _vm.$v.options.cart.cart_title.$model
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.$v.options.cart.cart_title,
                                      "$model",
                                      $event.target.value.trim()
                                    )
                                  },
                                  blur: function($event) {
                                    return _vm.$forceUpdate()
                                  }
                                }
                              })
                            ]
                          ),
                          !_vm.$v.options.cart.cart_title.maxLength
                            ? _c("p", { staticClass: "side-form__item-msg" }, [
                                _vm._v(
                                  "\n              Maximum length of the value should be less then 25 characters\n            "
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "side-form__item" },
                        [
                          _c("p", { staticClass: "side-form__item-label" }, [
                            _vm._v("Cart form title")
                          ]),
                          _c("div", { staticClass: "side-form__item-hint" }, [
                            _vm._v("Maximum length 25 characters")
                          ]),
                          _c(
                            "ProOptionWrapper",
                            { attrs: { featureName: "checkout" } },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value:
                                      _vm.$v.options.cart.cartFormTitle.$model,
                                    expression:
                                      "$v.options.cart.cartFormTitle.$model",
                                    modifiers: { trim: true }
                                  }
                                ],
                                staticClass: "input",
                                attrs: {
                                  type: "text",
                                  placeholder: _vm.msg.clientData
                                },
                                domProps: {
                                  value:
                                    _vm.$v.options.cart.cartFormTitle.$model
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.$v.options.cart.cartFormTitle,
                                      "$model",
                                      $event.target.value.trim()
                                    )
                                  },
                                  blur: function($event) {
                                    return _vm.$forceUpdate()
                                  }
                                }
                              })
                            ]
                          ),
                          !_vm.$v.options.cart.cartFormTitle.maxLength
                            ? _c("p", { staticClass: "side-form__item-msg" }, [
                                _vm._v(
                                  "\n              Maximum length of the value should be less then 50 characters\n            "
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "side-form__item" },
                        [
                          _c("p", { staticClass: "side-form__item-label" }, [
                            _vm._v("Cart button text")
                          ]),
                          _c("div", { staticClass: "side-form__item-hint" }, [
                            _vm._v("Maximum length 25 characters")
                          ]),
                          _c(
                            "ProOptionWrapper",
                            { attrs: { featureName: "checkout" } },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value:
                                      _vm.$v.options.cart.cart_button_text
                                        .$model,
                                    expression:
                                      "$v.options.cart.cart_button_text.$model",
                                    modifiers: { trim: true }
                                  }
                                ],
                                staticClass: "input",
                                attrs: {
                                  type: "text",
                                  placeholder: _vm.msg.checkoutSubmit
                                },
                                domProps: {
                                  value:
                                    _vm.$v.options.cart.cart_button_text.$model
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.$v.options.cart.cart_button_text,
                                      "$model",
                                      $event.target.value.trim()
                                    )
                                  },
                                  blur: function($event) {
                                    return _vm.$forceUpdate()
                                  }
                                }
                              })
                            ]
                          ),
                          !_vm.$v.options.cart.cart_button_text.maxLength
                            ? _c("p", { staticClass: "side-form__item-msg" }, [
                                _vm._v(
                                  "\n              Maximum length of the value should be less then 25 characters\n            "
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "side-form__item" },
                        [
                          _c("p", { staticClass: "side-form__item-label" }, [
                            _vm._v('Text for "Continue" button')
                          ]),
                          _c("div", { staticClass: "side-form__item-hint" }, [
                            _vm._v("Maximum length 50 characters")
                          ]),
                          _c(
                            "ProOptionWrapper",
                            { attrs: { featureName: "checkout" } },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value:
                                      _vm.$v.options.cart.cart_continue_text
                                        .$model,
                                    expression:
                                      "$v.options.cart.cart_continue_text.$model",
                                    modifiers: { trim: true }
                                  }
                                ],
                                staticClass: "input",
                                attrs: {
                                  type: "text",
                                  placeholder: _vm.msg.checkoutContinueShopping
                                },
                                domProps: {
                                  value:
                                    _vm.$v.options.cart.cart_continue_text
                                      .$model
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.$v.options.cart.cart_continue_text,
                                      "$model",
                                      $event.target.value.trim()
                                    )
                                  },
                                  blur: function($event) {
                                    return _vm.$forceUpdate()
                                  }
                                }
                              })
                            ]
                          ),
                          !_vm.$v.options.cart.cart_continue_text.maxLength
                            ? _c("p", { staticClass: "side-form__item-msg" }, [
                                _vm._v(
                                  "\n              Maximum length of the value should be less then 50 characters\n            "
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "side-form__item" },
                        [
                          _c(
                            "p",
                            {
                              staticClass: "side-form__item-label placement-top"
                            },
                            [_vm._v("Cart details text")]
                          ),
                          _c(
                            "ProOptionWrapper",
                            { attrs: { featureName: "checkout" } },
                            [
                              _c("MarkdownComponent", {
                                attrs: {
                                  height: "200px",
                                  preview: false,
                                  leftToolbar: "bold italic strikethrough link"
                                },
                                model: {
                                  value:
                                    _vm.$v.options.cart.cart_details_message
                                      .$model,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.$v.options.cart.cart_details_message,
                                      "$model",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression:
                                    "$v.options.cart.cart_details_message.$model"
                                }
                              })
                            ],
                            1
                          ),
                          !_vm.$v.options.cart.cart_details_message.maxLength
                            ? _c("p", { staticClass: "side-form__item-msg" }, [
                                _vm._v(
                                  "\n              Maximum length of the value should be less then 500 characters\n            "
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "side-form__item" },
                        [
                          _c("p", { staticClass: "side-form__item-label" }, [
                            _vm._v("Success message title")
                          ]),
                          _c("p", { staticClass: "side-form__item-hint" }, [
                            _vm._v("Maximum length 25 characters")
                          ]),
                          _c(
                            "ProOptionWrapper",
                            { attrs: { featureName: "checkout" } },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value:
                                      _vm.$v.options.cart.cart_tm_title.$model,
                                    expression:
                                      "$v.options.cart.cart_tm_title.$model",
                                    modifiers: { trim: true }
                                  }
                                ],
                                staticClass: "input",
                                attrs: {
                                  type: "text",
                                  placeholder: _vm.msg.checkoutThanksTitle
                                },
                                domProps: {
                                  value:
                                    _vm.$v.options.cart.cart_tm_title.$model
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.$v.options.cart.cart_tm_title,
                                      "$model",
                                      $event.target.value.trim()
                                    )
                                  },
                                  blur: function($event) {
                                    return _vm.$forceUpdate()
                                  }
                                }
                              })
                            ]
                          ),
                          !_vm.$v.options.cart.cart_tm_title.maxLength
                            ? _c("p", { staticClass: "side-form__item-msg" }, [
                                _vm._v(
                                  "\n              Maximum length of the value should be less then 25 characters\n            "
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "side-form__item" },
                        [
                          _c(
                            "p",
                            {
                              staticClass: "side-form__item-label placement-top"
                            },
                            [_vm._v("Success message text")]
                          ),
                          _c(
                            "ProOptionWrapper",
                            { attrs: { featureName: "checkout" } },
                            [
                              _c("MarkdownComponent", {
                                attrs: {
                                  height: "200px",
                                  preview: false,
                                  leftToolbar: "bold italic strikethrough link"
                                },
                                model: {
                                  value: _vm.$v.checkoutThanksText.$model,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.$v.checkoutThanksText,
                                      "$model",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "$v.checkoutThanksText.$model"
                                }
                              })
                            ],
                            1
                          ),
                          !_vm.$v.checkoutThanksText.maxLength
                            ? _c("p", { staticClass: "side-form__item-msg" }, [
                                _vm._v(
                                  "\n              Maximum length of the value should be less then 250 characters\n            "
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "ProOptionWrapper",
                        {
                          staticClass: "side-form__item",
                          attrs: { featureName: "checkout" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "side-form__switcher-wrap" },
                            [
                              _c(
                                "div",
                                { staticClass: "side-form__switcher-label" },
                                [
                                  _vm._v(
                                    "Limit max amount of items in cart to 1"
                                  )
                                ]
                              ),
                              _c(
                                "label",
                                {
                                  staticClass: "switcher",
                                  class: {
                                    selected:
                                      _vm.options.cart.cartItemsAmountLimit
                                  }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.options.cart.cartItemsAmountLimit,
                                        expression:
                                          "options.cart.cartItemsAmountLimit"
                                      }
                                    ],
                                    staticClass: "switcher__input",
                                    attrs: { type: "checkbox", value: "tiles" },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.options.cart.cartItemsAmountLimit
                                      )
                                        ? _vm._i(
                                            _vm.options.cart
                                              .cartItemsAmountLimit,
                                            "tiles"
                                          ) > -1
                                        : _vm.options.cart.cartItemsAmountLimit
                                    },
                                    on: {
                                      change: [
                                        function($event) {
                                          var $$a =
                                              _vm.options.cart
                                                .cartItemsAmountLimit,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = "tiles",
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.options.cart,
                                                  "cartItemsAmountLimit",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.options.cart,
                                                  "cartItemsAmountLimit",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              _vm.options.cart,
                                              "cartItemsAmountLimit",
                                              $$c
                                            )
                                          }
                                        },
                                        function($event) {
                                          return _vm.$trackFeature(
                                            "checkout",
                                            "cartItemsAmountLimit",
                                            _vm.options.cart
                                              .cartItemsAmountLimit
                                          )
                                        }
                                      ]
                                    }
                                  }),
                                  _c("span", {
                                    staticClass: "switcher__indicator"
                                  })
                                ]
                              )
                            ]
                          )
                        ]
                      ),
                      _c(
                        "ProOptionWrapper",
                        {
                          staticClass: "side-form__item",
                          attrs: { featureName: "checkout" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "side-form__switcher-wrap" },
                            [
                              _c(
                                "div",
                                { staticClass: "side-form__switcher-label" },
                                [
                                  _vm._v(
                                    "Limit max quantity of an item in cart to 1"
                                  )
                                ]
                              ),
                              _c(
                                "label",
                                {
                                  staticClass: "switcher",
                                  class: {
                                    selected:
                                      _vm.options.cart.cartItemQuantityLimit
                                  }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.options.cart
                                            .cartItemQuantityLimit,
                                        expression:
                                          "options.cart.cartItemQuantityLimit"
                                      }
                                    ],
                                    staticClass: "switcher__input",
                                    attrs: { type: "checkbox", value: "tiles" },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.options.cart.cartItemQuantityLimit
                                      )
                                        ? _vm._i(
                                            _vm.options.cart
                                              .cartItemQuantityLimit,
                                            "tiles"
                                          ) > -1
                                        : _vm.options.cart.cartItemQuantityLimit
                                    },
                                    on: {
                                      change: [
                                        function($event) {
                                          var $$a =
                                              _vm.options.cart
                                                .cartItemQuantityLimit,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = "tiles",
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.options.cart,
                                                  "cartItemQuantityLimit",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.options.cart,
                                                  "cartItemQuantityLimit",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              _vm.options.cart,
                                              "cartItemQuantityLimit",
                                              $$c
                                            )
                                          }
                                        },
                                        function($event) {
                                          return _vm.$trackFeature(
                                            "checkout",
                                            "cartItemQuantityLimit",
                                            _vm.options.cart
                                              .cartItemQuantityLimit
                                          )
                                        }
                                      ]
                                    }
                                  }),
                                  _c("span", {
                                    staticClass: "switcher__indicator"
                                  })
                                ]
                              )
                            ]
                          )
                        ]
                      ),
                      _c(
                        "ProOptionWrapper",
                        {
                          staticClass: "side-form__item",
                          attrs: { featureName: "checkout" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "side-form__switcher-wrap" },
                            [
                              _c(
                                "div",
                                { staticClass: "side-form__switcher-label" },
                                [_vm._v('"Copy link to cart" button ')]
                              ),
                              _c(
                                "label",
                                {
                                  staticClass: "switcher",
                                  class: {
                                    selected: _vm.options.cart.cartCopyLink
                                  }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.options.cart.cartCopyLink,
                                        expression: "options.cart.cartCopyLink"
                                      }
                                    ],
                                    staticClass: "switcher__input",
                                    attrs: { type: "checkbox", value: "tiles" },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.options.cart.cartCopyLink
                                      )
                                        ? _vm._i(
                                            _vm.options.cart.cartCopyLink,
                                            "tiles"
                                          ) > -1
                                        : _vm.options.cart.cartCopyLink
                                    },
                                    on: {
                                      change: [
                                        function($event) {
                                          var $$a =
                                              _vm.options.cart.cartCopyLink,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = "tiles",
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.options.cart,
                                                  "cartCopyLink",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.options.cart,
                                                  "cartCopyLink",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              _vm.options.cart,
                                              "cartCopyLink",
                                              $$c
                                            )
                                          }
                                        },
                                        function($event) {
                                          return _vm.$trackFeature(
                                            "checkout",
                                            "cartCopyLink",
                                            _vm.options.cart.cartCopyLink
                                          )
                                        }
                                      ]
                                    }
                                  }),
                                  _c("span", {
                                    staticClass: "switcher__indicator"
                                  })
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeSection === "promocodes",
                      expression: "activeSection === 'promocodes'"
                    }
                  ],
                  staticClass: "side-form__block-wrapper"
                },
                [
                  _c("h6", { staticClass: "side-form__section-title" }, [
                    _vm._v("Promo codes")
                  ]),
                  _c(
                    "div",
                    { staticClass: "side-form__item" },
                    [
                      _c(
                        "ProOptionWrapper",
                        {
                          staticClass: "side-form__item",
                          attrs: { featureName: "checkout" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "side-form__switcher-wrap" },
                            [
                              _c(
                                "div",
                                { staticClass: "side-form__switcher-label" },
                                [_vm._v("Enable promo codes on checkout")]
                              ),
                              _c(
                                "label",
                                {
                                  staticClass: "switcher",
                                  class: {
                                    selected:
                                      _vm.options.checkout.promocodes.enabled
                                  }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.options.checkout.promocodes
                                            .enabled,
                                        expression:
                                          "options.checkout.promocodes.enabled"
                                      }
                                    ],
                                    staticClass: "switcher__input",
                                    attrs: { type: "checkbox" },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.options.checkout.promocodes.enabled
                                      )
                                        ? _vm._i(
                                            _vm.options.checkout.promocodes
                                              .enabled,
                                            null
                                          ) > -1
                                        : _vm.options.checkout.promocodes
                                            .enabled
                                    },
                                    on: {
                                      change: [
                                        function($event) {
                                          var $$a =
                                              _vm.options.checkout.promocodes
                                                .enabled,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.options.checkout
                                                    .promocodes,
                                                  "enabled",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.options.checkout
                                                    .promocodes,
                                                  "enabled",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              _vm.options.checkout.promocodes,
                                              "enabled",
                                              $$c
                                            )
                                          }
                                        },
                                        function($event) {
                                          return _vm.$trackFeature(
                                            "checkout",
                                            "promocodes",
                                            _vm.options.checkout.promocodes
                                              .enabled
                                          )
                                        }
                                      ]
                                    }
                                  }),
                                  _c("span", {
                                    staticClass: "switcher__indicator"
                                  })
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "side-form__item" },
                    [
                      _c("p", { staticClass: "side-form__item-hint is-link" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              target: "_blank",
                              href:
                                "https://help.spreadsimple.com/en/article/setting-up-promo-codes-17o3klj/"
                            }
                          },
                          [_vm._v("Help: How to use promo codes?")]
                        )
                      ]),
                      _c(
                        "ProOptionWrapper",
                        { attrs: { featureName: "checkout" } },
                        [_c("PromoCodesComponent")],
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeSection === "shippingFeesPayment",
                      expression: "activeSection === 'shippingFeesPayment'"
                    }
                  ],
                  staticClass: "side-form__block-wrapper"
                },
                [
                  _c(
                    "div",
                    { staticClass: "side-form__item" },
                    [
                      _c("h6", { staticClass: "side-form__section-title" }, [
                        _vm._v("Shipping")
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "tile-constructor__hint",
                          staticStyle: {
                            "margin-top": "-15px",
                            "margin-bottom": "10px"
                          }
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                target: "_blank",
                                href:
                                  "https://help.spreadsimple.com/en/article/adding-shipping-methods-and-rates-86cptr/"
                              }
                            },
                            [_vm._v("Help: How to add a shipping method")]
                          )
                        ]
                      ),
                      _c(
                        "ProOptionWrapper",
                        { attrs: { featureName: "checkout" } },
                        [
                          _c("OptionsBuilder", {
                            attrs: {
                              addButtonTitle: "+ Add shipping method",
                              modalTitle: "New shipping method",
                              schema: "shippingFieldSchema"
                            },
                            model: {
                              value: _vm.options.checkout.shipping,
                              callback: function($$v) {
                                _vm.$set(_vm.options.checkout, "shipping", $$v)
                              },
                              expression: "options.checkout.shipping"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "side-form__item" },
                    [
                      _c("h6", { staticClass: "side-form__section-title" }, [
                        _vm._v("Taxes & Fees")
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "tile-constructor__hint",
                          staticStyle: {
                            "margin-top": "-15px",
                            "margin-bottom": "10px"
                          }
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                target: "_blank",
                                href:
                                  "https://help.spreadsimple.com/en/article/adding-taxes-and-fees-ctajlg/"
                              }
                            },
                            [_vm._v("Help: Adding taxes & fees")]
                          )
                        ]
                      ),
                      _c(
                        "ProOptionWrapper",
                        { attrs: { featureName: "checkout" } },
                        [
                          _c("OptionsBuilder", {
                            attrs: {
                              addButtonTitle: "+ Add new fee",
                              modalTitle: "Tax or fee",
                              schema: "feeFieldSchema"
                            },
                            model: {
                              value: _vm.options.checkout.fees,
                              callback: function($$v) {
                                _vm.$set(_vm.options.checkout, "fees", $$v)
                              },
                              expression: "options.checkout.fees"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "side-form__item" },
                    [
                      _c("h6", { staticClass: "side-form__section-title" }, [
                        _vm._v("Tips")
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "tile-constructor__hint",
                          staticStyle: {
                            "margin-top": "-15px",
                            "margin-bottom": "10px"
                          }
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                target: "_blank",
                                href:
                                  "https://help.spreadsimple.com/en/article/adding-tips-1i6xarj/"
                              }
                            },
                            [_vm._v("Help: Adding tips")]
                          )
                        ]
                      ),
                      _c(
                        "FormField",
                        {
                          staticClass: "tile-constructor__field aside-select",
                          attrs: { label: "Customize the section title" }
                        },
                        [
                          _c("v-text-field", {
                            model: {
                              value: _vm.options.checkout.tipsTitle,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.options.checkout,
                                  "tipsTitle",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "options.checkout.tipsTitle"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "ProOptionWrapper",
                        { attrs: { featureName: "checkout" } },
                        [
                          _c("OptionsBuilder", {
                            attrs: {
                              addButtonTitle: "+ Add new tip",
                              modalTitle: "Tips",
                              schema: "feeFieldSchema"
                            },
                            model: {
                              value: _vm.options.checkout.tips,
                              callback: function($$v) {
                                _vm.$set(_vm.options.checkout, "tips", $$v)
                              },
                              expression: "options.checkout.tips"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "side-form__item" },
                    [
                      _c("h6", { staticClass: "side-form__section-title" }, [
                        _vm._v("Payments")
                      ]),
                      _c(
                        "ProOptionWrapper",
                        { attrs: { featureName: "checkout" } },
                        [
                          _c("PaymentsBuilder", {
                            attrs: {
                              integrations: _vm.integrations,
                              integrationsData: _vm.integrationsData
                            },
                            on: {
                              "open-payment-addons": function($event) {
                                return _vm.$emit("open-payment-addons")
                              },
                              "disable-payment-addon": function(val) {
                                return _vm.$emit("disable-payment-addon", val)
                              }
                            },
                            model: {
                              value: _vm.options.checkout.paymentsOrder,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.options.checkout,
                                  "paymentsOrder",
                                  $$v
                                )
                              },
                              expression: "options.checkout.paymentsOrder"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeSection === "emailNotifications",
                      expression: "activeSection === 'emailNotifications'"
                    }
                  ],
                  staticClass: "side-form__block-wrapper"
                },
                [
                  _c("h6", { staticClass: "side-form__section-title" }, [
                    _vm._v("Email notifications")
                  ]),
                  _c(
                    "div",
                    { staticClass: "side-form__section" },
                    [
                      _c(
                        "ProOptionWrapper",
                        {
                          staticClass: "side-form__item",
                          attrs: { featureName: "notifications" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "side-form__switcher-wrap" },
                            [
                              _c(
                                "div",
                                { staticClass: "side-form__switcher-label" },
                                [_vm._v("Enable Email notifications")]
                              ),
                              _c(
                                "label",
                                {
                                  staticClass: "switcher",
                                  class: {
                                    selected:
                                      _vm.options.emailOptions
                                        .notificationsEmailEnabled
                                  }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.options.emailOptions
                                            .notificationsEmailEnabled,
                                        expression:
                                          "options.emailOptions.notificationsEmailEnabled"
                                      }
                                    ],
                                    staticClass: "switcher__input",
                                    attrs: { type: "checkbox", value: "tiles" },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.options.emailOptions
                                          .notificationsEmailEnabled
                                      )
                                        ? _vm._i(
                                            _vm.options.emailOptions
                                              .notificationsEmailEnabled,
                                            "tiles"
                                          ) > -1
                                        : _vm.options.emailOptions
                                            .notificationsEmailEnabled
                                    },
                                    on: {
                                      change: [
                                        function($event) {
                                          var $$a =
                                              _vm.options.emailOptions
                                                .notificationsEmailEnabled,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = "tiles",
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.options.emailOptions,
                                                  "notificationsEmailEnabled",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.options.emailOptions,
                                                  "notificationsEmailEnabled",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              _vm.options.emailOptions,
                                              "notificationsEmailEnabled",
                                              $$c
                                            )
                                          }
                                        },
                                        function($event) {
                                          return _vm.$trackFeature(
                                            "checkout",
                                            "notificationsEmailEnabled",
                                            _vm.options.emailOptions
                                              .notificationsEmailEnabled
                                          )
                                        }
                                      ]
                                    }
                                  }),
                                  _c("span", {
                                    staticClass: "switcher__indicator"
                                  })
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm.options.emailOptions.notificationsEmailEnabled
                    ? _c("div", { staticClass: "side-form__section" }, [
                        _c(
                          "div",
                          { staticClass: "side-form__item" },
                          [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "side-form__item-label placement-top"
                              },
                              [_vm._v("TO email:")]
                            ),
                            _c(
                              "ProOptionWrapper",
                              { attrs: { featureName: "notifications" } },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value: _vm.$v.emailTo.$model,
                                      expression: "$v.emailTo.$model",
                                      modifiers: { trim: true }
                                    }
                                  ],
                                  staticClass: "input",
                                  attrs: { type: "text" },
                                  domProps: { value: _vm.$v.emailTo.$model },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.$v.emailTo,
                                        "$model",
                                        $event.target.value.trim()
                                      )
                                    },
                                    blur: function($event) {
                                      return _vm.$forceUpdate()
                                    }
                                  }
                                })
                              ]
                            ),
                            !_vm.$v.emailTo.email
                              ? _c(
                                  "p",
                                  { staticClass: "side-form__item-msg" },
                                  [_vm._v("Please provide valid email address")]
                                )
                              : _vm._e(),
                            !_vm.$v.emailTo.required
                              ? _c(
                                  "p",
                                  { staticClass: "side-form__item-msg" },
                                  [_vm._v("This field id required")]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "side-form__item" },
                          [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "side-form__item-label placement-top"
                              },
                              [_vm._v("Sender name:")]
                            ),
                            _c(
                              "ProOptionWrapper",
                              { attrs: { featureName: "notifications" } },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value:
                                        _vm.options.emailOptions
                                          .notificationsEmailSenderName,
                                      expression:
                                        "options.emailOptions.notificationsEmailSenderName",
                                      modifiers: { trim: true }
                                    }
                                  ],
                                  staticClass: "input",
                                  attrs: { type: "text" },
                                  domProps: {
                                    value:
                                      _vm.options.emailOptions
                                        .notificationsEmailSenderName
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.options.emailOptions,
                                        "notificationsEmailSenderName",
                                        $event.target.value.trim()
                                      )
                                    },
                                    blur: function($event) {
                                      return _vm.$forceUpdate()
                                    }
                                  }
                                })
                              ]
                            ),
                            !_vm.$v.options.emailOptions
                              .notificationsEmailSenderName.required
                              ? _c(
                                  "p",
                                  { staticClass: "side-form__item-msg" },
                                  [_vm._v("This field id required")]
                                )
                              : _vm._e(),
                            !_vm.$v.options.emailOptions
                              .notificationsEmailSenderName.maxLength
                              ? _c(
                                  "p",
                                  { staticClass: "side-form__item-msg" },
                                  [
                                    _vm._v(
                                      "Maximum length of the value should be less then 50 characters"
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "side-form__item" },
                          [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "side-form__item-label placement-top"
                              },
                              [_vm._v("Subject:")]
                            ),
                            _c(
                              "ProOptionWrapper",
                              { attrs: { featureName: "notifications" } },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value: _vm.$v.emailSubject.$model,
                                      expression: "$v.emailSubject.$model",
                                      modifiers: { trim: true }
                                    }
                                  ],
                                  staticClass: "input",
                                  attrs: { type: "text" },
                                  domProps: {
                                    value: _vm.$v.emailSubject.$model
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.$v.emailSubject,
                                        "$model",
                                        $event.target.value.trim()
                                      )
                                    },
                                    blur: function($event) {
                                      return _vm.$forceUpdate()
                                    }
                                  }
                                })
                              ]
                            ),
                            !_vm.$v.emailSubject.maxLength
                              ? _c(
                                  "p",
                                  { staticClass: "side-form__item-msg" },
                                  [
                                    _vm._v(
                                      "Maximum length of the value should be less then 100 characters"
                                    )
                                  ]
                                )
                              : _vm._e(),
                            !_vm.$v.emailSubject.required
                              ? _c(
                                  "p",
                                  { staticClass: "side-form__item-msg" },
                                  [_vm._v("This field id required")]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "side-form__item" },
                          [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "side-form__item-label placement-top"
                              },
                              [_vm._v("Notification email template")]
                            ),
                            _c(
                              "ProOptionWrapper",
                              { attrs: { featureName: "notifications" } },
                              [
                                _c("MarkdownComponent", {
                                  attrs: {
                                    cols: _vm.cols,
                                    height: "200px",
                                    preview: false,
                                    leftToolbar:
                                      "bold italic strikethrough link | cartItemsTable"
                                  },
                                  model: {
                                    value: _vm.emailNotificationTemplate,
                                    callback: function($$v) {
                                      _vm.emailNotificationTemplate =
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                    },
                                    expression: "emailNotificationTemplate"
                                  }
                                })
                              ],
                              1
                            ),
                            !_vm.$v.emailNotificationTemplate.maxLength
                              ? _c(
                                  "p",
                                  { staticClass: "side-form__item-msg" },
                                  [
                                    _vm._v(
                                      "\n              Maximum length of the value should be less then 5000 characters\n            "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm.isAllowedFeature("brandNotificationsTemplate")
                              ? _c(
                                  "UiMsgBox",
                                  {
                                    staticClass: "mt-12",
                                    attrs: {
                                      icon: "mf-error_outline",
                                      color: "warning"
                                    }
                                  },
                                  [
                                    _c("b", [
                                      _vm._v(
                                        "Your email notifications will be branded"
                                      )
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        "Please note that on the current plan, the email notifications will be branded with SpreadSimple logo and text. "
                                      )
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        "To remove SpreadSimple branding, apply a different kind of licesence to this website."
                                      )
                                    ]),
                                    _c(
                                      "UpgradePlanButton",
                                      {
                                        attrs: {
                                          navigateToBilling: true,
                                          tag: "button"
                                        }
                                      },
                                      [_c("b", [_vm._v("Upgrade Plan")])]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeSection === "emailreceipts",
                      expression: "activeSection === 'emailreceipts'"
                    }
                  ],
                  staticClass: "side-form__block-wrapper"
                },
                [
                  _c("h6", { staticClass: "side-form__section-title" }, [
                    _vm._v("Email receipts")
                  ]),
                  _c(
                    "div",
                    { staticClass: "side-form__section" },
                    [
                      _c(
                        "ProOptionWrapper",
                        {
                          staticClass: "side-form__item",
                          attrs: { featureName: "notifications" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "side-form__switcher-wrap" },
                            [
                              _c(
                                "div",
                                { staticClass: "side-form__switcher-label" },
                                [_vm._v("Enable Email receipts")]
                              ),
                              _c(
                                "label",
                                {
                                  staticClass: "switcher",
                                  class: {
                                    selected:
                                      _vm.options.emailOptions
                                        .receiptEmailEnabled
                                  }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.options.emailOptions
                                            .receiptEmailEnabled,
                                        expression:
                                          "options.emailOptions.receiptEmailEnabled"
                                      }
                                    ],
                                    staticClass: "switcher__input",
                                    attrs: { type: "checkbox", value: "tiles" },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.options.emailOptions
                                          .receiptEmailEnabled
                                      )
                                        ? _vm._i(
                                            _vm.options.emailOptions
                                              .receiptEmailEnabled,
                                            "tiles"
                                          ) > -1
                                        : _vm.options.emailOptions
                                            .receiptEmailEnabled
                                    },
                                    on: {
                                      change: [
                                        function($event) {
                                          var $$a =
                                              _vm.options.emailOptions
                                                .receiptEmailEnabled,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = "tiles",
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.options.emailOptions,
                                                  "receiptEmailEnabled",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.options.emailOptions,
                                                  "receiptEmailEnabled",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              _vm.options.emailOptions,
                                              "receiptEmailEnabled",
                                              $$c
                                            )
                                          }
                                        },
                                        function($event) {
                                          return _vm.$trackFeature(
                                            "checkout",
                                            "receiptEmailEnabled",
                                            _vm.options.emailOptions
                                              .receiptEmailEnabled
                                          )
                                        }
                                      ]
                                    }
                                  }),
                                  _c("span", {
                                    staticClass: "switcher__indicator"
                                  })
                                ]
                              )
                            ]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "side-form__item-hint is-link placement-bottom"
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                target: "_blank",
                                href:
                                  "https://help.spreadsimple.com/en/article/how-to-set-up-email-receipts-158mx3i/"
                              }
                            },
                            [_vm._v("Help: How to set up Email receipts")]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm.options.emailOptions.receiptEmailEnabled
                    ? _c("div", { staticClass: "side-form__section" }, [
                        _c(
                          "div",
                          { staticClass: "side-form__item" },
                          [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "side-form__item-label placement-top"
                              },
                              [_vm._v("REPLY TO email:")]
                            ),
                            _c(
                              "ProOptionWrapper",
                              { attrs: { featureName: "notifications" } },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value:
                                        _vm.options.emailOptions
                                          .receiptReplyToEmail,
                                      expression:
                                        "options.emailOptions.receiptReplyToEmail",
                                      modifiers: { trim: true }
                                    }
                                  ],
                                  staticClass: "input",
                                  attrs: { type: "text" },
                                  domProps: {
                                    value:
                                      _vm.options.emailOptions
                                        .receiptReplyToEmail
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.options.emailOptions,
                                        "receiptReplyToEmail",
                                        $event.target.value.trim()
                                      )
                                    },
                                    blur: function($event) {
                                      return _vm.$forceUpdate()
                                    }
                                  }
                                })
                              ]
                            ),
                            !_vm.$v.options.emailOptions.receiptReplyToEmail
                              .email
                              ? _c(
                                  "p",
                                  { staticClass: "side-form__item-msg" },
                                  [_vm._v("Please provide valid email address")]
                                )
                              : _vm._e(),
                            !_vm.$v.options.emailOptions.receiptReplyToEmail
                              .required
                              ? _c(
                                  "p",
                                  { staticClass: "side-form__item-msg" },
                                  [_vm._v("This field id required")]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "side-form__item" },
                          [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "side-form__item-label placement-top"
                              },
                              [_vm._v("Receipt sender name:")]
                            ),
                            _c(
                              "ProOptionWrapper",
                              { attrs: { featureName: "notifications" } },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value:
                                        _vm.options.emailOptions
                                          .receiptSenderName,
                                      expression:
                                        "options.emailOptions.receiptSenderName",
                                      modifiers: { trim: true }
                                    }
                                  ],
                                  staticClass: "input",
                                  attrs: { type: "text" },
                                  domProps: {
                                    value:
                                      _vm.options.emailOptions.receiptSenderName
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.options.emailOptions,
                                        "receiptSenderName",
                                        $event.target.value.trim()
                                      )
                                    },
                                    blur: function($event) {
                                      return _vm.$forceUpdate()
                                    }
                                  }
                                })
                              ]
                            ),
                            !_vm.$v.options.emailOptions.receiptSenderName
                              .required
                              ? _c(
                                  "p",
                                  { staticClass: "side-form__item-msg" },
                                  [_vm._v("This field id required")]
                                )
                              : _vm._e(),
                            !_vm.$v.options.emailOptions.receiptSenderName
                              .maxLength
                              ? _c(
                                  "p",
                                  { staticClass: "side-form__item-msg" },
                                  [
                                    _vm._v(
                                      "Maximum length of the value should be less then 50 characters"
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "side-form__item" },
                          [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "side-form__item-label placement-top"
                              },
                              [_vm._v("Receipt Email subject:")]
                            ),
                            _c(
                              "ProOptionWrapper",
                              { attrs: { featureName: "notifications" } },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value:
                                        _vm.options.emailOptions
                                          .receiptEmailSubject,
                                      expression:
                                        "options.emailOptions.receiptEmailSubject",
                                      modifiers: { trim: true }
                                    }
                                  ],
                                  staticClass: "input",
                                  attrs: { type: "text" },
                                  domProps: {
                                    value:
                                      _vm.options.emailOptions
                                        .receiptEmailSubject
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.options.emailOptions,
                                        "receiptEmailSubject",
                                        $event.target.value.trim()
                                      )
                                    },
                                    blur: function($event) {
                                      return _vm.$forceUpdate()
                                    }
                                  }
                                })
                              ]
                            ),
                            !_vm.$v.options.emailOptions.receiptEmailSubject
                              .maxLength
                              ? _c(
                                  "p",
                                  { staticClass: "side-form__item-msg" },
                                  [
                                    _vm._v(
                                      "Maximum length of the value should be less then 100 characters"
                                    )
                                  ]
                                )
                              : _vm._e(),
                            !_vm.$v.options.emailOptions.receiptEmailSubject
                              .required
                              ? _c(
                                  "p",
                                  { staticClass: "side-form__item-msg" },
                                  [_vm._v("This field id required")]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "side-form__item" },
                          [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "side-form__item-label placement-top"
                              },
                              [_vm._v("Receipt email template")]
                            ),
                            _c(
                              "ProOptionWrapper",
                              { attrs: { featureName: "notifications" } },
                              [
                                _c("MarkdownComponent", {
                                  attrs: {
                                    cols: _vm.cols,
                                    height: "200px",
                                    preview: false,
                                    leftToolbar:
                                      "bold italic strikethrough link | cartItemsTable"
                                  },
                                  model: {
                                    value:
                                      _vm.options.emailOptions
                                        .receiptEmailTemplate,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.options.emailOptions,
                                        "receiptEmailTemplate",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression:
                                      "options.emailOptions.receiptEmailTemplate"
                                  }
                                })
                              ],
                              1
                            ),
                            !_vm.$v.options.emailOptions.receiptEmailTemplate
                              .maxLength
                              ? _c(
                                  "p",
                                  { staticClass: "side-form__item-msg" },
                                  [
                                    _vm._v(
                                      "Maximum length of the value should be less then 5000 characters"
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm.isAllowedFeature("brandNotificationsTemplate")
                              ? _c(
                                  "UiMsgBox",
                                  {
                                    staticClass: "mt-12",
                                    attrs: {
                                      icon: "mf-error_outline",
                                      color: "warning"
                                    }
                                  },
                                  [
                                    _c("p", [
                                      _c("b", [
                                        _vm._v(
                                          "Your email receipts will be branded"
                                        )
                                      ])
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        "Please note that on the current plan, the email receipts will be branded with SpreadSimple logo and text. "
                                      )
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        "To remove SpreadSimple branding, apply a different kind of licesence to this website."
                                      )
                                    ]),
                                    _c(
                                      "UpgradePlanButton",
                                      { attrs: { navigateToBilling: true } },
                                      [_c("b", [_vm._v("Upgrade Plan")])]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeSection === "webhooks",
                      expression: "activeSection === 'webhooks'"
                    }
                  ],
                  staticClass: "side-form__block-wrapper"
                },
                [
                  _c("h6", { staticClass: "side-form__section-title" }, [
                    _vm._v("Webhooks")
                  ]),
                  _c(
                    "div",
                    { staticClass: "side-form__item" },
                    [
                      _c(
                        "p",
                        { staticClass: "side-form__item-label placement-top" },
                        [_vm._v("Webhook URL")]
                      ),
                      _c(
                        "ProOptionWrapper",
                        { attrs: { featureName: "webhook" } },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.$v.options.webhooks.sendOrder.$model,
                                expression:
                                  "$v.options.webhooks.sendOrder.$model",
                                modifiers: { trim: true }
                              }
                            ],
                            staticClass: "input",
                            attrs: { type: "text" },
                            domProps: {
                              value: _vm.$v.options.webhooks.sendOrder.$model
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.$v.options.webhooks.sendOrder,
                                  "$model",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          })
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticClass:
                            "side-form__item-hint is-link placement-bottom"
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                target: "_blank",
                                href:
                                  "https://help.spreadsimple.com/en/article/how-to-use-webhooks-for-automation-19majym/"
                              }
                            },
                            [
                              _vm._v(
                                "Help: How to use Webhooks for automation?"
                              )
                            ]
                          )
                        ]
                      ),
                      !_vm.$v.options.webhooks.sendOrder.url
                        ? _c("p", { staticClass: "side-form__item-msg" }, [
                            _vm._v("Please provide a valid URL")
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            ])
      ]),
      _c(
        "UiBtn",
        {
          staticClass: "aside-save",
          attrs: {
            size: "l",
            "attr-type": "submit",
            loading: _vm.loading,
            disabled: _vm.loading || _vm.spreadViewerOptionsInvalid
          }
        },
        [_vm._v("\n    SAVE\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }