export default {
  published: true,
  pro: true,
  featureRestrictionsGroup: 'emailIntegrations',
  name: 'mailchimp',
  title: 'MailChimp',
  headline: '',
  logoUrl: '/img/integrations/mail-chimp.png',
  // coverUrl: '/img/integrations/mail-chimp-cover.png',
  bgColor: '#684C47',
  categories: ['Email Marketing'],
  comingSoon: false,
  builtIn: false,
  url: 'https://mailchimp.com/',
  helpUrl: '',
  description: `
  Mailchimp is a very popular email marketing platform.

  You can add an email subscription form to the intro section and also as a popup.

  **How to get your <a href="https://mailchimp.com/help/about-api-keys/#Find_or_generate_your_API_key" target="_blank">API key</a>?**

  1. Follow <a href="https://us1.admin.mailchimp.com/account/api/" target="_blank">this link</a>
  2. Copy existing API key or Create a new one.


  **Where do I find my API Server?**

  Your MailChimp API server is this part (e.g. 'us16') of the MailChimp dashboard URL:
  ![](https://s3.us-east-2.amazonaws.com/uploads.spreadsimple/dfe306e7-0fcf-421a-b91f-1b6128289f98_mc-server.png)

  `,

  formFields: [
    {
      name: 'enabled',
      type: 'toggle',
      label: 'Enabled',
      default: false,
      validations: [],
      secret: false
    },
    {
      name: 'apiKey',
      type: 'text',
      showIf: ['enabled'],
      label: 'API Key',
      default: '',
      validations: ['required'],
      secret: true
    },
    {
      name: 'apiServer',
      type: 'text',
      showIf: ['enabled'],
      label: 'API Server',
      default: '',
      validations: ['required'],
      secret: true
    },
    {
      name: 'showIntro',
      type: 'toggle',
      showIf: ['enabled'],
      label: 'Show Form in Intro Section',
      default: false,
      validations: [],
      secret: false
    },
    {
      name: 'showPopup',
      type: 'toggle',
      showIf: ['enabled'],
      label: 'Show Floating Button and Popup',
      default: false,
      validations: [],
      secret: false
    },
    {
      name: 'title',
      type: 'text',
      showIf: ['enabled', 'showPopup'],
      label: 'Popup Title',
      default: '',
      placeholder: '',
      validations: [
        'required',
        'maxLength:160'
      ],
      secret: false
    },
    {
      name: 'subtitle',
      type: 'text',
      showIf: ['enabled', 'showPopup'],
      label: 'Popup Subtitle',
      default: '',
      placeholder: '',
      validations: [
        'maxLength:300'
      ],
      secret: false
    },
    {
      name: 'askForName',
      type: 'toggle',
      showIf: ['enabled'],
      label: 'Ask for First and Last Name',
      default: false,
      validations: [],
      secret: false
    },
    {
      name: 'buttonTitle',
      type: 'text',
      showIf: ['enabled'],
      label: 'Button Title',
      default: 'Subscribe',
      placeholder: 'Subscribe',
      validations: [
        'required',
        'maxLength:50'
      ],
      secret: false
    },
    {
      name: 'buttonColor',
      type: 'color',
      showIf: ['enabled'],
      label: 'Button Color',
      default: '#999999',
      validations: [
        'required'
      ],
      secret: false
    }
  ],

  actions: []
}
