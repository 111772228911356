<template>
  <div>
    <SubscriptionInfo/>
  </div>
</template>
<script>
import SubscriptionInfo from '@/components/subscription/SubscriptionInfo.vue'
export default {
  name: 'BillingInfo',
  components: {
    SubscriptionInfo
  }
}
</script>
