import api from '../../services/api'

export async function getPopularFirstGoogleFonts () {
  try {
    const fonts = await api.getGoogleFontsList('?sort=popularity')
    const filteredFonts = fonts.items.filter(font => !font.family.includes('Material Icons') && !font.family.includes('Material Symbols'))
    return filteredFonts
  } catch (err) {
    throw new Error(err)
  }
}
