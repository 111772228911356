const currencyCodes = [
  { label: 'United Arab Emirates dirham', value: 'AED', digits: 2 },
  { label: 'Australian dollar', value: 'AUD', digits: 2 },
  { label: 'Bulgarian lev', value: 'BGN', digits: 2 },
  { label: 'Brazilian real', value: 'BRL', digits: 2 },
  { label: 'Canadian dollar', value: 'CAD', digits: 2 },
  { label: 'Swiss franc', value: 'CHF', digits: 2 },
  { label: 'Czech koruna', value: 'CZK', digits: 2 },
  { label: 'Danish krone', value: 'DKK', digits: 2 },
  { label: 'Euro', value: 'EUR', digits: 2 },
  { label: 'British pound', value: 'GBP', digits: 2 },
  { label: 'Hong Kong dollar', value: 'HKD', digits: 2 },
  { label: 'Hungarian forint', value: 'HUF', digits: 2 },
  { label: 'Israeli new shekel', value: 'ILS', digits: 2 },
  { label: 'Icelandic króna', value: 'ISK', digits: 0 },
  { label: 'Japanese yen', value: 'JPY', digits: 0 },
  { label: 'Mexican peso', value: 'MXN', digits: 2 },
  { label: 'Malaysian ringgit', value: 'MYR', digits: 2 },
  { label: 'Norwegian krone', value: 'NOK', digits: 2 },
  { label: 'New Zealand dollar', value: 'NZD', digits: 2 },
  { label: 'Philippine piso', value: 'PHP', digits: 2 },
  { label: 'Polish złoty', value: 'PLN', digits: 2 },
  { label: 'Romanian leu', value: 'RON', digits: 2 },
  { label: 'Russian ruble', value: 'RUB', digits: 2 },
  { label: 'Swedish krona', value: 'SEK', digits: 2 },
  { label: 'Singapore dollar', value: 'SGD', digits: 2 },
  { label: 'Thai baht', value: 'THB', digits: 2 },
  { label: 'New Taiwan dollar', value: 'TWD', digits: 2 },
  { label: 'United States dollar', value: 'USD', digits: 2 },
  { label: 'South African rand', value: 'ZAR', digits: 2 }
]
const envs = [
  { label: 'Test Mode', value: 'test' },
  { label: 'Live Mode', value: 'live' }
]
export default {
  published: true,
  pro: true,
  featureRestrictionsGroup: 'paymentIntegrations',
  name: 'mollie',
  title: 'Mollie',
  logoUrl: '/img/integrations/mollie.svg',
  bgColor: '#1e1e1e',
  categories: ['Payment Gateway'],
  comingSoon: false,
  builtIn: false,
  url: 'https://mollie.com',
  helpUrl: '',
  description: `
  An advanced solution to accept payments, optimise conversion, and access funding.

  <a href="https://help.mollie.com/hc/en-gb/articles/360023732273-What-is-an-API-key-/" target="_blank">How to find API keys in the Mollie Dashboard</a>

  `,

  functions: {
    checkSandboxMode: (mode) => (value) => {
      if (mode === 'sandbox' && value !== 'test') return false
      return true
    }
  },

  formFields: [
    {
      name: 'enabled',
      type: 'toggle',
      label: 'Enabled',
      default: false,
      validations: [
        'required'
      ],
      secret: false
    },
    {
      name: 'currency',
      type: 'select',
      showIf: ['enabled'],
      label: 'Currency Code',
      default: 'EUR',
      placeholder: '',
      validations: ['required'],
      options: currencyCodes,
      secret: false
    },
    {
      name: 'environment',
      type: 'select',
      showIf: ['enabled'],
      label: 'Environment (Test or Live)',
      default: 'test',
      placeholder: '',
      validations: ['required', 'runInternalValidationFunction:checkSandboxMode'],
      options: envs,
      secret: false
    },
    {
      name: 'apiKey_test',
      type: 'text',
      showIf: ['enabled', 'environment=test'],
      label: 'Test API key',
      default: '',
      placeholder: '',
      validations: [
        'maxLength:200',
        'requiredIf:environment=test'
      ],
      secret: true
    },
    {
      name: 'apiKey_live',
      type: 'text',
      showIf: ['enabled', 'environment=live'],
      label: 'Live API key',
      default: '',
      placeholder: '',
      validations: [
        'maxLength:200',
        'requiredIf:environment=live'
      ],
      secret: true
    },
    {
      name: 'buttonText',
      type: 'text',
      showIf: ['enabled'],
      label: 'Button Text',
      default: '💳 Continue to payment...',
      placeholder: 'Continue...',
      validations: [
        'required',
        'minLength:2',
        'maxLength:50'
      ],
      secret: false
    },
    {
      name: 'customTitle',
      type: 'text',
      showIf: ['enabled'],
      label: 'Custom title',
      default: 'Mollie',
      placeholder: '',
      validations: [
        'maxLength:50'
      ],
      secret: false
    },
    {
      name: 'customDescription',
      type: 'text',
      showIf: ['enabled'],
      label: 'Description',
      default: '',
      placeholder: '',
      validations: [
        'maxLength:100'
      ],
      secret: false
    }
  ],

  actions: []
}
