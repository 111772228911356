<template>
  <header v-scroll-class:scrolled=70 class="header for-manage">
    <div class="header__container">
      <a href="/" class="header__logo">
        <img src="/img/logo-main.svg">
      </a>
    </div>
  </header>
</template>
<script>
export default {
  name: 'LayoutHeader'
}
</script>
