export default {
  published: true,
  pro: true,
  featureRestrictionsGroup: 'commentsIntegrations',
  name: 'disqus',
  title: 'Disqus',
  logoUrl: '/img/integrations/disqus.svg',
  coverUrl: '/img/integrations/disqus-cover.png',
  bgColor: '#2E9FFF',
  categories: ['Comments', 'Community'],
  comingSoon: false,
  builtIn: false,
  url: 'https://disqus.com',
  helpUrl: '',
  description: `
  Disqus is a popular comment hosting service for web sites and online communities. It comes with various features, such as social integration, social networking, user profiles, spam and moderation tools, analytics, email notifications, and mobile commenting.

  Your comments section will be added to each details page on your website.

  Where to find your Disqus Shortname:

  1. Log in to your Disqus account, create one if you don't have one
  2. Open <a target="_blank" href="https://disqus.com/admin/settings/general/">Settings -> General</a>
  3. Copy the value of the "Shortname" field
  `,

  formFields: [
    {
      name: 'enabled',
      type: 'toggle',
      label: 'Enabled',
      default: false,
      validations: [
        'required'
      ],
      secret: false
    },
    {
      name: 'shortName',
      type: 'text',
      showIf: ['enabled'],
      label: 'Shortname',
      default: '',
      validations: [
        'required',
        'minLength:1',
        'maxLength:100'
      ],
      secret: false
    }
  ],

  actions: []
}
