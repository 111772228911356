const options = {
  page: {
    title: 'Bring your ideas to life',
    titleColor: '#fff',
    subtitle: 'We work to enhance the quality of life of our customers by providing high-valued products and services.',
    subtitleColor: '#fff',
    contentBeforeData: '',
    contentAfterData: '',
    contentBeforeDataMd: '',
    contentAfterDataMd: ''
  },
  header: {
    headerLayout: 'logo-left', // logo-left, logo-right, logo-center
    headerStyle: 'solid', // solid, transparent
    headerLogoImage: '',
    headerTagline: '',
    headerLogoExternalURL: {
      enabled: false,
      link: ''
    },
    showHeader: false,
    headerTextColor: '',
    headerButtonsColor: '',
    sideBarMenuByDefault: false,
    stickyHeader: false,
    mobileBottomNavigationEnabled: false,
    mobileBottomNavigationItems: []
  },
  footer: {
    showFooter: false,
    footerText: '',
    showSocialNetworks: false
  },
  appearance: {
    theme: { value: 'is-light', class: 'sv-is-light', label: 'Light Theme' },
    bgColor: '',
    logoImage: '',
    coverImage: 'https://images.unsplash.com/photo-1543857778-c4a1a3e0b2eb?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max&ixid=eyJhcHBfaWQiOjEwOTg0MH0',
    coverLayout: 'is-fixedheight',
    introKeepAspectRatio: false,
    showHomeIntro: true,
    openFiltersOnMobileByDefault: false,
    stickyFilters: false,
    scrollToTopBtn: false,
    globalFonts: {
      headingFont: '',
      bodyTextFont: ''
    }
  },
  viewer: {
    tileOptions: {
      title: {
        id: null
      },
      cardStyle: {
        value: 'tile' // tile | list
      },
      tilesSize: {
        type: 'large' // large | small
      },
      isTableScrollable: false,
      subtitle: {
        id: null
      },
      price: {
        id: null
      },
      oldPrice: {
        id: null
      },
      content: {
        id: null
      },
      image: {
        ids: [],
        keepAspectRatio: false
      },
      specs: {
        ids: []
      },
      badges: {
        ids: []
      },
      sku: {
        id: null
      },
      cta: {
        id: null,
        title: '',
        type: '',
        color: '#51535d',
        enabled: true,
        style: 'is-primary' // is-primary, is-secondary
      },
      cta_second: {
        id: null,
        title: '',
        type: '',
        color: '#51535d',
        enabled: false,
        style: 'is-secondary' // is-primary, is-secondary
      },
      doNotStretchContent: false
    },
    mapView: {
      enabled: false,
      markerColId: null,
      mapType: 'osm',
      markerType: 'pin',
      useClusters: false,
      markerImageColId: '',
      mapHeight: 640,
      zoom: 3,
      mapCenter: '0, 0',
      defaultView: 'mapView'
    },
    enableSingleItemView: false,
    showSingleItemAsPopup: true,
    singleItemOptions: {
      url: {
        id: null
      },
      title: {
        id: null
      },
      subtitle: {
        id: null
      },
      price: {
        id: null
      },
      oldPrice: {
        id: null
      },
      status: {
        id: null
      },
      content: {
        id: null
      },
      image: {
        ids: [],
        keepAspectRatio: false
      },
      specs: {
        ids: []
      },
      badges: {
        ids: []
      },
      cta: {
        id: null,
        title: '',
        type: '',
        color: '#51535d',
        enabled: true,
        style: 'is-primary' // is-primary, is-secondary
      },
      cta_second: {
        id: null,
        title: '',
        type: '',
        color: '#51535d',
        enabled: false,
        style: 'is-secondary' // is-primary, is-secondary
      },
      baseRoute: 'item',
      mapView: {
        enabled: false,
        mapType: 'osm',
        markerColId: null,
        markerType: 'pin',
        markerImageColId: '',
        mapHeight: 640,
        zoom: 3
      },
      layout: {
        reverse: false,
        type: 'content-on-side' // 'full-width', 'content-split'
      },
      socialShareButtons: {
        enabled: false,
        style: 'popup',
        popupTitleText: 'Share this link!',
        buttons: [
          'twitter',
          'facebook',
          'whatsapp',
          'linkedin',
          'reddit',
          'viber',
          'pinterest',
          'telegram',
          'messenger',
          'email'
        ]
      },
      relatedItems: {
        enabled: false,
        sectionTitle: '',
        itemsStyle: 'tile', // tile | list | horizontal
        itemsSize: 'small', // small | large
        isTableScrollable: false,
        maxCount: 3,
        selectionMethod: 'random', // random | category | sku
        categoryColumn: {
          id: null
        },
        skuColumn: {
          id: null
        }
      }
    }
  },
  search: {
    enabled: true,
    searchByColumns: [],
    placeholder: ''
  },
  filters: {
    enabled: false,
    asideFiltersView: false,
    values: [],
    blockUnavailableOptions: false
  },
  sorting: {
    enabled: false,
    asideSortingView: false,
    shuffleRows: false,
    reverseDefaultOrder: false,
    directions: [
      { value: 'desc', label: 'DESC' },
      { value: 'asc', label: 'ASC' }
    ],
    ids: [],
    default: {
      id: 'INDEX',
      direction: 'asc'
    }
  },
  pagination: {
    enabled: true,
    itemsPerPage: 40
  },
  variants: {
    enabled: false,
    variantsGroupBy: {
      id: null,
      groupCards: true
    },
    variantsOptions: {
      ids: [],
      showVariantsOptionsInCards: false
    }
  },
  stock: {
    enabled: false,
    id: null,
    outOfStockLabel: 'Out of stock',
    outOfStockLink: ''
  },
  contact: {
    email: ''
  },
  scripts: {
    scriptInHead: '',
    scriptInBodyStart: '',
    scriptInBodyFinish: ''
  },
  autoMapping: {
    requiresAutoMapping: true
  },
  seo: {
    favIcon: '',
    metaTitle: '',
    metaDescription: '',
    metaKeywords: '',
    ogImage: ''
  },
  cart: {
    cartView: 'popup',
    onAddToCart: 'openCheckout',
    showCartPreview: false,
    сartPreviewSettings: {
      title: '',
      submitButtonText: '',
      backButtonText: ''
    },
    cartFormTitle: '',
    cart_title: '',
    cart_button_text: '',
    cart_tm_title: '',
    cart_tm_text: '',
    cart_details_message: '',
    cart_continue_text: '',
    cartItemsAmountLimit: false,
    cartItemQuantityLimit: false,
    cartCopyLink: false
  },
  locale: 'en',
  webhooks: {
    sendOrder: ''
  },
  emailOptions: {
    notificationsEmailEnabled: true,
    notificationsEmailTo: '',
    notificationsEmailFrom: 'notify@alerts.spreadsimple.com',
    notificationsEmailSenderName: '',
    notificationsEmailSubject: 'Your order from SpreadSimple: {{orderId}}',
    notificationsEmailTemplate: `## You have a new order

Order #:  **{{orderId}}**
___
**Order information**
{{clientDetailsText}}
___
**Items ordered**
{{cartItemsText}}
___
**Taxes & fees:**  {{taxItemsText}}
**Tips:** {{tipItemText}}
**Shipping method:**  {{shippingMethodText}}
**Payment method:**  {{paymentMethod}}

## Total: {{totalAmountFormatted}}
`,

    receiptEmailEnabled: false,
    receiptEmailFrom: 'notify@alerts.spreadsimple.com',
    receiptReplyToEmail: '',
    receiptEmailSubject: 'Your order from SpreadSimple: {{orderId}}',
    receiptSenderName: '',
    receiptEmailTemplate: `## Thanks for your order

Order #: {{orderId}}

#### Items Ordered

{{cartItemsText}}

Total: {{totalAmountFormatted}}

Name: {{client.name}}
Phone: {{client.phone}}
Email: {{client.email}}

Order notes: {{client.message}}`,
    websiteAccess: {
      fromName: '',
      subject: '{{websiteDomain}} request code',
      body: `Hello! Someone has requested code for your email to access {{websiteDomain}}

Follow {{loginUrl}} to log in with this magic link.

Or copy your code:
# {{validationCode}}`
    }
  },
  paypal: {
    enablePaypalCheckout: false,
    currency: 'USD',
    environment: 'sandbox',
    sandboxClientId: '',
    productionClientId: '',
    buttonColor: 'blue'
  },
  routes: [],
  checkout: {
    fields: [],
    shipping: [],
    fees: [],
    tipsTitle: 'Tips',
    tips: [],
    paymentsOrder: [],
    promocodes: {
      enabled: false
    }
  },
  widget: {
    onlyWidgetMode: false,
    widgetModeDisallowed: false
  },
  redirectRules: [],
  privateData: {
    privateCells: {
      enabled: false,
      ids: []
    },
    privateRow: {
      enabled: false,
      id: null
    }
  },
  loginPage: {
    markdown: `## Welcome!

Please provide your email address.
We will send you the validation code that you will need to sign up.`
  },
  socialNetworks: []
}

export default options

// .class
// .meta-title
// .meta-description
// .og-image
// .date
