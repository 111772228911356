export default {
  published: true,
  pro: true,
  featureRestrictionsGroup: 'ecommerceIntegrations',
  name: 'ads.txt',
  title: 'ads.txt Builder',
  logoUrl: '/img/integrations/ads_txt.svg',
  bgColor: '#FEA832',
  categories: ['E-commerce'],
  comingSoon: false,
  builtIn: false,
  url: '',
  helpUrl: 'https://support.google.com/adsense/answer/7532444?hl=en',
  description: `
  Authorized Digital Sellers, or ads.txt is an IAB Tech Lab initiative that helps ensure that your digital ad inventory is only sold through sellers (such as AdSense) who you've identified as authorized. Creating your own ads.txt file gives you more control over who's allowed to sell ads on your site and helps prevent counterfeit inventory from being presented to advertisers.
  `,

  formFields: [
    {
      name: 'enabled',
      type: 'toggle',
      label: 'Enabled',
      default: false,
      validations: [
        'required'
      ],
      secret: false
    },
    {
      name: 'content',
      type: 'textarea',
      showIf: ['enabled'],
      label: 'Content',
      default: '',
      placeholder: '',
      validations: [
        'required'
      ],
      secret: false
    }
  ],

  actions: []
}
