<template>
  <Modal
    :value="opened"
    modalName="Import from Notion"
    @input="$emit('on-close')"
    >
    <div class="unsplash-modal__download-section">
      <div class="unsplash-modal__download-section-title">
        <p>1. Export a Notion page as markdown archive</p>
        <p>2. Upload this file to import the page content</p>
        <br>
        <p><a class="sorting-constructor__link" href="https://help.spreadsimple.com/en/article/import-from-notion-1lvfjr7/" target="_blank">Step-by-step guide →</a></p>
      </div>
      <form
        ref="uploadForm"
        enctype="multipart/form-data"
        @change.prevent="uploadFile">
        <label>
          <input
            ref="file"
            style="display: none;"
            type="file"
            :disabled="$async.uploadNotionExport.$pending"
          >
          <div class="uploader__btn">
            <transition name="fade" mode="out-in">
              <div v-if="!$async.uploadNotionExport.$pending" key="btn">
                Upload file
                <i class="uploader__btn-icon icon-upload2"></i>
              </div>
              <div v-else key="btn2">
                Loading
              </div>
            </transition>
          </div>
        </label>
      </form>
    </div>
  </Modal>

</template>
<script>
import Modal from '@/components/modal'
import api from '@/services/api/'
export default {
  name: 'UploadFromNotion',
  components: {
    Modal
  },
  props: {
    opened: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      resultingValue: ''
    }
  },
  methods: {
    updateValue (val) {
      this.$emit('input', val)
      this.$emit('on-close')
    },
    uploadFile () {
      let data = new FormData()
      data.append('archive', this.$refs.file.files[0])
      this.$async.uploadNotionExport.$perform(data)
        .then(response => {
          this.$emit('input', response)
          this.$refs.uploadForm.reset()
          this.$emit('on-close')
        })
        .catch((error) => {
          console.log(error)
          this.$refs.uploadForm.reset()
        })
    }
  },
  asyncOperations: {
    uploadNotionExport (data) {
      return api.uploadNotionExport(data)
    }
  }
}
</script>
