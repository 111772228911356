<template>
  <div class="sorting-constructor__item">
    <v-select
      :value="filtersState"
      :items="cols"
      item-text="label"
      item-value="id"
      attach
      menu-props="offsetY"
      chips
      deletable-chips
      multiple
      placeholder="Choose options"
      append-icon="keyboard_arrow_down"
      @change="updateFiltersValues"
    >
      <template slot="selection" slot-scope="data">
        <DraggableChip
          :id="data.index"
          v-model="filtersState"
        >
          {{ data.item.label }}
        </DraggableChip>
      </template>

    </v-select>
    <a class="sorting-constructor__link" target="_blank" href="https://help.spreadsimple.com/en/article/how-to-use-filters-1mpgefx/?1595796655713">Help: How to use filters?</a>
  </div>
</template>
<script>
import DraggableChip from '@/components/draggable-chip/DraggableChip.vue'

export default {
  name: 'FiltersConstructor',
  components: { DraggableChip },
  props: {
    cols: {
      type: Array,
      required: true
    },
    value: {
      type: Array,
      required: true
    },
    v: {
      type: Object
    }
  },
  data () {
    return {
      filtersState: []
    }
  },
  watch: {
    filtersState: {
      deep: true,
      handler: function (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    updateFiltersValues (val) {
      const updatedValues = val.map(i => {
        return {
          id: i,
          type: 'multiple'
        }
      })
      this.filtersState = updatedValues
    }
  },
  created () {
    this.filtersState = JSON.parse(JSON.stringify(this.value))
  }
}
</script>
