var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "unsubscribe-modal" }, [
    _c("div", { staticClass: "unsubscribe-modal__content-wrap" }, [
      _c("p", [
        _vm._v("\n      During your next billing period on "),
        _c("b", [_vm._v(_vm._s(_vm.nextBillingDate))]),
        _vm._v(" you will be charged only "),
        _c("b", [_vm._v("$" + _vm._s(_vm.nextBillingSum))]),
        _vm._v(".\n    ")
      ])
    ]),
    _c(
      "div",
      { staticClass: "unsubscribe-modal__btns justify-end" },
      [
        _c(
          "UiBtn",
          {
            on: {
              click: function($event) {
                return _vm.$emit("close-modal")
              }
            }
          },
          [_vm._v("ОК")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }