<template>
  <div>
    <FormField class="tile-constructor__field aside-select">
      <CustomOptionsPicker
        v-model="layoutOptions.type"
        :options="$options.singleItemLayoutOptions"
        label="Layout type"
      />
    </FormField>

    <div v-if="layoutOptions.type !== 'full-width'" class="content-form__switch-field">
      <div class="content-form__switcher-wrap">
        <div class="content-form__switcher-label">Reverse layout</div>
        <label
          :class="{ 'selected': layoutOptions.reverse }"
          class="switcher"
        >
          <input
            v-model="layoutOptions.reverse"
            class="switcher__input"
            type="checkbox"
            value="tiles"
          >
          <span class="switcher__indicator"></span>
        </label>
      </div>
    </div>

  </div>
</template>
<script>
import FormField from '@/components/form-field/'
import CustomOptionsPicker from '@/components/custom-options-picker/'
import { singleItemLayoutOptions } from '@/modules/utils/singleItemLayoutOptions'
export default {
  name: 'SingleItemLayoutConstructor',
  singleItemLayoutOptions,
  components: {
    CustomOptionsPicker,
    FormField
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      layoutOptions: {}
    }
  },
  watch: {
    layoutOptions: {
      deep: true,
      handler: function (val) {
        this.$emit('input', val)
      }
    }
  },
  created () {
    let obj = JSON.parse(JSON.stringify(this.value))
    this.layoutOptions = obj
  }
}
</script>
