<template>
  <div class="unsubscribe-modal">
    <div class="unsubscribe-modal__content-wrap">
      <p>We’d really like to know what went wrong. Please share your honest feedback with us so we can make the right decisions and improve our product and service.</p>
      <p>Please select one or several reasons below:</p>
    </div>
    <div class="unsubscribe-modal__form">
      <div
        v-for="reason in reasonsList"
        :key="reason.key"
        class="unsubscribe-modal__form-item"
      >
        <label class="unsubscribe-modal__checkbox checkbox">
          <input :value="reason.checked" type="checkbox"
            @input="$emit('reason-checked', { reason: reason.key, value: $event.target.checked })"
          >
          <div class="checkbox__indicator" />
          <span class="checkbox__title"> {{ reason.label }} </span>
        </label>
        <textarea
          v-if="reason.details && reason.checked"
          :value="reason.details.value"
          class="unsubscribe-modal__form-item-textarera"
          tabindex="1"
          :placeholder="reason.details.description"
          @input="$emit('reason-detailed', { description: $event.target.value, reason: reason.key })"
          >
        </textarea>
      </div>
    </div>
    <div class="unsubscribe-modal__btns">
      <UiBtn full-width type="secondary" @click="$emit('close-modal')">Cancel</UiBtn>
      <UiBtn full-width :disabled="pending || !isNextBtnActive" @click="$emit('submit-quiz')">Next</UiBtn>
    </div>
    <p v-if="quizError" class="unsubscribe-modal__error-msg">{{ quizError }}</p>
  </div>
</template>

<script>
import UiBtn from '@/components/ui-btn/UiBtn.vue'
export default {
  name: 'UnsubscribeReasons',
  components: {
    UiBtn
  },
  props: {
    reasons: {
      type: Array,
      default: () => []
    },
    quizError: {
      type: Boolean,
      default: false
    },
    pending: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    reasonsList () {
      return this.reasons
    },
    isNextBtnActive () {
      return this.reasonsList.some(reason => reason.checked)
    }
  }
}
</script>
