<template>
<div class="source-options">
  <transition name="fade" mode="out-in">
    <div v-if="!visible" key="btns">
      <div class="source-options__row">
        <button v-if="!accessRules.created" class="source-options__link" :disabled="loading" @click.prevent="$emit('create-users-spreadsheet')">
          <i class="source-options__link-icon icon-edit-google-sheet"></i>
          CREATE USERS SHEET
        </button>
        <a v-else :href="accessRules.spreadsheetUrl" target="_blank" class="source-options__link">
          <i class="source-options__link-icon icon-edit-google-sheet"></i>
          EDIT USERS SHEET
        </a>
        <button
          v-if="accessRules.created"
          class="source-options__button"
          :disabled="loading"
          title="Refresh Users Sheet data"
          @click.prevent="$emit('refresh-users-spreadsheet')">
          <i v-if="!refreshingSheetData" class="icon-refresh-arrow"></i>
          <div v-else class="icon-loader"></div>
        </button>
      </div>
      <button
        class="source-options__change-btn"
        :disabled="loading"
        @click.prevent="visible = true"
      >
        <i v-if="accessRules.created" class="source-options__change-btn-icon icon-pencil-new"></i>
        {{ !accessRules.created ? 'Add existing sheet' : 'Change source sheet' }}
      </button>
    </div>

    <div v-else key="form" class="source-options__form">
      <button
        class="source-options__form-back" @click.prevent="visible = false">
        <i class="source-options__form-back-icon icon-arrow"></i>
        Back
      </button>
      <div class="source-options__form-wrapper">
        <form class="spread-form" @submit.prevent="$emit('connect-users-spreadsheet', url)">
          <div class="spread-form__form-elements">
              <v-text-field
                v-model="url"
                :disabled="loading"
                :label="`${accessRules.created ? 'Linked spread sheet' : 'Paste Google Sheet link here'}`"
                class="spread-form__sidebar-input"
                @input="val => $emit('update-spread-sheet-url', val)"
              />
            <button
              :disabled="loading"
              type="submit"
              class="spread-form__sidebar-add-btn"
            >
              {{ websiteAccess.scheme.length && url ? 'UPDATE' : 'CONNECT' }}
            </button>
          </div>
          <div class="spread-form__messages-wrapp">
            <span
              v-if="!v.required"
              class="spread-form__message error"
            >
              {{ !v.required && v.$dirty ? $t('global.fieldIsRequired') : ''}}
            </span>
            <span
              v-else-if="v.$invalid"
              class="spread-form__message error"
            >
              {{ v.$invalid && v.$dirty ? $t('global.notValidUrl') : '' }}
            </span>
            <span
              v-if="accessRulesError"
              class="spread-form__message error"
            >
            {{ accessRulesError }}
            </span>
          </div>
        </form>
      </div>
    </div>
  </transition>
  <div class="side-form__item" style="margin-top: 30px">
    <a class="sorting-constructor__link" target="_blank" href="https://help.spreadsimple.com/en/article/how-to-set-up-website-access-rules-1vre7ri/">
      Help: How to set up website access rules?
    </a>
  </div>
</div>
</template>

<script>
// import FormField from '@/components/form-field'
import { mapState } from 'vuex'
export default {
  name: 'AccessManagementSpreadForm',
  components: {
    // FormField
  },
  props: {
    v: {
      type: Object
    },
    btnsDisabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    refreshingSheetData: {
      type: Boolean,
      default: false
    },
    accessRulesError: {
      type: [String, null],
      default: null
    },
    accessRules: {
      type: Object
    }
  },
  data () {
    return {
      visible: false,
      url: ''
    }
  },
  computed: {
    ...mapState('app', ['websiteAccess'])
  },
  watch: {
    'accessRules.created' (val) {
      if (!val) {
        this.url = ''
      }
    },
    'accessRules.spreadsheetUrl' (val, oldVal) {
      this.url = this.accessRules.spreadsheetUrl
    }
  },
  created () {
    this.url = this.accessRules.spreadsheetUrl
  }
}
</script>
