<template>
  <div>
    <button class="settings__back-btn" @click="goBackAndSave()">
      <i class="icon-arrow settings__back-btn-icon"></i>
      Back to list
    </button>
    <form
      class="side-form"
      @submit.prevent="submitForm"
    >
      <p v-if="validationStatus !== ''" class="aside-message">
        {{ validationStatus }}
      </p>
      <div v-if="route.type === 'page'" class="side-form__section">
        <h6 class="side-form__section-title">Content</h6>
        <div class="side-form__item">
          <button class="side-form__text-btn is-filled"
            type="button"
            @click="openFullscreenEditor = true"
          >
            <i class="icon-expand side-form__text-btn-icon"></i>
            Expand editor
          </button>
        </div>
        <div class="side-form__item">
          <MarkdownComponent v-model="route.content" :preview="false"/>
          <FullscreenMdEditor v-if="openFullscreenEditor" @onclose="openFullscreenEditor = false">
            <MarkdownComponent v-model="route.content" height="100%" :preview="true"/>
          </FullscreenMdEditor>
        </div>
      </div>
      <CollapsableBlock :expanded="true" class="side-form__section" title="Navigation">
        <div class="side-form__item">
          <FormField :label="`Menu item title`">
            <v-text-field
              v-model.trim="route.navTitle"
            ></v-text-field>
          </FormField>
        </div>
        <div class="side-form__item">
          <FormField
          v-if="route.type === 'link'"
          label="URL"
          hint="External URL must include a protocol prefix e.g. https://"
          >
            <v-text-field
              v-model.trim="$v.route.$model.navUrl"
              @blur="trimInternalLink"
            ></v-text-field>
            <p
              v-if="$v.route.navUrl.$invalid"
              class="form-field__error"
            >
              {{ $t('global.fieldIsRequired') }}
            </p>
          </FormField>
          <FormField
            v-else
            label="Page address"
            :messages="currentRouteUrlValidationErrors"
          >
            <v-text-field
              v-model.trim="$v.currentRouteUrl.$model"
              @blur="trimInternalPageUrl"
            ></v-text-field>
            <p
              v-if="!$v.currentRouteUrl.$model"
              class="form-field__error"
            >
              {{ $t('global.fieldIsRequired') }}
            </p>
          </FormField>
        </div>
        <div class="side-form__item">
          <FormField :label="`Open ${route.type} in`">
            <v-select
              v-model="route.navTarget"
              :items="navTargetOptions"
              item-text="label"
              item-value="value"
              attach
              menu-props="offsetY"
              append-icon="keyboard_arrow_down"
            ></v-select>
          </FormField>
        </div>
        <div class="side-form__item">
          <FormField label="Menu item type">
            <v-select
              v-model="route.navStyle"
              :items="navStyleOptions"
              item-text="label"
              item-value="value"
              attach
              menu-props="offsetY"
              append-icon="keyboard_arrow_down"
            ></v-select>
          </FormField>
        </div>
        <div class="side-form__item">
          <!-- <label class="side-form__checkbox">
            <input
              v-model="route.navHide"
              type="checkbox"
              class="side-form__checkbox-input"
            >
            <span class="side-form__checkbox-indicator" :class="{ 'is-active': route.navHide }"></span>
            <span class="side-form__checkbox-label">Hide this {{ route.type }} from menu</span>
          </label> -->
          <div class="side-form__switcher-wrap">
            <div class="side-form__switcher-label">Hide this {{ route.type }} from menu</div>
            <label
              :class="{ 'selected': route.navHide }"
              class="switcher"
            >
              <input
                v-model="route.navHide"
                class="switcher__input"
                type="checkbox"
                value="tiles"
              >
              <span class="switcher__indicator"></span>
            </label>
          </div>
        </div>
      </CollapsableBlock>
      <CollapsableBlock v-if="route.type === 'link'" class="side-form__section" title="URL Aliases">
        <div class="side-form__item">
          <FormField
          label="URL Alias"
          hint="A nice looking URL path for filtered content. <a href='https://help.spreadsimple.com/en/article/url-aliases-1xkqzul/' target=_blank>Learn more</a>"
          >
            <v-text-field
              v-model.trim="route.navAlias"
              placeholder="(no alias)"
              @blur="trimAlias"
            ></v-text-field>
            <p
              v-if="route.navAlias && !$v.route.navAlias.isUnique"
              class="form-field__error"
            >
              Route Alias URL must be unique
            </p>
            <p
              v-else-if="route.navAlias && !$v.route.navAlias.isInternalUrl"
              class="form-field__error"
            >
              Aliases work only for internal URLs.
            </p>
          </FormField>
        </div>
        <div class="side-form__item">
          <FormField label="Alias page title" hint="Maximum length 50 characters">
            <v-text-field
              v-model.trim="route.aliasPageTitle"
            ></v-text-field>
          </FormField>
        </div>
        <div class="side-form__item">
          <FormField label="Alias page subtitle" hint="Maximum length 300 characters">
            <v-text-field
              v-model.trim="route.aliasPageSubTitle"
            ></v-text-field>
          </FormField>
        </div>
        <div class="side-form__item">
          <FormField label="Alias - text before cards" class="side-form__item">
            <MarkdownComponent
              v-model="route.aliasContentBeforeDataMd"
              :preview="false"
              leftToolbar="bold italic strikethrough link"
            />
          </FormField>
        </div>
        <div class="side-form__item">
          <FormField label="Alias - text after cards" class="side-form__item">
            <MarkdownComponent
              v-model="route.aliasContentAfterDataMd"
              :preview="false"
              leftToolbar="bold italic strikethrough link"
            />
          </FormField>
        </div>
        <div class="side-form__item">
          <FormField label="Alias meta title" hint="Maximum length 160 characters">
            <v-text-field
              v-model.trim="route.metaTitle"
              :placeholder="route.navTitle"
            ></v-text-field>
          </FormField>
        </div>
        <div class="side-form__item">
          <FormField label="Alias meta description" hint="Maximum length 160 characters">
            <textarea
              v-model="route.metaDescription"
              class="textarea"
            ></textarea>
          </FormField>
        </div>
      </CollapsableBlock>
      <CollapsableBlock v-if="route.type === 'page'" class="side-form__section" title="Seo">
        <div class="side-form__item">
          <FormField label="Meta title" hint="Maximum length 160 characters">
            <v-text-field
              v-model.trim="route.metaTitle"
              :placeholder="route.navTitle"
            ></v-text-field>
          </FormField>
        </div>
        <div class="side-form__item">
          <FormField label="Meta description" hint="Maximum length 160 characters">
            <textarea
              v-model="route.metaDescription"
              class="textarea"
            ></textarea>
          </FormField>
        </div>
        <div class="side-form__item">
          <!-- <label class="side-form__checkbox">
            <input
              v-model="route.seoHide"
              type="checkbox"
              class="side-form__checkbox-input"
            >
            <span class="side-form__checkbox-indicator" :class="{ 'is-active': route.seoHide }"></span>
            <span class="side-form__checkbox-label"></span>
          </label> -->
          <div class="side-form__switcher-wrap">
            <div class="side-form__switcher-label">Hide this page from search results</div>
            <label
              :class="{ 'selected': route.seoHide }"
              class="switcher"
            >
              <input
                v-model="route.seoHide"
                class="switcher__input"
                type="checkbox"
                value="tiles"
              >
              <span class="switcher__indicator"></span>
            </label>
          </div>
        </div>
      </CollapsableBlock>

      <CollapsableBlock v-if="$ft.ALLOW_USER_ACCESS" class="side-form__section" title="ACCESS">
        <div class="side-form__item">
          <FormField
            label="User groups that have access to this page">
            <ProOptionWrapper :featureName="'userAccess'">
            <v-select
              v-model="route.accessRules.allowedGroups"
              :items="websiteAccess.availableAccessGroups"
              :disabled="!websiteAccess.enabled"
              item-text="name"
              item-value="name"
              attach
              :placeholder="!websiteAccess.enabled ? 'Create or enable access rules' : 'Choose group'"
              clearable
              multiple
              chips
              menu-props="offsetY"
              append-icon="keyboard_arrow_down"
            ></v-select>
            </ProOptionWrapper>
          </FormField>
          <a class="sorting-constructor__link" target="_blank" href="https://help.spreadsimple.com/en/article/how-to-set-up-website-access-rules-1vre7ri/">Help: How to set up website access rules?</a>
        </div>
      </CollapsableBlock>
      <UiBtn
        size="l"
        attr-type="submit"
        :loading="loading"
        :disabled="loading || $v.$invalid"
        class="aside-save"
      >
        SAVE
      </UiBtn>
    </form>
  </div>
</template>

<script>
import Vue from 'vue'
import Vuelidate from 'vuelidate'
import CollapsableBlock from '@/components/collapsable-block'
import FormField from '@/components/form-field/'
import UiBtn from '@/components/ui-btn'
import MarkdownComponent from '@/components/markdown-component'
import FullscreenMdEditor from '@/components/fullscreen-md-editor'
import { required } from 'vuelidate/lib/validators'
import { mapState, mapActions, mapGetters } from 'vuex'
import ProOptionWrapper from '@/components/pro-option-wrapper'
Vue.use(Vuelidate)

function trimSlashes (val) {
  return String(val).trim().replace(/^\/|\/$/g, '')
}

export default {
  name: 'RouteForm',
  components: {
    CollapsableBlock,
    FormField,
    MarkdownComponent,
    FullscreenMdEditor,
    UiBtn,
    ProOptionWrapper
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    domain: {
      type: String,
      required: true
    },
    routes: {
      type: Array
    },
    currentRouteIndex: {
      type: Number
    }
  },
  data () {
    return {
      validationStatus: '',
      openFullscreenEditor: false,
      currentRouteUrl: '',
      navStyleOptions: [
        {
          label: 'Link',
          value: 'link'
        },
        {
          label: 'Primary Button',
          value: 'primary-button'
        },
        {
          label: 'Secondary Button',
          value: 'secondary-button'
        }
      ]

    }
  },
  computed: {
    ...mapState('app', [
      'websiteAccess'
    ]),
    ...mapGetters('app', ['spreadViewerOptionsInvalid']),
    route: {
      get () {
        const newValue = this.value
        if (!this.value.accessRules) {
          newValue.accessRules = {
            allowedGroups: []
          }
        }
        return newValue
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    isRouteUnique () {
      const routesUrls = this.routes.filter((el, index) => index !== this.currentRouteIndex).map(el => el.navUrl)
      return !routesUrls.includes(this.currentRouteUrl)
    },
    isInternalUrl () {
      const navUrl = String(this.route.navUrl).trim()
      return navUrl.startsWith(this.baseDomain) || !(navUrl.startsWith('http'))
    },

    navTargetOptions () {
      return [
        {
          label: 'Same tab (regular)',
          value: '_self'
        },
        {
          label: 'New tab',
          value: '_blank'
        },
        ...this.value.type === 'link'
          ? [{
            label: 'Popup',
            value: 'popup',
            disabled: this.isInternalUrl
          }] : []
      ]
    },
    baseDomain () {
      return process.env.NODE_ENV === 'development' ? process.env.VUE_APP_VIEWER_APP_BASE_URL : `https://${this.domain}`
    },
    currentRouteUrlValidationErrors () {
      if (!this.$v.currentRouteUrl.checkRouteUnique) {
        return ['Route URL must be unique']
      } else if (!this.$v.currentRouteUrl.checkRouteIsNotReserved) {
        return ['This route URL is reserved']
      } else {
        return []
      }
    }
  },
  watch: {
    'websiteAccess.created' (val) {
      if (!val) {
        this.route.accessRules.allowedGroups = []
      }
    }
  },
  validations: {
    currentRouteUrl: {
      required,
      checkRouteUnique: function (value) {
        if (this.route.type === 'link') return true
        return this.isRouteUnique
      },
      checkRouteIsNotReserved: function (value) {
        // return value !== '/login'
        return true
      }
    },
    route: {
      navUrl: {
        required
      },
      navAlias: {
        isUnique: function (alias) {
          return !this.routes.find((route, index) => {
            if (!alias || this.currentRouteIndex === index) return false
            if (alias.trim() === '/') return true
            return trimSlashes(route.navAlias || route.navUrl) === trimSlashes(alias)
          })
        },
        isInternalUrl: function (alias) {
          if (!alias || this.route.type !== 'link') return true
          return this.isInternalUrl
        }
      }
    }
  },
  methods: {
    ...mapActions('app', ['setSpreadViewerOptionsValidationState']),
    submitForm () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.validationStatus = this.$t('global.formSubmitError')
        return
      }
      if (this.route.type === 'page') {
        this.$emit('input', {
          ...this.route,
          navUrl: this.currentRouteUrl
        })
      }
      this.$emit('submit')
      this.validationStatus = ''
    },
    goBackAndSave () {
      if (this.route.type === 'page') {
        this.$v.$touch()
        if (this.$v.$invalid) {
          this.validationStatus = this.$t('global.formSubmitError')
          return
        }
        this.$emit('input', {
          ...this.route,
          navUrl: this.currentRouteUrl
        })
      }
      this.$emit('back')
    },
    trimInternalPageUrl () {
      if (this.currentRouteUrl.startsWith(this.baseDomain)) {
        this.currentRouteUrl = this.currentRouteUrl.trim().split(this.baseDomain)[1] || '/'
      } else {
        this.currentRouteUrl = this.prefixWithSlash(this.currentRouteUrl)
      }
    },
    trimInternalLink () {
      if (this.route.navUrl.startsWith(this.baseDomain)) {
        this.route.navUrl = this.route.navUrl.trim().split(this.baseDomain)[1] || '/'
      } else if (!this.doNotPrefixWithSlash(this.route.navUrl)) {
        this.route.navUrl = this.prefixWithSlash(this.route.navUrl)
      }
    },
    doNotPrefixWithSlash (path) {
      return path.startsWith('http') ||
        path.startsWith('#') ||
        this.checkIfLinkIsToContact(path)
    },
    checkIfLinkIsToContact (url) {
      return url.startsWith('tel:') ||
      url.startsWith('mailto:') ||
      url.startsWith('whatsapp:') ||
      url.startsWith('skype:') ||
      url.startsWith('sms:') ||
      url.startsWith('irc:') ||
      url.startsWith('geo:')
    },
    prefixWithSlash (path) {
      if (!path.startsWith('/')) {
        return '/' + path
      }
      return path
    },
    trimAlias () {
      if (!this.route.type === 'link' || !this.route.navAlias) return
      this.route.navAlias = String(this.route.navAlias).trim()
      this.route.navAlias = this.prefixWithSlash(this.route.navAlias)
    }
  },
  created () {
    this.currentRouteUrl = this.value.navUrl
  },
  mounted () {
    this.$watch('$v.$invalid', (val) => {
      this.setSpreadViewerOptionsValidationState({
        optionName: 'routesOptionsInvalid',
        isInvalid: val
      })
    })
  }
}
</script>
