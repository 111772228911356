<template>
  <div class="errorPage">
    <div class="errorPage__container">

      <a v-if="options.link" :href="options.link" class="errorPage__logo sv-link-reset">
        <svg
          class="viewer__footer-logo-img"
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="30"
          viewBox="0 0 40 30">
          <path
            fill="#A2A9B4"
            fill-rule="nonzero"
            d="M19.748.42l20.143 10.55-8.837 4.175 1.895 6.584-13.216 7.868L.136 18.763l8.524-4.299L5.582 6.19l-.015-.013-.006-.004-.016-.014-.02-.024a.263.263 0 0 1-.052-.113l-.003-.015-.001-.015a.255.255 0 0 1 .001-.06l.005-.024-.084-.232L19.748.42zM8.865 14.965l-7.573 3.82 18.431 10.19 12.235-7.286-23.035-6.7-.058-.024zm-.329-7.681L32.182 21.03l-1.634-5.675L8.536 7.284zM6.32 6.62l2.942 7.906 21.33 6.203L6.32 6.62zM19.71 1.009L6.339 5.903l24.215 8.88 8.126-3.839L19.71 1.01z"/>
        </svg>
      </a>
      <div class="errorPage__number">{{options.error}}</div>
      <h2 class="errorPage__title sv-text-reset">{{options.title}}</h2>
      <p class="errorPage__paragraph sv-text-reset">{{options.content}}</p>
      <slot/>
      <a v-if="options.link && options.linkText" :href="options.link" class="errorPage__home-link sv-link-reset">{{options.linkText}}</a>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ErrorPage',
  props: {
    options: {
      type: Object,
      default: () => {
        return {
          error: '404',
          title: 'Ooops… Not found',
          content: 'The requested URL was not found on this server. That’s all we know.',
          link: '/',
          linkText: 'Back Home'
        }
      }

    }
  }
}
</script>
<style src='./errorPage.styl' lang='stylus'></style>
