<template>
  <draggable
    :id="id"
    :list="value"
    v-bind="dragOptionsChips"
    :move="move"
    @change="change"
  >
    <v-chip
      :key="id"
      draggable
      close
      @mousedown.stop
      @click.stop
      @input="() => onChipDeleteClick(id)"
    >
      <slot/>
    </v-chip>
  </draggable>
</template>

<script>
import draggable from 'vuedraggable'
export default {
  name: 'DraggableChip',
  components: { draggable },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    value: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      dragged: {
        from: -1,
        to: -1,
        newIndex: -1
      }
    }
  },
  computed: {
    dragOptionsChips () {
      return {
        animation: 200,
        group: 'group',
        disabled: false,
        ghostClass: 'ghost',
        sort: true
      }
    }
  },
  methods: {
    move: function (value) {
      this.dragged = {
        from: parseInt(value.from.id),
        to: parseInt(value.to.id),
        newIndex: value.draggedContext.futureIndex
      }
    },
    change: function (value) {
      const copy = [...this.value]
      if (value.removed) {
        // insert
        copy.splice(this.dragged.to + this.dragged.newIndex, 0, copy[this.dragged.from])
        // delete
        if (this.dragged.from < this.dragged.to) {
          copy.splice(this.dragged.from, 1)
        } else {
          copy.splice(this.dragged.from + 1, 1)
        }
      }
      this.$emit('input', copy)
    },
    onChipDeleteClick (index) {
      const copy = [...this.value]
      copy.splice(index, 1)

      this.$emit('input', copy)
    }
  }
}
</script>

<style scoped>

</style>
