var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "profile-tab__form",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.updateProfile.apply(null, arguments)
        }
      }
    },
    [
      _c("div", { staticClass: "profile-tab__switcher-wrapp" }, [
        _c("p", { staticClass: "profile-tab__switcher-label" }, [
          _vm._v(_vm._s(_vm.$t("pageAccount.keepMeUpdated")))
        ]),
        _c(
          "label",
          { staticClass: "switcher", class: { selected: _vm.subscribed } },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.subscribed,
                  expression: "subscribed"
                }
              ],
              staticClass: "switcher__input",
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.subscribed)
                  ? _vm._i(_vm.subscribed, null) > -1
                  : _vm.subscribed
              },
              on: {
                change: [
                  function($event) {
                    var $$a = _vm.subscribed,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.subscribed = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.subscribed = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.subscribed = $$c
                    }
                  },
                  function($event) {
                    return _vm.$trackEvent({
                      event: "subscribeToNews",
                      status: _vm.subscribed
                    })
                  }
                ]
              }
            }),
            _c("span", { staticClass: "switcher__indicator" })
          ]
        )
      ]),
      _c(
        "button",
        { staticClass: "profile-tab__button", attrs: { type: "submit" } },
        [_vm._v("\n    " + _vm._s(_vm.$t("global.save")) + "\n  ")]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }