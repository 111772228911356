import defaultOptions from './options'
import checkColumns from './column-checker'
import deepmerge from 'deepmerge'
import fixDefaultTranslations from './fix-translations'

function mergeOptions (options) {
  const merged = deepmerge(defaultOptions, options, { arrayMerge: (destinationArray, sourceArray) => sourceArray })
  try {
    return fixDefaultTranslations(merged, options.locale)
  } catch (e) {
    console.warn('Failed to reset legacy translations', e)
    return merged
  }
}

export {
  defaultOptions,
  checkColumns,
  mergeOptions
}
