<template>
  <div>
    <div class="profile-tab__container">
      <h4 class="profile-tab__title">{{ $t('global.settings') }}</h4>
      <p v-if="userData" class="profile-tab__subtitle">{{ fullName }} ({{ email }})</p>
      <FormSettings
        :newsSubscription="userData.newsSubscription"
      />
      <div class="profile-tab__divider"></div>
      <div class="profile-tab__danger">
        <!-- <div class="profile-tab__danger-title">Danger zone</div> -->
        <button class="profile-tab__danger-btn" @click="modalDeleteOpened = true">Delete my account</button>
      </div>
    </div>
    <Modal
      v-model="modalDeleteOpened"
      modalName="Are you sure?"
      >
      <div class="delete-modal">
        <div class="delete-modal__body">
          <p class="delete-modal__paragraph">This action cannot be undone. This will permanently delete your entire account.</p>
          <form class="delete-modal__confirm-form" @submit.prevent="deleteAccount">
            <FormField
              label="Please type in your email to confirm"
              :messages="confirmEmailMessages"
            >
              <input v-model="confirmEmail" type="text" :placeholder="hiddenEmail" class="delete-modal__confirm-form-input">
            </FormField>
            <div class="delete-modal__field">
              <button :disabled="loading" :class="{'disabled': loading}" type="submit" class="delete-modal__button">Delete my account</button>
              <button type="button" class="delete-modal__button negative" @click="modalDeleteOpened = false">Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import Modal from '@/components/modal'
import FormField from '@/components/form-field'
import FormSettings from '../form-settings/'
import Vuelidate from 'vuelidate'
import Vue from 'vue'
import api from '@/services/api/'
import { get } from '@/modules/utils/'
import { required } from 'vuelidate/lib/validators'
Vue.use(Vuelidate)

export default {
  name: 'AccountSettings',
  components: {
    Modal,
    FormSettings,
    FormField
  },
  data () {
    return {
      modalDeleteOpened: false,
      confirmEmail: '',
      loading: false
    }
  },
  validations: {
    confirmEmail: {
      required,
      sameAsUserEmail: (value, vm) => {
        return vm.email === value.trim()
      }
    }
  },
  computed: {
    ...mapState('app', [
      'userData'
    ]),
    fullName () {
      return `${get(this.userData, 'firstName', '')} ${get(this.userData, 'lastName', '')}`
    },
    email () {
      return get(this.userData, 'email', '')
    },
    hiddenEmail () {
      const emailArray = this.email.split('@')
      const firstPartAsArray = Array.from(emailArray[0])
      const firstPart = firstPartAsArray.map((el, index) => {
        if (index === 0 || index === (firstPartAsArray.length - 1)) { return el } else { return '*' }
      }).join('')

      return `${firstPart}@${emailArray[1]}`
    },
    confirmEmailMessages () {
      const messages = []
      if (this.$v.confirmEmail.$dirty && !this.$v.confirmEmail.required) {
        messages.push('Fied is required')
      }
      if (this.$v.confirmEmail.$dirty && !this.$v.confirmEmail.sameAsUserEmail) {
        messages.push('Email is incorrect')
      }
      return messages
    }
  },
  watch: {
    modalDeleteOpened: function (val) {
      if (!val) {
        this.confirmEmail = ''
        this.$v.confirmEmail.$reset()
      }
    }
  },
  methods: {
    get,
    async logout () {
      await this.$auth.logout()
      this.$router.push({ name: 'login' })
    },
    async deleteAccount () {
      this.$v.confirmEmail.$touch()
      if (!this.$v.confirmEmail.$invalid) {
        this.loading = true
        try {
          await api.deleteUserAccount()
          await this.logout()
        } catch (e) {
          console.log(`Error: ${e}`)
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>
