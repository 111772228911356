<template>
  <div>
    <div class="account-page">
      <div v-if="userData" class="account-page__container">
        <el-tabs
          v-model="activeTab"
          type="card"
          class="account-tabs"
          @tab-click="pushToRoute"
        >
          <el-tab-pane
            :label="$t('global.billing')"
            name="account-billing"
          >
            <div class="profile-tab no-bottom-indent">
              <BillingInfo/>
            </div>
          </el-tab-pane>
          <el-tab-pane
            :label="$t('global.settings')"
            name="account-settings"
          >
            <div class="profile-tab">
              <AccountSettings/>
            </div>
          </el-tab-pane>
          <el-tab-pane
            label="Affiliate"
            name="account-affiliate"
          >
            <div class="profile-tab">
              <AffiliateSettings/>
            </div>
          </el-tab-pane>
          <el-tab-pane
            label="Workspace"
            name="account-workspaces"
          >
            <div class="profile-tab">
              <WorkspacesSettings/>
            </div>
          </el-tab-pane>
          <el-tab-pane
            v-if="userData.appsumo"
            label="AppSumo"
            name="account-appsumo"
          >
            <div class="profile-tab">
              <AppsumoSettings/>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import WorkspacesSettings from './workspaces-settings/'
import BillingInfo from './billing-info/'
import AffiliateSettings from './affiliate-settings'
import AccountSettings from './account-settings'
import AppsumoSettings from './appsumo-settings'

export default {
  name: 'AccountPage',
  metaInfo: {
    title: 'Account'
  },
  components: {
    BillingInfo,
    WorkspacesSettings,
    AffiliateSettings,
    AccountSettings,
    AppsumoSettings
  },
  data () {
    return {
      activeTab: this.$route.name
    }
  },
  computed: {
    ...mapState('app', [
      'userData'
    ])
  },
  watch: {
    '$route': {
      deep: true,
      handler: function (val) {
        this.activeTab = val.name
      }
    }
  },
  methods: {
    pushToRoute () {
      this.$router.push({ name: this.activeTab })
    }
  }
}
</script>
