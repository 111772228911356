<template>
  <div class="tile-constructor">
    <div class="tile-constructor__form-fields">
      <FormField
        label="Base path for detail pages"
        class="tile-constructor__field aside-select"
        required
        :messages="baseRouteValidationErrors">
        <v-text-field
          v-model.trim="baseRoute"
        />
      </FormField>

      <SingleItemLayoutConstructor
        v-model="singleItemOptions.layout"
      />

      <div
        v-for="field in singleItemFields"
        :key="field.name"
        class="tile-constructor__field aside-select"
        >
        <h6 class="aside-field-label">{{ field.label }}</h6>
        <v-select
          :value="singleItemOptions[field.name][field.multiple ? 'ids' : 'id']"
          :items="cols"
          item-text="label"
          item-value="id"
          attach
          placeholder="Choose a column"
          menu-props="offsetY"
          :clearable="field.clearable"
          :chips="field.multiple"
          :deletable-chips="field.multiple"
          :multiple="field.multiple"
          append-icon="keyboard_arrow_down"
          @input="val => updateTileOptionValue(val, field.name, field.multiple)"
          >
          <template slot="selection" slot-scope="data">
            <DraggableChip
              v-if="field.multiple"
              :id="data.index"
              :value="singleItemOptions[field.name][field.multiple ? 'ids' : 'id']"
              @input="val => updateTileOptionValue(val, field.name, field.multiple)"
            >
              {{ data.item.label }}
            </DraggableChip>
            <div v-else>{{ data.item.label }}</div>
          </template>
          <div slot="prepend-item" class="list-title"> {{ field.dropdownLabel }}</div>
        </v-select>
        <i class="aside-select__field-icon icon-arrow-down"></i>
        <p
          v-if="!!v[field.name] && !v[field.name].id.required"
          class="form-field__error"
        >
          {{ $t('global.fieldIsRequired') }}
        </p>
        <template field.hint>
          <div class="tile-constructor__hint" v-html="field.hint"></div>
        </template>
        <div v-if="field.name === 'image' && singleItemOptions.image.keepAspectRatio !== undefined" class="content-form__switch-field" style="margin-top: 20px;">
          <div class="content-form__switcher-wrap">
            <div class="content-form__switcher-label">Keep image aspect ratio</div>
              <label
                :class="{ 'selected': singleItemOptions.image.keepAspectRatio }"
                class="switcher"
              >
                <input v-model="singleItemOptions.image.keepAspectRatio" class="switcher__input" type="checkbox">
                <span class="switcher__indicator"></span>
              </label>
          </div>
        </div>
      </div>

      <CtaButtonConstructor
        v-model="singleItemOptions.cta"
        class="tile-constructor__field aside-select"
        widgetLabel="Primary button settings"
        :cols="cols"
        :msg="msg"
        :onDetailsPage="true"
        @change-tab="$emit('change-tab', 'checkout-options')"
      />
      <CtaButtonConstructor
        v-model="singleItemOptions.cta_second"
        class="tile-constructor__field aside-select"
        widgetLabel="Secondary button settings"
        :cols="cols"
        :msg="msg"
        :onDetailsPage="true"
        @change-tab="$emit('change-tab', 'checkout-options')"
      />
      <MapViewConstructor
        v-model="singleItemOptions.mapView"
        :cols="cols"
        :singleItemPage="true"
        :integrationsData="integrationsData"
      />
      <div class="content-form__switch-field">
        <div class="content-form__switcher-wrap">
          <div class="content-form__switcher-label">Social Share buttons</div>
            <label
              :class="{ 'selected': singleItemOptions.socialShareButtons.enabled }"
              class="switcher"
            >
              <input v-model="singleItemOptions.socialShareButtons.enabled" class="switcher__input" type="checkbox">
              <span class="switcher__indicator"></span>
            </label>
        </div>
      </div>
      <div
        v-if="singleItemOptions.socialShareButtons.enabled"
        class="tile-constructor__field content-form__switch-hidden-field aside-select paddingless"
      >
        <div class="content-form__highlighted">
        <FormField
          label="Social share buttons style"
          class="side-form__item aside-input">
            <v-select
              :value="singleItemOptions.socialShareButtons.style"
              :items="socialShareButtonsStyleOptions"
              attach
              item-text="label"
              item-value="value"
              placeholder="Choose a share button style"
              menu-props="offsetY"
              append-icon="keyboard_arrow_down"
              @input="val => singleItemOptions.socialShareButtons.style = val"
            >
            </v-select>
        </FormField>
        <FormField
          v-if="singleItemOptions.socialShareButtons.style === 'popup'"
          label="Popup title text"
          class="side-form__item aside-input">
          <v-text-field
            v-model="singleItemOptions.socialShareButtons.popupTitleText"
            type="text"
          >
          </v-text-field>
        </FormField>
        <FormField
          v-if="singleItemOptions.socialShareButtons.enabled"
          label="Social share buttons"
          class="side-form__item aside-input">
            <v-select
              :value="singleItemOptions.socialShareButtons.buttons"
              :items="socialShareButtons"
              item-text="label"
              item-value="value"
              chips
              attach
              multiple
              deletable-chips
              append-icon="keyboard_arrow_down"
              @input="updateSocialShareButtons"
            >
              <template slot="selection" slot-scope="data">
                <DraggableChip
                  :id="data.index"
                  v-model="singleItemOptions.socialShareButtons.buttons"
                >
                  {{ data.item.label }}
                </DraggableChip>
              </template>
            </v-select>
        </FormField>
        </div>
      </div>
      <RelatedItemsConstructor
        v-model="singleItemOptions.relatedItems"
        :cols="cols"
      />
    </div>
  </div>
</template>
<script>
import FormField from '@/components/form-field/'
import CtaButtonConstructor from '@/components/cta-button-constructor'
import MapViewConstructor from '@/components/map-view-constructor/'
import SingleItemLayoutConstructor from '@/components/single-item-layout-constructor/'
import { socialShareButtons, socialShareButtonsStyleOptions } from '@/modules/utils/socialShareBtnsOptions.js'
import DraggableChip from '@/components/draggable-chip/DraggableChip.vue'
import RelatedItemsConstructor from '@/components/related-items-constructor'

export default {
  name: 'SingleItemConstructor',
  components: {
    RelatedItemsConstructor,
    DraggableChip,
    CtaButtonConstructor,
    FormField,
    MapViewConstructor,
    SingleItemLayoutConstructor
  },
  props: {
    cols: {
      type: Array,
      required: true
    },
    value: {
      type: Object,
      required: true
    },
    v: {
      type: Object
    },
    msg: {
      type: Object,
      required: true
    },
    integrationsData: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      singleItemOptions: {},
      singleItemFields: [
        {
          name: 'url',
          label: 'Route URL (must be unique)',
          dropdownLabel: 'Select sheet column',
          multiple: false,
          clearable: true
        },
        {
          name: 'title',
          label: 'Title',
          dropdownLabel: 'Select sheet column',
          multiple: false,
          clearable: true
        },
        {
          name: 'subtitle',
          label: 'Subtitle',
          dropdownLabel: 'Select sheet column',
          multiple: false,
          clearable: true
        },
        {
          name: 'price',
          label: 'Price',
          dropdownLabel: 'Select sheet column',
          multiple: false,
          clearable: true
        },
        {
          name: 'oldPrice',
          label: 'Old price',
          dropdownLabel: 'Select sheet column',
          multiple: false,
          clearable: true
        },
        {
          name: 'content',
          label: 'Text',
          dropdownLabel: 'Select sheet column',
          multiple: false,
          clearable: true
        },
        {
          name: 'image',
          label: 'Image',
          dropdownLabel: 'Select sheet column',
          multiple: true,
          clearable: false,
          hint: `<a target="_blank" href="https://help.spreadsimple.com/en/article/adding-images-1k2y9tj/">Help: Adding images</a>`
        },
        {
          name: 'specs',
          label: 'Specifications',
          dropdownLabel: 'Select sheet column',
          multiple: true,
          clearable: false
        },
        {
          name: 'badges',
          label: 'Badges',
          dropdownLabel: 'Select sheet column',
          multiple: true,
          clearable: false,
          image: '/img/field-icons/specs.svg'
        }
      ],
      socialShareButtons,
      socialShareButtonsStyleOptions
    }
  },
  computed: {
    baseRoute: {
      get () {
        return this.singleItemOptions.baseRoute
      },
      set (val) {
        this.singleItemOptions.baseRoute = val.toLowerCase()
      }
    },
    baseRouteValidationErrors () {
      const errors = []
      if (this.v.baseRoute) {
        if (!this.v.baseRoute.required) errors.push('This field is required')
        if (!this.v.baseRoute.checkSlugFor404) errors.push('404 is forbidden route for details page')
        if (!this.v.baseRoute.maxLength) errors.push('Maximum length should be less then 50 characters')
        if (!this.v.baseRoute.routeSlugRegexp) errors.push('Value must contain only letters, numbers, "_" and "-"')
      }
      return errors
    }
  },
  watch: {
    singleItemOptions: {
      deep: true,
      handler: function (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    updateTileOptionValue (val, name, multiple) {
      const value = (val === undefined) ? null : val
      this.singleItemOptions[name][multiple ? 'ids' : 'id'] = value
    },
    mapStatusToBadges () {
      if (!this.singleItemOptions.status.id) return
      this.singleItemOptions.badges.ids.push(this.singleItemOptions.status.id)
      this.singleItemOptions.status.id = null
    },
    updateSocialShareButtons (value) {
      this.singleItemOptions.socialShareButtons.buttons = value
    }
  },
  created () {
    let obj = JSON.parse(JSON.stringify(this.value))
    // 'mapView', 'socialShareButtons', 'layout' do not require adding Id
    const fieldsWithoutId = ['mapView', 'socialShareButtons', 'layout']
    Object.keys(obj).forEach(key => {
      if (fieldsWithoutId.includes(key)) return
      if (typeof obj[key] === 'object' && !obj[key].ids && obj[key].id === undefined) {
        obj[key].id = ''
      }
    })
    this.singleItemOptions = obj
    this.mapStatusToBadges()
  }
}
</script>
