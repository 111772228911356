<template>
  <Modal
    :value="opened"
    :modalName="unsplashEnabled ? 'Search Unsplash for images' : 'Upload image'"
    @input="$emit('on-close')"
    >
    <div v-if="unsplashEnabled" class="unsplash-modal__body">
      <form @submit.prevent="getUnsplashImages">
        <div class="unsplash-modal__search">
          <input v-model="searchKey" type="text" placeholder="Search images by topics or colors">
          <button
            type="submit"
            class="unspalsh-modal__button"
            :class="{'loading': $async.unsplashSearch.$pending}"
            >Search</button>
        </div>
        <ul
          class="unsplash-chips"
          :class="{'disabled': $async.unsplashSearch.$pending}"
          >
          <li
          v-for="(item, index) in unsplashTips"
          :key="index"
          @click="searchByTip(item)"
          >
            {{item}}
          </li>
        </ul>
      </form>
      <div v-if="paginatedData" class="unsplash-modal__results">
        <div
          v-for="(image, index) in paginatedData.data"
          :key="index"
          class="unsplash-modal__result-item"
          >
            <div
              class="unsplash-modal__result-item-inner"
              :style="{backgroundImage: `url('${image.urls.thumb}')`}"
              @click="updateValue(image)"
            >
            </div>
            <a :href="`${image.user.links.html}?${unsplashReferralLink}`" target="_blank">
              {{image.user.name}}
            </a>
        </div>
        <Paginator
          v-if="unsplashResults.length > itemsPerPage"
          :data="paginatedData"
          @change-page="val => paginator(unsplashResults, val, itemsPerPage)"
        />
        <div
          v-if="unsplashResults.length"
          class="unsplash__copyright"
        >
          Photos from <a :href="`https://unsplash.com/?${unsplashReferralLink}`" target="_blank">Unsplash</a>
        </div>
      </div>

    </div>
    <div class="unsplash-modal__download-section">
      <div v-if="unsplashEnabled" class="unsplash-modal__download-section-title">
        Or upload file from your computer
      </div>
      <form
        ref="uploadForm"
        enctype="multipart/form-data"
        @change.prevent="uploadFile">
        <label>
          <input ref="image" style="display: none;" type="file">
          <div class="uploader__btn">
            <transition name="fade" mode="out-in">
              <div v-if="!$async.uploadFiles.$pending" key="btn">
                Upload
                <i class="uploader__btn-icon icon-upload2"></i>
              </div>
              <div v-else key="btn2">
                Loading
              </div>
            </transition>
          </div>
        </label>
      </form>
    </div>
  </Modal>

</template>
<script>
import Modal from '@/components/modal'
import Paginator from '@/components/paginator'
import api from '@/services/api/'
export default {
  name: 'UploadImageModal',
  components: {
    Modal,
    Paginator
  },
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    unsplashEnabled: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      searchKey: '',
      unsplashResults: [],
      unsplashTips: [
        'colorful',
        'nature',
        'work',
        'business',
        'office',
        'architecture',
        'fashion',
        'travel',
        'people',
        'art',
        'store'
      ],
      paginatedData: {},
      currentPage: 1,
      itemsPerPage: 9,
      unsplashReferralLink: 'utm_source=SpreadSimple&utm_medium=referral'
    }
  },
  methods: {
    updateValue (val) {
      this.$emit('input', val.urls.regular)
      this.$emit('on-close')
      api.unsplashDownload(val.id)
    },
    uploadFile () {
      let data = new FormData()
      data.append('image', this.$refs.image.files[0])
      this.$async.uploadFiles.$perform(data)
        .then(response => {
          this.$emit('input', response.imageUri)
          this.$refs.uploadForm.reset()
          this.$emit('on-close')
        })
        .catch((error) => {
          console.log(error)
          this.$refs.uploadForm.reset()
        })
    },
    searchByTip (key) {
      this.searchKey = key
      this.getUnsplashImages()
    },
    getUnsplashImages () {
      const key = this.searchKey
      this.$async.unsplashSearch.$perform(`page=1&per_page=30&query=${key}`)
        .then(response => {
          this.unsplashResults = response.results
          this.paginator(this.unsplashResults, this.currentPage, this.itemsPerPage)
        })
    },
    paginator (items, page, perPage) {
      let offset = (page - 1) * perPage
      let paginatedItems = items.slice(offset).slice(0, perPage)
      let totalPages = Math.ceil(items.length / perPage)
      this.paginatedData = {
        page,
        perPage,
        totalPages,
        prevPage: page - 1 ? page - 1 : null,
        nextPage: (totalPages > page) ? page + 1 : null,
        data: paginatedItems
      }
      return this.paginatedData
    }
  },
  asyncOperations: {
    uploadFiles (data) {
      return api.uploadFiles(data)
    },
    unsplashSearch (query) {
      return api.unsplashSearch(query)
    }
  }
}
</script>
