<template>
  <div class="side-form">
    <div v-if="order.length" class="side-form__item side-form__draggable-wrap" >
      <draggable
        :value="order"
        tag="div"
        handle=".draggable__item-trigger"
        class="draggable"
        @input="val => onDrag(val)"
      >
        <div
          v-for="item in order"
          :key="item.name"
          class="draggable__item"
          >
          <div class="draggable__item-trigger icon-draggable"></div>
          <div class="draggable__item-title-wrap cursor-default">
            <img class="draggable__item-title-img" :src="getPaymentLogo(item.name)" :alt="item.name">
            <span class="draggable__item-title">
              {{ getPaymentTitle(item.name) }}
              {{item.default ? ' (default)' : ''}}
            </span>
          </div>
          <div class="draggable__item-controls">
            <el-dropdown
              trigger="click"
              class="draggable__item-control"
            >
              <i class="icon-gear"></i>
              <el-dropdown-menu slot="dropdown" class="draggable__dropdown-menu">
                <el-dropdown-item v-if="item.default"  @click.native="deselectAsDefault(item.name)">Deselect as default</el-dropdown-item>
                <el-dropdown-item v-else  @click.native="selectAsDefault(item.name)">Select as default</el-dropdown-item>
                <el-dropdown-item  @click.native="disablePayment(item.name)">Disable</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </draggable>
    </div>
    <div class="side-form__btns">
      <button type="button" class="btn btn--transparent" @click="openPaymentsModal">+ Enable new add-on</button>
    </div>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
export default {
  name: 'PaymentsBuilder',
  components: {
    draggable
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    integrations: {
      type: Array,
      required: true
    },
    integrationsData: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      order: []
    }
  },
  computed: {
    paymentMethods () {
      const paymentMethods = this.integrations.filter(i => i.categories.includes('Payment Gateway'))
      const enabledPaymentMethods = paymentMethods.filter(item => item.formFields.length && item.formFields.find(f => f.name === 'enabled').value)
      return enabledPaymentMethods
    }
  },
  watch: {
    paymentMethods: function (val) {
      if (val.length > this.order.length) {
        val.forEach(method => {
          if (!this.order.map(i => i.name).includes(method.name)) {
            this.order.push({
              name: method.name,
              default: false
            })
          }
        })
        this.$emit('input', this.order)
      }
      if (val.length < this.order.length) {
        const existingNames = val.map(i => i.name)
        this.order = this.order.reduce((arr, item, index) => {
          if (!existingNames.includes(item.name)) {
            arr.splice(index, 1)
          }
          return arr
        }, this.order)
      }
      if (val.length === 1) {
        this.order[0].default = true
      }
    }
  },
  methods: {
    selectAsDefault (name) {
      this.order.map(item => {
        if (item.name !== name) {
          item.default = false
        } else {
          item.default = true
        }
      })
      this.$emit('input', this.order)
    },
    deselectAsDefault (name) {
      this.order.find(i => i.name === name).default = false
      this.$emit('input', this.order)
    },
    disablePayment (name) {
      const integrationsData = this.integrationsData.slice()
      integrationsData.find(i => i.name === name).data.find(d => d.name === 'enabled').value = false
      this.$emit('disable-payment-addon', integrationsData)
    },
    onDrag (val) {
      this.order = val
      this.$emit('input', val)
    },
    openPaymentsModal () {
      this.$emit('open-payment-addons')
    },
    getPaymentLogo (name) {
      return this.paymentMethods.find(item => item.name === name).logoUrl
    },
    getPaymentTitle (name) {
      const currentItem = this.paymentMethods.find(item => item.name === name)
      const customTitle = currentItem.formFields.find(ff => ff.name === 'customTitle').value
      return (customTitle && customTitle.length) ? customTitle : currentItem.title
    }
  },
  created () {
    if (!this.value || !this.value.length || this.value.length !== this.paymentMethods.length) {
      this.order = this.paymentMethods.map(item => {
        return {
          name: item.name,
          default: false
        }
      })
      this.$emit('input', this.order)
    } else {
      this.order = this.value
    }
  }
}
</script>
