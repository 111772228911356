<template>
  <div class="profile-tab__container">
    <h4 class="profile-tab__title">Affiliate program</h4>
    <br>
    <p>Get 30% from each referral payment and up to 12 recurring payments by sharing your referral link</p>
    <p><a style="display: inline-block;" class="profile-tab__button" href="https://affiliate.spreadsimple.com/" target="_blank">Become a partner</a></p>
  </div>
</template>
<script>
export default {
  name: 'AffiliateSettings'
}
</script>
