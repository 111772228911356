<template>
  <div>
    <UpgradePlanButton
        class="pro-option__wrapper"
        :class="{'is-active': !featureIsAvailable}"
        :runAction="!featureIsAvailable"
      >
      <div class="pro-option" :class="{'is-not-pro': !featureIsAvailable}">
        <slot/>
      </div>
    </UpgradePlanButton>
  </div>
</template>
<script>
import UpgradePlanButton from '@/components/pro-option-wrapper/UpgradePlanButton.vue'
import { allowFeature } from '@/modules/features-checker'
import { mapState } from 'vuex'
export default {
  name: 'ProOptionWrapper',
  components: {
    UpgradePlanButton
  },
  props: {
    featureName: {
      type: String
    },
    allowed: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('app', [
      'userData',
      'currentSpreadViewData'
    ]),
    featureIsAvailable () { // PRICING_UPDATE
      if (this.allowed) return this.allowed
      if (!this.featureName) return this.allowed
      return allowFeature(this.featureName, {
        user: this.userData,
        website: this.currentSpreadViewData
      })
    }
  }
}
</script>
