<template>

  <div class="content-form__highlighted">
    <div class="sorting-constructor__item">
      <FormField label="Select the user groups column to grant access to the items" class="side-form__item aside-input">
        <v-select
          :value="privateRow.id"
          :items="cols"
          :disabled="!accessRulesEnabled"
          item-text="label"
          item-value="id"
          attach
          :placeholder="!accessRulesEnabled ? 'Create or enable access rules' : 'Choose a column'"
          menu-props="offsetY"
          clearable
          chips
          append-icon="keyboard_arrow_down"
          @change="setPrivateRow"
        >
        <div slot="prepend-item" class="list-title"> {{ '' }}</div>
        </v-select>
      </FormField>
      <a class="sorting-constructor__link" target="_blank" href="https://help.spreadsimple.com/en/article/how-to-set-up-website-access-rules-1vre7ri/">Help: How to set up website access rules?</a>
    </div>
  </div>

</template>
<script>
import FormField from '@/components/form-field'
import { mapState } from 'vuex'
export default {
  name: 'PrivateRowConstructor',
  components: {
    FormField
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    cols: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      privateRow: {
        enabled: false,
        id: null
      }
    }
  },
  computed: {
    accessRulesEnabled () {
      return this.websiteAccess && this.websiteAccess.enabled
    },
    ...mapState('app', [
      'websiteAccess'
    ])
  },
  watch: {
    'websiteAccess.created' (val) {
      if (!val) {
        this.setPrivateRow(null)
      }
    }
  },
  methods: {
    setPrivateRow (val) {
      this.privateRow = val ? { enabled: true, id: val } : { enabled: false, id: null }
      this.$emit('input', this.privateRow)
    }
  },
  created () {
    this.privateRow = JSON.parse(JSON.stringify(this.value))
  }
}
</script>
