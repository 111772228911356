import { render, staticRenderFns } from "./OptionItemBuilder.vue?vue&type=template&id=47224b06&"
import script from "./OptionItemBuilder.vue?vue&type=script&lang=js&"
export * from "./OptionItemBuilder.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/devstark/spreadsimple/spreadsimple-app/main-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('47224b06', component.options)
    } else {
      api.reload('47224b06', component.options)
    }
    module.hot.accept("./OptionItemBuilder.vue?vue&type=template&id=47224b06&", function () {
      api.rerender('47224b06', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/form-builder/OptionItemBuilder.vue"
export default component.exports