<template>
<ProOptionWrapper :featureName="'itemVariations'">
  <div class="content-form__switch-field">
    <div class="content-form__switcher-wrap">
      <div class="content-form__switcher-label">Enable item variations</div>
      <label
        :class="{ 'selected': variantsState.enabled }"
        class="switcher"
      >
        <input
          v-model="variantsState.enabled"
          class="switcher__input"
          type="checkbox"
          value="tiles"
          @change="$trackFeature('content', 'variants', variantsState.enabled)"
        >
        <span class="switcher__indicator"></span>
      </label>
    </div>
    <div v-if="variantsState.enabled" class="content-form__switch-hidden-field aside-select paddingless">
      <div class="sorting-constructor__highlighted">
        <div class="sorting-constructor__item">
          <div class="sorting-constructor__select aside-select paddingless">
            <h6 class="sorting-constructor__item-label">Group by column</h6>
            <v-select
              v-model="variantsState.variantsGroupBy.id"
              attach
              clearable
              menu-props="offsetY"
              :items="mappedCols"
              item-text="label"
              item-value="value"
              append-icon="keyboard_arrow_down"
            >
            </v-select>
            <p
              v-if="variantsGroupByValidationError"
              class="sorting-constructor__select-error"
            >
            {{ $t('global.fieldIsRequired') }}
          </p>
          <a class="sorting-constructor__link" target="_blank" href="https://help.spreadsimple.com/en/article/item-variations-sylxt5/">Help: How to use variations?</a>
          </div>
        </div>
        <div v-if="variantsState.variantsGroupBy.id">

          <div class="sorting-constructor__item">
            <div class="sorting-constructor__select aside-select">
              <h6 class="sorting-constructor__item-label">Variations options</h6>
              <v-select
                v-model="variantsState.variantsOptions.ids"
                :items="mappedCols"
                item-text="label"
                item-value="value"
                attach
                menu-props="offsetY"
                chips
                deletable-chips
                multiple
                append-icon="keyboard_arrow_down"
              >
              </v-select>
            </div>
          </div>

          <div v-if="variantsState.variantsOptions.ids.length" class="content-form__switch-field">
            <div class="content-form__switcher-wrap">
              <div class="content-form__switcher-label">Group cards in list</div>
              <label
                :class="{ 'selected': variantsState.variantsGroupBy.groupCards }"
                class="switcher"
              >
                <input
                  v-model="variantsState.variantsGroupBy.groupCards"
                  class="switcher__input"
                  type="checkbox"
                  @change="$trackFeature('content', 'variantsGroupCards', variantsState.variantsGroupBy.groupCards)"
                >
                <span class="switcher__indicator"></span>
              </label>
            </div>
          </div>

          <div v-if="false" class="content-form__switch-field">
          <!-- <div v-if="variantsState.variantsOptions.ids.length" class="content-form__switch-field"> -->
            <div class="content-form__switcher-wrap">
              <div class="content-form__switcher-label">Show variants options in cards</div>
              <label
                :class="{ 'selected': variantsState.variantsOptions.showVariantsOptionsInCards }"
                class="switcher"
              >
                <input
                  v-model="variantsState.variantsOptions.showVariantsOptionsInCards"
                  class="switcher__input"
                  type="checkbox"
                >
                <span class="switcher__indicator"></span>
              </label>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</ProOptionWrapper>
</template>
<script>
import ProOptionWrapper from '@/components/pro-option-wrapper'
export default {
  name: 'VariantsConstructor',
  components: {
    ProOptionWrapper
  },
  props: {
    cols: {
      type: Array,
      required: true
    },
    value: {
      type: Object,
      required: true
    },
    v: {
      type: Object
    },
    variantsGroupByValidationError: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      variantsState: {}
    }
  },
  computed: {
    mappedCols () {
      return this.cols.map((col) => {
        return { value: col.id, label: col.label }
      })
    }
  },
  watch: {
    variantsState: {
      deep: true,
      handler: function (val) {
        if (val.variantsOptions.ids.length === 0) {
          val.variantsGroupBy.groupCards = false
        }
        if (val.variantsGroupBy.id === undefined) {
          val.variantsGroupBy.id = null
        }
        this.$emit('input', val)
      }
    }
  },
  created () {
    this.variantsState = JSON.parse(JSON.stringify(this.value))
  }
}
</script>
