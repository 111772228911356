var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "spread-form",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submitForm.apply(null, arguments)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "spread-form__form-elements" },
        [
          [
            _vm.forAddViewPage
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.$v.form.url.$model,
                      expression: "$v.form.url.$model",
                      modifiers: { trim: true }
                    }
                  ],
                  staticClass: "spread-form__input",
                  attrs: {
                    placeholder: "Paste Google Sheet link here",
                    type: "text"
                  },
                  domProps: { value: _vm.$v.form.url.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.form.url,
                        "$model",
                        $event.target.value.trim()
                      )
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                })
              : _c("v-text-field", {
                  staticClass: "spread-form__sidebar-input",
                  attrs: { label: "Linked spread sheet" },
                  model: {
                    value: _vm.$v.form.url.$model,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.$v.form.url,
                        "$model",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "$v.form.url.$model"
                  }
                })
          ],
          [
            _vm.forAddViewPage
              ? _c(
                  "button",
                  {
                    staticClass: "spread-form__add-button",
                    class: { loading: _vm.isLoading },
                    attrs: {
                      disabled:
                        _vm.$v.form.url.$dirty && _vm.$v.form.url.$invalid,
                      type: "submit"
                    }
                  },
                  [
                    _vm.isLoading
                      ? _c("div", { staticClass: "icon-loader" })
                      : _c("span", [_vm._v(_vm._s(_vm.$t("global.continue")))])
                  ]
                )
              : _c(
                  "button",
                  {
                    staticClass: "spread-form__sidebar-add-btn",
                    class: { loading: _vm.isLoading },
                    attrs: { disabled: _vm.isLoading, type: "submit" }
                  },
                  [
                    _vm.isLoading
                      ? _c("div", { staticClass: "icon-loader" })
                      : _c("span", [_vm._v("Submit")])
                  ]
                )
          ]
        ],
        2
      ),
      _c("div", { staticClass: "spread-form__messages-wrapp" }, [
        _vm.$v.form.url.$dirty && !_vm.$v.form.url.required
          ? _c("span", { staticClass: "spread-form__message error" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("pageAddView.pastLinkErrorNotCorrectUrl")) +
                  "\n    "
              )
            ])
          : _vm._e(),
        !_vm.$v.form.url.url
          ? _c("span", { staticClass: "spread-form__message error" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("pageAddView.pastLinkErrorNotCorrectUrl")) +
                  "\n    "
              )
            ])
          : _vm._e(),
        _vm.$v.form.url.required &&
        _vm.$v.form.url.url &&
        !_vm.$v.form.url.isSpreadsheetUrl
          ? _c("span", { staticClass: "spread-form__message error" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("pageAddView.pastLinkErrorNotGoogleUrl")) +
                  "\n    "
              )
            ])
          : _vm._e(),
        _vm.avaliabilityError !== ""
          ? _c("span", { staticClass: "spread-form__message error" }, [
              _vm._v("\n      " + _vm._s(_vm.avaliabilityError) + "\n    ")
            ])
          : _vm._e()
      ]),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }