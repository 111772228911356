<template>
  <div class="code-textarea">
    <textarea ref="textarea"></textarea>
  </div>
</template>

<script>
import { EditorView, basicSetup } from 'codemirror'
import { xmlLanguage } from '@codemirror/lang-xml'
import { cssLanguage } from '@codemirror/lang-css'

export default {
  name: 'CodeTextArea',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      text: '',
      editor: null
    }
  },
  watch: {
    value (val, oldVal) {
      if (this.editor) {
        this.editor.doc = val
      }
    }
  },
  mounted () {
    const area = this.$refs.textarea
    area.style.display = 'none'
    this.editor = new EditorView({
      doc: this.value,
      extensions: [
        basicSetup,
        cssLanguage,
        xmlLanguage,
        EditorView.updateListener.of(update => {
          if (update.docChanged) {
            const val = update.state.doc.toString()
            this.$emit('input', val)
          }
        })
      ]
    })
    area.insertAdjacentElement('afterend', this.editor.dom)
  }
}
</script>
