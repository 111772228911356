<template>
  <div class="plan-cards">
    <div class="plan-cards__type-switcher">
      <label
        v-for="(tab, i) in tabs"
        :key="i"
        class="plan-cards__type-switcher-item"
        :class="{'is-active': activeTab === tab.value}"
      >
        <input
          v-model="activeTab"
          type="radio"
          name="activeTab"
          :value="tab.value"
        >
        {{tab.name}}
      </label>
    </div>

    <div class="plan-cards__payment-switcher">
      <label
        v-for="(period, i) in paymentPeriods"
        :key="i"
        class="plan-cards__payment-switcher-item"
        :class="{'is-active': activePaymentPeriod === period.value}"
        >
        <input
          v-model="activePaymentPeriod"
          type="radio"
          name="activePaymentPeriod"
          :value="period.value"
        >
        <span class="plan-cards__payment-switcher-item-indicator"></span>
        <span class="plan-cards__payment-switcher-item-title">{{ period.name }}</span>
        <span v-if="period.dicsount" class="plan-cards__payment-switcher-item-label"> –{{ period.dicsount }}% off </span>
      </label>
    </div>

    <transition name="plan-cards-items" mode="out-in">
      <div
        v-if="activeTab === 'one'"
        key="1"
        class="plan-cards__items"
      >
        <div
          v-for="(plan, i) in oneWebsitePlans[activePaymentPeriod]"
          :key="i"
          class="plan-card"
          :class="{'plan-card--highlighted': plan.highlighted}"
        >

          <div class="plan-card__meta">
            <h2 class="plan-card__title">{{ plan.title }}</h2>
            <p v-if="plan.subtitle" class="plan-card__subtitle">{{ plan.subtitle }}</p>
            <p class="plan-card__price-wrap">
              <sup class="plan-card__price-currency">$</sup>
              <span class="plan-card__price" v-html="splitPrice(plan.price.month)"></span>
              <sup class="plan-card__price-period">/mo</sup>
            </p>
            <button
              :disabled="checkActiveDealCard(plan)"
              class="plan-card__cta-btn btn"
              :class="{'btn--transparent': !plan.highlighted}"
              @click="onPlanCardClick(plan)"
            >
              {{checkActiveDealCard(plan) ? 'Using now' : defaultButtonText}}
            </button>
          </div>
          <div class="plan-card__legend">
            <div
              v-for="(feature, i) in filterByPlan(plan.type)"
              :key="i"
              class="plan-card__legend-item"
            >
              <img
                class="plan-card__legend-item-icon"
                src="/img/list-icon.svg"
                alt="check icon"
              >
              <span class="plan-card__legend-item-title">{{ feature.name }}{{feature.isBeta ? ' (Beta)':''}}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        key="2"
        class="plan-cards__items"
      >
        <div
          v-for="(plan, i) in bulkWebsitePlans[activePaymentPeriod]"
          :key="i"
          class="plan-card"
          :class="{'plan-card--highlighted': plan.highlighted}"
        >
          <div class="plan-card__meta">
            <h2 class="plan-card__title">{{ plan.title }}</h2>
            <p v-if="plan.subtitle" class="plan-card__subtitle">{{ plan.subtitle }}</p>
            <p class="plan-card__price-wrap">
              <sup class="plan-card__price-currency">$</sup>
              <span class="plan-card__price" v-html="splitPrice(plan.price.month)"></span>
              <sup class="plan-card__price-period">/mo</sup>
            </p>
            <button
              :disabled="checkActiveDealCard(plan)"
              class="plan-card__cta-btn btn"
              :class="{'btn--transparent': !plan.highlighted}"
              @click="onPlanCardClick(plan)"
            >
              {{checkActiveDealCard(plan) ? 'Using now' : defaultButtonText}}
            </button>
          </div>
          <div class="plan-card__legend">
            <div
              v-for="(feature, i) in bulkFilterByPlan(plan.type)"
              :key="i"
              class="plan-card__legend-item"
            >
              <span v-if="feature.name === 'Number of websites' && plan.type === 'bulkSmall'" class="plan-card__legend-item-value">x3</span>
              <span v-else-if="feature.name === 'Number of websites' && plan.type === 'bulkLarge'" class="plan-card__legend-item-value">x5</span>
              <span v-else-if="feature.name === 'Number of websites' && plan.type === 'bulkMega'" class="plan-card__legend-item-value">x10</span>
              <img
                v-else
                class="plan-card__legend-item-icon"
                src="/img/list-icon.svg"
                alt="check icon"
              >
              <span class="plan-card__legend-item-title">{{ feature.name }}{{feature.comingSoon ? ' (Coming soon)':''}}</span>
            </div>
          </div>
        </div>
      </div>
    </transition>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import planManager from '@devstark/spreadsimple-plan-manager'
const dealsConfig = planManager.deals
export default {
  name: 'PlanCards',
  props: {
    switchPlanAction: {
      type: Boolean,
      default: false
    },
    currentDeal: {
      type: Object
    }
  },
  data () {
    return {
      isSandbox: process.env.VUE_APP_PADDLE_ENV === 'sandbox',
      activeTab: 'one', // bulk
      tabs: [
        { name: 'One website', value: 'one' },
        { name: 'Bulk offer', value: 'bulk' }
      ],
      activePaymentPeriod: 'year',
      paymentPeriods: [
        { name: 'Pay monthly', value: 'month' },
        { name: 'Pay annually', value: 'year', dicsount: 30 }
      ],
      dealAdditionalData: {
        // pro: {
        //   order: 4,
        //   title: 'Pro',
        //   highlighted: false,
        //   subtitle: 'THIS IS LEGACY PLAN FOR TESTING'
        // },
        connect: {
          order: 1,
          title: 'Connect',
          highlighted: false,
          subtitle: 'Harness user accounts to build advanced role-based web apps'
        },
        business: {
          order: 2,
          title: 'Business',
          highlighted: true,
          subtitle: 'Best for driving your sales'
        },
        starter: {
          order: 3,
          title: 'Starter',
          highlighted: false,
          subtitle: 'Good enougth to go live'
        },
        bulkSmall: {
          order: 3,
          title: 'Small',
          highlighted: false,
          subtitle: ''
        },
        bulkLarge: {
          order: 2,
          title: 'Large',
          highlighted: false,
          subtitle: ''
        },
        bulkMega: {
          order: 1,
          title: 'Mega',
          highlighted: true,
          subtitle: ''
        }
      },
      oneWebsiteFeatures: [
        { name: 'Custom domain', helper: '', plan: ['connect', 'business', 'starter'] },
        { name: 'Remove SpreadSimple branding', helper: '', plan: ['connect', 'business', 'starter'] },
        { name: 'SEO settings ', helper: '', plan: ['connect', 'business', 'starter'] },
        { name: 'Accept payments', helper: '', plan: ['connect', 'business'] },
        { name: '30+ extensions and add-ons ', helper: '', plan: ['connect', 'business'] },
        { name: 'Customer accounts', helper: '', plan: ['connect'], isBeta: true },
        { name: 'User groups and permissions', helper: '', plan: ['connect'], isBeta: true }
      ],
      bulkWebsiteFeatures: [
        { name: 'Number of websites', helper: '', plan: ['bulkMega', 'bulkLarge', 'bulkSmall'] },
        { name: 'All SpreadSimple features', helper: '', plan: ['bulkMega', 'bulkLarge', 'bulkSmall'] },
        { name: 'Shared access for 10 editors', helper: '', plan: ['bulkMega', 'bulkLarge', 'bulkSmall'] },
        { name: 'Uptime monitoring & notifications', helper: '', plan: ['bulkMega', 'bulkLarge'], comingSoon: true }
        // { name: 'Premium priority support', helper: '', plan: ['bulkMega'] }
      ]
    }
  },
  computed: {
    ...mapState('app', [
      'userData'
    ]),
    paddleEnv () {
      return process.env.VUE_APP_PADDLE_ENV === 'sandbox' ? 'staging' : 'production'
    },
    oneWebsitePlans () {
      return {
        'month': dealsConfig.filter(d => {
          return (d.period === 'month' &&
            d.status === 'active' &&
            ['connect', 'business', 'starter'].includes(d.type))
          //  || (this.isSandbox && d.name === 'proMonth2')
        }).map(d => {
          return Object.assign(d, this.dealAdditionalData[d.type])
        }).sort((a, b) => { return a.order - b.order }),
        'year': dealsConfig.filter(d => {
          return (d.period === 'year' &&
            d.status === 'active' &&
            ['connect', 'business', 'starter'].includes(d.type))
          // || (this.isSandbox && d.name === 'proYear2')
        }).map(d => {
          return Object.assign(d, this.dealAdditionalData[d.type])
        }).sort((a, b) => { return a.order - b.order })
      }
    },
    bulkWebsitePlans () {
      return {
        'month': dealsConfig.filter(d => {
          return d.period === 'month' &&
            d.status === 'active' &&
            ['bulkSmall', 'bulkLarge', 'bulkMega'].includes(d.type)
        }).map(d => {
          return Object.assign(d, this.dealAdditionalData[d.type])
        }).sort((a, b) => { return a.order - b.order }),
        'year': dealsConfig.filter(d => {
          return d.period === 'year' &&
            d.status === 'active' &&
            ['bulkSmall', 'bulkLarge', 'bulkMega'].includes(d.type)
        }).map(d => {
          return Object.assign(d, this.dealAdditionalData[d.type])
        }).sort((a, b) => { return a.order - b.order })
      }
    },
    defaultButtonText () {
      return this.userData.dealOptions.hadPaddleTrial ? 'Subscribe' : 'Start your trial'
    }
  },
  methods: {
    splitPrice (price) {
      const splittedPrice = price.toFixed(2).toString().split('.')
      return `<span>${splittedPrice[0]}</span><sup>${splittedPrice[1] === '00' ? '' : splittedPrice[1]}</sup>`
    },
    filterByPlan (planName) {
      return this.oneWebsiteFeatures.filter((item) => {
        return item.plan.includes(planName)
      })
    },
    bulkFilterByPlan (planName) {
      return this.bulkWebsiteFeatures.filter((item) => {
        return item.plan.includes(planName)
      })
    },
    checkActiveDealCard (deal) {
      if (!this.currentDeal) return undefined
      return this.currentDeal.name === deal.name
    },
    onPlanCardClick (plan) {
      if (this.switchPlanAction) {
        this.$emit('switch-to', { id: plan.paddleId[this.paddleEnv], label: plan.label })
      } else {
        this.$emit('subscribe-to', { id: plan.paddleId[this.paddleEnv], type: plan.type, name: plan.name })
      }
    }
  },
  mounted () {
    if (this.$route.query.startTrialFromPricing === 'true') {
      this.activePaymentPeriod = this.$route.query.period
      this.activeTab = this.$route.query.tab
      this.$router.replace({ ...this.$route, query: {} })
    }
    if (this.currentDeal) {
      this.activePaymentPeriod = this.currentDeal.period
    }
  }
}
</script>
