<template>
  <div class="unsubscribe-modal">
    <div class="unsubscribe-modal__content-wrap">
      <p>{{ features.description }}</p>
      <ul>
        <li
          v-for="(feature, index) in features.list"
          :key="index"
        >{{ feature }}</li>
      </ul>
      <p
        v-if="hasDiscount"
      >{{ features.hasDiscount }}</p>
      <p
        v-else
      >{{ features.hasNotDiscount }}</p>
      <p
        v-if="!hasDiscount"
        v-html="features.offer(discountSize)"
      ></p>
    </div>
    <div class="unsubscribe-modal__btns">
      <UiBtn
        v-if="hasDiscount"
        full-width
        @click="$emit('close-modal')"
      >
        {{ features.hasDiscountKeepBtn }}
      </UiBtn>
      <UiBtn
        v-else
        full-width
        @click="$emit('take-discount')"
      >
        {{ features.hasNotDiscountTakeBtn }}
      </UiBtn>

      <UiBtn
        v-if="hasDiscount"
        type="secondary"
        full-width
        @click="$emit('unsubscribe')"
      >{{ features.hasDiscountUnsubscribeBtn }}</UiBtn>
      <UiBtn
        v-else
        type="secondary"
        full-width
        @click="$emit('unsubscribe')"
      >{{ features.hasNotDiscountUnsubscribeBtn }}</UiBtn>
    </div>
  </div>
</template>

<script>
import UiBtn from '@/components/ui-btn'
export default {
  name: 'DiscountOffer',
  components: {
    UiBtn
  },
  props: {
    features: {
      type: Object,
      default: () => {}
    },
    hasDiscount: {
      type: Boolean,
      default: false
    },
    discountSize: {
      type: Number,
      default: 20
    }
  }
}
</script>
