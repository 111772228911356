export default {
  published: true,
  pro: true,
  featureRestrictionsGroup: 'analyticsIntegrations',
  name: 'urlTags',
  title: 'URL Replacement Tags',
  logoUrl: '/img/integrations/url-tags.svg',
  // coverUrl: '/img/integrations/sample-cover.png',
  bgColor: '#3B7CA8',
  categories: ['Analytics'],
  comingSoon: false,
  builtIn: false,
  url: '',
  helpUrl: 'https://help.spreadsimple.com/en/article/setting-up-url-replacement-tags-18ll3hv/',
  description: `
  Pass custom values via <code>utm_</code> and <code>var_</code> URL params and store them in user's browser.

  Stored URL replacement tags can be useful for:
  - personalized website experience
  - tracking the visit sources
  - pass a pre-defined value as a URL parameter to use it as the additional checkout data

  <a href="https://help.spreadsimple.com/en/article/setting-up-url-replacement-tags-18ll3hv/" target="_blank">Learn more...</a>
  `,

  formFields: [
    {
      name: 'enabled',
      type: 'toggle',
      label: 'Enabled',
      default: false,
      validations: [
        'required'
      ],
      secret: false
    },

    {
      name: 'store',
      type: 'toggle',
      showIf: ['enabled'],
      label: 'Store utm_* and var_* params in browser',
      default: true,
      validations: [],
      secret: false
    },

    {
      name: 'storageType',
      type: 'select',
      showIf: ['enabled', 'store'],
      label: 'Storage type',
      default: 'session',
      options: [
        {
          label: 'Persistent',
          value: 'persistent'
        },
        {
          label: 'Session-only',
          value: 'session'
        }
      ],
      validations: [],
      secret: false
    },

    {
      name: 'clearFromUrl',
      type: 'toggle',
      showIf: ['enabled'],
      label: 'Remove utm_* and var_* params from URL',
      default: true,
      validations: [],
      secret: false
    },
    {
      name: 'debug',
      type: 'toggle',
      showIf: ['enabled'],
      label: 'Debug mode',
      default: false,
      validations: [],
      secret: false
    }
  ],

  actions: [
  ]
}
