<template>
  <div class="promo-code__wrapp">
    <div class="promo-code">
      <div class="promo-code__title">Apply Promo Code</div>
      <div class="promo-code__field">
        <div class="promo-code__input-wrapp">
          <input v-model="promoCode" type="text" class="promo-code__input">
        </div>
      </div>
      <button class="promo-code__button" :disabled="$async.applyPromoCode.$pending" @click.prevent="submitPromoCode">APPLY CODE</button>
      <button v-if="promoSuccess" class="promo-code__success" >Promo-code was successfully applied.</button>
      <button v-if="promoError" class="promo-code__error" >{{errorMessage}}</button>
    </div>
  </div>
</template>
<script>
import api from '@/services/api/'
import { mapActions } from 'vuex'
export default {
  name: 'PromoCodePage',
  metaInfo: {
    title: 'Promo'
  },
  data () {
    return {
      promoCode: '',
      promoSuccess: false,
      promoError: false,
      errorMessage: ''

    }
  },
  methods: {
    ...mapActions('app', [
      'fetchUserData'
    ]),
    submitPromoCode () {
      this.promoSuccess = false
      this.promoError = false
      if (this.promoCode.length) {
        this.$async.applyPromoCode.$perform(this.promoCode)
          .then(response => {
            this.promoSuccess = true
            this.fetchUserData()
          })
          .catch(() => {
            this.promoError = true
            this.errorMessage = 'Activation code not found'
          })
      }
    }
  },
  asyncOperations: {
    applyPromoCode (value) {
      return api.applyPromoCode(value)
    }
  }
}
</script>
