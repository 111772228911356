var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.component,
    _vm._g(
      _vm._b(
        {
          tag: "component",
          staticClass: "ui-btn",
          class: [
            "ui-btn--size-" + _vm.size,
            "ui-btn--type-" + _vm.type,
            {
              "ui-btn--loading": _vm.loading,
              "ui-btn--disabled": _vm.disabled,
              "ui-btn--wide": _vm.wide,
              "ui-btn--uppercase": _vm.uppercase,
              "ui-btn--full-width": _vm.fullWidth,
              "ui-btn--inline": _vm.inline,
              "ui-btn--icon-only": _vm.iconOnly,
              "ui-btn--has-prepend": _vm.prependIcon,
              "ui-btn--has-append": _vm.appendIcon,
              "is-highlighted": _vm.highlighted
            }
          ]
        },
        "component",
        _vm.bindedAttrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _vm.prependIcon && !_vm.loading
        ? _c("UiIcon", {
            staticClass: "ui-btn__prepend-icon",
            attrs: { icon: _vm.prependIcon }
          })
        : _vm._e(),
      _vm.loading
        ? _c("UiIcon", {
            staticClass: "ui-btn__loading-icon",
            attrs: { icon: "im-loader" }
          })
        : _vm._e(),
      _vm.$slots.default || _vm.label
        ? _c(
            "span",
            {
              staticClass: "ui-btn__text",
              class: _vm.labelClass ? "" + _vm.labelClass : null
            },
            [
              _vm._t("default", function() {
                return [_vm._v(" " + _vm._s(_vm.label) + " ")]
              })
            ],
            2
          )
        : _vm._e(),
      _vm.iconOnly && !_vm.loading
        ? _c("UiIcon", {
            staticClass: "ui-btn__single-icon",
            attrs: { icon: _vm.iconOnly }
          })
        : _vm._e(),
      _vm.appendIcon
        ? _c("UiIcon", {
            staticClass: "ui-btn__append-icon",
            attrs: { icon: _vm.appendIcon }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }