<template>
  <div class="side-form">
    <div class="side-form__item">
      <p class="side-form__footnote">Create internal pages to tell more about your product.</p>
    </div>
    <div class="side-form__item">
      <draggable
        :value="value"
        tag="div"
        handle=".draggable__item-trigger"
        class="draggable"
        @input="val => onDrag(val)"
      >
        <div
          v-for="(route, index) in value"
          :key="index"
          class="draggable__item"
          >
          <div class="draggable__item-trigger icon-draggable"></div>
          <div class="draggable__item-title-wrap"
            :class="{'is-inactive': (index >= allowedNumberOfPages)}"
            @click="onItemClick(index)"
          >
            <i v-if="route.type === 'page'" class="icon-new-page draggable__item-title-icon"></i>
            <i v-else class="icon-link2 draggable__item-title-icon"></i>
            <span class="draggable__item-title">{{ route.navTitle }}</span>
          </div>
          <div class="draggable__item-controls">
            <ProOptionWrapper :allowed="index < allowedNumberOfPages">
            <div
              v-if="route.navHide"
              class="draggable__item-control icon-eye-hide"
              @click="$emit('update', index, { navHide: false })">
            </div>
            </ProOptionWrapper>
            <el-dropdown
              trigger="click"
              class="draggable__item-control"
            >
              <i class="icon-gear"></i>
              <el-dropdown-menu slot="dropdown" class="draggable__dropdown-menu">
                <ProOptionWrapper :allowed="index < allowedNumberOfPages">
                <el-dropdown-item @click.native="$emit('set-current', index)">Edit</el-dropdown-item>
                <el-dropdown-item @click.native="$emit('update', index, { navHide: !route.navHide })">
                  {{ route.navHide ? 'Show on menu' : 'Hide from menu' }}
                </el-dropdown-item>
                </ProOptionWrapper>
                <ProOptionWrapper :allowed="canEditPages">
                <el-dropdown-item @click.native="$emit('duplicate', index)">Duplicate</el-dropdown-item>
                </ProOptionWrapper>
                <el-dropdown-item @click.native="$emit('delete', index)">Delete</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </draggable>
    </div>
    <div class="side-form__item side-form__btns">
      <ProOptionWrapper :allowed="canEditPages">
        <button class="btn btn--transparent btn--prefix-icon btn--icon-new-page" @click="$emit('create', 'page')">Add page</button>
      </ProOptionWrapper>
      <ProOptionWrapper :allowed="canEditPages">
        <button class="btn btn--transparent btn--prefix-icon btn--icon-link2" @click="$emit('create', 'link')">Add link</button>
      </ProOptionWrapper>
    </div>
    <UiBtn
      size="l"
      attr-type="submit"
      :loading="loading"
      :disabled="loading || spreadViewerOptionsInvalid"
      class="aside-save"
      @click="$emit('submit')"
    >
      SAVE
    </UiBtn>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import UiBtn from '@/components/ui-btn'
import ProOptionWrapper from '@/components/pro-option-wrapper'
import { allowFeature } from '@/modules/features-checker'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'ManageRoutes',
  components: {
    draggable,
    ProOptionWrapper,
    UiBtn
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('app', [
      'userData',
      'currentSpreadViewData',
      'currentSpreadViewId'
    ]),
    ...mapGetters('app', ['spreadViewerOptionsInvalid']),
    allowedNumberOfPages () {
      return allowFeature('pagesLimit', {
        user: this.userData,
        website: this.currentSpreadViewData
      })
    },
    canEditPages () {
      return this.value.length < this.allowedNumberOfPages
    }
  },
  watch: {
    canEditPages () {
      this.updatePageVisibility()
    }
  },
  methods: {
    updatePageVisibility () {
      if (!this.canEditPages) {
        this.value.map((el, index) => {
          el.navHide = el.navHide || index >= this.allowedNumberOfPages
        })
        this.$emit('input', this.value)
      }
    },
    onDrag (val) {
      if (this.canEditPages) {
        this.$emit('input', val)
      }
    },
    onItemClick (index) {
      if (!this.canEditPages && index >= this.allowedNumberOfPages) return
      this.$emit('set-current', index)
    }
  },
  created () {
    this.updatePageVisibility()
  }
}
</script>
