import { Manager } from 'socket.io-client'
import Vue from 'vue'
import router from '../router'
import store from '@/store'
const URL = `${process.env.VUE_APP_API_BASE}/socket`

function showToast (message, type = 'error') {
  Vue.toasted.show(`${message}`, {
    theme: 'toasted-primary',
    position: 'top-center',
    type: type,
    duration: 1500
  })
}

export const initSocket = ({ path = '/', currentState = {}, onConnect = () => {}, onDestroy = () => {} }) => {
  if (!store.getters['app/startWebsocketConnection']) return null
  const socketManager = new Manager(URL, {
    autoConnect: false
  })
  const socketState = { ...{ wsId: localStorage.getItem('ssWsId') }, ...currentState }

  const socket = socketManager.socket(path, {
    auth: {
      token: localStorage.getItem('accessToken'),
      state: socketState
    }
  })

  socket.connect()

  socket.on('connect_error', (err) => {
    function refreshToken () {
      Vue.$auth.refresh(localStorage.getItem('refreshToken')).then(result => {
        socket.auth.token = result.accessToken
        socket.io.reconnect()
        showToast(`Connection established.`, 'success')
      })
    }

    if (err) {
      if (err.data.status === 429) {
        showToast(`${err.data.status}. ${err.data.name}. ${parseInt(err.data.payload.msBeforeNext / 1000)} seconds until the next request.`)
      }
      if (err.data.status === 404) {
        showToast(err.data.payload.message)
        router.push({ name: 'spread-views' })
      }
      if (err.data.payload.name === 'JsonWebTokenError') {
        showToast(err.data.payload.message)
        refreshToken()
      }
      if (err.data.payload.name === 'TokenExpiredError') {
        showToast(err.data.payload.message)
        refreshToken()
      }
    }
  })

  socket.on('connect', () => {
    onConnect()
  })

  if (socket.connected) {
    socket.on('disconnect', (reason) => {
      console.log(`socket disconnect: ${reason}`)
      onDestroy()
    })
  }

  return socket
}
