export default {
  published: true,
  pro: true,
  featureRestrictionsGroup: 'emailIntegrations',
  name: 'universal-email-subscription',
  title: 'Universal Email Subscription',
  headline: '',
  logoUrl: '/img/integrations/universal-email-subscription.png',
  // coverUrl: '/img/integrations/mail-chimp-cover.png',
  bgColor: '#3B8AF0',
  categories: ['Email Marketing'],
  comingSoon: false,
  builtIn: false,
  url: '',
  helpUrl: '',
  description: `
  You can add an email subscription form to the intro section and also as a popup.

  <details>
  <summary>How it works?</summary>

  Technically, when using this add-on, the email subscription form data is submitted as a form to the specified action URL with the specified method: GET or POST.

  You're able to map each query param and form-data field of this form by setting up the key=value pairs like

  \`EMAIL={{email}}\` <br>

  where \`EMAIL\` is a request parameter key;  \`{{email}}\` is a replacement tag to be replaced with actual value.

  </details>

  <br/>

  <details>
  <summary>Available replacement tags</summary>
  <div class="table-wrap">

  | Tag | Example value |
  |---------------|---------------|
  |  \`{{email}}\` | john@example.com |
  |  \`{{firstName}}\` | John |
  |  \`{{lastName}}\` | Doe |
  </div>
  </details>

  <br/>

  <details>
  <summary>NEW! Webhook added.</summary>

  When utilizing the "Webhook (POST)" method, we will effortlessly transmit the form data to the designated "Action URL."

  Any subsequent actions required should be performed within your automation tool. It's important to note that the user will not be redirected to the "Action URL" address, and query parameters will not be appended to your URL.

  The submitted form values will be transmitted in their entirety.

  </details>
  `,

  formFields: [
    {
      name: 'enabled',
      type: 'toggle',
      label: 'Enabled',
      default: false,
      validations: [],
      secret: false
    },
    {
      name: 'showIntro',
      type: 'toggle',
      showIf: ['enabled'],
      label: 'Show Form in Intro Section',
      default: false,
      validations: [],
      secret: false
    },
    {
      name: 'showPopup',
      type: 'toggle',
      showIf: ['enabled'],
      label: 'Show Floating Button and Popup',
      default: false,
      validations: [],
      secret: false
    },
    {
      name: 'title',
      type: 'text',
      showIf: ['enabled', 'showPopup'],
      label: 'Popup Title',
      default: '',
      placeholder: '',
      validations: [
        'required',
        'maxLength:160'
      ],
      secret: false
    },
    {
      name: 'subtitle',
      type: 'text',
      showIf: ['enabled', 'showPopup'],
      label: 'Popup Subtitle',
      default: '',
      placeholder: '',
      validations: [
        'maxLength:300'
      ],
      secret: false
    },
    {
      name: 'askForName',
      type: 'toggle',
      showIf: ['enabled'],
      label: 'Ask for First and Last Name',
      default: false,
      validations: [],
      secret: false
    },
    {
      name: 'buttonTitle',
      type: 'text',
      showIf: ['enabled'],
      label: 'Button Title',
      default: 'Subscribe',
      placeholder: 'Subscribe',
      validations: [
        'required',
        'maxLength:50'
      ],
      secret: false
    },
    {
      name: 'buttonColor',
      type: 'color',
      showIf: ['enabled'],
      label: 'Button Color',
      default: '#999999',
      validations: [
        'required'
      ],
      secret: false
    },
    {
      name: 'url',
      type: 'text',
      showIf: ['enabled'],
      label: 'Action URL',
      default: '',
      placeholder: '',
      validations: [
        'required',
        'maxLength:500',
        'url',
        'startsWith:http:https'
      ],
      secret: false
    },
    {
      name: 'method',
      type: 'select',
      showIf: ['enabled'],
      label: 'Request Method',
      default: 'GET',
      options: [
        {
          label: 'GET',
          value: 'GET'
        },
        {
          label: 'POST',
          value: 'POST'
        },
        {
          label: 'Webhook (POST)',
          value: 'WEBHOOKPOST'
        }
      ],
      validations: [
      ],
      secret: false
    },
    {
      name: 'getArgsConfig',
      type: 'textarea',
      showIf: ['enabled', 'method=POST||GET'],
      label: 'Query Params mapping',
      default: '',
      placeholder: `EMAIL={{email}}
`,
      validations: [
      ],
      secret: false
    },
    {
      name: 'postArgsConfig',
      type: 'textarea',
      showIf: ['enabled', 'method=POST'],
      label: 'Body form-data mapping',
      default: '',
      placeholder: `EMAIL={{email}}
`,
      validations: [
      ],
      secret: false
    },
    {
      name: 'openInPopup',
      type: 'toggle',
      showIf: ['enabled', 'method=POST||GET'],
      label: 'Open destination URL in a popup (iFrame)',
      default: false,
      validations: [],
      secret: false
    }
  ],

  actions: []
}
