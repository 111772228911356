var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "unsubscribe-modal" }, [
    _c("div", { staticClass: "unsubscribe-modal__content-wrap" }, [
      _c("p", [_vm._v(_vm._s(_vm.features.description))]),
      _c(
        "ul",
        _vm._l(_vm.features.list, function(feature, index) {
          return _c("li", { key: index }, [_vm._v(_vm._s(feature))])
        }),
        0
      ),
      _vm.hasDiscount
        ? _c("p", [_vm._v(_vm._s(_vm.features.hasDiscount))])
        : _c("p", [_vm._v(_vm._s(_vm.features.hasNotDiscount))]),
      !_vm.hasDiscount
        ? _c("p", {
            domProps: {
              innerHTML: _vm._s(_vm.features.offer(_vm.discountSize))
            }
          })
        : _vm._e()
    ]),
    _c(
      "div",
      { staticClass: "unsubscribe-modal__btns" },
      [
        _vm.hasDiscount
          ? _c(
              "UiBtn",
              {
                attrs: { "full-width": "" },
                on: {
                  click: function($event) {
                    return _vm.$emit("close-modal")
                  }
                }
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.features.hasDiscountKeepBtn) +
                    "\n    "
                )
              ]
            )
          : _c(
              "UiBtn",
              {
                attrs: { "full-width": "" },
                on: {
                  click: function($event) {
                    return _vm.$emit("take-discount")
                  }
                }
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.features.hasNotDiscountTakeBtn) +
                    "\n    "
                )
              ]
            ),
        _vm.hasDiscount
          ? _c(
              "UiBtn",
              {
                attrs: { type: "secondary", "full-width": "" },
                on: {
                  click: function($event) {
                    return _vm.$emit("unsubscribe")
                  }
                }
              },
              [_vm._v(_vm._s(_vm.features.hasDiscountUnsubscribeBtn))]
            )
          : _c(
              "UiBtn",
              {
                attrs: { type: "secondary", "full-width": "" },
                on: {
                  click: function($event) {
                    return _vm.$emit("unsubscribe")
                  }
                }
              },
              [_vm._v(_vm._s(_vm.features.hasNotDiscountUnsubscribeBtn))]
            )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }