<template>
<ProOptionWrapper :featureName="'mapsView'">
  <div class="content-form__switch-field">
    <div class="content-form__switcher-wrap">
      <div class="content-form__switcher-label">Map view</div>
      <label
        :class="{ 'selected': mapViewState.enabled }"
        class="switcher"
      >
        <input
          v-model="mapViewState.enabled"
          class="switcher__input"
          type="checkbox"
          value="tiles"
          @change="$trackFeature('content', 'mapView', mapViewState.enabled)"
        >
        <span class="switcher__indicator"></span>
      </label>
    </div>
    <div v-if="mapViewState.enabled" class="content-form__switch-hidden-field aside-select paddingless">
      <div class="content-form__highlighted">

        <FormField
          label="Coordinates"
          class="side-form__item aside-input">
          <v-select
            v-model="mapViewState.markerColId"
            :items="cols"
            item-text="label"
            item-value="id"
            menu-props="offsetY"
            placeholder="Marker address"
            append-icon="keyboard_arrow_down"
          >
          </v-select>
        </FormField>

        <div class="sorting-constructor__item">
          <a class="sorting-constructor__link" target="_blank" href="https://help.spreadsimple.com/en/article/how-to-get-coordinates-1oc7v23/">Help: How to get coordinates?</a>
        </div>

        <FormField
          label="Map type"
          class="side-form__item aside-input">
            <v-select
              v-model="mapViewState.mapType"
              :items="mapTypes"
              item-disabled="disabled"
              item-text="label"
              item-value="value"
              menu-props="offsetY"
              placeholder="Map type"
              append-icon="keyboard_arrow_down"
            >
            </v-select>
        </FormField>

        <div class="sorting-constructor__item">
          <a class="sorting-constructor__link" target="_blank" href="https://help.spreadsimple.com/en/article/google-maps-add-on-4ag4nq/">Help: How to set up Google Maps Add-on?</a>
        </div>

        <div
          v-if="!singleItemPage"
          class="n-form-field side-form__item aside-input">
          <div class="content-form__switcher-wrap">
            <div class="content-form__switcher-label">Use clusters</div>
            <label
              :class="{ 'selected': mapViewState.useClusters }"
              class="switcher"
            >
              <input
                v-model="mapViewState.useClusters"
                class="switcher__input"
                type="checkbox"
              >
              <span class="switcher__indicator"></span>
            </label>
          </div>
        </div>

        <FormField
          label="Marker type"
          class="side-form__item aside-input">
            <v-select
              v-model="mapViewState.markerType"
              :items="$options.markerTypes"
              item-text="label"
              item-value="value"
              menu-props="offsetY"
              placeholder="Marker type"
              append-icon="keyboard_arrow_down"
            >
            </v-select>
        </FormField>

        <FormField
          v-if="mapViewState.markerType === 'image'"
          label="Marker Image"
          class="side-form__item aside-input">
          <v-select
            v-model="mapViewState.markerImageColId"
            :items="cols"
            item-text="label"
            item-value="id"
            menu-props="offsetY"
            placeholder="Marker image"
            append-icon="keyboard_arrow_down"
          >
          </v-select>
        </FormField>

        <FormField
          label="Map height"
          class="side-form__item aside-input">
          <v-text-field
            v-model="mapViewState.mapHeight"
            type="number"
            required
            :min="50"
            :max="1200"
          >
          </v-text-field>
        </FormField>

        <FormField
          label="Zoom"
          class="side-form__item aside-input">
          <v-text-field
            v-model="mapViewState.zoom"
            type="number"
            :min="1"
            :max="20"
            required
          >
          </v-text-field>
        </FormField>

        <FormField
          v-if="!singleItemPage"
          label="Map center"
          class="side-form__item aside-input">
          <v-text-field
            v-model="mapViewState.mapCenter"
            type="text"
            required
          >
          </v-text-field>
        </FormField>

        <FormField
          v-if="!singleItemPage"
          label="Default view"
          class="side-form__item aside-input">
            <v-select
              v-model="mapViewState.defaultView"
              :items="$options.viewTypes"
              item-text="label"
              item-value="value"
              menu-props="offsetY"
              placeholder="Default view"
              append-icon="keyboard_arrow_down"
            >
            </v-select>
        </FormField>

        <div class="sorting-constructor__item">
          <a class="sorting-constructor__link" target="_blank" href="https://help.spreadsimple.com/en/article/setting-up-map-view-ilfdcu/">Help: How to use map?</a>
        </div>

      </div>
    </div>
  </div>
</ProOptionWrapper>
</template>
<script>
import FormField from '@/components/form-field'
import ProOptionWrapper from '@/components/pro-option-wrapper'
import { markerTypes, viewTypes } from '@/modules/utils/mapOptions'
export default {
  name: 'MapViewConstructor',
  markerTypes,
  viewTypes,
  components: {
    ProOptionWrapper,
    FormField
  },
  props: {
    cols: {
      type: Array,
      required: true
    },
    value: {
      type: Object,
      required: true
    },
    singleItemPage: {
      default: false
    },
    integrationsData: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      mapViewState: null
    }
  },
  computed: {
    gmapsDisabled () {
      const gmapData = this.integrationsData.find(i => i.name === 'google-maps')
      if (!gmapData) return true
      return !gmapData.data.find(i => i.name === 'enabled').value
    },
    mapboxDisabled () {
      const mapboxData = this.integrationsData.find(i => i.name === 'mapbox')
      if (!mapboxData) return true
      return !mapboxData.data.find(i => i.name === 'enabled').value
    },
    mapTypes () {
      return [
        { label: 'OSM', value: 'osm', disabled: false },
        { label: 'Google Maps', value: 'gmaps', disabled: this.gmapsDisabled },
        { label: 'Mapbox', value: 'mapbox', disabled: this.mapboxDisabled }
      ]
    }
  },
  watch: {
    mapViewState: {
      deep: true,
      handler: function (val) {
        this.$emit('input', val)
      }
    }
  },
  created () {
    this.mapViewState = JSON.parse(JSON.stringify(this.value))
  }
}
</script>
