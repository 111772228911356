var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.value
    ? _c(
        "div",
        {
          staticClass: "modal__wrapp",
          class: { "modal__wrapp--fullscreen": _vm.fullscreen },
          attrs: { tabindex: "0" },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              return _vm.onPressEscBtn.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _vm.onClickOutside,
                  expression: "onClickOutside"
                },
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              staticClass: "modal",
              style: "--maxWidth: " + _vm.maxWidth + "; --top: " + _vm.top + ";"
            },
            [
              _vm.hasCloseButton
                ? _c(
                    "button",
                    {
                      staticClass: "modal__close",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.$emit("input", false)
                        }
                      }
                    },
                    [_c("i", { staticClass: "icon icon-close" })]
                  )
                : _vm._e(),
              _vm.modalName
                ? _c(
                    "h4",
                    {
                      staticClass: "modal__title",
                      class: { "align-left": _vm.alignTitleLeft }
                    },
                    [_vm._v(_vm._s(_vm.modalName))]
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "modal__body" },
                [_vm._t("modal-content"), _vm._t("default")],
                2
              )
            ]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }