import OpenReplay from '@openreplay/tracker'

let tracker = null

function readCookie (name) {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop().split(';').shift()
}

export async function initOpenReplay (env, key) {
  const isDev = env === 'development'
  const isProd = env === 'production'

  if (isDev) return

  const initOpts = {
    projectKey: key,
    ingestPoint: 'https://or.spreadsimple.com/ingest',
    defaultInputMode: 2,
    obscureTextNumbers: false,
    obscureTextEmails: true,
    captureIFrames: true
  }

  if (!isProd) {
    initOpts.__DISABLE_SECURE_MODE = true
    initOpts.onStart = ({ sessionID }) => console.log('OpenReplay tracker started with session: ', sessionID)
  }

  tracker = new OpenReplay(initOpts)

  const knownUser = readCookie('__ss_user_email')

  if (!isProd) {
    console.log('OpenReplay Module: find knownUser', knownUser)
  }

  await tracker.start()

  if (knownUser && knownUser !== 'undefined' && knownUser !== undefined) {
    openReplayIdentifyUser(knownUser, env)
  }
  return tracker
}

export function openReplayIdentifyUser (email, env = 'production') {
  const isDev = env === 'development'
  const isProd = env === 'production'

  if (isDev) return

  tracker.setUserID(`${email}`)
  if (!isProd) {
    console.log('OpenReplay Module: set User Id', email)
  }
}
