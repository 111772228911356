import Vue from 'vue'
import api from '@/services/api/'
import { trackEvent } from '@/modules/utils/analytics'
import { startProfitWell } from '@/modules/utils/profitWell'

// DATA FETCHING

export const setUserData = ({ commit, state }, user) => {
  commit('set', { key: 'userData', value: user })
  return state.userData
}

export const setUserDataWhenLoaded = async ({ dispatch, commit }) => {
  const user = await Vue.$auth.whenUserDataLoaded()
  startProfitWell(user.email)
  return dispatch('setUserData', user)
}

export const fetchUserData = async ({ dispatch }) => {
  const user = await Vue.$auth.fetchUser()
  return dispatch('setUserData', user)
}

export const refreshWorkspaceId = async ({ dispatch }, id) => {
  let wsId = id
  if (!wsId) {
    const response = await dispatch('fetchWorkspaces')
    wsId = response.find(ws => ws.main).id
  }
  dispatch('setWorkspaceId', wsId)
  return wsId
}

export const fetchWorkspaces = async ({ dispatch, commit, state }) => {
  const result = await api.getWorkspaces()
  commit('set', { key: 'workspaces', value: result })

  const wsId = localStorage.getItem('ssWsId')
  if (wsId && !state.currentWorkspaceId) {
    dispatch('setWorkspaceId', wsId)
  }

  return result
}

export const setWorkspaceId = ({ commit }, wsId) => {
  localStorage.setItem('ssWsId', wsId)
  commit('set', { key: 'currentWorkspaceId', value: wsId })
}

export const clearWorkspacesData = ({ commit }) => {
  commit('set', { key: 'currentWorkspaceId', value: undefined })
  commit('set', { key: 'workspaces', value: null })
  localStorage.removeItem('ssWsId')
}

export const clearUserData = ({ dispatch, state }) => {
  dispatch('setUserData', null)
}

// PRICING AND SUBSCRIPTIONS

export const checkAvailableLicenseByDeal = async ({ getters }, dealType) => {
  const isFreeDeal = dealType.toLowerCase().includes('free')
  if (isFreeDeal) {
    return getters.workspaceAvailableLicenses['free'] > 0
  }
  return getters.workspaceAvailableLicenses[dealType] > 0
}

export const updateSpreadViewDeal = async ({ dispatch, commit, state, getters }, { id, dealType }) => {
  const hasAvailableLicensesOfType = await dispatch('checkAvailableLicenseByDeal', dealType)
  commit('set', { key: 'currentSpreadViewId', value: id })
  if (hasAvailableLicensesOfType) {
    const result = await dispatch('updateCurrentViewDeal', dealType)
    return result
  }

  // Show modal to request more licenses from workspace owner
  if (!hasAvailableLicensesOfType && !getters.currentWorkspace.main) {
    dispatch('toggleModal', { name: 'seatsRequestModal', value: true })
    return false
  }

  // Show modal to increase license number
  if (!hasAvailableLicensesOfType && getters.currentWorkspace.main) {
    dispatch('toggleModal', { name: 'addMoreSeatsInfoModal', value: true })
    return false
  }
}

export const toggleModal = async ({ commit }, { name, value = false }) => {
  commit('set', { key: name, value: value })
}

export const updateCurrentViewDeal = async ({ dispatch, commit, state }, dealType) => {
  if (!state.currentSpreadViewId) {
    return false
  }
  try {
    const response = await api.patchDealType(state.currentSpreadViewId, dealType)
    trackEvent({
      'event': 'upgradeWebsitePlan',
      'domain': response.domain,
      'dealType': dealType
    })
    return true
  } catch (e) {
    console.warn(e)
  } finally {
    commit('set', { key: 'currentSpreadViewId', value: null })
    commit('set', { key: 'needToUpdateViews', value: true })
    await dispatch('fetchWorkspaces')
    commit('set', { key: 'needToUpdateViews', value: false })
  }
}

// ONBOARDING WIZARD

export const updateOnboardingWizardState = ({ dispatch, state }, wizards) => {
  const { userData } = state
  userData.wizards = wizards
  return dispatch('setUserData', userData)
}

// UPDATE WEBSITE ACCESS

export const updateWebsiteAccessRules = ({ commit, state }, rules) => {
  for (let rule in rules) {
    commit('setWebsiteAccessRules', { key: rule, value: rules[rule] })
  }
  return state.websiteAccess
}

export const setDefaultWebsiteAccessState = ({ commit, state }) => {
  commit('set', {
    key: 'websiteAccess',
    value: {
      created: false,
      enabled: false,
      spreadsheetUrl: '',
      scheme: [],
      authProvider: '',
      authProviders: [],
      users: [],
      availableAccessGroups: []
    } })
  return state.websiteAccess
}

// MANAGE PAGE VALIDATION

export const setSpreadViewerOptionsValidationState = ({ commit, state }, { optionName, isInvalid }) => {
  commit('setSpreadViewerOptionValidationState', { key: optionName, value: isInvalid })
  return state.spreadViewerOptionsValidation
}
