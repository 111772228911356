export default {
  published: true,
  pro: true,
  featureRestrictionsGroup: 'formsIntegrations',
  name: 'aidaform',
  title: 'Aidaform',
  logoUrl: '/img/integrations/aidaform.png',
  // coverUrl: '/img/integrations/aidaform-cover.png',
  bgColor: '#CAEFFF',
  categories: ['Analytics'],
  comingSoon: false,
  builtIn: false,
  url: 'https://aidaform.com',
  helpUrl: '',
  description: `
  AidaForm a complete service where you can create both conversational and traditional online forms, surveys, order forms and quizzes. You can also integrate your forms with Google Sheets and other services.

  This Add-on will allow you to add your forms created with Aidaform to SpreadSimple pages.

  ## How to use

  1. Enable this Add-on
  2. Create a form in Aidaform
  3. Go to the Publish tab of the form
  4. In the "Embed on web page" section, copy the div code only (without the script tag) ![](/img/integrations/aidaform-instructions-01.png)
  5. Paste the copied code to page you need the form to appear ![](/img/integrations/aidaform-instructions-02.png)
  6. Save and publish the website. <em>Note that the form is visible only on the published website and not visible in the preview.</em>
  `,

  formFields: [
    {
      name: 'enabled',
      type: 'toggle',
      label: 'Enabled',
      default: false,
      validations: [
        'required'
      ],
      secret: false
    }
  ],

  actions: [
    {
      type: 'injectToHead',
      run (formData) {
        return `
        <script>
        document.addEventListener('ssViewerRendered', () => {
          if (!document.querySelector('[data-aidaform-widget]')) return;
          const aidaformScripts = document.querySelectorAll('[src^="https://embed.aidaform"]');
          if (aidaformScripts.length) aidaformScripts.forEach(el => el.remove());
          (function(){var r,d=document,gt=d.getElementById,cr=d.createElement,tg=d.getElementsByTagName,id="aidaform-embed";if(!gt.call(d,id)){r=cr.call(d,"script");r.id=id;r.src="https://embed.aidaform.com/embed.js";(d.head || tg.call(d,"head")[0]).appendChild(r);}})()
        })
        </script>
        `
      }
    }
  ]
}
