<template>
  <div class="paginator">
    <button
      type="button"
      class="paginator__prev"
      :disabled="prevPage === null"
      @click="$emit('change-page', prevPage)"
    ></button>
    <div class="paginator__list">
      <button
        v-for="(page, index) in pageNumbers"
        :key="index"
        :class="{ 'is-active': page === currentPage }"
        class="paginator__item"
        type="button"
        @click="$emit('change-page', page)"
      >
        {{ page }}
      </button>
    </div>
    <button
      type="button"
      class="paginator__next"
      :disabled="nextPage === null"
      @click="$emit('change-page', nextPage)"
    ></button>
  </div>
</template>
<script>
export default {
  name: 'Paginator',
  props: {
    data: {}
  },
  computed: {
    currentPage () {
      return this.data.page
    },
    prevPage () {
      return this.data.prevPage
    },
    nextPage () {
      return this.data.nextPage
    },
    pageNumbers () {
      let numbers = []
      for (let i = 1; i <= this.data.totalPages; i++) {
        numbers.push(i)
      }
      return numbers
    }
  }
}
</script>
