<template>
  <div class="form-field">
    <span class="form-field__label">
      {{ $t('pageManageSheet.settings.theme') }}
    </span>

    <div class="form-field__control align-end">
      <label
        v-for="(option, index) in options"
        :key="index"
        :class="[
          {'active': option.value === value.value},
          option.class
        ]"
        class="theme-picker"
      >
        <input
          :value="option.value"
          class="theme-picker__input"
          type="radio"
          name="theme"
          @input="e => emitValue(e)"
        />
      </label>
    </div>
  </div>
</template>
<script>
import { themeOptions } from '@/modules/utils/themes'
export default {
  name: 'ThemePicker',
  props: {
    value: {
      type: Object
    }
  },
  data () {
    return {
      options: themeOptions
    }
  },
  methods: {
    emitValue (e) {
      this.$emit('input', this.options.find(o => o.value === e.target.value))
    }
  }
}
</script>
