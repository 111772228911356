var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Preloader", {
        attrs: {
          loading:
            _vm.$async.getSpreadView.$pending ||
            _vm.$async.deleteSpreadView.$pending ||
            _vm.$async.createSpreadView.$pending ||
            _vm.fullscreenPreloader,
          content: "Loading your website content..."
        }
      }),
      _vm.$async.getSpreadView.$resolved
        ? _c(
            "div",
            { staticClass: "manage" },
            [
              _c(
                "portal",
                { attrs: { to: "manage-header-title-editor" } },
                [
                  _c("TitleEditor", {
                    attrs: {
                      value: _vm.options.spreadView.name,
                      disabled: _vm.$async.saveSpreadView.$pending
                    },
                    on: { input: _vm.updateSpreadViewName }
                  })
                ],
                1
              ),
              _c(
                "portal",
                { attrs: { to: "manage-header-subscription-button" } },
                [
                  _c("UiTag", {
                    attrs: {
                      size: "xxs",
                      color:
                        _vm.websiteObject.dealType === "free"
                          ? "secondary"
                          : "success",
                      label:
                        _vm.$options.dealTypeLabels[_vm.websiteObject.dealType]
                    }
                  })
                ],
                1
              ),
              _c(
                "portal",
                { attrs: { to: "manage-header-menu" } },
                [
                  _vm.currentWorkspace
                    ? _c(
                        "el-dropdown",
                        {
                          attrs: { trigger: "click" },
                          on: {
                            "visible-change": function($event) {
                              _vm.spreadViewSettingsDropdownVisible = !_vm.spreadViewSettingsDropdownVisible
                            }
                          }
                        },
                        [
                          _c("UiBtn", {
                            attrs: {
                              "icon-only": "im-gear-2",
                              type: "ghost",
                              highlighted: _vm.spreadViewSettingsDropdownVisible
                            }
                          }),
                          _c(
                            "el-dropdown-menu",
                            {
                              staticStyle: { width: "190px" },
                              attrs: { slot: "dropdown" },
                              slot: "dropdown"
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "pl-12 pr-12 pt-8 mb-4 text--size-xs text--weight-500 text--color-secondary"
                                },
                                [_vm._v("Manage plans")]
                              ),
                              _vm._l(
                                Object.entries(
                                  _vm.currentWorkspace.ownerLicenses
                                    .paidLicenses
                                ),
                                function(deal, index) {
                                  return [
                                    _c(
                                      "el-dropdown-item",
                                      {
                                        key: index,
                                        staticClass: "is-wrapper",
                                        attrs: {
                                          disabled:
                                            _vm.websiteObject.dealType ===
                                            deal[0]
                                        }
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.applySpreadViewDeal(
                                                  deal[0]
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                Apply " +
                                                _vm._s(
                                                  _vm.$options.dealTypeLabels[
                                                    deal[0]
                                                  ]
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                }
                              ),
                              !_vm.websiteObject.dealType
                                .toLowerCase()
                                .includes("free")
                                ? _c(
                                    "el-dropdown-item",
                                    { staticClass: "is-danger is-wrapper" },
                                    [
                                      _c(
                                        "button",
                                        {
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.applySpreadViewDeal(
                                                "free"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n              Downgrade to free\n            "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _c("div", {
                                staticClass: "ui-divider mt-2 mb-12"
                              }),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "pl-12 pr-12 mb-4 text--size-xs text--weight-500 text--color-secondary"
                                },
                                [_vm._v("General")]
                              ),
                              _c(
                                "el-dropdown-item",
                                { staticClass: "is-wrapper" },
                                [
                                  _c(
                                    "button",
                                    {
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.duplicateSpreadView()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              Duplicate\n            "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "el-dropdown-item",
                                { staticClass: "is-danger is-wrapper" },
                                [
                                  _c(
                                    "button",
                                    {
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          _vm.modalDeleteOpened = true
                                        }
                                      }
                                    },
                                    [_vm._v("Delete")]
                                  )
                                ]
                              )
                            ],
                            2
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      class: {
                        "is-loading": _vm.$async.saveSpreadView.$pending
                      },
                      attrs: { disabled: _vm.spreadViewerOptionsInvalid },
                      on: { click: _vm.tryToPublishSpreadView }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("global.publish")) +
                          "\n      "
                      )
                    ]
                  )
                ],
                1
              ),
              _c("SpreadViewerOptions", {
                ref: "spreadOptions",
                attrs: {
                  cols: _vm.cols,
                  domain: _vm.websiteObject.domain,
                  googleUrl: _vm.googleUrl,
                  pwdProtection: _vm.pwdProtection,
                  seoIndexing: _vm.seoIndexing,
                  integrationsOpened: _vm.integrationsOpened,
                  loading:
                    _vm.$async.saveSpreadView.$pending ||
                    _vm.$async.updateSpreadViewSource.$pending ||
                    _vm.isLoading,
                  integrationCategories: _vm.integrationCategories,
                  integrations: _vm.integrationsWithData,
                  integrationsData: _vm.integrationsData,
                  currentIntegrationCategory: _vm.currentIntegrationCategory
                },
                on: {
                  "update:currentIntegrationCategory": function($event) {
                    _vm.currentIntegrationCategory = $event
                  },
                  "update:current-integration-category": function($event) {
                    _vm.currentIntegrationCategory = $event
                  },
                  save: function(val) {
                    return _vm.saveSpreadView({ options: val })
                  },
                  "reload-preview": function($event) {
                    return _vm.reloadPreview()
                  },
                  "request-preview-change": _vm.setPreviewRoute,
                  "google-url-change": function(val) {
                    return _vm.updateSpreadViewSource({
                      spreadsheetUrl: val,
                      sheetHash: ""
                    })
                  },
                  "update-indexing": function(val) {
                    return _vm.saveSpreadView({ seoIndexing: val })
                  },
                  "update-pwd-protection": function(val) {
                    return _vm.saveSpreadView({ pwdProtection: val })
                  },
                  "open-default-single-item": _vm.openDefaultSIngleItem,
                  "update-sheet-data": function($event) {
                    return _vm.refreshSheetData()
                  },
                  "open-payment-addons": function($event) {
                    _vm.paymentsModalOpened = true
                  },
                  "disable-payment-addon": _vm.saveIntegrationsData
                },
                model: {
                  value: _vm.options,
                  callback: function($$v) {
                    _vm.options = $$v
                  },
                  expression: "options"
                }
              }),
              _vm.integrationsOpened
                ? _c(
                    "div",
                    { staticClass: "manage__integrations-wrap" },
                    [
                      _c("IntegrationsManager", {
                        attrs: {
                          integrations: _vm.integrationsWithData,
                          integrationsData: _vm.integrationsData,
                          currentIntegrationCategory:
                            _vm.currentIntegrationCategory,
                          integrationCategories: _vm.integrationCategories,
                          userData: _vm.userData,
                          websiteUrl: _vm.resultUrl
                        },
                        on: {
                          "update:integrationsData": [
                            function($event) {
                              _vm.integrationsData = $event
                            },
                            _vm.saveIntegrationsData
                          ],
                          "update:integrations-data": function($event) {
                            _vm.integrationsData = $event
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c("keep-alive", [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.integrationsOpened,
                        expression: "!integrationsOpened"
                      }
                    ],
                    staticClass: "manage__viewer-side"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "manage__viewer-wrap",
                        class: "is-" + _vm.previewType + "-view"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "viewer-bar",
                            class: "is-" + _vm.previewType + "-view"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "viewer-bar__circles" },
                              _vm._l(3, function(item) {
                                return _c("div", {
                                  key: item,
                                  staticClass: "viewer-bar__circle"
                                })
                              }),
                              0
                            ),
                            _c(
                              "div",
                              { staticClass: "viewer-bar__field" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "viewer-bar__input-wrap" },
                                  [
                                    _vm.options.spreadView.isPublished
                                      ? _c(
                                          "a",
                                          {
                                            staticClass:
                                              "viewer-bar__input-prepend",
                                            attrs: {
                                              href: _vm.resultUrl,
                                              target: "_blank"
                                            }
                                          },
                                          [_vm._v(_vm._s(_vm.resultUrl))]
                                        )
                                      : _c(
                                          "a",
                                          {
                                            staticClass:
                                              "viewer-bar__input-prepend",
                                            attrs: { href: "" },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.$root.$emit(
                                                  "openDomainSettings"
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                class: {
                                                  "viewer-bar__input-prepend--blur": !_vm
                                                    .options.spreadView
                                                    .isPublished
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(_vm.resultUrl) +
                                                    "\n                  "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                  ]
                                ),
                                _c("transition", { attrs: { name: "fade" } }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "viewer-bar__submit",
                                      on: {
                                        click: function($event) {
                                          return _vm.$root.$emit(
                                            "openDomainSettings"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Change domain\n                "
                                      )
                                    ]
                                  )
                                ])
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "viewer-bar__switchers" },
                              _vm._l(_vm.previewTypes, function(item) {
                                return _c(
                                  "label",
                                  {
                                    key: item,
                                    staticClass: "viewer-bar__switcher",
                                    class: {
                                      "is-active": item === _vm.previewType
                                    }
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.previewType,
                                          expression: "previewType"
                                        }
                                      ],
                                      attrs: { type: "radio" },
                                      domProps: {
                                        value: item,
                                        checked: _vm._q(_vm.previewType, item)
                                      },
                                      on: {
                                        change: function($event) {
                                          _vm.previewType = item
                                        }
                                      }
                                    }),
                                    _c("i", {
                                      staticClass: "viewer-bar__switcher-icon",
                                      class: "icon-preview-" + item
                                    })
                                  ]
                                )
                              }),
                              0
                            )
                          ]
                        ),
                        _c("iframe", {
                          staticStyle: { width: "100%", height: "100%" },
                          attrs: {
                            src:
                              _vm.viewerBaseUrl +
                              "?_preview_mode=1" +
                              "&_website_id=" +
                              _vm.id,
                            frameborder: "0",
                            "data-openreplay-capture": "",
                            allow: "clipboard-write"
                          }
                        })
                      ]
                    )
                  ]
                )
              ]),
              false ? _c("FullscreenMdEditor") : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.$async.getSpreadView.$resolved
        ? [
            _vm.publishModalOpened
              ? _c("PublishModal", {
                  attrs: {
                    publishingInProgress: _vm.publishingInProgress,
                    resultUrl: _vm.resultUrl
                  },
                  model: {
                    value: _vm.publishModalOpened,
                    callback: function($$v) {
                      _vm.publishModalOpened = $$v
                    },
                    expression: "publishModalOpened"
                  }
                })
              : _vm._e(),
            _c(
              "Modal",
              {
                attrs: { modalName: "Payment methods" },
                model: {
                  value: _vm.paymentsModalOpened,
                  callback: function($$v) {
                    _vm.paymentsModalOpened = $$v
                  },
                  expression: "paymentsModalOpened"
                }
              },
              [
                _c("IntegrationsManager", {
                  attrs: {
                    modalView: "",
                    integrations: _vm.integrationsWithData,
                    integrationsData: _vm.integrationsData,
                    currentIntegrationCategory: "Payment Gateway",
                    integrationCategories: _vm.integrationCategories,
                    userData: _vm.userData,
                    websiteUrl: _vm.resultUrl,
                    enableSearch: false
                  },
                  on: {
                    "update:integrationsData": [
                      function($event) {
                        _vm.integrationsData = $event
                      },
                      _vm.saveIntegrationsData
                    ],
                    "update:integrations-data": function($event) {
                      _vm.integrationsData = $event
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "Modal",
              {
                staticClass: "is-whatsup-setup",
                attrs: {
                  value: _vm.whatsAppTemplate,
                  modalName: "Finish setting up the website"
                },
                on: { input: _vm.closeWhatsAppTemplate }
              },
              [
                _c(
                  "div",
                  { attrs: { slot: "modal-content" }, slot: "modal-content" },
                  [
                    _c("div", { staticClass: "modal__about" }, [
                      _c("p", [
                        _vm._v(
                          "This template has add-ons that require configuration. Please click on the add-on card to specify your options. You can finish this customization later by going to the Add-ons section."
                        )
                      ])
                    ])
                  ]
                ),
                _c("IntegrationsManager", {
                  attrs: {
                    singleCentered: "",
                    integrations: _vm.integrationsWithData,
                    integrationsData: _vm.integrationsData,
                    currentIntegrationCategory: "_ALL_",
                    currentIntegrationItems: ["whatsapp"],
                    integrationCategories: _vm.integrationCategories,
                    userData: _vm.userData,
                    websiteUrl: _vm.resultUrl,
                    enableSearch: false
                  },
                  on: {
                    "update:integrationsData": [
                      function($event) {
                        _vm.integrationsData = $event
                      },
                      _vm.saveIntegrationsData
                    ],
                    "update:integrations-data": function($event) {
                      _vm.integrationsData = $event
                    }
                  }
                })
              ],
              1
            )
          ]
        : _vm._e(),
      _vm.$async.getSpreadView.$rejected && !_vm.$async.getSpreadView.$pending
        ? _c(
            "div",
            { staticClass: "manage-error" },
            [
              _vm.error !== null
                ? _c(
                    "ErrorPageComponent",
                    {
                      attrs: {
                        options: {
                          error: _vm.error.status || "OUPS",
                          title: "Something went wrong",
                          link: "/",
                          linkText: "Go back"
                        }
                      }
                    },
                    [
                      _c("p", { staticClass: "errorPage__paragraph" }, [
                        _vm._v(
                          "Some troubles in your content: " +
                            _vm._s(_vm.error.message) +
                            " "
                        )
                      ]),
                      _c("p", { staticClass: "errorPage__paragraph" }, [
                        _vm._v(
                          "Probably you`ve shared an empty table. Try to fill it out with data first."
                        )
                      ]),
                      _c("p", { staticClass: "errorPage__paragraph" }, [
                        _vm._v("Or you can start using our\n      "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.publicAppBaseUrl + "/templates/"
                            }
                          },
                          [_vm._v("Templates")]
                        )
                      ])
                    ]
                  )
                : _c("div", [
                    _c(
                      "div",
                      { staticClass: "container_sheet-error" },
                      [
                        _c("h2", [
                          _vm._v("Failed to get data from your sheet.")
                        ]),
                        _c("p", [
                          _vm._v(
                            "If you are facing this error - your Google Sheet is not available."
                          )
                        ]),
                        !_vm.tableCopyUrl && !_vm.tableCurrentUrl
                          ? _c(
                              "button",
                              {
                                staticClass: "container_sheet-error-button",
                                attrs: {
                                  disabled:
                                    _vm.$async.requestTableCopy.$pending ||
                                    _vm.$async.getViewOptions.$pending
                                },
                                on: { click: _vm.startTroubleshooting }
                              },
                              [
                                _vm.$async.requestTableCopy.$pending ||
                                _vm.$async.getViewOptions.$pending
                                  ? _c("div", { staticClass: "icon-loader" })
                                  : _c("span", [
                                      _vm._v("Start Troubleshooting")
                                    ])
                              ]
                            )
                          : _vm._e(),
                        !_vm.tableCopyUrl && _vm.tableCurrentUrl
                          ? _c(
                              "div",
                              { staticClass: "container_sheet-error-text" },
                              [
                                _c("ul", [
                                  _c("li", [
                                    _vm._v("Open "),
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: _vm.tableCurrentUrl,
                                          target: "_blank"
                                        }
                                      },
                                      [_vm._v("your Google Sheet")]
                                    ),
                                    _vm._v(" and click 'Share' button.")
                                  ]),
                                  _c("li", [
                                    _vm._v(
                                      'Allow the document to be visible to "Anyone with the link"'
                                    )
                                  ]),
                                  _c("li", [
                                    _vm._v("Click the Continue button below.")
                                  ])
                                ])
                              ]
                            )
                          : _vm._e(),
                        _vm.tableCopyUrl
                          ? _c(
                              "div",
                              { staticClass: "container_sheet-error-text" },
                              [
                                _c("ul", [
                                  _c("li", [
                                    _vm._v(" Open "),
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: _vm.tableCopyUrl,
                                          target: "_blank"
                                        }
                                      },
                                      [_vm._v("this")]
                                    ),
                                    _vm._v(
                                      " link and clilck 'Make a copy' button. A new copy will be created."
                                    )
                                  ]),
                                  _c("li", [
                                    _vm._v(
                                      'Allow the new document to be visible to "Anyone with the link"'
                                    )
                                  ]),
                                  _c("li", [_vm._v("Copy the Sheet URL")]),
                                  _c("li", [
                                    _vm._v(
                                      "Paste it to the form below and press Continue."
                                    )
                                  ])
                                ])
                              ]
                            )
                          : _vm._e(),
                        _c("SpreadForm", {
                          attrs: {
                            forAddViewPage: true,
                            initialVal: _vm.tableCurrentUrl
                          },
                          on: {
                            submit: function(val) {
                              return _vm.updateSpreadViewSource({
                                spreadsheetUrl: val,
                                sheetHash: ""
                              })
                            }
                          }
                        })
                      ],
                      1
                    )
                  ])
            ],
            1
          )
        : _vm._e(),
      _vm.$async.getSpreadView.$resolved
        ? [
            _c("TakoverWarning", {
              attrs: {
                "v-if": _vm.takeoverModalVisible,
                visible: _vm.takeoverModalVisible,
                type: _vm.takeoverWarningType,
                currentEditor:
                  _vm.takeoverWarningType === "takeover"
                    ? _vm.takeoverRequestInitiator
                    : _vm.activeEditor
                      ? _vm.activeEditor.firstName +
                        " " +
                        _vm.activeEditor.lastName
                      : "current editor"
              },
              on: {
                "takeover-request": _vm.sendTakeoverRequest,
                "countdown-end": function($event) {
                  _vm.takeoverWarningType === "takeover"
                    ? _vm.approveUnlock
                    : _vm.onTimerEnd
                },
                "reject-takeover": function($event) {
                  return _vm.approveUnlock(false)
                },
                "allow-takeover": _vm.approveUnlock,
                close: _vm.onTakeoverModalClose
              }
            })
          ]
        : _vm._e(),
      _c(
        "Modal",
        {
          attrs: { modalName: _vm.$t("pageManageSheet.modal.titleDelete") },
          model: {
            value: _vm.modalDeleteOpened,
            callback: function($$v) {
              _vm.modalDeleteOpened = $$v
            },
            expression: "modalDeleteOpened"
          }
        },
        [
          _c("div", { staticClass: "delete-modal" }, [
            _c("div", { staticClass: "delete-modal__body" }, [
              _c("div", { staticClass: "delete-modal__field" }, [
                _c(
                  "button",
                  {
                    staticClass: "delete-modal__button negative",
                    on: {
                      click: function($event) {
                        _vm.modalDeleteOpened = false
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("global.cancel")))]
                ),
                _c(
                  "button",
                  {
                    staticClass: "delete-modal__button",
                    on: {
                      click: function($event) {
                        return _vm.deleteCurrentView()
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("global.yes")))]
                )
              ])
            ])
          ])
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }