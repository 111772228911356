<template>
  <div
    class="ui-switcher"
    :class="label ? 'ui-switcher--full-width' : 'ui-switcher--inline'"
  >
    <div
      v-if="label"
      class="ui-switcher__label"
    >
      {{label}}
    </div>
    <label
      :class="{ 'selected': value }"
      class="ui-switcher__trigger"
    >
      <input
        v-model="internalValue"
        class="ui-switcher__trigger-input"
        type="checkbox"
      >
      <span class="ui-switcher__trigger-indicator"></span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'UiSwitcher',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
