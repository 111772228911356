<template>
  <div>
    <Preloader :loading="$async.getSpreadView.$pending || $async.deleteSpreadView.$pending || $async.createSpreadView.$pending || fullscreenPreloader"
      :content="'Loading your website content...'"
    />
    <div
      v-if="$async.getSpreadView.$resolved"
      class="manage"
      >
      <portal to="manage-header-title-editor">
        <TitleEditor
          :value="options.spreadView.name"
          :disabled="$async.saveSpreadView.$pending"
          @input="updateSpreadViewName"
        />
      </portal>
      <portal to="manage-header-subscription-button">
        <UiTag
          size="xxs"
          :color="websiteObject.dealType === 'free' ? 'secondary' : 'success'"
          :label="$options.dealTypeLabels[websiteObject.dealType]"
        />
      </portal>

      <portal to="manage-header-menu">
        <el-dropdown
          v-if="currentWorkspace"
          trigger="click"
          @visible-change="spreadViewSettingsDropdownVisible = !spreadViewSettingsDropdownVisible"
        >
          <UiBtn
            icon-only="im-gear-2"
            type="ghost"
            :highlighted="spreadViewSettingsDropdownVisible"
          />

          <el-dropdown-menu slot="dropdown" style="width: 190px;">
            <p class="pl-12 pr-12 pt-8 mb-4 text--size-xs text--weight-500 text--color-secondary">Manage plans</p>
            <template v-for="(deal, index) in Object.entries(currentWorkspace.ownerLicenses.paidLicenses)">
              <el-dropdown-item
                :key="index"
                :disabled="websiteObject.dealType === deal[0]"
                class="is-wrapper"
                >
                <button @click.prevent="applySpreadViewDeal(deal[0])">
                  Apply {{$options.dealTypeLabels[deal[0]]}}
                </button>
              </el-dropdown-item>
            </template>
            <el-dropdown-item
              v-if="!websiteObject.dealType.toLowerCase().includes('free')"
              class="is-danger is-wrapper"
            >
              <button @click.prevent="applySpreadViewDeal('free')">
                Downgrade to free
              </button>
            </el-dropdown-item>
            <div class="ui-divider mt-2 mb-12"></div>
            <p class="pl-12 pr-12 mb-4 text--size-xs text--weight-500 text--color-secondary">General</p>
            <el-dropdown-item class="is-wrapper">
              <button @click.prevent="duplicateSpreadView()">
                Duplicate
              </button>
            </el-dropdown-item>
            <el-dropdown-item class="is-danger is-wrapper">
              <button @click.prevent="modalDeleteOpened = true">Delete</button>
            </el-dropdown-item>

          </el-dropdown-menu>
        </el-dropdown>
        <button
          :class="{'is-loading': $async.saveSpreadView.$pending }"
          class="btn"
          :disabled="spreadViewerOptionsInvalid"
          @click="tryToPublishSpreadView"
        >
          {{ $t('global.publish') }}
        </button>
      </portal>

      <SpreadViewerOptions
        ref="spreadOptions"
        v-model="options"
        :cols="cols"
        :domain="websiteObject.domain"
        :googleUrl="googleUrl"
        :pwdProtection="pwdProtection"
        :seoIndexing="seoIndexing"
        :integrationsOpened="integrationsOpened"
        :loading="$async.saveSpreadView.$pending || $async.updateSpreadViewSource.$pending || isLoading"
        :integrationCategories="integrationCategories"
        :integrations="integrationsWithData"
        :integrationsData="integrationsData"
        :currentIntegrationCategory.sync="currentIntegrationCategory"
        @save="val => saveSpreadView({ options: val })"
        @reload-preview="reloadPreview()"
        @request-preview-change="setPreviewRoute"
        @google-url-change="val => updateSpreadViewSource({ spreadsheetUrl: val, sheetHash: '' })"
        @update-indexing="val => saveSpreadView({ seoIndexing: val })"
        @update-pwd-protection="val => saveSpreadView({ pwdProtection: val })"
        @open-default-single-item="openDefaultSIngleItem"
        @update-sheet-data="refreshSheetData()"
        @open-payment-addons="paymentsModalOpened = true"
        @disable-payment-addon="saveIntegrationsData"
      />

      <div v-if="integrationsOpened" class="manage__integrations-wrap">
        <IntegrationsManager
          :integrations="integrationsWithData"
          :integrationsData.sync="integrationsData"
          :currentIntegrationCategory="currentIntegrationCategory"
          :integrationCategories="integrationCategories"
          :userData="userData"
          :websiteUrl="resultUrl"
          @update:integrationsData="saveIntegrationsData"
        />
      </div>
      <keep-alive>
        <div v-show="!integrationsOpened" class="manage__viewer-side">
          <div
            class="manage__viewer-wrap"
            :class="`is-${previewType}-view`"
          >
            <div class="viewer-bar" :class="`is-${previewType}-view`">
              <div class="viewer-bar__circles">
                <div v-for="item in 3" :key="item" class="viewer-bar__circle" />
              </div>
              <div class="viewer-bar__field"
              >
                <div class="viewer-bar__input-wrap">
                  <a v-if="options.spreadView.isPublished"
                  :href="resultUrl"
                  target="_blank"
                  class="viewer-bar__input-prepend"
                  >{{resultUrl}}</a>
                  <a
                    v-else
                    href=""
                    class="viewer-bar__input-prepend"
                    @click.prevent="$root.$emit('openDomainSettings')">
                      <span
                        :class="{ 'viewer-bar__input-prepend--blur': !options.spreadView.isPublished }"
                      >
                      {{resultUrl}}
                    </span>
                  </a>
                </div>
                <transition name="fade">
                  <button class="viewer-bar__submit" @click="$root.$emit('openDomainSettings')">
                    Change domain
                  </button>
                </transition>
              </div>
              <div class="viewer-bar__switchers">
                <label
                  v-for="item in previewTypes"
                  :key="item"
                  :class="{'is-active': item === previewType}"
                  class="viewer-bar__switcher"
                >
                  <input v-model="previewType" type="radio" :value="item">
                  <i class="viewer-bar__switcher-icon" :class="`icon-preview-${item}`"></i>
                </label>
              </div>
            </div>
            <iframe :src="viewerBaseUrl+'?_preview_mode=1'+'&_website_id='+id" style="width:100%; height:100%;" frameborder="0" data-openreplay-capture allow="clipboard-write"></iframe>
          </div>
        </div>
      </keep-alive>
      <FullscreenMdEditor v-if="false" />
    </div>

    <template v-if="$async.getSpreadView.$resolved">
      <PublishModal
        v-if="publishModalOpened"
        v-model="publishModalOpened"
        :publishingInProgress="publishingInProgress"
        :resultUrl="resultUrl"
      />
      <Modal
        v-model="paymentsModalOpened"
        modalName="Payment methods"
      >
        <IntegrationsManager
          modalView
          :integrations="integrationsWithData"
          :integrationsData.sync="integrationsData"
          :currentIntegrationCategory="'Payment Gateway'"
          :integrationCategories="integrationCategories"
          :userData="userData"
          :websiteUrl="resultUrl"
          :enableSearch="false"
          @update:integrationsData="saveIntegrationsData"
        />
      </Modal>

      <Modal
        :value="whatsAppTemplate"
        class="is-whatsup-setup"
        modalName="Finish setting up the website"
        @input="closeWhatsAppTemplate"
      >
        <div slot="modal-content">
          <div class="modal__about">
            <p>This template has add-ons that require configuration. Please click on the add-on card to specify your options. You can finish this customization later by going to the Add-ons section.</p>
          </div>
        </div>
        <IntegrationsManager
          singleCentered
          :integrations="integrationsWithData"
          :integrationsData.sync="integrationsData"
          :currentIntegrationCategory="'_ALL_'"
          :currentIntegrationItems="['whatsapp']"
          :integrationCategories="integrationCategories"
          :userData="userData"
          :websiteUrl="resultUrl"
          :enableSearch="false"
          @update:integrationsData="saveIntegrationsData"
        />
      </Modal>
    </template>
    <div
      v-if="$async.getSpreadView.$rejected && !$async.getSpreadView.$pending"
      class="manage-error"
      >
      <ErrorPageComponent
        v-if="error !== null"
        :options="{
          error: error.status || 'OUPS',
          title: 'Something went wrong',
          link: '/',
          linkText: 'Go back'
        }"
      >
      <p class="errorPage__paragraph">Some troubles in your content: {{error.message}} </p>
      <p class="errorPage__paragraph">Probably you`ve shared an empty table. Try to fill it out with data first.</p>
      <p class="errorPage__paragraph">Or you can start using our
        <a :href="`${publicAppBaseUrl}/templates/`">Templates</a>
      </p>

      </ErrorPageComponent>
      <div v-else>
        <div class="container_sheet-error">
          <h2>Failed to get data from your sheet.</h2>
          <p>If you are facing this error - your Google Sheet is not available.</p>

          <button v-if="!tableCopyUrl && !tableCurrentUrl" class="container_sheet-error-button"
            :disabled="$async.requestTableCopy.$pending || $async.getViewOptions.$pending"
            @click="startTroubleshooting"
          >
            <div v-if="$async.requestTableCopy.$pending || $async.getViewOptions.$pending" class="icon-loader"></div>
            <span v-else>Start Troubleshooting</span>
          </button>

          <div v-if="!tableCopyUrl && tableCurrentUrl"  class="container_sheet-error-text">
            <ul>
              <li>Open <a :href="tableCurrentUrl" target="_blank">your Google Sheet</a> and click 'Share' button.</li>
              <li>Allow the document to be visible to "Anyone with the link"</li>
              <li>Click the Continue button below.</li>
            </ul>
          </div>
          <div v-if="tableCopyUrl"  class="container_sheet-error-text">
            <ul>
              <li> Open <a :href="tableCopyUrl" target="_blank">this</a> link and clilck 'Make a copy' button. A new copy will be created.</li>
              <li>Allow the new document to be visible to "Anyone with the link"</li>
              <li>Copy the Sheet URL</li>
              <li>Paste it to the form below and press Continue.</li>
            </ul>
          </div>

        <SpreadForm
          :forAddViewPage="true"
          :initialVal="tableCurrentUrl"
          @submit="val => updateSpreadViewSource({ spreadsheetUrl: val, sheetHash: '' })"
        />
      </div>
        </div>
    </div>
    <template v-if="$async.getSpreadView.$resolved">
      <TakoverWarning
        :v-if="takeoverModalVisible"
        :visible="takeoverModalVisible"
        :type="takeoverWarningType"
        :currentEditor="takeoverWarningType === 'takeover' ? takeoverRequestInitiator : (activeEditor ? `${activeEditor.firstName} ${activeEditor.lastName}` : 'current editor')"
        @takeover-request="sendTakeoverRequest"
        @countdown-end="takeoverWarningType === 'takeover' ? approveUnlock : onTimerEnd"
        @reject-takeover="approveUnlock(false)"
        @allow-takeover="approveUnlock"
        @close="onTakeoverModalClose"
      />
    </template>
    <Modal
      v-model="modalDeleteOpened"
      :modalName="$t('pageManageSheet.modal.titleDelete')"
      >
      <div class="delete-modal">
        <div class="delete-modal__body">
          <div class="delete-modal__field">
            <button class="delete-modal__button negative" @click="modalDeleteOpened = false">{{ $t('global.cancel') }}</button>
            <button class="delete-modal__button" @click="deleteCurrentView()">{{ $t('global.yes') }}</button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import api from '@/services/api/'
import PublishModal from '@/components/publish-modal'
import spreadViewData from '@/services/api/spread-view-data'
import SpreadViewerOptions from '@/components/spread-viewer-options'
import TitleEditor from '@/components/title-editor/'
import Modal from '@/components/modal'
import Vue from 'vue'
import ErrorPageComponent from 'error-page-component'
import VueInputAutowidth from 'vue-input-autowidth'
import FullscreenMdEditor from '@/components/fullscreen-md-editor'
import { mapColumns } from '@/modules/column-mapper'
import { checkColumns } from 'spreadview-options'
import { mapState, mapGetters, mapActions } from 'vuex'
import postRobot from 'post-robot'
import { getIntegrationsCategories, getIntegrationsWithData } from 'integrations'
import IntegrationsManager from '@/components/integrations-manager'
import { sheetContentPipelineWrite, sheetContentRoutesWrite, createEncodedWriteOptions } from 'google-sheets-data'
import defaultFieldsSchema from '@/components/form-builder/defaultFieldsSchema.js'
import { setInitialTrackingData } from '@/modules/utils/featureStatusTracker'
import TakoverWarning from '@/components/takover-warning'
import { initSocket } from '@/modules/sockets.js'
import SpreadForm from '@/components/spread-form'
import UiBtn from '@/components/ui-btn'
import UiTag from '@/components/ui-tag'
import { dealTypeLabels } from '../../modules/utils/dealTypeLabels'
// eslint-disable-next-line
const punycode = require('punycode')

Vue.use(VueInputAutowidth)
export default {
  name: 'ManageSheetPage',
  dealTypeLabels,
  metaInfo: {
    title: 'Manage'
  },
  components: {
    SpreadViewerOptions,
    TitleEditor,
    Modal,
    ErrorPageComponent,
    FullscreenMdEditor,
    IntegrationsManager,
    PublishModal,
    TakoverWarning,
    SpreadForm,
    UiBtn,
    UiTag
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      error: null,
      googleUrl: null,
      pwdProtection: {},
      cols: [],
      options: {},
      publishModalOpened: false,
      paymentsModalOpened: false,
      seoIndexing: true,
      appMode: process.env.NODE_ENV,
      publicAppBaseUrl: process.env.VUE_APP_PUBLIC_APP_URL,
      isVisibleSingleItem: false,
      viewerBaseUrl: process.env.VUE_APP_VIEWER_APP_BASE_URL,
      integrationsData: [],
      integrationCategories: getIntegrationsCategories({ ignorePublished: this.$ft.INTEGRATIONS_WIP }),
      currentIntegrationCategory: '_ALL_',
      publishingInProgress: false,
      whatsAppTemplate: false,
      initFromTemplate: undefined,
      takeoverRequestInitiator: '',
      socket: null,
      activeEditor: '',
      takeoverModalVisible: false,
      takeoverWarningType: '',
      tableCopyUrl: undefined,
      tableCurrentUrl: undefined,
      websiteObject: null,
      singleItemCurrentRoute: '',
      singleItemBaseRoute: '',
      isLoading: false,
      previewTypes: [
        'desktop',
        'mobile'
      ],
      previewType: 'desktop',
      spreadViewSettingsDropdownVisible: false,
      modalDeleteOpened: false,
      fullscreenPreloader: false,
      iframeFirstRunDone: false
    }
  },
  computed: {
    resultUrl () {
      let viewerUrl
      if (this.websiteObject.customDomain && this.websiteObject.customDomain !== '') {
        viewerUrl = 'https://' + this.websiteObject.customDomain
      } else if (this.appMode === 'development') {
        viewerUrl = process.env.VUE_APP_VIEWER_APP_LOCAL_DOMAIN + '?_host=' + this.websiteObject.domain
      } else {
        viewerUrl = 'https://' + this.websiteObject.domain
      }
      return viewerUrl
    },

    integrationsWithData () {
      return getIntegrationsWithData(this.integrationsData, { secrets: true, ignorePublished: this.$ft.INTEGRATIONS_WIP })
    },

    ...mapState('app', [
      'userData',
      'integrationsOpened',
      'currentWorkspaceId',
      'workspaces',
      'websiteAccess'
    ]),
    ...mapGetters('app', [
      'currentWorkspace',
      'startWebsocketConnection',
      'spreadViewerOptionsInvalid'
    ])
  },
  watch: {
    options: {
      deep: true,
      handler (val) {
        this.websiteObject.options = val
        this.updatePreviewData()
      }
    },
    integrationsData: {
      deep: true,
      handler (val) {
        this.websiteObject.integrations = val
        this.updatePreviewData()
      }
    }
  },
  methods: {
    ...mapActions('app', [
      'fetchWorkspaces',
      'updateWebsiteAccessRules',
      'setDefaultWebsiteAccessState',
      'updateSpreadViewDeal'
    ]),
    updateDomainSettings (payload) {
      this.websiteObject.domain = payload.domain
      this.websiteObject.customDomain = payload.customDomain
    },
    async applySpreadViewDeal (dealType) {
      this.isLoading = true
      try {
        const res = await this.updateSpreadViewDeal({
          id: this.id,
          dealType: dealType
        })
        if (res) {
          location.reload()
        }
      } catch (_) {} finally {
        this.isLoading = false
      }
    },
    async startTroubleshooting () {
      this.$async.requestTableCopy.$perform()
        .then(response => {
          let sheetLink = response.link
          sheetLink = sheetLink.split('https://docs.google.com/spreadsheets/d/')[1]
          this.tableCopyUrl = `https://docs.google.com/spreadsheets/u/0/d/${sheetLink}`
        })
        .catch(() => {
          this.$async.getViewOptions.$perform()
            .then(r => {
              this.tableCurrentUrl = r.spreadsheetUrl
            })
        })
    },

    closeWhatsAppTemplate () {
      this.whatsAppTemplate = false
      this.$trackEvent({
        'event': 'initialAddonPopup',
        'popupEventData': {
          addonName: 'whatsApp',
          clickType: 'close'
        }
      })
    },

    setCheckoutFieldsIfEmpty () {
      if (!this.options.checkout.fields.length) {
        this.options.checkout.fields = defaultFieldsSchema.schema
        this.saveSpreadView({
          options: this.options
        })
      }
    },

    getSpreadViewData () {
      this.error = null
      this.$async.getSpreadView.$perform()
        .then(({ data, cols }) => {
          if (process.env.NODE_ENV === 'development') {
            document.cookie = `spreadViewDomain=${data.domain}; domain=localhost`
          }
          this.websiteObject = data

          this.$store.commit('app/set', { key: 'currentSpreadViewData', value: data })
          this.$store.commit('app/set', { key: 'currentSpreadViewId', value: data.id })

          if (data.isArchived) {
            this.$router.push({ name: 'spread-views', query: { isOverlimit: true } })
          }
          this.googleUrl = data.spreadsheetUrl
          this.pwdProtection = data.pwdProtection
          this.cols = cols
          this.options = this.setUserEmailAsFormEmail(this.userData.email, checkColumns(cols, data.options))
          this.ssl = data.ssl
          this.seoIndexing = (data.seoIndexing !== undefined) ? data.seoIndexing : true
          this.singleItemCurrentRoute = data.options.viewer.singleItemOptions.url.id
          this.singleItemBaseRoute = data.options.viewer.singleItemOptions.baseRoute
          this.autoMapColumns()
          this.integrationsData = data.integrations
          if (data.accessRules) {
            data.accessRules.created = true
            this.updateWebsiteAccessRules(data.accessRules)
          } else {
            this.setDefaultWebsiteAccessState()
          }
          this.setCheckoutFieldsIfEmpty()
          if (this.initFromTemplate === 'food-delivery') {
            this.whatsAppTemplate = true
          }
          if (this.initFromTemplate === 'marketing-books') {
            const initPwdProtection = {
              enabled: true,
              passwords: ['demo'],
              cardsOnly: true,
              message: `## Members only 🔐\n\n### Please enter a password to get access to the restricted content\n\n💡 Password: '**demo**'`
            }
            this.saveSpreadView({ pwdProtection: initPwdProtection })
            this.pwdProtection = initPwdProtection
          }
          setInitialTrackingData(data)
        })
        .catch(error => {
          this.error = JSON.parse(error.message)
        })
    },

    async refreshSheetData () {
      this.error = null
      this.isLoading = true
      try {
        const data = await this.warmUpSheetData(this.websiteObject, true)
        if (data.statusCode === 200 || data.statusCode === 201 || data.statusCode === 408) {
          await this.getSpreadViewData()
        }
      } catch (error) {
        this.error = JSON.parse(error.message)
        this.isLoading = false
      } finally {
        this.isLoading = false
      }
    },

    setUserEmailAsFormEmail (email, options) {
      if (!options.emailOptions.notificationsEmailTo) {
        options.emailOptions.notificationsEmailTo = email
      }
      if (!options.emailOptions.receiptReplyToEmail) {
        options.emailOptions.receiptReplyToEmail = email
      }
      return options
    },

    autoMapColumns  () {
      if (!this.options.autoMapping.requiresAutoMapping) return

      const mapped = mapColumns(
        this.cols,
        [
          { name: 'title', type: 'single' },
          { name: 'subtitle', type: 'single' },
          { name: 'content', type: 'single' },
          { name: 'price', type: 'single' },
          { name: 'image', type: 'multiple' }
        ],
        {
          extractInput: c => c.label,
          extractOutput: c => c.id
        }
      )

      Object.keys(this.options.viewer.tileOptions).forEach((key) => {
        const mappedCol = mapped.find(v => v.name === key && v.value)
        if (!mappedCol) return
        const idProp = mappedCol.type === 'single' ? 'id' : 'ids'
        this.options.viewer.tileOptions[key][idProp] = mappedCol.value
      })

      Object.keys(this.options.viewer.singleItemOptions).forEach((key) => {
        const mappedCol = mapped.find(v => v.name === key && v.value)
        if (!mappedCol) return
        const idProp = mappedCol.type === 'single' ? 'id' : 'ids'
        this.options.viewer.singleItemOptions[key][idProp] = mappedCol.value
      })

      this.options.autoMapping.requiresAutoMapping = false
    },

    async tryToPublishSpreadView () {
      this.options.spreadView.isPublished = true
      this.publishModalOpened = true
      this.publishingInProgress = true

      this.$trackEvent({
        'event': 'publishWebsite',
        'domain': this.websiteObject.domain,
        'customDomain': this.websiteObject.customDomain
      })

      const spreadViewData = await this.saveSpreadView({
        options: this.options
      }, '?updateCoverImage=true')
      this.warmUpSheetData(spreadViewData)
      this.publishingInProgress = false
    },

    async warmUpSheetData (spreadViewData, shouldAwait = false) {
      return sheetContentPipelineWrite({
        spreadViewData,
        sid: spreadViewData.sid,
        shouldAwait: shouldAwait
      })
    },

    async warmUpRoutesData (spreadViewData) {
      await sheetContentRoutesWrite({
        spreadViewData,
        sid: spreadViewData.sid
      })
      this.reloadPreview()
    },

    saveSpreadView (val, queryString = '') {
      return this.$async.saveSpreadView.$perform(val, queryString)
        .then((spreadViewData) => {
          this.$toasted.show('Website was updated successfully', {
            theme: 'toasted-primary',
            position: 'top-center',
            type: 'success',
            duration: 1500
          })
          if (val.options &&
          val.options.viewer &&
          val.options.viewer.enableSingleItemView &&
          (this.singleItemCurrentRoute !== val.options.viewer.singleItemOptions.url.id ||
          this.singleItemBaseRoute !== val.options.viewer.singleItemOptions.baseRoute)
          ) {
            this.warmUpRoutesData(spreadViewData)
          }
          this.singleItemCurrentRoute = spreadViewData.options.viewer.singleItemOptions.url.id
          this.singleItemBaseRoute = spreadViewData.options.viewer.singleItemOptions.baseRoute
          return spreadViewData
        })
        .catch(() => {
          this.$toasted.show('Error, try again', {
            theme: 'toasted-primary',
            position: 'top-center',
            type: 'error',
            duration: 1500
          })
        })
    },

    updateSpreadViewName (name) {
      const oldName = this.options.spreadView.name
      const updatedName = {
        options: {
          spreadView: {
            name
          }
        }
      }
      this.$async.saveSpreadView.$perform(updatedName)
        .then(() => {
          this.options.spreadView.name = name
          this.$toasted.show('Website title was changed successfully', {
            theme: 'toasted-primary',
            position: 'top-center',
            type: 'success',
            duration: 1500
          })
        })
        .catch(() => {
          this.options.spreadView.name = oldName
          this.$toasted.show('Error, try again', {
            theme: 'toasted-primary',
            position: 'top-center',
            type: 'error',
            duration: 1500
          })
        })
    },

    updateSpreadViewSource (val) {
      this.$async.saveSpreadView.$perform(val)
        .then(async (response) => {
          this.iframeFirstRunDone = false
          await this.warmUpSheetData(response, true)
          await this.getSpreadViewData()
          this.$toasted.show('Website source was changed successfully', {
            theme: 'toasted-primary',
            position: 'top-center',
            type: 'success',
            duration: 1500
          })
        })
        .catch(() => {
          this.$toasted.show('Error, try again', {
            theme: 'toasted-primary',
            position: 'top-center',
            type: 'error',
            duration: 1500
          })
        })
    },

    openDefaultSIngleItem (val) {
      if (val === this.isVisibleSingleItem) return
      this.isVisibleSingleItem = val
      this.updatePreviewData()
    },

    updatePreviewData (firstRun) {
      if (firstRun) {
        this.iframeFirstRunDone = firstRun
      }
      if (!this.iframeFirstRunDone) return

      if (this.websiteAccess) {
        this.websiteObject.accessRules = this.websiteAccess
      }
      const data = {
        spreadViewData: {
          ...this.websiteObject,
          isVisibleSingleItem: this.isVisibleSingleItem
        }
      }
      if (this.$options.updatePreview && !firstRun) {
        this.$options.updatePreview(data)
      }
      return data
    },

    setPreviewRoute (route) {
      if (!this.$options.setPreviewRoute) return
      this.$options.setPreviewRoute(route)
    },

    previewInit ({ data }) {
      this.$options.updatePreview = data.updatePreview
      this.$options.setPreviewRoute = data.setPreviewRoute
      this.$options.reloadPreview = data.reloadPreview
      return this.updatePreviewData(true)
    },

    reloadPreview () {
      setTimeout(() => {
        this.$options.reloadPreview()
      }, 2000)
    },

    saveIntegrationsData () {
      return this.saveSpreadView({
        integrations: this.integrationsData
      })
    },

    savePwdProtectionData () {
      return this.saveSpreadView({
        pwdProtection: this.pwdProtection
      })
    },

    toggleTakeoverModal (type, open = true) {
      this.takeoverWarningType = type
      this.takeoverModalVisible = open
    },

    onTakeoverModalClose () {
      switch (this.takeoverWarningType) {
        case 'takeover':
          return this.approveUnlock(false)
        case 'warning':
          this.$router.push({ name: 'spread-views' })
          break
        case 'takeover-fail':
          this.$router.push({ name: 'spread-views' })
          break
        case 'takeover-decline':
          this.$router.push({ name: 'spread-views' })
          break
        default:
          this.takeoverModalVisible = false
          break
      }
    },

    approveUnlock (approve = true) {
      this.socket.emit('spreadview:approveUnlock', ({
        unlock: approve
      }), response => {})
      this.takeoverRequestInitiator = ''
      this.toggleTakeoverModal('noType', false)
      if (approve) {
        this.socket.disconnect()
        this.$router.push({ name: 'spread-views' })
      }
    },

    sendTakeoverRequest () {
      this.socket.emit('spreadview:requestUnlock', ({
        userId: this.userData.id,
        firstName: this.userData.firstName,
        lastName: this.userData.lastName
      }), (response) => {
        if (response.status === 200) {
          this.toggleTakeoverModal('countdown')
        } else if (response.msg === 'SpreadView is free access') {
          this.lockWebsite()
          this.toggleTakeoverModal('takeover-success')
        } else {
          this.toggleTakeoverModal('takeover-fail')
        }
      })
    },

    onTimerEnd () {
      this.toggleTakeoverModal('takeover-fail')
    },

    lockWebsite () {
      this.socket.emit('spreadview:createLock', ({
        userId: this.userData.id,
        firstName: this.userData.firstName,
        lastName: this.userData.lastName
      }), response => {
        if (response.status === 400) {
          this.activeEditor = {
            firstName: response.payload.currentEditor.firstName,
            lastName: response.payload.currentEditor.lastName
          }
          this.toggleTakeoverModal('warning')
        }
      })
    },

    initSocketChannel () {
      // INIT SOCKETS
      this.socket = initSocket({
        path: `/spreadview-${this.id}`,
        state: { spreadViewId: this.id },
        onConnect: () => {
          this.socket.on('edit', (data) => {
            if ('unlockRequestedBy' in data) {
              if (data.unlockRequestedBy.id && data.unlockRequestedBy.id !== this.userData.id) {
                this.takeoverRequestInitiator = `${data.unlockRequestedBy.firstName} ${data.unlockRequestedBy.lastName}`
                this.toggleTakeoverModal('takeover')
              }
            } else if ('unlockApproved' in data) {
              if (data.unlockApproved) {
                this.$router.push({ name: 'spread-views' })
              } else {
                this.toggleTakeoverModal('noType', false)
              }
            }
          })
          this.socket.on(this.socket.id, (data) => {
            if (data.unlockApproved) {
              this.lockWebsite()
              this.toggleTakeoverModal('takeover-success')
            } else {
              this.toggleTakeoverModal('takeover-decline')
            }
          })
        },
        onDestroy: () => {
          this.socket.off(this.socket.id)
          this.$router.push({ name: 'spread-views' })
        }
      })
      this.lockWebsite()
      // END INIT SOCKETS
    },

    async duplicateSpreadView () {
      const item = JSON.parse(JSON.stringify(this.websiteObject))
      item.options.spreadView.name = `Copy of ${item.options.spreadView.name}`
      this.fullscreenPreloader = true
      try {
        const response = await this.$async.createSpreadView.$perform({
          spreadsheetUrl: item.spreadsheetUrl,
          options: item.options,
          integrations: item.integrations,
          encodedOptions: createEncodedWriteOptions(item.options)
        })
        // if item has accessRules -> create access rules for new website
        if (item.accessRules && item.accessRules.spreadsheetUrl) {
          await api.createWebsiteAccessRules(response.id, item.accessRules)
        }
        this.$trackEvent({
          'event': 'createWebsite',
          'creationType': 'duplicate',
          'domain': response.domain
        })
        this.$router.replace(
          { name: 'manage-sheet-page', params: { id: response.id } },
          () => {
            this.$router.go(0)
          })
      } catch ({ response }) {
        this.$toasted.show(response.data.reason, {
          theme: 'toasted-primary',
          position: 'top-center',
          type: 'error',
          duration: 1500
        })
        this.fullscreenPreloader = false
      }
    },

    async deleteCurrentView () {
      this.modalDeleteOpened = false
      this.$async.deleteSpreadView.$perform(this.id)
        .then(() => {
          this.$trackEvent({
            'event': 'deleteWebsite',
            'websiteId': this.id
          })
        })
        .then(response => {
          this.$router.push({ name: 'spread-views' })
        })
        .catch(err => console.warn(err.message))
    }
  },

  asyncOperations: {
    getViewOptions () {
      return api.getView(this.id)
    },
    requestTableCopy () {
      return api.requestSheetCopy(this.id)
    },
    getSpreadView () {
      return spreadViewData(this.id)
    },

    updateSpreadViewSource () {
      return spreadViewData(this.id)
    },

    saveSpreadView (val, queryString = '') {
      return api.updateView(this.id, val, queryString)
    },

    createSpreadView (url) {
      return api.createView(url)
    },

    deleteSpreadView (id) {
      return api.deleteView(id)
    }
  },

  created () {
    if (localStorage.getItem('initTemplateName')) {
      this.initFromTemplate = localStorage.getItem('initTemplateName')
      localStorage.removeItem('initTemplateName')
    }

    this.$store.commit('app/set', { key: 'integrationsOpened', value: false })
    this.getSpreadViewData()

    this.$root.$on('update-domain-settings', this.updateDomainSettings)
  },

  async mounted () {
    if (this.$options.ssPreviewInitListener) {
      this.$options.ssPreviewInitListener.cancel()
    }
    this.$options.ssPreviewInitListener = postRobot.on('ssPreviewInit', this.previewInit)

    if (!this.workspaces) {
      await this.fetchWorkspaces()
    }
    if (this.startWebsocketConnection) {
      this.initSocketChannel()
    }
  },

  beforeDestroy () {
    if (this.socket) {
      this.socket.disconnect()
    }

    if (this.$options.ssPreviewInitListener) {
      this.$options.ssPreviewInitListener.cancel()
    }
    this.$store.commit('app/set', { key: 'currentSpreadViewData', value: null })
    this.$store.commit('app/set', { key: 'currentSpreadViewId', value: undefined })

    this.$root.$off('update-domain-settings')
  }
}
</script>
