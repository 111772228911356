<template>
  <vue-select
    v-model="selectedValue"
    :options="options"
    :searchable="false"
    :class="{'clearless': clearable}"
    :placeholder="placeholderValue"
    transition="fade"
  ></vue-select>
</template>
<script>
import equal from 'deep-equal'
export default {
  name: 'Select',
  props: {
    options: {
      type: Array,
      required: true
    },
    value: {},
    clearable: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      selectedValue: null,
      placeholderValue: 'Sort by'
    }
  },
  watch: {
    value (value) {
      this.setSelectedValue(value)
    },
    selectedValue: {
      deep: true,
      handler (val) {
        if (!val) {
          this.$emit('input', null)
        } else {
          this.$emit('input', val.value)
        }
      }
    }
  },
  methods: {
    setSelectedValue (value) {
      this.selectedValue = this.options.find(option => equal(option.value, value))
    }
  },
  created () {
    this.setSelectedValue(this.value)
  }
}
</script>
