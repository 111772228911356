const commonPrefixes = ['item', 'product', 'service', 'deal']
const commonSuffixes = ['1', '2', '3']

const dicts = {
  title: {
    name: 'title',
    prefixes: [...commonPrefixes, 'main', 'first'],
    bases: [
      'title', 'header', 'caption', 'name', 'heading', 'headline', 'label', 'second name', 'product', 'task'
    ],
    suffixes: [...commonSuffixes]
  },
  subtitle: {
    prefixes: [...commonPrefixes],
    bases: [
      'secondary title', 'secondary header', 'second name', 'subtitle', 'subheading', 'subhead'
    ],
    suffixes: [...commonSuffixes]
  },
  price: {
    prefixes: [...commonPrefixes, '$'],
    bases: [
      '$', 'price', 'amount', 'bill', 'cost', 'estimated', 'estimated rate', 'expense', 'fare', 'fee', 'pay', 'payment', 'rate', 'tariff'
    ],
    suffixes: ['$']
  },
  content: {
    prefixes: [...commonPrefixes],
    bases: [
      'description', 'specification', 'definition', 'explanation', 'interpretation', 'content', 'contents',
      'text', 'excerpt', 'about', 'bio', 'details', 'detailed', 'article', 'data', 'meaning'
    ],
    suffixes: [...commonSuffixes]
  },
  image: {
    prefixes: [...commonPrefixes],
    bases: [
      'image', 'main image', 'image 1', 'picture', 'figure', 'photo', 'main photo', 'thumbnail', 'photography',
      'picture', 'icon', 'avatar', 'portrait', 'face', 'person', 'view', 'screenshot', 'screen', 'snapshot', 'art', 'artwork'
    ],
    suffixes: [...commonSuffixes, 'url', 'png', 'jpg']
  },
  specs: {
    prefixes: [...commonPrefixes],
    bases: [
      'no', 'author', 'promo code', 'art no', 'number', 'width', 'height', 'size', 'sizes',
      'SKU', 'color', 'ASIN', 'model', 'brand', 'manufacturer', 'rank', 'rating', 'seller', 'retailer', 'supplier',
      'age', 'company', 'dimensions', 'speed', 'type', 'system', 'model number', 'series', 'interface',
      'date', 'dates'
    ],
    suffixes: [...commonSuffixes, 'no', 'id']
  }
}

const dictsObj = Object.entries(dicts).reduce((acc, [dictName, dict]) => {
  acc[dictName] = [
    ...dict.bases,
    ...dict.bases.reduce((merges, base) => {
      merges.push(...dict.prefixes.map(prefix => `${prefix} ${base}`))
      return merges
    }, []),
    ...dict.bases.reduce((merges, base) => {
      merges.push(...dict.suffixes.map(suffix => `${base} ${suffix}`))
      return merges
    }, [])
  ]
  return acc
}, {})

export default dictsObj
