<template>
  <div
    class="n-form-field"
    :class="{'without-label': !label && !$slots.label }"
  >
    <div
      v-if="$slots.label || label"
      class="n-form-field__label"
      :class="{
        'required-field': required,
        'error': messages.length,
      }"
    >
      <slot name="label">{{ label }}</slot>
    </div>

    <div
      class="n-form-field__control"
      :class="{ 'error': messages.length }"
    >
      <slot/>
    </div>

    <p v-if="messages.length" class="n-form-field__error-message"> {{ messages.join(', ') }} </p>

    <p v-if="hint" class="n-form-field__hint" v-html="hint"></p>

  </div>
</template>
<script>
export default {
  name: 'FormField',
  props: {
    label: {
      type: String,
      default: null
    },
    hint: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    messages: {
      type: Array,
      default: () => []
    }
  }
}
</script>
