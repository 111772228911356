export default {
  published: true,
  pro: true,
  featureRestrictionsGroup: 'chatIntegrations',
  name: 'active-chat',
  title: 'ActiveChat',
  headline: '',
  logoUrl: '/img/integrations/active-chat.png',
  // coverUrl: '/img/integrations/active-chat-cover.png',
  bgColor: '#2748F5',
  categories: ['Chat'],
  comingSoon: false,
  builtIn: false,
  url: 'https://activechat.ai',
  helpUrl: '',
  description: `
  Improve and expand the self-service segment with smart virtual assistants that speak human language and constantly improve.

  Find your Bot ID inside your ActiveChat account:

  ![](https://spreadsimple.s3.us-east-2.amazonaws.com/6a0c088f-d1bb-4bde-ac5d-23ff7b41e7c0_active-chatpng.png)
  `,

  formFields: [
    {
      name: 'enabled',
      type: 'toggle',
      label: 'Enabled',
      default: false,
      validations: [
        'required'
      ],
      secret: false
    },
    {
      name: 'id',
      type: 'text',
      showIf: ['enabled'],
      label: 'Bot ID',
      default: '',
      placeholder: '6be52xxx-xxxx-433c-951b-c06b183e3cab',
      validations: [
        'required',
        'minLength:4',
        'maxLength:40'
      ],
      secret: false
    }
  ],

  actions: [
    {
      type: 'injectToBodyEnd',
      run (formData) {
        return `
        <script>"use strict";
        document.addEventListener("ssViewerRendered", function () {
          if (!document.getElementById('ACCW_EMBED')) {
            var script = document.createElement('script');
            script.src = 'https://app.activechat.ai/gateway/script/${formData.id}';
            script.id = 'ACCW_EMBED';
            document.getElementsByTagName('head')[0].appendChild(script);
          }
        });</script>
        `
      }
    }
  ]
}
