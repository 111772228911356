export const providersWithLabels = [
  {
    label: 'Magic Link',
    value: 'magic-link'
  },
  {
    label: 'Auth0',
    value: 'auth0'
  }
]

export function useProviderAsObject (array) {
  return array.map(item => providersWithLabels.find(p => p.value === item))
}
