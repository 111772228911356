<template>
  <div>
    <div class="billing">
      <h4 class="billing__title">Billing settings</h4>
      <div class="billing__body">
      <div class="billing__grid-wrap">
        <div
          v-if="userActiveDealsByType"
          class="billing-grid"
        >
          <div class="billing-grid__head">
            <div class="billing-grid__cell is-heading">Plan</div>
            <div class="billing-grid__cell is-heading">Licenses</div>
            <div class="billing-grid__cell is-heading"></div>
            <div class="billing-grid__cell is-heading"></div>
          </div>
          <div
            v-for="(type, index) in Object.entries(userDealsExcludeAppsumo)"
            :key="index"
            class="billing-grid__body"
          >
            <div
              v-for="(deal, index) in type[1]"
              :key="index"
              class="billing-grid__row"
            >
            <div class="billing-grid__cell is-bold">
              <span>
                <span :class="dealCancelled(deal) ? 'text-color-secondary' : ''">
                  {{ deal.label }}</span>
                <span v-if="deal.quantity > 1" :class="dealCancelled(deal) ? 'text-color-secondary' : 'text-color-primary'">
                  x{{deal.quantity}}</span>
              </span>
              </div>
              <div class="billing-grid__cell is-bold">
                {{ deal.licensesQuantity }}
              </div>
              <div class="billing-grid__cell">
                <p v-if="dealIsActive(deal)" class="size-s">
                  Next payment {{dealExpirationDate(deal.nextBillDate)}}
                </p>
                <p v-if="dealCancelled(deal)" class="size-s text-color-danger">
                  Subscription will work until {{dealExpirationDate(deal.nextBillDate)}}
                </p>
                <p v-if="dealOnTrial(deal)" class="size-s">
                  Trial will last until {{dealExpirationDate(deal.nextBillDate)}}
                </p>
              </div>

              <div
                class="billing-grid__cell align-end gap-x"
              >
                <UiBtn
                  v-if="dealIsActive(deal)"
                  label="Change plan"
                  size="xs"
                  uppercase
                  @click="onSwitchPlan(deal)"
                />
                <UiTag
                  v-if="dealOnTrial(deal)"
                  label="Trial"
                  size="xs"
                />
                <UiTag
                  v-if="dealCancelled(deal)"
                  label="Cancelled"
                  color="secondary"
                  size="xs"
                />

                <el-dropdown
                  v-if="!dealCancelled(deal) && showDealControls(deal)"
                  trigger="click"
                  :arrow-visible="false"
                  @visible-change="val => tableItemDropdownOpened = val"
                >
                  <el-dropdown-menu slot="dropdown" class="billing-grid__row-menu">
                    <el-dropdown-item v-if="!dealOnTrial(deal)">
                      <button @click="onUpdateSeats(deal)">Update Quantity</button>
                    </el-dropdown-item>
                    <el-dropdown-item v-if="deal.period === 'month' && !dealOnTrial(deal)">
                      <button @click="onUpdatePlanToAnnual(deal)">
                        Switch to Annual Plan <span v-if="hasAnnualDealDiscount">with 25% discount</span>
                      </button>
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <button @click="onEditPaymentDetails(deal)">Edit payment details</button>
                    </el-dropdown-item>
                    <el-dropdown-item class="is-danger">
                      <button @click="onClickUnsubscribe(deal)">Cancel subscription</button>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                  <UiBtn
                    icon-only="im-gear"
                    size="xs"
                    type="secondary"
                    :highlighted="tableItemDropdownOpened"
                  />
                </el-dropdown>
              </div>
            </div>

            <div class="billing-grid__row is-divided">
              <div class="billing-grid__cell is-bold">
                <span>Applied: </span>
              </div>
              <div class="billing-grid__cell is-bold">
                <span>
                  {{type[0] === 'free' ? userData.dealOptions.calculatedWebsites.free.applied : userData.dealOptions.calculatedWebsites.paidLicenses[type[0]].applied}}
                  <span class="text-color-secondary">
                    of {{type[0] === 'free' ? userData.dealOptions.calculatedWebsites.free.total : userData.dealOptions.calculatedWebsites.paidLicenses[type[0]].total}}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

        <div v-if="legacyTrialSubscription || canSubscribeToPaidPlan" class="billing__btns">
          <UiBtn label="Subscribe" @click="onTryToSubscribe" />
        </div>
        <p class="billing__footnote">
          <img src="/img/info-icon.svg" class="billing__footnote-icon">
          <span>
            One license applies to one website. Learn more about
            <a href="https://help.spreadsimple.com/en/article/subscription-plans-11wipl5/" target="_blank">the subscription plans</a>.
          </span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import UiBtn from '@/components/ui-btn'
import UiTag from '@/components/ui-tag'

export default {
  name: 'SubscriptionInfo',
  components: {
    UiBtn,
    UiTag
  },
  data () {
    return {
      showPlansModal: true,
      tableItemDropdownOpened: false
    }
  },
  computed: {
    ...mapState('app', [
      'userData'
    ]),
    ...mapGetters('app', [
      'userActiveDealsByType',
      'legacyTrialSubscription',
      'canSubscribeToPaidPlan'
    ]),
    userDealsExcludeAppsumo () {
      const userActiveDealsByType = JSON.parse(JSON.stringify(this.userActiveDealsByType))
      if (userActiveDealsByType.appsumo) {
        delete userActiveDealsByType.appsumo
      }
      return userActiveDealsByType
    },
    hasAnnualDealDiscount () {
      if (!this.userData.temporaryDiscounts) return undefined
      const annualDiscount = this.userData.temporaryDiscounts.find(d => d.name === 'switch to annual plan')
      if (annualDiscount && !annualDiscount.applied) {
        return annualDiscount
      }
      return undefined
    }
  },
  methods: {
    ...mapActions('app', [
      'toggleModal'
    ]),
    dealExpirationDate (nextBillDate) {
      const date = moment(new Date(nextBillDate.toString()))
      return moment(date).format('MMMM D YYYY')
    },
    dealCancelled (deal) {
      return deal.dealStatus === 'CANCELLED'
    },
    dealOnTrial (deal) {
      return deal.dealStatus === 'ACTIVE' && deal.isTrial
    },
    dealIsActive (deal) {
      return deal.isPaddleSubscription === true && deal.dealStatus === 'ACTIVE' && !deal.isTrial
    },
    showDealControls (deal) {
      return deal.type !== 'free' && deal.name !== 'trial' && !deal.name.includes('promo')
    },
    requestSubscriptionModal (modalName, dealData, details) {
      this.$root.$emit('openSubscriptionModal', { modalName, dealData, details })
    },
    onClickUnsubscribe (deal) {
      this.$trackEvent({
        'event': 'unsubscribeFlowStarted'
      })
      this.requestSubscriptionModal('unSubscriptionQuizModal', deal)
    },
    onSwitchPlan (deal) {
      this.requestSubscriptionModal('pricingModal', deal, { switchPlan: true })
    },
    onUpdateSeats (deal) {
      this.requestSubscriptionModal('updateSeatsModal', deal)
    },
    onUpdatePlanToAnnual (deal) {
      this.requestSubscriptionModal('proRateModal', deal, { switchPeriod: true })
    },
    onEditPaymentDetails (deal) {
      this.requestSubscriptionModal('paymentDetailsModal', deal)
    },
    onTryToSubscribe () {
      this.requestSubscriptionModal('pricingModal')
    }
  }
}
</script>
