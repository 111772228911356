export default {
  published: true,
  pro: true,
  featureRestrictionsGroup: 'emailIntegrations',
  name: 'access-by-email',
  title: 'Access by email',
  headline: '',
  logoUrl: '/img/integrations/access-by-email.svg',
  // coverUrl: '/img/integrations/mail-chimp-cover.png',
  bgColor: '#b78ed3',
  categories: ['Email Marketing'],
  comingSoon: false,
  builtIn: false,
  url: '',
  helpUrl: '',
  description: `
  Gather user emails in exchange for access to your website and show the content only to visitors who have provided their email addresses.

  Please note this add-on requires using an email marketing service connected via one of the available add-ons.

  **How to set up Access by email:**

  1. Enable add-on.
  2. Select the email marketing add-on you have previously set up from the list.
  3. Add welcome text and save the changes.
  `,

  formFields: [
    {
      name: 'enabled',
      type: 'toggle',
      label: 'Enabled',
      default: false,
      validations: [],
      secret: false
    },
    {
      name: 'emailprovider',
      type: 'select',
      showIf: ['enabled'],
      label: 'Choose email addon',
      default: '',
      placeholder: '',
      options: 'runExternalFunction:getActiveEmailProviders',
      validations: [
        'required'
      ],
      secret: false
    },
    {
      name: 'text',
      type: 'textarea',
      showIf: ['enabled'],
      label: 'Welcome text',
      default: '',
      placeholder: '',
      validations: [
        'required'
      ],
      secret: false
    },
    {
      name: 'askForName',
      type: 'toggle',
      showIf: ['enabled'],
      label: 'Ask for First and Last Name',
      default: false,
      validations: [],
      secret: false
    },
    {
      name: 'buttonTitle',
      type: 'text',
      showIf: ['enabled'],
      label: 'Button Title',
      default: 'Subscribe',
      placeholder: 'Subscribe',
      validations: [
        'required',
        'maxLength:50'
      ],
      secret: false
    },
    {
      name: 'buttonColor',
      type: 'color',
      showIf: ['enabled'],
      label: 'Button Color',
      default: '#999999',
      validations: [
        'required'
      ],
      secret: false
    }
  ],

  actions: []
}
