export default {
  published: true,
  pro: true,
  featureRestrictionsGroup: 'emailIntegrations',
  name: 'mailerlite',
  title: 'Mailerlite',
  headline: '',
  logoUrl: '/img/integrations/mailerlite.svg',
  // coverUrl: '/img/integrations/mailerlite-cover.png',
  bgColor: '#09C269',
  categories: ['Email Marketing'],
  comingSoon: false,
  builtIn: false,
  url: 'https://www.mailerlite.com',
  helpUrl: '',
  description: `
  MailerLite is a cloud-based email marketing solution. Key features include campaign reporting, email automation, subscriber list management and result tracking. The solution helps users to design email campaigns in the form of newsletters.

  You can add an email subscription form to the intro section and also as a popup.

  **How to get your API key?**

  1. Log in to your Mailerlite dashboard and go to <a href="https://dashboard.mailerlite.com/integrations/api" target="_blank">Integrations -> API</a>
  2. Create new API token and copy it.
  `,

  formFields: [
    {
      name: 'enabled',
      type: 'toggle',
      label: 'Enabled',
      default: false,
      validations: [],
      secret: false
    },
    {
      name: 'apiKey',
      type: 'text',
      showIf: ['enabled'],
      label: 'API Key',
      default: '',
      validations: ['required'],
      secret: true
    },
    {
      name: 'showIntro',
      type: 'toggle',
      showIf: ['enabled'],
      label: 'Show Form in Intro Section',
      default: false,
      validations: [],
      secret: false
    },
    {
      name: 'showPopup',
      type: 'toggle',
      showIf: ['enabled'],
      label: 'Show Floating Button and Popup',
      default: false,
      validations: [],
      secret: false
    },
    {
      name: 'title',
      type: 'text',
      showIf: ['enabled', 'showPopup'],
      label: 'Popup Title',
      default: '',
      placeholder: '',
      validations: [
        'required',
        'maxLength:160'
      ],
      secret: false
    },
    {
      name: 'subtitle',
      type: 'text',
      showIf: ['enabled', 'showPopup'],
      label: 'Popup Subtitle',
      default: '',
      placeholder: '',
      validations: [
        'maxLength:300'
      ],
      secret: false
    },
    {
      name: 'askForName',
      type: 'toggle',
      showIf: ['enabled'],
      label: 'Ask for First and Last Name',
      default: false,
      validations: [],
      secret: false
    },
    {
      name: 'buttonTitle',
      type: 'text',
      showIf: ['enabled'],
      label: 'Button Title',
      default: 'Subscribe',
      placeholder: 'Subscribe',
      validations: [
        'required',
        'maxLength:50'
      ],
      secret: false
    },
    {
      name: 'buttonColor',
      type: 'color',
      showIf: ['enabled'],
      label: 'Button Color',
      default: '#999999',
      validations: [
        'required'
      ],
      secret: false
    }
  ],

  actions: []
}
