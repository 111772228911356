<template>
  <div>
    <div class="source-options">
      <transition name="fade" mode="out-in">

        <div v-if="!visible" key="btns">
          <div class="source-options__row">
            <a :href="googleUrl" target="_blank" class="source-options__link" @click="trackEditGSAction">
              <i class="source-options__link-icon icon-edit-google-sheet"></i>
              {{ $t('global.editSheet') }}
            </a>
            <button
              class="source-options__button"
              :disabled="loading"
              title="Refresh Google Sheet data"
              @click="onRefresh">
              <div v-if="loading && refreshing" class="icon-loader"></div>
              <i v-else class="icon-refresh-arrow"></i>
            </button>
          </div>
          <button
            class="source-options__change-btn"
            @click.prevent="$emit('toggle-visibility', true)"
          >
            <i class="source-options__change-btn-icon icon-pencil-new"></i>
            Change source sheet
          </button>
        </div>

        <div v-else key="form" class="source-options__form">
          <button
            class="source-options__form-back"
            @click="$emit('toggle-visibility', false)"
          >
            <i class="source-options__form-back-icon icon-arrow"></i>
            Back
          </button>
          <div class="source-options__form-wrapper">
            <SpreadForm
              :loading="loading"
              :initialVal="googleUrl"
              @submit="val => $emit('google-url-change', val)"
            />
          </div>
        </div>

      </transition>
    </div>
  </div>
</template>
<script>
import SpreadForm from '@/components/spread-form'
export default {
  name: 'SpreadViewerSourceOptions',
  components: {
    SpreadForm
  },
  props: {
    googleUrl: {
      type: String,
      required: true
    },
    visible: {
      type: Boolean
    },
    loading: {
      type: Boolean
    }
  },
  data () {
    return {
      refreshing: false
    }
  },
  watch: {
    loading (val) {
      if (!val) {
        this.refreshing = false
      }
    }
  },
  methods: {
    trackEditGSAction () {
      this.$trackEvent({
        'event': 'editGS'
      })
    },
    onRefresh () {
      this.refreshing = true
      this.$emit('update-sheet-data')
    }
  }
}
</script>
