export const mapDetailsOptionsToTileOptions = (tiles, details) => {
  function isNotDefined (val) {
    return val === '' || val === null || val === undefined || val.length === 0
  }
  const updatedCta = (tiles.cta.type !== 'details' && details.cta.type !== 'order' && isNotDefined(details.cta.id))
    ? {
      ...tiles.cta
    } : {
      ...details.cta
    }

  const updatedDetails = {
    ...details,
    url: {
      id: isNotDefined(details.url.id) ? tiles.title.id : details.url.id
    },
    title: {
      id: isNotDefined(details.title.id) ? tiles.title.id : details.title.id
    },
    subtitle: {
      id: isNotDefined(details.subtitle.id) ? tiles.subtitle.id : details.subtitle.id
    },
    content: {
      id: isNotDefined(details.content.id) ? tiles.content.id : details.content.id
    },
    price: {
      id: isNotDefined(details.price.id) ? tiles.price.id : details.price.id
    },
    image: {
      ids: isNotDefined(details.image.ids) ? tiles.image.ids : details.image.ids,
      keepAspectRatio: isNotDefined(details.image.keepAspectRatio) ? tiles.image.keepAspectRatio : details.image.keepAspectRatio
    },
    specs: {
      ids: isNotDefined(details.specs.ids) ? tiles.specs.ids : details.specs.ids
    },
    cta: updatedCta
  }
  return updatedDetails
}
