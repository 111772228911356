<template>
  <form
    class="profile-tab__form"
    @submit.prevent="updateProfile"
  >
    <!-- <div class="profile-tab__form-field">
      <span class="profile-tab__form-field-label">{{ $t('global.language') }}</span>
      <div class="profile-tab__form-field-control">
        <Select
          v-model="$i18n.locale"
          :options="$t('pageAccount.availableLanguages')"
          :clearable="false"
          class="profile-tab__form-field-select"
          transition="fade"
        />
      </div>
    </div> -->
    <div class="profile-tab__switcher-wrapp">
      <p class="profile-tab__switcher-label">{{ $t('pageAccount.keepMeUpdated') }}</p>
      <label
        :class="{ 'selected': subscribed }"
        class="switcher"
      >
        <input
          v-model="subscribed"
          class="switcher__input"
          type="checkbox"
          @change="$trackEvent({
            'event': 'subscribeToNews',
            'status': subscribed,
          })"
        />
        <span class="switcher__indicator"></span>
      </label>
    </div>
    <button
      type="submit"
      class="profile-tab__button"
    >
      {{ $t('global.save') }}
    </button>
  </form>
</template>
<script>
import api from '@/services/api/'
export default {
  name: 'FormSettings',
  props: {
    newsSubscription: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      subscribed: false
    }
  },
  methods: {
    updateProfile () {
      const data = {
        preferredLanguage: this.$i18n.locale,
        newsSubscription: this.subscribed
      }
      this.$async.updateProfile.$perform(data)
        .then(response => {
          this.$toasted.show('Account settings were successfully updated', {
            theme: 'toasted-primary',
            position: 'top-center',
            type: 'success',
            duration: 1500
          })
        })
        .catch(() => {
          this.$toasted.show('Error, try again', {
            theme: 'toasted-primary',
            position: 'top-center',
            type: 'error',
            duration: 1500
          })
        })
    }
  },
  asyncOperations: {
    updateProfile (data) {
      return api.updateProfile(data)
    }
  },
  created () {
    this.subscribed = this.newsSubscription
  }
}
</script>
