export default {
  published: true,
  pro: true,
  featureRestrictionsGroup: 'analyticsIntegrations',
  name: 'fbPixel',
  title: 'Facebook Pixel',
  logoUrl: '/img/integrations/fb-pixel.svg',
  coverUrl: '/img/integrations/fb-pixel-cover.png',
  bgColor: '#3854A0',
  categories: ['Analytics'],
  comingSoon: false,
  builtIn: false,
  url: 'https://www.facebook.com/business/learn/facebook-ads-pixel',
  helpUrl: '',
  description: `
  The Facebook pixel is a code that collects data that helps you track conversions from Facebook ads, optimize ads, build targeted audiences for future ads, and remarket to people who have already taken some kind of action on your website.

  <a href="https://www.facebook.com/business/help/952192354843755?id=1205376682832142" target="_blank">Where can I get the Pixel ID ?</a>
  `,

  formFields: [
    {
      name: 'enabled',
      type: 'toggle',
      label: 'Enabled',
      default: false,
      validations: [
        'required'
      ],
      secret: false
    },
    {
      name: 'id',
      type: 'text',
      showIf: ['enabled'],
      label: 'Pixel ID',
      default: '',
      placeholder: '1230000000000123',
      validations: [
        'required',
        'minLength:1',
        'maxLength:20',
        'numeric'
      ],
      secret: false
    },
    {
      name: 'trackPurchaseEvents',
      type: 'toggle',
      showIf: ['enabled'],
      label: 'Track Purchase Events',
      default: false,
      validations: [],
      secret: false
    },
    {
      name: 'purchaseCurrency',
      type: 'text',
      showIf: ['enabled', 'trackPurchaseEvents'],
      label: 'Currency Code',
      default: 'USD',
      placeholder: 'USD',
      validations: [
        'required',
        'minLength:2',
        'maxLength:4'
      ],
      secret: false
    }
  ],

  actions: [
    {
      type: 'injectToHead',
      run (formData) {
        return `
        <!-- Facebook Pixel Code -->
        <script>
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${formData.id}');
        fbq('track', 'PageView');
        </script>
        <noscript><img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=${formData.id}&ev=PageView&noscript=1"
        /></noscript>
        <!-- End Facebook Pixel Code -->
        `
      }
    },
    {
      type: 'trackPurchase',
      run (formData, payload) {
        if (!formData.trackPurchaseEvents) return
        try {
          const eventData = {
            value: payload.totalAmount,
            currency: formData.purchaseCurrency,
            contents: payload.cartItems.map(item => ({
              id: item.sku || item.title,
              quantity: item.quantity
            })),
            content_type: 'product'
          }
          window.fbq('track', 'Purchase', eventData)
        } catch (e) {
          console.warn('Failed to track FB Pixel Purchase', e)
        }
      }
    }
  ]
}
