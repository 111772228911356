var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "page-form side-form",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submitForm.apply(null, arguments)
        }
      }
    },
    [
      _vm.validationStatus !== ""
        ? _c("p", { staticClass: "aside-message" }, [
            _vm._v("\n    " + _vm._s(_vm.validationStatus) + "\n  ")
          ])
        : _vm._e(),
      _c(
        "ProOptionWrapper",
        { attrs: { featureName: "userAccess" } },
        [
          _c("AccessManagementSpreadForm", {
            attrs: {
              accessRules: _vm.accessRules,
              v: _vm.$v.accessRules.spreadsheetUrl,
              accessRulesError: _vm.accessRulesError,
              loading: _vm.processing,
              refreshingSheetData: _vm.refreshingSheetData
            },
            on: {
              "create-users-spreadsheet": _vm.createUsersSpreadsheet,
              "refresh-users-spreadsheet": _vm.refreshUsersSpreadSheet,
              "connect-users-spreadsheet": _vm.getSpreadsheetScheme,
              "update-spread-sheet-url": _vm.updateSpreadSheetUrl
            }
          })
        ],
        1
      ),
      _vm.websiteAccess.scheme.length
        ? [
            _c(
              "div",
              { staticClass: "side-form__section" },
              [
                _c(
                  "ProOptionWrapper",
                  { attrs: { featureName: "userAccess" } },
                  [
                    _c("AccessModeManagementSwitcher", {
                      attrs: {
                        title: "Enable access rules",
                        isEnabled: _vm.accessRules.enabled
                      },
                      on: {
                        "toggle-access-mode": function($event) {
                          _vm.accessRules.enabled = !_vm.accessRules.enabled
                        }
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "CollapsableBlock",
              {
                staticClass: "side-form__section",
                attrs: { expanded: true, title: "ACCESS SETTINGS" }
              },
              [
                _c(
                  "FormField",
                  {
                    staticClass: "side-form__item",
                    attrs: { label: "User email column" }
                  },
                  [
                    _c(
                      "ProOptionWrapper",
                      { attrs: { featureName: "userAccess" } },
                      [
                        _c("v-select", {
                          attrs: {
                            disabled:
                              _vm.processing ||
                              !_vm.websiteAccess.scheme.length,
                            items: _vm.websiteAccess.scheme,
                            "item-text": "label",
                            "item-value": "id",
                            attach: "",
                            placeholder: !_vm.websiteAccess.scheme.length
                              ? "Connect users sheet first"
                              : "Select sheet column for user email",
                            "menu-props": "offsetY",
                            "append-icon": "keyboard_arrow_down"
                          },
                          model: {
                            value: _vm.accessRules.tableFieldsMapping.email.id,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.accessRules.tableFieldsMapping.email,
                                "id",
                                $$v
                              )
                            },
                            expression:
                              "accessRules.tableFieldsMapping.email.id"
                          }
                        }),
                        _vm.$v.accessRules.tableFieldsMapping.accessGroups.id
                          .$dirty &&
                        !_vm.$v.accessRules.tableFieldsMapping.email.id
                          ? _c("p", { staticClass: "form-field__error" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("global.fieldIsRequired")) +
                                  "\n          "
                              )
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "FormField",
                  {
                    staticClass: "side-form__item",
                    attrs: { label: "Access group column" }
                  },
                  [
                    _c(
                      "ProOptionWrapper",
                      { attrs: { featureName: "userAccess" } },
                      [
                        _c("v-select", {
                          attrs: {
                            disabled:
                              _vm.processing ||
                              !_vm.websiteAccess.scheme.length,
                            items: _vm.websiteAccess.scheme,
                            "item-text": "label",
                            "item-value": "id",
                            attach: "",
                            placeholder: !_vm.websiteAccess.scheme.length
                              ? "Connect users sheet first"
                              : "Select sheet column for access group",
                            "menu-props": "offsetY",
                            "append-icon": "keyboard_arrow_down"
                          },
                          model: {
                            value:
                              _vm.accessRules.tableFieldsMapping.accessGroups
                                .id,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.accessRules.tableFieldsMapping.accessGroups,
                                "id",
                                $$v
                              )
                            },
                            expression:
                              "accessRules.tableFieldsMapping.accessGroups.id"
                          }
                        }),
                        _vm.$v.accessRules.tableFieldsMapping.accessGroups.id
                          .$dirty &&
                        !_vm.$v.accessRules.tableFieldsMapping.accessGroups.id
                          .required
                          ? _c("p", { staticClass: "form-field__error" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("global.fieldIsRequired")) +
                                  "\n          "
                              )
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "CollapsableBlock",
              {
                staticClass: "side-form__section",
                attrs: { expanded: false, title: "LOGIN PAGE SETTINGS" }
              },
              [
                _c(
                  "div",
                  { staticClass: "side-form__item" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "side-form__item-label access-manager__mb-8"
                      },
                      [_vm._v("Login page welcome text")]
                    ),
                    _c(
                      "ProOptionWrapper",
                      { attrs: { featureName: "userAccess" } },
                      [
                        _c("MarkdownComponent", {
                          attrs: {
                            preview: false,
                            leftToolbar: "bold italic strikethrough link",
                            height: "200px"
                          },
                          model: {
                            value: _vm.options.loginPage.markdown,
                            callback: function($$v) {
                              _vm.$set(_vm.options.loginPage, "markdown", $$v)
                            },
                            expression: "options.loginPage.markdown"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            ),
            _c(
              "CollapsableBlock",
              {
                staticClass: "side-form__section",
                attrs: { expanded: false, title: "E-MAIL SETTINGS" }
              },
              [
                _c(
                  "div",
                  { staticClass: "side-form__item" },
                  [
                    _c(
                      "p",
                      { staticClass: "side-form__item-label placement-top" },
                      [_vm._v("E-mail sender name:")]
                    ),
                    _c(
                      "ProOptionWrapper",
                      { attrs: { featureName: "userAccess" } },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value:
                                _vm.options.emailOptions.websiteAccess.fromName,
                              expression:
                                "options.emailOptions.websiteAccess.fromName",
                              modifiers: { trim: true }
                            }
                          ],
                          staticClass: "input",
                          attrs: { type: "text" },
                          domProps: {
                            value:
                              _vm.options.emailOptions.websiteAccess.fromName
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.options.emailOptions.websiteAccess,
                                "fromName",
                                $event.target.value.trim()
                              )
                            },
                            blur: function($event) {
                              return _vm.$forceUpdate()
                            }
                          }
                        })
                      ]
                    ),
                    _vm.$v.options.emailOptions.websiteAccess.fromName.$invalid
                      ? _c("p", { staticClass: "side-form__item-msg" }, [
                          _vm._v(
                            _vm._s(
                              !_vm.$v.options.emailOptions.websiteAccess
                                .fromName.required
                                ? "This field id required"
                                : "Length of the value should be less then 50 characters"
                            )
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "side-form__item" },
                  [
                    _c(
                      "p",
                      { staticClass: "side-form__item-label placement-top" },
                      [_vm._v("E-mail subject:")]
                    ),
                    _c(
                      "ProOptionWrapper",
                      { attrs: { featureName: "userAccess" } },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value:
                                _vm.options.emailOptions.websiteAccess.subject,
                              expression:
                                "options.emailOptions.websiteAccess.subject",
                              modifiers: { trim: true }
                            }
                          ],
                          staticClass: "input",
                          attrs: { type: "text" },
                          domProps: {
                            value:
                              _vm.options.emailOptions.websiteAccess.subject
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.options.emailOptions.websiteAccess,
                                "subject",
                                $event.target.value.trim()
                              )
                            },
                            blur: function($event) {
                              return _vm.$forceUpdate()
                            }
                          }
                        })
                      ]
                    ),
                    _vm.$v.options.emailOptions.websiteAccess.subject.$invalid
                      ? _c("p", { staticClass: "side-form__item-msg" }, [
                          _vm._v(
                            _vm._s(
                              !_vm.$v.options.emailOptions.websiteAccess
                                .fromName.required
                                ? "This field id required"
                                : "Length of the value should be less then 50 characters"
                            )
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "side-form__item" },
                  [
                    _c(
                      "p",
                      { staticClass: "side-form__item-label placement-top" },
                      [_vm._v("E-mail body:")]
                    ),
                    _c(
                      "ProOptionWrapper",
                      { attrs: { featureName: "userAccess" } },
                      [
                        _c("MarkdownComponent", {
                          attrs: {
                            height: "200px",
                            preview: false,
                            leftToolbar: "bold italic strikethrough link"
                          },
                          model: {
                            value: _vm.options.emailOptions.websiteAccess.body,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.options.emailOptions.websiteAccess,
                                "body",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression:
                              "options.emailOptions.websiteAccess.body"
                          }
                        })
                      ],
                      1
                    ),
                    _vm.$v.options.emailOptions.websiteAccess.body.$invalid
                      ? _c("p", { staticClass: "side-form__item-msg" }, [
                          _vm._v(
                            _vm._s(
                              !_vm.$v.options.emailOptions.websiteAccess.body
                                .required
                                ? "This field id required"
                                : "Length of the value should be less then 5000 characters"
                            )
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                )
              ]
            ),
            _vm.websiteAccess.created
              ? _c(
                  "div",
                  { staticClass: "access-manager__wrapper--center" },
                  [
                    _c(
                      "ProOptionWrapper",
                      { attrs: { featureName: "userAccess" } },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "profile-tab__danger-btn",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                _vm.modalDeleteOpened = true
                              }
                            }
                          },
                          [_vm._v("Reset access settigns")]
                        )
                      ]
                    ),
                    _c(
                      "Modal",
                      {
                        attrs: { modalName: "Are you sure?" },
                        model: {
                          value: _vm.modalDeleteOpened,
                          callback: function($$v) {
                            _vm.modalDeleteOpened = $$v
                          },
                          expression: "modalDeleteOpened"
                        }
                      },
                      [
                        _c("div", { staticClass: "delete-modal" }, [
                          _c("div", { staticClass: "delete-modal__body" }, [
                            _c(
                              "p",
                              { staticClass: "delete-modal__paragraph" },
                              [
                                _vm._v(
                                  "This action will delete access rules that you have customized"
                                )
                              ]
                            ),
                            _c(
                              "form",
                              {
                                staticClass: "delete-modal__confirm-form",
                                on: {
                                  submit: function($event) {
                                    $event.preventDefault()
                                    return _vm.deleteAccessRules.apply(
                                      null,
                                      arguments
                                    )
                                  }
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "delete-modal__field" },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "delete-modal__button",
                                        class: { disabled: _vm.loading },
                                        attrs: {
                                          disabled: _vm.loading,
                                          type: "submit"
                                        }
                                      },
                                      [_vm._v("Delete")]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "delete-modal__button negative",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function($event) {
                                            _vm.modalDeleteOpened = false
                                          }
                                        }
                                      },
                                      [_vm._v("Cancel")]
                                    )
                                  ]
                                )
                              ]
                            )
                          ])
                        ])
                      ]
                    )
                  ],
                  1
                )
              : _vm._e()
          ]
        : _vm._e(),
      _c(
        "UiBtn",
        {
          staticClass: "aside-save",
          attrs: {
            size: "l",
            "attr-type": "submit",
            loading: _vm.processing,
            disabled: !_vm.websiteAccess
              ? !_vm.accessRules.enabled ||
                _vm.processing ||
                _vm.$v.accessRules.$invalid
              : _vm.processing || _vm.spreadViewerOptionsInvalid
          }
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(
                !_vm.websiteAccess.created ? "SET UP ACCESS SETTINGS" : "SAVE"
              ) +
              "\n  "
          )
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }