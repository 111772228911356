var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      staticClass: "ui-helper",
      class: "ui-helper--direction-" + _vm.direction
    },
    [
      _vm.label
        ? _c("span", { staticClass: "ui-helper__label" }, [
            _vm._v("\n    " + _vm._s(_vm.label) + "\n  ")
          ])
        : _vm._e(),
      _vm.popoverContent || _vm.$slots.default || _vm.videoData
        ? _c(
            "el-popover",
            {
              staticClass: "ui-helper__popover-wrap",
              attrs: {
                trigger: _vm.triggerAction,
                placement: _vm.popoverPlacement,
                "visible-arrow": false,
                "popper-class": _vm.popoverClasses
              },
              model: {
                value: _vm.popoverVisible,
                callback: function($$v) {
                  _vm.popoverVisible = $$v
                },
                expression: "popoverVisible"
              }
            },
            [
              _vm.popoverContent
                ? [_vm._v("\n      " + _vm._s(_vm.popoverContent) + "\n    ")]
                : _vm.videoData
                  ? _c(
                      "div",
                      { staticClass: "ui-helper__popover-video-wrap" },
                      [
                        _c("video", {
                          staticClass: "ui-helper__popover-video",
                          attrs: {
                            autoplay: "",
                            loop: "",
                            muted: "",
                            src: _vm.videoData.src
                          },
                          domProps: { muted: true }
                        }),
                        _c(
                          "div",
                          { staticClass: "ui-helper__popover-video-meta" },
                          [
                            _vm.videoData.label
                              ? _c(
                                  "p",
                                  {
                                    staticClass:
                                      "ui-helper__popover-video-label"
                                  },
                                  [_vm._v(_vm._s(_vm.videoData.label))]
                                )
                              : _vm._e(),
                            _vm.videoData.btns
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "ui-helper__popover-video-btns"
                                  },
                                  _vm._l(_vm.videoData.btns, function(
                                    btn,
                                    index
                                  ) {
                                    return _c("UiBtn", {
                                      key: index,
                                      attrs: {
                                        label: btn.label,
                                        to: btn.to,
                                        "external-resource":
                                          btn.externalResource,
                                        "target-blank": "",
                                        type: btn.color ? btn.color : "primary"
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.handleClick(btn)
                                        }
                                      }
                                    })
                                  }),
                                  1
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    )
                  : _vm._t("default"),
              _c("UiIcon", {
                staticClass: "ui-helper__popover-trigger",
                class: {
                  "ui-helper__popover-trigger--highlighted": _vm.popoverVisible
                },
                attrs: { slot: "reference", icon: "mf-info" },
                slot: "reference"
              })
            ],
            2
          )
        : _c("UiIcon", {
            staticClass: "ui-helper__icon",
            attrs: { icon: "mf-info" }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }