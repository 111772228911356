export default {
  published: true,
  pro: true,
  featureRestrictionsGroup: 'ecommerceIntegrations',
  name: 'externalCheckoutAction',
  title: 'External Checkout Action',
  logoUrl: '/img/integrations/external-checkout-action.svg',
  // coverUrl: '/img/integrations/sample-cover.png',
  bgColor: '#F54D41',
  categories: ['Checkout'],
  comingSoon: false,
  builtIn: false,
  url: '',
  helpUrl: 'https://help.spreadsimple.com/en/article/external-checkout-action-eca-add-on-1f5o00q/?bust=1613653625840',
  description: `
  This add-on allows you to send order form data to an external resource.

  <details>
  <summary>How it works?</summary>
  This way you can cover the following scenarios after purchase:

  - redirect the user to any 'Thank you' page
  - as the next step after shopping cart confirmation, direct the user to the custom form and optionally populate the fields of this form with data from the order
  - implement an arbitrary checkout process using your own script for payment, delivery, booking. Your script can obtain the state of the cart form and you can manage the mapping of the fields.

  Technically, when using this add-on, the cart contents and client info are submitted as a \`<form>\` to the specified action URL with the specified method: GET or POST.

  You're able to map each query param and form-data field of this form by setting up the key=value pairs like

  \`fullName={{client.name}}\` <br>
  \`notes=some custom text {{cartItemsText}}\`

  where \`fullName\` and \`notes\` are request parameter keys;  \`{{client.name}}\` and  \`{{cartItemsText}}\` are replacement tags to be replaced with actual values.

  </details>

  <br/>

  <details>
  <summary>Available order replacement tags</summary>
  <div class="table-wrap">

  | Tag | Example value |
  |---------------|---------------|
  |  \`{{client.name}}\` | John Doe |
  |  \`{{client.phone}}\` | 202-555-0192 |
  |  \`{{client.email}}\` | john@example.com |
  |  \`{{client.message}}\` | Deliver it ASAP |
  |  \`{{orderId}}\` | K8KZPUA |
  |  \`{{cartItemsText}}\` | • 1 × $2.40 \\| Spicy unagi |
  |  \`{{totalAmountFormatted}}\` | $2.40 |
  |  \`{{totalAmount}}\` | 2.40 |
  |  \`{{cartItems}}\` | \`[{"title":"Spicy unagi","image":"https://cdn.pixabay.com/photo/2017/06/04/03/37/sushi-2370265_1280.jpg","priceFormatted":"$2.40"}]\` |
  |  \`{{client}}\` | \`{"name":"John Doe","phone":"202-555-0192","email":"john@example.com","message":"Deliver it ASAP"}\` |

  </div>
  </details>
  `,

  formFields: [
    {
      name: 'enabled',
      type: 'toggle',
      label: 'Enabled',
      default: false,
      validations: [
        'required'
      ],
      secret: false
    },
    {
      name: 'url',
      type: 'text',
      showIf: ['enabled'],
      label: 'Action URL',
      default: '',
      placeholder: '',
      validations: [
        'required',
        'maxLength:500',
        'url',
        'startsWith:http:https'
      ],
      secret: false
    },
    {
      name: 'method',
      type: 'select',
      showIf: ['enabled'],
      label: 'Request Method',
      default: 'GET',
      options: [
        {
          label: 'GET',
          value: 'GET'
        },
        {
          label: 'POST',
          value: 'POST'
        }
      ],
      validations: [
      ],
      secret: false
    },
    {
      name: 'getArgsConfig',
      type: 'textarea',
      showIf: ['enabled'],
      label: 'Query Params mapping',
      default: '',
      placeholder: `param1=value1
name={{ client.name }}
`,
      validations: [
      ],
      secret: false
    },
    {
      name: 'postArgsConfig',
      type: 'textarea',
      showIf: ['enabled', 'method=POST'],
      label: 'Body form-data mapping',
      default: '',
      placeholder: `key1=value1
name={{ client.name }}
`,
      validations: [
      ],
      secret: false
    },
    {
      name: 'openInPopup',
      type: 'toggle',
      showIf: ['enabled'],
      label: 'Open destination URL in a popup (iFrame)',
      default: false,
      validations: [],
      secret: false
    },
    {
      name: 'cartOnly',
      type: 'toggle',
      showIf: ['enabled'],
      label: 'Show cart only (hide default checkout form)',
      default: false,
      validations: [],
      secret: false
    }
  ],

  actions: [
  ]
}
