<template>
  <div class="unsubscribe-modal">
    <div class="unsubscribe-modal__content-wrap">
      <p>
        During your next billing period on <b>{{ nextBillingDate }}</b> you will be charged only <b>${{ nextBillingSum }}</b>.
      </p>
    </div>
    <div class="unsubscribe-modal__btns justify-end">
      <UiBtn
        @click="$emit('close-modal')"
      >ОК</UiBtn>
    </div>
  </div>
</template>

<script>
import UiBtn from '@/components/ui-btn/UiBtn.vue'
import moment from 'moment'

export default {
  name: 'DiscountTaken',
  components: {
    UiBtn
  },
  props: {
    nextBillingPeriod: {
      type: String,
      default: ''
    },
    nextBillingSum: {
      type: Number
    }
  },
  computed: {
    nextBillingDate () {
      const date = moment(new Date(this.nextBillingPeriod.toString()))
      return moment(date).format('MMMM D YYYY')
    }
  }
}
</script>
