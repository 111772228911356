<template>
  <div class="side-form__item is-justified">
    <div class="side-form__item-label">{{ title }}</div>
    <label
      :class="{ 'selected': isEnabled }"
      class="switcher"
    >
      <input
        :value="accessModeValue"
        class="switcher__input"
        type="checkbox"
        @input="$emit('toggle-access-mode')"
        @change="$trackFeature('access', 'accessManagementMode', isEnabled)"
      >
      <span class="switcher__indicator"></span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'AccessModeSwitcher',
  props: {
    title: {
      type: String,
      default: 'Enable access rules'
    },
    isEnabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    accessModeValue () {
      return this.isEnabled
    }
  }
}
</script>
