export default {
  schema: [
    {
      name: 'name',
      type: 'text',
      label: 'Name',
      default: '',
      enabled: true,
      isBuiltIn: true,
      width: 12,
      validations: [{ name: 'required', label: 'Required', message: 'Field required' }],
      conditions: []
    },
    {
      name: 'secondName',
      type: 'text',
      label: 'Last Name',
      default: '',
      enabled: false,
      isBuiltIn: true,
      width: 6,
      validations: [{ name: 'required', label: 'Required', message: 'Field required' }],
      conditions: []
    },
    {
      name: 'phone',
      type: 'tel',
      label: 'Phone number',
      default: '',
      enabled: true,
      isBuiltIn: true,
      width: 6,
      validations: [],
      conditions: []
    },
    {
      name: 'email',
      type: 'email',
      label: 'Email',
      default: '',
      enabled: true,
      isBuiltIn: true,
      width: 6,
      validations: [{ name: 'required', label: 'Required', message: 'Field required' }, { name: 'email', label: 'Email', message: 'Field invalid' }],
      conditions: []
    },
    {
      name: 'address',
      type: 'text',
      label: 'Address',
      default: '',
      enabled: false,
      isBuiltIn: true,
      width: 12,
      validations: [{ name: 'required', label: 'Required', message: 'Field required' }],
      conditions: []
    },
    {
      name: 'message',
      type: 'textarea',
      label: 'Notes',
      default: '',
      enabled: true,
      isBuiltIn: true,
      width: 12,
      validations: [],
      conditions: []
    }
  ]
}
