<template>
  <form>
    <div class="settings-form__settings-wrap">
      <FormField label="Add social media icons">
        <v-select
          :value="selectedSocialNetworks"
          :items="options"
          item-text="label"
          item-value="value"
          multiple
          placeholder="Select from the list"
          menu-props="offsetY"
          append-icon="keyboard_arrow_down"
          @input="onSocialMediaSelect"
        >
          <template slot="selection" slot-scope="data">
            <DraggableChip :id="data.index" :value="value" @input="(val) => $emit('input', val)">
              {{ data.item.label }}
            </DraggableChip>
          </template>
        </v-select>
        <div class="side-form__item tile-constructor__hint" style="margin-top: 0;">
          <a target="_blank" href="https://help.spreadsimple.com/en/article/adding-social-media-icons-kzg8iq/">Help: How to add social media icons</a>
        </div>
      </FormField>
    </div>
    <div
      v-for="({value: url, key}, i) in value"
      :key="i"
      class="settings-form__settings-wrap"
    >
      <FormField
        :label="`Your ${getSocialMediaTitle(key)} link:`"
      >
        <input
          :value="url"
          type="text"
          class="input"
          @input="onLinkInput($event, key)"
        />
        <p
          v-if="getUrlValidationStatus(i)"
          class="form-field__error"
        >
          Field is required and must be a URL
        </p>
      </FormField>
    </div>
  </form>
</template>

<script>
import FormField from '@/components/form-field'
import { socialNetworksOptions } from '@/modules/utils/socialNetworksOptions'
import DraggableChip from '@/components/draggable-chip/DraggableChip.vue'

export default {
  name: 'SocialNetworksForm',
  components: { DraggableChip, FormField },
  props: {
    value: {
      type: Array,
      required: true
    },
    vuelidateObject: {
      type: Object
    }
  },
  data () {
    return {
      selected: [],
      links: {}
    }
  },
  computed: {
    options () {
      return socialNetworksOptions
    },
    selectedSocialNetworks () {
      return this.value.map(sn => sn.key)
    }
  },
  methods: {
    onSocialMediaSelect (newArr) {
      const copy = [...this.value]
      this.value.forEach((sn, index) => {
        if (!newArr.includes(sn.key)) {
          copy.splice(index, 1)
        }
      })

      newArr.forEach((str) => {
        if (!this.value.some((obj) => obj.key === str)) {
          copy.push({ key: str, value: '' })
        }
      })

      this.$emit('input', copy)
    },
    getSocialMediaTitle (value) {
      return this.options.find(o => o.value === value).label
    },
    onLinkInput (event, key) {
      const val = event.target.value
      const copy = [...this.value]
      copy[(copy.findIndex((el) => el.key === key))].value = val
      this.$emit('input', copy)
    },
    getUrlValidationStatus (index) {
      return this.vuelidateObject.$each.$iter[index].$invalid
    }
  }
}
</script>
