<template>
  <div class="side-form">
    <div v-if="value.length" class="side-form__item">
      <draggable
        :value="value"
        tag="div"
        handle=".draggable__item-trigger"
        class="draggable"
        @input="val => onDrag(val)"
      >
        <div
          v-for="(field, index) in value"
          :key="index"
          class="draggable__item"
          :class="{'hidden': !field.enabled}"
          >
          <div class="draggable__item-trigger icon-draggable"></div>
          <div class="draggable__item-title-wrap" @click="onEdit(field, index)">
            <span class="draggable__item-title">{{ field.label }} <span v-if="field.validations && field.validations.some(rule => rule.name === 'required')">*</span></span>
          </div>
          <div class="draggable__item-controls">
            <el-dropdown
              trigger="click"
              class="draggable__item-control"
            >
              <i class="icon-gear"></i>
              <el-dropdown-menu slot="dropdown" class="draggable__dropdown-menu">
                <el-dropdown-item @click.native="onEdit(field, index)">Edit</el-dropdown-item>
                <el-dropdown-item
                  @click.native="onChangeVisibility(index)"
                >
                  {{ field.enabled ? 'Disable' : 'Enable' }}
                </el-dropdown-item>
                <el-dropdown-item v-if="!field.isBuiltIn"  @click.native="onDelete(index)">Delete</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </draggable>
    </div>
    <div class="side-form__item side-form__btns">
      <button type="button" class="btn btn--transparent" @click="formBuilderModalOpened = true">+ Add custom field</button>
    </div>
    <Modal
      v-model="formBuilderModalOpened"
      :closeOnClickOutside="false"
      :modalName="currentEditingField !== null ? 'Edit field' : 'Add custom field'"
      :hasCloseButton="true"
    >
      <FormFieldBuilder
        :value="currentEditingField"
        :existingFormFields="value"
        @on-add="onAddField"
        @on-edit="onUpdateField"
        @cancel-submission="closeModalAndCleanData"
      />
    </Modal>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
import Modal from '@/components/modal'
import defaultFieldsSchema from './defaultFieldsSchema.js'
import FormFieldBuilder from './FormFieldBuilder.vue'
export default {
  name: 'FormBuilder',
  components: {
    draggable,
    Modal,
    FormFieldBuilder
  },
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      formBuilderModalOpened: false,
      currentEditingField: null,
      currentEditingIndex: null
    }
  },
  watch: {
    formBuilderModalOpened (val) {
      if (!val) {
        this.closeModalAndCleanData()
      }
    }
  },
  methods: {
    onAddField (val) {
      const formFields = this.value
      formFields.push(val)
      this.$emit('input', formFields)

      this.closeModalAndCleanData()
    },
    onUpdateField (val) {
      const formFields = this.value
      formFields.splice(this.currentEditingIndex, 1, val)
      this.$emit('input', formFields)

      this.closeModalAndCleanData()
    },
    onDrag (val) {
      this.$emit('input', val)
    },
    onDelete (index) {
      const formFields = this.value
      formFields.splice(index, 1)
      this.$emit('input', formFields)
    },
    onChangeVisibility (index) {
      const formFields = this.value
      formFields[index].enabled = !formFields[index].enabled
      this.$emit('input', formFields)
    },
    onEdit (val, index) {
      this.currentEditingField = val
      this.currentEditingIndex = index
      this.formBuilderModalOpened = true
    },
    closeModalAndCleanData () {
      this.formBuilderModalOpened = false
      this.currentEditingField = null
      this.currentEditingIndex = null
    }
  },
  created () {
    if (this.value.length === 0) {
      this.$emit('input', defaultFieldsSchema.schema)
    }
  }
}
</script>

<style scoped>
  .draggable__item.hidden {
    opacity: 0.5;
  }
</style>
