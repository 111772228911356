export function getDataStructure (data, stepName) {
  switch (stepName) {
    case 'editItemCard':
      return {
        ...data.options.viewer.tileOptions,
        ...data.options.appearance.stickyFilters,
        ...data.options.appearance.openFiltersOnMobileByDefault
      }
    case 'editDetailsPage':
      return {
        enableSingleItemView: data.options.viewer.enableSingleItemView,
        showSingleItemAsPopup: data.options.viewer.showSingleItemAsPopup,
        singleItemOptions: data.options.viewer.singleItemOptions
      }
    case 'editDesign':
      return {
        ...data.options.appearance.theme,
        ...data.options.appearance.bgColor,
        ...data.options.appearance.coverImage,
        ...data.options.appearance.coverLayout,
        ...data.options.appearance.introKeepAspectRatio,
        ...data.options.appearance.logoImage,
        ...data.options.appearance.scrollToTopBtn,
        ...data.options.appearance.showHomeIntro,
        ...data.options.footer,
        ...data.options.header,
        ...data.options.page,
        ...data.options.scrollToTopBtn
      }
    case 'setUpCheckout':
      return {
        ...data.options.cart
      }
    case 'updateSeoSettings':
      return {
        ...data.options.seo,
        ...data.seoIndexing
      }
    case 'addPagesAndLinks':
      return { ...data.options.routes }
    case 'updateDomain': {
      return { ...data.domain, ...data.customDomain }
    }
    case 'enableAddon': {
      return { ...data.integrations }
    }
  }
}
