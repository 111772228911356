<template>
  <span>
    <span class="value">{{progress}}</span>
    <el-progress v-if="showProgressBar" type="circle" :percentage="progress/time*100"></el-progress>
  </span>
</template>
<script>

export default {
  name: 'Countdown',
  props: {
    time: {
      type: Number,
      required: true
    },
    showProgressBar: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      progress: 0,
      timer: null
    }
  },
  methods: {
    startCountdown (initialTime) {
      this.progress = initialTime
      this.timer = setInterval(() => {
        if (this.progress <= 0) {
          clearInterval(this.timer)
          this.$emit('time-end')
          return
        }
        this.progress -= 1
      }, 1000)
    }
  },
  created () {
    this.startCountdown(this.time)
  },
  beforeDestroy () {
    clearInterval(this.timer)
  }
}
</script>
