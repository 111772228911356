var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sheet-samples" },
    [
      _c(
        "div",
        { staticClass: "sheet-samples__list" },
        _vm._l(_vm.sourcesList, function(item, index) {
          return _c("div", { key: index, staticClass: "sheet-samples__item" }, [
            _c("div", { staticClass: "sheet-samples__preview" }, [
              _c(
                "div",
                { staticClass: "sheet-samples__preview-header" },
                _vm._l(3, function(n) {
                  return _c("div", {
                    key: n,
                    staticClass: "sheet-samples__preview-header-circle"
                  })
                }),
                0
              ),
              _c("div", {
                staticClass: "sheet-samples__preview-inner",
                style: { backgroundImage: "url('" + item.imageUrl + "')" }
              })
            ]),
            _c("h5", { staticClass: "sheet-samples__item-title" }, [
              _vm._v(_vm._s(item.name))
            ]),
            _c(
              "div",
              { staticClass: "sheet-samples__item-btns" },
              [
                _c("UiBtn", {
                  attrs: {
                    attrType: "button",
                    label: "Use template",
                    "full-width": ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("on-link-choice", {
                        templateName: item.templateName
                      })
                    }
                  }
                }),
                _c("UiBtn", {
                  attrs: {
                    label: "View",
                    type: "secondary-blue",
                    "full-width": ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.onOpenPreviewClick(item)
                    }
                  }
                })
              ],
              1
            )
          ])
        }),
        0
      ),
      _c("TemplatePreviewModal", {
        ref: "templatePreviewModal",
        on: {
          startWithTemplate: function(temp) {
            return _vm.onStartTemplateFromPreview(temp)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }