export default {
  published: true,
  pro: true,
  featureRestrictionsGroup: 'automationIntegrations',
  name: 'pabbly',
  title: 'Pabbly Connect',
  headline: '',
  logoUrl: '/img/integrations/pabbly.svg',
  coverUrl: '/img/integrations/pabbly-cover.png',
  bgColor: '#20B276',
  categories: ['Automation'],
  comingSoon: false,
  builtIn: true,
  url: 'https://www.pabbly.com/connect/integrations/spreadsimple/',
  helpUrl: 'https://www.pabbly.com/connect/integrations/spreadsimple/',
  description: `
  Pabbly Connect is a powerful automation tool that allows you to connect your SpreadSimple website with many other tools and services.

  Follow the [Pabbly Connect Instructions](https://www.pabbly.com/connect/integrations/spreadsimple/) to learn more.
  `,

  formFields: [],

  actions: []
}
