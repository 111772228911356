export default {
  published: true,
  pro: true,
  featureRestrictionsGroup: 'blogIntegrations',
  name: 'quickblog',
  title: 'QuickBlog',
  logoUrl: '/img/integrations/quickblog.png',
  bgColor: '#ef3f59',
  categories: ['Content Management'],
  comingSoon: false,
  builtIn: false,
  url: 'https://quickblog.co',
  helpUrl: 'https://help.quickblog.co/',
  description: `
  Quickblog gives bloggers and agencies an embedded, super-fast, SEO ready blog for their websites and clients' websites, that ranks on Search Engines and earns passive income, without spending any time or $$$ on theme coding, editing or design.
  <a href="https://help.quickblog.co/" target="_blank">QuickBlog help center</a>
  `,

  formFields: [
    {
      name: 'enabled',
      type: 'toggle',
      label: 'Enabled',
      default: false,
      validations: [
        'required'
      ],
      secret: false
    },
    {
      name: 'blogScript',
      type: 'textarea',
      showIf: ['enabled'],
      label: 'Blog Script',
      default: '',
      placeholder: '',
      validations: [
        'required'
      ],
      secret: false
    },
    {
      name: 'blogPageSlug',
      type: 'multiselect',
      showIf: ['enabled'],
      label: 'Choose blog pages',
      default: '',
      placeholder: '',
      validations: ['required'],
      options: 'runExternalFunction:getPages',
      secret: false
    }

  ],

  actions: []
}
