export default {
  published: true,
  pro: true,
  featureRestrictionsGroup: 'automationIntegrations',
  name: 'albato',
  title: 'Albato',
  headline: '',
  logoUrl: '/img/integrations/albato.svg',
  coverUrl: 'https://spreadsimple.s3.us-east-2.amazonaws.com/7ef91279-dae2-4876-b975-72ff97fb198a_albato-spreadsimle.gif',
  bgColor: '#E17352',
  categories: ['Automation'],
  comingSoon: false,
  builtIn: true,
  url: 'https://albato.com',
  helpUrl: 'https://albato.com/app-spreadsimple',
  description: `
  Albato lets you connect CRM, analytics, communications and other systems in 5 minutes.

  Albato has <a href="https://albato.com/app-spreadsimple" target="_blank">a built-in integration with SpreadSimple</a>.

  Just follow the instructions to make your own integration.
  `,

  formFields: [],

  actions: []
}
