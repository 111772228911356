<!-- eslint-disable vue/valid-v-on -->
<template>
  <Modal v-model="integrationModalOpened" :closeOnClickOutside="false" class="integrations-modal">
    <div v-if="value" class="integrations-modal__inner">
      <div class="integrations-modal__head">
        <img v-if="value.logoUrl" :src="value.logoUrl" class="integrations-modal__head-icon">
        <h4 class="integrations-modal__head-title">{{ value.title }}</h4>
        <div class="integrations-modal__head-status" :class="{ 'is-active': ['Connected', 'Built-in'].includes(value.enabledStatus) }"> {{ value.enabledStatus }} </div>
      </div>
      <div class="integrations-modal__body">
        <img v-if="value.coverUrl" :src="value.coverUrl" class="integrations-modal__img">
        <div class="integrations-modal__content">
          <div class="integrations-modal__content-left-side">
            <div class="integrations-modal__about" v-html="renderedDescription">
            </div>
            <ProOptionWrapper :featureName="value.featureRestrictionsGroup">
              <form v-if="hasForm && $v" class="integrations-modal__form" @submit.prevent="submit()">
                <FormField
                  v-for="field in fields"
                  v-show="showField(field)"
                  :key="field.name"
                  :label="field.type === 'toggle' || field.type === 'color'? '' : field.label"
                  class="integrations-modal__form-item"
                  >
                    <!-- v-model.trim="field.value" -->
                  <v-text-field
                    v-if="field.type === 'text'"
                    v-model.trim="formValues[field.name]"
                    :placeholder="field.placeholder"
                    @blur="el => handleFieldBlur(el, field)"
                  ></v-text-field>
                  <div v-if="showField(field)" class="form-field__error">{{validationMsg($v.formValues[field.name])}}</div>

                  <v-textarea
                    v-if="field.type === 'textarea'"
                    v-model.trim="formValues[field.name]"
                    :placeholder="field.placeholder"
                    :auto-grow="true"
                    spellcheck="false"
                    :rows="field.rows || 10"
                    @blur="el => handleFieldBlur(el, field)"
                  >
                  </v-textarea>
                  <div v-if="showField(field)" class="form-field__error">{{validationMsg($v.formValues[field.name])}}</div>

                  <div
                    v-if="field.type === 'toggle'"
                    class="side-form__item is-justified">
                    <div class="side-form__item-label">{{ field.label }}</div>
                    <label :class="{ 'selected': formValues[field.name] }" class="switcher">
                      <input v-model="formValues[field.name]" class="switcher__input" type="checkbox">
                      <span class="switcher__indicator"></span>
                    </label>
                  </div>

                  <v-select
                    v-if="field.type === 'select'"
                    v-model="formValues[field.name]"
                    :items="setOptions(field.options)"
                    item-text="label"
                    item-value="value"
                    item-disabled="disabled"
                    attach
                    :placeholder="field.label"
                    menu-props="offsetY"
                    append-icon="keyboard_arrow_down"
                    ></v-select>

                  <v-select
                    v-if="field.type === 'multiselect'"
                    v-model="formValues[field.name]"
                    :items="setOptions(field.options)"
                    item-text="label"
                    item-value="value"
                    attach
                    chips
                    deletable-chips
                    multiple
                    :placeholder="field.label"
                    menu-props="offsetY"
                    append-icon="keyboard_arrow_down"
                    >
                  </v-select>

                  <div
                    v-if="field.type === 'color'"
                    class="side-form__item is-justified">
                    <div class="side-form__item-label">{{ field.label }}</div>
                    <ColorPicker v-model="formValues[field.name]" class="for-mailchimp" />
                  </div>

                  <template
                    v-if="field.type === 'tel'">
                    <vue-tel-input
                      class="phone-input"
                      :value="formValues[field.name]"
                      :validCharactersOnly="false"
                      :autoFormat="true"
                      @input="(val, obj) => { formValues[field.name] = obj.number }"
                      @validate="(val) => setComponentValidation(field.name, val.valid)"
                    ></vue-tel-input>
                    <div v-if="showField(field)" class="form-field__error">{{validationMsg($v.formValues[field.name])}}</div>
                  </template>
                </FormField>
                <button class="integrations-modal__form-btn btn btn--uppercase" type="submit">Save</button>
                <p v-if="validationStatus !== ''" style="color: #ff4d4d;font-size: 10px;">
                  {{ validationStatus }}
                </p>
                <!-- <button class="integrations-modal__form-btn btn btn--uppercase btn--transparent btn--danger">Connect Google analytics</button> -->
              </form>
            </ProOptionWrapper>

          </div>
          <div class="integrations-modal__content-right-side">
            <UiMsgBox
              v-if="integrationIsAllowed === 'sandbox'"
              icon="mf-error_outline"
              color="warning"
              size="s"
              class="mb-24"
              >
              <p><b>Sandbox mode only</b></p>
              <p>Please note that on the current plan this payment gateway will work in sandbox mode only. You will not be able to receive real payments in the sandbox mode.</p>
              <UpgradePlanButton :navigateToBilling="true">
                <b>Upgrade Plan</b>
              </UpgradePlanButton>
            </UiMsgBox>

            <div v-if="value.url" class="integrations-modal__cta">
              <a :href="value.url" target="_blank" class="integrations-modal__cta-item is-highlighted">
                <i class="icon-external-link integrations-modal__cta-item-icon"></i>
                Go to website
              </a>
              <!-- <span class="integrations-modal__cta-item">
                <i class="icon-link2 integrations-modal__cta-item-icon"></i>
                Copy link
              </span> -->
            </div>
            <div class="integrations-modal__footnote">
              <p>To share feedback or request a feature, <a :href="supportMailTo">send us a note</a> </p>
              <p v-if="value.helpUrl">If you don't know how to integrate, see the <a :href="value.helpUrl" target="_blank">Connection guide</a> </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import Modal from '@/components/modal'
import UiMsgBox from '@/components/ui-msg-box/UiMsgBox.vue'
import FormField from '@/components/form-field'
import clone from 'clone'
import MarkdownIt from 'markdown-it'
import Vue from 'vue'
import Vuelidate from 'vuelidate'
import ColorPicker from '@/components/color-picker'
import { helpers, required, minLength, maxLength, url, email, numeric } from 'vuelidate/lib/validators'
import { validationMessage } from 'vuelidate-messages'
import ProOptionWrapper from '@/components/pro-option-wrapper'
import { mapState } from 'vuex'
import UpgradePlanButton from '@/components/pro-option-wrapper/UpgradePlanButton.vue'
import { allowFeature } from '@/modules/features-checker'
import 'vue-tel-input/dist/vue-tel-input.css'
import api from '@/services/api'

const formMessages = {
  required: () => 'Required',
  minLength: ({ $params }) => `Must be at least ${$params.minLength.min} characters.`,
  maxLength: ({ $params }) => `Must be max ${$params.maxLength.max} characters.`,
  requiredIf: ({ $params }) => `This field is required in ${$params.requiredIf.prop.split('=')[1]} mode.`,
  url: ({ $params }) => `Must be valid URL.`,
  email: ({ $params }) => `Must be valid email.`,
  numeric: ({ $params }) => `Must be numeric only.`,
  startsWith: ({ $params }) => `Must start with ${$params.startsWith.value.join(' or ')}.`,
  tel: ({ $params }) => `Please provide a valid phone number`,
  checkSandboxMode: ({ $params }) => `This value is not allowed in sandbox mode`,
  checkSandboxKey: ({ $params }) => `Invalid test api key`,
  containsValue: ({ $params }) => `Must contain ${$params.containsValue.value.join(' or ')}.`
}

const builtInValidation = {
  required,
  minLength,
  maxLength,
  url,
  email,
  numeric,
  startsWith: (...args) => helpers.withParams(
    { type: 'options', value: [...args] },
    (value) => args.some(arg => value === '' || value.startsWith(arg))
  ),
  containsValue: (...args) => helpers.withParams(
    { type: 'options', value: [...args] },
    (value) => args.some(arg => value === '' || value.includes(arg))
  ),
  tel: function (fieldName) {
    return function (...args) {
      return !!this.componentValidations[fieldName]
    }
  },
  requiredIf: (prop) => helpers.withParams(
    { type: 'requiredIf', prop },
    function (value, parentVm) {
      if (parentVm[prop.split('=')[0]] === prop.split('=')[1]) {
        return value.length > 0
      }
      return true
    }
  )
}

const md = new MarkdownIt({
  html: true,
  linkify: true,
  typographer: true
})

Vue.use(Vuelidate)
export default {
  name: 'IntegrationModal',
  components: {
    FormField,
    Modal,
    ProOptionWrapper,
    ColorPicker,
    VueTelInput: () => import('vue-tel-input').then(m => m.VueTelInput),
    UiMsgBox,
    UpgradePlanButton
  },
  props: {
    value: {
      type: Object
    },
    integrationsData: {
      type: Array
    },
    apiKey: {
      type: String,
      default: 'NONE'
    },
    websiteUrl: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      fields: [],
      formValues: {},
      componentValidations: {},
      validationStatus: ''
    }
  },
  computed: {
    ...mapState('app', [
      'userData',
      'currentSpreadViewData'
    ]),
    options () {
      return this.currentSpreadViewData.options
    },
    integrationIsAllowed () {
      return allowFeature(this.value.featureRestrictionsGroup, {
        user: this.userData,
        website: this.currentSpreadViewData
      })
    },
    integrationModalOpened: {
      get () { return this.value !== null },
      set (value) {
        if (!value) {
          this.validationStatus = ''
          this.$emit('input', null)
        }
      }
    },

    renderedDescription () {
      if (!this.value) return ''
      const mdCode = this.value.description
        .replace(/<apikey.+?>/ig, this.apiKey)
        .replace(/<websiteurl.+?>/ig, this.websiteUrl)

      return md.render(mdCode)
    },

    supportMailTo () {
      if (!this.value) return ''
      return `mailto:support@spreadsimple.com?subject=${encodeURIComponent('Integration feedback: ' + this.value.title)}`
    },

    validationRules () {
      return { formValues: this.fields.reduce((acc, el) => {
        if (this.showField(el)) {
          acc[el.name] = {}
          el.validations.forEach((rule) => {
            if (typeof rule === 'string') {
              const [ruleName, ...args] = rule.split(':')
              if (ruleName === 'runInternalValidationFunction') {
                if (args.length) {
                  args.forEach((funcName) => {
                    acc[el.name][funcName] = this.value.functions[funcName](this.integrationIsAllowed)
                  })
                }
                return
              }
              if (ruleName === 'tel') {
                acc[el.name][ruleName] =
                builtInValidation[ruleName](el.name)
                return
              }
              acc[el.name][ruleName] = args.length ? builtInValidation[ruleName](...args) : builtInValidation[ruleName]
            }
          })
        }
        return acc
      }, {}) }
    },

    hasForm () {
      return this.value.formFields && this.value.formFields.length > 0
    }
  },
  watch: {
    value (value) {
      this.fields = value ? clone(this.value.formFields) : []
      this.formValues = this.fields.reduce((acc, el) => {
        acc[el.name] = el.value
        return acc
      }, {})
    }
  },
  validations () {
    return this.validationRules
  },
  methods: {
    getPages () {
      return this.options.routes.reduce((acc, route) => {
        if (route.type === 'page') {
          acc.push({
            value: route.navUrl,
            label: route.navTitle
          })
        }
        return acc
      }, [])
    },
    getActiveEmailProviders () {
      const providers = [
        { label: 'Universal Email Subscription', value: 'universal-email-subscription' },
        { label: 'MailChimp', value: 'mailchimp' },
        { label: 'Moosend', value: 'moosend' },
        { label: 'SendFox', value: 'sendfox' },
        { label: 'Mailerlite', value: 'mailerlite' },
        { label: 'Hubspot', value: 'hubspot' }
      ]
      return providers.map(p => {
        const isActive = this.integrationsData.some(i => i.name === p.value && i.data.some(d => d.name === 'enabled' && d.value))
        if (!isActive) {
          p.label = `${p.label} (requires set up)`
        }
        p['disabled'] = !isActive
        return p
      })
    },
    setOptions (options) {
      if (Array.isArray(options)) {
        return options
      } else if (options.includes('runExternalFunction')) {
        return this[options.split(':')[1]]() // run function by name
      } else {
        console.warn('Error: no options found, but required')
        return []
      }
    },
    objPut (key, val) {
      return { [key]: val }
    },
    setComponentValidation (name, val) {
      if (!name) return
      this.$set(this.componentValidations, name, val)
    },
    async submit () {
      this.$v.$touch()
      this.validationStatus = ''
      if (this.$v.$invalid) {
        this.validationStatus = this.$t('global.formSubmitError')
        return
      }
      const data = this.fields.map(field => {
        return {
          name: field.name,
          value: this.formValues[field.name],
          secret: field.secret
        }
      })
      this.$emit('update', {
        name: this.value.name,
        data
      })
      this.$trackEvent({
        'event': 'useAddon',
        'addonData': {
          name: this.value.name,
          status: data ? data.find(i => i.name === 'enabled').value : false
        }
      })
      console.log(this.value.name)
      console.log(data)
      const paymentsList = ['paypal', 'stripe', 'razorpay', 'billplz', 'payplug', 'mollie']
      if (paymentsList.includes(this.value.name)) {
        try {
          await api.sendgridSendFeatures(
            [{
              key: `addon${this.capitalizeFirstLetter(this.value.name)}`,
              value: data ? data.find(i => i.name === 'enabled').value : false
            }]
          )
        } catch (e) {
          console.log(e)
        }
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    showField (field) {
      if (!field.showIf || !field.showIf.length === 0) return true
      const showIfCriteria = field.showIf.map(key => {
        const [field, criteria] = key.split('=')
        return {
          field,
          value: criteria === undefined ? true : String(criteria)
        }
      })
      return showIfCriteria.every(({ field, value }) => {
        if (value === true) return !!this.formValues[field]
        const OR = '||'
        if (value.includes(OR)) {
          const values = value.split(OR)
          return values.some(v => this.formValues[field] === v)
        }
        return this.formValues[field] === String(value)
      })
    },
    validationMsg: validationMessage(formMessages),
    handleFieldBlur (el, field) {
      if (field.name === 'url' && el.target.value.includes('docs.google.com/forms')) {
        const newValue = decodeURIComponent(el.target.value)
        this.formValues[field.name] = newValue
      }
      if (!field.onBlur) return
      field.onBlur(el, field, this.formValues)
    }
  }
}
</script>
