export default {
  published: true,
  pro: true,
  featureRestrictionsGroup: 'emailIntegrations',
  name: 'sendfox',
  title: 'SendFox',
  headline: '',
  logoUrl: '/img/integrations/sendfox.svg',
  // coverUrl: '/img/integrations/sendfox-cover.png',
  bgColor: '#485570',
  categories: ['Email Marketing'],
  comingSoon: false,
  builtIn: false,
  url: 'https://sendfox.com',
  helpUrl: '',
  description: `
  Sendfox is an email marketing solution that allows you to send mass emails out to your subscribers.

  You can add an email subscription form to the intro section and also as a popup.

  **How to get Personal Access Token:**
  1. <a href="https://sendfox.com/account/oauth" target="_blank">Go to Settings -> API (OAuth Apps)</a>
  2. Click "Create New Token".
  3. Give your token a name, e.g. "a token for add-on" and then click "Create".
  4. Select the whole content of the appeared text area and copy it.

  **How to get List ID:**
  1. <a href="https://sendfox.com/dashboard/lists" target="_blank">Go to Lists section</a>
  2. Open an existing list or create one.
  3. Copy the numeric list id from the URL (<a href="https://cln.sh/jJs16p" target="_blank">sample</a>)

  `,

  formFields: [
    {
      name: 'enabled',
      type: 'toggle',
      label: 'Enabled',
      default: false,
      validations: [],
      secret: false
    },
    {
      name: 'personalAccessToken',
      type: 'text',
      showIf: ['enabled'],
      label: 'Personal Access Token',
      default: '',
      validations: ['required'],
      secret: true
    },
    {
      name: 'listId',
      type: 'text',
      showIf: ['enabled'],
      label: 'List ID',
      default: '',
      validations: ['required'],
      secret: true
    },
    {
      name: 'showIntro',
      type: 'toggle',
      showIf: ['enabled'],
      label: 'Show Form in Intro Section',
      default: false,
      validations: [],
      secret: false
    },
    {
      name: 'showPopup',
      type: 'toggle',
      showIf: ['enabled'],
      label: 'Show Floating Button and Popup',
      default: false,
      validations: [],
      secret: false
    },
    {
      name: 'title',
      type: 'text',
      showIf: ['enabled', 'showPopup'],
      label: 'Popup Title',
      default: '',
      placeholder: '',
      validations: [
        'required',
        'maxLength:160'
      ],
      secret: false
    },
    {
      name: 'subtitle',
      type: 'text',
      showIf: ['enabled', 'showPopup'],
      label: 'Popup Subtitle',
      default: '',
      placeholder: '',
      validations: [
        'maxLength:300'
      ],
      secret: false
    },
    {
      name: 'askForName',
      type: 'toggle',
      showIf: ['enabled'],
      label: 'Ask for First and Last Name',
      default: false,
      validations: [],
      secret: false
    },
    {
      name: 'buttonTitle',
      type: 'text',
      showIf: ['enabled'],
      label: 'Button Title',
      default: 'Subscribe',
      placeholder: 'Subscribe',
      validations: [
        'required',
        'maxLength:50'
      ],
      secret: false
    },
    {
      name: 'buttonColor',
      type: 'color',
      showIf: ['enabled'],
      label: 'Button Color',
      default: '#999999',
      validations: [
        'required'
      ],
      secret: false
    }
  ],

  actions: []
}
