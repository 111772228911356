// reset the previously stored English translations
// to make them empty, so that they can be automatically
// translated to the selected language

export default function fixDefaultTranslations (options, hasLocale = false) {
  if (hasLocale) return options // apply fix only for legacy options before locale support

  if (options.viewer.tileOptions.cta.title.trim() === 'Buy') {
    options.viewer.tileOptions.cta.title = ''
  }
  if (options.cart.cart_title.trim() === 'Your cart') {
    options.cart.cart_title = ''
  }
  if (options.cart.cart_button_text.trim() === 'Checkout') {
    options.cart.cart_button_text = ''
  }
  if (options.cart.cart_tm_title.trim() === 'Thank you!') {
    options.cart.cart_tm_title = ''
  }
  if (options.cart.cart_tm_text.trim() === '<p class="sv-text-reset">We received your order. We will contact you soon.</p>' ||
    options.cart.cart_tm_text.trim() === 'We received your order. We will contact you soon.'
  ) {
    options.cart.cart_tm_text = ''
  }

  return options
}
