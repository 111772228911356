<template>
  <form class="spread-form" @submit.prevent="submitForm" >
    <div class="spread-form__form-elements">
      <template>
        <input
          v-if="forAddViewPage"
          v-model.trim="$v.form.url.$model"
          placeholder="Paste Google Sheet link here"
          type="text"
          class="spread-form__input"
        >
        <v-text-field
          v-else
          v-model.trim="$v.form.url.$model"
          label="Linked spread sheet"
          class="spread-form__sidebar-input"
        />
      </template>

      <template>
        <button
          v-if="forAddViewPage"
          :disabled="$v.form.url.$dirty && $v.form.url.$invalid"
          :class="{'loading': isLoading}"
          class="spread-form__add-button"
          type="submit"
        >
        <div v-if="isLoading" class="icon-loader"></div>
        <span v-else>{{ $t('global.continue') }}</span>
        </button>
        <button
          v-else
          :class="{'loading': isLoading}"
          :disabled="isLoading"
          class="spread-form__sidebar-add-btn"
          type="submit"
        >
          <div v-if="isLoading" class="icon-loader"></div>
          <span v-else>Submit</span>
        </button>
      </template>
    </div>

    <div class="spread-form__messages-wrapp">
      <span
        v-if="$v.form.url.$dirty && !$v.form.url.required"
        class="spread-form__message error"
      >
        {{ $t('pageAddView.pastLinkErrorNotCorrectUrl') }}
      </span>

      <span
        v-if="!$v.form.url.url"
        class="spread-form__message error"
      >
        {{ $t('pageAddView.pastLinkErrorNotCorrectUrl') }}
      </span>

      <span
        v-if="$v.form.url.required && $v.form.url.url && !$v.form.url.isSpreadsheetUrl"
        class="spread-form__message error"
      >
        {{ $t('pageAddView.pastLinkErrorNotGoogleUrl') }}
      </span>

      <span
        v-if="avaliabilityError !== ''"
        class="spread-form__message error"
      >
        {{ avaliabilityError }}
      </span>
    </div>

    <slot></slot>
  </form>
</template>
<script>
import api from '@/services/api/'
import { getIdFromUrl, validateSheetUrl } from 'google-sheets-data'
import { required, url } from 'vuelidate/lib/validators'
import Vuelidate from 'vuelidate'
import Vue from 'vue'
Vue.use(Vuelidate)
export default {
  name: 'SpreadForm',
  props: {
    loading: {
      type: Boolean
    },
    forAddViewPage: {
      type: Boolean,
      default: false
    },
    initialVal: {
      type: String
    }
  },
  data () {
    return {
      form: {
        url: ''
      },
      avaliabilityError: '',
      formLoading: false
    }
  },
  computed: {
    isLoading () {
      return this.formLoading || this.loading
    }
  },
  watch: {
    initialVal: function (val) {
      this.form.url = val
    }
  },
  validations: {
    form: {
      url: {
        required,
        url,
        isSpreadsheetUrl: validateSheetUrl
      }
    }
  },
  methods: {
    async submitForm () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.formLoading = true
        try {
          const id = getIdFromUrl(this.form.url)
          await api.checkGoogleSheetAvailability(id)
          this.$emit('submit', this.form.url)
          this.$trackEvent({
            'event': 'changeGS'
          })
        } catch (e) {
          this.avaliabilityError = 'Access to the spreadsheet denied. Make sure that "Anyone with the link" option is on.'
        } finally {
          this.formLoading = false
        }
      }
    }
  },
  created () {
    this.form.url = this.initialVal
  }
}
</script>
