import Init from '@/init.js'

// To add to window
if (!window.Promise) {
  window.Promise = Promise
}

Init
  .addStore()
  .addEventHub()
  .addVueAsyncOperations()
  .addPortalVue()
  .addSelectWrapper()
  .addPreloader()
  .addRouter()
  .addToasted()
  .addElementOnDemand()
  .addElementUiLang()
  .addClickOutside()
  .addI18n()
  .addVuetify()
  .addMdEditor()
  .addAnalytics()
  .addSentry()
  .run()
  .addFeatureTogglers()
