const mapStyleOptions = [
  { label: 'Standart', value: 'standart' },
  { label: 'Silver', value: 'silver' },
  { label: 'Retro', value: 'retro' },
  { label: 'Dark', value: 'dark' },
  { label: 'Night', value: 'night' },
  { label: 'Aubergine', value: 'aubergine' },
  { label: 'Custom Style', value: 'custom' }
]

export default {
  published: true,
  pro: true,
  featureRestrictionsGroup: 'mapsIntegrations',
  name: 'google-maps',
  title: 'Google Maps',
  headline: '',
  logoUrl: '/img/integrations/google-maps.svg',
  coverUrl: '/img/integrations/google-maps-cover.png',
  bgColor: '#4285F4',
  categories: ['Maps'],
  comingSoon: false,
  builtIn: false,
  url: 'https://developers.google.com/maps',
  helpUrl: 'https://help.spreadsimple.com/en/article/google-maps-add-on-4ag4nq/',
  description: `
  Build awesome apps with Google’s knowledge of the real world.

  Create real-world, real-time experiences with the latest Maps, Routes, and Places features from Google Maps Platform.
  Built by the Google team for developers everywhere.
  `,

  formFields: [
    {
      name: 'enabled',
      type: 'toggle',
      label: 'Enabled',
      default: false,
      validations: [
        'required'
      ],
      secret: false
    },
    {
      name: 'apiKey',
      type: 'text',
      showIf: ['enabled'],
      label: 'API key',
      default: '',
      placeholder: 'AIzaSyDUTSPwiUYOsORiOQnWubhD3vN7jR8ypRM',
      validations: [
        'required',
        'minLength:30',
        'maxLength:40'
      ],
      secret: false
    },
    {
      name: 'mapStyle',
      type: 'select',
      showIf: ['enabled'],
      label: 'Map Style',
      default: 'standart',
      placeholder: '',
      options: mapStyleOptions,
      validations: [
        'required'
      ],
      secret: false
    },
    {
      name: 'customStyle',
      type: 'textarea',
      showIf: ['enabled', 'mapStyle=custom'],
      label: 'Custom Style JSON',
      default: '',
      placeholder: '',
      validations: [
        'requiredIf:mapStyle=custom'
      ],
      secret: false
    }
  ],

  actions: []
}
