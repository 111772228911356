export const dealTypeLabels = {
  starter: 'Starter',
  business: 'Business',
  connect: 'Connect',
  bulkSmall: 'Bulk Small',
  bulkLarge: 'Bulk Large',
  bulkMega: 'Bulk Mega',
  pro: 'Pro',
  free: 'Free',
  legacyFree: 'Legacy Free',
  appsumo: 'LTD'
}
