export default {
  published: true,
  pro: false,
  featureRestrictionsGroup: 'builtInIntegrations',
  name: 'vimeo',
  title: 'Vimeo',
  headline: '',
  logoUrl: '/img/integrations/vimeo.svg',
  // coverUrl: '/img/integrations/vimeo-cover.png',
  coverUrl: 'https://spreadsimple.s3.us-east-2.amazonaws.com/e2bc95f7-c37d-4930-9f78-752f55011e9b_vimeo.png',
  bgColor: '#4FB7E6',
  categories: ['Video'],
  comingSoon: false,
  builtIn: true,
  url: 'https://vimeo.com',
  helpUrl: '',
  description: `
  Place the URLs to your Vimeo videos in a column in your Google Sheet.

  Then map the column to the **Image (Gallery)** in the 'Content' Tab.
  `,

  formFields: [],

  actions: []
}
