var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      attrs: {
        value: _vm.value,
        modalName: _vm.modalTitle,
        closeOnClickOutside: false,
        alignTitleLeft: ""
      },
      on: { input: _vm.closeModal }
    },
    [
      _c(
        "div",
        [
          _c("Preloader", { attrs: { loading: _vm.isLoading } }),
          !_vm.isLoading && _vm.quizStep === "DiscountOffer"
            ? _c("DiscountOffer", {
                attrs: { features: _vm.features, hasDiscount: _vm.hasDiscount },
                on: {
                  "take-discount": _vm.takeDiscount,
                  unsubscribe: _vm.proceedToReasons,
                  "close-modal": _vm.closeModal
                }
              })
            : _vm._e(),
          !_vm.isLoading && _vm.quizStep === "DiscountTaken"
            ? _c("DiscountTaken", {
                attrs: {
                  nextBillingPeriod: _vm.currentDeal.nextBillDate,
                  nextBillingSum: _vm.nextBillingSum
                },
                on: { "close-modal": _vm.closeModal }
              })
            : _vm._e(),
          !_vm.isLoading && _vm.quizStep === "UnsubscribeReasons"
            ? _c("UnsubscribeReasons", {
                attrs: {
                  reasons: _vm.reasons,
                  quizError: _vm.quizError,
                  pending: _vm.isLoading
                },
                on: {
                  "close-modal": _vm.closeModalReasons,
                  "reason-checked": _vm.reasonChecked,
                  "reason-detailed": _vm.reasonDetailed,
                  "submit-quiz": _vm.submitQuiz
                }
              })
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }