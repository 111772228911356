const mapStyleOptions = [
  { label: 'Mapbox Streets', value: 'streets-v12' },
  { label: 'Mapbox Outdoors', value: 'outdoors-v12' },
  { label: 'Mapbox Light', value: 'light-v11' },
  { label: 'Mapbox Dark', value: 'dark-v11' },
  { label: 'Mapbox Satellite', value: 'satellite-v9' },
  { label: 'Mapbox Satellite Streets', value: 'satellite-streets-v12' },
  { label: 'Mapbox Navigation Day', value: 'navigation-day-v1' },
  { label: 'Mapbox Navigation Night', value: 'navigation-night-v1' }
]

export default {
  published: true,
  pro: true,
  featureRestrictionsGroup: 'mapsIntegrations',
  name: 'mapbox',
  title: 'Mapbox',
  headline: '',
  logoUrl: '/img/integrations/mapbox.svg',
  coverUrl: '/img/integrations/mapbox-cover.png',
  bgColor: '#000',
  categories: ['Maps'],
  comingSoon: false,
  builtIn: false,
  url: 'https://www.mapbox.com',
  helpUrl: 'https://help.spreadsimple.com/en/article/mapbox-add-on-d4n7lb',
  description: `
  Bring location data to life with beautiful base maps, versatile upload and design tools and cross-platform rendering.
  Mapbox helps build engaging maps that delight users and bring them back for more.
  `,

  formFields: [
    {
      name: 'enabled',
      type: 'toggle',
      label: 'Enabled',
      default: false,
      validations: [
        'required'
      ],
      secret: false
    },
    {
      name: 'apiKey',
      type: 'text',
      showIf: ['enabled'],
      label: 'API key',
      default: '',
      placeholder: 'pk.eyJ1IjoidmxhZGlzbGF2LWRldnN0YXJrIiwiYSI6ImNsbGxsN2gybDBnY2IzbW5mZWpnNXI5bjcifQ.asBa_mbs_VR0ps_O-sLICw',
      validations: [
        'required'
      ],
      secret: false
    },
    {
      name: 'mapStyle',
      type: 'select',
      showIf: ['enabled'],
      label: 'Map Style',
      default: 'streets-v12',
      placeholder: '',
      options: mapStyleOptions,
      validations: [
        'required'
      ],
      secret: false
    }
  ],

  actions: []
}
