export default {
  published: true,
  pro: true,
  featureRestrictionsGroup: 'paymentIntegrations',
  name: 'payplug',
  title: 'Payplug',
  logoUrl: '/img/integrations/payplug.svg',
  bgColor: '#1aac7d',
  categories: ['Payment Gateway'],
  comingSoon: false,
  builtIn: false,
  url: 'https://payplug.com',
  helpUrl: 'https://help.spreadsimple.com/en/article/payplug-checkout-ur9fvk/',
  description: `
  Your French omnichannel payment solution
  <a href="https://support.payplug.com/hc/en-gb" target="_blank">PayPlug help center</a>
  `,

  functions: {
    checkSandboxKey: (mode) => (value) => {
      if (mode === 'sandbox' && !value.startsWith('sk_test_')) return false
      return true
    }
  },

  formFields: [
    {
      name: 'enabled',
      type: 'toggle',
      label: 'Enabled',
      default: false,
      validations: [
        'required'
      ],
      secret: false
    },
    {
      name: 'buttonText',
      type: 'text',
      showIf: ['enabled'],
      label: 'Button Text',
      default: '💳 Continue to payment...',
      placeholder: 'Continue...',
      validations: [
        'required',
        'minLength:2',
        'maxLength:50'
      ],
      secret: false
    },
    {
      name: 'secretKey',
      type: 'text',
      showIf: ['enabled'],
      label: 'Secret Key',
      default: '',
      placeholder: '',
      validations: [
        'required',
        'maxLength:100',
        'startsWith:sk_live_:sk_test_',
        'runInternalValidationFunction:checkSandboxKey'
      ],
      secret: true
    },
    {
      name: 'customTitle',
      type: 'text',
      showIf: ['enabled'],
      label: 'Custom title',
      default: 'PayPlug',
      placeholder: '',
      validations: [
        'maxLength:50'
      ],
      secret: false
    },
    {
      name: 'customDescription',
      type: 'text',
      showIf: ['enabled'],
      label: 'Description',
      default: '',
      placeholder: '',
      validations: [
        'maxLength:100'
      ],
      secret: false
    }
  ],

  actions: []
}
