<template>
  <div class="side-form">
    <div v-if="value.length" class="side-form__item side-form__draggable-wrap">
      <draggable
        :value="value"
        tag="div"
        handle=".draggable__item-trigger"
        class="draggable"
        @input="val => onDrag(val)"
      >
        <div
          v-for="(field, index) in value"
          :key="index"
          class="draggable__item"
          >
          <div class="draggable__item-trigger icon-draggable"></div>
          <div class="draggable__item-title-wrap" @click="onEdit(field, index)">
            <span class="draggable__item-title">{{ field.label }}</span>
          </div>
          <div class="draggable__item-controls">
            <el-dropdown
              trigger="click"
              class="draggable__item-control"
            >
              <i class="icon-gear"></i>
              <el-dropdown-menu slot="dropdown" class="draggable__dropdown-menu">
                <el-dropdown-item @click.native="onEdit(field, index)">Edit</el-dropdown-item>
                <el-dropdown-item  @click.native="onDelete(index)">Delete</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </draggable>
    </div>
    <div class="side-form__btns">
      <button type="button" class="btn btn--transparent" @click="builderModalOpened = true">{{ addButtonTitle }}</button>
    </div>
    <Modal
      v-model="builderModalOpened"
      :closeOnClickOutside="false"
      :modalName="modalTitle"
      :hasCloseButton="true"
    >
      <OptionItemBuilder
        :value="currentEditingField"
        :schema="schema"
        @input="onAddField"
        @on-edit="onUpdateField"
        @cancel-submission="builderModalOpened = false"
      />
    </Modal>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
import Modal from '@/components/modal'
import OptionItemBuilder from './OptionItemBuilder.vue'
export default {
  name: 'OptionsBuilder',
  components: {
    draggable,
    Modal,
    OptionItemBuilder
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    addButtonTitle: {
      type: String,
      required: true
    },
    modalTitle: {
      type: String,
      required: true
    },
    schema: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      builderModalOpened: false,
      currentEditingField: null,
      currentEditingIndex: null
    }
  },
  watch: {
    formBuilderModalOpened (val) {
      if (!val) {
        this.closeModalAndCleanData()
      }
    },
    value (val) {
      this.$trackFeature('checkout', `${this.schema}Changed`, val.length > 0)
    }
  },
  methods: {
    onAddField (val) {
      const formFields = this.value
      formFields.push(val)
      this.$emit('input', formFields)
      this.closeModalAndCleanData()
    },
    onUpdateField (val) {
      const formFields = this.value
      formFields.splice(this.currentEditingIndex, 1, val)
      this.$emit('input', formFields)
      this.closeModalAndCleanData()
    },
    onDrag (val) {
      this.$emit('input', val)
    },
    onEdit (val, index) {
      this.currentEditingField = val
      this.currentEditingIndex = index
      this.builderModalOpened = true
    },
    onDelete (index) {
      const formFields = this.value
      formFields.splice(index, 1)
      this.$emit('input', formFields)
    },
    closeModalAndCleanData () {
      this.builderModalOpened = false
      this.currentEditingField = null
      this.currentEditingIndex = null
    }
  }
}
</script>

<style scoped>
  .draggable__item.hidden {
    opacity: 0.5;
  }
</style>
