<template>
  <div class="fs-editor">
    <div class="fs-editor__head">
      <div class="fs-editor__head-left-side">
        <h6 class="fs-editor__head-title text-reset">Markdown</h6>
      </div>
      <div class="fs-editor__head-right-side">
        <h6 class="fs-editor__head-title text-reset">Preview</h6>
        <div class="fs-editor__head-close-btn-wrap">
          <button
          type="button"
          class="fs-editor__head-close-btn icon-close2 button-reset"
          @click="$emit('onclose')"
          ></button>
        </div>
      </div>
    </div>
    <div class="fs-editor__body sv-content-block">
      <slot/>
      <!-- <div class="fs-editor__markdown">
        Editor
      </div>
      <div class="fs-editor__preview">
        <div class="fs-editor__preview-container fs-editor__content">
          <h1>H1 heading</h1>
          <h2>H2 heading</h2>
          <h3>h3 heading</h3>
          <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diem nonummy nibh euismod tincidunt ut lacreet dolore magna aliguam erat volutpat. Ut wisis enim ad minim veniam, quis nostrud exerci tution ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat</p>
          <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diem nonummy nibh euismod tincidunt ut lacreet dolore magna aliguam erat volutpat. Ut wisis enim ad minim veniam, quis nostrud exerci tution ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat</p>
          <ul>
            <li>lol</li>
            <li>kek</li>
          </ul>
          <ol>
            <li>lol</li>
            <li>kek</li>
          </ol>
          <div class="content-page__toggle">
            <div
              class="content-page__toggle-trigger"
              :class="{ 'is-opened': toggle }"
              @click="toggle = !toggle"
            >
              Toggle
            </div>
            <div v-if="toggle" class="content-page__toggle-content">
              <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diem nonummy nibh euismod tincidunt ut lacreet dolore magna aliguam erat volutpat. Ut wisis enim ad minim veniam, quis nostrud exerci tution ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat</p>
            </div>
          </div>
          <hr>
          <blockquote>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diem nonummy nibh euismod tincidunt ut lacreet dolore magna aliguam erat volutpat. Ut wisis enim ad minim veniam, quis nostrud exerci tution ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat</blockquote>
          <table>
            <caption>Таблица размеров обуви</caption>
            <tr>
              <th>Russia</th>
              <th>UK</th>
              <th>Eu</th>
              <th>Cm.</th>
            </tr>
            <tr><td>34,5</td><td>3,5</td><td>36</td><td>23</td></tr>
            <tr><td>35,5</td><td>4</td><td>36⅔</td><td>23–23,5</td></tr>
            <tr><td>36</td><td>4,5</td><td>37⅓</td><td>23,5</td></tr>
            <tr><td>36,5</td><td>5</td><td>38</td><td>24</td></tr>
          </table>
          <figure>
            <img src="https://www.vivastore.gr/image/catalog/Vivacom/Manufacturers/Samsung_Logo-modf_highres.jpg" alt="logo">
            <figcaption>Описание</figcaption>
          </figure>
          <ul>
            <li><input type="checkbox"> sdsd</li>
            <li><input type="checkbox" checked="true"> sdsdsd</li>
            <li>[x]</li>
          </ul>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  name: 'FullscreenMdEditor',
  data () {
    return {
      toggle: false
    }
  }
}
</script>
