var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "i",
    _vm._b(
      {
        staticClass: "ui-icon",
        class: [
          { "material-icons": _vm.iconMaterial },
          { "material-icons-filled": _vm.iconMaterialFilled },
          _vm.iconIcomoon ? "icon-" + _vm.iconName : null
        ],
        style: _vm.iconSize ? "--iconSize: " + _vm.iconSize + ";" : null
      },
      "i",
      _vm.$attrs,
      false
    ),
    [
      _vm.iconMaterial || _vm.iconMaterialFilled
        ? [_vm._v("\n    " + _vm._s(_vm.iconName) + "\n  ")]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }