<template>
  <div  class="sorting-constructor">

    <div class="sorting-constructor__item">
      <div class="sorting-constructor__select aside-select">
        <h6 class="sorting-constructor__item-label">Sort by columns</h6>
        <v-select
          v-model="value.ids"
          :items="defaultCols"
          item-text="label"
          item-value="value"
          attach
          menu-props="offsetY"
          chips
          deletable-chips
          multiple
          append-icon="keyboard_arrow_down"
          placeholder="Choose options"
        >
          <template slot="selection" slot-scope="data">
            <DraggableChip
              :id="data.index"
              v-model="value.ids"
            >
              {{ data.item.label }}
            </DraggableChip>
          </template>
        </v-select>
      </div>
      <p
        v-if="v.ids.$dirty && !v.ids.required"
        class="form-field__error"
      >
        {{ $t('global.fieldIsRequired') }}
      </p>
    </div>

    <div class="sorting-constructor__item">
      <div class="sorting-constructor__select aside-select">
        <h6 class="sorting-constructor__item-label">Default sort column</h6>
        <v-select
          v-model="value.default"
          attach
          menu-props="offsetY"
          :items="sortableOptions"
          item-text="label"
          item-value="value"
          append-icon="keyboard_arrow_down"
        >
        </v-select>
      </div>
      <p
        v-if="!v.default.id.required"
        class="form-field__error"
      >
        {{ $t('global.fieldIsRequired') }}
      </p>
    </div>
    <div class="content-form__switch-field">
      <div class="content-form__switcher-wrap">
        <div class="content-form__switcher-label">Reverse default order</div>
          <label
            :class="{ 'selected': value.reverseDefaultOrder }"
            class="switcher"
          >
            <input v-model="value.reverseDefaultOrder" class="switcher__input" type="checkbox" value="tiles">
            <span class="switcher__indicator"></span>
          </label>
      </div>
    </div>
    <div class="content-form__switch-field">
      <div class="content-form__switcher-wrap">
        <div class="content-form__switcher-label">Shuffle items by default</div>
          <label
            :class="{ 'selected': value.shuffleRows }"
            class="switcher"
          >
            <input v-model="value.shuffleRows" class="switcher__input" type="checkbox" value="tiles">
            <span class="switcher__indicator"></span>
          </label>
      </div>
    </div>
  </div>
</template>
<script>
import DraggableChip from '@/components/draggable-chip/DraggableChip.vue'

export default {
  name: 'SortingConstructor',
  components: { DraggableChip },
  props: {
    cols: {
      type: Array,
      required: true
    },
    value: {
      type: Object,
      required: true
    },
    v: {
      type: Object
    }
  },
  data () {
    return {
      defaultSortingOrder: {
        value: {
          id: 'INDEX',
          direction: 'asc'
        },
        label: 'Default order'
      }
    }
  },
  computed: {
    defaultCols () {
      return this.cols
        .map((col) => {
          return { value: col.id, label: col.label }
        })
    },
    sortableCols () {
      return this.defaultCols.filter(col => {
        return this.value.ids.includes(col.value)
      })
    },
    sortableOptions () {
      const defaultSortings = [this.defaultSortingOrder]

      const sortingFields = this.sortableCols.reduce((options, option) => {
        options.push({
          value: {
            direction: 'asc',
            id: option.value
          },
          label: `\u25b3 ${option.label}`
        })
        options.push({
          value: {
            direction: 'desc',
            id: option.value
          },
          label: `\u25bd ${option.label}`
        })
        return options
      }, [])
      return defaultSortings.concat(sortingFields)
    }
  },
  watch: {
    'value.ids': {
      deep: true,
      handler: function (val) {
        // if (!val.length) this.value.enabled = false
        if (!val.includes(this.value.default.id)) this.value.default = this.defaultSortingOrder.value
      }
    },
    'value.reverseDefaultOrder' (val) {
      this.value.default.direction = val ? 'desc' : 'asc'
    }
  },
  created () {
    this.defaultSortingOrder.value.direction = this.value.reverseDefaultOrder ? 'desc' : 'asc'
    // if (this.value.ids.length === 0) {
    //   this.value.ids = this.cols.map(col => col.id)
    //   this.value.ids = []
    //   this.value.default = this.defaultSortingOrder.value
    // }
  }
}
</script>
