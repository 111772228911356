import axios from 'axios'
import store from '@/store'

export const client = axios.create({
  baseURL: process.env.VUE_APP_API_BASE,
  withCredentials: true
})

const request = async (method, url, data) => {
  if (url.includes(':wsId')) {
    const wsId = await store.dispatch('app/refreshWorkspaceId', localStorage.getItem('ssWsId'))
    url = url.replace(':wsId', wsId)
  }

  return client[method](url, data)
    .then(response => {
      return response.data
    })
}

const api = {
  // USER_ACCOUNT
  getUserData() {
    return request('get', '/auth/me')
  },
  updateProfile(data) {
    return request('put', '/profile', data)
  },
  googleAuth(data) {
    return request('post', '/auth/login', data)
  },
  refresh(data) {
    return request('post', '/auth/refresh', data)
  },
  deleteUserAccount() {
    return request('delete', '/profile/me')
  },
  sendInitialQuiz(data) {
    return request('post', '/profile/poll', data)
  },
  skipQuiz(data) {
    return request('post', '/profile/poll/skip', data)
  },
  applyFlashTrial() {
    return request('post', '/profile/apply-flash-trial')
  },
  // WORKSPACES
  getWorkspaces() {
    return request('get', '/workspace')
  },
  addWorkspaceUser(wsId, data) {
    return request('post', `/workspace/${wsId}/add-user`, data)
  },
  deleteWorkspaceUser(wsId, data) {
    return request('delete', `/workspace/${wsId}/delete-user`, { data })
  },
  updateWorkspaceUser(wsId, data) {
    return request('patch', `/workspace/${wsId}/update-user`, data)
  },
  updateWorkspace(wsId, data) {
    return request('put', `/workspace/${wsId}`, data)
  },
  gWsDetails(wsId) {
    return request('get', `/workspace/${wsId}/owner`)
  },
  acceptWorkspaceInvite(data) {
    return request('post', '/workspace/accept-invite', data)
  },
  // SPREAD_VIEWS
  getViewsList() {
    return request('get', `/workspace/:wsId/spread-view/?_orderBy=updatedAt:desc`)
  },
  createView(data) {
    return request('post', '/workspace/:wsId/spread-view/', data)
  },
  getView(id) {
    return request('get', `/workspace/:wsId/spread-view/${id}`)
  },
  updateView(id, data, queryString = '') {
    return request('put', `/workspace/:wsId/spread-view/${id}/${queryString}`, data)
  },
  deleteView(id) {
    return request('delete', `/workspace/:wsId/spread-view/${id}`)
  },
  validateDomain(id, data) {
    return request('post', `/workspace/:wsId/spread-view/${id}/validate-domain`, data)
  },
  patchDealType(spreadViewId, dealType) {
    return request('patch', `/workspace/:wsId/spread-view/${spreadViewId}`, {
      dealType: dealType
    })
  },
  initTemplate(templateName) {
    return request('post', `/spread-view/init/${templateName}`)
  },
  requestSheetCopy(id) {
    return request('get', `/workspace/:wsId/spread-view/${id}/export`)
  },
  // TAGS
  getTagsList() {
    return request('get', `workspace/:wsId/tag`)
  },
  createTag(data) {
    return request('post', `/workspace/:wsId/tag`, data)
  },
  updateTag(id, data) {
    return request('patch', `/workspace/:wsId/tag/${id}`, data)
  },
  deleteTag(id) {
    return request('delete', `/workspace/:wsId/tag/${id}`)
  },
  updateSpreadViewTags(spreadViewId, tagsList) {
    return request('put', `/workspace/:wsId/spread-view/${spreadViewId}/tag`, tagsList)
  },
  // SPREAD_VIEW PROXIES
  uploadFiles(data) {
    return request('post', '/upload', data)
  },
  uploadNotionExport(data) {
    return request('post', '/integration/notion/archive', data)
  },
  unsplashSearch(query) {
    return request('get', `/proxy/unsplash/search?${query}`)
  },
  unsplashDownload(id) {
    return request('get', `/proxy/unsplash/download?id=${id}`)
  },
  checkGoogleSheetAvailability(id) {
    return request('get', `/sheet/${id}/status`)
  },
  // SUBSCRIPTION_AND_PAYMENT
  paddleUpdateSubscriptionProxy(data) {
    return request('post', `/proxy/paddle/subscription/update`, data)
  },
  paddleGeneratePayLinkProxy(data) {
    return request('post', `/proxy/paddle/generate-pay-link`, data)
  },
  paddlePreviewUpdateProxy(data) {
    return request('post', `/proxy/paddle/subscription/preview_update`, data)
  },
  paddleGetModifiersProxy(data) {
    return request('post', `/proxy/paddle/subscription/modifiers`, data)
  },
  paddleCreateModifierProxy(data) {
    return request('post', `/proxy/paddle/subscription/modifiers/create`, data)
  },
  paddleGetUsersList(data) {
    return request('post', `/proxy/paddle/subscription/modifiers/list-users`, data)
  },
  applyPromoCode(code) {
    return request('post', `/profile/apply-code/${code}`)
  },
  prerenderWebsite(domain) {
    return request('get', `/proxy/prerender/cache/${domain}/clear`)
  },
  unsubscribeFeedback(data) {
    return request('post', `/unsubscribe-feedback`, data)
  },
  applyTrial() {
    return request('post', `/profile/apply-trial`)
  },
  cancelTrial() {
    return request('post', `/profile/cancel-trial`)
  },
  applyDiscount(data) {
    return request('post', `/profile/apply-discount`, data)
  },
  // SSL
  issueSsl(id) {
    return request('post', `/services/issue-ssl/${id}`)
  },
  checkCustomDomainDns(hostname) {
    return request('get', `/services/dns/${hostname}`)
  },
  getSslStatus(id) {
    return request('get', `/workspace/:wsId/spread-view/${id}`)
  },
  // PROMOCODES
  createPromocode(spreadViewId, data) {
    return request('post', `/spread-view/${spreadViewId}/promocode`, data)
  },
  updatePromocode(spreadViewId, promoId, data) {
    return request('put', `/spread-view/${spreadViewId}/promocode/${promoId}`, data)
  },
  deletePromocode(spreadViewId, promoId) {
    return request('delete', `/spread-view/${spreadViewId}/promocode/${promoId}`)
  },
  getPromocodes(spreadViewId) {
    return request('get', `/spread-view/${spreadViewId}/promocode`)
  },
  // WIZARDS
  updateWizard(data) {
    return request('post', '/profile/wizard', data)
  },
  // WEBFONTS
  getGoogleFontsList(data) {
    return request('get', '/proxy/fonts/webfonts' + data)
  },
  // USERS ACCESS MANAGEMENT
  getAccessSheetScheme(spreadsheetUrl) {
    return request('post', '/workspace/:wsId/website-access/scheme', {
      spreadsheetUrl: spreadsheetUrl
    })
  },
  refreshWebsiteAccessUsers(websiteId, { spreadsheetUrl, authProvider }) {
    return request('post', `/workspace/:wsId/website-access/${websiteId}/refresh-users`, { spreadsheetUrl, authProvider })
  },
  createWebsiteAccessRules(websiteId, rules, createSpreadsheet = false) {
    if (createSpreadsheet) {
      return request('post', `/workspace/:wsId/website-access/${websiteId}`, {
        createSpreadsheet
      })
    }
    return request('post', `/workspace/:wsId/website-access/${websiteId}`, {
      spreadsheetUrl: rules.spreadsheetUrl,
      tableFieldsMapping: rules.tableFieldsMapping,
      authProvider: rules.authProvider
    })
  },
  deleteWebsiteAccessRules(websiteId) {
    return request('delete', `/workspace/:wsId/website-access/${websiteId}/`)
  },
  saveWebsiteAccessRules(websiteId, accessRules) {
    return request('put', `/workspace/:wsId/website-access/${websiteId}`, accessRules)
  },
  getWebsiteAccessGroups(websiteId) {
    return request('get', `/workspace/:wsId/website-access/${websiteId}/access-groups`)
  },
  // SENDGRID
  sendgridSendFeatures(data) {
    return request('post', '/sendgrid/features', data)
  }
}

export default api
