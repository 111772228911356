var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "spreadview", class: { hover: _vm.imgOverlayVisible } },
    [
      _c(
        "div",
        {
          staticClass: "spreadview__img",
          class: { "is-archived": _vm.item.isArchived },
          style: "background-image: url('" + _vm.websiteCover + "')",
          on: { click: _vm.linkToView }
        },
        [
          _c(
            "div",
            { staticClass: "spreadview__overlay" },
            [
              !_vm.item.isArchived
                ? _c(
                    "button",
                    {
                      staticClass: "spreadview__edit-btn",
                      class: { "archived--disabled": _vm.item.isArchived },
                      attrs: { disabled: _vm.item.isArchived },
                      on: {
                        click: function($event) {
                          return _vm.tryToLock()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("global.manageOptions")))]
                  )
                : _vm._e(),
              _c("SingleSpreadViewDropdownMenu", {
                attrs: { item: _vm.item, canDelete: _vm.canDelete },
                on: {
                  "change-view-published-status": function($event) {
                    return _vm.$emit("change-view-published-status", $event)
                  },
                  "change-view-archived-status": function($event) {
                    return _vm.$emit("change-view-archived-status", $event)
                  },
                  "open-delete-modal": _vm.openDeleteModal,
                  "open-link-modal": _vm.openLinkModal,
                  duplicate: function($event) {
                    return _vm.$emit("duplicate")
                  },
                  "on-menu-visible": _vm.showOverlay
                }
              }),
              _vm.canChangePlan
                ? _c(
                    "el-dropdown",
                    {
                      staticClass: "spreadview__dropdown for-manage-plans",
                      attrs: { trigger: "click", placement: "bottom-start" },
                      on: { "visible-change": _vm.showOverlay }
                    },
                    [
                      _vm.currentWorkspace
                        ? _c(
                            "el-dropdown-menu",
                            {
                              staticClass:
                                "spreadview__dropdown-menu for-manage-plans",
                              attrs: { slot: "dropdown" },
                              slot: "dropdown"
                            },
                            [
                              _vm._l(
                                Object.entries(
                                  _vm.currentWorkspace.ownerLicenses
                                    .paidLicenses
                                ),
                                function(deal, index) {
                                  return [
                                    _c(
                                      "el-dropdown-item",
                                      {
                                        key: index,
                                        attrs: {
                                          disabled:
                                            _vm.item.dealType === deal[0]
                                        }
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "spreadview__dropdown-item",
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.applySpreadViewDeal(
                                                  deal[0]
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                Apply " +
                                                _vm._s(
                                                  _vm.$options.dealTypeLabels[
                                                    deal[0]
                                                  ]
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                }
                              ),
                              _vm.item.isPaid
                                ? [
                                    _c("div", {
                                      staticClass:
                                        "spreadview__dropdown-menu-divider"
                                    }),
                                    _c(
                                      "el-dropdown-item",
                                      { staticClass: "is-danger" },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "spreadview__dropdown-item is-danger",
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                _vm.showDowngradeInfoModal = true
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                Downgrade to free\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        : _vm._e(),
                      _c(
                        "button",
                        { staticClass: "spreadview__manage-plans-btn" },
                        [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("paid")
                          ]),
                          _vm._v("\n          Manage plans\n        ")
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "spreadview__body" },
        [
          _c("UiTag", {
            staticClass: "spreadview__plan-tag",
            attrs: {
              size: "xxs",
              label: _vm.$options.dealTypeLabels[_vm.item.dealType],
              color: _vm.planTagColor(_vm.item)
            }
          }),
          _c(
            "TitleEditor",
            {
              staticClass: "spreadview__title",
              attrs: {
                value: _vm.item.options.spreadView.name,
                disabled: _vm.item.isArchived
              },
              on: { input: _vm.tryToUpdateTitle }
            },
            [
              _c(
                "span",
                {
                  staticClass: "spreadview__title-link",
                  class: { "is-disabled": _vm.item.isArchived },
                  on: { click: _vm.manageSpreadViewName }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.item.options.spreadView.name) +
                      "\n      "
                  )
                ]
              )
            ]
          ),
          _vm.item.options.spreadView.isPublished
            ? _c(
                "a",
                {
                  staticClass: "spreadview__link",
                  attrs: { href: _vm.resultUrl, target: "_blank" }
                },
                [
                  _c("span", { staticClass: "spreadview__link-title" }, [
                    _vm._v(" " + _vm._s(_vm.resultUrl) + " ")
                  ]),
                  _c("i", {
                    staticClass: "spreadview__link-icon icon-external-link2"
                  })
                ]
              )
            : _c("div", { staticClass: "spreadview__indicator" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("global.publishedNot")) + "\n    "
                )
              ]),
          _c("div", { staticClass: "spreadview__footer" }, [
            _c(
              "div",
              {
                staticClass: "spreadview__tags-wrap",
                class: { "is-archived": _vm.item.isArchived }
              },
              [
                _c(
                  "div",
                  { staticClass: "spreadview__tags" },
                  [
                    _c("i", { staticClass: "spreadview__tags-icon icon-tags" }),
                    _vm._l(_vm.websiteAssignedTags.slice(0, 3), function(
                      id,
                      i
                    ) {
                      return _c(
                        "div",
                        { key: i, staticClass: "spreadview__tag" },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.showTagLabel(id)) +
                              "\n          "
                          )
                        ]
                      )
                    })
                  ],
                  2
                ),
                _c(
                  "el-popover",
                  {
                    staticClass: "spreadview__tag-popover",
                    attrs: {
                      trigger: "click",
                      placement: "right-start",
                      "popper-class": "spreadview__tag-popover-menu",
                      "visible-arrow": false
                    },
                    on: {
                      hide: function($event) {
                        _vm.newTag = ""
                      }
                    }
                  },
                  [
                    [
                      _c(
                        "div",
                        { staticClass: "spreadview__tag-popover-search" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.$v.newTag.$model,
                                expression: "$v.newTag.$model",
                                modifiers: { trim: true }
                              }
                            ],
                            staticClass: "spreadview__tag-popover-search-input",
                            attrs: {
                              type: "text",
                              placeholder: "Enter tag name..."
                            },
                            domProps: { value: _vm.$v.newTag.$model },
                            on: {
                              keydown: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.addNewTag.apply(null, arguments)
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.$v.newTag,
                                  "$model",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          }),
                          _vm.$v.newTag.$dirty && !_vm.$v.newTag.maxLength
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "spreadview__tag-popover-search-msg"
                                },
                                [_vm._v("Max length is 30 symbols")]
                              )
                            : _vm._e()
                        ]
                      ),
                      _vm.newTag.length
                        ? _c(
                            "button",
                            {
                              staticClass: "spreadview__tag-popover-add-item",
                              attrs: {
                                title: _vm.tagLabelExists
                                  ? "Tag already exists"
                                  : null,
                                disabled: _vm.tagLabelExists
                              },
                              on: { click: _vm.addNewTag }
                            },
                            [
                              _vm._v('\n              + Add "'),
                              _c("span", [_vm._v(_vm._s(_vm.newTag))]),
                              _vm._v('"\n            ')
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "el-checkbox-group",
                        {
                          staticClass: "spreadview__tag-popover-items",
                          model: {
                            value: _vm.websiteAssignedTags,
                            callback: function($$v) {
                              _vm.websiteAssignedTags = $$v
                            },
                            expression: "websiteAssignedTags"
                          }
                        },
                        _vm._l(_vm.allTagsFiltered, function(tagItem, i) {
                          return _c(
                            "div",
                            {
                              key: i,
                              staticClass: "spreadview__tag-popover-item"
                            },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  staticClass:
                                    "spreadview__tag-popover-item-checkbox",
                                  attrs: { label: tagItem.id }
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(tagItem.label) +
                                      "\n                "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        }),
                        0
                      )
                    ],
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          slot: "reference",
                          effect: "dark",
                          content: "Add tag",
                          placement: "top"
                        },
                        slot: "reference"
                      },
                      [
                        _c(
                          "button",
                          {
                            class: {
                              "spreadview__tags-add-btn": true,
                              "is-counter": _vm.item.tags.length >= 4,
                              "archived--disabled": _vm.item.isArchived
                            },
                            attrs: { disabled: _vm.item.isArchived }
                          },
                          [
                            _vm.item.tags.length < 4
                              ? _c("i", { staticClass: "icon-plus" })
                              : _c("span", [
                                  _vm._v(
                                    " +" +
                                      _vm._s(_vm.item.tags.length - 3) +
                                      " "
                                  )
                                ])
                          ]
                        )
                      ]
                    )
                  ],
                  2
                )
              ],
              1
            ),
            _vm.activeEditor
              ? _c(
                  "div",
                  [
                    _c("UserAvatar", {
                      staticClass: "spreadview__revision-indicator",
                      attrs: { letter: _vm.editorInitials }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: { modalName: _vm.$t("pageManageSheet.modal.titleDelete") },
          model: {
            value: _vm.modalDeleteOpened,
            callback: function($$v) {
              _vm.modalDeleteOpened = $$v
            },
            expression: "modalDeleteOpened"
          }
        },
        [
          _c("div", { staticClass: "delete-modal" }, [
            _c("div", { staticClass: "delete-modal__body" }, [
              _c("div", { staticClass: "delete-modal__field" }, [
                _c(
                  "button",
                  {
                    staticClass: "delete-modal__button negative",
                    on: {
                      click: function($event) {
                        _vm.modalDeleteOpened = false
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("global.cancel")))]
                ),
                _c(
                  "button",
                  {
                    staticClass: "delete-modal__button",
                    on: { click: _vm.deleteItem }
                  },
                  [_vm._v(_vm._s(_vm.$t("global.yes")))]
                )
              ])
            ])
          ])
        ]
      ),
      _c(
        "Modal",
        {
          attrs: { modalName: _vm.$t("pageManageSheet.modal.titleLink") },
          model: {
            value: _vm.modalLinkOpened,
            callback: function($$v) {
              _vm.modalLinkOpened = $$v
            },
            expression: "modalLinkOpened"
          }
        },
        [
          _c("div", { staticClass: "publish-modal" }, [
            _c("div", { staticClass: "publish-modal__published-state" }, [
              _c("div", { staticClass: "publish-modal__result-field" }, [
                _c("input", {
                  ref: "code",
                  staticClass: "publish-modal__result-field-url",
                  attrs: { type: "text", readonly: "" },
                  domProps: { value: _vm.resultUrl }
                }),
                _c("button", {
                  staticClass:
                    "publish-modal__result-field-copy icon-duplicate2",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.copyToClipboard.apply(null, arguments)
                    }
                  }
                })
              ]),
              _c(
                "a",
                {
                  staticClass: "publish-modal__button for-published",
                  attrs: { target: "_blank", href: _vm.resultUrl }
                },
                [_vm._v("Open")]
              )
            ])
          ])
        ]
      ),
      _c(
        "Modal",
        {
          attrs: { modalName: "You are about to downgrade a website" },
          model: {
            value: _vm.showDowngradeInfoModal,
            callback: function($$v) {
              _vm.showDowngradeInfoModal = $$v
            },
            expression: "showDowngradeInfoModal"
          }
        },
        [
          _c("div", { staticClass: "modal-pro-options" }, [
            _c("p", [
              _c("b", [
                _vm._v(
                  "Please note that downgrading your website doesn’t update your subscription plan. "
                )
              ])
            ]),
            _c(
              "p",
              [
                _vm._v(
                  "You can update the subscription and change the number of licenses in your "
                ),
                _c(
                  "router-link",
                  { attrs: { to: { name: "account-billing" } } },
                  [_vm._v("Billing Settings")]
                ),
                _vm._v(". Or apply the available license to another website.")
              ],
              1
            ),
            _c(
              "button",
              {
                staticClass: "modal-pro-options__button",
                on: { click: _vm.downgradeToFree }
              },
              [_vm._v("Proceed")]
            )
          ])
        ]
      ),
      _c("TakoverWarning", {
        attrs: {
          "v-if": _vm.takeoverModalVisible,
          visible: _vm.takeoverModalVisible,
          type: _vm.takeoverWarningType,
          currentEditor: _vm.activeEditor
            ? _vm.activeEditor.firstName + " " + _vm.activeEditor.lastName
            : "current editor"
        },
        on: {
          close: function($event) {
            _vm.takeoverModalVisible = false
          },
          "takeover-request": _vm.sendTakeoverRequest,
          "countdown-end": function($event) {
            return _vm.tryToLock(_vm.prevActionAttempt, false)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }