import * as integrationsModules from './config/'

const integrationsConfig = Object.values(integrationsModules)

export function getIntegrationsCategories ({ ignorePublished = false } = {}) {
  const integrationsConfigFiltered = integrationsConfig
    .filter(config => ignorePublished || config.published)

  const categories = integrationsConfigFiltered
    .reduce((cats, integration) => {
      integration.categories.forEach(intCat => {
        const existingCat = cats.find(({ value }) => value === intCat)
        if (existingCat) {
          existingCat.count += 1
        } else {
          const newCat = {
            title: intCat,
            value: intCat,
            count: 1
          }
          cats.push(newCat)
        }
      })
      return cats
    }, [])

  return [
    {
      title: 'All',
      value: '_ALL_',
      count: integrationsConfigFiltered.length
    },
    ...categories
  ]
}

export function getIntegrationsWithData (data = [], { secrets = false, ignorePublished = false } = {}) {
  return integrationsConfig
    .filter(config => ignorePublished || config.published)
    .map(config => {
      const intItem = data.find(({ name }) => name === config.name)
      const dataFields = intItem ? intItem.data : []
      config.formFields = config.formFields
        .slice()
        .filter(field => {
          if (secrets) return true
          return field.secret === false
        })
        .map(field => {
          const intField = dataFields.find(({ name }) => name === field.name)
          return {
            ...field,
            value: intField ? intField.value : field.default
          }
        })
      return config
    })
}

export function getPublicIntegrationsData (data = []) {
  const allIntegrations = getIntegrationsWithData(data, { secrets: false, ignorePublished: true }) // @todo remove ignorePublished:true later
  return allIntegrations
    .filter(int => {
      const enabledField = int.formFields.find(({ name }) => name === 'enabled')
      return enabledField && enabledField.value
    })
    .map(int => {
      const formData = int.formFields.reduce((formData, field) => {
        formData[field.name] = field.value
        return formData
      }, {})
      return {
        name: int.name,
        pro: int.pro,
        featureRestrictionsGroup: int.featureRestrictionsGroup,
        title: int.title,
        actions: int.actions,
        formData
      }
    })
}
