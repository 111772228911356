<template>
    <el-dialog
      :show-close="type !== 'countdown'"
      :visible="visible"
      :destroy-on-close="true"
      class="team-dialog"
      :class="{ 'is-countdown-view': type === 'countdown' }"
      @close="type !== 'countdown' ? $emit('close') : null"
    >
      <transition mode="out-in" name="fade">
        <div v-if="type === 'countdown'" key="1" class="team-dialog__container">
          <Countdown
            class="team-dialog__timer"
            :time="takeoverRequestTimerLimit"
            :showProgressBar="true"
            @time-end="$emit('countdown-end')"
          />
          <p class="team-dialog__string align-center">
            Please wait for <span>{{currentEditor}}</span> to confirm. If this user doesn’t respond, you will be automatically redirected to the website editor.
          </p>
        </div>
        <div v-else-if="type === 'warning'" key="2" class="team-dialog__container">
          <h4 class="team-dialog__title"> Are you sure? </h4>
          <div class="team-dialog__users-wrap">
            😱
          </div>
          <p class="team-dialog__string">
            This content is currently locked. If you take over, <span>{{currentEditor}}</span> will be blocked from continuing to edit. Do you want to take over?
          </p>
          <div class="team-dialog__btns">
            <UiBtn type="secondary" uppercase
              @click="$emit('close')">
              Cancel
            </UiBtn>
            <UiBtn uppercase
              @click="$emit('takeover-request')"
            >
              Take over
            </UiBtn>
          </div>
        </div>
        <div v-else-if="type === 'takeover'" key="3" class="team-dialog__container">
          <h4 class="team-dialog__title"> Take over request </h4>
          <div class="team-dialog__users-wrap">
            ✏️
          </div>
          <p class="team-dialog__string">
            <span>{{currentEditor}}</span> wants to take over the editing.
            Click <b>Decline</b> to keep working on this website.
            Click <b>Accept</b> to finish editing the website without saving the changes you've made.
          </p>
          <div class="team-dialog__btns">
            <UiBtn type="secondary" uppercase
              @click="$emit('reject-takeover')">
              Decline
            </UiBtn>
            <UiBtn
              uppercase
              type="danger"
              @click="$emit('allow-takeover')"
            >
              Accept
              (<Countdown :time="takeoverResponseTimerLimit" @time-end="$emit('allow-takeover')"/>)
            </UiBtn>
          </div>
        </div>
        <div v-else-if="type === 'takeover-decline'" key="4" class="team-dialog__container">
          <h4 class="team-dialog__title"> Takeover request declined </h4>
          <div class="team-dialog__users-wrap">
            🙅
          </div>
          <p class="team-dialog__string">
            <span>{{currentEditor}}</span> declined your takeover request. You can try again later.
          </p>
          <div class="team-dialog__btns">
            <UiBtn uppercase
              @click="$emit('close')"
            >
              OK
            </UiBtn>
          </div>
        </div>
        <div v-else-if="type === 'takeover-fail'" key="5" class="team-dialog__container">
          <h4 class="team-dialog__title"> Takeover request failed </h4>
          <div class="team-dialog__users-wrap">
            🙅
          </div>
          <p class="team-dialog__string">
            Please try again later.
          </p>
          <div class="team-dialog__btns">
            <UiBtn uppercase
              @click="$emit('close')"
            >
              OK
            </UiBtn>
          </div>
        </div>
        <div v-else-if="type === 'takeover-success'" key="6" class="team-dialog__container">
          <h4 class="team-dialog__title"> Takeover request accepted </h4>
          <div class="team-dialog__users-wrap">
            🙌
          </div>
          <p class="team-dialog__string">
            You can start editing
          </p>
          <div class="team-dialog__btns">
            <UiBtn uppercase
              @click="$emit('close')"
            >
              OK
            </UiBtn>
          </div>
        </div>
      </transition>
    </el-dialog>
</template>
<script>
import UiBtn from '@/components/ui-btn'
import Countdown from './Countdown.vue'
export default {
  name: 'TakoverWarning',
  components: {
    UiBtn,
    Countdown
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      required: true
    },
    currentEditor: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      dialogCountdownView: false,
      takeoverRequestTimerLimit: 60,
      takeoverResponseTimerLimit: 60
    }
  }
}
</script>
