<template>
  <div class="page-cta">
    <div class="page-cta__container">
      <p v-if="invitedUser !== userData.email" class="page-cta__string">
        Please check the account you’re signed in to. Looks like the invitation was sent to another email address.
      </p>
      <template v-else>
        <h2 class="page-cta__title">{{ wsOwner }}</h2>
        <h3 class="page-cta__subtitle">invites you to join </h3>
        <h2 class="page-cta__title">{{wsName}} workspace</h2>

        <UiBtn
          :loading="isLoading"
          uppercase
          wide
          class="page-cta__btn"
          @click="acceptInvitation"
        >Join</UiBtn>
        <p v-if="error" class="page-cta__error-msg">{{error}}</p>
      </template>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import UiBtn from '@/components/ui-btn'
import api from '@/services/api/'
export default {
  name: 'AcceptWorkspaceInvitation',
  metaInfo: {
    title: 'Accept invitation',
    titleTemplate: '%s | SpreadSimple'
  },
  components: {
    UiBtn
  },
  data () {
    return {
      wsId: '',
      isLoading: false,
      error: '',
      wsName: 'new',
      wsOwner: 'Workspace owner'
    }
  },
  computed: {
    ...mapState('app', [
      'userData'
    ])
  },
  methods: {
    ...mapActions('app', [
      'setWorkspaceId'
    ]),
    async acceptInvitation () {
      this.isLoading = true
      try {
        await api.acceptWorkspaceInvite({
          email: this.userData.email,
          wsId: this.wsId
        })
        this.setWorkspaceId(this.wsId)
        this.$router.push({ name: 'spread-views' })
      } catch ({ response }) {
        this.error = response.data.reason
      } finally {
        this.isLoading = false
      }
    }
  },
  async created () {
    if (this.$route.query && this.$route.query.workspace) {
      this.wsId = this.$route.query.workspace
      this.invitedUser = decodeURIComponent(this.$route.query.email)
      try {
        const wsDetails = await api.gWsDetails(this.wsId)
        this.wsName = wsDetails.title
        this.wsOwner = `${wsDetails.owner.firstName} ${wsDetails.owner.lastName}`
      } catch (e) {}
    }
  }
}
</script>
