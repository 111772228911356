import messages from './messages'

export function getMessages (locale = 'en') {
  return {
    ...messages['en'],
    ...messages[locale]
  }
}

export function getLocales () {
  return Object.entries(messages).map(([key, locale]) => {
    return {
      value: key,
      label: locale.label,
      isRtl: locale.isRtl
    }
  })
}

export {
  messages
}
