<template>
  <header v-scroll-class:scrolled=70 class="manage-header">
    <div class="manage-header__container">
      <div class="manage-header__left-side">
        <!-- <router-link :to="{name: 'spread-views'}" class="manage-header__logo">
          <img
            src="/img/LogoSimple.svg"
            alt=""
          >
        </router-link> -->
        <div class="manage-header__controls">
          <UiBtn
            :to="{ name: 'spread-views'}"
            class="manage-header__back-btn"
            icon-only="mi-keyboard_backspace"
            size="m"
            type="ghost-secondary"
          />
          <div class="manage-header__meta">
            <div class="manage-header__meta-title">
              <portal-target name="manage-header-title-editor" slim/>
            </div>
            <div class="manage-header__meta-badges">
              <portal-target name="manage-header-subscription-button" slim/>
              <UpgradePlanButton v-if="showUpgradeButton" class="manage-header__subscribe-cta" :navigateToBilling="true">
                {{upgradeButtonLabel}}
              </UpgradePlanButton>
            </div>
          </div>
        </div>
      </div>

      <div class="manage-header__right-side">
        <TrialNotificationBar :small="true"/>
        <portal-target class="manage-header__right-side-slot" name="manage-header-menu" />
        <ProfileDropdown :userData="userData || {}" />
      </div>
    </div>
  </header>
</template>
<script>
import UiBtn from '@/components/ui-btn/UiBtn.vue'
import TrialNotificationBar from '@/components/trial-notification-bar'
import ProfileDropdown from '@/components/profile-dropdown'
import UpgradePlanButton from '@/components/pro-option-wrapper/UpgradePlanButton.vue'

import { mapState } from 'vuex'

export default {
  name: 'LayoutHeader',
  components: {
    ProfileDropdown,
    TrialNotificationBar,
    UiBtn,
    UpgradePlanButton
  },
  computed: {
    ...mapState('app', [
      'userData',
      'currentSpreadViewData'
    ]),
    showUpgradeButton () {
      return ['free', 'starter'].includes(this.currentSpreadViewData?.dealType)
    },
    upgradeButtonLabel () {
      return this.userData.dealOptions.hadPaddleTrial ? 'Upgrade plan' : 'Start your trial'
    }
  }
}
</script>
