export default {
  published: true,
  pro: true,
  featureRestrictionsGroup: 'paymentIntegrations',
  name: 'razorpay',
  title: 'Razorpay',
  logoUrl: '/img/integrations/razorpay.svg',
  // coverUrl: '/img/integrations/razorpay-cover.png',
  bgColor: '#3395ff',
  categories: ['Payment Gateway'],
  comingSoon: false,
  builtIn: false,
  url: 'https://razorpay.com',
  helpUrl: '',
  description: `
  Razorpay is the leading payments solution in India that allows businesses to accept, process and disburse payments with its product suite. It gives you access to all payment modes including credit card, debit card, netbanking, UPI and popular wallets including JioMoney, Mobikwik, Airtel Money, FreeCharge, Ola Money and PayZapp.

  <a href="https://razorpay.com/docs/payment-gateway/dashboard-guide/settings/api-keys/" target="_blank">How to find API keys in the Razorpay Dashboard</a>

  `,

  functions: {
    checkSandboxKey: (mode) => (value) => {
      if (mode === 'sandbox' && !value.startsWith('rzp_test_')) return false
      return true
    }
  },

  formFields: [
    {
      name: 'enabled',
      type: 'toggle',
      label: 'Enabled',
      default: false,
      validations: [
        'required'
      ],
      secret: false
    },
    {
      name: 'currency',
      type: 'text',
      showIf: ['enabled'],
      label: 'Currency Code',
      default: 'INR',
      placeholder: 'INR',
      validations: [
        'required',
        'minLength:3',
        'maxLength:3'
      ],
      secret: false
    },
    {
      name: 'buttonText',
      type: 'text',
      showIf: ['enabled'],
      label: 'Button Text',
      default: '💳 Continue to payment...',
      placeholder: 'Continue...',
      validations: [
        'required',
        'minLength:2',
        'maxLength:50'
      ],
      secret: false
    },
    {
      name: 'keyId',
      type: 'text',
      showIf: ['enabled'],
      label: 'Key Id',
      default: '',
      placeholder: 'rzp_',
      validations: [
        'required',
        'startsWith:rzp_',
        'maxLength:100',
        'runInternalValidationFunction:checkSandboxKey'
      ],
      secret: false
    },
    {
      name: 'keySecret',
      type: 'text',
      showIf: ['enabled'],
      label: 'Key Secret',
      default: '',
      placeholder: '',
      validations: [
        'required',
        'maxLength:100'
      ],
      secret: true
    },
    {
      name: 'customTitle',
      type: 'text',
      showIf: ['enabled'],
      label: 'Custom title',
      default: 'Razorpay',
      placeholder: '',
      validations: [
        'maxLength:50'
      ],
      secret: false
    },
    {
      name: 'customDescription',
      type: 'text',
      showIf: ['enabled'],
      label: 'Description',
      default: '',
      placeholder: '',
      validations: [
        'maxLength:100'
      ],
      secret: false
    }
  ],

  actions: [
    {
      type: 'injectToHead',
      run () {
        return '<script src="https://checkout.razorpay.com/v1/checkout.js"></script>'
      }
    }
  ]
}
