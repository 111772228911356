var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.currentRouteClass, attrs: { id: "app", "data-app": "true" } },
    [
      _vm.isMaintenance ? _c("div", [_c("Maintenance")], 1) : _vm._e(),
      !_vm.isMaintenance && _vm.$auth.isLoaded()
        ? _c("LayoutBroker", {
            attrs: { layouts: _vm.layouts, current: _vm.$route.meta.layout }
          })
        : _vm._e(),
      _c("SubscriptionComponent"),
      _c("Preloader", { attrs: { loading: !_vm.$auth.isLoaded() } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }