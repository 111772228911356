var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "side-form" }, [
    _vm.order.length
      ? _c(
          "div",
          { staticClass: "side-form__item side-form__draggable-wrap" },
          [
            _c(
              "draggable",
              {
                staticClass: "draggable",
                attrs: {
                  value: _vm.order,
                  tag: "div",
                  handle: ".draggable__item-trigger"
                },
                on: {
                  input: function(val) {
                    return _vm.onDrag(val)
                  }
                }
              },
              _vm._l(_vm.order, function(item) {
                return _c(
                  "div",
                  { key: item.name, staticClass: "draggable__item" },
                  [
                    _c("div", {
                      staticClass: "draggable__item-trigger icon-draggable"
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "draggable__item-title-wrap cursor-default"
                      },
                      [
                        _c("img", {
                          staticClass: "draggable__item-title-img",
                          attrs: {
                            src: _vm.getPaymentLogo(item.name),
                            alt: item.name
                          }
                        }),
                        _c("span", { staticClass: "draggable__item-title" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.getPaymentTitle(item.name)) +
                              "\n            " +
                              _vm._s(item.default ? " (default)" : "") +
                              "\n          "
                          )
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "draggable__item-controls" },
                      [
                        _c(
                          "el-dropdown",
                          {
                            staticClass: "draggable__item-control",
                            attrs: { trigger: "click" }
                          },
                          [
                            _c("i", { staticClass: "icon-gear" }),
                            _c(
                              "el-dropdown-menu",
                              {
                                staticClass: "draggable__dropdown-menu",
                                attrs: { slot: "dropdown" },
                                slot: "dropdown"
                              },
                              [
                                item.default
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.deselectAsDefault(
                                              item.name
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("Deselect as default")]
                                    )
                                  : _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.selectAsDefault(
                                              item.name
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("Select as default")]
                                    ),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.disablePayment(item.name)
                                      }
                                    }
                                  },
                                  [_vm._v("Disable")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              }),
              0
            )
          ],
          1
        )
      : _vm._e(),
    _c("div", { staticClass: "side-form__btns" }, [
      _c(
        "button",
        {
          staticClass: "btn btn--transparent",
          attrs: { type: "button" },
          on: { click: _vm.openPaymentsModal }
        },
        [_vm._v("+ Enable new add-on")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }