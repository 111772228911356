var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "content-form__switch-field" }, [
      _c("div", { staticClass: "content-form__switcher-wrap" }, [
        _c("div", { staticClass: "content-form__switcher-label" }, [
          _vm._v("Related items ")
        ]),
        _c(
          "label",
          {
            staticClass: "switcher",
            class: { selected: _vm.relatedItemsState.enabled }
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.relatedItemsState.enabled,
                  expression: "relatedItemsState.enabled"
                }
              ],
              staticClass: "switcher__input",
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.relatedItemsState.enabled)
                  ? _vm._i(_vm.relatedItemsState.enabled, null) > -1
                  : _vm.relatedItemsState.enabled
              },
              on: {
                change: function($event) {
                  var $$a = _vm.relatedItemsState.enabled,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(
                          _vm.relatedItemsState,
                          "enabled",
                          $$a.concat([$$v])
                        )
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.relatedItemsState,
                          "enabled",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.relatedItemsState, "enabled", $$c)
                  }
                }
              }
            }),
            _c("span", { staticClass: "switcher__indicator" })
          ]
        )
      ])
    ]),
    _vm.relatedItemsState.enabled
      ? _c(
          "div",
          { staticClass: "content-form__highlighted" },
          [
            _c(
              "FormField",
              {
                staticClass:
                  "tile-constructor__field side-form__item aside-input",
                attrs: { label: "Related items title" }
              },
              [
                _c("v-text-field", {
                  model: {
                    value: _vm.relatedItemsState.sectionTitle,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.relatedItemsState,
                        "sectionTitle",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "relatedItemsState.sectionTitle"
                  }
                }),
                _c(
                  "div",
                  {
                    staticClass: "tile-constructor__hint",
                    staticStyle: { "margin-bottom": "10px" }
                  },
                  [
                    _c(
                      "a",
                      {
                        attrs: {
                          target: "_blank",
                          href:
                            "https://help.spreadsimple.com/en/article/related-items-xqg93j"
                        }
                      },
                      [_vm._v("Help: How to manage related items")]
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "tile-constructor__field" },
              [
                _c("CustomOptionsPicker", {
                  attrs: {
                    label: "Select card style",
                    options: _vm.styleOptions
                  },
                  model: {
                    value: _vm.cardStyle,
                    callback: function($$v) {
                      _vm.cardStyle = $$v
                    },
                    expression: "cardStyle"
                  }
                })
              ],
              1
            ),
            _vm.cardStyle !== "table"
              ? _c(
                  "div",
                  { staticClass: "tile-constructor__field" },
                  [
                    _c("CustomOptionsPicker", {
                      attrs: {
                        label: "Select card size",
                        options: _vm.getCardSizeOptions(
                          _vm.relatedItemsState.itemsStyle
                        )
                      },
                      model: {
                        value: _vm.relatedItemsState.itemsSize,
                        callback: function($$v) {
                          _vm.$set(_vm.relatedItemsState, "itemsSize", $$v)
                        },
                        expression: "relatedItemsState.itemsSize"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.cardStyle === "table"
              ? _c("div", { staticClass: "content-form__switch-field" }, [
                  _c("div", { staticClass: "content-form__switcher-wrap" }, [
                    _c("div", { staticClass: "content-form__switcher-label" }, [
                      _vm._v("Scrollable table rows")
                    ]),
                    _c(
                      "label",
                      {
                        staticClass: "switcher",
                        class: {
                          selected: _vm.relatedItemsState.isTableScrollable
                        }
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.relatedItemsState.isTableScrollable,
                              expression: "relatedItemsState.isTableScrollable"
                            }
                          ],
                          staticClass: "switcher__input",
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.relatedItemsState.isTableScrollable
                            )
                              ? _vm._i(
                                  _vm.relatedItemsState.isTableScrollable,
                                  null
                                ) > -1
                              : _vm.relatedItemsState.isTableScrollable
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.relatedItemsState.isTableScrollable,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.relatedItemsState,
                                      "isTableScrollable",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.relatedItemsState,
                                      "isTableScrollable",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.relatedItemsState,
                                  "isTableScrollable",
                                  $$c
                                )
                              }
                            }
                          }
                        }),
                        _c("span", { staticClass: "switcher__indicator" })
                      ]
                    )
                  ])
                ])
              : _vm._e(),
            _c(
              "FormField",
              {
                staticClass: "side-form__item aside-input",
                attrs: { label: "Max items to display" }
              },
              [
                _c("v-text-field", {
                  attrs: { type: "number", min: 1, max: 100, required: "" },
                  model: {
                    value: _vm.relatedItemsState.maxCount,
                    callback: function($$v) {
                      _vm.$set(_vm.relatedItemsState, "maxCount", $$v)
                    },
                    expression: "relatedItemsState.maxCount"
                  }
                })
              ],
              1
            ),
            _c(
              "FormField",
              {
                staticClass: "side-form__item aside-input",
                attrs: { label: "Related items selection method" }
              },
              [
                _c("v-select", {
                  attrs: {
                    value: _vm.relatedItemsState.selectionMethod,
                    items: _vm.selectionMethodOptions,
                    attach: "",
                    "item-text": "label",
                    "item-value": "value",
                    placeholder: "Choose a selection method",
                    "menu-props": "offsetY",
                    "append-icon": "keyboard_arrow_down"
                  },
                  on: {
                    input: function(val) {
                      return (_vm.relatedItemsState.selectionMethod = val)
                    }
                  }
                })
              ],
              1
            ),
            _vm.relatedItemsState.selectionMethod === "category"
              ? _c(
                  "div",
                  {
                    staticClass:
                      "tile-constructor__field side-form__item aside-select"
                  },
                  [
                    _c("h6", { staticClass: "aside-field-label" }, [
                      _vm._v("Specify category column")
                    ]),
                    _c("v-select", {
                      attrs: {
                        items: _vm.cols,
                        "item-text": "label",
                        "item-value": "id",
                        attach: "",
                        placeholder: "Choose a category column",
                        "menu-props": "offsetY",
                        "append-icon": "keyboard_arrow_down"
                      },
                      model: {
                        value: _vm.relatedItemsState.categoryColumn.id,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.relatedItemsState.categoryColumn,
                            "id",
                            $$v
                          )
                        },
                        expression: "relatedItemsState.categoryColumn.id"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.relatedItemsState.selectionMethod === "sku"
              ? _c(
                  "div",
                  {
                    staticClass:
                      "tile-constructor__field side-form__item aside-select"
                  },
                  [
                    _c("h6", { staticClass: "aside-field-label" }, [
                      _vm._v("Specify related SKU column")
                    ]),
                    _c("v-select", {
                      attrs: {
                        items: _vm.cols,
                        "item-text": "label",
                        "item-value": "id",
                        attach: "",
                        placeholder: "related SKU column",
                        "menu-props": "offsetY",
                        "append-icon": "keyboard_arrow_down"
                      },
                      model: {
                        value: _vm.relatedItemsState.skuColumn.id,
                        callback: function($$v) {
                          _vm.$set(_vm.relatedItemsState.skuColumn, "id", $$v)
                        },
                        expression: "relatedItemsState.skuColumn.id"
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }