import api from '@/services/api'
import { hashUncompletedStepsData, getHash } from './hashing'
import { getDataStructure } from './dataStructure'
import { trackEvent } from '@/modules/utils/analytics'
import onboardingWizardData from './onboardingWizardData'

const wizardName = 'onboarding'

export async function setUpdateDomainStepAsCompleted (data, hashes, step = 'updateDomain') {
  try {
    let wizards = []
    if (hashes[step] !== getHash(getDataStructure(data, step))) {
      const response = await setOnboardingWizardStepAsCompleted(step)
      wizards = response
    }
    return wizards
  } catch (err) {
    console.warn(err)
  }
}

export async function checkHashesAndUpdateSteps (steps, data, hashes) {
  if (localStorage.getItem('ssOnboardingStepsProcessing')) return
  try {
    localStorage.setItem('ssOnboardingStepsProcessing', true)
    let wizards = []
    for await (const step of steps) {
      if (step.name === 'publishWebsite' || step.name === 'updateDomain') continue
      if (hashes[step.name]) {
        if (hashes[step.name] !== getHash(getDataStructure(data, step.name))) {
          try {
            const response = await setOnboardingWizardStepAsCompleted(step.name)
            wizards = response
          } catch (err) {
            throw new Error(err)
          }
        }
      }
    }
    return wizards
  } catch (err) {
    console.warn(err)
  } finally {
    localStorage.removeItem('ssOnboardingStepsProcessing')
  }
}

export async function setOnboardingWizardStepAsCompleted (stepName, wizardName = 'onboarding') {
  if (!JSON.parse(localStorage.getItem('ssOnboardingWizardStatus'))) return
  try {
    const response = await api.updateWizard({
      name: wizardName,
      step: {
        name: stepName,
        completed: true
      }
    })
    trackEvent({
      'event': 'Complete step in onboarding wizard',
      'stepName': stepName
    })
    return response
  } catch (err) {
    throw new Error(err)
  }
}

export async function finishOnboardingWizard () {
  try {
    await api.updateWizard({
      name: wizardName,
      finished: true,
      step: {
        name: 'publishWebsite', // just because step is required
        completed: true
      }
    })
    trackEvent({
      'event': 'Finish onboarding wizard'
    })
  } catch (err) {
    throw new Error(err)
  }
}

export { hashUncompletedStepsData, onboardingWizardData }
