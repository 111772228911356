var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "integrations" },
    [
      _vm.enableSearch
        ? _c("div", { staticClass: "integrations__head" }, [
            _c("div", { staticClass: "integrations__head-search-wrap" }, [
              _c("button", {
                staticClass: "integrations__head-search-btn icon-search-new"
              }),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchQuery,
                    expression: "searchQuery"
                  }
                ],
                staticClass: "integrations__head-search",
                attrs: { type: "text", placeholder: "Search..." },
                domProps: { value: _vm.searchQuery },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "esc", 27, $event.key, [
                        "Esc",
                        "Escape"
                      ])
                    ) {
                      return null
                    }
                    _vm.searchQuery = ""
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.searchQuery = $event.target.value
                  }
                }
              })
            ])
          ])
        : _vm._e(),
      _vm.filteredList.length
        ? _c(
            "div",
            {
              staticClass: "integrations__list",
              class: { "is-centered": _vm.singleCentered }
            },
            _vm._l(_vm.filteredList, function(item) {
              return _c(
                "div",
                {
                  key: item.name,
                  staticClass: "integrations__item",
                  class: {
                    "is-disabled": item.enabledStatus === "Coming Soon",
                    "three-in-row": _vm.modalView,
                    "is-single": _vm.singleCentered
                  },
                  on: {
                    click: function($event) {
                      return _vm.setCurrent(item)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "integrations__item-img-bg",
                      style: "background-color: " + item.bgColor + ";"
                    },
                    [
                      _vm.setBadge(item)
                        ? _c(
                            "div",
                            { staticClass: "integrations__item-pro-status" },
                            [
                              _vm._v(
                                "\n          Subscription required\n        "
                              )
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "integrations__item-img-wrap" },
                        [
                          _c("img", {
                            staticClass: "integrations__item-img",
                            attrs: { src: item.logoUrl }
                          })
                        ]
                      )
                    ]
                  ),
                  _c("div", { staticClass: "integrations__item-body" }, [
                    _c("div", { staticClass: "integrations__item-content" }, [
                      _c("h4", { staticClass: "integrations__item-title" }, [
                        _vm._v(_vm._s(item.title))
                      ]),
                      item.about
                        ? _c(
                            "p",
                            { staticClass: "integrations__item-paragraph" },
                            [_vm._v(_vm._s(item.description))]
                          )
                        : _vm._e()
                    ]),
                    item.comingSoon
                      ? _c(
                          "div",
                          { staticClass: "integrations__item-status" },
                          [_vm._v("\n          Coming Soon\n        ")]
                        )
                      : _c(
                          "div",
                          {
                            staticClass: "integrations__item-status",
                            class: {
                              "is-active": ["Connected", "Built-in"].includes(
                                item.enabledStatus
                              )
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(item.enabledStatus) +
                                "\n        "
                            )
                          ]
                        )
                  ])
                ]
              )
            }),
            0
          )
        : _c("div", { staticClass: "integrations__not-found" }, [
            _c("div", { staticClass: "is-centered" }, [
              _c("p", [
                _vm._v(
                  "Sorry, but nothing matched your search terms. Please try  again with some different keywords."
                )
              ]),
              _c(
                "button",
                {
                  staticClass: "btn",
                  on: {
                    click: function($event) {
                      _vm.searchQuery = ""
                    }
                  }
                },
                [_vm._v("RESET")]
              )
            ])
          ]),
      _c("IntegrationModal", {
        attrs: {
          apiKey: _vm.userData.apiKey,
          websiteUrl: _vm.websiteUrl,
          integrationsData: _vm.integrationsData
        },
        on: { update: _vm.updateIntegration },
        model: {
          value: _vm.currentIntegration,
          callback: function($$v) {
            _vm.currentIntegration = $$v
          },
          expression: "currentIntegration"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }