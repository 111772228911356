var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "profile-tab__container" },
        [
          _c("h4", { staticClass: "profile-tab__title" }, [
            _vm._v(_vm._s(_vm.$t("global.settings")))
          ]),
          _vm.userData
            ? _c("p", { staticClass: "profile-tab__subtitle" }, [
                _vm._v(_vm._s(_vm.fullName) + " (" + _vm._s(_vm.email) + ")")
              ])
            : _vm._e(),
          _c("FormSettings", {
            attrs: { newsSubscription: _vm.userData.newsSubscription }
          }),
          _c("div", { staticClass: "profile-tab__divider" }),
          _c("div", { staticClass: "profile-tab__danger" }, [
            _c(
              "button",
              {
                staticClass: "profile-tab__danger-btn",
                on: {
                  click: function($event) {
                    _vm.modalDeleteOpened = true
                  }
                }
              },
              [_vm._v("Delete my account")]
            )
          ])
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: { modalName: "Are you sure?" },
          model: {
            value: _vm.modalDeleteOpened,
            callback: function($$v) {
              _vm.modalDeleteOpened = $$v
            },
            expression: "modalDeleteOpened"
          }
        },
        [
          _c("div", { staticClass: "delete-modal" }, [
            _c("div", { staticClass: "delete-modal__body" }, [
              _c("p", { staticClass: "delete-modal__paragraph" }, [
                _vm._v(
                  "This action cannot be undone. This will permanently delete your entire account."
                )
              ]),
              _c(
                "form",
                {
                  staticClass: "delete-modal__confirm-form",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.deleteAccount.apply(null, arguments)
                    }
                  }
                },
                [
                  _c(
                    "FormField",
                    {
                      attrs: {
                        label: "Please type in your email to confirm",
                        messages: _vm.confirmEmailMessages
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.confirmEmail,
                            expression: "confirmEmail"
                          }
                        ],
                        staticClass: "delete-modal__confirm-form-input",
                        attrs: { type: "text", placeholder: _vm.hiddenEmail },
                        domProps: { value: _vm.confirmEmail },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.confirmEmail = $event.target.value
                          }
                        }
                      })
                    ]
                  ),
                  _c("div", { staticClass: "delete-modal__field" }, [
                    _c(
                      "button",
                      {
                        staticClass: "delete-modal__button",
                        class: { disabled: _vm.loading },
                        attrs: { disabled: _vm.loading, type: "submit" }
                      },
                      [_vm._v("Delete my account")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "delete-modal__button negative",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            _vm.modalDeleteOpened = false
                          }
                        }
                      },
                      [_vm._v("Cancel")]
                    )
                  ])
                ],
                1
              )
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }