<template>
  <form @submit.prevent="addRedirectRule">
    <div class="settings-form__settings-wrap">
      <ProOptionWrapper :featureName="'redirectRules'">
        <FormField label="Redirect FROM">
            <input
              v-model.trim="$v.unsavedRedirectRule.from.$model"
              type="text"
              class="input"
              @blur="unsavedRedirectRule.from = trimPath(unsavedRedirectRule.from)"
            />
            <p
              v-if="$v.unsavedRedirectRule.from.$dirty && !$v.unsavedRedirectRule.from.required"
              class="form-field__error"
            >
              Field is required
            </p>
        </FormField>
      </ProOptionWrapper>
    </div>
    <div class="settings-form__settings-wrap">
      <ProOptionWrapper :featureName="'redirectRules'">
        <FormField label="Redirect TO">
          <input
            v-model.trim="$v.unsavedRedirectRule.to.$model"
            type="text"
            class="input"
            @blur="unsavedRedirectRule.to = trimPath(unsavedRedirectRule.to)"
          />
          <p
            v-if="$v.unsavedRedirectRule.to.$dirty && !$v.unsavedRedirectRule.to.required"
            class="form-field__error"
          >
            Field is required
          </p>
        </FormField>
      </ProOptionWrapper>
    </div>
    <div class="settings-form__settings-wrap">
      <ProOptionWrapper :featureName="'redirectRules'">
        <FormField label="Redirect status">
          <v-select
            v-model="$v.unsavedRedirectRule.status.$model"
            :items="redirectStatuses"
            attach
            placeholder="status"
            menu-props="offsetY"
            append-icon="keyboard_arrow_down"
            >
          </v-select>
          <p
            v-if="$v.unsavedRedirectRule.status.$dirty && !$v.unsavedRedirectRule.status.required"
            class="form-field__error"
          >
            Field is required
          </p>
        </FormField>
      </ProOptionWrapper>
    </div>
    <div class="side-form__item side-form__btns">
      <ProOptionWrapper :featureName="'redirectRules'">
        <button :disabled="$v.$invalid" type="submit" class="btn btn--transparent">Add Rule</button>
      </ProOptionWrapper>
    </div>
    <div class="side-form__item">

      <div class="redirect-rules">
        <div
          v-for="(rule, index) in redirectRules"
          :key="index"
          class="redirect-rules__item"
          :title="`From: ${rule.from}. To: ${rule.to}. Status: ${rule.status}`"
        >
          <div class="redirect-rules__item-left">
            <span class="redirect-rules__item-label">{{ rule.from }}</span>
            <span class="redirect-rules__item-label-divider icon-arrow"></span>
            <span class="redirect-rules__item-label">{{ rule.to }}</span>
            <span class="redirect-rules__item-status">({{ rule.status }})</span>
          </div>
          <button type="button" class="redirect-rules__item-btn icon-delete" @click="deleteRedirectRule(index)"></button>
        </div>
      </div>

    </div>
  </form>
</template>
<script>
import Vue from 'vue'
import Vuelidate from 'vuelidate'
import ProOptionWrapper from '@/components/pro-option-wrapper'
import FormField from '@/components/form-field'
import { required } from 'vuelidate/lib/validators'
Vue.use(Vuelidate)
export default {
  name: 'RedirectRulesForm',
  components: {
    ProOptionWrapper,
    FormField
  },
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      redirectStatuses: [301, 302, 303, 307, 308],
      unsavedRedirectRule: {
        status: 301,
        from: '',
        to: ''
      },
      redirectRules: []
    }
  },
  validations: {
    unsavedRedirectRule: {
      status: { required },
      from: { required },
      to: { required }
    }
  },
  methods: {
    trimPath (val) {
      const trimVal = String(val).trim()
      if (!trimVal.length) return ''
      return this.prefixWithSlash(trimVal)
    },
    prefixWithSlash (path) {
      if (!path.startsWith('/')) {
        return '/' + path
      }
      return path
    },
    addRedirectRule () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      this.redirectRules.push(this.unsavedRedirectRule)
      this.$emit('input', this.redirectRules)
      this.unsavedRedirectRule = {
        status: 301,
        from: '',
        to: ''
      }
      this.$v.$reset()
    },
    deleteRedirectRule (index) {
      this.redirectRules.splice(index, 1)
      this.$emit('input', this.redirectRules)
    }
  },
  created () {
    this.redirectRules = this.value
  }
}
</script>
