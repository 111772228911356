import stringHash from 'string-hash'
import { getDataStructure } from './dataStructure'

export function getHash (obj) {
  return stringHash(JSON.stringify(obj))
}

export function hashUncompletedStepsData (steps, data) {
  const hashes = {}
  for (const step of steps) {
    switch (step.name) {
      case 'editItemCard':
        hashes['editItemCard'] = getHash(
          getDataStructure(data, 'editItemCard')
        )
        break
      case 'editDetailsPage':
        hashes['editDetailsPage'] = getHash(
          getDataStructure(data, 'editDetailsPage')
        )
        break
      case 'editDesign':
        hashes['editDesign'] = getHash(
          getDataStructure(data, 'editDesign')
        )
        break
      case 'setUpCheckout':
        hashes['setUpCheckout'] = getHash(
          getDataStructure(data, 'setUpCheckout')
        )
        break
      case 'updateSeoSettings':
        hashes['updateSeoSettings'] = getHash(
          getDataStructure(data, 'updateSeoSettings')
        )
        break
      case 'addPagesAndLinks':
        hashes['addPagesAndLinks'] = getHash(
          getDataStructure(data, 'addPagesAndLinks')
        )
        break
      case 'updateDomain':
        hashes['updateDomain'] = getHash(
          getDataStructure(data, 'updateDomain')
        )
        break
      case 'enableAddon': {
        hashes['enableAddon'] = getHash(
          getDataStructure(data, 'enableAddon')
        )
      }
    }
  }
  return hashes
}
