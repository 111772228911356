<template>
  <div class="sheet-samples">
    <div class="sheet-samples__list" >
      <div
        v-for="(item, index) in sourcesList"
        :key="index"
        class="sheet-samples__item"
      >
        <div class="sheet-samples__preview">
          <div class="sheet-samples__preview-header">
            <div v-for="n in 3" :key="n" class="sheet-samples__preview-header-circle" />
          </div>
          <div
            class="sheet-samples__preview-inner"
            :style="{ backgroundImage: `url('${item.imageUrl}')`}"
          >
            <!-- @click="onOpenPreviewClick(item)" -->
          </div>
        </div>
        <h5 class="sheet-samples__item-title">{{item.name}}</h5>
        <div class="sheet-samples__item-btns">
          <UiBtn
            attrType="button"
            label="Use template"
            full-width
            @click="$emit('on-link-choice', {templateName: item.templateName})"
          />
          <UiBtn
            label="View"
            type="secondary-blue"
            full-width
            @click="onOpenPreviewClick(item)"
          />
          <!-- <a
            :href="`${publicAppUrl}/template-preview/?tmp_name=${item.templateName}`"
            target="_blank"
            class="sheet-samples__preview-btn is-transparent"
            @click="trackPreviewTemplateAction"
          >View</a> -->
        </div>
      </div>
    </div>
    <TemplatePreviewModal ref="templatePreviewModal" @startWithTemplate="(temp) => onStartTemplateFromPreview(temp)"/>
  </div>
</template>
<script>
import UiBtn from '@/components/ui-btn/UiBtn.vue'
import TemplatePreviewModal from '@/components/template-preview-modal/TemplatePreviewModal.vue'

export default {
  name: 'SheetSamplesList',
  components: {
    TemplatePreviewModal,
    UiBtn
  },
  props: {
    sources: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      publicAppUrl: process.env.VUE_APP_PUBLIC_APP_URL
    }
  },
  computed: {
    sourcesList () {
      return this.sources.map(src => {
        return {
          templateName: src.templateName,
          name: src.name,
          previewLink: src.link,
          copyLink: src.googleDocs + '/copy',
          imageUrl: src.imageUrl
        }
      })
    }
  },
  methods: {
    onOpenPreviewClick (item) {
      this.$refs.templatePreviewModal.open(item)
      this.$trackEvent({
        'event': 'viewTemplate',
        'templateName': item.templateName
      })
    },
    onStartTemplateFromPreview (template) {
      this.$emit('on-link-choice', { templateName: template.templateName, from: 'add-page-preview' })
    }
  }
}
</script>
