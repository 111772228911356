<template>
  <PageAside
    :isOpened="isAsideOpened"
    @close="closeAside"
  >
    <!-- :width="asideWidth" -->

    <div
      v-show="activeMenuTab === 'page-options'"
      class="settings"
    >
      <div class="settings__head">
        <div class="settings__head-top">
          <h3 class="settings__title">Appearance</h3>
          <div
            class="settings__close-sidebar"
            @click="closeAside"
          >
            <i class="settings__close-sidebar-icon icon-close2"></i>
          </div>
        </div>
        <!-- <div class="settings__head-bottom"></div> -->
      </div>

      <div id="editor-bounds" class="settings__body">
        <SpreadViewerDesignOptions
          class="settings__form"
          :value="{appearance: value['appearance'], page: value['page'], header: value['header'], footer: value['footer']}"
          :loading="loading"
          @save="val => $emit('save', val)"
          @input="val => Object.assign(value, val)"
        />
      </div>
    </div>

    <div
      v-show="activeMenuTab === 'content-options'"
      class="settings"
    >
      <div class="settings__head">
        <div class="settings__head-top">
          <h3 class="settings__title">Content</h3>
          <div
            class="settings__close-sidebar"
            @click="closeAside"
          >
            <i class="settings__close-sidebar-icon icon-close2"></i>
          </div>
        </div>
        <div class="settings__head-bottom">
          <UiHelper
            label="How to configure your content"
            :popoverFeature="'mapping'"
            :videoData="{
              src: '/video/helper-popup/mapping.mp4',
              label: 'Learn how to configure and manage your content',
              btns: [
                {
                  label: 'Learn more',
                  to: 'https://help.spreadsimple.com/en/article/mapping-your-sheet-data-to-website-elements-q1fgsr/',
                  externalResource: true,
                  color: 'secondary-blue',
                  event: {
                    type: 'goToHelp'
                  }
                },
                {
                  label: 'Watch video',
                  to: { name: 'help-video', query: { videoPath: '/video/helper-popup/mapping.mp4' }},
                  event: {
                    type: 'goToVideo'
                  }
                }
              ]
            }"
          >
          </UiHelper>
        </div>
      </div>

      <div class="settings__body">

          <SpreadViewerSourceOptions
            :googleUrl="googleUrl"
            :visible="changeSheet"
            :loading="loading"
            class="settings__form"
            @toggle-visibility="val => changeSheet = val"
            @google-url-change="val => $emit('google-url-change', val)"
            @update-sheet-data="$emit('update-sheet-data')"
          />

          <transition name="fade">
            <SpreadViewerContentOptions
              v-show="!changeSheet"
              class="settings__form"
              :cols="cols"
              :value="value"
              :domain="domain"
              :loading="loading"
              :integrationsData="integrationsData"
              :msg="msg"
              @save="val => $emit('save', val)"
              @input="val => Object.assign(this.value, val)"
              @change-tab="val => changeActiveSidebarTab(val)"
              @open-default-single-item="val => $emit('open-default-single-item', val)"
            />
          </transition>
      </div>
    </div>

    <div v-show="activeMenuTab === 'checkout-options'"
      class="settings"
      >
      <div class="settings__head">
        <div class="settings__head-top">
          <h3 class="settings__title">Checkout settings</h3>
          <div
            class="settings__close-sidebar"
            @click="closeAside"
          >
            <i class="settings__close-sidebar-icon icon-close2"></i>
          </div>
        </div>
        <!-- <div class="settings__head-bottom"></div> -->
      </div>

      <div class="settings__body">
        <SpreadViewerCheckoutOptions
          :value="value"
          :cols="cols"
          class="settings__form"
          :loading="loading"
          :msg="msg"
          :integrations="integrations"
          :integrationsData="integrationsData"
          @save="val => $emit('save', val)"
          @input="val => Object.assign(value, val)"
          @open-payment-addons="$emit('open-payment-addons')"
          @disable-payment-addon="val => $emit('disable-payment-addon', val)"
        />
      </div>
    </div>

    <div
      v-show="activeMenuTab === 'settings'"
      class="settings"
    >
      <div class="settings__head">
        <div class="settings__head-top">
          <h3 class="settings__title">Settings</h3>
          <div
            class="settings__close-sidebar"
            @click="closeAside"
          >
            <i class="settings__close-sidebar-icon icon-close2"></i>
          </div>
        </div>
        <!-- <div class="settings__head-bottom"> -->
      </div>

      <div class="settings__body">
        <SpreadViewerSettings
          :value="value"
          class="settings__form"
          :domain="domain"
          :pwdProtection="pwdProtection"
          :loading="loading"
          :locales="locales"
          :seoIndexing="seoIndexing"
          @delete="$emit('delete')"
          @save="val => { $emit('save', val); $emit('reload-preview') }"
          @input="val => Object.assign(value, val)"
          @update-indexing="val => $emit('update-indexing', val)"
          @update-pwd-protection="val => $emit('update-pwd-protection', val)"
        />
      </div>
    </div>

    <div
      v-show="activeMenuTab === 'pages'"
      class="settings"
    >
      <div class="settings__head">
        <div class="settings__head-top">
          <h3 class="settings__title">Pages & links</h3>
          <div
            class="settings__close-sidebar"
            @click="closeAside"
          >
            <i class="settings__close-sidebar-icon icon-close2"></i>
          </div>
        </div>
        <!-- <div class="settings__head-bottom"></div> -->
      </div>
      <div class="settings__body">
        <SpreadViewerRoutesOptions
          :value="{ routes: value.routes }"
          class="settings__form"
          :domain="domain"
          :cols="cols"
          :loading="loading"
          :msg="msg"
          @save="val => $emit('save', val)"
          @input="val => Object.assign(value, val)"
          @change-tab="val => changeActiveSidebarTab(val)"
          @request-preview-change="val => $emit('request-preview-change', val)"
          @open-default-single-item="val => $emit('open-default-single-item', val)"
        />
      </div>
    </div>

    <div
      v-show="activeMenuTab === 'addons'"
      class="settings"
    >
      <div class="settings__head">
        <div class="settings__head-top">
          <h3 class="settings__title">Add-ons</h3>
          <div
            class="settings__close-sidebar"
            @click="closeAside"
          >
            <i class="settings__close-sidebar-icon icon-close2"></i>
          </div>
        </div>
        <!-- <div class="settings__head-bottom"></div> -->
      </div>
      <div class="settings__body">
        <IntegrationsFilter
          :categories="integrationCategories"
          :value="currentIntegrationCategory"
          @input="v => $emit('update:currentIntegrationCategory', v)"
        />
      </div>
    </div>

    <div
      v-if="$ft.ALLOW_USER_ACCESS"
      v-show="activeMenuTab === 'access'"
      class="settings"
      >
      <div class="settings__head">
        <div class="settings__head-top">
          <h3 class="settings__title">Access Manager</h3>
          <div
            class="settings__close-sidebar"
            @click="closeAside"
          >
            <i class="settings__close-sidebar-icon icon-close2"></i>
          </div>
        </div>
      </div>
      <div class="settings__body">
        <SpreadViewerAccessOptions
          v-show="!changeSheet"
          class="settings__form"
          :value="value"
          :domain="domain"
          :isLoading="loading"
          :msg="msg"
          @save="val => { $emit('save', val); $emit('reload-preview') }"
        />
      </div>
    </div>

    <template slot="controls">
      <button
        v-for="(options, index) in asideTabs"
        :key="index"
        :class="{active: activeMenuTab === options.id && isAsideOpened}"
        type="button"
        class="aside-control"
        @click="changeActiveSidebarTab(options.id)"
      >
        <i :class="`icon-${options.iconName}`" class="aside-control__icon"></i>
        <h4 class="aside-control__title"> {{ options.title }} </h4>
        <span class="aside-control__border" />
      </button>
    </template>
  </PageAside>
</template>
<script>
import SpreadViewerDesignOptions from './SpreadViewerDesignOptions.vue'
import SpreadViewerContentOptions from './SpreadViewerContentOptions.vue'
import SpreadViewerCheckoutOptions from './SpreadViewerCheckoutOptions.vue'
import SpreadViewerSourceOptions from './SpreadViewerSourceOptions.vue'
import SpreadViewerSettings from './SpreadViewerSettings.vue'
import SpreadViewerRoutesOptions from './spread-viewer-routes-options'
import SpreadViewerAccessOptions from './spread-viewer-access-options'
import PageAside from '@/components/page-aside/'
import UiHelper from '@/components/ui-helper'
import IntegrationsFilter from '@/components/integrations-filter'
import { getMessages, getLocales } from 'viewer-i18n'
import { mapState } from 'vuex'

export default {
  name: 'SpreadViewerOptions',
  components: {
    SpreadViewerDesignOptions,
    SpreadViewerContentOptions,
    SpreadViewerCheckoutOptions,
    SpreadViewerSourceOptions,
    SpreadViewerSettings,
    SpreadViewerRoutesOptions,
    PageAside,
    IntegrationsFilter,
    SpreadViewerAccessOptions,
    UiHelper
  },
  props: {
    cols: {
      type: Array,
      required: true
    },
    value: {
      type: Object,
      required: true
    },
    domain: {
      type: String,
      required: true
    },
    googleUrl: {
      type: String,
      required: true
    },
    pwdProtection: {
      type: Object,
      required: true
    },
    seoIndexing: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean
    },
    currentIntegrationCategory: {
      type: [String, Object]
    },
    integrationCategories: {
      type: Array,
      required: true
    },
    integrations: {
      type: Array,
      required: true
    },
    integrationsData: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      isAsideOpened: true,
      changeSheet: false,
      activeMenuTab: 'content-options',
      asideTabs: [
        { id: 'page-options', iconName: 'theme', title: 'Design' },
        { id: 'content-options', iconName: 'tiles', title: 'Content' },
        { id: 'checkout-options', iconName: 'checkout', title: 'Checkout' },
        { id: 'settings', iconName: 'option', title: 'Settings' },
        { id: 'pages', iconName: 'pages-links', title: 'Pages & links' },
        { id: 'addons', iconName: 'addons', title: 'Add-ons' },
        ...(this.$ft.ALLOW_USER_ACCESS ? [{ id: 'access', iconName: 'team', title: 'Access Manager' }] : [])
      ]
    }
  },
  computed: {
    ...mapState('app', [
      'integrationsOpened'
    ]),
    locales () {
      return getLocales()
    },
    msg () {
      return getMessages(this.value.locale || 'en')
    }
  },
  watch: {
    activeMenuTab (val) {
      this.setIntegrationsOpened(val === 'addons')
    }
  },
  methods: {
    setIntegrationsOpened (value = false) {
      this.$store.commit('app/set', { key: 'integrationsOpened', value })
    },
    closeAside () {
      this.isAsideOpened = false
    },

    openAside () {
      this.isAsideOpened = true
    },

    changeActiveSidebarTab (sidebarState) {
      this.openAside()
      this.$store.commit('app/set', { key: 'integrationsOpened', value: false })
      this.activeMenuTab = sidebarState
    },
    openDomainSettingsTab () {
      this.changeActiveSidebarTab('settings')
    }
  },
  created () {
    this.$root.$on('openDomainSettings', this.openDomainSettingsTab)
  },
  beforeDestroy () {
    this.$root.$off('openDomainSettings')
  }
}
</script>
