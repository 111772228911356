<template>
  <div
    :class="{ 'absolute': positionAbsolute, 'fixed': positionFixed }"
    class="aside"
  >
    <!-- :style="{ width: `${width}px`}" -->
    <div class="aside__controls-wrap">
      <slot name="controls"/>
    </div>
    <div class="aside__body" :class="{'opened': isOpened}">
      <!-- <a
        class="aside__close"
        @click="$emit('close')"
      >
        <i class="icon icon-close2"></i>
      </a> -->
      <div class="aside__body-inner">
        <slot/>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PageAside',
  props: {
    width: {
      type: Number
    },
    positionAbsolute: {
      type: Boolean,
      default: false
    },
    positionFixed: {
      type: Boolean,
      default: false
    },
    isOpened: {
      type: Boolean,
      default: false
    }
  }
}
</script>
