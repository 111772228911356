export default {
  published: true,
  pro: false,
  featureRestrictionsGroup: 'builtInIntegrations',
  name: 'notion',
  title: 'Notion Import',
  headline: '',
  logoUrl: '/img/integrations/notion.svg',
  // coverUrl: '/img/integrations/notion-cover.png',
  bgColor: '#000000',
  categories: ['Content Management'],
  comingSoon: false,
  builtIn: true,
  url: 'https://www.notion.so',
  helpUrl: 'https://help.spreadsimple.com/en/article/import-from-notion-1lvfjr7/',
  description: `
  This is a built-in integration.

  Import your content from Notion in a few clicks.

  1. Open your Notion page and <a href="https://www.notion.so/Export-a-page-as-Markdown-69b6031dd9454022abed8e23a86b0e1e" target="_blank">export it in Markdown & CSV format as a zip-file</a>.
  2. Upload the file on a page of your SpreadSimple website.

  ![](https://storage.crisp.chat/users/helpdesk/website/918f9541750fe800/inport-from-notion2_qrxglm.png)
  `,

  formFields: [],

  actions: []
}
