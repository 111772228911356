import { fuzzySearch, removeDuplicates } from './utils'

const MAX_TRUSTED_SCORE = 0.35

export function mapColumns (cols = [], vals = [], {
  extractInput = c => c.label,
  extractOutput = val => val
} = {}) {
  const mappedVals = vals.map(val => {
    const matchedCols = cols
      .map(col => {
        const input = extractInput(col)
        const matched = fuzzySearch(input, val.name)
        return {
          col: col,
          score: matched.length ? matched[0].score : null
        }
      })
      .filter(v => v.score !== null)
      .filter(v => v.score < MAX_TRUSTED_SCORE)
      .sort((a, b) => a.score - b.score)

    return {
      name: val.name,
      type: val.type,
      matchedCols
    }
  })

  removeDuplicates(mappedVals)

  function getValueByType (matchedCols, type) {
    if (!matchedCols.length) return null
    if (type === 'single') return extractOutput(matchedCols[0].col)
    if (type === 'multiple') return matchedCols.map(({ col }) => extractOutput(col))
  }

  // todo - remove duplicates in multiple values

  return mappedVals.map(val => {
    return {
      ...val,
      value: getValueByType(val.matchedCols, val.type)
    }
  })
}
