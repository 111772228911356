<template>
<div>
  <!-- <button type="button" class="settings__back-btn" @click="$emit('back')">
    <i class="icon-arrow settings__back-btn-icon"></i>
    Back
  </button> -->
  <!-- <div class="side-form__section" style="padding-top: 10px;">
    <div class="side-form__filter-item" @click="setCategory('_CONNECTED_')">
      <span class="side-form__filter-item-title">Connected integrations</span>
      <div class="side-form__filter-item-value">1</div>
    </div>
  </div> -->
  <div class="side-form__section">
    <h6 class="side-form__section-title">Categories</h6>
    <div class="side-form__item">
      <div
        class="side-form__filter-item"
        :class="{ 'is-active': value === '_ALL_' }"
        @click="setCategory('_ALL_')"
      >
        <span class="side-form__filter-item-title">{{ getCategoryItemWithValueAll().title }}</span>
        <div class="side-form__filter-item-value">{{ getCategoryItemWithValueAll().count }}</div>
      </div>
      <div
        v-for="category in sortedCategories"
        :key="category.value"
        class="side-form__filter-item"
        :class="{ 'is-active': category.value === value }"
        @click="setCategory(category.value)"
        >
        <span class="side-form__filter-item-title">{{ category.title }}</span>
        <div class="side-form__filter-item-value">{{ category.count }}</div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'IntegrationsFilter',
  props: {
    value: {
      type: [String, Object],
      default: '_ALL_'
    },
    categories: {
      type: Array,
      required: true
    }
  },
  computed: {
    sortedCategories () {
      const array = JSON.parse(JSON.stringify(this.categories))
        .filter(cat => cat.value !== '_ALL_')
      return array.sort((a, b) => {
        if (a.title > b.title) {
          return 1
        }
        if (a.title < b.title) {
          return -1
        }
        return 0
      })
    }
  },
  methods: {
    setCategory (value) {
      this.$emit('input', value)
    },
    getCategoryItemWithValueAll () {
      return this.categories.find(cat => cat.value === '_ALL_')
    }
  }
}
</script>
