var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%" } },
    [
      _c("v-md-editor", {
        key: "vmdeditor",
        ref: "vmdeditor",
        staticClass: "sv-md-editor",
        attrs: {
          height: _vm.height,
          placeholder: "Enter text",
          leftToolbar: _vm.leftToolbar,
          rightToolbar: "",
          mode: _vm.preview ? "editable" : "edit",
          toolbar: _vm.toolbar,
          "disabled-menus": []
        },
        model: {
          value: _vm.content,
          callback: function($$v) {
            _vm.content = $$v
          },
          expression: "content"
        }
      }),
      _c("UploadImageModal", {
        attrs: { opened: _vm.unsplashModalOpened, unsplashEnabled: true },
        on: {
          input: _vm.handleUploadImage,
          "on-close": function($event) {
            _vm.unsplashModalOpened = false
          }
        }
      }),
      _c("UploadFromNotion", {
        attrs: { opened: _vm.notionModalOpened },
        on: {
          input: _vm.handleUploadFile,
          "on-close": function($event) {
            _vm.notionModalOpened = false
          }
        }
      }),
      _c("CreateCartItemsTable", {
        attrs: { cols: _vm.cols, opened: _vm.cartItemsModalOpened },
        on: {
          input: _vm.handleCartItemsTable,
          "on-close": function($event) {
            _vm.cartItemsModalOpened = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }