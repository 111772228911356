var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sorting-constructor__highlighted" }, [
    _c("div", { staticClass: "sorting-constructor__item" }, [
      _c(
        "div",
        { staticClass: "sorting-constructor__select aside-select paddingless" },
        [
          _c("h6", { staticClass: "sorting-constructor__item-label" }, [
            _vm._v("Hide columns in public mode")
          ]),
          _c("v-select", {
            attrs: {
              value: _vm.privateCells.ids,
              items: _vm.cols,
              "item-text": "label",
              "item-value": "id",
              attach: "",
              "menu-props": "offsetY",
              chips: "",
              "deletable-chips": "",
              multiple: "",
              placeholder: "Choose options",
              "append-icon": "keyboard_arrow_down"
            },
            on: { change: _vm.setPrivateCells }
          }),
          _c(
            "a",
            {
              staticClass: "sorting-constructor__link",
              attrs: {
                target: "_blank",
                href:
                  "https://help.spreadsimple.com/en/article/digital-downloads-1jrcqgw/"
              }
            },
            [_vm._v("Help: How to use sensitive columns?")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }