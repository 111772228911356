var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      staticClass: "confirmation-popup",
      attrs: { value: _vm.opened, modalName: "Choose columns" },
      on: {
        input: function($event) {
          return _vm.$emit("on-close")
        }
      }
    },
    [
      _c("div", { staticClass: "confirmation-popup__content" }, [
        _c("p", [
          _vm._v(
            "Choose which columns you would like to show for every ordered item.\n        Additionally, you can choose whether to show quantity and price for each item"
          )
        ]),
        _c("div", { staticClass: "sorting-constructor__item" }, [
          _c(
            "div",
            {
              staticClass:
                "sorting-constructor__select aside-select paddingless"
            },
            [
              _c("h6", { staticClass: "sorting-constructor__item-label" }, [
                _vm._v("Choose format")
              ]),
              _c("v-select", {
                attrs: {
                  items: _vm.formatOptions,
                  "item-text": "label",
                  "item-value": "value",
                  attach: "",
                  "menu-props": "offsetY",
                  placeholder: "Choose options",
                  "append-icon": "keyboard_arrow_down"
                },
                model: {
                  value: _vm.format,
                  callback: function($$v) {
                    _vm.format = $$v
                  },
                  expression: "format"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "sorting-constructor__item" }, [
          _c(
            "div",
            {
              staticClass:
                "sorting-constructor__select aside-select paddingless"
            },
            [
              _c("h6", { staticClass: "sorting-constructor__item-label" }, [
                _vm._v("Choose columns")
              ]),
              _c("v-select", {
                attrs: {
                  value: _vm.selectedColumns,
                  items: _vm.cols,
                  "item-text": "label",
                  "item-value": "label",
                  attach: "",
                  "menu-props": "offsetY",
                  chips: "",
                  "deletable-chips": "",
                  multiple: "",
                  placeholder: "Choose options",
                  "append-icon": "keyboard_arrow_down"
                },
                on: { change: _vm.updateSelectedColumns }
              })
            ],
            1
          )
        ]),
        _c("textarea", {
          staticClass: "iframe-code__textarea",
          attrs: { "aria-readonly": "" },
          domProps: { value: _vm.value }
        })
      ]),
      _c(
        "div",
        { staticClass: "confirmation-popup__btns" },
        [
          _c(
            "UiBtn",
            { attrs: { type: "primary" }, on: { click: _vm.insertContent } },
            [_vm._v("Insert")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }