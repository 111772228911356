var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return false
    ? _c("div", [
        !_vm.small && !_vm.isTrialBarHidden
          ? _c("div", { staticClass: "header-trialing-bar" }, [
              _c("span", { staticClass: "header-trialing-bar__text-wrapper" }, [
                _c("b", [_vm._v("Yay! You are now trialing PRO.")]),
                _vm._v(
                  " You have " +
                    _vm._s(_vm.trialEndsInDays) +
                    " days to try out our\n      "
                ),
                _c(
                  "a",
                  {
                    staticClass: "highlighted",
                    attrs: {
                      href: _vm.publilAppUrl + "/pricing",
                      target: "_blank"
                    },
                    on: { click: _vm.trackPricingLinkEvent }
                  },
                  [_vm._v("premium features")]
                ),
                _vm._v(".\n    ")
              ]),
              _c("button", {
                staticClass: "header-trialing-bar__close-btn icon-close",
                on: { click: _vm.hideTrialBar }
              })
            ])
          : _vm._e(),
        _vm.small
          ? _c("div", { staticClass: "header-trialing-bar is-small" }, [
              _vm._v("\n    Your Trial ends in "),
              _c("b", [_vm._v(" " + _vm._s(_vm.trialEndsInDays) + " ")]),
              _vm._v(" days.\n  ")
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }