var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-video" }, [
    _c("div", { staticClass: "page-video__container" }, [
      _c(
        "video",
        {
          staticClass: "page-video__video",
          attrs: { autoplay: "", loop: "", muted: "", controls: "" },
          domProps: { muted: true }
        },
        [
          _c("source", {
            attrs: { src: _vm.$route.query.videoPath, type: "video/mp4" }
          })
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }