<template>
  <Modal
    :value="opened"
    modalName="Choose columns"
    class="confirmation-popup"
    @input="$emit('on-close')"
    >
      <div class="confirmation-popup__content">
        <p>Choose which columns you would like to show for every ordered item.
          Additionally, you can choose whether to show quantity and price for each item</p>

        <div class="sorting-constructor__item">
          <div class="sorting-constructor__select aside-select paddingless">
            <h6 class="sorting-constructor__item-label">Choose format</h6>
            <v-select
              v-model="format"
              :items="formatOptions"
              item-text="label"
              item-value="value"
              attach
              menu-props="offsetY"
              placeholder="Choose options"
              append-icon="keyboard_arrow_down"
            >
            </v-select>
          </div>
        </div>

        <div class="sorting-constructor__item">
          <div class="sorting-constructor__select aside-select paddingless">
            <h6 class="sorting-constructor__item-label">Choose columns</h6>
            <v-select
              :value="selectedColumns"
              :items="cols"
              item-text="label"
              item-value="label"
              attach
              menu-props="offsetY"
              chips
              deletable-chips
              multiple
              placeholder="Choose options"
              append-icon="keyboard_arrow_down"
              @change="updateSelectedColumns"
            >
            </v-select>
          </div>
        </div>
        <textarea aria-readonly class="iframe-code__textarea" :value="value"></textarea>
      </div>
      <div class="confirmation-popup__btns">
        <UiBtn type="primary" @click="insertContent">Insert</UiBtn>
      </div>
  </Modal>

</template>
<script>
import UiBtn from '@/components/ui-btn'
import Modal from '@/components/modal'
export default {
  name: 'CreateCartItemsTable',
  components: {
    Modal,
    UiBtn
  },
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    cols: {
      type: Array,
      default: () => { return [] }
    }
  },
  data () {
    return {
      selectedColumns: [],
      format: 'cartItemsTablePQ',
      formatOptions: [
        { label: 'Insert as a table', value: 'cartItemsTable' },
        { label: 'Insert as a table with price and quantity', value: 'cartItemsTablePQ' },
        { label: 'Insert as a list', value: 'cartItemsList' },
        { label: 'Insert as a list with price and quantity', value: 'cartItemsListPQ' }
      ]
    }
  },
  computed: {
    value () {
      return this.selectedColumns.length
        ? `{{${this.format}(${this.selectedColumns.reduce((acc, item, index) => { return acc.concat(`"${item}"${index < this.selectedColumns.length - 1 ? ', ' : ''}`) }, '')})}}`
        : ''
    }
  },
  methods: {
    updateSelectedColumns (val) {
      this.selectedColumns = val
    },
    insertContent () {
      this.$emit('input', this.value)
      this.$emit('on-close')
      this.selectedColumns = []
    }
  }

}
</script>
