<template>
<div v-if="false">
  <div v-if="!small && !isTrialBarHidden" class="header-trialing-bar">
    <span class="header-trialing-bar__text-wrapper">
      <b>Yay! You are now trialing PRO.</b> You have {{trialEndsInDays}} days to try out our
      <a class="highlighted" :href="`${publilAppUrl}/pricing`" target="_blank" @click="trackPricingLinkEvent">premium features</a>.
    </span>
    <button class="header-trialing-bar__close-btn icon-close" @click="hideTrialBar"></button>
  </div>
  <div v-if="small" class="header-trialing-bar is-small">
    Your Trial ends in <b> {{ trialEndsInDays }} </b> days.
  </div>
</div>
</template>
<script>
import { mapState } from 'vuex'
import moment from 'moment'

export default {
  name: 'TrialNotificationBar',
  props: {
    spreadViewId: {
      type: String
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      publilAppUrl: process.env.VUE_APP_PUBLIC_APP_URL,
      monthlyPcice: process.env.VUE_APP_MONTHLY_PRICE,
      trialBarOpened: true
    }
  },
  computed: {
    ...mapState('app', [
      'userData'
    ]),
    // showTrailBar () {
    //   if (!this.userData) return false
    //   return this.userData.dealOptions.isTrial && !this.userData.appsumo
    // },
    isTrialBarHidden () {
      const isBarHidden = JSON.parse(localStorage.getItem('ssTrialBar'))
      if (isBarHidden) return true
      if (!this.trialBarOpened) return true
      return false
    },
    trialEndsInDays () {
      if (!this.userData) return 0
      const endDate = this.userData.dealOptions.nextBillDate
      const start = moment(new Date())
      const end = moment(new Date(endDate.toString()))
      return Math.round(moment.duration(end.diff(start)).asDays())
    }
  },
  methods: {
    trackPricingLinkEvent () {
      this.$trackEvent({
        'event': 'clickTrialBanner',
        'clickType': 'premium_features'
      })
    },
    hideTrialBar () {
      localStorage.setItem('ssTrialBar', JSON.stringify('hidden'))
      this.trialBarOpened = false
    }
  }
}
</script>
