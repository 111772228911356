// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import router from '@/router'
import store from '@/store/'
import VueAsyncOperations from 'vue-async-operations'
import PortalVue from 'portal-vue'
import VueLiteKit from 'vue-lite-kit'
import Toasted from 'vue-toasted'
import Select from '@/components/select'
import VueScrollClass from 'vue-scroll-class'
import Preloader from 'preloader-component'
import vClickOutside from 'v-click-outside'
import { Loading, Dropdown, DropdownMenu, DropdownItem, Tabs, TabPane, Tooltip, Select as ElSelect, Option, Popover, Checkbox, CheckboxGroup, DatePicker, Dialog, Message, Input, Progress as ElProgress } from 'element-ui'
import ElementUiLang from 'element-ui/lib/locale/lang/en'
import ElementUiLocale from 'element-ui/lib/locale'

import VueI18n from 'vue-i18n'
import Vuetify, {
  VSelect,
  VTextField,
  VTextarea,
  VAutocomplete,
  VChip
} from 'vuetify/lib'
import VueGtm from 'vue-gtm'
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'
import { trackEvent } from '@/modules/utils/analytics'
import { trackFeature } from '@/modules/utils/featureStatusTracker'

import locales from '@/locales/'
import App from '@/App'
import '@/services/auth/'

import VMdEditor from '@kangc/v-md-editor'
import '@kangc/v-md-editor/lib/style/base-editor.css'
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js'
import createEmojiPlugin from '@kangc/v-md-editor/lib/plugins/emoji/index'
import enUS from '@kangc/v-md-editor/lib/lang/en-US'
import createTodoListPlugin from '@kangc/v-md-editor/lib/plugins/todo-list/index'
import createMermaidPlugin from '@kangc/v-md-editor/lib/plugins/mermaid/npm'
import '@kangc/v-md-editor/lib/plugins/mermaid/mermaid.css'
Vue.prototype.$message = Message

export default {
  conf: null,
  router: null,
  store: null,

  /**
   * Add VueRouter
   * http://router.vuejs.org/en/index.html
   */
  addRouter () {
    this.router = router
    return this
  },
  /**
   * Add vuex store
   */
  addStore () {
    this.store = store
    return this
  },

  /**
   * Add event hub
   */
  addEventHub () {
    Vue.prototype.$eh = new Vue()
    return this
  },

  /**
   * Add Analytics
   */
  addAnalytics () {
    if (process.env.VUE_APP_GTM_ID !== 'undefined') {
      Vue.use(VueGtm, {
        id: process.env.VUE_APP_GTM_ID,
        enabled: true,
        loadScript: true,
        vueRouter: this.router
      })
    }
    Vue.prototype.$trackEvent = trackEvent
    Vue.prototype.$trackFeature = trackFeature
    return this
  },

  /**
   * Add Sentry
   */
  addSentry () {
    if (process.env.VUE_APP_SENTRY_DSN !== 'undefined') {
      Sentry.init({
        release: process.env.VUE_APP_VERSION,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        integrations: [
          new VueIntegration({ Vue, attachProps: true, logErrors: true })
        ]
      })
    }
    return this
  },

  /**
   * Add vue portal plugin
   */
  addPortalVue () {
    Vue.use(PortalVue)
    return this
  },

  /**
   * Add async operations plugin
   */
  addVueAsyncOperations () {
    Vue.use(VueAsyncOperations)
    return this
  },

  /**
   * Add vue-lite-kit plugin
   */
  addVueLiteKit () {
    Vue.use(VueLiteKit, {})
    return this
  },

  /**
   * Add select custom wrapper
   */
  addSelectWrapper () {
    Vue.component('Select', Select)
    return this
  },

  /**
   * Add preloader
   */
  addPreloader () {
    Vue.component('Preloader', Preloader)
    return this
  },

  addToasted () {
    Vue.use(Toasted)
    return this
  },

  addClickOutside () {
    Vue.use(vClickOutside)
    return this
  },

  addElementOnDemand () {
    Vue.use(Dropdown)
    Vue.use(DropdownMenu)
    Vue.use(DropdownItem)
    Vue.use(Tabs)
    Vue.use(TabPane)
    Vue.use(Tooltip)
    Vue.use(ElSelect)
    Vue.use(Option)
    Vue.use(Popover)
    Vue.use(Checkbox)
    Vue.use(CheckboxGroup)
    Vue.use(DatePicker)
    Vue.use(Dialog)
    Vue.use(Input)
    Vue.use(ElProgress)
    Vue.use(Loading)
    return this
  },

  addElementUiLang () {
    ElementUiLocale.use(ElementUiLang)
    return this
  },

  addI18n () {
    Vue.use(VueI18n)

    this.i18n = new VueI18n({
      fallbackLocale: 'en',
      locale: 'en',
      messages: locales
    })

    return this
  },

  addVuetify () {
    Vue.use(Vuetify, {
      components: {
        VSelect,
        VTextField,
        VTextarea,
        VAutocomplete,
        VChip
      }
    })

    return this
  },
  addMdEditor () {
    VMdEditor.use(createTodoListPlugin())
    VMdEditor.use(createMermaidPlugin())
    VMdEditor.lang.use('en-US', enUS)
    VMdEditor.use(createEmojiPlugin())
    VMdEditor.use(githubTheme)
    VMdEditor.xss.extend({
      whiteList: {
        iframe: ['allow', 'style', 'frameborder', 'tabindex', 'aria-hidden', 'allowfullscreen', 'allowpaymentrequest', 'height', 'loading', 'name', 'referrerpolicy', 'sandbox', 'src', 'srcdoc', 'width']
      }
    })

    Vue.use(VMdEditor)
    return this
  },

  addFeatureTogglers () {
    /**
     * 1) add a toggle in featureToggles array, e.g. 'SHOW_WIP'
     * 2) to turn it on via env add VUE_APP_FT_SHOW_WIP=true
     * 3) to turn in on via console run localStorage.FT_SHOW_WIP = true
     * 4) Usage in component template `v-if="$ft.SHOW_WIP"`
     * 5) Usage in component script `if (this.$ft.SHOW_WIP)`
     *
     * FT also can be added to user data on backend,
     * e.g. me.features: ['FT_SHOW_WIP=1']
     *
     */

    const featureToggles = [
      'SHOW_WIP',
      'ENABLE_TRIAL',
      'INTEGRATIONS_WIP',
      'KEEP_INTRO_RATIO',
      'NEXT',
      'ALLOW_USER_ACCESS'
    ]

    function parseBool (val) {
      if (!val) return false
      if (typeof val !== 'string') return false
      const tVal = val.trim()
      if (tVal === 'false' || tVal === '0' || tVal === 'off') return false
      return true
    }

    Vue.prototype.$ft = featureToggles.reduce((ft, toggle) => {
      ft[toggle] = parseBool(process.env[`VUE_APP_FT_${toggle}`]) || parseBool(localStorage[`FT_${toggle}`])
      return ft
    }, {})
  },

  /**
   * Run application
   */
  run () {
    const vm = new Vue({
      router: this.router,
      store: this.store,
      i18n: this.i18n,
      directives: {
        'scroll-class': VueScrollClass
      },
      render: h => h(App)
    }).$mount('#app')

    if (process.env.NODE_ENV === 'development') window.$vm = vm

    return this
  }
}

// @TODO - change this notation
Vue.directive('scroll-class', VueScrollClass)
