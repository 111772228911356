<template>
  <Modal    
    :value="value"
    @input="val => $emit('input', val)"
    :modalName="$t('pageManageSheet.modal.titlePublish')"    
    >
    <div class="publish-modal">
      <div class="publish-modal__published-state">
        <div class="publish-modal__result-field">
          <input ref="code" type="text" class="publish-modal__result-field-url" readonly :value="resultUrl">
          <button class="publish-modal__result-field-copy icon-duplicate2" @click.prevent="copyToClipboard"></button>
        </div>
        <div class="publish-modal__back-step for-published">You can <button class="publish-modal__back-step-button" @click="onChangeDomain()">change domain</button></div>
        <a
          target="_blank"
          :href="resultUrl"
          class="publish-modal__button for-published"
          :class="{'disabled': publishingInProgress}"
        >
          <span v-if="!publishingInProgress" >Open</span>
          <div v-else class="icon-loader"></div>
        </a>
      </div>
    </div>
  </Modal>
</template>
<script>
import Modal from '@/components/modal'
export default {
  name: 'PublishModal',
  components: {
    Modal
  },
  props: {
    value: { 
      type: Boolean,
      required: true
    },
    resultUrl: {
      type: String,
      required: true
    },
    publishingInProgress: {
      type: Boolean,
      required: true
    }
  },
  methods: { 
    copyToClipboard () {  
      let code = this.$refs.code
      code.select()
      document.execCommand('copy')
    },
    onChangeDomain () {
      this.$root.$emit('openDomainSettings')
      this.$emit('input', false)
    }
  }
}
</script>
