<template>
  <component
    :is="component"
    class="ui-btn"
    :to="to"
    v-bind="$attrs"
    :type="!to ? attrType : null"
    :class="[
    `ui-btn--size-${size}`,
    `ui-btn--type-${type}`,
    {
      'ui-btn--loading': loading,
      'ui-btn--disabled': disabled,
      'ui-btn--wide': wide,
      'ui-btn--uppercase': uppercase,
      'ui-btn--full-width': fullWidth,
      'ui-btn--inline': inline,
      'ui-btn--icon-only': iconOnly,
      'ui-btn--has-prepend': prependIcon,
      'ui-btn--has-append': appendIcon,
      'is-highlighted': highlighted
    }]"
    :disabled="disabled"
    v-on="$listeners"
  >
    <UiIcon
      v-if="prependIcon && !loading"
      class="ui-btn__prepend-icon"
      :icon="prependIcon"
    />
    <UiIcon
      v-if="loading"
      icon="im-loader"
      class="ui-btn__loading-icon"
    />
    <!-- <span v-if="loading" class="ui-btn__loader"></span> -->
    <span
      v-if="$slots.default || label"
      class="ui-btn__text"
      :class="labelClass ? `${labelClass}` : null "
    >
      <slot> {{ label }} </slot>
    </span>
    <UiIcon
      v-if="iconOnly && !loading"
      class="ui-btn__single-icon"
      :icon="iconOnly"
    />
    <UiIcon
      v-if="appendIcon"
      class="ui-btn__append-icon"
      :icon="appendIcon"
    />
  </component>
</template>
<script>
import UiIcon from '@/components/ui-icon'
export default {
  name: 'UiBtn',
  components: {
    UiIcon
  },
  props: {
    to: {
      type: [String, Object],
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    uppercase: {
      type: Boolean,
      default: false
    },
    attrType: {
      type: String,
      default: 'button'
    },
    prependIcon: {
      type: String,
      required: false
    },
    appendIcon: {
      type: String,
      required: false
    },
    wide: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    labelClass: {
      type: String,
      default: null
    },
    iconOnly: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: 'm',
      validator (value) {
        return [
          'xs',
          's',
          'm',
          'l'
        ].includes(value)
      }
    },
    type: {
      type: String,
      default: 'primary',
      validator (value) {
        return [
          'primary',
          'secondary',
          'secondary-blue',
          'secondary-text-blue',
          'ghost',
          'ghost-secondary',
          'success',
          'info',
          'warning',
          'help',
          'danger',
          'contrast'
        ].includes(value)
      }
    },
    inline: Boolean,
    highlighted: Boolean
  },
  computed: {
    component () {
      return !this.to ? 'button' : 'router-link'
    }
  }
}
</script>
