const currencyCodes = [
  { label: 'Australian dollar', value: 'AUD' },
  { label: 'Brazilian real', value: 'BRL' },
  { label: 'Canadian dollar', value: 'CAD' },
  { label: 'Chinese Renmenbi', value: 'CNY' },
  { label: 'Czech koruna', value: 'CZK' },
  { label: 'Danish krone', value: 'DKK' },
  { label: 'Euro', value: 'EUR' },
  { label: 'Hong Kong dollar', value: 'HKD' },
  { label: 'Hungarian forint', value: 'HUF' },
  { label: 'Indian rupee', value: 'INR' },
  { label: 'Israeli new shekel', value: 'ILS' },
  { label: 'Japanese yen', value: 'JPY' },
  { label: 'Malaysian ringgit', value: 'MYR' },
  { label: 'Mexican peso', value: 'MXN' },
  { label: 'New Taiwan dollar', value: 'TWD' },
  { label: 'New Zealand dollar', value: 'NZD' },
  { label: 'Norwegian krone', value: 'NOK' },
  { label: 'Philippine peso', value: 'PHP' },
  { label: 'Polish złoty', value: 'PLN' },
  { label: 'Pound sterling', value: 'GBP' },
  { label: 'Russian ruble', value: 'RUB' },
  { label: 'Singapore dollar', value: 'SGD' },
  { label: 'Swedish krona', value: 'SEK' },
  { label: 'Swiss franc', value: 'CHF' },
  { label: 'Thai baht', value: 'THB' },
  { label: 'United States dollar', value: 'USD' }
]

const payPalEnvironments = [
  { label: 'Sandbox', value: 'sandbox' },
  { label: 'Live', value: 'production' }
]
const payPalButtonColors = [
  { label: 'Gold', value: 'gold' },
  { label: 'Blue', value: 'blue' },
  { label: 'Silver', value: 'silver' },
  { label: 'White', value: 'white' },
  { label: 'Black', value: 'black' }
]

export default {
  published: true,
  pro: true,
  featureRestrictionsGroup: 'paymentIntegrations',
  name: 'paypal',
  title: 'PayPal',
  headline: '',
  logoUrl: '/img/integrations/paypal.svg',
  // coverUrl: '/img/integrations/paypal-cover.png',
  bgColor: '#179BD7',
  categories: ['Payment Gateway'],
  comingSoon: false,
  builtIn: false,
  url: 'https://developer.paypal.com',
  helpUrl: 'https://help.spreadsimple.com/en/article/paypal-checkout-of7z8r/',
  description: `
  To set up PayPal checkout:

  1. Enable PayPal addon.
  2. Get **PayPal Client ID** by **creating app** in <a href="https://developer.paypal.com/developer/applications" target="_blank">Developers Dashboard</a>.
  3. Choose the type of environment and paste your **Client ID** in the PayPal settings.
  `,

  functions: {
    checkSandboxMode: (mode) => (value) => {
      if (mode === 'sandbox' && value !== 'sandbox') return false
      return true
    }
  },

  formFields: [
    {
      name: 'enabled',
      type: 'toggle',
      label: 'Enabled',
      default: false,
      validations: [
        'required'
      ],
      secret: false
    },
    {
      name: 'currency',
      type: 'select',
      showIf: ['enabled'],
      label: 'Currency Code',
      default: 'USD',
      placeholder: '',
      validations: ['required'],
      options: currencyCodes,
      secret: false
    },
    {
      name: 'buttonColor',
      type: 'select',
      showIf: ['enabled'],
      label: 'Button color',
      default: 'blue',
      placeholder: '',
      validations: ['required'],
      options: payPalButtonColors,
      secret: false
    },
    {
      name: 'environment',
      type: 'select',
      showIf: ['enabled'],
      label: 'Environment',
      default: 'sandbox',
      placeholder: '',
      validations: ['required', 'runInternalValidationFunction:checkSandboxMode'],
      options: payPalEnvironments,
      secret: false
    },
    {
      name: 'sandboxClientId',
      type: 'text',
      showIf: ['enabled', 'environment=sandbox'],
      label: 'Sandbox Client ID',
      default: '',
      placeholder: '',
      validations: [
        'maxLength:200',
        'requiredIf:environment=sandbox'
      ],
      secret: false
    },
    {
      name: 'productionClientId',
      type: 'text',
      showIf: ['enabled', 'environment=production'],
      label: 'Production Client ID',
      default: '',
      placeholder: '',
      validations: [
        'maxLength:200',
        'requiredIf:environment=production'
      ],
      secret: false
    },
    {
      name: 'customTitle',
      type: 'text',
      showIf: ['enabled'],
      label: 'Custom title',
      default: 'PayPal',
      placeholder: '',
      validations: [
        'maxLength:50'
      ],
      secret: false
    },
    {
      name: 'customDescription',
      type: 'text',
      showIf: ['enabled'],
      label: 'Description',
      default: '',
      placeholder: '',
      validations: [
        'maxLength:100'
      ],
      secret: false
    }
  ],

  actions: []
}
