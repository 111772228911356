<template>
  <div class="custom-option">

    <h6 class="aside-field-label">{{ label }}</h6>

    <div class="custom-option__control align-end">
      <label
        v-for="(option, index) in options"
        :key="index"
        :class="[
          {'active': option.value === value},
          option.value
        ]"
        class="cover-layout"
      >
        <input
          :value="option.value"
          class="cover-layout__input"
          type="radio"
          name="theme"
          style="display:none;"
          @input="e => $emit('input', e.target.value)"
        />
        <div class="cover-layout__trigger">
          <span> {{options.icon}} </span>
          <template>
            <i class="cover-layout__icon"
              :class="option.icon"
            ></i>
          </template>
        </div>
      </label>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CustomOptionsPicker',
  props: {
    options: {
      type: Array
    },
    value: {
      type: String
    },
    label: {
      type: String
    }
  }
}
</script>
