const socialShareButtonsStyleOptions = [
  {
    label: 'Popup with share buttons',
    value: 'popup'
  },
  {
    label: 'Icon buttons',
    value: 'icon'
  },
  {
    label: 'Text buttons',
    value: 'text'
  }
]

const socialShareButtons = [
  {
    label: 'X',
    value: 'twitter'
  },
  {
    label: 'Facebook',
    value: 'facebook'
  },
  {
    label: 'WhatsApp',
    value: 'whatsapp'
  },
  {
    label: 'LinkedIn',
    value: 'linkedin'
  },
  {
    label: 'Reddit',
    value: 'reddit'
  },
  {
    label: 'Viber',
    value: 'viber'
  },
  {
    label: 'Pinterest',
    value: 'pinterest'
  },
  {
    label: 'Telegram',
    value: 'telegram'
  },
  {
    label: 'Messenger',
    value: 'messenger'
  },
  {
    label: 'E-mail',
    value: 'email'
  }
]

export { socialShareButtonsStyleOptions, socialShareButtons }
