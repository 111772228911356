<template>

  <div class="sorting-constructor__highlighted">
  <div class="sorting-constructor__item">
    <div class="sorting-constructor__select aside-select paddingless">
      <h6 class="sorting-constructor__item-label">Hide columns in public mode</h6>
      <v-select
        :value="privateCells.ids"
        :items="cols"
        item-text="label"
        item-value="id"
        attach
        menu-props="offsetY"
        chips
        deletable-chips
        multiple
        placeholder="Choose options"
        append-icon="keyboard_arrow_down"
        @change="setPrivateCells"
      >
      </v-select>

        <a class="sorting-constructor__link" target="_blank" href="https://help.spreadsimple.com/en/article/digital-downloads-1jrcqgw/">Help: How to use sensitive columns?</a>

    </div>
  </div>

  </div>

</template>
<script>
export default {
  name: 'PrivateColumnsConstructor',
  props: {
    cols: {
      type: Array,
      required: true
    },
    value: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      privateCells: {
        enabled: false,
        ids: []
      }
    }
  },
  methods: {
    setPrivateCells (val) {
      this.privateCells = val.length ? { enabled: true, ids: [...val] } : { enabled: false, ids: [] }
      this.$emit('input', this.privateCells)
    }
  },
  created () {
    this.privateCells = JSON.parse(JSON.stringify(this.value))
  }
}
</script>
