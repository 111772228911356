import { getQueryObject } from './utils'

const mustachesPattern = /{{\s*([^}]*)\s*}}/g

function isEmpty (value) {
  return value === null || typeof value === 'undefined'
}

export function getOrderTags (order, { only = [] } = {}) {
  if (!order.shippingMethod) {
    order.shippingMethod = {}
  }
  const tags = [
    { tag: 'client.name', literal: true, value: order.client.name },
    { tag: 'client.phone', literal: true, value: order.client.phone },
    { tag: 'client.email', literal: true, value: order.client.email },
    { tag: 'orderId', literal: true, value: order.orderId },
    { tag: 'totalAmount', literal: true, value: order.totalAmount },
    { tag: 'totalAmountFormatted', literal: true, value: order.totalAmountFormatted },
    { tag: 'client.message', literal: true, value: order.client.message },
    { tag: 'cartItemsText', literal: true, value: order.cartItemsText },

    { tag: 'client', literal: false, value: order.client },
    { tag: 'clientDetailsText', literal: true, value: order.clientDetailsText },
    { tag: 'cartItems', literal: false, value: order.cartItems },

    { tag: 'formFields', literal: false, value: order.formFields },
    {
      tag: /formFields.(\w+)/,
      literal: true,
      value (tag, defaultValue = '') {
        const extracted = /formFields.([^}]*)/g.exec(tag)
        if (!extracted || !extracted[1]) return defaultValue
        const paramName = extracted[1]

        const value = order.formFields[paramName] || defaultValue

        return Array.isArray(value) ? value.join(', ') : value
      }
    },
    { tag: 'shippingMethod', literal: false, value: order.shippingMethod },
    { tag: 'shippingMethod.label', literal: true, value: order.shippingMethod.label },
    { tag: 'shippingMethod.minOrder', literal: true, value: order.shippingMethod.minOrder },
    { tag: 'shippingMethod.value', literal: true, value: order.shippingMethod.value },
    { tag: 'taxItems', literal: false, value: order.taxItems },
    { tag: 'paymentMethod', literal: true, value: order.paymentMethod },
    { tag: 'taxItemsText', literal: true, value: order.taxItemsText },
    { tag: 'tipItemText', literal: true, value: order.tipItemText },
    { tag: 'shippingMethodText', literal: true, value: order.shippingMethodText }
  ]

  if (only.length > 0) {
    return tags.filter(t => only.includes(t.tag))
  }
  return tags
}

export function getUrlTags (location = window.location) {
  const query = getQueryObject(location.search)
  return [
    { tag: 'url.href', literal: true, value: location.href },
    { tag: 'url.path', literal: true, value: location.pathname },
    { tag: 'url.host', literal: true, value: location.host },
    { tag: 'url.search', literal: true, value: location.search },
    { tag: 'url.query', literal: false, value: query },
    {
      tag: 'url',
      literal: false,
      value: {
        href: location.href,
        path: location.pathname,
        host: location.host,
        search: location.search,
        query: query
      }
    },
    {
      tag: /url.query.(\w+)/,
      literal: true,
      value (tag, defaultValue) {
        const extracted = /url.query.([^}]*)/g.exec(tag)
        if (!extracted || !extracted[1]) return defaultValue
        const paramName = extracted[1]
        return query[paramName] || defaultValue
      }
    }
  ]
}

export function getStoredUrlTags (serializedUrlTags = '') {
  const params = Array.from(new URLSearchParams(serializedUrlTags))
  return [
    {
      tag: /var_(\w+)/,
      literal: true,
      value (tag, defaultValue) {
        const extracted = /([^}]*)/g.exec(tag)
        if (!extracted || !extracted[1]) return defaultValue
        const paramName = extracted[1]
        const param = params.find(([name]) => name === paramName)
        return param ? param[1] : defaultValue
      }
    },
    {
      tag: /utm_(\w+)/,
      literal: true,
      value (tag, defaultValue) {
        const extracted = /([^}]*)/g.exec(tag)
        if (!extracted || !extracted[1]) return defaultValue
        const paramName = extracted[1]
        const param = params.find(([name]) => name === paramName)
        return param ? param[1] : defaultValue
      }
    }
  ]
}

export function getEmailFormTags (form) {
  return [
    { tag: 'firstName', literal: true, value: form.firstName },
    { tag: 'lastName', literal: true, value: form.lastName },
    { tag: 'email', literal: true, value: form.email }
  ]
}

export function getTagValue (tagBody = '', tags = '') {
  const [tagPart, defaultPart] = tagBody.split(/\|(.+)/)
  const tag = tagPart.trim()
  const defaultValue = defaultPart && typeof defaultPart === 'string' ? defaultPart.trim() : ''
  const foundTag = tags.find(t => {
    if (t.tag instanceof RegExp) return t.tag.test(tag)
    return t.tag === tag
  })
  if (!foundTag || isEmpty(foundTag.value)) return defaultValue
  const tagValue = typeof foundTag.value === 'function' ? foundTag.value(tag, defaultValue) : foundTag.value
  return isEmpty(tagValue) ? defaultValue : tagValue
}

export function replaceTemplateTags (template = '', tags = [], { onlyLiterals = true } = {}) {
  if (onlyLiterals) {
    const literalTags = tags.filter(t => t.literal)
    return template.replace(mustachesPattern, (matched, tagBody) => {
      return getTagValue(tagBody, literalTags)
    })
  } else {
    // if non-literals allowed, replace only first found literal
    let nonLiteralFound = null
    const replacedString = template.replace(mustachesPattern, (matched, tagBody) => {
      if (nonLiteralFound) return ''
      const tag = tags.find(t => t.tag === tagBody)
      if (tag && tag.literal) {
        return getTagValue(tagBody, tags)
      } else {
        nonLiteralFound = getTagValue(tagBody, tags)
      }
    })

    return nonLiteralFound || replacedString
  }
}
