export default {
  en: {
    label: 'English',
    isRtl: false,

    searchPlaceholder: 'What are you looking for?',
    resetAll: 'Reset all',
    defaultOrder: 'Default order',
    madeWithSpreadSimple: 'Made with SpreadSimple',
    buyButton: 'Buy',
    yourCart: 'Your cart',
    cartTotalPrice: 'Total price',
    cartIsEmpty: 'Cart is empty...',
    checkoutName: 'Name',
    checkoutNameValidation: 'Please enter your name',
    checkoutPhone: 'Phone number',
    checkoutPhoneValidation: 'Please enter your phone number',
    checkoutEmail: 'E-mail',
    checkoutEmailValidation: 'Please enter a valid email address',
    checkoutNotes: 'Notes',
    checkoutSubmit: 'Checkout',
    checkoutThanksTitle: 'Thank you!',
    checkoutThanksText: 'We received your order. We will contact you soon.',
    checkoutContinueShopping: 'Continue shopping',
    email: 'E-mail',
    firstName: 'First name',
    lastName: 'Last name',
    subtotal: 'Subtotal',
    clientData: 'Client data',
    paymentMethods: 'Payment methods',
    shipping: 'Shipping',
    taxesAndFees: 'Taxes & fees',
    required: 'Required',
    formIsInvalid: 'Form is invalid',
    promocode: 'Promo code',
    discount: 'Discount',
    promocodeNotFound: 'Promo code not found or expired',
    apply: 'Apply',
    nothingFound: 'Ooops! Nothing found...',
    pageNotExists: 'This page doesn`t exist any more',
    goBack: 'Go back',
    mapView: 'Map view',
    listView: 'List view',
    thankYouForSubscription: 'Thank you for subscribing',
    reachedMaxQuantity: 'You reached the max quantity allowed for this item',
    reachedMaxItemsAmount: 'You cannot add more than one item',
    reset: 'Reset'
  },

  es: {
    label: 'Español',
    isRtl: false,

    searchPlaceholder: 'Buscar...',
    resetAll: 'Resetear',
    defaultOrder: 'Orden por defecto',
    madeWithSpreadSimple: 'Realizado con SpreadSimple',
    buyButton: 'Comprar',
    yourCart: 'Su cesta',
    cartTotalPrice: 'Precio total',
    cartIsEmpty: 'Su cesta está vacía',
    checkoutName: 'Nombre',
    checkoutNameValidation: 'Por favor, introducir su nombre',
    checkoutPhone: 'Número de teléfono',
    checkoutPhoneValidation: 'Por favor, introduzca su número de teléfono',
    checkoutEmail: 'E-mail',
    checkoutEmailValidation: 'Por favor, introduzca una dirección de e-mail válida',
    checkoutNotes: 'Comentario sobre el pedido',
    checkoutSubmit: 'Tramitar pedido',
    checkoutThanksTitle: '¡Gracias! ',
    checkoutThanksText: 'Hemos recibido su pedido. Nos pondremos en contacto con usted muy pronto.',
    checkoutContinueShopping: 'Seguir comprando ',
    email: 'E-mail',
    firstName: 'Nombre de pila',
    lastName: 'Apellido',
    subtotal: 'Total parcial',
    clientData: 'Información del cliente',
    paymentMethods: 'Métodos de pago',
    shipping: 'Transporte',
    taxesAndFees: 'Impuestos y pagos',
    required: 'Obligatorio',
    formIsInvalid: 'El formulario no es válido',
    discount: 'Descuento',
    promocode: 'Codigo promocional',
    promocodeNotFound: 'Código promocional no encontrado o caducado',
    apply: 'Aplicar',
    nothingFound: '¡Uy! Nada Encontrado ...',
    pageNotExists: 'Esta pagina ya no existe',
    goBack: 'Regresar',
    mapView: 'El mapa',
    listView: 'El listado',
    thankYouForSubscription: 'Gracias por suscribirse',
    reachedMaxQuantity: 'Has alcanzado la cantidad máxima permitida para este artículo',
    reachedMaxItemsAmount: 'No puedes agregar más de un artículo',
    reset: 'Resetear'
  },

  fr: {
    label: 'Français',
    isRtl: false,

    searchPlaceholder: 'Recherche',
    resetAll: 'Tout Réinitialiser',
    defaultOrder: 'Ordre par défaut',
    madeWithSpreadSimple: 'Fait avec SpreadSimple',
    buyButton: 'Acheter',
    yourCart: 'Votre panier',
    cartTotalPrice: 'Prix total',
    cartIsEmpty: 'Votre panier est vide',
    checkoutName: 'Nom',
    checkoutNameValidation: 'Veuillez entrer votre nom',
    checkoutPhone: 'Numéro de téléphone',
    checkoutPhoneValidation: 'Veuillez entrer votre numéro de téléphone',
    checkoutEmail: 'E-mail',
    checkoutEmailValidation: 'Veuillez entrer une adresse e-mail valide',
    checkoutNotes: 'Notes',
    checkoutSubmit: 'Passer la commande',
    checkoutThanksTitle: 'Merci',
    checkoutThanksText: 'Nous avons bien reçu votre commande. Nous vous contacterons bientôt.',
    checkoutContinueShopping: 'Continuer mes achats',
    email: 'E-mail',
    firstName: 'Prénom',
    lastName: 'Nom de famille',
    subtotal: 'Sous-total',
    clientData: 'Données clients',
    paymentMethods: 'Méthodes de paiement',
    shipping: 'Expédition',
    taxesAndFees: 'Taxes et frais',
    required: 'Obligatoire',
    formIsInvalid: 'Le formulaire n`est pas valide',
    discount: 'Rabais',
    promocode: 'Code promo',
    promocodeNotFound: 'Code promotionnel introuvable ou expiré',
    apply: 'Appliquer',
    nothingFound: "Oups! Rien n'a été trouvé...",
    pageNotExists: "Cette page n'existe plus",
    goBack: 'Retourner',
    mapView: 'Carte',
    listView: 'Catalogue',
    thankYouForSubscription: 'Merci de vous être abonné',
    reachedMaxQuantity: 'Vous avez atteint la quantité maximale autorisée pour cet article',
    reachedMaxItemsAmount: 'Vous ne pouvez pas ajouter plus d un élément',
    reset: 'Réinitialiser'
  },

  ru: {
    label: 'Русский',
    isRtl: false,

    searchPlaceholder: 'Поиск...',
    resetAll: 'Сбросить',
    defaultOrder: 'По умолчанию',
    madeWithSpreadSimple: 'Работает на SpreadSimple',
    buyButton: 'Купить',
    yourCart: 'Корзина',
    cartTotalPrice: 'Итого',
    cartIsEmpty: 'Корзина пуста...',
    checkoutName: 'Имя',
    checkoutNameValidation: 'Пожалуйста, укажите имя',
    checkoutPhone: 'Телефон',
    checkoutPhoneValidation: 'Пожалуйста, укажите телефон',
    checkoutEmail: 'E-mail',
    checkoutEmailValidation: 'Пожалуйста, укажите email',
    checkoutNotes: 'Комментарий к заказу',
    checkoutSubmit: 'Оформить заказ',
    checkoutThanksTitle: 'Спасибо!',
    checkoutThanksText: 'Мы получили ваш заказ! Мы с вами свяжемся.',
    checkoutContinueShopping: 'Продолжить покупки',
    email: 'E-mail',
    firstName: 'Имя',
    lastName: 'Фамилия',
    subtotal: 'Стоимость всего',
    clientData: 'Информация о клиенте',
    paymentMethods: 'Способы оплаты',
    shipping: 'Доставка',
    taxesAndFees: 'Налоги и сборы',
    required: 'Необходимо выбрать',
    formIsInvalid: 'Форма не заполнена',
    discount: 'Скидка',
    promocode: 'Промокод',
    promocodeNotFound: 'Промокод не найден или просрочен',
    apply: 'Применить',
    nothingFound: 'Ой! Ничего не найдено...',
    pageNotExists: 'Эта страница больше не существует',
    goBack: 'Вернуться',
    mapView: 'Карта',
    listView: 'Список',
    thankYouForSubscription: 'Спасибо, что подписались!',
    reachedMaxQuantity: 'Достигнуто максимальное количество, разрешенное для этого товара',
    reachedMaxItemsAmount: 'Вы не можете добавить более одного товара',
    reset: 'Сбросить'
  },

  ua: {
    label: 'Українська',
    isRtl: false,

    searchPlaceholder: 'Пошук...',
    resetAll: 'Скинути налаштування',
    defaultOrder: 'Типовий порядок',
    madeWithSpreadSimple: 'Зроблено у SpreadSimple',
    buyButton: 'Купити',
    yourCart: 'Ваш кошик',
    cartTotalPrice: 'Усього',
    cartIsEmpty: 'Кошик порожній',
    checkoutName: 'Ім`я',
    checkoutNameValidation: 'Введіть Ваше ім`я',
    checkoutPhone: 'Телефон',
    checkoutPhoneValidation: 'Введіть Ваш номер телефону',
    checkoutEmail: 'Електронна пошта',
    checkoutEmailValidation: 'Будь ласка, введіть існуючу адресу електронної пошти',
    checkoutNotes: 'Нотатки',
    checkoutSubmit: 'Оформити замовлення',
    checkoutThanksTitle: 'Дякуємо!',
    checkoutThanksText: 'Ми отримали Ваше замовлення і зв`яжемося з Вами незабаром.',
    checkoutContinueShopping: 'Повернутися до покупок',
    email: 'Електронна пошта',
    firstName: 'Ім`я',
    lastName: 'Прізвище',
    subtotal: 'Вартість усього',
    clientData: 'Інформація про клієнта',
    paymentMethods: 'Cпосіб оплати',
    shipping: 'Доставка',
    taxesAndFees: 'Податки та збори',
    required: 'Обов’язкове',
    formIsInvalid: 'Форма недійсна',
    discount: 'Знижка',
    promocode: 'Промокод',
    promocodeNotFound: 'Промо-код не знайдено або він недійсний',
    apply: 'Застосувати',
    nothingFound: 'Oй! Нічого не знайдено...',
    pageNotExists: 'Ця сторінка більше не існує',
    goBack: 'Повернутися',
    mapView: 'Kарта',
    listView: 'Список',
    thankYouForSubscription: 'Дякуємо за підписку',
    reachedMaxQuantity: 'Ви досягли максимальної дозволеної кількості',
    reachedMaxItemsAmount: 'Ви не можете додати більше одного елемента',
    reset: 'Скинути'
  },

  de: {
    label: 'Deutsch',
    isRtl: false,

    searchPlaceholder: 'Suchen...',
    resetAll: 'Alles zurücksetzen',
    defaultOrder: 'Standardreihenfolge',
    madeWithSpreadSimple: 'Gemachten mit SpreadSimple',
    buyButton: 'Kaufen',
    yourCart: 'Ihr Einkaufswagen',
    cartTotalPrice: 'Gesamtpreis',
    cartIsEmpty: 'Ihr Einkaufswagen ist leer...',
    checkoutName: 'Name',
    checkoutNameValidation: 'Tragen Sie bitte Ihren Namen',
    checkoutPhone: 'Telefonnummer',
    checkoutPhoneValidation: 'Tragen Sie bitte Ihren Telefonnummer',
    checkoutEmail: 'E-Mail',
    checkoutEmailValidation: 'Tragen Sie bitte Ihren gültige E-Mail Adresse',
    checkoutNotes: 'Notizen',
    checkoutSubmit: 'Bestellen',
    checkoutThanksTitle: 'Danke!',
    checkoutThanksText: 'Wir haben Ihre Bestellung erhalten. Wir werden Sie so bald wie möglich kontaktieren!',
    checkoutContinueShopping: 'Weiter einkaufen',
    email: 'E-mail',
    firstName: 'Vorname',
    lastName: 'Nachname',
    subtotal: 'Zwischensumme',
    clientData: 'Kundendaten',
    paymentMethods: 'Zahlungsmethoden',
    shipping: 'Versand',
    taxesAndFees: 'Steuern und Gebühren',
    required: 'Erforderlich',
    formIsInvalid: 'Das Formular ist ungültig',
    discount: 'Rabatt',
    promocode: 'Promo code',
    promocodeNotFound: 'Promo code nicht gefunden oder abgelaufen',
    apply: 'Anwenden',
    nothingFound: 'Ups! Nichts gefunden',
    pageNotExists: 'Diese Seite existiert nicht mehr',
    goBack: 'zurückkehren',
    mapView: 'Kartenansicht',
    listView: 'Listenansicht',
    thankYouForSubscription: 'Danke fürs Abonnieren',
    reachedMaxQuantity: 'Sie haben die maximal zulässige Menge für diesen Artikel erreicht',
    reachedMaxItemsAmount: 'Sie können nicht mehr als ein Element hinzufügen',
    reset: 'Zurücksetzen'
  },

  brpt: {
    label: 'Português',
    isRtl: false,

    searchPlaceholder: 'O que você está procurando?',
    resetAll: 'Limpar tudo',
    defaultOrder: 'Ordem padrão',
    madeWithSpreadSimple: 'Feito com SpreadSimple',
    buyButton: 'Comprar',
    yourCart: 'Carrinho',
    cartTotalPrice: 'Total',
    cartIsEmpty: 'Seu carrinho está vazio',
    checkoutName: 'Nome',
    checkoutNameValidation: 'Por favor, entre com seu nome',
    checkoutPhone: 'Número de telefone',
    checkoutPhoneValidation: 'Por favor, entre seu número de telefone',
    checkoutEmail: 'E-mail',
    checkoutEmailValidation: 'Por favor, entre com um endereço de email válido',
    checkoutNotes: 'Anotações',
    checkoutSubmit: 'Fechar pedido',
    checkoutThanksTitle: 'Obrigado!',
    checkoutThanksText: 'Recebemos o seu pedido. Entraremos em contato em breve. ',
    checkoutContinueShopping: 'Continue comprando',
    email: 'E-mail',
    firstName: 'Primeiro nome',
    lastName: 'Último nome',
    subtotal: 'Subtotal',
    clientData: 'Dados do cliente',
    paymentMethods: 'Métodos de Pagamento',
    shipping: 'Envio',
    taxesAndFees: 'Impostos e taxas',
    required: 'Obrigatório',
    formIsInvalid: 'Formulário é inválido',
    discount: 'Desconto',
    promocode: 'Código promocional',
    promocodeNotFound: 'Código promocional não encontrado ou expirado',
    apply: 'Aplicar',
    nothingFound: 'Ops! Nada encontrado...',
    pageNotExists: 'Esta página não existe mais',
    goBack: 'Voltar',
    mapView: 'Mapa',
    listView: 'Catálogo',
    thankYouForSubscription: 'Obrigado por se inscrever',
    reachedMaxQuantity: 'Você atingiu a quantidade máxima permitida para este item',
    reachedMaxItemsAmount: 'Você não pode adicionar mais de um item',
    reset: 'Limpar'
  },

  it: {
    label: 'Italiano',
    isRtl: false,

    searchPlaceholder: 'Ricerca...',
    resetAll: 'Resetta tutto',
    defaultOrder: 'Ordinamento di default',
    madeWithSpreadSimple: 'Creato con SpreadSimple',
    buyButton: 'Acquista',
    yourCart: 'Carrello ',
    cartTotalPrice: 'Prezzo totale',
    cartIsEmpty: 'Il tuo carrello è vuoto',
    checkoutName: 'Nome',
    checkoutNameValidation: 'Inserisci il tuo cognome',
    checkoutPhone: 'Numero di telefono',
    checkoutPhoneValidation: 'Inserisci il tuo numero di telefono',
    checkoutEmail: 'E-mail',
    checkoutEmailValidation: 'Inserisci un indirizzo e-mail valido',
    checkoutNotes: 'Indicazioni ',
    checkoutSubmit: 'Procedi con l\'ordine',
    checkoutThanksTitle: 'Grazie!',
    checkoutThanksText: 'Abbiamo ricevuto il tuo ordine. Ti contatteremo al più presto.',
    checkoutContinueShopping: 'Сontinua acquisti',
    email: 'E-mail',
    firstName: 'Nome',
    lastName: 'Cognome',
    subtotal: 'Totale parziale',
    clientData: 'Dati del cliente',
    paymentMethods: 'Modalità di pagamento',
    shipping: 'Spedizione',
    taxesAndFees: 'Tasse e commissioni',
    required: 'Richiesto',
    formIsInvalid: 'Il modulo non è valido',
    discount: 'Sconto',
    promocode: 'Codice promozionale',
    promocodeNotFound: 'Codice promozionale non trovato o scaduto',
    apply: 'Applicare',
    nothingFound: 'Ops! Non abbiamo trovato nulla...',
    pageNotExists: 'Questa pagina non esiste più',
    goBack: 'Tornare',
    mapView: 'La carta',
    listView: 'Il catalogo',
    thankYouForSubscription: 'Grazie per esserti iscritto',
    reachedMaxQuantity: 'Hai raggiunto la quantità massima consentita per questo articolo',
    reachedMaxItemsAmount: 'Non puoi aggiungere più di un articolo',
    reset: 'Resetta'
  },

  se: {
    label: 'Svenska',
    isRtl: false,

    searchPlaceholder: 'Vad letar du efter?',
    resetAll: 'Återställ allt',
    defaultOrder: 'Relevans',
    madeWithSpreadSimple: 'Gjord med SpreadSimple',
    buyButton: 'Köpa',
    yourCart: 'Din kundvagn ',
    cartTotalPrice: 'Totalbelopp',
    cartIsEmpty: 'Din kundvagn är tom',
    checkoutName: 'Namn',
    checkoutNameValidation: 'Vänligen fyll i ditt namn',
    checkoutPhone: 'Telefonnummer',
    checkoutPhoneValidation: 'Vänligen skriv in ditt telefonnummer',
    checkoutEmail: 'E-postadress',
    checkoutEmailValidation: 'Ange en giltig e-postadress',
    checkoutNotes: 'Anteckningar ',
    checkoutSubmit: 'Till Kassa',
    checkoutThanksTitle: 'Tack!',
    checkoutThanksText: 'Vi fick din beställning. Vi kontaktar dig snart.',
    checkoutContinueShopping: 'Fortsätt handla',
    email: 'E-post',
    firstName: 'Förnamn',
    lastName: 'Efternamn',
    subtotal: 'Delsumma',
    clientData: 'Kunddata',
    paymentMethods: 'Betalningsmetoder',
    shipping: 'Frakt',
    taxesAndFees: 'Skatter och avgifter',
    required: 'Detta krävs',
    formIsInvalid: 'Formuläret är ogiltigt',
    discount: 'Rabatt',
    promocode: 'Rabattkod',
    promocodeNotFound: 'Rabattkoden hittades inte eller har löpt ut',
    apply: 'Använd',
    nothingFound: 'Oj! Inget hittat...',
    pageNotExists: 'Den här sidan finns inte längre',
    goBack: 'Gå tillbaka',
    mapView: 'Kartvy',
    listView: 'Listvy',
    thankYouForSubscription: 'Tack för att du prenumererar',
    reachedMaxQuantity: 'Du har nått den högsta tillåtna kvantiteten för denna artikel',
    reachedMaxItemsAmount: 'Du kan inte lägga till mer än ett objekt',
    reset: 'Återställ'
  },

  he: {
    label: 'עִבְרִית',
    isRtl: true,

    searchPlaceholder: 'מה אתה מחפש?',
    resetAll: 'לאפס הכל',
    defaultOrder: 'ברירת מחדל לסדר',
    madeWithSpreadSimple: 'מיוצר בעזרת SpreadSimple',
    buyButton: 'לִקְנוֹת',
    yourCart: 'העגלה שלך',
    cartTotalPrice: 'מחיר סופי',
    cartIsEmpty: 'עגלת הקניות ריקה',
    checkoutName: 'שֵׁם',
    checkoutNameValidation: 'אנא הכנס את שמך',
    checkoutPhone: 'מספר טלפון',
    checkoutPhoneValidation: 'אנא הזן את מספר הטלפון שלך',
    checkoutEmail: 'אימייל',
    checkoutEmailValidation: 'אנא הכנס כתובת דואר אלקטרוני נכונה',
    checkoutNotes: 'הערות',
    checkoutSubmit: 'לבדוק',
    checkoutThanksTitle: 'תודה!',
    checkoutThanksText: 'קיבלנו את ההזמנה שלך. אנו ניצור איתך קשר בקרוב.',
    checkoutContinueShopping: 'המשיכו בקניות',
    email: 'אימייל',
    firstName: 'שם פרטי',
    lastName: 'שם משפחה',
    subtotal: 'סה"כ',
    clientData: 'נתוני לקוח',
    paymentMethods: 'שיטות תשלום',
    shipping: 'משלוח',
    taxesAndFees: 'מסים ועמלות',
    required: 'זה נדרש',
    formIsInvalid: 'טופס לא חוקי',
    discount: 'הנחה',
    promocode: 'קוד קופון',
    promocodeNotFound: 'קוד ההטבה לא נמצא או שפג תוקפו',
    apply: 'לְהַפְעִיל',
    nothingFound: 'אופס! שום דבר לא נמצא...',
    pageNotExists: 'הדף הזה כבר לא קיים',
    goBack: 'לַחֲזוֹר',
    mapView: 'תצוגת מפה',
    listView: 'תצוגת רשימה',
    thankYouForSubscription: 'תודה שנרשמת',
    reachedMaxQuantity: 'הגעת לכמות המקסימלית המותרת עבור פריט זה',
    reachedMaxItemsAmount: 'לא ניתן להוסיף יותר מפריט אחד',
    reset: 'אִתחוּל'
  },

  ar: {
    label: 'العربية',
    isRtl: true,

    searchPlaceholder: 'عما تبحث؟',
    resetAll: 'إعادة ضبط الكل',
    defaultOrder: 'ترتيب افتراضي',
    madeWithSpreadSimple: 'مصنوعة من SpreadSimple',
    buyButton: 'شراء',
    yourCart: 'سلتك',
    cartTotalPrice: 'السعر الكلي',
    cartIsEmpty: 'سلتك فارغة',
    checkoutName: 'الاسم',
    checkoutNameValidation: 'يرجى إدخال اسمك',
    checkoutPhone: 'رقم الهاتف',
    checkoutPhoneValidation: 'يرجى إدخال رقم الهاتف الخاص بك',
    checkoutEmail: 'البريد الإلكتروني',
    checkoutEmailValidation: 'من فضلك أدخل بريد أليكترونى صحيح',
    checkoutNotes: 'ملاحظات',
    checkoutSubmit: 'الدفع',
    checkoutThanksTitle: 'شكرا جزيلا!',
    checkoutThanksText: 'لقد تلقينا طلبك. سوف نتصل بك قريبا.',
    checkoutContinueShopping: 'مواصلة التسوق',
    email: 'بريد إلكتروني',
    firstName: 'الاسم الأول',
    lastName: 'اسم العائلة',
    subtotal: 'المجموع الفرعي',
    clientData: 'بيانات العميل',
    paymentMethods: 'طرق الدفع',
    shipping: 'شحن',
    taxesAndFees: 'الضرائب والرسوم',
    required: 'هذا مطلوب',
    formIsInvalid: 'النموذج غير صالح',
    discount: 'خصم',
    promocode: 'رمز ترويجي',
    promocodeNotFound: 'الرمز الترويجي غير موجود أو منتهي الصلاحية',
    apply: 'تطبيق',
    nothingFound: 'أُووبس! لم يتم العثور على شيء ...',
    pageNotExists: 'هذه الصفحة لم تعد موجودة',
    goBack: 'رجع',
    mapView: 'عرض الخريطة',
    listView: 'عرض القائمة',
    thankYouForSubscription: 'شكرا لك على الاشتراك',
    reachedMaxQuantity: 'لقد وصلت إلى الحد الأقصى للكمية المسموح بها لهذا العنصر',
    reachedMaxItemsAmount: 'لا يمكنك إضافة أكثر من عنصر واحد',
    reset: 'أعاد التشغيل'
  },

  jp: {
    label: '日本語',
    isRtl: false,

    searchPlaceholder: '何をお探しでしょうか？',
    resetAll: 'すべてリセット',
    defaultOrder: 'デフォルトの順序',
    madeWithSpreadSimple: 'SpreadSimpleで作成',
    buyButton: '購入',
    yourCart: 'カート',
    cartTotalPrice: '合計金額',
    cartIsEmpty: 'カートは空です...',
    checkoutName: '名前',
    checkoutNameValidation: '名前を入力してください',
    checkoutPhone: '電話番号',
    checkoutPhoneValidation: '電話番号を入力してください',
    checkoutEmail: 'Eメール',
    checkoutEmailValidation: '有効なメールアドレスを入力してください',
    checkoutNotes: 'ノート',
    checkoutSubmit: 'チェックアウト',
    checkoutThanksTitle: 'ありがとうございました！',
    checkoutThanksText: 'ご注文を承りました。すぐにご連絡いたします。',
    checkoutContinueShopping: 'ショッピングを続ける',
    email: 'Eメール',
    firstName: '名前',
    lastName: '苗字',
    subtotal: '中量',
    clientData: 'クライアントデータ',
    paymentMethods: 'お支払い方法',
    shipping: '運送',
    taxesAndFees: '税金と手数料',
    required: '必須です',
    formIsInvalid: 'フォームが無効です',
    discount: '割引',
    promocode: 'プロモーションコード',
    promocodeNotFound: 'プロモーションコードが見つからないか、有効期限が切れています',
    apply: 'を適用する',
    nothingFound: 'おっと！何も見つかりません',
    pageNotExists: 'このページはもう存在しません',
    goBack: '立ち返る',
    mapView: '地図表示',
    listView: 'リストビュー',
    thankYouForSubscription: '登録ありがとうございます',
    reachedMaxQuantity: 'このアイテムに許可されている最大数量に達しました',
    reachedMaxItemsAmount: '複数の項目を追加することはできません',
    reset: 'リセット'
  },

  tr: {
    label: 'Türkçe',
    isRtl: false,

    searchPlaceholder: 'Nasıl bir şey arıyorsunuz?',
    resetAll: 'Hepsini sıfırla',
    defaultOrder: 'Varsayılan sıralama',
    madeWithSpreadSimple: 'SpreadSimple ile yapılmıştır',
    buyButton: 'Satın Al',
    yourCart: 'Sepetiniz',
    cartTotalPrice: 'Toplam fiyat',
    cartIsEmpty: 'Sepetiniz boş...',
    checkoutName: 'İsim',
    checkoutNameValidation: 'Adınızı giriniz',
    checkoutPhone: 'Telefon numarası',
    checkoutPhoneValidation: 'Telefon numaranızı giriniz',
    checkoutEmail: 'E-posta',
    checkoutEmailValidation: 'Geçerli bir e-posta giriniz',
    checkoutNotes: 'Notlar',
    checkoutSubmit: 'Ödeme',
    checkoutThanksTitle: 'Teşekkürler!',
    checkoutThanksText: 'Siparişinizi aldık. Yakında sizinle iletişime geçeceğiz.',
    checkoutContinueShopping: 'Alışverişe devam',
    email: 'E-posta',
    firstName: 'İsim',
    lastName: 'Soyadı',
    subtotal: 'Ara toplam',
    clientData: 'Müşteri bilgisi',
    paymentMethods: 'Ödeme metodları',
    shipping: 'Gönderi',
    taxesAndFees: 'Vergiler ve ücretler',
    required: 'Gerekli',
    formIsInvalid: 'Form geçersiz',
    discount: 'İndirim',
    promocode: 'Promosyon kodu',
    promocodeNotFound: 'Promosyon kodu bulunamadı veya süresi doldu',
    apply: 'Uygulamak',
    nothingFound: 'Hata! Hiçbirşey Bulunamadı...',
    pageNotExists: 'Bu sayfa artık mevcut değil',
    goBack: 'Geri dön',
    mapView: 'Harita',
    listView: 'Katalog',
    thankYouForSubscription: 'Abone olduğunuz için teşekkürler',
    reachedMaxQuantity: 'Bu ürün için izin verilen maksimum miktara ulaştınız',
    reachedMaxItemsAmount: 'Birden fazla öğe ekleyemezsiniz',
    reset: 'Sıfırla'
  },

  hi: {
    label: 'हिन्दी',
    isRtl: false,

    searchPlaceholder: 'आप क्या ढूंढ़ रहे हैं?',
    resetAll: 'सभी को रीसेट करें',
    defaultOrder: 'डिफ़ॉल्ट आदेश',
    madeWithSpreadSimple: 'SpreadSimple द्वारा बना हुआ',
    buyButton: 'ख़रीदे',
    yourCart: 'आपकी कार्ट',
    cartTotalPrice: 'कुल कीमत',
    cartIsEmpty: 'आपकी कार्ट खाली है',
    checkoutName: 'नाम',
    checkoutNameValidation: 'अपना नाम दर्ज करें',
    checkoutPhone: 'फ़ोन नंबर',
    checkoutPhoneValidation: 'कृपया अपना फोन नंबर दर्ज करें',
    checkoutEmail: 'ईमेल',
    checkoutEmailValidation: 'कृपया एक वैध ई - मेल एड्रेस डालें',
    checkoutNotes: 'अपने आदेश के बारे में नोट्स',
    checkoutSubmit: 'खरीदने के लिए आगे बढ़ें',
    checkoutThanksTitle: 'धन्यवाद',
    checkoutThanksText: 'हमें आपका आदेश मिला। हम आपसे शीघ्र ही संपर्क करेंगे।',
    checkoutContinueShopping: 'खरीदारी जारी रखें',
    email: 'ईमेल',
    firstName: 'पहला नाम',
    lastName: 'उपनाम',
    subtotal: 'लागत',
    clientData: 'ग्राहक डेटा',
    paymentMethods: 'भुगतान की विधि',
    shipping: 'शिपिंग',
    taxesAndFees: 'कर एवं शुल्क',
    required: 'यह आवश्यक है',
    formIsInvalid: 'फॉर्म अमान्य है',
    discount: 'छूट',
    promocode: 'प्रचार कोड',
    promocodeNotFound: 'प्रोमो कोड नहीं मिला या समाप्त हो गया',
    apply: 'लागू करना',
    nothingFound: 'ओह! कुछ भी नहीं मिला...',
    pageNotExists: 'यह पेज अब मौजूद नहीं है',
    goBack: 'वापस जाओ',
    mapView: 'नक्शा देखें',
    listView: 'लिस्ट व्यू',
    thankYouForSubscription: 'सदस्यता लेने के लिए धन्यवाद',
    reachedMaxQuantity: 'आप इस आइटम के लिए अनुमत अधिकतम मात्रा तक पहुंच गए हैं',
    reachedMaxItemsAmount: 'आप एक से अधिक आइटम नहीं जोड़ सकते',
    reset: 'रीसेट'
  },

  nl: {
    label: 'Nederlands',
    isRtl: false,

    searchPlaceholder: 'Waar ben je naar op zoek?',
    resetAll: 'Alles resetten',
    defaultOrder: 'Standaard volgorde',
    madeWithSpreadSimple: 'Gemaakt met SpreadSimple',
    buyButton: 'Kopen',
    yourCart: 'Jouw winkelwagen',
    cartTotalPrice: 'Totaalprijs',
    cartIsEmpty: 'Winkelwagen is leeg ...',
    checkoutName: 'Naam',
    checkoutNameValidation: 'Voer je naam in',
    checkoutPhone: 'Telefoonnummer',
    checkoutPhoneValidation: 'Voer je telefoonnummer in',
    checkoutEmail: 'E-mail',
    checkoutEmailValidation: 'Vul een geldig e-mailadres in',
    checkoutNotes: 'Jouw opmerkingen',
    checkoutSubmit: 'Afrekenen',
    checkoutThanksTitle: 'Bedankt!',
    checkoutThanksText: 'We hebben je bestelling ontvangen. We nemen spoedig contact met je op.',
    checkoutContinueShopping: 'Doorgaan met winkelen',
    email: 'E-mail',
    firstName: 'Voornaam',
    lastName: 'Achternaam',
    subtotal: 'Subtotaal',
    clientData: 'Klantgegevens',
    paymentMethods: 'Betalingsmethoden',
    shipping: 'Verzending',
    taxesAndFees: 'Belastingen en heffingen',
    required: 'Verplicht',
    formIsInvalid: 'Formulier is ongeldig',
    discount: 'Korting',
    promocode: 'Promotiecode',
    promocodeNotFound: 'Promotiecode niet gevonden of verlopen',
    apply: 'Toepassen',
    nothingFound: 'Oeps! Niets gevonden ...',
    pageNotExists: 'Deze pagina bestaat niet meer',
    goBack: 'Ga terug',
    mapView: 'Kaartweergave',
    listView: 'Lijstweergave',
    thankYouForSubscription: 'Bedankt voor je inschrijving',
    reachedMaxQuantity: 'U heeft het maximaal toegestane aantal voor dit artikel bereikt',
    reachedMaxItemsAmount: 'Je kunt niet meer dan één item toevoegen',
    reset: 'Resetten'
  },

  el: {
    label: 'Ελληνικά',
    isRtl: false,

    searchPlaceholder: 'Τι ψάχνετε;',
    resetAll: 'Επαναφορά όλων',
    defaultOrder: 'Προκαθορισμένη ταξινόμηση',
    madeWithSpreadSimple: 'Δημιουργήθηκε με το SpeadSimple',
    buyButton: 'Αγορά',
    yourCart: 'Το Καλάθι σας',
    cartTotalPrice: 'Σύνολο',
    cartIsEmpty: 'Το Καλάθι σας είναι άδειο',
    checkoutName: 'Όνομα',
    checkoutNameValidation: 'Παρακαλώ εισάγετε το όνομά σας',
    checkoutPhone: 'Τηλέφωνο',
    checkoutPhoneValidation: 'Παρακαλώ εισάγετε το τηλέφωνό σας',
    checkoutEmail: 'Ηλεκτρονική Διεύθυνση',
    checkoutEmailValidation: 'Παρακαλώ εισάγετε μια έγκυρη ηλεκτρονική διεύθυνση',
    checkoutNotes: 'Σημειώσεις',
    checkoutSubmit: 'Σελίδα Πληρωμής',
    checkoutThanksTitle: 'Ευχαριστούμε!',
    checkoutThanksText: 'Έχουμε λάβει την παραγγελία σας. Θα επικοινωνήσουμε σύντομα μαζί σας.',
    checkoutContinueShopping: 'Συνεχίστε τις αγορές',
    email: 'Ηλεκτρονική Διεύθυνση',
    firstName: 'Ονομα',
    lastName: 'Επίθετο',
    subtotal: 'Uποσυνολική τιμή',
    clientData: 'Δεδομένα πελατών',
    paymentMethods: 'Μέθοδοι πληρωμής',
    shipping: 'Αποστολή',
    taxesAndFees: 'Φόροι και τέλη',
    required: 'Αυτό απαιτείται',
    formIsInvalid: 'Η φόρμα δεν είναι έγκυρη',
    discount: 'Εκπτωση',
    promocode: 'Κωδικός προσφοράς',
    promocodeNotFound: 'Ο κωδικός προσφοράς δεν βρέθηκε ή έληξε',
    apply: 'Ισχύουν',
    nothingFound: 'Ωχ! Δεν βρέθηκε τίποτα...',
    pageNotExists: 'Αυτή η σελίδα δεν υπάρχει πια',
    goBack: 'Επιστρέψτε',
    mapView: 'Χάρτης',
    listView: 'Κατάλογος',
    thankYouForSubscription: 'Ευχαριστώ για την εγγραφή',
    reachedMaxQuantity: 'Φτάσατε τη μέγιστη επιτρεπόμενη ποσότητα για αυτό το στοιχείο',
    reachedMaxItemsAmount: 'Δεν μπορείτε να προσθέσετε περισσότερα από ένα στοιχεία',
    reset: 'Επαναφορά'
  },

  pl: {
    label: 'Polski',
    isRtl: false,

    searchPlaceholder: 'Czego szukasz?',
    resetAll: 'Wyczyść filtry',
    defaultOrder: 'Domyślne sortowanie',
    madeWithSpreadSimple: 'Wykonano z SpreadSimple',
    buyButton: 'Kup',
    yourCart: 'Twój koszyk',
    cartTotalPrice: 'Łączna cena',
    cartIsEmpty: 'Koszyk jest pusty...',
    checkoutName: 'Imię',
    checkoutNameValidation: 'Wpisz swoje imię',
    checkoutPhone: 'Telefon',
    checkoutPhoneValidation: 'Wpisz swój numer telefonu',
    checkoutEmail: 'E-mail',
    checkoutEmailValidation: 'Wpisz adres e-mail',
    checkoutNotes: 'Treść wiadomości',
    checkoutSubmit: 'Realizacja zamówienia',
    checkoutThanksTitle: 'Dziękujemy!',
    checkoutThanksText: 'Otrzymaliśmy Twoje zamówienie. Skontaktujemy się z Tobą wkrótce.',
    checkoutContinueShopping: 'Kontynuuj zakupy',
    email: 'E-mail',
    firstName: 'Imię',
    lastName: 'Nazwisko',
    subtotal: 'Suma częściowa',
    clientData: 'Dane klienta',
    paymentMethods: 'Metody Płatności',
    shipping: 'Wysyłka',
    taxesAndFees: 'Podatki i płatności',
    required: 'Wymagane',
    formIsInvalid: 'Formularz jest nieprawidłowy',
    discount: 'Zniżka',
    promocode: 'Kod promocyjny',
    promocodeNotFound: 'Nie znaleziono kodu promocyjnego lub wygasł',
    apply: 'Zastosować',
    nothingFound: 'Ups! Nic nie znaleziono...',
    pageNotExists: 'Ta strona już nie istnieje',
    goBack: 'Wracać',
    mapView: 'Mapa',
    listView: 'Katalogować',
    thankYouForSubscription: 'Dziękujemy za subskrypcję',
    reachedMaxQuantity: 'Osiągnąłeś maksymalną ilość dozwoloną dla tego przedmiotu',
    reachedMaxItemsAmount: 'Nie możesz dodać więcej niż jednego elementu',
    reset: 'Zresetuj'
  },

  ro: {
    label: 'Română',
    isRtl: false,

    searchPlaceholder: 'Ce cauţi?',
    resetAll: 'Resetează',
    defaultOrder: 'Ordonare implicită',
    madeWithSpreadSimple: 'Realizat cu SpreadSimple',
    buyButton: 'Cumpără',
    yourCart: 'Coșul tău',
    cartTotalPrice: 'Preț total',
    cartIsEmpty: 'Coșul este gol',
    checkoutName: 'Nume',
    checkoutNameValidation: 'Te rugăm să introduci numele ',
    checkoutPhone: 'Număr de telefon',
    checkoutPhoneValidation: 'Te rugăm să introduci numărul de telefon',
    checkoutEmail: 'E-mail',
    checkoutEmailValidation: 'Te rugăm să introduci o adresa de e-mail validă ',
    checkoutNotes: 'Notiţe',
    checkoutSubmit: 'Verifică',
    checkoutThanksTitle: 'Mulțumesc!',
    checkoutThanksText: 'Am primit comanda ta. Te vom contacta în curând.',
    checkoutContinueShopping: 'Continuă cumpărăturile',
    email: 'E-mail',
    firstName: 'Nume',
    lastName: 'Nume de familie',
    subtotal: 'Subtotal',
    clientData: 'Datele clientului',
    paymentMethods: 'Payment methods',
    shipping: 'Livrare',
    taxesAndFees: 'Taxe și plăți',
    required: 'Necesar',
    formIsInvalid: 'Formularul este invalid',
    discount: 'Reducere',
    promocode: 'Cod promoțional',
    promocodeNotFound: 'Cod promoțional nu a fost găsit sau a expirat',
    apply: 'Aplică',
    nothingFound: 'Hopa! Nimic gasit...',
    pageNotExists: 'Această pagină nu mai există',
    goBack: 'Reveniți',
    mapView: 'Hartă',
    listView: 'Catalog',
    thankYouForSubscription: 'Multumesc pentru abonare',
    reachedMaxQuantity: 'Ați atins cantitatea maximă permisă pentru acest articol',
    reachedMaxItemsAmount: 'Nu puteți adăuga mai mult de un articol',
    reset: 'Resetează'
  },

  sl: {
    label: 'Slovenščina',
    isRtl: false,

    searchPlaceholder: 'Kaj iščete?',
    resetAll: 'Ponastavi vse',
    defaultOrder: 'Privzeti vrstni red',
    madeWithSpreadSimple: 'Izdelano s SpreadSimple',
    buyButton: 'Kupi',
    yourCart: 'Košarica',
    cartTotalPrice: 'Skupaj',
    cartIsEmpty: 'Košarica je prazna ...',
    checkoutName: 'Ime',
    checkoutNameValidation: 'Vnesite svoje ime',
    checkoutPhone: 'Telefonska številka',
    checkoutPhoneValidation: 'Vnesite svojo telefonsko številko',
    checkoutEmail: 'E-naslov',
    checkoutEmailValidation: 'Vnesite veljaven e-naslov',
    checkoutNotes: 'Opombe',
    checkoutSubmit: 'Blagajna',
    checkoutThanksTitle: 'Hvala vam!',
    checkoutThanksText: 'Prejeli smo vaše naročilo. Kmalu vas bomo kontaktirali.',
    checkoutContinueShopping: 'Nadaljuj z nakupovanjem',
    email: 'E-naslov',
    firstName: 'Ime',
    lastName: 'Priimek',
    subtotal: 'Znesek',
    clientData: 'Podatki strank',
    paymentMethods: 'Načini plačila',
    shipping: 'Dostava',
    taxesAndFees: 'Davki in pristojbine',
    required: 'Potrebno',
    formIsInvalid: 'Obrazec ni veljaven',
    discount: 'Popust',
    promocode: 'Promocijska koda',
    promocodeNotFound: 'Promocijska koda ni najdena ali je potekla',
    apply: 'Uporabite',
    nothingFound: 'Ups! Nič ni bilo najdeno ...',
    pageNotExists: 'Ta stran ne obstaja več',
    goBack: 'Pojdi nazaj',
    mapView: 'Zemljevid',
    listView: 'Seznam',
    thankYouForSubscription: 'Hvala za naročanje!',
    reachedMaxQuantity: 'Dosegli ste največjo dovoljeno količino za ta izdelek',
    reachedMaxItemsAmount: 'Ne morete dodati več kot enega elementa',
    reset: 'Ponastaviti'
  },

  da: {
    label: 'Dansk',
    isRtl: false,

    searchPlaceholder: 'Hvad søger du efter?',
    resetAll: 'Nulstil filtre',
    defaultOrder: 'Sorter efter',
    madeWithSpreadSimple: 'Skabt med SpreadSimple',
    buyButton: 'Køb',
    yourCart: 'Din kurv',
    cartTotalPrice: 'Total pris',
    cartIsEmpty: 'Kurven er tom',
    checkoutName: 'Navn',
    checkoutNameValidation: 'Indtast navn',
    checkoutPhone: 'Telefonnummer',
    checkoutPhoneValidation: 'Indtast telefonnummer',
    checkoutEmail: 'E-mail',
    checkoutEmailValidation: 'Indtast en gyldig e-mail adresse',
    checkoutNotes: 'Dine noter',
    checkoutSubmit: 'Aflæg ordre',
    checkoutThanksTitle: 'Tak!',
    checkoutThanksText: 'Vi har modtaget din ordre. Vi vender tilbage til dig snarest muligt.',
    checkoutContinueShopping: 'Fortsæt indkøb',
    email: 'E-mail',
    firstName: 'Fornavn',
    lastName: 'Efternavn',
    subtotal: 'Subtotal',
    clientData: 'Kundedata',
    paymentMethods: 'Betalingsmetoder',
    shipping: 'Forsendelse',
    taxesAndFees: 'Skatter og gebyrer',
    required: 'Obligatorisk',
    formIsInvalid: 'Formularen er ugyldig',
    discount: 'Rabat',
    promocode: 'Tilbudskode',
    promocodeNotFound: 'Tilbudskoden blev ikke fundet eller udløbet',
    apply: 'Anvend',
    nothingFound: 'Ups! Intet fundet...',
    pageNotExists: 'Denne side eksisterer ikke længere',
    goBack: 'Gå tilbage',
    mapView: 'Kortvisning',
    listView: 'Listevisning',
    thankYouForSubscription: 'Tak fordi du abonnerer',
    reachedMaxQuantity: 'Du har nået det maksimalt tilladte antal for denne vare',
    reachedMaxItemsAmount: 'Du kan ikke tilføje mere end ét element',
    reset: 'Nulstil'
  },

  vi: {
    label: 'Việt',
    isRtl: false,

    searchPlaceholder: 'Bạn đang tìm kiếm gì?',
    resetAll: 'Xóa tất cả',
    defaultOrder: 'Thứ tự mặc định',
    madeWithSpreadSimple: 'Tạo với SpreadSimple',
    buyButton: 'Mua',
    yourCart: 'Giỏ hàng',
    cartTotalPrice: 'Tổng giá tiền',
    cartIsEmpty: 'Giỏ hàng trống...',
    checkoutName: 'Tên',
    checkoutNameValidation: 'Vui lòng nhập tên của bạn',
    checkoutPhone: 'Số điện thoại',
    checkoutPhoneValidation: 'Vui lòng nhập số điện thoại của bạn',
    checkoutEmail: 'E-mail',
    checkoutEmailValidation: 'Vui lòng nhập một địa chỉ email hợp lệ',
    checkoutNotes: 'Ghi chú',
    checkoutSubmit: 'Thanh toán',
    checkoutThanksTitle: 'Cám ơn!',
    checkoutThanksText: 'Chúng tôi đã nhận được đơn hàng của bạn. Chúng tôi sẽ liên hệ với bạn trong thời gian sớm nhất.',
    checkoutContinueShopping: 'Tiếp tục mua hàng',
    email: 'E-mail',
    firstName: 'Tên',
    lastName: 'Họ',
    subtotal: 'Tổng phụ',
    clientData: 'Dữ liệu khách hàng',
    paymentMethods: 'Phương thức thanh toán',
    shipping: 'Hàng hải',
    taxesAndFees: 'Thuế và phí',
    required: 'Điều này là bắt buộc',
    formIsInvalid: 'Biểu mẫu không hợp lệ',
    discount: 'Bớt giá',
    promocode: 'Mã khuyến mại',
    promocodeNotFound: 'Mã khuyến mại không được tìm thấy hoặc đã hết hạn',
    apply: 'Áp dụng',
    nothingFound: 'Ồ! Không tìm thấy thứ gì...',
    pageNotExists: 'Trang này không tồn tại nữa',
    goBack: 'Quay lại',
    mapView: 'Bản đồ',
    listView: 'Mục lục',
    thankYouForSubscription: 'Cảm ơn bạn đã đăng ký',
    reachedMaxQuantity: 'Bạn đã đạt đến số lượng tối đa cho phép đối với mặt hàng này',
    reachedMaxItemsAmount: 'Bạn không thể thêm nhiều hơn một mục',
    reset: 'Đặt lại'
  },

  th: {
    label: 'ภาษาไทย',
    isRtl: false,

    searchPlaceholder: 'ค้นหา',
    resetAll: 'ล้างข้อมูล',
    defaultOrder: 'ลำดับตามค่าเริ่มต้น',
    madeWithSpreadSimple: 'สร้างด้วย SpreadSimple',
    buyButton: 'สั่งซื้อ',
    yourCart: 'ตะกร้าสินค้า',
    cartTotalPrice: 'ยอดรวม',
    cartIsEmpty: 'ไม่มีสินค้าในตะกร้า',
    checkoutName: 'ชื่อ',
    checkoutNameValidation: 'กรุณาระบุชื่อ',
    checkoutPhone: 'หมายเลขโทรศัพท์',
    checkoutPhoneValidation: 'กรุุณาระบุหมายเลขโทรศัพท์',
    checkoutEmail: 'อีเมล',
    checkoutEmailValidation: 'กรุุณาระบุอีเมลที่ถูกต้อง',
    checkoutNotes: 'ข้อความ',
    checkoutSubmit: 'ชำระเงิน',
    checkoutThanksTitle: 'ขอบคุณ',
    checkoutThanksText: 'เราได้รับคำสั่งซื้อแล้วและจะทำการติดต่อกลับเร็วๆนี้',
    checkoutContinueShopping: 'เลือกสินค้าต่อ',
    email: 'อีเมล',
    firstName: 'ชื่อ',
    lastName: 'นามสกุล',
    subtotal: 'ราคารวมย่อย',
    clientData: 'ข้อมูลลูกค้า',
    paymentMethods: 'วิธีการชำระเงิน',
    shipping: 'การส่งสินค้า',
    taxesAndFees: 'ภาษีและค่าธรรมเนียม',
    required: 'สิ่งนี้จำเป็น',
    formIsInvalid: 'แบบฟอร์มไม่ถูกต้อง',
    discount: 'การลดราคา',
    promocode: 'รหัสโปรโมชั่น',
    promocodeNotFound: 'ไม่พบรหัสโปรโมชั่นหรือหมดอายุ',
    apply: 'ใช้รหัส',
    nothingFound: 'เอ่อ ไม่พบอะไรเลย...',
    pageNotExists: 'เพจนี้ไม่มีอยู่อีกต่อไป',
    goBack: 'กลับไป',
    mapView: 'มุมมองแผนที่',
    listView: 'มุมมองรายการ',
    thankYouForSubscription: 'ขอบคุณสำหรับการสมัคร',
    reachedMaxQuantity: 'คุณถึงจำนวนสูงสุดที่อนุญาตสำหรับรายการนี้แล้ว',
    reachedMaxItemsAmount: 'คุณไม่สามารถเพิ่มมากกว่าหนึ่งรายการ',
    reset: 'รีเซ็ต'
  },

  cs: {
    label: 'Čeština',
    isRtl: false,

    searchPlaceholder: 'Co hledáte?',
    resetAll: 'Obnovit vše',
    defaultOrder: 'Výchozí pořadí',
    madeWithSpreadSimple: 'Vytvořil SpreadSimple',
    buyButton: 'Koupit',
    yourCart: 'Váš košík',
    cartTotalPrice: 'Celková cena',
    cartIsEmpty: 'Košík je prázdný...',
    checkoutName: 'Jméno',
    checkoutNameValidation: 'Prosím, zadejte vaše jméno',
    checkoutPhone: 'Telefonní číslo',
    checkoutPhoneValidation: 'Prosím, zadejte vaše telefonní číslo',
    checkoutEmail: 'E-mail',
    checkoutEmailValidation: 'Prosím, zadejte platnou e-mailovou adresu',
    checkoutNotes: 'Poznámky',
    checkoutSubmit: 'K pokladně',
    checkoutThanksTitle: 'Děkujeme!',
    checkoutThanksText: 'Obdrželi jsme vaši objednávku. Brzy vás budeme kontaktovat.',
    checkoutContinueShopping: 'Pokračovat v nákupu',
    email: 'E-mail',
    firstName: 'Jméno',
    lastName: 'Příjmení',
    subtotal: 'Mezisoučet',
    clientData: 'Údaje o klientech',
    paymentMethods: 'Payment methods',
    shipping: 'Doprava',
    taxesAndFees: 'Daně a poplatky',
    required: 'Povinný',
    formIsInvalid: 'Formulář je neplatný',
    discount: 'Sleva',
    promocode: 'Promo kód',
    promocodeNotFound: 'Propagační kód nebyl nalezen nebo jeho platnost vypršela',
    apply: 'Použít',
    nothingFound: 'Jejda! Nic nalezeno',
    pageNotExists: 'Tato stránka již neexistuje',
    goBack: 'Vrátit se',
    mapView: 'Mapa',
    listView: 'Seznam',
    thankYouForSubscription: 'Děkujeme, že jste se přihlásili k odběru!',
    reachedMaxQuantity: 'Dosáhli jste maximálního povoleného množství pro tuto položku',
    reachedMaxItemsAmount: 'Nemůžete přidat více než jednu položku',
    reset: 'Zrušiť'
  },

  no: {
    label: 'Norsk',
    isRtl: false,

    searchPlaceholder: 'Hva ser du etter?',
    resetAll: 'Tilbakestill alt',
    defaultOrder: 'Standard rekkefølge',
    madeWithSpreadSimple: 'Laget med SpreadSimple',
    buyButton: 'Kjøp',
    yourCart: 'Handlekurven din',
    cartTotalPrice: 'Totalpris',
    cartIsEmpty: 'Handlekurven er tom ...',
    checkoutName: 'Navn',
    checkoutNameValidation: 'Vennligst skriv inn navnet ditt',
    checkoutPhone: 'Telefonnummer',
    checkoutPhoneValidation: 'Vennligst skriv inn telefonnummeret ditt',
    checkoutEmail: 'E-post',
    checkoutEmailValidation: 'Vennligst skriv inn en gyldig e-postadresse',
    checkoutNotes: 'Merknader',
    checkoutSubmit: 'Bestill',
    checkoutThanksTitle: 'Takk skal du ha!',
    checkoutThanksText: 'Vi mottok bestillingen din. Vi kontakter deg snart.',
    checkoutContinueShopping: 'Fortsette å handle',
    email: 'E-post',
    firstName: 'Fornavn',
    lastName: 'Etternavn',
    subtotal: 'Delsum',
    clientData: 'Kundedata',
    paymentMethods: 'Betalingsmetoder',
    shipping: 'Leveranse',
    taxesAndFees: 'Skatter og avgifter',
    required: 'Påkrevd',
    formIsInvalid: 'Skjemaet er ugyldig',
    discount: 'Rabatt',
    promocode: 'Kampanjekode',
    promocodeNotFound: 'Kampanjekoden ble ikke funnet eller utløpt',
    apply: 'Bruke',
    nothingFound: 'Oops! Ingenting funnet...',
    pageNotExists: 'Denne siden eksisterer ikke lenger',
    goBack: 'Gå tilbake',
    mapView: 'Kartvisning',
    listView: 'Listevisning',
    thankYouForSubscription: 'Takk for at du abonnerer',
    reachedMaxQuantity: 'Du har nådd det maksimale antallet som er tillatt for denne varen',
    reachedMaxItemsAmount: 'Du kan ikke legge til mer enn ett element',
    reset: 'Tilbakestill'
  },

  bg: {
    label: 'Български',
    isRtl: false,

    searchPlaceholder: 'Какво търсиш?',
    resetAll: 'Нулирайте всички',
    defaultOrder: 'Поръчка по подразбиране',
    madeWithSpreadSimple: 'Направено с SpreadSimple',
    buyButton: 'Купи',
    yourCart: 'Вашата количка',
    cartTotalPrice: 'Обща цена',
    cartIsEmpty: 'Количката е празна...',
    checkoutName: 'Име',
    checkoutNameValidation: 'Моля, въведете вашето име',
    checkoutPhone: 'Телефонен номер',
    checkoutPhoneValidation: 'Моля, въведете своя телефонен номер',
    checkoutEmail: 'Електронна поща',
    checkoutEmailValidation: 'Моля, въведете валиден имейл адрес',
    checkoutNotes: 'Бележки',
    checkoutSubmit: 'Поръчка',
    checkoutThanksTitle: 'Благодарим Ви!',
    checkoutThanksText: 'Получихме вашата поръчка. Ще се свържем с вас скоро.',
    checkoutContinueShopping: 'Продължете да пазарувате',
    email: 'Електронна поща',
    firstName: 'Първо име',
    lastName: 'Фамилия',
    subtotal: 'Цена',
    clientData: 'Клиентски данни',
    paymentMethods: 'Payment methods',
    shipping: 'Доставка',
    taxesAndFees: 'Данъци и такси',
    required: 'Това е задължително',
    formIsInvalid: 'Формулярът е невалиден',
    discount: 'Отстъпка',
    promocode: 'Промо код',
    promocodeNotFound: 'Промо код не е намерен или е изтекъл',
    apply: 'Приложи',
    nothingFound: 'Опа! Нищо не е намерено ...',
    pageNotExists: 'Тази страница вече не съществува',
    goBack: 'Върнете се',
    mapView: 'Карта',
    listView: 'Kаталог',
    thankYouForSubscription: 'Благодаря ви, че се абонирахте',
    reachedMaxQuantity: 'Достигнахте максимално разрешеното количество за този артикул',
    reachedMaxItemsAmount: 'Не можете да добавяте повече от един елемент',
    reset: 'Нулирайте'
  },

  id: {
    label: 'Bahasa Indonesia',
    isRtl: false,

    searchPlaceholder: 'Apa yang Anda cari?',
    resetAll: 'Setel ulang semua',
    defaultOrder: 'Urutkan secara default',
    madeWithSpreadSimple: 'Dibuat dengan SpreadSimple',
    buyButton: 'Beli',
    yourCart: 'Keranjang',
    cartTotalPrice: 'Total Harga',
    cartIsEmpty: 'Keranjang Belanjamu Kosong',
    checkoutName: 'Nama',
    checkoutNameValidation: 'Mohon Masukkan Namamu',
    checkoutPhone: 'Nomor Telepon',
    checkoutPhoneValidation: 'Mohon masukkan nomor teleponmu',
    checkoutEmail: 'E-mail',
    checkoutEmailValidation: 'Mohon masukkan alamat email yang benar',
    checkoutNotes: 'Catatan',
    checkoutSubmit: 'Checkout',
    checkoutThanksTitle: 'Terima Kasih',
    checkoutThanksText: 'Pesananmu sudah kami terima. Kami akan menghubungi Anda segera.',
    checkoutContinueShopping: 'Lanjut belanja',
    email: 'E-mail',
    firstName: 'Nama',
    lastName: 'Nama keluarga',
    subtotal: 'Subtotal',
    clientData: 'Informasi klien',
    paymentMethods: 'Cara Pembayaran',
    shipping: 'Pengiriman',
    taxesAndFees: 'Pajak dan biaya',
    required: 'Ini diperlukan',
    formIsInvalid: 'Formulir tidak valid',
    discount: 'Diskon',
    promocode: 'Kode promosi',
    promocodeNotFound: 'Kode promo tidak ditemukan atau kedaluwarsa',
    apply: 'Terapkan',
    nothingFound: 'Ups! Tidak ada yang ditemukan...',
    pageNotExists: 'Halaman ini sudah tidak ada',
    goBack: 'Kembali',
    mapView: 'Peta',
    listView: 'Katalog',
    thankYouForSubscription: 'Terima kasih telah berlangganan',
    reachedMaxQuantity: 'Anda mencapai jumlah maksimum yang diperbolehkan untuk barang ini',
    reachedMaxItemsAmount: 'Anda tidak dapat menambahkan lebih dari satu barang',
    reset: 'Setel ulang'
  },

  ms: {
    label: 'Bahasa Melayu',
    isRtl: false,

    searchPlaceholder: 'Apa yang anda cari?',
    resetAll: 'Set semula semua',
    defaultOrder: 'Pesanan asal',
    madeWithSpreadSimple: 'Dicipta dengan SpreadSimple',
    buyButton: 'Beli',
    yourCart: 'Kart anda',
    cartTotalPrice: 'Jumlah harga',
    cartIsEmpty: 'Kart kosong...',
    checkoutName: 'Nama',
    checkoutNameValidation: 'Sila masukkan nama anda',
    checkoutPhone: 'Nombor telefon',
    checkoutPhoneValidation: 'Sila masukkan nombor telefon anda',
    checkoutEmail: 'E-mel',
    checkoutEmailValidation: 'Sila masukkan alamat e-mel yang sah',
    checkoutNotes: 'Nota',
    checkoutSubmit: 'Daftar keluar',
    checkoutThanksTitle: 'Terima kasih!',
    checkoutThanksText: 'Kami telah menerima pesanan anda. Kami akan menghubungi anda kemudian.',
    checkoutContinueShopping: 'Teruskan membeli-belah',
    email: 'E-mel',
    firstName: 'Nama',
    lastName: 'Nama terakhir',
    subtotal: 'Jumlah kecil',
    clientData: 'Maklumat pelanggan',
    paymentMethods: 'Cara bayaran',
    shipping: 'Penghantaran',
    taxesAndFees: 'Cukai dan bayaran',
    required: 'Ini diperlukan',
    formIsInvalid: 'Borang tidak sah',
    discount: 'Diskaun',
    promocode: 'Kod promosi',
    promocodeNotFound: 'Kod promosi tidak ditemui atau tamat tempoh',
    apply: 'Gunakan',
    nothingFound: 'Oops! Tiada apa-apa Dijumpai ...',
    pageNotExists: 'Halaman ini tidak wujud lagi',
    goBack: 'Pergi balik',
    mapView: 'Peta',
    listView: 'Senarai',
    thankYouForSubscription: 'Terima kasih kerana melanggan!',
    reachedMaxQuantity: 'Anda mencapai kuantiti maksimum yang dibenarkan untuk item ini',
    reachedMaxItemsAmount: 'Anda tidak boleh menambah lebih daripada satu item',
    reset: 'Set semula'
  },

  fi: {
    label: 'Suomi',
    isRtl: false,

    searchPlaceholder: 'Mitä olet etsimässä?',
    resetAll: 'Nollaa kaikki',
    defaultOrder: 'Oletusjärjestys',
    madeWithSpreadSimple: 'Tehty SpreadSimplen avulla',
    buyButton: 'Osta',
    yourCart: 'Ostoskorisi',
    cartTotalPrice: 'Yhteensä',
    cartIsEmpty: 'Ostoskorisi on tyhjä',
    checkoutName: 'Nimi',
    checkoutNameValidation: 'Anna nimesi',
    checkoutPhone: 'Puhelinnumero',
    checkoutPhoneValidation: 'Anna puhelinnumerosi',
    checkoutEmail: 'Sähköposti',
    checkoutEmailValidation: 'Anna oikea sähköpostiosoite',
    checkoutNotes: 'Huom!',
    checkoutSubmit: 'Kassa',
    checkoutThanksTitle: 'Kiitos!',
    checkoutThanksText: 'Saimme tilauksesi. Olemme sinuun pian yhteydessä!',
    checkoutContinueShopping: 'Jatka ostoksia',
    email: 'Sähköposti',
    firstName: 'Etunimi',
    lastName: 'Sukunimi',
    subtotal: 'Välisumma',
    clientData: 'Asiakkaan tiedot',
    paymentMethods: 'Maksutavat',
    shipping: 'Lähetys',
    taxesAndFees: 'Verot ja maksut',
    required: 'Kenttä on pakollinen',
    formIsInvalid: 'Lomake on virheellinen',
    discount: 'Alennus',
    promocode: 'Tarjouskoodi',
    promocodeNotFound: 'Tarjouskoodia ei löydy tai se on vanhentunut',
    apply: 'Käytä',
    nothingFound: 'Hups! Mitään ei löytynyt...',
    pageNotExists: 'Tätä sivua ei ole enää olemassa',
    goBack: 'Mene takaisin',
    mapView: 'Karttanäkymä',
    listView: 'Listanäkymä',
    thankYouForSubscription: 'Kiitos tilaamisesta!',
    reachedMaxQuantity: 'Olet saavuttanut tälle tuotteelle sallitun enimmäismäärän',
    reachedMaxItemsAmount: 'Et voi lisätä enempää kuin yhden kohteen',
    reset: 'Nollaa'
  },

  'zh-hk': {
    label: '繁體中文',
    isRtl: false,

    searchPlaceholder: '您正在找什麼呢？',
    resetAll: '全部重新設定',
    defaultOrder: '默認排序',
    madeWithSpreadSimple: '本站採用 SpreadSimple 建置',
    buyButton: '購買',
    yourCart: '您的購物車',
    cartTotalPrice: '總價',
    cartIsEmpty: '現在購物車是空的',
    checkoutName: '姓名',
    checkoutNameValidation: '請輸入姓名',
    checkoutPhone: '手機號碼',
    checkoutPhoneValidation: '請輸入手機號碼',
    checkoutEmail: '電子信箱',
    checkoutEmailValidation: '請輸入有效電子信箱',
    checkoutNotes: '結帳註釋',
    checkoutSubmit: '結帳',
    checkoutThanksTitle: '謝謝您！',
    checkoutThanksText: '我們已收到您的訂單，會很快跟您聯繫。',
    checkoutContinueShopping: '請繼續購買',
    email: '電子郵件',
    firstName: '名',
    lastName: '姓',
    subtotal: '小計',
    clientData: '客戶資料',
    paymentMethods: '支付方式',
    shipping: '船運',
    taxesAndFees: '稅費',
    required: '這是必需的',
    formIsInvalid: '無效表格',
    discount: '折扣',
    promocode: '促銷代碼',
    promocodeNotFound: '促銷代碼未找到或已過期',
    apply: '申請',
    nothingFound: '哎呀！什麼都沒找到...',
    pageNotExists: '此頁面已不存在',
    goBack: '回去',
    mapView: '地圖視圖',
    listView: '列表顯示',
    thankYouForSubscription: '感謝您的訂閱',
    reachedMaxQuantity: '您已達到該商品允許的最大數量',
    reachedMaxItemsAmount: '您不能新增多於一項的項目',
    reset: '重置'
  },

  'zh-cn': {
    label: '中国 - 简体中文',
    isRtl: false,

    searchPlaceholder: '搜索...',
    resetAll: '重设',
    defaultOrder: '默认排序',
    madeWithSpreadSimple: '本站采用 SpreadSimple 建设',
    buyButton: '购买',
    yourCart: '购物车',
    cartTotalPrice: '总价',
    cartIsEmpty: '购物车没有货品',
    checkoutName: '姓名',
    checkoutNameValidation: '请输入你的姓名',
    checkoutPhone: '电话号码',
    checkoutPhoneValidation: '请输入你的电话号码',
    checkoutEmail: '电子邮箱',
    checkoutEmailValidation: '请输入你的电子邮箱',
    checkoutNotes: '备注',
    checkoutSubmit: '确认订单',
    checkoutThanksTitle: '谢谢！',
    checkoutThanksText: '我们已收到您的订单, 我们会尽快与您联系。',
    checkoutContinueShopping: '继续购物',
    email: '电子邮箱',
    firstName: '名',
    lastName: '名',
    subtotal: '小计',
    clientData: '客户资料',
    paymentMethods: '支付方式',
    shipping: '船运',
    taxesAndFees: '税收',
    required: '这是必需的',
    formIsInvalid: '表格无效',
    discount: '折扣',
    promocode: '促销代码',
    promocodeNotFound: '促销代码未找到或已过期',
    apply: '申请',
    nothingFound: '哎呀！什么都没找到...',
    pageNotExists: '此页面已不存在',
    goBack: '回去',
    mapView: '地图视图',
    listView: '列表显示',
    thankYouForSubscription: '感谢您的订阅',
    reachedMaxQuantity: '您已达到该商品允许的最大数量',
    reachedMaxItemsAmount: '您不能添加多于一项的项目',
    reset: '重设'
  },

  sk: {
    label: 'Slovenčina',
    isRtl: false,

    searchPlaceholder: 'Čo hľadáte?',
    resetAll: 'Zrušiť filtre',
    defaultOrder: 'Predvolené poradie',
    madeWithSpreadSimple: 'Vytvorené pomocou SpreadSimple',
    buyButton: 'Kúpiť',
    yourCart: 'Váš košík',
    cartTotalPrice: 'Celková cena',
    cartIsEmpty: 'Košík je prázdny ...',
    checkoutName: 'Meno',
    checkoutNameValidation: 'Prosím, zadajte vaše meno',
    checkoutPhone: 'Telefónne číslo',
    checkoutPhoneValidation: 'Prosím, zadajte vaše telefónne číslo',
    checkoutEmail: 'E-mail',
    checkoutEmailValidation: 'Prosím, zadajte platnú emailovú adresu',
    checkoutNotes: 'Poznámka',
    checkoutSubmit: 'Prejsť k pokladni',
    checkoutThanksTitle: 'Ďakujeme!',
    checkoutThanksText: 'Dostali sme vašu objednávku. Čoskoro vás budeme kontaktovať.',
    checkoutContinueShopping: 'Pokračovať v nákupe',
    email: 'E-mail',
    firstName: 'Meno',
    lastName: 'Priimek',
    subtotal: 'Medzisúčet',
    clientData: 'Klientske údaje',
    paymentMethods: 'Spôsob platby',
    shipping: 'Poštovné',
    taxesAndFees: 'Povinné príplatky',
    required: 'Povinný údaj',
    formIsInvalid: 'Formulár je nesprávne vyplnený',
    discount: 'Zľava',
    promocode: 'Propagačný kód',
    promocodeNotFound: 'Propagačný kód sa nenašiel alebo jeho platnosť vypršala',
    apply: 'Použiť',
    nothingFound: 'Ojoj! Nič sa nenašlo',
    pageNotExists: 'Táto stránka už neexistuje',
    goBack: 'Vráťte sa',
    mapView: 'Mapa',
    listView: 'Zoznam',
    thankYouForSubscription: 'Ďakujeme, že ste sa prihlásili na odber!',
    reachedMaxQuantity: 'Dosiahli ste maximálne povolené množstvo pre túto položku',
    reachedMaxItemsAmount: 'Nemôžete pridať viac ako jednu položku',
    reset: 'Zrušiť'
  },
  hu: {
    label: 'Magyar',
    isRtl: false,

    searchPlaceholder: 'Keresés',
    resetAll: 'Szűrők törlése',
    defaultOrder: 'Alapértelmezett sorrend',
    madeWithSpreadSimple: 'SpreadSimple-vel készítve',
    buyButton: 'Vásárlás',
    yourCart: 'Kosár',
    cartTotalPrice: 'Összesen',
    cartIsEmpty: 'A kosár üres...',
    checkoutName: 'Név',
    checkoutNameValidation: 'A név beírása szükséges',
    checkoutPhone: 'Telefonszám',
    checkoutPhoneValidation: 'A telefonszám beírása szükséges',
    checkoutEmail: 'E-mail',
    checkoutEmailValidation: 'Érvényes e-mail cím beírása szükséges',
    checkoutNotes: 'Megjegyzések',
    checkoutSubmit: 'Rendelés',
    checkoutThanksTitle: 'Köszönjük!',
    checkoutThanksText: 'Megkaptuk a rendelést. Hamarosan jelentkezünk.',
    checkoutContinueShopping: 'Vásárlás folytatása',
    email: 'E-mail',
    firstName: 'Név',
    lastName: 'Vezetéknév',
    subtotal: 'Részösszeg',
    clientData: 'Ügyféladatok',
    paymentMethods: 'Fizetési mód',
    shipping: 'Szállítás',
    taxesAndFees: 'Adók és díjak',
    required: 'Szükséges',
    formIsInvalid: 'Az űrlap érvénytelen',
    discount: 'Kedvezmény',
    promocode: 'Promóciós kód',
    promocodeNotFound: 'Promóciós kód nem található, vagy lejárt',
    apply: 'Alkalmaz',
    nothingFound: 'Hoppá! Nincs találat',
    pageNotExists: 'Ez az oldal már nem létezik',
    goBack: 'Menjen vissza',
    mapView: 'Térkép',
    listView: 'Jegyzék',
    thankYouForSubscription: 'Köszönjük hogy feliratkozott!',
    reachedMaxQuantity: 'Elérte a tételhez engedélyezett maximális mennyiséget',
    reachedMaxItemsAmount: 'Egynél több elemet nem adhat hozzá',
    reset: 'Visszaállítás'
  },
  ko: {
    label: '한국어',
    isRtl: false,

    searchPlaceholder: '무엇을 찾고 계신가요?',
    resetAll: '모두 리셋',
    defaultOrder: '기본 순서',
    madeWithSpreadSimple: 'SpreadSimple로 만들어졌습니다.',
    buyButton: '구매',
    yourCart: '장바구니',
    cartTotalPrice: '총 가격',
    cartIsEmpty: '장바구니에 담긴 상품이 없습니다...',
    checkoutName: '이름',
    checkoutNameValidation: '이름을 입력하세요',
    checkoutPhone: '전화번호',
    checkoutPhoneValidation: '전화번호를 입력하세요',
    checkoutEmail: '이메일',
    checkoutEmailValidation: '유효한 이메일 주소를 넣으세요',
    checkoutNotes: '노트',
    checkoutSubmit: '주문',
    checkoutThanksTitle: '감사합니다!',
    checkoutThanksText: '주문해주셔서 감사합니다. 빠른시일 안에 연락드리겠습니다.',
    checkoutContinueShopping: '계속 쇼핑하기',
    email: '이메일',
    firstName: '이름',
    lastName: '성',
    subtotal: '부분합',
    clientData: '고객 정보',
    paymentMethods: '결제 방법',
    shipping: '배송',
    taxesAndFees: '세금 & 수수료',
    required: '필수',
    formIsInvalid: '양식이 잘못되었습니다.',
    discount: '할인',
    promocode: '할인 코드',
    promocodeNotFound: '할인 코드를 찾을수 없거나 만료되었습니다.',
    apply: '적용',
    nothingFound: '앗! 아무것도 찾을 수 없음',
    pageNotExists: '이 페이지는 존재하지 않는 것 같습니다',
    goBack: '돌아가다',
    mapView: '지도 보기',
    listView: '목록보기',
    thankYouForSubscription: '구독해주셔서 감사합니다!',
    reachedMaxQuantity: '이 품목에 허용된 최대 수량에 도달했습니다',
    reachedMaxItemsAmount: '항목을 두 개 이상 추가할 수 없습니다',
    reset: '초기화'
  },
  mn: {
    label: 'Монгол хэл',
    isRtl: false,

    searchPlaceholder: 'Хайлт',
    resetAll: 'Шүүлтийг цуцлах',
    defaultOrder: 'Өгөгдмөл захиалга',
    madeWithSpreadSimple: 'SpreadSimple ээр бүтээгдсэн',
    buyButton: 'Худалдан авах',
    yourCart: 'Таны сагсанд',
    cartTotalPrice: 'Нийт үнэ',
    cartIsEmpty: 'Сагс хоосон байна...',
    checkoutName: 'Нэр',
    checkoutNameValidation: 'Та өөрйин нэрээ оруулна уу',
    checkoutPhone: 'Утасны дугаар',
    checkoutPhoneValidation: 'Та утасны дугаараа оруулна уу',
    checkoutEmail: 'И-мэйл',
    checkoutEmailValidation: 'И-мэйл хаягаа оруулна уу',
    checkoutNotes: 'Тэмдэглэл',
    checkoutSubmit: 'Тооцоо хийх',
    checkoutThanksTitle: 'Баярлалаа!',
    checkoutThanksText: 'Таны захиалгыг хүлээн авлаа. Бид таньтай холбогдох болно',
    checkoutContinueShopping: 'Үргэлжлүүлэн худалдан авалт хийе',
    email: 'И-мэйл',
    firstName: 'Нэр',
    lastName: 'Овог',
    subtotal: 'Нийлбэр',
    clientData: 'Хэрэглэгчийн дата',
    paymentMethods: 'Төлбөрийн хэлбэр',
    shipping: 'Хүргэлт',
    taxesAndFees: 'Татвар & төлбөр',
    required: 'Шаардлагатай',
    formIsInvalid: 'Маягт хүчингүй байна',
    discount: 'Хямдрал',
    promocode: 'Хямдралын код',
    promocodeNotFound: 'Хямдралын код олдсонгүй эсвэл хүчинтэй хугацаа дууссан байна',
    apply: 'Идэвхжүүлэх',
    nothingFound: 'Уучлаарай! Олдсонгүй',
    pageNotExists: 'Хуудас олдсонгүй',
    goBack: 'Буцах',
    mapView: 'Газрын зураг',
    listView: 'Жагсаалт',
    thankYouForSubscription: 'Захиалсанд баярлалаа!',
    reachedMaxQuantity: 'Та энэ бүтээгдэхүүний зөвшөөрөгдөх дээд хэмжээнд хүрсэн байна',
    reachedMaxItemsAmount: 'Та нэгээс олон зүйл нэмэх боломжгүй',
    reset: 'Дахин тохируулах'
  },
  hr: {
    label: 'Hrvatski',
    isRtl: false,

    searchPlaceholder: 'Što tražiš?',
    resetAll: 'Resetiraj sve',
    defaultOrder: 'Zadani poredak',
    madeWithSpreadSimple: 'Izrađeno sa SpreadSimple',
    buyButton: 'Kupi',
    yourCart: 'Košarica',
    cartTotalPrice: 'Ukupno',
    cartIsEmpty: 'Košarica je prazna',
    checkoutName: 'Ime',
    checkoutNameValidation: 'Unesi ime',
    checkoutPhone: 'Broj telefona',
    checkoutPhoneValidation: 'Unesi broj telefona',
    checkoutEmail: 'E-mail',
    checkoutEmailValidation: 'Unesi važeću email adresu',
    checkoutNotes: 'Bilješke',
    checkoutSubmit: 'Dovrši kupovinu',
    checkoutThanksTitle: 'Hvala!',
    checkoutThanksText: 'Zaprimili smo tvoju narudžbu. Kontaktirat ćemo te uskoro.',
    checkoutContinueShopping: 'Nastavi kupovinu',
    email: 'E-mail',
    firstName: 'Ime',
    lastName: 'Prezime',
    subtotal: 'Subtotal',
    clientData: 'Podaci',
    paymentMethods: 'Načini plaćanja',
    shipping: 'Dostava',
    taxesAndFees: 'Porez i troškovi',
    required: 'Obavezno',
    formIsInvalid: 'Forma nije važeća',
    discount: 'Popust',
    promocode: 'Kod za popust',
    promocodeNotFound: 'Promo kod nije važeći ili je istekao',
    apply: 'Primijeni',
    nothingFound: 'Ups! Stranica nije pronađena...',
    pageNotExists: 'Ova stranica više ne postoji',
    goBack: 'Povratak',
    mapView: 'Popis',
    listView: 'Karta',
    thankYouForSubscription: 'Hvala što ste se pretplatili!',
    reachedMaxQuantity: 'Dosegli ste maksimalnu dopuštenu količinu za ovu stavku',
    reachedMaxItemsAmount: 'Ne možete dodati više od jedne stavke',
    reset: 'Resetiraj'
  },
  fa: {
    label: 'فارسی',
    isRtl: true,

    searchPlaceholder: 'دنبال چه چیزی می گردید؟',
    resetAll: 'باز نشانی تنظیمات',
    defaultOrder: 'ترتیب پیش فرض',
    madeWithSpreadSimple: ' با SpreadSimple ساخته شد',
    buyButton: 'خرید',
    yourCart: 'سبد شما',
    cartTotalPrice: 'قیمت کل',
    cartIsEmpty: 'سبد خالی است',
    checkoutName: 'نام',
    checkoutNameValidation: 'لطفاً نام خود را بنویسید',
    checkoutPhone: 'شماره تماس',
    checkoutPhoneValidation: 'لطفاً شماره تلفونی خود را فراهم نمایید',
    checkoutEmail: 'ایمیل',
    checkoutEmailValidation: 'لطفاً ایمیل معتبر خود را فراهم نمایید',
    checkoutNotes: 'یادداشت',
    checkoutSubmit: 'اقدام/ صندوق پراخت',
    checkoutThanksTitle: 'سپاس گزار هستیم!',
    checkoutThanksText: 'سفارش شما را دریافت شد. به زودی با شما تماس خواهیم گرفت.',
    checkoutContinueShopping: 'ادامه خرید',
    email: 'ایمیل',
    firstName: 'نام',
    lastName: 'نام خانودگی',
    subtotal: 'جمع مبلغ فرعی',
    clientData: 'مشخصات مشتری',
    paymentMethods: 'روش پرداخت',
    shipping: 'هزینه ارسال',
    taxesAndFees: 'مالیات و هزینه های دیگر',
    required: 'فیلد مو',
    formIsInvalid: 'فیلد مورد نیاز',
    discount: 'تخفیف',
    promocode: 'کد تبلیقاتی',
    promocodeNotFound: 'کد تبلاقاتی شما یا منقضی شده و یا نا معتبر است',
    apply: 'درخواست',
    nothingFound: 'اوه، پیدا نشد',
    pageNotExists: 'این سفحه دیگر قابل دسترس نیست',
    goBack: 'بازگشت',
    mapView: 'نمای نقشه',
    listView: 'نمای لیست',
    thankYouForSubscription: 'با تشکر از شما برای اشتراک',
    reachedMaxQuantity: 'شما به حداکثر مقدار مجاز برای این مورد رسیده اید',
    reachedMaxItemsAmount: 'شما نمی توانید بیش از یک مورد اضافه کنید',
    reset: 'تنظیم مجدد'
  },
  te: {
    label: 'తెలుగు',
    isRtl: false,

    searchPlaceholder: 'మీరు దేని కోసం చూస్తున్నారు?',
    resetAll: 'రీసెట్ చేయండి',
    defaultOrder: 'డిఫాల్ట్ ఆర్డర్',
    madeWithSpreadSimple: 'SpreadSimpleతో తయారు చేయబడింది',
    buyButton: 'కొనుగోలు',
    yourCart: 'మీ కార్ట్',
    cartTotalPrice: 'మొత్తం విలువ',
    cartIsEmpty: 'మీ కార్ట్ మొత్తం ప్రైస్‌కార్ట్ ఖాళీగా ఉంది...',
    checkoutName: 'పేరు',
    checkoutNameValidation: 'దయచేసి మీ పేరు నమోదు చేయండి',
    checkoutPhone: 'ఫోను నంబరు',
    checkoutPhoneValidation: 'దయచేసి మీ ఫోన్ నంబర్‌ను నమోదు చేయండి',
    checkoutEmail: 'ఇ-మెయిల్',
    checkoutEmailValidation: 'దయచేసి చెల్లుబాటు అయ్యే ఇమెయిల్ చిరునామాను నమోదు చేయండి',
    checkoutNotes: 'గమనికలు',
    checkoutSubmit: 'చెక్అవుట్',
    checkoutThanksTitle: 'ధన్యవాదాలు!',
    checkoutThanksText: 'మేము మీ ఆర్డర్‌ని అందుకున్నాము.  మేము త్వరలో మిమ్మల్ని సంప్రదిస్తాము.',
    checkoutContinueShopping: 'షాపింగ్ కొనసాగించండి',
    email: 'ఇమెయిల్',
    firstName: 'మొదటి పేరు',
    lastName: 'చివరి పేరు',
    subtotal: 'ఉపమొత్తం',
    clientData: 'క్లయింట్ డేటా',
    paymentMethods: 'చెల్లింపు పద్ధతులు',
    shipping: 'షిప్పింగ్',
    taxesAndFees: 'పన్నులు & ఫీజులు',
    required: 'అవసరం',
    formIsInvalid: 'ఫారమ్ చెల్లదు',
    discount: 'తగ్గింపు',
    promocode: 'ప్రోమో కోడ్',
    promocodeNotFound: 'ప్రోమో కోడ్ కనుగొనబడలేదు లేదా గడువు ముగిసింది',
    apply: 'దరఖాస్తు చేసుకోండి',
    nothingFound: 'అయ్యో!  ఏమీ దొరకలేదు...',
    pageNotExists: 'ఈ పేజీ ఇప్పుడు లేదు',
    goBack: 'వెనక్కి వెళ్ళు',
    mapView: 'మ్యాప్ వీక్షణ',
    listView: 'జాబితా వీక్షణ',
    thankYouForSubscription: 'చందా చేసినందుకు ధన్యవాదాలు',
    reachedMaxQuantity: 'మీరు ఈ అంశం కోసం అనుమతించబడిన గరిష్ట పరిమాణానికి చేరుకున్నారు',
    reachedMaxItemsAmount: 'మీరు ఒకటి కంటే ఎక్కువ అంశాలను జోడించలేరు',
    reset: 'రీసెట్ చేయండి'
  }
}
