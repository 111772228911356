<template>
  <div class="page-quiz">
    <div class="page-quiz__container">
      <h1 class="page-quiz__title">Welcome! Let us personalize your experience</h1>
      <form class="page-quiz__form" @submit.prevent="submitForm">

        <div class="page-quiz__form-section">
          <div class="page-quiz__form-item">
            <h5 class="page-quiz__form-item-title">1. What kind of website are you going to build? </h5>
            <div class="page-quiz__form-item-control is-grid-view">
              <label
                v-for="item in questionOptions.websiteType"
                :key="item.value"
                class="page-quiz__radio"
                :class="{'is-checked': answers.websiteType === item.value}"
              >
                <input
                  v-model="answers.websiteType"
                  type="radio"
                  name="website-type"
                  :value="item.value"
                >
                <span class="page-quiz__radio-indicator"></span>
                <span class="page-quiz__radio-label">{{item.title}}</span>
              </label>
            </div>
            <h3>{{ $v.answers.websiteType.$dirty && $v.answers.websiteType.$invalid ? 'Field is required' : '' }}</h3>
            <div
              v-if="answers.websiteType === 'Other'"
              class="page-quiz__form-item-control"
            >
              <FormField
                :messages="[
                  $v.answers.websiteTypeOther.$dirty && $v.answers.websiteTypeOther.$invalid ? 'Field is required' : ''
                ]">
                <el-input
                  v-model="answers.websiteTypeOther"
                  placeholder="Text, please"
                />
              </FormField>
            </div>
          </div>
          <div class="page-quiz__form-item">
            <h5 class="page-quiz__form-item-title">2. Do you have any experience with website building?</h5>
            <div class="page-quiz__form-item-control is-list-view">
              <label
                v-for="item in questionOptions.experience"
                :key="item.value"
                class="page-quiz__radio"
                :class="{'is-checked': answers.experience === item.value}"
              >
                <input
                  v-model="answers.experience"
                  type="radio"
                  name="website-type"
                  :value="item.value"
                >
                <span class="page-quiz__radio-indicator"></span>
                <span class="page-quiz__radio-label">{{item.title}}</span>
              </label>
            </div>
            <h3>{{ $v.answers.experience.$dirty && $v.answers.experience.$invalid ? 'Field is required' : '' }}</h3>
          </div>
          <div class="page-quiz__form-item">
            <h5 class="page-quiz__form-item-title">3. How well are you familiar with Google Sheets?</h5>
            <div class="page-quiz__form-item-control is-list-view">
              <label
                v-for="item in questionOptions.googleSheetFamiliar"
                :key="item.value"
                class="page-quiz__radio"
                :class="{'is-checked': answers.googleSheetFamiliar === item.value}"
              >
                <input
                  v-model="answers.googleSheetFamiliar"
                  type="radio"
                  name="website-type"
                  :value="item.value"
                >
                <span class="page-quiz__radio-indicator"></span>
                <span class="page-quiz__radio-label">{{item.title}}</span>
              </label>
            </div>
            <h3>{{ $v.answers.googleSheetFamiliar.$dirty && $v.answers.googleSheetFamiliar.$invalid ? 'Field is required' : '' }}</h3>
          </div>
        </div>
        <div class="page-quiz__form-btns">
          <UiBtn
            label="SKIP"
            attrType="button"
            type="secondary-blue"
            wide
            @click="skipPoll"
          />
          <UiBtn
            label="SUBMIT"
            attrType="submit"
            wide
          />
        </div>
      </form>
    </div>
    <Preloader :loading="isLoading" />
  </div>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators'
import api from '@/services/api/'
import Vuelidate from 'vuelidate'
import Vue from 'vue'
import { mapState } from 'vuex'
import mixpanel from 'mixpanel-browser'
import UiBtn from '@/components/ui-btn/UiBtn.vue'
import FormField from '@/components/form-field/FormField.vue'
Vue.use(Vuelidate)
export default {
  name: 'PageQuiz',
  components: {
    FormField,
    UiBtn
  },
  metaInfo: {
    title: 'Quiz'
  },
  data () {
    return {
      isLoading: false,
      questionOptions: {
        websiteType: [
          { title: 'Affiliate Marketing', value: 'Affiliate Marketing' },
          { title: 'Menu / Food delivery', value: 'Menu / Food delivery' },
          { title: 'Portfolio', value: 'Portfolio' },
          { title: 'E-commerce', value: 'E-commerce' },
          { title: 'Education', value: 'Education' },
          { title: 'Real Estate', value: 'Real Estate' },
          { title: 'Listing / Directory', value: 'Listing / Directory' },
          { title: 'Curated list', value: 'Curated list' },
          { title: 'Video Catalog', value: 'Video Catalog' },
          { title: 'Jobboard', value: 'Jobboard' },
          { title: 'Events', value: 'Events' },
          { title: 'Other', value: 'Other' }
        ],
        experience: [
          { title: 'Never made a website before', value: 'Never made a website before' },
          { title: 'Built a few websites using other similar tools', value: 'Built a few websites using other similar tools' },
          { title: 'Professional web developer', value: 'Professional web developer' }
        ],
        googleSheetFamiliar: [
          { title: 'Still figuring it out', value: 'Still figuring it out' },
          { title: 'Comfortable with formulas and using them regularly', value: 'Comfortable with formulas and using them regularly' },
          { title: 'Google Sheets Guru', value: 'Google Sheets Guru' }
        ]
      },
      answers: {
        websiteType: '',
        websiteTypeOther: '',
        experience: '',
        googleSheetFamiliar: ''
      }
    }
  },
  computed: {
    ...mapState('app', [
      'userData'
    ])
  },
  validations: {
    answers: {
      websiteType: {
        required
      },
      websiteTypeOther: {
        required: requiredIf(function (answers) {
          return answers.websiteType === 'Other'
        })
      },
      experience: {
        required
      },
      googleSheetFamiliar: {
        required
      }
    }
  },
  methods: {
    routerPush () {
      localStorage.setItem('ssShowInitialQuiz', false)
      if (this.userData.pollsStates[0].retries < 1) {
        if (this.$route.query.redirect !== undefined) {
          let redirect = this.$route.query.redirect
          if (this.$route.query.redirect.startsWith('/?_ga')) {
            redirect = `/add${redirect}`
          }
          this.$router.push({ path: redirect })
        } else {
          this.$router.push({ name: 'add-view-page' })
        }
      } else {
        this.$router.push({ name: 'spread-views' })
      }
    },

    async skipPoll () {
      try {
        await api.skipQuiz({ name: 'initialPoll' })
        this.$trackEvent({
          'event': 'quizSkip'
        })
        this.$v.$reset()
        this.routerPush()
      } catch (e) {
        console.error(e)
      }
    },
    async submitForm () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          this.answers.websiteType = [this.answers.websiteType]
          this.isLoading = true
          await api.sendInitialQuiz({
            name: 'initialPoll',
            results: this.answers
          })
          if (mixpanel) {
            mixpanel.identify(this.userData.id)
            mixpanel.people.set({
              'Website type': this.answers.websiteType,
              'Website type other': this.answers.websiteTypeOther,
              'Experience': this.answers.experience,
              'Google Sheets familiar': this.answers.googleSheetFamiliar
            })
          }
          this.$trackEvent({
            'event': 'quizSubmit'
          })
          this.$v.$reset()
          this.routerPush()
        } catch (e) {
          console.error(e)
          this.isLoading = false
        }
      }
    }
  }
}
</script>
