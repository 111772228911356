var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tile-constructor" }, [
    _c(
      "div",
      { staticClass: "tile-constructor__form-fields" },
      [
        _vm._l(_vm.tileFields, function(field) {
          return _c(
            "div",
            {
              key: field.name,
              staticClass: "tile-constructor__field aside-select"
            },
            [
              _c("h6", { staticClass: "aside-field-label" }, [
                _vm._v(_vm._s(field.label))
              ]),
              _c(
                "v-select",
                {
                  attrs: {
                    value:
                      _vm.tileOptions[field.name][
                        field.multiple ? "ids" : "id"
                      ],
                    items: _vm.cols,
                    "item-text": "label",
                    "item-value": "id",
                    attach: "",
                    placeholder: "Choose a column",
                    "menu-props": "offsetY",
                    clearable: field.clearable,
                    chips: field.multiple,
                    "deletable-chips": field.multiple,
                    multiple: field.multiple,
                    "append-icon": "keyboard_arrow_down"
                  },
                  on: {
                    input: function(val) {
                      return _vm.updateTileOptionValue(
                        val,
                        field.name,
                        field.multiple
                      )
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "selection",
                        fn: function(data) {
                          return [
                            field.multiple
                              ? _c(
                                  "DraggableChip",
                                  {
                                    attrs: {
                                      id: data.index,
                                      value:
                                        _vm.tileOptions[field.name][
                                          field.multiple ? "ids" : "id"
                                        ]
                                    },
                                    on: {
                                      input: function(val) {
                                        return _vm.updateTileOptionValue(
                                          val,
                                          field.name,
                                          field.multiple
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(data.item.label) +
                                        "\n          "
                                    )
                                  ]
                                )
                              : _c(
                                  "div",
                                  { staticClass: "v-select__selection--comma" },
                                  [_vm._v(_vm._s(data.item.label))]
                                )
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "list-title",
                      attrs: { slot: "prepend-item" },
                      slot: "prepend-item"
                    },
                    [_vm._v(" " + _vm._s(field.dropdownLabel))]
                  )
                ]
              ),
              _c("i", {
                staticClass: "aside-select__field-icon icon-arrow-down"
              }),
              !!_vm.v[field.name] && !_vm.v[field.name].id.required
                ? _c("p", { staticClass: "form-field__error" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("global.fieldIsRequired")) +
                        "\n      "
                    )
                  ])
                : _vm._e(),
              [
                _c("div", {
                  staticClass: "tile-constructor__hint",
                  domProps: { innerHTML: _vm._s(field.hint) }
                })
              ],
              field.name === "image" &&
              _vm.tileOptions.image.keepAspectRatio !== undefined
                ? _c(
                    "div",
                    {
                      staticClass: "content-form__switch-field",
                      staticStyle: { "margin-top": "20px" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "content-form__switcher-wrap" },
                        [
                          _c(
                            "div",
                            { staticClass: "content-form__switcher-label" },
                            [_vm._v("Keep image aspect ratio")]
                          ),
                          _c(
                            "label",
                            {
                              staticClass: "switcher",
                              class: {
                                selected: _vm.tileOptions.image.keepAspectRatio
                              }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.tileOptions.image.keepAspectRatio,
                                    expression:
                                      "tileOptions.image.keepAspectRatio"
                                  }
                                ],
                                staticClass: "switcher__input",
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.tileOptions.image.keepAspectRatio
                                  )
                                    ? _vm._i(
                                        _vm.tileOptions.image.keepAspectRatio,
                                        null
                                      ) > -1
                                    : _vm.tileOptions.image.keepAspectRatio
                                },
                                on: {
                                  change: function($event) {
                                    var $$a =
                                        _vm.tileOptions.image.keepAspectRatio,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.tileOptions.image,
                                            "keepAspectRatio",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.tileOptions.image,
                                            "keepAspectRatio",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.tileOptions.image,
                                        "keepAspectRatio",
                                        $$c
                                      )
                                    }
                                  }
                                }
                              }),
                              _c("span", { staticClass: "switcher__indicator" })
                            ]
                          )
                        ]
                      )
                    ]
                  )
                : _vm._e()
            ],
            2
          )
        }),
        _c("CtaButtonConstructor", {
          staticClass: "tile-constructor__field aside-select",
          attrs: {
            widgetLabel: "Primary button settings",
            cols: _vm.cols,
            enableSingleItemView: _vm.enableSingleItemView,
            msg: _vm.msg
          },
          on: {
            "change-tab": function($event) {
              return _vm.$emit("change-tab", "checkout-options")
            }
          },
          model: {
            value: _vm.tileOptions.cta,
            callback: function($$v) {
              _vm.$set(_vm.tileOptions, "cta", $$v)
            },
            expression: "tileOptions.cta"
          }
        }),
        _c("CtaButtonConstructor", {
          staticClass: "tile-constructor__field aside-select",
          attrs: {
            widgetLabel: "Secondary button settings",
            cols: _vm.cols,
            enableSingleItemView: _vm.enableSingleItemView,
            msg: _vm.msg
          },
          on: {
            "change-tab": function($event) {
              return _vm.$emit("change-tab", "checkout-options")
            }
          },
          model: {
            value: _vm.tileOptions.cta_second,
            callback: function($$v) {
              _vm.$set(_vm.tileOptions, "cta_second", $$v)
            },
            expression: "tileOptions.cta_second"
          }
        }),
        _c(
          "div",
          { staticClass: "tile-constructor__field" },
          [
            _c("CustomOptionsPicker", {
              attrs: {
                label: "Select card style",
                options: [
                  {
                    value: "tile",
                    label: "Tile view",
                    icon: "icon-tiles-view2"
                  },
                  {
                    value: "list",
                    label: "List view",
                    icon: "icon-list-view2"
                  },
                  {
                    value: "horizontal",
                    label: "Horizontal view",
                    icon: "icon-horizontal-view"
                  },
                  {
                    value: "table",
                    label: "Table view",
                    icon: "icon-table-view-style"
                  }
                ]
              },
              model: {
                value: _vm.cardStyle,
                callback: function($$v) {
                  _vm.cardStyle = $$v
                },
                expression: "cardStyle"
              }
            })
          ],
          1
        ),
        _vm.cardStyle !== "table"
          ? _c("CustomOptionsPicker", {
              attrs: {
                label: "Select card size",
                options: _vm.getCardSizeOptions(_vm.tileOptions.cardStyle.value)
              },
              model: {
                value: _vm.tileOptions.tilesSize.type,
                callback: function($$v) {
                  _vm.$set(_vm.tileOptions.tilesSize, "type", $$v)
                },
                expression: "tileOptions.tilesSize.type"
              }
            })
          : _vm._e(),
        _vm.tileOptions.cardStyle.value === "table"
          ? _c(
              "div",
              {
                staticClass: "content-form__switch-field",
                staticStyle: { "margin-top": "20px" }
              },
              [
                _c("div", { staticClass: "content-form__switcher-wrap" }, [
                  _c("div", { staticClass: "content-form__switcher-label" }, [
                    _vm._v("Scrollable table rows")
                  ]),
                  _c(
                    "label",
                    {
                      staticClass: "switcher",
                      class: { selected: _vm.tileOptions.isTableScrollable }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.tileOptions.isTableScrollable,
                            expression: "tileOptions.isTableScrollable"
                          }
                        ],
                        staticClass: "switcher__input",
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(
                            _vm.tileOptions.isTableScrollable
                          )
                            ? _vm._i(_vm.tileOptions.isTableScrollable, null) >
                              -1
                            : _vm.tileOptions.isTableScrollable
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.tileOptions.isTableScrollable,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.tileOptions,
                                    "isTableScrollable",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.tileOptions,
                                    "isTableScrollable",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(
                                _vm.tileOptions,
                                "isTableScrollable",
                                $$c
                              )
                            }
                          }
                        }
                      }),
                      _c("span", { staticClass: "switcher__indicator" })
                    ]
                  )
                ])
              ]
            )
          : _vm._e(),
        _vm.tileOptions.cardStyle.value === "tile"
          ? _c(
              "div",
              {
                staticClass: "content-form__switch-field",
                staticStyle: { "margin-top": "20px" }
              },
              [
                _c("div", { staticClass: "content-form__switcher-wrap" }, [
                  _c("div", { staticClass: "content-form__switcher-label" }, [
                    _vm._v("Don't force tiles vertical distribution")
                  ]),
                  _c(
                    "label",
                    {
                      staticClass: "switcher",
                      class: { selected: _vm.tileOptions.doNotStretchContent }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.tileOptions.doNotStretchContent,
                            expression: "tileOptions.doNotStretchContent"
                          }
                        ],
                        staticClass: "switcher__input",
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(
                            _vm.tileOptions.doNotStretchContent
                          )
                            ? _vm._i(
                                _vm.tileOptions.doNotStretchContent,
                                null
                              ) > -1
                            : _vm.tileOptions.doNotStretchContent
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.tileOptions.doNotStretchContent,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.tileOptions,
                                    "doNotStretchContent",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.tileOptions,
                                    "doNotStretchContent",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(
                                _vm.tileOptions,
                                "doNotStretchContent",
                                $$c
                              )
                            }
                          }
                        }
                      }),
                      _c("span", { staticClass: "switcher__indicator" })
                    ]
                  )
                ])
              ]
            )
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }