export default {
  published: true,
  pro: true,
  featureRestrictionsGroup: 'seoIntegrations',
  name: 'rss',
  title: 'RSS Feed',
  logoUrl: '/img/integrations/rss.svg',
  // coverUrl: '/img/integrations/rss-cover.png',
  bgColor: 'orange',
  categories: ['Feeds'],
  comingSoon: false,
  builtIn: false,
  url: '',
  helpUrl: '',
  description: `
  RSS allows users and applications to access updates to websites in a standardized, computer-readable format.

  There are two types of feeds you can enable for your website:
  - Items from the mapped Google Sheet:

    <a href="<websiteurl/>/items.rss" target="_blank"><websiteurl/>/items.rss</a>
  - Pages added to 'Pages & Links tab':

    <a href="<websiteurl/>/pages.rss" target="_blank"><websiteurl/>/pages.rss</a>
  `,

  formFields: [
    {
      name: 'enabled',
      type: 'toggle',
      label: 'Enabled',
      default: false,
      validations: [],
      secret: false
    },
    {
      name: 'enableItemsFeed',
      type: 'toggle',
      showIf: ['enabled'],
      label: 'Items Feed',
      default: true,
      validations: [],
      secret: false
    },
    {
      name: 'enablePagesFeed',
      type: 'toggle',
      showIf: ['enabled'],
      label: 'Content Pages Feed',
      default: true,
      validations: [],
      secret: false
    }
  ],

  actions: []
}
