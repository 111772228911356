const onboardingWizardData = {
  intro: {
    markdown: `
#### Welcome on board! {.onboarding-popup__head-title}
Take a quick tour around SpreadSimple and learn how to create
and manage a website with this Get Started checklist. {.onboarding-popup__head-string}

There are 10 steps but do not let this number scare you!
Every step on this list can be done just in a few clicks. {.onboarding-popup__head-string}

Ready to create your first website? 😉 {.onboarding-popup__head-string}

    `
  },
  progress: {
    text: 'Complete all the steps and get a <br> <b>20% discount on Pro Yearly Plan<b>',
    steps: [
      {
        name: 'createWebsite',
        markdown: `
#### Create a website
Create a new website by using one of our templates.

![Create website](/img/wizards/onboarding/step-1-create-website.gif)
        `,
        helpLink: 'https://help.spreadsimple.com/en/article/how-to-create-a-website-67rm0w/'
      },
      {
        name: 'editItemCard',
        markdown: `
#### Configure your content
Change the titles by mapping (connecting) a column from Google Sheet to the **Title:**

In the **Content Tab**, open the Title menu dropdown.

Select one of the columns from the list.

Click on the **Save** button to save the changes.

![Change title](/img/wizards/onboarding/step-2-change-title.gif)

        `,
        helpLink: 'https://help.spreadsimple.com/en/article/how-to-manage-details-page-16h9j6e/'
      },
      {
        name: 'editDetailsPage',
        markdown: `
#### Edit details page
Open the **Details page** settings (enable detail pages if needed).

Change the **details page layout**.

Save the changes.

![Details page](/img/wizards/onboarding/step-3-details-page.gif)
        `,
        helpLink: 'https://help.spreadsimple.com/en/article/how-to-manage-details-page-16h9j6e/'
      },
      {
        name: 'editDesign',
        markdown: `
#### Customize website design
Go to the **Design Tab -> Header.**

**Upload your header logo.** Don’t have one yet? Choose any of the images from our built-in Unsplash library.

Save the changes.

![Header logo](/img/wizards/onboarding/step-4-header-logo.gif)
        `,
        helpLink: 'https://help.spreadsimple.com/en/article/how-to-customize-your-design-duvqd2/'
      },
      {
        name: 'setUpCheckout',
        markdown: `
#### Set up the checkout
Go to the **Checkout Tab -> Cart settings.**

Set up your **shopping cart view** by selecting one of the available options from the dropdown menu.

Save the changes.

![Checkout settings](/img/wizards/onboarding/step-5-checkout-settings.gif)
        `,
        helpLink: 'https://help.spreadsimple.com/en/article/cart-settings-65sm1g/'
      },
      {
        name: 'updateSeoSettings',
        markdown: `
#### Set up your website SEO
Go to the **Settings Tab -> SEO** menu.

Add your website **meta title** in the corresponding text area.

Save the changes.

![seo](/img/wizards/onboarding/step-6-seo.gif)
        `,
        helpLink: 'https://help.spreadsimple.com/en/article/seo-settings-h6crwp/'
      },
      {
        name: 'addPagesAndLinks',
        markdown: `
#### Add a new content page
Go to the **Pages&links Tab.**

Click on the **Add page** button to create a new page.

Save the changes.

![New page](/img/wizards/onboarding/step-7-new-page.gif)
        `,
        helpLink: 'https://help.spreadsimple.com/en/article/adding-pages-and-helpLinks-and-managing-navigation-1m8d12u/'
      },
      {
        name: 'enableAddon',
        markdown: `
#### Enable an add-on
Go to the **Add-ons Tab** and enable one of the available add-ons.

Not sure which one to pick? Enable **RSS feed add-on** and save the changes.

![RSS addon](/img/wizards/onboarding/step-8-rss-addon.gif)
        `,
        helpLink: 'https://help.spreadsimple.com/en/article/rss-feed-281ffy/'
      },
      {
        name: 'updateDomain',
        markdown: `
#### Change your website domain
In the website preview, hover over the address bar and click on **Change domain.**

Set a new subdomain for your website or connect a custom domain and click on **Save.**

![Change domain](/img/wizards/onboarding/step-9-change-domain.gif)
        `,
        helpLink: 'https://help.spreadsimple.com/en/article/how-to-change-a-domain-16cllno/'
      },
      {
        name: 'publishWebsite',
        markdown: `
#### Publish your website
Finally, click the **Publish** button on the top right
of your website editor and see your website go live! 🎉

![Publish website](/img/wizards/onboarding/step-10-publish.gif)
        `,
        helpLink: ''
      }
    ]
  },
  success: {

    markdown: `
#### Congratulations! {.onboarding-popup__head-title}
You have successfully completed all the steps. {.onboarding-popup__head-string}

Happy building! {.onboarding-popup__head-string}`
  }
}

export default onboardingWizardData
