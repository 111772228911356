export default {
  published: true,
  pro: true,
  featureRestrictionsGroup: 'emailIntegrations',
  name: 'moosend',
  title: 'Moosend',
  headline: '',
  logoUrl: '/img/integrations/moosend.png',
  // coverUrl: '/img/integrations/moosend-cover.png',
  bgColor: '#485570',
  categories: ['Email Marketing'],
  comingSoon: false,
  builtIn: false,
  url: 'https://moosend.com',
  helpUrl: '',
  description: `
  With Moosend you can easily manage multiple email campaigns and mailing lists, as well as design and send striking, responsive newsletters created on the built-in campaign editor.

  You can add an email subscription form to the intro section and also as a popup.

  - <a href="https://help.moosend.com/hc/en-us/articles/208061865-How-do-I-connect-to-the-Moosend-Web-API-" target="_blank">How to get your API key?</a>
  - <a href="https://help.moosend.com/hc/en-us/articles/360006620073-How-can-I-find-the-Mailing-list-ID-" target="_blank">How to find the Mailing list ID?</a>

  `,

  formFields: [
    {
      name: 'enabled',
      type: 'toggle',
      label: 'Enabled',
      default: false,
      validations: [],
      secret: false
    },
    {
      name: 'apiKey',
      type: 'text',
      showIf: ['enabled'],
      label: 'API Key',
      default: '',
      validations: ['required'],
      secret: true
    },
    {
      name: 'mailingListId',
      type: 'text',
      showIf: ['enabled'],
      label: 'Mailing List ID',
      default: '',
      validations: ['required'],
      secret: true
    },
    {
      name: 'showIntro',
      type: 'toggle',
      showIf: ['enabled'],
      label: 'Show Form in Intro Section',
      default: false,
      validations: [],
      secret: false
    },
    {
      name: 'showPopup',
      type: 'toggle',
      showIf: ['enabled'],
      label: 'Show Floating Button and Popup',
      default: false,
      validations: [],
      secret: false
    },
    {
      name: 'title',
      type: 'text',
      showIf: ['enabled', 'showPopup'],
      label: 'Popup Title',
      default: '',
      placeholder: '',
      validations: [
        'required',
        'maxLength:160'
      ],
      secret: false
    },
    {
      name: 'subtitle',
      type: 'text',
      showIf: ['enabled', 'showPopup'],
      label: 'Popup Subtitle',
      default: '',
      placeholder: '',
      validations: [
        'maxLength:300'
      ],
      secret: false
    },
    {
      name: 'askForName',
      type: 'toggle',
      showIf: ['enabled'],
      label: 'Ask for First and Last Name',
      default: false,
      validations: [],
      secret: false
    },
    {
      name: 'buttonTitle',
      type: 'text',
      showIf: ['enabled'],
      label: 'Button Title',
      default: 'Subscribe 🐮',
      placeholder: 'Subscribe',
      validations: [
        'required',
        'maxLength:50'
      ],
      secret: false
    },
    {
      name: 'buttonColor',
      type: 'color',
      showIf: ['enabled'],
      label: 'Button Color',
      default: '#999999',
      validations: [
        'required'
      ],
      secret: false
    }
  ],

  actions: []
}
