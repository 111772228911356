const socialNetworksOptions = [
  {
    label: 'X',
    value: 'x'
  },
  {
    label: 'Facebook',
    value: 'facebook'
  },
  {
    label: 'WhatsApp',
    value: 'whatsapp'
  },
  {
    label: 'LinkedIn',
    value: 'linkedin'
  },
  {
    label: 'Reddit',
    value: 'reddit'
  },
  {
    label: 'Viber',
    value: 'viber'
  },
  {
    label: 'Pinterest',
    value: 'pinterest'
  },
  {
    label: 'Telegram',
    value: 'telegram'
  },
  {
    label: 'Messenger',
    value: 'messenger'
  },
  {
    label: 'Instagram',
    value: 'instagram'
  },
  {
    label: 'YouTube',
    value: 'youtube'
  },
  {
    label: 'TikTok',
    value: 'tiktok'
  },
  {
    label: 'Medium',
    value: 'medium'
  }
]

export { socialNetworksOptions }
