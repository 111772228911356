<template>
<div @click.stop="openDropdownMenu">
  <el-dropdown
    ref="spreadviewDropdown"
    class="spreadview__dropdown for-overlay"
    placement="bottom-start"
    trigger="click"
    @visible-change="showOverlay"
  >
    <!-- view is archived drop menu starts -->
    <el-dropdown-menu v-if="item.isArchived" slot="dropdown" class="spreadview__dropdown-menu">
      <el-dropdown-item v-if="item.options.spreadView.isPublished">
        <a
          :href="item.spreadsheetUrl"
          target="_blank"
          class="spreadview__dropdown-item"
        >{{ $t('global.editSheet') }}</a>
      </el-dropdown-item>
      <el-dropdown-item
        v-if="item.options.spreadView.isPublished"
      >
        <a
          href="javascript:;"
          class="spreadview__dropdown-item"
          @click="$emit('open-link-modal')"
        >{{ $t('global.viewLink') }}</a>
      </el-dropdown-item>
      <el-dropdown-item>
        <button
          v-if="item.options.spreadView.isPublished"
          class="spreadview__dropdown-item"
          @click="$emit('change-view-published-status', { id: item.id, published: false })"
        >{{ 'Unpublish' }}</button>
      </el-dropdown-item>
      <el-dropdown-item>
        <button
          class="spreadview__dropdown-item"
          @click="$emit('change-view-archived-status', { id: item.id, archived: false })"
        >{{ 'Unarchive' }}</button>
      </el-dropdown-item>
      <el-dropdown-item  v-if="canDelete" class="is-danger">
        <button
          class="spreadview__dropdown-item"
          @click="$emit('open-delete-modal')"
        >Delete</button>
      </el-dropdown-item>
    </el-dropdown-menu>
    <!-- view is archived drop menu ends -->

    <!-- view is not archived drop menu starts -->
    <el-dropdown-menu v-else slot="dropdown" class="spreadview__dropdown-menu">
      <el-dropdown-item>
        <a
          :href="item.spreadsheetUrl"
          target="_blank"
          class="spreadview__dropdown-item"
        >{{ $t('global.editSheet') }}</a>
      </el-dropdown-item>
      <el-dropdown-item
        v-if="item.options.spreadView.isPublished"
      >
        <a
          href="javascript:;"
          class="spreadview__dropdown-item"
          @click="$emit('open-link-modal')"
        >{{ $t('global.viewLink') }}</a>
      </el-dropdown-item>
      <el-dropdown-item
        v-if="item.options.spreadView.isPublished"
      >
        <a
          href="javascript:;"
          class="spreadview__dropdown-item"
          @click="$emit('duplicate')"
        >{{ $t('global.duplicate') }}</a>
      </el-dropdown-item>
      <el-dropdown-item>
        <button
          class="spreadview__dropdown-item"
          @click="$emit('change-view-published-status', { id: item.id, published: !item.options.spreadView.isPublished })"
        >{{ item.options.spreadView.isPublished ? 'Unpublish' : 'Publish' }}</button>
      </el-dropdown-item>
      <el-dropdown-item  v-if="canDelete" class="is-danger">
        <button
          class="spreadview__dropdown-item"
          @click="$emit('open-delete-modal')"
        >Delete</button>
      </el-dropdown-item>
    </el-dropdown-menu>

    <!-- view is not archived drop menu ends -->

    <button class="spreadview__dropdown-trigger icon-dots"></button>
  </el-dropdown>
</div>
</template>

<script>
export default {
  name: 'SingleSpreadViewDropdownMenu',
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    canDelete: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    openDropdownMenu (e) {
      this.$refs.spreadviewDropdown.handleClick()
    },
    showOverlay (val) {
      this.$emit('on-menu-visible', val)
    }
  }
}
</script>
