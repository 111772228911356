var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "n-form-field",
      class: { "without-label": !_vm.label && !_vm.$slots.label }
    },
    [
      _vm.$slots.label || _vm.label
        ? _c(
            "div",
            {
              staticClass: "n-form-field__label",
              class: {
                "required-field": _vm.required,
                error: _vm.messages.length
              }
            },
            [
              _vm._t("label", function() {
                return [_vm._v(_vm._s(_vm.label))]
              })
            ],
            2
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "n-form-field__control",
          class: { error: _vm.messages.length }
        },
        [_vm._t("default")],
        2
      ),
      _vm.messages.length
        ? _c("p", { staticClass: "n-form-field__error-message" }, [
            _vm._v(" " + _vm._s(_vm.messages.join(", ")) + " ")
          ])
        : _vm._e(),
      _vm.hint
        ? _c("p", {
            staticClass: "n-form-field__hint",
            domProps: { innerHTML: _vm._s(_vm.hint) }
          })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }