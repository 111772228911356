<template>
    <div class="content-form__switch-field">
      <div class="content-form__switcher-wrap">
        <div class="content-form__switcher-label">{{ $t('pageManageSheet.settings.enableSearch') }}</div>
        <label
          :class="{ 'selected': search.enabled }"
          class="switcher"
        >
          <input
            v-model="search.enabled"
            class="switcher__input"
            type="checkbox"
            value="tiles"
            @change="$trackFeature('content', 'search', search.enabled)"
          >
          <span class="switcher__indicator"></span>
        </label>
      </div>
      <div v-if="search.enabled" class="content-form__switch-hidden-field aside-select paddingless">
        <div class="content-form__highlighted">
          <div class="sorting-constructor__item">
            <FormField label="Search placeholder" class="side-form__item aside-input">
              <v-text-field
                v-model.trim="search.placeholder"
                :placeholder="msg.searchPlaceholder"
              />
            </FormField>
          </div>
          <h6 class="aside-field-label">Search by columns</h6>
            <v-select
              :value="search.searchByColumns"
              :items="cols"
              item-text="label"
              item-value="id"
              attach
              menu-props="offsetY"
              chips
              deletable-chips
              multiple
              placeholder="Choose options"
              append-icon="keyboard_arrow_down"
              @change="updateSearchableColumns"
            >
              <template slot="selection" slot-scope="data">
                <DraggableChip
                  :id="data.index"
                  :value="search.searchByColumns"
                  @input="updateSearchableColumns"
                >
                  {{ data.item.label }}
                </DraggableChip>
              </template>
            </v-select>
      </div>
    </div>
  </div>
</template>
<script>
import FormField from '@/components/form-field'
import DraggableChip from '@/components/draggable-chip/DraggableChip.vue'
export default {
  name: 'SearchConstructor',
  components: {
    DraggableChip,
    FormField
  },
  props: {
    cols: {
      type: Array,
      required: true
    },
    value: {
      type: Object,
      required: true
    },
    msg: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      search: {}
    }
  },
  watch: {
    search: {
      deep: true,
      handler: function (val) {
        if (!val.searchByColumns.length) {
          this.search.enabled = false
          this.search.searchByColumns = this.cols.map(col => col.id)
        }
        this.$emit('input', val)
      }
    }
  },
  methods: {
    updateSearchableColumns (val) {
      this.search.searchByColumns = val
    }
  },
  created () {
    this.search = JSON.parse(JSON.stringify(this.value))
    if (!this.search.searchByColumns.length) {
      this.search.searchByColumns = this.cols.map(col => col.id)
    }
  }
}
</script>
