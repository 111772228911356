const trimToInsTag = (el, field, formValues) => {
  const match = String(el.target.value).match(/<ins[\s\S]*?<\/ins>/g)
  if (match) formValues[field.name] = match[0]
}

const trimToScriptTag = (el, field, formValues) => {
  const match = String(el.target.value).match(/<script[\s\S]*?<\/script>/g)
  if (match) formValues[field.name] = match[0]
}

const createSlotField = ({ name, label }) => {
  return {
    name,
    type: 'textarea',
    showIf: ['enabled'],
    label,
    default: '',
    placeholder: `<ins class="adsbygoogle"
    style="display:block"
    data-ad-client="ca-pub-0000000000000000"
    data-ad-slot="000000000"
    data-ad-format="auto"
    data-full-width-responsive="true"></ins>`,
    validations: [
      'minLength:50',
      'maxLength:500',
      'startsWith:<ins'
    ],
    rows: 6,
    onBlur: trimToInsTag,
    secret: false
  }
}

export default {
  published: true,
  pro: true,
  featureRestrictionsGroup: 'ecommerceIntegrations',
  name: 'googleAdsense',
  title: 'Google Adsense (beta)',
  logoUrl: '/img/integrations/g_a_1.svg',
  // coverUrl: '/img/integrations/google-adsense-cover.png',
  bgColor: '#fbbd05',
  categories: ['Ads', 'Marketing'],
  comingSoon: false,
  builtIn: false,
  url: 'https://www.google.com/adsense',
  helpUrl: '',
  description: `
  Google Adsense is a free, simple way to earn money by placing ads on your website.

  This add-on is in beta and may not work as expected. Please report any issues to our support team.

  #### How to use

  1. Create an account in Google Adsense
  2. Add your SpreadSimple website to Adsense, it will require to have a top-level domain (e.g. <code>example.com</code>), so you need to connect your domain to your SpreadSimple website in advance.
  3. It will require you to add a code to your website's <head> section, copy it and paste it in the "AdSense website code for &lt;head&gt;" field.
  Alternatively you can go to Ads &rarr; By Site section and use the "Get code" button: ![](/img/integrations/google-adsense-instructions-01.png)
  4. Go to Ads &rarr; Ad Units section and create an Ad Unit: ![](/img/integrations/google-adsense-instructions-02.png)
  4. Copy code snipped of the created Ad Unit and paste it in the necessary slot below. There are 4 slots available: home page top, home page bottom, details page top and details page bottom. You can use the same Ad Unit for multiple slots. It's not required to use all 4 slots, you can use only the ones you need.

  #####  Things to pay attention to:

  - It takes time for Google to approve your website, so you may need to wait a few days before you see ads on your website.
  - You can use "Show borders" checkbox to see where the ads will be placed on your website.
  - You can see the ads on your published website only, not on the preview mode.
  `,

  formFields: [
    {
      name: 'enabled',
      type: 'toggle',
      label: 'Enabled',
      default: false,
      validations: [
        'required'
      ],
      secret: false
    },
    {
      name: 'siteCode',
      type: 'textarea',
      showIf: ['enabled'],
      label: 'AdSense website code for <head> *',
      default: '',
      placeholder: '<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-0000000000000000" crossorigin="anonymous"></script>',
      onBlur: trimToScriptTag,
      validations: [
        'required',
        'minLength:100',
        'maxLength:200',
        'startsWith:<script'
      ],
      rows: 5,
      secret: false
    },
    createSlotField({ name: 'slotHomeTop', label: 'Ad Unit code for home page top slot' }),
    createSlotField({ name: 'slotHomeBottom', label: 'Ad Unit code for home page bottom slot' }),
    createSlotField({ name: 'slotDetailsPageTop', label: 'Ad Unit code for details page top slot' }),
    createSlotField({ name: 'slotDetailsPageBottom', label: 'Ad Unit code for details page bottom slot' }),
    {
      name: 'showBorders',
      type: 'toggle',
      label: 'Show borders',
      showIf: ['enabled'],
      default: false,
      validations: [],
      secret: false
    }
  ],

  actions: [
    {
      type: 'injectToHead',
      run (formData) {
        return formData.siteCode
      }
    },
    {
      type: 'injectToHead',
      run (formData) {
        return `
        <script>
        document.addEventListener('ssViewerRendered', () => {
          const ads = document.querySelectorAll('ins.adsbygoogle')
          if (!ads || !ads.length) return
          ads.forEach(ad => {
            try {
              (adsbygoogle = window.adsbygoogle || []).push({})
            } catch (_) {}
          })
        })
        </script>
        `
      }
    }
  ]
}
