<template>
  <component
    :is="baseTag"
    class="title-editor"
    :class="{
      'is-editing': isTitleEditing,
      'is-disabled': disabled
    }"
  >
    <form
      class="title-editor__form"
      @submit.prevent="updateTitle"
    >
      <span
        v-show="!isTitleEditing"
        class="title-editor__value-saved"
      >
        <slot>{{ title }}</slot>
      </span>

      <input
        v-show="isTitleEditing"
        ref="titleInput"
        v-model.trim.lazy="$v.title.$model"
        :class="{ 'title-editor__input_error' : !$v.title.required }"
        class="title-editor__input"
      />

      <button
        v-if="!isTitleEditing"
        type="button"
        :disabled="disabled"
        class="title-editor__btn-edit icon-pencil2"
        @click.prevent="isTitleEditing = true"
      />
      <button
        v-else
        class="title-editor__btn-edit icon-save"
        type="submit"
      />
    </form>
    <div
      v-if="!$v.title.required"
      class="title-editor__error"
    >
      Title is required
    </div>

    <div
      v-if="!$v.title.minLength"
      class="title-editor__error"
    >
      Title must have at least {{$v.title.$params.minLength.min}} letters.
    </div>
    <div
      v-if="!$v.title.maxLength"
      class="title-editor__error"
    >
      Title can`t be longer than {{$v.title.$params.maxLength.max}} letters.
    </div>
  </component>
</template>
<script>
import Vue from 'vue'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)
export default {
  name: 'TitleEditor',
  props: {
    baseTag: {
      type: String,
      default: 'div'
    },
    value: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      title: this.value,
      isTitleEditing: false
    }
  },
  validations: {
    title: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(50)
    }
  },
  watch: {
    value (newTitle) {
      this.title = newTitle
    },
    isTitleEditing () {
      this.$nextTick(() => {
        this.$refs.titleInput.focus()
      })
    }
  },
  methods: {
    updateTitle () {
      if (!this.$v.$invalid) {
        if (this.title !== this.value) this.$emit('input', this.title)
        this.isTitleEditing = false
      }
    }
  }
}
</script>
