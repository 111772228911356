<template>
  <ErrorPageComponent/>
</template>
<script>
import ErrorPageComponent from 'error-page-component'
export default {
  name: 'Page404',
  metaInfo: {
    title: '404'
  },
  components: {
    ErrorPageComponent
  }
}
</script>
