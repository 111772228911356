var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "PageAside",
    { attrs: { isOpened: _vm.isAsideOpened }, on: { close: _vm.closeAside } },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeMenuTab === "page-options",
              expression: "activeMenuTab === 'page-options'"
            }
          ],
          staticClass: "settings"
        },
        [
          _c("div", { staticClass: "settings__head" }, [
            _c("h3", { staticClass: "settings__title" }, [
              _vm._v("Appearance")
            ]),
            _c(
              "div",
              {
                staticClass: "settings__close-sidebar",
                on: { click: _vm.closeAside }
              },
              [
                _c("i", {
                  staticClass: "settings__close-sidebar-icon icon-close2"
                })
              ]
            )
          ]),
          _c(
            "div",
            { staticClass: "settings__body", attrs: { id: "editor-bounds" } },
            [
              _c("SpreadViewerDesignOptions", {
                staticClass: "settings__form",
                attrs: {
                  value: {
                    appearance: _vm.value["appearance"],
                    page: _vm.value["page"],
                    header: _vm.value["header"],
                    footer: _vm.value["footer"]
                  },
                  loading: _vm.loading
                },
                on: {
                  save: function(val) {
                    return _vm.$emit("save", val)
                  },
                  input: function(val) {
                    return Object.assign(_vm.value, val)
                  }
                }
              })
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeMenuTab === "content-options",
              expression: "activeMenuTab === 'content-options'"
            }
          ],
          staticClass: "settings"
        },
        [
          _c("div", { staticClass: "settings__head" }, [
            _c("h3", { staticClass: "settings__title" }, [_vm._v("Content")]),
            _c(
              "div",
              {
                staticClass: "settings__close-sidebar",
                on: { click: _vm.closeAside }
              },
              [
                _c("i", {
                  staticClass: "settings__close-sidebar-icon icon-close2"
                })
              ]
            )
          ]),
          _c(
            "div",
            { staticClass: "settings__body" },
            [
              _c("SpreadViewerSourceOptions", {
                staticClass: "settings__form",
                attrs: {
                  googleUrl: _vm.googleUrl,
                  visible: _vm.changeSheet,
                  loading: _vm.loading
                },
                on: {
                  "toggle-visibility": function(val) {
                    return (_vm.changeSheet = val)
                  },
                  "google-url-change": function(val) {
                    return _vm.$emit("google-url-change", val)
                  },
                  "update-sheet-data": function($event) {
                    return _vm.$emit("update-sheet-data")
                  }
                }
              }),
              _c(
                "transition",
                { attrs: { name: "fade" } },
                [
                  _c("SpreadViewerContentOptions", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.changeSheet,
                        expression: "!changeSheet"
                      }
                    ],
                    staticClass: "settings__form",
                    attrs: {
                      cols: _vm.cols,
                      value: _vm.value,
                      domain: _vm.domain,
                      loading: _vm.loading,
                      integrationsData: _vm.integrationsData,
                      msg: _vm.msg
                    },
                    on: {
                      save: function(val) {
                        return _vm.$emit("save", val)
                      },
                      input: function(val) {
                        return Object.assign(this$1.value, val)
                      },
                      "change-tab": function(val) {
                        return _vm.changeActiveSidebarTab(val)
                      },
                      "open-default-single-item": function(val) {
                        return _vm.$emit("open-default-single-item", val)
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeMenuTab === "checkout-options",
              expression: "activeMenuTab === 'checkout-options'"
            }
          ],
          staticClass: "settings"
        },
        [
          _c("div", { staticClass: "settings__head" }, [
            _c("h3", { staticClass: "settings__title" }, [
              _vm._v("Checkout settings")
            ]),
            _c(
              "div",
              {
                staticClass: "settings__close-sidebar",
                on: { click: _vm.closeAside }
              },
              [
                _c("i", {
                  staticClass: "settings__close-sidebar-icon icon-close2"
                })
              ]
            )
          ]),
          _c(
            "div",
            { staticClass: "settings__body" },
            [
              _c("SpreadViewerCheckoutOptions", {
                staticClass: "settings__form",
                attrs: {
                  value: _vm.value,
                  cols: _vm.cols,
                  loading: _vm.loading,
                  msg: _vm.msg,
                  integrations: _vm.integrations,
                  integrationsData: _vm.integrationsData
                },
                on: {
                  save: function(val) {
                    return _vm.$emit("save", val)
                  },
                  input: function(val) {
                    return Object.assign(_vm.value, val)
                  },
                  "open-payment-addons": function($event) {
                    return _vm.$emit("open-payment-addons")
                  },
                  "disable-payment-addon": function(val) {
                    return _vm.$emit("disable-payment-addon", val)
                  }
                }
              })
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeMenuTab === "settings",
              expression: "activeMenuTab === 'settings'"
            }
          ],
          staticClass: "settings"
        },
        [
          _c("div", { staticClass: "settings__head" }, [
            _c("h3", { staticClass: "settings__title" }, [_vm._v("Settings")]),
            _c(
              "div",
              {
                staticClass: "settings__close-sidebar",
                on: { click: _vm.closeAside }
              },
              [
                _c("i", {
                  staticClass: "settings__close-sidebar-icon icon-close2"
                })
              ]
            )
          ]),
          _c(
            "div",
            { staticClass: "settings__body" },
            [
              _c("SpreadViewerSettings", {
                staticClass: "settings__form",
                attrs: {
                  value: _vm.value,
                  domain: _vm.domain,
                  pwdProtection: _vm.pwdProtection,
                  loading: _vm.loading,
                  locales: _vm.locales,
                  seoIndexing: _vm.seoIndexing
                },
                on: {
                  delete: function($event) {
                    return _vm.$emit("delete")
                  },
                  save: function(val) {
                    _vm.$emit("save", val)
                    _vm.$emit("reload-preview")
                  },
                  input: function(val) {
                    return Object.assign(_vm.value, val)
                  },
                  "update-indexing": function(val) {
                    return _vm.$emit("update-indexing", val)
                  },
                  "update-pwd-protection": function(val) {
                    return _vm.$emit("update-pwd-protection", val)
                  }
                }
              })
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeMenuTab === "pages",
              expression: "activeMenuTab === 'pages'"
            }
          ],
          staticClass: "settings"
        },
        [
          _c("div", { staticClass: "settings__head" }, [
            _c("h3", { staticClass: "settings__title" }, [
              _vm._v("Pages & links")
            ]),
            _c(
              "div",
              {
                staticClass: "settings__close-sidebar",
                on: { click: _vm.closeAside }
              },
              [
                _c("i", {
                  staticClass: "settings__close-sidebar-icon icon-close2"
                })
              ]
            )
          ]),
          _c(
            "div",
            { staticClass: "settings__body" },
            [
              _c("SpreadViewerRoutesOptions", {
                staticClass: "settings__form",
                attrs: {
                  value: { routes: _vm.value.routes },
                  domain: _vm.domain,
                  cols: _vm.cols,
                  loading: _vm.loading,
                  msg: _vm.msg
                },
                on: {
                  save: function(val) {
                    return _vm.$emit("save", val)
                  },
                  input: function(val) {
                    return Object.assign(_vm.value, val)
                  },
                  "change-tab": function(val) {
                    return _vm.changeActiveSidebarTab(val)
                  },
                  "request-preview-change": function(val) {
                    return _vm.$emit("request-preview-change", val)
                  },
                  "open-default-single-item": function(val) {
                    return _vm.$emit("open-default-single-item", val)
                  }
                }
              })
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeMenuTab === "addons",
              expression: "activeMenuTab === 'addons'"
            }
          ],
          staticClass: "settings"
        },
        [
          _c("div", { staticClass: "settings__head" }, [
            _c("h3", { staticClass: "settings__title" }, [_vm._v("Add-ons")]),
            _c(
              "div",
              {
                staticClass: "settings__close-sidebar",
                on: { click: _vm.closeAside }
              },
              [
                _c("i", {
                  staticClass: "settings__close-sidebar-icon icon-close2"
                })
              ]
            )
          ]),
          _c(
            "div",
            { staticClass: "settings__body" },
            [
              _c("IntegrationsFilter", {
                attrs: {
                  categories: _vm.integrationCategories,
                  value: _vm.currentIntegrationCategory
                },
                on: {
                  input: function(v) {
                    return _vm.$emit("update:currentIntegrationCategory", v)
                  }
                }
              })
            ],
            1
          )
        ]
      ),
      _vm.$ft.ALLOW_USER_ACCESS
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeMenuTab === "access",
                  expression: "activeMenuTab === 'access'"
                }
              ],
              staticClass: "settings"
            },
            [
              _c("div", { staticClass: "settings__head" }, [
                _c("h3", { staticClass: "settings__title" }, [
                  _vm._v("Access Manager")
                ]),
                _c(
                  "div",
                  {
                    staticClass: "settings__close-sidebar",
                    on: { click: _vm.closeAside }
                  },
                  [
                    _c("i", {
                      staticClass: "settings__close-sidebar-icon icon-close2"
                    })
                  ]
                )
              ]),
              _c(
                "div",
                { staticClass: "settings__body" },
                [
                  _c("SpreadViewerAccessOptions", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.changeSheet,
                        expression: "!changeSheet"
                      }
                    ],
                    staticClass: "settings__form",
                    attrs: {
                      value: _vm.value,
                      domain: _vm.domain,
                      isLoading: _vm.loading,
                      msg: _vm.msg
                    },
                    on: {
                      save: function(val) {
                        _vm.$emit("save", val)
                        _vm.$emit("reload-preview")
                      }
                    }
                  })
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _c(
        "template",
        { slot: "controls" },
        _vm._l(_vm.asideTabs, function(options, index) {
          return _c(
            "button",
            {
              key: index,
              staticClass: "aside-control",
              class: {
                active: _vm.activeMenuTab === options.id && _vm.isAsideOpened
              },
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.changeActiveSidebarTab(options.id)
                }
              }
            },
            [
              _c("i", {
                staticClass: "aside-control__icon",
                class: "icon-" + options.iconName
              }),
              _c("h4", { staticClass: "aside-control__title" }, [
                _vm._v(" " + _vm._s(options.title) + " ")
              ]),
              _c("span", { staticClass: "aside-control__border" })
            ]
          )
        }),
        0
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }