export default (client) => {
  const publicEndpoints = ['/auth/refresh', '/auth/login']

  return {
    /**
     * Injects request modifier
     */
    interceptors: (service) => {
      client.interceptors.request.use(request => {
        if (publicEndpoints.includes(request.url)) return request

        return service.accessToken()
          .then(token => {
            request.headers = {
              ...request.headers || {},
              Authorization: `Bearer ${token}`
            }
            return Promise.resolve(request)
          })
          .catch(() => Promise.resolve(request))
      }, error => Promise.reject(error))

      /**
       * Check response for access denied code
       */
      client.interceptors.response.use(response => {
        return response
      }, error => {
        // @todo - refactor logout criteria
        const isAuthIssue = (error) => {
          if (!error.response || !error.config) return false
          if (error.response.status === 401) return true
          if (error.config.url.includes('/auth/refresh')) return true
          return false
        }
        if (isAuthIssue(error)) {
          service.logout().then(() => {
            window.location.reload()
          })
        } else {
          return Promise.reject(error)
        }
      })
    }
  }
}
