<template>
  <div>
    <div class="billing">
      <h4 class="billing__title">Appsumo Codes</h4>
      <div class="billing__body">
        <div class="billing__grid-wrap">
          <div
            v-if="appsumoDeals"
            class="billing-grid"
          >
            <div class="billing-grid__head">
              <div class="billing-grid__cell is-heading">Plan</div>
              <div class="billing-grid__cell is-heading">Licenses</div>
              <div class="billing-grid__cell is-heading"></div>
              <div class="billing-grid__cell is-heading"></div>
            </div>
            <div
              class="billing-grid__body"
            >
              <div
                v-for="(deal, index) in appsumoDeals"
                :key="index"
                class="billing-grid__row"
              >
                <div class="billing-grid__cell is-bold">
                  <span>Appsumo</span>
                </div>
                <div class="billing-grid__cell is-bold">
                  {{ deal.licensesQuantity }}
                </div>
                <div class="billing-grid__cell">
                  <p v-if="deal.appsumoCodes.length">Number of codes applied: {{deal.appsumoCodes.length}}</p>
                </div>
                <div class="billing-grid__cell align-end"></div>
              </div>

              <div class="billing-grid__row is-divided">
                <div class="billing-grid__cell is-bold">
                  <span>Applied: </span>
                </div>
                <div class="billing-grid__cell is-bold">
                  <span>
                    {{userData.dealOptions.calculatedWebsites.paidLicenses['appsumo'].applied}}
                    <span class="text-color-secondary">
                      of {{userData.dealOptions.calculatedWebsites.paidLicenses['appsumo'].total}}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'AppsumoSettings',
  computed: {
    ...mapState('app', [
      'userData'
    ]),
    ...mapGetters('app', [
      'userActiveDealsByType'
    ]),
    appsumoDeals () {
      if (!this.userActiveDealsByType) return null
      return this.userActiveDealsByType.appsumo || null
    }
  }
}
</script>
