import { trackEvent } from './analytics'

let plan

export function setInitialTrackingData (payload) {
  plan = payload.plan
}

export function trackFeature (category, name, enabled = undefined, value = undefined) {
  trackEvent({
    'event': 'trackFeature',
    'usageData': {
      category: category,
      name: name,
      enabled: enabled,
      value: value,
      plan: plan
    }
  })
}
