// RULES:
// We ask user to fill each poll initial + three extra times.
// Afrer - poll will not be shown anymore.

const pollStorageName = pollName => {
  switch (pollName) {
    case 'initialPoll':
      return 'ssShowInitialQuiz'
    default:
      return 'ssShowOtherQuiz'
  }
}

const retryPeriods = pollName => {
  switch (pollName) {
    case 'initialPoll':
      return [ 0, 7, 14, 30 ]
    default:
      return [ 0, 7, 14, 30 ] // days
  }
}

const currentRetryPeriod = (name, retryN) => {
  return retryPeriods(name)[retryN]
}
const showThePoll = (poll) => {
  if (poll.retries > 3) return false
  const lUpdD = (new Date(poll.updatedAt)).getTime()
  const now = Date.now()
  return now > (lUpdD + 86400000 * currentRetryPeriod(poll.name, poll.retries))
}

export function setPollsStates (pollsStates) {
  for (let index = 0; index < pollsStates.length; index++) {
    const poll = pollsStates[index]
    const showPoll = !poll.ok && showThePoll(poll)
    if (!showPoll) return
    localStorage.setItem(pollStorageName(poll.name), showPoll)
  }
}
