import { render, staticRenderFns } from "./ErrorPage.vue?vue&type=template&id=a3e6d58e&"
import script from "./ErrorPage.vue?vue&type=script&lang=js&"
export * from "./ErrorPage.vue?vue&type=script&lang=js&"
import style0 from "./errorPage.styl?vue&type=style&index=0&lang=stylus&"


/* normalize component */
import normalizer from "!../../../main-app/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/devstark/spreadsimple/spreadsimple-app/main-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('a3e6d58e', component.options)
    } else {
      api.reload('a3e6d58e', component.options)
    }
    module.hot.accept("./ErrorPage.vue?vue&type=template&id=a3e6d58e&", function () {
      api.rerender('a3e6d58e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "@shared/error-page-component/src/ErrorPage.vue"
export default component.exports