var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    {
      directives: [
        {
          name: "scroll-class",
          rawName: "v-scroll-class:scrolled",
          value: 70,
          expression: "70",
          arg: "scrolled"
        }
      ],
      staticClass: "header for-manage"
    },
    [
      _c("TrialNotificationBar", { attrs: { spreadViewId: null } }),
      _c("div", { staticClass: "header__container" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "header__right-side" },
          [_c("ProfileDropdown", { attrs: { userData: _vm.userData || {} } })],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { staticClass: "header__logo", attrs: { href: "/" } }, [
      _c("img", { attrs: { src: "/img/logo-main.svg" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }