import dicts from './dicts'
import Fuse from 'fuse.js'

let fuses = new Map()

export function fuzzySearch (term, dictName) {
  let fuse = fuses.get(dictName)

  if (!fuse) {
    fuse = new Fuse(dicts[dictName], {
      shouldSort: true,
      tokenize: true,
      includeScore: true,
      includeMatches: false,
      threshold: 0.6,
      location: 0,
      distance: 100,
      maxPatternLength: 100,
      minMatchCharLength: 1,
      matchAllTokens: false
    })
    fuses.set(dictName, fuse)
  }

  return fuse.search(term)
}

function getFirstDuplicates (mappedVals) {
  const mappedValsOnly = mappedVals.filter(v => v.matchedCols.length)
  for (let valIndex in mappedValsOnly) {
    const val = mappedVals[valIndex]
    if (!val.matchedCols.length) continue
    const currentCol = val.matchedCols[0].col
    const valsWithCurrentCol = mappedValsOnly.filter(v => v.matchedCols[0].col === currentCol)
    if (valsWithCurrentCol.length > 1) return valsWithCurrentCol
  }
}

export function removeDuplicates (mappedVals) {
  const duplicates = getFirstDuplicates(mappedVals)
  if (duplicates) {
    duplicates
      .sort((a, b) => a.matchedCols[0].score - b.matchedCols[0].score) // get the best score first
      .slice(1) // keep the best only
      .forEach(val => {
        val.matchedCols.shift()
      }) // remove the duplicated col for the rests

    removeDuplicates(mappedVals) // repeat
  } else {
    return mappedVals
  }
}
