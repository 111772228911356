var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.addRedirectRule.apply(null, arguments)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "settings-form__settings-wrap" },
        [
          _c(
            "ProOptionWrapper",
            { attrs: { featureName: "redirectRules" } },
            [
              _c("FormField", { attrs: { label: "Redirect FROM" } }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.$v.unsavedRedirectRule.from.$model,
                      expression: "$v.unsavedRedirectRule.from.$model",
                      modifiers: { trim: true }
                    }
                  ],
                  staticClass: "input",
                  attrs: { type: "text" },
                  domProps: { value: _vm.$v.unsavedRedirectRule.from.$model },
                  on: {
                    blur: [
                      function($event) {
                        _vm.unsavedRedirectRule.from = _vm.trimPath(
                          _vm.unsavedRedirectRule.from
                        )
                      },
                      function($event) {
                        return _vm.$forceUpdate()
                      }
                    ],
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.unsavedRedirectRule.from,
                        "$model",
                        $event.target.value.trim()
                      )
                    }
                  }
                }),
                _vm.$v.unsavedRedirectRule.from.$dirty &&
                !_vm.$v.unsavedRedirectRule.from.required
                  ? _c("p", { staticClass: "form-field__error" }, [
                      _vm._v("\n            Field is required\n          ")
                    ])
                  : _vm._e()
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "settings-form__settings-wrap" },
        [
          _c(
            "ProOptionWrapper",
            { attrs: { featureName: "redirectRules" } },
            [
              _c("FormField", { attrs: { label: "Redirect TO" } }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.$v.unsavedRedirectRule.to.$model,
                      expression: "$v.unsavedRedirectRule.to.$model",
                      modifiers: { trim: true }
                    }
                  ],
                  staticClass: "input",
                  attrs: { type: "text" },
                  domProps: { value: _vm.$v.unsavedRedirectRule.to.$model },
                  on: {
                    blur: [
                      function($event) {
                        _vm.unsavedRedirectRule.to = _vm.trimPath(
                          _vm.unsavedRedirectRule.to
                        )
                      },
                      function($event) {
                        return _vm.$forceUpdate()
                      }
                    ],
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.$v.unsavedRedirectRule.to,
                        "$model",
                        $event.target.value.trim()
                      )
                    }
                  }
                }),
                _vm.$v.unsavedRedirectRule.to.$dirty &&
                !_vm.$v.unsavedRedirectRule.to.required
                  ? _c("p", { staticClass: "form-field__error" }, [
                      _vm._v("\n          Field is required\n        ")
                    ])
                  : _vm._e()
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "settings-form__settings-wrap" },
        [
          _c(
            "ProOptionWrapper",
            { attrs: { featureName: "redirectRules" } },
            [
              _c(
                "FormField",
                { attrs: { label: "Redirect status" } },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.redirectStatuses,
                      attach: "",
                      placeholder: "status",
                      "menu-props": "offsetY",
                      "append-icon": "keyboard_arrow_down"
                    },
                    model: {
                      value: _vm.$v.unsavedRedirectRule.status.$model,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.$v.unsavedRedirectRule.status,
                          "$model",
                          $$v
                        )
                      },
                      expression: "$v.unsavedRedirectRule.status.$model"
                    }
                  }),
                  _vm.$v.unsavedRedirectRule.status.$dirty &&
                  !_vm.$v.unsavedRedirectRule.status.required
                    ? _c("p", { staticClass: "form-field__error" }, [
                        _vm._v("\n          Field is required\n        ")
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "side-form__item side-form__btns" },
        [
          _c("ProOptionWrapper", { attrs: { featureName: "redirectRules" } }, [
            _c(
              "button",
              {
                staticClass: "btn btn--transparent",
                attrs: { disabled: _vm.$v.$invalid, type: "submit" }
              },
              [_vm._v("Add Rule")]
            )
          ])
        ],
        1
      ),
      _c("div", { staticClass: "side-form__item" }, [
        _c(
          "div",
          { staticClass: "redirect-rules" },
          _vm._l(_vm.redirectRules, function(rule, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "redirect-rules__item",
                attrs: {
                  title:
                    "From: " +
                    rule.from +
                    ". To: " +
                    rule.to +
                    ". Status: " +
                    rule.status
                }
              },
              [
                _c("div", { staticClass: "redirect-rules__item-left" }, [
                  _c("span", { staticClass: "redirect-rules__item-label" }, [
                    _vm._v(_vm._s(rule.from))
                  ]),
                  _c("span", {
                    staticClass: "redirect-rules__item-label-divider icon-arrow"
                  }),
                  _c("span", { staticClass: "redirect-rules__item-label" }, [
                    _vm._v(_vm._s(rule.to))
                  ]),
                  _c("span", { staticClass: "redirect-rules__item-status" }, [
                    _vm._v("(" + _vm._s(rule.status) + ")")
                  ])
                ]),
                _c("button", {
                  staticClass: "redirect-rules__item-btn icon-delete",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.deleteRedirectRule(index)
                    }
                  }
                })
              ]
            )
          }),
          0
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }