<template>
<div>
  <ManageRoutes
    v-if="currentRouteIndex === null"
    v-model="options.routes"
    :loading="loading"
    @set-current="setCurrentRoute"
    @update="updateRoute"
    @duplicate="duplicateRoute"
    @create="createRoute"
    @delete="deleteRoute"
    @submit="save"
  />
  <RouteForm
    v-if="currentRouteIndex !== null"
    v-model="currentRoute"
    :loading="loading"
    :routes="options.routes"
    :currentRouteIndex="currentRouteIndex"
    :domain="domain"
    @back="setCurrentRoute(null)"
    @submit="save"
  />
</div>
</template>

<script>
import ManageRoutes from './ManageRoutes'
import RouteForm from './RouteForm'

export default {
  name: 'SpreadViewerRoutesOptions',
  components: {
    ManageRoutes,
    RouteForm
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    cols: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean
    },
    domain: {
      type: String,
      required: true
    },
    msg: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      options: null,
      currentRouteIndex: null
    }
  },
  computed: {
    currentRoute: {
      get () {
        if (!this.options) return
        return this.options.routes[this.currentRouteIndex]
      },
      set (val) {
        this.updateRoute(this.currentRouteIndex, val)
      }
    }
  },

  watch: {
    options: {
      deep: true,
      handler (val) {
        this.$emit('input', val)
      }
    },
    value: {
      deep: true,
      handler (val) {
        this.options = val
      }
    },
    currentRoute (route) {
      this.requestPreviewChange(route)
    }
  },

  methods: {
    requestPreviewChange (route) {
      if (!route) return this.$emit('request-preview-change', { path: '/' })
      if (route.type !== 'page') return
      this.$emit('request-preview-change', { path: route.navUrl })
    },
    setCurrentRoute (index) {
      this.currentRouteIndex = index
    },
    updateRoute (index, val) {
      const routes = this.options.routes.slice()
      const route = routes[index]
      routes[index] = Object.assign({}, route, val)
      this.options.routes = routes
    },
    duplicateRoute (index) {
      const route = this.options.routes[index]
      const newRoute = Object.assign({}, route, {
        navTitle: route.navTitle + ' copy',
        navUrl: this.getUniqueNavRoute(route.navUrl)
      })
      this.options.routes.splice(index + 1, 0, newRoute)
    },
    deleteRoute (index) {
      this.options.routes.splice(index, 1)
      this.$trackFeature('pages&links', 'deleteRoute')
    },
    getUniqueNavRoute (baseNavUrl = 'page') {
      let suffix = 1
      const baseNavUrls = this.options.routes.map(r => r.navUrl)
      if (baseNavUrls.length === 0) return baseNavUrl
      const incrementName = () => {
        suffix++
        return `${baseNavUrl}-${suffix}`
      }
      let newNavUrl = incrementName()
      while (baseNavUrls.includes(newNavUrl)) {
        newNavUrl = incrementName()
      }
      return newNavUrl
    },
    createRoute (type) {
      const getDefaultPage = () => ({
        type: 'page',
        navTitle: 'New page',
        navUrl: this.getUniqueNavRoute('/new-page'),
        navTarget: '_self',
        navStyle: 'link',
        navHide: false,
        metaTitle: '',
        metaDescription: '',
        seoHide: false,
        accessRules: {
          allowedGroups: []
        },
        content: `# New Page

Hello [Markdown](https://commonmark.org/help/)!

- Use 'Expand editor' for a better writing experience
- Add images, links, tables with ease
- Add forms, maps, videos using by just pasting embed code
`
      })

      const getDefaultLink = () => ({
        type: 'link',
        navTitle: 'New link',
        navUrl: '/',
        navAlias: '',
        navTarget: '_self',
        navStyle: 'link',
        navHide: false,
        metaTitle: '',
        metaDescription: '',
        accessRules: {
          allowedGroups: []
        }
      })

      const newRoute = type === 'page' ? getDefaultPage() : getDefaultLink()
      const len = this.options.routes.push(newRoute)
      this.setCurrentRoute(len - 1)
      this.$trackFeature('pages&links', 'createRoute', undefined, type)
    },
    save () {
      this.$emit('save', this.options)
      this.requestPreviewChange(this.currentRoute)
    }
  },
  created () {
    this.options = this.value
  }
}
</script>
