export default {
  published: true,
  pro: true,
  featureRestrictionsGroup: 'analyticsIntegrations',
  name: 'ga',
  title: 'Google Analytics',
  logoUrl: '/img/integrations/ga.svg',
  coverUrl: '/img/integrations/ga-cover.png',
  bgColor: '#FFC517',
  categories: ['Analytics'],
  comingSoon: false,
  builtIn: false,
  url: 'https://analytics.google.com/analytics/web/',
  helpUrl: '',
  description: `
  Google Analytics lets you measure your advertising ROI as well as track your video, and social networking sites and applications.

  To connect Google Analytics enter your integration code.
  `,

  formFields: [
    {
      name: 'enabled',
      type: 'toggle',
      label: 'Enabled',
      default: false,
      validations: [
        'required'
      ],
      secret: false
    },
    {
      name: 'id',
      type: 'text',
      showIf: ['enabled'],
      label: 'Google Analytics ID',
      default: '',
      validations: [
        'required',
        'minLength:1',
        'maxLength:20',
        'startsWith:UA-:G-'
      ],
      secret: false
    }
  ],

  actions: [
    {
      type: 'injectToHead',
      run (formData) {
        return `
        <!-- Global site tag (gtag.js) - Google Analytics -->
        <script async src="https://www.googletagmanager.com/gtag/js?id=${formData.id}"></script>
        <script>
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${formData.id}');
        </script>
        `
      }
    }
  ]
}
