export default {
  published: true,
  pro: true,
  featureRestrictionsGroup: 'automationIntegrations',
  name: 'zapier',
  title: 'Zapier',
  headline: '',
  logoUrl: '/img/integrations/zapier.svg',
  coverUrl: '/img/integrations/zapier-cover.png',
  bgColor: '#FF4A00',
  categories: ['Automation'],
  comingSoon: false,
  builtIn: true,
  url: 'https://zapier.com',
  helpUrl: '',
  description: `
  Zapier is an online automation tool that connects your favorite apps, such as Google Sheets, Gmail, Slack, Mailchimp, and more.

  This integration allows you to catch the SpreadSimple orders in Zapier platform and connect with more than 2000 apps.

  Follow the instructions below:

  1. Login to your Zapier account.
  2. You will need to add your site to your Zapier account. For that <a href="https://zapier.com/developer/public-invite/124232/58e0c1dfb4c32fe42b41aaf22fd3a1b0/" target="_blank">follow this link</a> and click "Accept invite & build a Zap".
  3. In your Zapier account, click on "Make a Zap" button.
  4. Search for the SpreadSimple and select the newest version.
  5. Continue and when requested paste your API Key: <code>**<apikey/>**</code>
  6. Choose the relevant SpreadSimple website and click Continue.
  7. Choose the apps that you will be working with on Zapier. Continue with customizing them - select the action and so on.
  `,

  formFields: [],

  actions: []
}
