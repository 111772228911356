<template>
  <div class="available-websites__section">
    <h5 class="available-websites__section-title">{{ title }}</h5>
    <div class="available-websites__section-info">
      <span class="available-websites__section-info--regular">{{ amountOfActiveSpreadViews }} out of {{ amountOfAvailableActiveSpreadViews }}</span>
      <span class="available-websites__section-info--archived"> ({{ amountOfArchivedSpreadViews }} in archive)</span>
    </div>
    <div class="available-websites__section-progress-bar">
      <div
        :style="`width: ${progressBarFillingProcent}%`"
        :class="{
          'available-websites__section-progress-bar-fill': true,
          'available-websites__section-progress-bar-fulfilled': isLimitOver
        }"></div>
    </div>
    <div v-if="isLimitOver" class="available-websites__section-warning">
      <svg class="available-websites__section-warning-icon" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.99968 13.6667C3.31967 13.6667 0.333008 10.68 0.333008 7.00002C0.333008 3.32002 3.31967 0.333352 6.99968 0.333352C10.6797 0.333352 13.6663 3.32002 13.6663 7.00002C13.6663 10.68 10.6797 13.6667 6.99968 13.6667ZM7.66634 3.66669H6.33301V7.66669H7.66634V3.66669ZM7.66634 9.00002H6.33301V10.3334H7.66634V9.00002Z" fill="#FF4D4D"/>
      </svg>
      <span class="available-websites__section-warning-message">{{ warningMessage }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AvailableWebsitesInfo',
  props: {
    title: {
      type: String,
      default: 'Available websites'
    },
    warningMessage: {
      type: String,
      default: 'Please, upgrade your plan'
    },
    amountOfActiveSpreadViews: {
      type: Number,
      required: true
    },
    amountOfArchivedSpreadViews: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters('app', [
      'amountOfAvailableActiveSpreadViews'
    ]),
    progressBarFillingProcent () {
      return this.amountOfActiveSpreadViews * 100 / this.amountOfAvailableActiveSpreadViews
    },
    isLimitOver () {
      return this.amountOfActiveSpreadViews === this.amountOfAvailableActiveSpreadViews
    }
  }
}
</script>
