<template>
  <Modal
    v-model="modalOpened"
    :has-close-button="false"
    maxWidth="1440px"
    fullscreen
    class="template-preview-modal"
    closeOnPressEscBtn
  >
    <div class="template-preview">
      <div class="template-preview__header">
        <div class="template-preview__header-container">
          <a href="/" class="template-preview__header-logo">
            <img src="/img/logo-main.svg" alt="spreadsimple" class="template-preview__header-logo-img" width="153" height="32">
          </a>
          <div class="template-preview__header-right">
            <div class="template-preview__header-view-switcher">
              <label
                v-for="item in previewTypes"
                :key="item.value"
                class="template-preview__header-radio"
                :class="{'is-active': previewType === item.value}"
              >
                <input v-model="previewType" type="radio" :value="item.value">
                <i class="template-preview__header-radio-icon" :class="`icon-${item.icon}`"></i>
              </label>
            </div>
            <UiBtn size="s" @click="onStartClick">START WITH THIS TEMPLATE</UiBtn>
          </div>
        </div>
      </div>
      <transition v-if="template" name="fade" mode="out-in">
        <div v-if="previewType === 'desktop'" key="1" class="template-preview__desktop">
          <iframe
            class="template-preview__iframe" :src="template.previewLink"
            frameborder="0"
            data-openreplay-capture
          ></iframe>
        </div>
        <div v-else key="2" class="template-preview__phone-wrap">
          <div class="template-preview__phone">
            <iframe
              class="template-preview__phone-iframe" :src="template.previewLink"
              frameborder="0"></iframe>
          </div>
        </div>
      </transition>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/modal'
import UiBtn from '@/components/ui-btn/UiBtn.vue'
export default {
  name: 'TemplatePreviewModal',
  components: {
    UiBtn,
    Modal
  },
  data () {
    return {
      template: null,
      modalOpened: false,
      previewType: 'desktop',
      previewTypes: [
        { value: 'desktop', icon: 'preview-desktop' },
        { value: 'mobile', icon: 'preview-mobile' }
      ]
    }
  },
  methods: {
    open (template) {
      this.template = template
      this.modalOpened = true
    },
    onStartClick () {
      this.modalOpened = false
      this.$emit('startWithTemplate', this.template)
    }
  }
}
</script>
