export default {
  published: true,
  pro: true,
  featureRestrictionsGroup: 'chatIntegrations',
  name: 'intercom',
  title: 'Intercom',
  headline: '',
  logoUrl: '/img/integrations/intercom.svg',
  coverUrl: '/img/integrations/intercom-cover.png',
  bgColor: '#3C8EE6',
  categories: ['Chat'],
  comingSoon: false,
  builtIn: false,
  url: 'https://intercom.com',
  helpUrl: '',
  description: `
  Intercom is a fundamentally new way for internet businesses to communicate with customers, personally.

  Your Workspace ID is located here:
  ![](https://spreadsimple.s3.us-east-2.amazonaws.com/9d38f26b-5966-4970-9060-c191798d861c_intercom.png)
  `,

  formFields: [
    {
      name: 'enabled',
      type: 'toggle',
      label: 'Enabled',
      default: false,
      validations: [
        'required'
      ],
      secret: false
    },
    {
      name: 'id',
      type: 'text',
      showIf: ['enabled'],
      label: 'Workspace ID',
      default: '',
      validations: [
        'required',
        'minLength:4',
        'maxLength:20'
      ],
      secret: false
    }
  ],

  actions: [
    {
      type: 'injectToBodyEnd',
      run (formData) {
        return `
        <script>
        window.intercomSettings = {
          app_id: "${formData.id}"
        };
        </script>

        <script>
        // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/${formData.id}'
        (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${formData.id}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
        </script>
        `
      }
    }
  ]
}
