export const features = {
  list: [
    'connect a custom domain',
    'use payment methods',
    'remove SpreadSimple branding',
    'create more than 3 Free websites',
    'display more than 50 rows on a website'
  ],
  description: 'If you cancel your subscription now, your websites will be downgraded to Free and you won’t be able to:',
  hasNotDiscount: 'You will also lose your current discounts (if there are any) and the ability to save this price in case of future price increases.',
  hasDiscount: 'And you will also lose the current discount if it was applied to your subscription before.',
  offer: (discountSize) => 'To save all the features for your website and your current pricing, we are offering you a <span class="unsubscribe-modal__paragraph--accent">' + discountSize + '% discount</span> on your next payment.',
  hasNotDiscountUnsubscribeBtn: 'Skip discount, unsubscribe me',
  hasDiscountUnsubscribeBtn: 'Unsubscribe me',
  hasNotDiscountTakeBtn: 'I will take the discount',
  hasDiscountKeepBtn: 'I’ll keep the subscription'
}

export const reasons = [
  {
    key: 'doesNotFitMyCase',
    label: `SpreadSimple doesn’t fit my case`,
    checked: false,
    details: {
      value: '',
      description: 'Please tell us the reasons'
    }
  },
  {
    key: 'missingFeatures',
    label: `There are missing features`,
    checked: false,
    details: {
      value: '',
      description: 'Please let us know what features are missing?'
    }
  },
  {
    key: 'priceIsNotReasonable',
    label: `I think the price is not reasonable`,
    checked: false,
    details: {
      value: '',
      description: 'What price is more reasonable for your opinion?'
    }
  },
  {
    key: 'quality',
    label: `I am not satisfied with the quality of the service`,
    checked: false,
    details: {
      value: '',
      description: 'Please describe the quality issues'
    }
  },
  {
    key: 'doNotNeedItNow',
    label: `I don’t need it now but probably I will use it in the future`,
    checked: false,
    details: {
      value: '',
      description: 'Could you let us know when you might need it?'
    }
  },
  {
    key: 'discoveredPro',
    label: `I subscribed only to discover certain features`,
    checked: false,
    details: null
  },
  {
    key: 'freePlanIsEnough',
    label: `Free plan is enough for me`,
    checked: false,
    details: null
  },
  {
    key: 'cancelTrial',
    label: `I want to cancel my trial`,
    checked: false,
    details: null
  },
  {
    key: 'other',
    label: `Other`,
    checked: false,
    details: {
      value: '',
      description: 'Please give us more details'
    }
  }
]
