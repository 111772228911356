<template>
  <div class="spreadview" :class="{'hover': imgOverlayVisible}">
    <div
      class="spreadview__img"
      :class="{ 'is-archived': item.isArchived }"
      :style="`background-image: url('${websiteCover}')`"
      @click="linkToView"
    >
      <div class="spreadview__overlay">
        <button
          v-if="!item.isArchived"
          class="spreadview__edit-btn"
          :class="{ 'archived--disabled': item.isArchived }"
          :disabled="item.isArchived"
          @click="tryToLock()"
        >{{ $t('global.manageOptions') }}</button>
        <SingleSpreadViewDropdownMenu
          :item="item"
          :canDelete="canDelete"
          @change-view-published-status="$emit('change-view-published-status', $event)"
          @change-view-archived-status="$emit('change-view-archived-status', $event)"
          @open-delete-modal="openDeleteModal"
          @open-link-modal="openLinkModal"
          @duplicate="$emit('duplicate')"
          @on-menu-visible="showOverlay"
        />

        <el-dropdown
          v-if="canChangePlan"
          class="spreadview__dropdown for-manage-plans"
          trigger="click"
          placement="bottom-start"
          @visible-change="showOverlay"
        >
          <el-dropdown-menu
            v-if="currentWorkspace"
            slot="dropdown"
            class="spreadview__dropdown-menu for-manage-plans"
          >
            <template v-for="(deal, index) in Object.entries(currentWorkspace.ownerLicenses.paidLicenses)">
              <el-dropdown-item
                :key="index"
                :disabled="item.dealType === deal[0]"
                >
                <button
                  class="spreadview__dropdown-item"
                  @click.prevent="applySpreadViewDeal(deal[0])"
                >
                  Apply {{$options.dealTypeLabels[deal[0]]}}
                </button>
              </el-dropdown-item>
            </template>
            <template v-if="item.isPaid">
              <div class="spreadview__dropdown-menu-divider"></div>
              <el-dropdown-item class="is-danger">
                <button
                  class="spreadview__dropdown-item is-danger"
                  @click.prevent="showDowngradeInfoModal = true">
                  Downgrade to free
                </button>
              </el-dropdown-item>
            </template>
          </el-dropdown-menu>
          <button class="spreadview__manage-plans-btn">
            <i class="material-icons">paid</i>
            Manage plans
          </button>
        </el-dropdown>
      </div>
    </div>
    <div class="spreadview__body">
      <UiTag
        size="xxs"
        :label="$options.dealTypeLabels[item.dealType]"
        :color="planTagColor(item)"
        class="spreadview__plan-tag"
      />
      <TitleEditor
        :value="item.options.spreadView.name"
        class="spreadview__title"
        :disabled="item.isArchived"
        @input="tryToUpdateTitle"
      >
        <span
          class="spreadview__title-link"
          :class="{'is-disabled': item.isArchived}"
          @click="manageSpreadViewName"
        >
          {{ item.options.spreadView.name }}
        </span>
      </TitleEditor>
      <a
        v-if="item.options.spreadView.isPublished"
        :href="resultUrl"
        target="_blank"
        class="spreadview__link"
      >
        <span class="spreadview__link-title"> {{ resultUrl }} </span>
        <i class="spreadview__link-icon icon-external-link2"></i>
      </a>
      <div
        v-else
        class="spreadview__indicator"
      >
        {{ $t('global.publishedNot') }}
      </div>
      <div class="spreadview__footer">
        <div class="spreadview__tags-wrap" :class="{'is-archived': item.isArchived}">
          <div class="spreadview__tags">
            <i class="spreadview__tags-icon icon-tags"></i>
            <div
              v-for="(id, i) in websiteAssignedTags.slice(0, 3)"
              :key="i"
              class="spreadview__tag"
            >
              {{ showTagLabel(id) }}
            </div>
          </div>
          <el-popover
            trigger="click"
            placement="right-start"
            class="spreadview__tag-popover"
            popper-class="spreadview__tag-popover-menu"
            :visible-arrow="false"
            @hide="newTag = ''"
          >
            <template>
              <div class="spreadview__tag-popover-search">
                <input
                  v-model.trim="$v.newTag.$model"
                  type="text"
                  placeholder="Enter tag name..."
                  class="spreadview__tag-popover-search-input"
                  @keydown.enter="addNewTag"
                >
                <p v-if="$v.newTag.$dirty && !$v.newTag.maxLength" class="spreadview__tag-popover-search-msg">Max length is 30 symbols</p>
              </div>
              <button
                v-if="newTag.length"
                class="spreadview__tag-popover-add-item"
                :title="tagLabelExists ? 'Tag already exists' : null "
                :disabled="tagLabelExists"
                @click="addNewTag"
                >
                + Add "<span>{{ newTag }}</span>"
              </button>
              <el-checkbox-group
                v-model="websiteAssignedTags"
                class="spreadview__tag-popover-items"
                >
                <div v-for="(tagItem, i) in allTagsFiltered" :key="i" class="spreadview__tag-popover-item">
                  <el-checkbox
                    :label="tagItem.id"
                    class="spreadview__tag-popover-item-checkbox">
                    {{tagItem.label}}
                  </el-checkbox>
                </div>
              </el-checkbox-group>
            </template>
            <el-tooltip slot="reference" effect="dark" content="Add tag" placement="top">
              <button
                :class="{
                  'spreadview__tags-add-btn': true,
                  'is-counter': item.tags.length >= 4,
                  'archived--disabled': item.isArchived
                }"
                :disabled="item.isArchived"
              >
                <i v-if="item.tags.length < 4" class="icon-plus"></i>
                <span v-else> +{{ item.tags.length - 3 }} </span>
              </button>
            </el-tooltip>
          </el-popover>
        </div>
        <div v-if="activeEditor">
          <UserAvatar class="spreadview__revision-indicator" :letter="editorInitials"/>
        </div>
      </div>
    </div>

    <Modal
      v-model="modalDeleteOpened"
      :modalName="$t('pageManageSheet.modal.titleDelete')"
      >
      <div class="delete-modal">
        <div class="delete-modal__body">
          <div class="delete-modal__field">
            <button class="delete-modal__button negative" @click="modalDeleteOpened = false">{{ $t('global.cancel') }}</button>
            <button class="delete-modal__button" @click="deleteItem">{{ $t('global.yes') }}</button>
          </div>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="modalLinkOpened"
      :modalName="$t('pageManageSheet.modal.titleLink')"
      >
      <div class="publish-modal">
        <div class="publish-modal__published-state">
          <div class="publish-modal__result-field">
            <input ref="code" type="text" class="publish-modal__result-field-url" readonly :value="resultUrl">
             <button class="publish-modal__result-field-copy icon-duplicate2" @click.prevent="copyToClipboard"></button>
          </div>
          <a target="_blank" :href="resultUrl" class="publish-modal__button for-published">Open</a>
        </div>
      </div>
    </Modal>

    <Modal
      v-model="showDowngradeInfoModal"
      modalName="You are about to downgrade a website"
      >
      <div class="modal-pro-options">
        <p><b>Please note that downgrading your website doesn’t update your subscription plan. </b></p>
        <p>You can update the subscription and change the number of licenses in your <router-link :to="{name: 'account-billing'}">Billing Settings</router-link>. Or apply the available license to another website.</p>
        <button
          class="modal-pro-options__button"
          @click="downgradeToFree"
        >Proceed</button>
      </div>
    </Modal>

    <TakoverWarning
      :v-if="takeoverModalVisible"
      :visible="takeoverModalVisible"
      :type="takeoverWarningType"
      :currentEditor="activeEditor ? `${activeEditor.firstName} ${activeEditor.lastName}` : 'current editor'"
      @close="takeoverModalVisible = false"
      @takeover-request="sendTakeoverRequest"
      @countdown-end="tryToLock(prevActionAttempt, false)"
    />
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import Modal from '@/components/modal'
import UiTag from '@/components/ui-tag'
import TitleEditor from '@/components/title-editor'
import SingleSpreadViewDropdownMenu from './SingleSpreadViewDropdownMenu.vue'
import Vuelidate from 'vuelidate'
import Vue from 'vue'
import TakoverWarning from '@/components/takover-warning'
import { required, maxLength } from 'vuelidate/lib/validators'
import UserAvatar from '@/components/user-avatar'
import { initSocket } from '@/modules/sockets.js'
import { dealTypeLabels } from '../../modules/utils/dealTypeLabels'

Vue.use(Vuelidate)

export default {
  name: 'SingleSpreadView',
  dealTypeLabels,
  components: {
    Modal,
    UiTag,
    TitleEditor,
    TakoverWarning,
    UserAvatar,
    SingleSpreadViewDropdownMenu
  },
  props: {
    item: {
      type: Object
    },
    allTags: {
      type: Array,
      default () {
        return []
      }
    },
    activeEditor: {
      type: Object,
      default () {
        return undefined
      }
    }
  },
  data () {
    return {
      imgOverlayVisible: false,
      modalLinkOpened: false,
      modalDeleteOpened: false,
      iframeVisible: false,
      appMode: process.env.NODE_ENV,
      newTag: '',
      websiteCover: '',
      takeoverModalVisible: false,
      takeoverWarningType: '',
      prevActionAttempt: '',
      socket: null,
      showDowngradeInfoModal: false
    }
  },
  validations: {
    newTag: {
      required,
      maxLength: maxLength(30)
    }
  },
  computed: {
    ...mapState('app', [
      'userData',
      'currentWorkspaceId',
      'workspaces'
    ]),
    ...mapGetters('app', [
      'currentWorkspace',
      'workspaceAvailableLicenses'
    ]),
    canChangePlan () {
      if (this.item.isArchived) return false
      if (!this.workspaceAvailableLicenses) return false
      return Object.keys(this.workspaceAvailableLicenses).length > 1
    },
    canDelete () {
      const mainWorkspaceId = this.workspaces ? this.workspaces.find(ws => ws.main).id : undefined
      return mainWorkspaceId === this.currentWorkspaceId
    },
    allTagsFiltered () {
      if (this.newTag.length === 0) return this.allTags
      const matches = this.allTags.filter(i => i.label.toLowerCase().includes(this.newTag.toLowerCase().trim()))
      if (matches.length) return matches
      return this.allTags
    },
    resultUrl () {
      let viewerUrl
      if (this.appMode === 'development') {
        viewerUrl = process.env.VUE_APP_VIEWER_APP_LOCAL_DOMAIN + '?_host=' + this.item.domain
      } else {
        viewerUrl = this.item.customDomain && this.item.customDomain.length ? 'https://' + this.item.customDomain : 'https://' + this.item.domain
      }
      return viewerUrl
    },
    websiteAssignedTags: {
      get () {
        return this.item.tags.map(i => i.id)
      },
      set (newValue) {
        this.$emit('update-website-tags', { websiteId: this.item.id, tags: newValue })
      }
    },
    tagLabelExists () {
      return this.allTagsFiltered.some(i => i.label.toLowerCase() === this.newTag.toLowerCase().trim())
    },
    editorInitials () {
      if (!this.activeEditor) return ''
      return this.activeEditor.firstName.slice(0, 1) + this.activeEditor.lastName.slice(0, 1)
    }
  },
  methods: {
    ...mapActions('app', [
      'updateSpreadViewDeal'
    ]),
    planTagColor (item) {
      if (item.isPaid && !item.isArchived) {
        return 'success'
      } else if (!item.isPaid && !item.isArchived) {
        return 'secondary'
      } else if (item.isArchived) {
        return 'danger'
      } else return 'primary'
    },
    showOverlay (val) {
      this.imgOverlayVisible = val
    },
    openLinkModal () {
      this.modalLinkOpened = true
    },
    async openDeleteModal () {
      const canUse = this.tryToLock('DELETE')
      if (canUse) {
        this.modalDeleteOpened = true
      }
    },
    deleteItem () {
      this.$emit('delete')
      this.modalDeleteOpened = false
    },
    copyToClipboard () {
      let code = this.$refs.code
      code.select()
      document.execCommand('copy')
    },
    addNewTag () {
      this.$v.newTag.$touch()
      if (!this.$v.newTag.$invalid && !this.tagLabelExists) {
        this.$emit('add-new-tag', { tagLabel: this.newTag, websiteId: this.item.id, websiteTags: this.item.tags })
        this.newTag = ''
      }
    },
    showTagLabel (id) {
      const tag = this.allTags.find(t => t.id === id)
      return tag ? tag.label : 't_err'
    },
    async tryToUpdateTitle (newTitle) {
      const canUse = this.tryToLock('RENAME')
      if (canUse) {
        this.$emit('title-updated', newTitle)
      }
    },
    async downgradeToFree () {
      this.showDowngradeInfoModal = false
      this.updateSpreadViewDeal({
        id: this.item.id,
        dealType: 'free'
      })
    },
    async applySpreadViewDeal (dealType) {
      const canUse = this.tryToLock('CHANGE_DEAL')
      if (canUse) {
        this.updateSpreadViewDeal({
          id: this.item.id,
          dealType: dealType
        })
      }
    },
    toggleTakeoverModal (type, open = true) {
      this.takeoverWarningType = type
      this.takeoverModalVisible = open
    },
    tryToLock (action = 'UPDATE', initial = true) {
      let result
      this.toggleTakeoverModal('noType', false)
      if (this.activeEditor) {
        this.toggleTakeoverModal('warning')
        result = false
        if (initial) {
          this.prevActionAttempt = action
        }
      } else {
        if (action === 'UPDATE') {
          this.$router.push({ name: 'manage-sheet-page', params: { id: this.item.id } })
        } else {
          result = true
        }
        this.prevActionAttempt = ''
      }
      return result
    },
    async sendTakeoverRequest () {
      // INIT SOCKETS
      this.socket = initSocket({
        path: `/spreadview-${this.item.id}`,
        state: { spreadViewId: this.item.id },
        onConnect: () => {
          this.socket.on(this.socket.id, (data) => {
            this.toggleTakeoverModal('noType', false)
            if (!data.unlockApproved) {
              // this.takeoverFailModal = true
              this.toggleTakeoverModal('takeover-decline')
            } else {
              console.warn('Here must be an action from prev step...')
              if (this.prevActionAttempt === 'UPDATE') {
                this.$router.push({ name: 'manage-sheet-page', params: { id: this.item.id } })
              } else {
                this.toggleTakeoverModal('takeover-success')
              }
            }
          })
        },
        onDestroy: () => {
          this.socket.off(this.socket.id)
        }
      })

      this.socket.emit('spreadview:requestUnlock', ({
        userId: this.userData.id,
        firstName: this.userData.firstName,
        lastName: this.userData.lastName
      }), (response) => {
        if (response.status === 200) {
          this.toggleTakeoverModal('countdown')
        } else if (response.msg === 'SpreadView is free access') {
          if (this.prevActionAttempt === 'UPDATE') {
            this.$router.push({ name: 'manage-sheet-page', params: { id: this.item.id } })
          } else {
            this.toggleTakeoverModal('takeover-success')
          }
        } else if (response.msg === 'Someone is already waiting for access') {
          this.lockTakeOverModal = false
          this.takeoverFailModal = true
          this.toggleTakeoverModal('takeover-fail')
        } else {
          console.error('Takover request error;')
        }
      })
      // END INIT SOCKETS
    },
    manageSpreadViewName () {
      if (!this.item.isArchived) {
        this.tryToLock()
      } else {
        this.linkToView()
      }
    },
    linkToView () {
      if (this.item.isArchived && this.item.options.spreadView.isPublished) {
        this.openLinkModal()
      }
    }
  },
  created () {
    this.websiteCover = this.item.coverImage || '/img/noPreview.png'
  }
}
</script>
